import React from 'react';
import classNames from 'classnames';

import sparkleSticker from 'images/common/sticker/sparkle.png';

export const CampaignBanner = ({className}) => {
  const campaignExists = window.constants.campaign_exists;

  if (!campaignExists) return false;

  const Sticker = () => (
    <div className="c-campaign-banner__sticker">
      <img src={sparkleSticker} alt="sparkle-sticker" />
    </div>
  );

  return (
    <div className={classNames('c-campaign-banner', {[className]: className})}>
      <div className="c-campaign-banner__content">
        <div className="c-campaign-banner__text">
          <h2 className="c-campaign-banner__title">
            100 More Copies
            <span> For You, On Us</span>
          </h2>
          <Sticker />
          <div className="c-campaign-banner__description">
            100 more copies records for your next project.
          </div>
        </div>
        <Sticker />
      </div>
      <a className="c-campaign-banner__button c-button c-button--black" href="https://promo.qrates.com/press-on">LEARN MORE</a>
    </div>
  );
};
