import React, {useEffect, useRef, useState} from 'react';
import classNames from 'classnames';

export const WidgetPlayer = ({
  mainCoverImg,
  tracks,
  label,
  className,
  link,
  status = null,
  onChangeStatus,
}) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isActiveListener, setIsActiveListener] = useState(false);
  const [currentSongIndex, setCurrentSongIndex] = useState(0);
  const [nextSongIndex, setNextSongIndex] = useState(currentSongIndex + 1);
  const currentTrack = tracks && tracks[currentSongIndex] || null;
  const nextTrack = tracks && tracks[nextSongIndex] || null;
  const audioElement = useRef();

  const onSkipSong = (forwards) => {
    if (forwards) {
      tracks && setCurrentSongIndex(
        (currentSongIndex) => ((currentSongIndex + 1 > tracks.length - 1) ? 0 : currentSongIndex + 1),
      );
    } else {
      tracks && setCurrentSongIndex(
        (currentSongIndex) => ((currentSongIndex - 1 < 0) ? (tracks.length - 1) : currentSongIndex - 1),
      );
    }
  };

  useEffect(() => {
    tracks && setNextSongIndex(currentSongIndex + 1 > tracks.length - 1 ? 0 : currentSongIndex + 1);
  }, [currentSongIndex]);

  useEffect(() => {
    if (audioElement && tracks && !isActiveListener) {
      setIsActiveListener(true);
      audioElement.current.addEventListener('ended', () => onSkipSong(true));
      return () => {
        audioElement.current.removeEventListener('ended', () => onSkipSong(true));
      };
    }
  }, [audioElement]);

  useEffect(() => {
    isPlaying ? audioElement.current.play() : audioElement.current.pause();
  });

  return (
    <div className={classNames('c-widget-player', {'c-widget-player--stories': className === 'stories'})}>
      <audio className="c-widget-player__audio" src={currentTrack?.audio_url || currentTrack?.sound_cloud_stream_url} ref={audioElement}>
        <track kind="captions" />
      </audio>
      <div className="c-widget-player__label">{label || 'Not set'}</div>
      <div className="c-widget-player__cover">
        <img src={mainCoverImg || currentTrack?.img_src} alt="track cover" />
        {link && <a href={link} target="_blank"> </a>}
      </div>
      <div className="c-widget-player__controls">
        <div className="c-widget-player__buttons">
          <div className="c-widget-player__back-button" onClick={() => tracks && onSkipSong(false)}>
            <svg viewBox="0 0 24 24"><path d="M24 24 4.8 12 24 0V24zM4.8.0v24H0V0H4.8z" /></svg>
          </div>
          <div className="c-widget-player__play-button" onClick={() => tracks && setIsPlaying(!isPlaying)}>
            {isPlaying
              ? (
                <svg viewBox="0 0 100 100">
                  <path d="M22 0h20v1e2H20z" />
                  <path d="M65 0h20v1e2H60z" />
                </svg>
              )
              : <svg viewBox="0 0 100 100"><path d="M90.2 50.1 11 .5V1e2l79.4-49.7L90.2 50.1z" /></svg>}
          </div>
          <div className="c-widget-player__forward-button" onClick={() => onSkipSong(true)}>
            <svg viewBox="0 0 24 24"><path className="st0" d="M0 0l19.2 12L0 24V0zM24 0v24h-4.8V0H24z" /></svg>
          </div>
        </div>
        <h3 className="c-widget-player__track-title">{currentTrack?.title || 'Track not found'}</h3>
      </div>
      <div className="c-widget-player__content" onClick={() => tracks && onSkipSong(true)}>
        <h3 className="c-widget-player__title">{nextTrack?.title || 'No title'}</h3>
        <p className="c-widget-player__subtitle">{nextTrack?.artist_name || 'No name'}</p>
      </div>
      <div className="c-widget-player__bottom-bar">
        {status !== null && (
          <div onClick={() => { onChangeStatus && onChangeStatus(); }} className="c-widget-player__follow c-widget-player-button">
            <i className="c-widget-player-button__icon c-pictogram c-pictogram--star"> </i>
            <div className="c-widget-player-button__name">{status ? 'unwatch' : 'watch'}</div>
          </div>
        )}
        {link && (
          <a href={link} target="_blank" className="c-widget-player__view-link c-widget-player-button">
            <i className="c-widget-player-button__icon c-pictogram c-pictogram--record"> </i>
            <div className="c-widget-player-button__name">view project</div>
          </a>
        )}
      </div>
    </div>
  );
};
