import React from 'react';
import classNames from 'classnames';
import {connectCurrentRefinements} from 'react-instantsearch-dom';

const ClearRefinements = ({
  items, refine, clearOptionLabel, isActive, onClear,
}) => (
  <li
    key="clear-all"
    onClick={(event) => {
      event.preventDefault();
      refine(items);
      onClear();
    }}
    className={isActive ? 'active' : ''}
  >
    <a href="#" className={classNames('c-tab-slider__link', {'c-tab-slider__link--active': isActive})}>
      <span>{clearOptionLabel}</span>
    </a>
  </li>
);

export default connectCurrentRefinements(ClearRefinements);
