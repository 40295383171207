import React from 'react';
import dateFormat from 'dateformat';

export const WidgetInfo = ({project}) => {
  const {
    title,
    artist_name,
    available_quantity,
    start_date,
    left_hours,
    left_days,
    base_quantity,
    ordered_quantity,
    funding_rate,
    is_coming_soon,
    is_funding,
    is_onsale,
    is_sell_catalog,
    is_preorder,
    is_failed,
    is_finished,
    is_countdown,
    is_soldout,
    root_url,
  } = project;

  const projectPath = `${root_url}projects/${project?.slug || project?.id}`;

  const renderProjectTiming = () => {
    let label = null;
    if (is_coming_soon && is_funding) {
      label = `LAUNCHES ${dateFormat(start_date, 'd mmm yyyy')}`;
    } else if (is_coming_soon && is_preorder) {
      label = `STARTS ${dateFormat(start_date, 'd mmm yyyy')}`;
    } else if (is_finished) {
      label = 'FINISHED';
    } else if (is_countdown) {
      label = `${left_hours} HOURS LEFT`;
    } else if (left_days) {
      label = `${left_days} DAYS LEFT`;
    } else if (is_sell_catalog && start_date) {
      label = `RELEASE DATE ${dateFormat(start_date, 'd mmm yyyy')}`;
    }
    if (!label) return null;

    return <p className="info__date">{label}</p>;
  };

  const progressLineState = () => {
    if (is_funding) {
      return funding_rate;
    } else {
      return 100;
    }
  };

  const renderProjectState = () => {
    if (is_funding) {
      return 'CROWDFUNDING';
    } else if (is_sell_catalog && !is_soldout) {
      return 'IN-STOCK';
    } else if (is_sell_catalog && is_soldout) {
      return 'SOLD OUT';
    } else if (is_preorder) {
      return 'PRE-ORDER';
    }
  };

  const renderProjectQuantity = () => {
    if (is_coming_soon) {
      return null;
    } else if (is_failed) {
      return 'UNCOMPLETED';
    } else if (is_funding) {
      return `${ordered_quantity} / ${base_quantity}`;
    } else if (is_onsale && available_quantity < 10) {
      return `${available_quantity} copies left`;
    }
  };

  return (
    <div className="info">
      <a className="info__status status">
        <div className="status__info">
          <h3 className="status__type">{renderProjectState()}</h3>
          <p className="status__percent">{renderProjectQuantity()}</p>
        </div>
        <div className="status__progress-line progress-line">
          <span className="progress-line__progress" style={{width: `${progressLineState()}%`}} />
        </div>
      </a>
      <a className="info__link" href={projectPath} target="_blank">
        <h1 className="info__project-name">{title}</h1>
        <h2 className="info__artist-name">{artist_name}</h2>
      </a>
      {renderProjectTiming()}
    </div>
  );
};
