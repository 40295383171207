import React from 'react';
import {RichText} from 'prismic-reactjs';
import {NewLabel} from 'shared/components/labels/new_label/new_label';

export const StoriesCard = ({
  category,
  image,
  title,
  text,
  index,
  uid,
}) => {
  return (
    <div key={`${title + index}`} index={index} className="c-stories-card">
      <a href={`/stories/${uid}`}>
        <div className="c-stories-card__top">
          <div className="c-stories-card__label-container">
            <NewLabel className="c-stories-card__label" title={category} theme="transparent" />
          </div>
          <div className="c-stories-card__image-container">
            <span>
              <img src={image} alt={title} data-testid="stories-card-image" />
            </span>
          </div>
        </div>
        <div className="c-stories-card__bottom">
          <h4 wovn-ignore="" className="c-stories-card__title">
            {RichText.asText(title)}
          </h4>
          <p wovn-ignore="" className="c-stories-card__info">
            {RichText.asText(text)}
          </p>
        </div>
      </a>
    </div>
  );
};
