import React, {
  useState,
  useEffect,
} from 'react';

import {SoundCloudHelper} from 'utils/soundcloud_helper';

export const ProjectMainPlayer = ({tracks = {}}) => {
  const player = document.getElementById('audioPlayer');
  const progressBar = document.getElementById('track-player-progress-bar');

  const [currentTrack, setCurrentTrack] = useState({});
  const [currentTrackId, setCurrentTrackId] = useState(player.getAttribute('data-trackId'));
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isPlayerShow, setIsPlayerShow] = useState(false);

  const soundCloudHelper = new SoundCloudHelper(window.constants.soundcloud_token);

  const allTracks = [
    ...tracks.a_side,
    ...tracks.b_side,
    ...tracks.c_side,
    ...tracks.d_side,
    ...tracks.bonus,
  ].flat();

  useEffect(() => {
    allTracks.length > 0 && allTracks.find((track) => track.enable_sample_play) && setIsPlayerShow(true);
  }, [allTracks]);

  useEffect(() => {
    if (currentTrackId && currentTrackId != currentTrack?.id) {
      setCurrentTrack(allTracks?.find((track) => track.id == currentTrackId) || {});
    }
  }, [currentTrackId]);

  useEffect(() => {
    player.addEventListener('ended', () => {
      changeCurrentTrack('next');
      player.play();
    });
    player.addEventListener('play', () => setIsPlaying(true));
    player.addEventListener('pause', () => setIsPlaying(false));
    player.addEventListener('timeupdate', () => {
      const currentTime = player.currentTime;
      const duration = player.duration;

      setProgress((currentTime / duration) * 100);
    });
    player.addEventListener('playerTrackIdChange', () => {
      setProgress(0);
      setCurrentTrackId(player.getAttribute('data-trackId'));
    });

    const firstTrack = allTracks.find((track) => track.enable_sample_play);
    if (firstTrack) {
      const {audio_url, sound_cloud_track_id} = firstTrack;
      setCurrentTrack(firstTrack);

      if (audio_url) {
        player.setAttribute('src', audio_url);
      } else if (sound_cloud_track_id) {
        soundCloudHelper
          .getStreamUrlsByTrackId(sound_cloud_track_id)
          .then((url) => {
            player.setAttribute('src', url);
          });
      }
    } else {
      console.log('No tracks');
    }
  }, []);

  const setTrackId = (value) => {
    player.setAttribute('data-trackId', value);
    const event = new Event('playerTrackIdChange');
    player.dispatchEvent(event);
  };

  const changeCurrentTrack = (destination) => {
    const availableTracks = allTracks.filter((track) => {
      const disabledElement = document.querySelector(`[data-track-id='${track.id}'][data-disabled='true']`);

      return track.enable_sample_play && !disabledElement;
    });
    player.currentTime = 0;
    setProgress(0);
    let currentIndex = 0;
    availableTracks.forEach((track, index) => {
      if (track.id == currentTrack.id || track.id == player.getAttribute('data-trackId')) {
        currentIndex = index;
      }
    });
    let newIndex = destination == 'previous' ? currentIndex - 1 : currentIndex + 1;
    if (availableTracks.length < newIndex + 1) {
      newIndex = 0;
    }
    if (newIndex < 0) {
      newIndex = availableTracks.length - 1;
    }

    if (availableTracks[newIndex]) {
      const current = availableTracks[newIndex];
      const audioURL = document.querySelector(`[data-track-id='${current.id}']`)?.getAttribute('data-audio-url');
      const {audio_url, sound_cloud_track_id} = current;
      const paused = player.paused;

      setCurrentTrack(current);

      if (audio_url || audioURL) {
        player.setAttribute('src', audio_url || audioURL);
        setTrackId(current.id);
        !paused && player.play();
      } else if (sound_cloud_track_id) {
        soundCloudHelper
          .getStreamUrlsByTrackId(sound_cloud_track_id)
          .then((url) => {
            player.setAttribute('src', url);
            setTrackId(current.id);
            !paused && player.play();
          });
      }
    }
  };

  const handlePlay = () => {
    currentTrack && setTrackId(currentTrack.id);
    if (player.paused) {
      player.play();
      setIsPlaying(true);
      return;
    }

    player.pause();
    setIsPlaying(false);
  };

  const rewindAudio = (event) => {
    const clickPosition = event.pageX - progressBar.offsetLeft;
    const currentPercentage = (clickPosition / progressBar.offsetWidth) * 100;
    const currentTime = (player.duration * currentPercentage) / 100;
    setProgress(currentPercentage);
    player.currentTime = currentTime;
  };

  if (!isPlayerShow) {
    return null;
  }

  return (
    <div className="c-track-player">
      <div className="c-track-player__container">
        <div className="c-track-player__meta">
          <div className="c-track-player__meta--title" wovn-ignore="">
            {currentTrack.title}
            &nbsp;-&nbsp;
            <span className="c-track-player__meta--name">
              {currentTrack.artist_name}
            </span>
          </div>
        </div>
        <div className="c-track-player__interactive">
          <div
            className="c-track-player__buttons"
          >
            <div
              className="c-track-player__prev"
              onClick={(e) => changeCurrentTrack('previous')}
            >
              <i className="c-pictogram c-pictogram--skip-back" />
            </div>
            <div
              className="c-track-player__play"
            >
              <div className="c-track-player__play-button" onClick={handlePlay}>
                <i className={`c-pictogram ${isPlaying ? 'c-pictogram--pause' : 'c-pictogram--play'}`} />
              </div>
            </div>
            <div
              className="c-track-player__next"
              onClick={(e) => changeCurrentTrack('next')}
            >
              <i className="c-pictogram c-pictogram--skip-forward" />
            </div>
          </div>
          <div
            className="c-track-player__bar"
            id="track-player-progress-bar"
            onClick={rewindAudio}
          >
            <div className="c-track-player__progress-bar" style={{width: `${progress}%`}} />
          </div>
        </div>
        <div className="c-track-player__meta--name" />
      </div>
    </div>
  );
};
