import {Record} from 'immutable';

import CopyrightFile from './copyright_file';

const _Record = Record({
  id: null,
  ipr: null,
  ipr_text: null,
  country_id: null,
  company: null,
  position: null,
  name: null,
  city: null,
  street: null,
  prefecture: null,
  postal_code: null,
  filling_out_date: new Date(),
  copyright_file: null,
  acknowledgement_of_samples: false,
}, 'CopyrightFormRecord');

export default class CopyrightForm extends _Record {
  static fromJSON(json) {
    if (json) {
      json.copyright_file = CopyrightFile.fromJSON(json.copyright_file);

      return new CopyrightForm(json);
    }

    return new CopyrightForm();
  }
}
