import styled, { css } from 'styled-components';

const MOBILE_BREAKPOINT = 675;
const TABLET_BREAKPOINT = 990;

const minQuery = (breakpoint: number) => `@media (min-width: ${breakpoint}px)`;

const maxQuery = (breakpoint: number) => `@media (max-width: ${breakpoint}px)`;

export const breakpointDesktopOnly = `${minQuery(TABLET_BREAKPOINT + 1)}`;

export const breakpointTabletOnly = `${maxQuery(TABLET_BREAKPOINT)}`;

export const breakpointMobileOnly = `${maxQuery(MOBILE_BREAKPOINT)}`;

export const cssMobileSidePadding = css`
  ${breakpointTabletOnly} {
    padding-left: 15px;
    padding-right: 15px;
  }
`;

export const StyledDesktopOnly = styled.div`
  ${breakpointTabletOnly} {
    display: none;
  }
`;
