import React, {useEffect, useState} from 'react';
import {ProjectCard} from 'components/shared/project_cards/project';
import ApiHelper from 'utils/api_helper';
import {CatalogSlider} from 'shared/components/sliders/catalog_slider/catalog_slider';
import {Preloader} from 'shared/components/preloader/preloader';

export const MoreProjectsSection = ({
  target,
  artist,
  project_id,
  custom_class,
}) => {
  const [projects, setProjects] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const artistTitle = (
    <>
      More by&nbsp;
      <span wovn-ignore="">{artist?.name}</span>
    </>
  );
  const targets = {
    artist: {title: artistTitle, path: `/api/v1/projects/${project_id}/more_by_artist`},
    tags: {title: 'With Same Tag', path: `/api/v1/projects/${project_id}/more_by_tags`},
  };
  const title = targets[target]?.title;

  useEffect(() => {
    if (targets[target]?.path) {
      setIsLoading(true);
      ApiHelper.get(targets[target].path).then((res) => {
        setProjects(res.response);
        setIsLoading(false);
      });
    }
  }, []);

  if (isLoading) {
    return (
      <div className="c-more-projects">
        <div className="c-more-projects__header">
          <div className="c-more-projects__title">
            {title}
          </div>
        </div>
        <div className="c-more-projects__loading">
          <Preloader />
        </div>
      </div>
    );
  }

  return (
    <CatalogSlider className={custom_class} slidesType="project-card" title={title}>
      {projects.map((project, index) => {
        return (
          <ProjectCard isResponsive project={project} key={index} />
        );
      })}
    </CatalogSlider>
  );
};
