// @flow
import React from "react";
import styled from 'styled-components';
import { breakpointTabletOnly } from '../../styles/responsive';

const DividerContainer = styled.div`
  margin-bottom: 100px;
  padding: 0px 40px;
  hr {
    height: 2px;
    background-color: #000000;
    border-top: none;
  }

  ${breakpointTabletOnly} {
    padding: 0px 15px;
    margin-bottom: 40px;
  }
`;

export const Divider = () => {
  return (
    <DividerContainer>
      <hr />
    </DividerContainer>
  );
};
