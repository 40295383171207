/*eslint no-eval: 0*/

import React from 'react';
import classNames from 'classnames';

import WhiteQratesIcon from 'images/common/logos/qrates_logo_white.svg';
import BlackQratesIcon from 'images/common/logos/qrates-logo.svg';
import {orderProjectButton} from 'utils/button_helpers';

export const WidgetButton = ({
  project,
  className,
}) => {
  const rootPath = project.root_url;
  const orderPath = `${rootPath}projects/${project?.slug || project?.id}?add_to_order="true"`;
  const voteForRestartPath = `${rootPath}projects/${project?.slug || project?.id}?vote_for_a_restart="true"`;
  const orderButtonData = orderProjectButton({project});

  const linkPath = () => {
    if (orderButtonData.action === 'order') {
      return orderPath;
    } else if (orderButtonData.action === 'restart_request') {
      return voteForRestartPath;
    } else {
      return '#';
    }
  };

  const renderButton = () => {
    if (orderButtonData.action === 'order') {
      return (
        <a
          className="widget-button"
          target="_blank"
          href={linkPath()}
        >
          <img className="widget-button__icon" src={WhiteQratesIcon} alt="qrates icon" />
          {orderButtonData.label}
        </a>
      );
    } else if (orderButtonData.action === 'restart_request') {
      return (
        <a
          className="widget-button widget-button--yellow-border"
          target="_blank"
          href={linkPath()}
        >
          <img className="widget-button__icon" src={BlackQratesIcon} alt="qrates icon" />
          {orderButtonData.label}
        </a>
      );
    } else if (orderButtonData.readOnly) {
      return (
        <div className="widget-button readonly">
          <img className="widget-button__icon" src={BlackQratesIcon} alt="qrates icon" />
          {orderButtonData.label}
        </div>
      );
    } else {
      return (
        <div className="widget-button disabled">
          <img className="widget-button__icon" src={WhiteQratesIcon} alt="qrates icon" />
          {orderButtonData.label}
        </div>
      );
    }
  };

  return (
    <div className={classNames({
      [className]: className,
    })}
    >
      {renderButton()}
    </div>
  );
};
