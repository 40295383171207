import React from 'react';
import classNames from 'classnames';
import {Link} from 'react-router-dom';

import {DesignPanelRoutes} from '../../modules/routes/routes';

export const Header = ({className}) => {
  return (
    <div className={classNames('header', {[className]: className})}>
      <Link className="header__logo" to={DesignPanelRoutes.designPath()}> </Link>
      <Link className="header__title" to={DesignPanelRoutes.designPath()}>DESIGN PANEL</Link>
      <a className="header__close-button" href={DesignPanelRoutes.adminPanelPath('design')}>
        ADMIN PANEL
        <i className="c-pictogram c-pictogram--chevron-right" />
      </a>
    </div>
  );
};
