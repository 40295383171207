import React, {useState} from 'react';
import classNames from 'classnames';

import {ReactModal} from 'shared/components/modals/react_modal/react_modal';
import {BillingDetailsModal} from './modals/billing_details';

export const BillingDetails = ({order, setOrder, isEditable}) => {
  const orderItem = order.order_items[0] || {};
  const appElement = document.getElementById('order-billing-details');
  const [modal, setModal] = useState(false);

  const closeModal = () => {
    setModal(false);
  };

  return (
    <div id="order-billing-details">
      <div className="p-purchases__details__heading">
        Billing details
        {orderItem.delivery_type != 'storage' && (
          <a
            className={classNames('c-button--edit', {'c-button--disabled': !isEditable})}
            onClick={() => setModal(true)}
          >
            EDIT
          </a>
        )}
      </div>
      <div className="c-grid--start" wovn-ignore="">
        <div className="c-grid--n1-d3">
          <div className="p-purchases__details__target">{order.billing_name}</div>
        </div>
      </div>
      <div className="c-grid--start" wovn-ignore="">
        <div className="c-grid--n1-d3">
          <div className="p-purchases__details__content">{order.billing_email}</div>
          <div className="p-purchases__details__content">{order.billing_phone_number}</div>
        </div>
        <div className="c-grid--n1-d3">
          <div className="p-purchases__details__content">{order.billing_address}</div>
        </div>
      </div>
      <ReactModal
        isOpen={modal}
        appElement={appElement}
        onRequestClose={closeModal}
      >
        <BillingDetailsModal order={order} setOrder={setOrder} onClose={closeModal} />
      </ReactModal>
    </div>
  );
};
