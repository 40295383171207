import React from 'react';
import {Link} from 'react-router-dom';

export default function Navigation() {
  return (
    <div className="c-nav-discover">
      <div className="c-nav-discover__container">
        <nav className="c-nav-discover__nav--main">
          <ul>
            <li>
              <a href="/discover">Discover</a>
            </li>
            <li>
              <Link to="/stories" status="selected">
                Stories
              </Link>
            </li>
            <li>
              <a href="/lists">Lists</a>
            </li>
            <li>
              <a href="/artists">Artists & Labels</a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
}
