import React from 'react';
import {Link} from 'react-router-dom';
import {ReactButton} from 'shared/components/buttons/react_button/react_button';
import {DesignPanelRoutes} from '../../../modules/routes/routes';

export const MainScreen = ({navigationList, onButtonClick}) => {
  return (
    <div className="main-screen container">
      <div className="main-screen__title">DESIGN PANEL</div>
      <div className="categories-list">
        {navigationList && navigationList.slice(0, 2).map((category, index) => {
          return (
            <div className="categories-list__group" key={index}>
              <div className="categories-list__group-title">{category.name}</div>
              <div className="categories-list__sublist">
                {category.items.slice(0, 2).map((item, index) => {
                  return (
                    <Link
                      className="categories-list__sublist-item"
                      activeClassName="is-active"
                      to={DesignPanelRoutes.designPath(item.path)}
                      key={index}
                    >
                      {item.name}
                    </Link>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
      <ReactButton text="Open catalog" onClick={onButtonClick} theme="primary-black" align="center" />
    </div>
  );
};
