// @flow
import React, {useEffect, useState} from 'react';
import classNames from 'classnames';
import ApiHelper from 'utils/api_helper';
import {RichText} from 'prismic-reactjs';
import type {StorySliceEmbeddedAlbum} from '../../cms/story';
import {
  getSliceEmbeddedAlbumLabel,
  getSliceEmbeddedAlbumProject,
  getSliceLayoutSize,
} from '../../cms/story';
import {getProjectSlug} from '../../cms/project';
import {WidgetPlayer} from '../../../../shared/widget_player/widget_player';
import {useHistory} from 'react-router-dom/cjs/react-router-dom.min';

type Props = {
  slice: StorySliceEmbeddedAlbum,
};

export const AlbumEmbed = ({ slice }: Props) => {
  const project = getSliceEmbeddedAlbumProject(slice);
  const projectLabel = RichText.asText(getSliceEmbeddedAlbumLabel(slice));
  const projectLink = project && `/projects/${getProjectSlug(project)}`;
  const layoutSize = getSliceLayoutSize(slice) === 'Half';
  const [tracks, setTracks] = useState(null);
  const [isWatch, setIsWatch] = useState(false);
  const history = useHistory();

  const getAllTracks = (tracks) => {
    return [
      ...(tracks?.a_side || []),
      ...(tracks?.b_side || []),
      ...(tracks?.c_side || []),
      ...(tracks?.d_side || []),
      ...(tracks?.bonus || []),
    ];
  };

  const getWatchingProjects = async () => {
    if (project?.objectID) {
      try {
        const getWatchings = await ApiHelper.get(`/api/v1/watchings`);
        setIsWatch(!!getWatchings.response?.find((w) => w.project_id !== null && w.project_id === project.objectID));
      } catch (error) {console.log(error);}
    }
  };

  const onChangeWatch = async () => {
    if(project?.objectID) {
      const url = `/watchings/?project_id=${project.objectID}`;
      try {
        const onChangeWatch = isWatch ? await ApiHelper.delete(url) : await ApiHelper.post(url);
        onChangeWatch.response?.require_login && window.open('/signin?callback=draft');
        onChangeWatch.response?.result && getWatchingProjects();
      } catch (error) {console.log(error);}
    }
  };

  useEffect(() => {
      getWatchingProjects();
  }, [project?.objectID]);

  useEffect(() => {
    if (project?.objectID && !tracks) {
      const fetchData = async () => {
        try {
          const getTracks = await ApiHelper.get(`/projects/${project.objectID}/tracks.json`);
          if (getTracks) {
            const trackItems = getTracks.response.tracks;
            const firstTrack = getAllTracks(trackItems).find((track) => track.enable_sample_play);
            firstTrack &&  setTracks(getAllTracks(trackItems));
          }
        } catch (error) {console.log(error);}
      };
      fetchData();
    }
  }, [project?.objectID, tracks]);

  return (
    <div className={classNames('c-widget-player--story-album', {'c-widget-player--half': layoutSize})}>
      <WidgetPlayer
        className="stories"
        mainCoverImg={project?.rendered_image_url}
        tracks={tracks}
        label={projectLabel}
        link={projectLink}
        status={isWatch}
        onChangeStatus={onChangeWatch}
      />
    </div>
  );
};
