import React, {useState} from 'react';
import classNames from 'classnames';

import {ReactModal} from 'shared/components/modals/react_modal/react_modal';
import ShippingDetailsModal from './modals/shipping_details';

export const ShippingDetails = ({order, setOrder, isEditable}) => {
  const orderItem = order.order_items[0] || {};
  const appElement = document.getElementById('order-shipping-details');
  const [modal, setModal] = useState(false);

  const closeModal = () => {
    setModal(false);
  };

  return (
    <div id="order-shipping-details">
      <div className="p-purchases__details__heading">
        Shipping details
        {orderItem.delivery_type != 'storage' && (
          <a
            className={classNames('c-button--edit', {'c-button--disabled': !isEditable})}
            onClick={() => setModal(true)}
          >
            EDIT
          </a>
        )}
      </div>

      {orderItem.self_purchase && (
        <div className="c-grid--start">
          <div className="c-grid--n1-d3">
            <div className="p-purchases__details__target">{orderItem.delivery_type_i18n}</div>
          </div>
        </div>
      )}

      {!orderItem.self_purchase && orderItem.delivery_type != 'storage' && (
        <>
          <div className="c-grid--start">
            <div className="c-grid--n1-d3">
              <div className="p-purchases__details__target">{orderItem.delivery_name}</div>
            </div>
            <div className="c-grid--n1-d3">
              <div className="p-purchases__details__content">{orderItem.delivery_company}</div>
            </div>
          </div>
          <div className="c-grid--start">
            <div className="c-grid--n1-d3">
              <div className="p-purchases__details__content">{orderItem.delivery_phone_number}</div>
            </div>
            <div className="c-grid--n1-d3">
              <div className="p-purchases__details__content">{orderItem.delivery_address}</div>
            </div>
          </div>
        </>
      )}
      <ReactModal
        isOpen={modal}
        appElement={appElement}
        onRequestClose={closeModal}
      >
        <ShippingDetailsModal order={order} setOrder={setOrder} onClose={closeModal} />
      </ReactModal>
    </div>
  );
};
