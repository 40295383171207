import React from 'react';
import {connectStats} from 'react-instantsearch-dom';

const CustomStats = ({nbHits}) => {
  if (nbHits) {
    return (
      <div className="p-section-header__hits">
        {nbHits}
        {' '}
        projects
      </div>
    );
  } else {
    return null;
  }
};

export default connectStats(CustomStats);
