import React, {useState} from 'react';

import ApiHelper from 'utils/api_helper';
import {useLanguageChange} from 'utils/use_language_change';

import TricksImagePath from 'images/common/sticker/tricks.png';

export const ArticlesScreen = ({articles, next_page}) => {
  const [records, setRecords] = useState(articles);
  const [nextPage, setNextPage] = useState(next_page);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const fetchArticles = ({page}) => {
    const queryParams = new URLSearchParams();
    page && queryParams.set('page', page);
    ApiHelper.get(`/articles.json?${queryParams}`).then((res) => {
      setNextPage(res.response.next_page);
      const results = page ? [...records, ...res.response.results] : res.response.results;
      setRecords(results);
      setIsButtonDisabled(false);
    });
  };

  const loadMore = () => {
    setIsButtonDisabled(true);
    fetchArticles({page: nextPage});
  };

  useLanguageChange(fetchArticles);

  return (
    <div className="p-toolkit">
      <div className="p-toolkit__top-illustration"><img src={TricksImagePath} /></div>
      <h2 className="p-toolkit__heading">ARTIST TOOLKIT</h2>
      <p className="p-toolkit__explanation">
        <span className="f-adjust-ja">
          The Qrates team is here to make your project a success. Here are some tips & tricks you might find useful based on the status of your project.
        </span>
      </p>
      <p className="p-toolkit__butons c-button-grid--center-button">
        <a href="https://support.qrates.com" className="c-button c-button--black" target="_bllank">
          VISIT QRATES SUPPORT
        </a>
        <a href="/contact" className="c-button c-button--white-line-black">
          CONTACT SUPPORT TEAM
        </a>
      </p>
      <div className="c-grid--columns" wovn-ignore="">
        {records.map((article) => (
          <a href={`/articles/${article.uid}`} key={article.uid}>
            <div className="c-dashboard__summary">
              <div className="c-dashboard__summary__image" style={{backgroundImage: `url(${article.preview_image})`}}>
                <div className="c-dashboard__summary__tags">
                  {article.tags.map((tag, index) => (
                    <span className="c-label c-label--small c-label--help" key={index}>{tag.toUpperCase()}</span>
                  ))}
                </div>
              </div>
              <div className="c-dashboard__summary__title">{article.preview_title}</div>
              <div className="c-dashboard__summary__text">{article.preview_text}</div>
            </div>
          </a>
        ))}
      </div>
      <div className="pagination">
        {nextPage && (
          <>
            <br />
            <div className="c-button-grid--center-button">
              <button
                type="button"
                className="c-button c-button--large c-button--black"
                onClick={loadMore}
                disabled={isButtonDisabled}
              >
                LOAD MORE
              </button>
            </div>
            <br />
          </>
        )}
      </div>
    </div>
  );
};
