import React from 'react';
import classNames from 'classnames';

export const Label = ({
  className, title, onClick, theme,
}) => {
  const isIcon = theme === 'sucsess';

  return (
    <span
      className={classNames('c-label', {
        [className]: className,

        // Theme
        'c-label--small-rectangle': theme?.includes('small-rectangle'),
        'c-label--success': theme === 'sucsess',
        'c-label--unsuccess': theme === 'unsuccess',
      })}
      onClick={onClick}
    >
      <span>
        {isIcon && (
          <i className={classNames('c-label__icon c-pictogram', {
            'c-pictogram--tick': theme === 'sucsess',
          })}
          />
        )}
        {title}
      </span>
    </span>
  );
};
