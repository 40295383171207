import React, {useState} from 'react';
import {get, set} from 'lodash';

import ApiHelper from 'utils/api_helper';
import {handleError, clearValidationErrors} from 'utils/validation';

import {InputField} from 'shared/components/form/input_field/input_field';
import {SelectField} from 'shared/components/form/select_field/select_field';
import {Notification} from 'shared/components/notification';
import {ReactButton} from 'shared/components/buttons/react_button/react_button';
import {ModalHeading} from 'shared/components/modals/parts/content/modal_heading/modal_heading';
import {HalfFieldsContainer} from 'shared/components/form/grid_containers/half_fields_container/half_fields_container';

export const DeliveryInfoForm = ({onClose, user, setUser}) => {
  const {deliverableCountries} = window.constants;
  const countriesOptionsList = deliverableCountries.map((country) => ({label: country.name, value: country.code}));
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [notification, setNotification] = useState();
  const [formParams, setFormParams] = useState({
    validation_context: 'delivery_info',
    user: {
      delivery_first_name: user.delivery_first_name || '',
      delivery_last_name: user.delivery_last_name || '',
      delivery_building: user.delivery_building || '',
      delivery_street: user.delivery_street || '',
      delivery_city: user.delivery_city || '',
      delivery_prefecture: user.delivery_prefecture || '',
      delivery_postal_code: user.delivery_postal_code || '',
      delivery_country: user.delivery_country || '',
      delivery_phone_number: user.delivery_phone_number || '',
    },
  });

  const handleChange = (event) => {
    setFormParams(set({...formParams}, event.target.name, event.target.value));
  };

  const handleSubmit = () => {
    setSubmitDisabled(true);
    clearValidationErrors(setNotification);
    ApiHelper.patch('/api/v1/users/update', formParams).then((res) => {
      if (res.error) {
        handleError(res.error, setNotification);
      } else {
        setUser(res.response);
        onClose();
      }
      setSubmitDisabled(false);
    });
  };

  return (
    <div className="c-delivery-info-form">
      <ModalHeading className="c-delivery-info-form__modal-heading">Update your shipping information</ModalHeading>
      {notification && (
        <Notification type={notification.type}>
          {notification.message}
        </Notification>
      )}
      <HalfFieldsContainer className="c-delivery-info-form__user-info-group">
        <InputField
          placeholder="First name"
          label="First name *"
          onChange={handleChange}
          name="user.delivery_first_name"
          type="text"
          value={get(formParams, 'user.delivery_first_name')}
        />
        <InputField
          placeholder="Last name"
          label="Last name *"
          onChange={handleChange}
          name="user.delivery_last_name"
          type="text"
          value={get(formParams, 'user.delivery_last_name')}
        />
      </HalfFieldsContainer>
      <HalfFieldsContainer className="c-delivery-info-form__adress-group">
        <InputField
          placeholder="Building"
          label="Building"
          onChange={handleChange}
          name="user.delivery_building"
          type="text"
          value={get(formParams, 'user.delivery_building')}
        />
        <InputField
          placeholder="Street address"
          label="Street address *"
          onChange={handleChange}
          name="user.delivery_street"
          type="text"
          value={get(formParams, 'user.delivery_street')}
        />
        <InputField
          placeholder="City"
          label="City *"
          onChange={handleChange}
          name="user.delivery_city"
          type="text"
          value={get(formParams, 'user.delivery_city')}
        />
        <InputField
          placeholder="Prefecture / State"
          label="Prefecture / State *"
          onChange={handleChange}
          name="user.delivery_prefecture"
          type="text"
          value={get(formParams, 'user.delivery_prefecture')}
        />
        <InputField
          placeholder="Postal code"
          label="Postal code *"
          onChange={handleChange}
          name="user.delivery_postal_code"
          type="text"
          value={get(formParams, 'user.delivery_postal_code')}
        />
        <SelectField
          label="Country *"
          placeholder="Select country"
          value={get(formParams, 'user.delivery_country')}
          onChange={(option) => handleChange({target: {value: option.value, name: 'user.delivery_country'}})}
          options={countriesOptionsList}
          isSearchable
        />
      </HalfFieldsContainer>
      <InputField
        className="c-delivery-info-form__phone-number"
        placeholder="xx-xxxx-xxxx"
        label="Phone number *"
        onChange={handleChange}
        name="user.delivery_phone_number"
        type="text"
        value={get(formParams, 'user.delivery_phone_number')}
      />
      <div className="c-delivery-info-form__buttons">
        <div className="c-help-block">* Required information</div>
        <ReactButton
          className="c-delivery-info-form__save-button"
          type="button"
          onClick={handleSubmit}
          disabled={submitDisabled}
          theme="primary-black"
        >
          SAVE CHANGES
        </ReactButton>
      </div>
    </div>
  );
};
