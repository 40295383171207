import React, {useState, useEffect} from 'react';
import classNames from 'classnames';
import ApiHelper from 'utils/api_helper';
import {Dropdown} from 'shared/components/dropdown';
import {PurchaseOrders} from './purchase_orders/purchase_orders';

export const Purchases = () => {
  const [orders, setOrders] = useState([]);
  const [sort, setSort] = useState('desc');
  const [status, setStatus] = useState('all');
  const singlePurchase = orders?.length === 1;
  const totalPurchasesLabel = singlePurchase ? 'purchase' : 'purchases';

  const filters = [
    {label: 'ALL PURCHASES', value: 'all'},
    {label: 'FUNDING/PREORDER', value: 'funding_preorder'},
    {label: 'PRESSING', value: 'pressing'},
    {label: 'SHIPPED', value: 'shipped'},
    {label: 'COMPLETED', value: 'completed'},
    {label: 'PAYMENT FAILED', value: 'failed'},
  ];
  const sortOptions = [
    {label: 'Sort by New-old', value: 'desc'},
    {label: 'Sort by Old-new', value: 'asc'},
  ];

  const getOrders = () => {
    ApiHelper.get(`/api/v1/orders?sort=${sort}&status=${status}`).then((res) => {
      setOrders(res.response);
    });
  };

  const renderPurchaseOrders = () => {
    if (orders.length > 0) {
      return <PurchaseOrders orders={orders} />;
    } else {
      return (
        <div className="c-purchases__empty">
          <div className="c-purchases__empty--title">
            <h2>You don't have any orders yet.</h2>
          </div>
        </div>
      );
    }
  };

  useEffect(getOrders, [status, sort]);

  const ProjectStateFilterLine = () => {
    return (
      filters.map((filter, index) => (
        <span key={index}>
          <a
            className={classNames('c-tag--purchase', {'c-tag--black': filter.value === status})}
            onClick={() => setStatus(filter.value)}
          >
            {filter.label}
          </a>
        </span>
      ))
    );
  };

  return (
    <div className="p-account--purchase__grid">
      <div className="l-side-nav--order-details-control c-grid--sticky-top">
        <div className="p-side-nav-contents--order-details-control c-grid--sticky-top__item">
          <div className="c-purchases__heading">Purchases</div>
          <div className="c-purchases__explanation">
            You're turning dreams into reality.&nbsp;
            Thank you for supporting independent
            artists and their music on Qrates.
          </div>
        </div>
      </div>
      <div className="c-grid__container p-account--purchase__grid-container">
        <div className="c-purchases__section-control">
          <div className="c-purchases__filters">
            <ProjectStateFilterLine />
          </div>
        </div>
        <div className="c-purchases__section-control">
          <div className="c-grid--center">
            <div className="c-purchases__number">
              {orders.length}
              &nbsp;
              {totalPurchasesLabel}
            </div>
            <Dropdown
              options={sortOptions}
              onChange={setSort}
              initialValue={sort}
              customClass="c-select--sort c-dashboard__sort"
            />
          </div>
        </div>
        {renderPurchaseOrders()}
      </div>
    </div>
  );
};
