import {Record} from 'immutable';

const _Record = Record({
  id: null,
  url: '',
  original_filename: '',
  file_type: '',
  stored_file_type_id: 0,
  created_at: null,
}, 'StoredFileRecord');

export default class StoredFile extends _Record {
  static fromJSON(json) {
    return new StoredFile(json);
  }
}
