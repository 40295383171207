import React, {useEffect, useState} from 'react';
import {sumBy} from 'lodash';
import classNames from 'classnames';

import {formatMoney} from 'utils/format_money';
import ApiHelper from 'utils/api_helper';
import EmptyCartImage from 'images/common/sticker/press_only.png';
import {Notification} from 'shared/components/notification';
import {Preloader} from 'shared/components/preloader/preloader';
import {CartItemScreen} from '../cart_item_screen/cart_item_screen';

export const CartScreen = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isCartUpdating, setIsCartUpdating] = useState(false);
  const [errors, setErrors] = useState([]);
  const [cartItems, setCartItems] = useState([]);
  const [watchings, setWatchings] = useState([]);
  const pendingCartItems = cartItems.filter((cartItem) => cartItem.state === 'pending');
  const activeCartItems = cartItems.filter((cartItem) => cartItem.state === 'active');
  const paymentCurrency = activeCartItems[0]?.payment_currency;
  const checkoutDisabled = isCartUpdating || activeCartItems.some((i) => i.errors.full_messages.length > 0)
    || activeCartItems.filter((i) => i.can_order).length === 0;
  const orderableCartItems = activeCartItems.filter((item) => item.is_onsale || item.can_order);

  const updateCartItems = (items) => {
    const newErrors = [];
    items.forEach((cartItem) => {
      cartItem.errors.full_messages?.forEach((message) => {
        newErrors.push(`${cartItem.project_title}: ${message}`);
      });
    });
    setCartItems(items);
    setErrors(newErrors);
  };

  useEffect(() => {
    setIsLoading(true);
    ApiHelper.get('/api/v1/cart_items').then((res) => {
      updateCartItems(res.response);
      setIsLoading(false);
    });
    ApiHelper.get('/api/v1/watchings').then((res) => {
      res.response && setWatchings(res.response);
    });
  }, []);

  const renderEmptyCart = () => {
    return (
      <div id="p-order" className="p-cart">
        <div className="c-grid">
          <div className="c-grid__container">
            <div className="p-section">
              <div className="c-heading">
                <h2>Your Cart</h2>
              </div>
              <div className="p-cart__empty">
                <div className="p-cart__empty-sticker">
                  <img src={EmptyCartImage} />
                </div>
                <div className="p-cart__empty-text">
                  Nothing in your cart
                </div>
                {/* TODO: Refactor styles */}
                <div className="c-button-grid--center-button">
                  <a href="/curated" className="c-button c-button--black c-button--large">
                    QRATES CURATED
                  </a>
                </div>
                <div className="c-divider--blank" />
              </div>
            </div>
          </div>
          <div className="l-side-nav--cart u-hidden-side-nav-control" />
        </div>
      </div>
    );
  };

  if (isLoading) return <Preloader className="c-cart-screen__preloader" />;

  if (cartItems.length === 0) return renderEmptyCart();

  return (
    <div id="p-order" className="p-cart">
      <div className="c-grid">
        <div className="c-grid__container">
          <div className="p-section">
            <div className="c-heading">
              <h2>Your Cart</h2>
            </div>
            {errors.length > 0 && (
              <Notification type="error" customClassName="c-notification--middle">
                <ul>
                  {errors.map((error, index) => (
                    <li key={index}>{error}</li>
                  ))}
                </ul>
              </Notification>
            )}
            {activeCartItems.length > 0 && (
              <>
                <div className="p-cart__text u-hidden-tablet">
                  Putting an item in your cart does NOT reserve or hold the item for you.
                  The item will be secured once you complete you payment.
                </div>
                <div className="p-cart__sell-items">
                  {activeCartItems.map((cartItem) => (
                    <CartItemScreen cartItem={cartItem} updateCartItems={updateCartItems} key={cartItem.id} setIsLoading={setIsCartUpdating} />
                  ))}
                </div>
                <div className="p-purchases">
                  <div className="p-purchases__bottom-grid--purchases">
                    <div className="p-purchases__detail">
                      Shipping calculated during checkout
                    </div>
                    <div className="p-purchases__right-container">
                      <div className="p-purchases__cart-total">
                        <span className="p-purchases__subtotal-label">
                          {`SUBTOTAL (${paymentCurrency})`}
                        </span>
                        <span>{formatMoney({amount: sumBy(orderableCartItems, 'payment_amount'), currency: paymentCurrency})}</span>
                      </div>
                      <div className="p-purchases__button--purchases">
                        {isCartUpdating ? <Preloader className="c-cart-screen__button-preloader" /> : (
                          <a
                            href="/orders/new"
                            className={classNames(
                              'c-newbutton--black c-newbutton--x-large-tb-full',
                              {'c-newbutton--disabled': checkoutDisabled},
                            )}
                          >
                            CHECKOUT
                          </a>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="p-cart__text--purchases u-hidden-desktop-only">
                  Putting an item in your cart does NOT reserve or hold the item for you.
                  The item will be secured once you complete you payment.
                </div>
              </>
            )}
          </div>
          {pendingCartItems.length > 0 && (
            <div className="p-section">
              <div className="p-cart__title">Checkout later</div>
              {pendingCartItems.map((cartItem) => (
                <CartItemScreen cartItem={cartItem} updateCartItems={updateCartItems} key={cartItem.id} setIsLoading={setIsCartUpdating} />
              ))}
            </div>
          )}
          {watchings.length > 0 && (
            <p className="p-cart__watchlist-note">
              <span>Hey, it seems you have some records in your watchlist! Would you like to </span>
              <a href="/watchings" target="_blank" className="c-link">add records from watchlist</a>
              <span> to your cart?</span>
            </p>
          )}
        </div>
        <div className="l-side-nav--cart u-hidden-side-nav-control" />
      </div>
    </div>
  );
};
