import React, {useState, useEffect, useRef} from 'react';
import Prismic from '@prismicio/client';
import {useLanguageChange} from 'utils/use_language_change';
import {prismicClient} from 'prismic/shared/cms/prismic';
import {ReactButton} from 'shared/components/buttons/react_button/react_button';
import {ArtistToolkitCard} from './artist_toolkit_card';
import {toolkitFilters} from '../../constants/constants';
import {CuratedFeaturedFallback} from '../../components/curated_featured/curated_featured_fallback';

export const ArtistToolkitArticles = ({currentFilter}) => {
  const [articles, setArticles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [nextPage, setNextPage] = useState(null);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const queryAllArticles = Prismic.Predicates.at('document.type', 'article');
  const queryArticlesByTag = Prismic.Predicates.at('my.article.category', currentFilter);
  const dynamicQuery = currentFilter === 'Most Recent' ? queryAllArticles : [queryAllArticles, queryArticlesByTag];
  const pageRef = useRef(1);

  const queryPrismic = async (options, isPagination) => {
    setIsLoading(true);
    const locales = window.constants?.prismic_locales;
    const response = await prismicClient.query(dynamicQuery, {
      lang: locales[options.locale || window.constants.locale || 'en'],
      pageSize: 12,
      page: pageRef.current,
      orderings: '[document.last_publication_date desc]',
    });
    response && setNextPage(response.next_page);
    response && setArticles(isPagination ? (prev) => [...prev, ...response.results] : response.results);
    response && setButtonDisabled(!response.next_page);
  };

  const fetchArticles = async (options = {}, isPagination) => {
    try {
      return await queryPrismic(options, isPagination);
    } catch (err) {
      console.err(err);
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchNextPage = () => {
    setButtonDisabled(true);
    if (nextPage) {
      pageRef.current += 1;
      fetchArticles({}, true);
    }
  };

  useEffect(() => {
    pageRef.current = 1;
    fetchArticles();
  }, [currentFilter]);

  useLanguageChange((event) => {
    const locale = event.detail.locale;
    fetchArticles({locale});
  });

  if (isError) return <CuratedFeaturedFallback />;

  return (
    <div className="c-artist-toolkit-page__wrapper">
      <div className="c-artist-toolkit-page__grid">
        {articles?.map((article, index) => (
          <ArtistToolkitCard
            key={index}
            uid={article.uid}
            category={toolkitFilters?.[article.data.category]}
            title={article.data.preview_title}
            image={article.data.preview_image.url}
            text={article.data.preview_text}
            index={index}
          />
        ))}
      </div>
      {nextPage && (
        <div className="c-artist-toolkit-page__button-container" wovn-ignore="">
          <ReactButton
            className="c-artist-toolkit-page__button"
            disabled={buttonDisabled}
            text={isLoading ? 'LOADING' : 'LOAD MORE'}
            onClick={fetchNextPage}
          />
        </div>
      )}
    </div>
  );
};
