import React from 'react';
import classNames from 'classnames';

export const StoryCard = ({
  className,
  label,
  subtitle,
  title,
  text,
  date,
  image,
  link,
}) => {
  return (
    <div className={classNames('с-story-card', {[className]: className})}>
      <div className="с-story-card__lable c-label--working">{label}</div>
      <div className="с-story-card__cover-image" style={{backgroundImage: `url(${image})`}} />
      <p className="с-story-card__subtitle">{subtitle}</p>
      <h3 className="с-story-card__title">{title}</h3>
      <p className="с-story-card__text">{text}</p>
      <div className="с-story-card__date">{date}</div>
      <a className="с-story-card__link" href={link} target="_blank"> </a>
    </div>
  );
};
