// @flow
import React from 'react';
import {ArticleBody} from './components/ArticleBody/ArticleBody';
import ScreenWrapper from 'prismic/shared/components/ScreenWrapper/ScreenWrapper';
import {
  getArticleRelatedArticles,
  getArticleType,
  getArticleTags,
  getArticleTitle,
  getArticleBannerImageUrl,
  getArticlePreviewText,
} from 'prismic/shared/cms/article';
import {ArticleFooter} from './components/ArticleFooter/ArticleFooter';
import {ArticleHeader} from './components/ArticleHeader/ArticleHeader';

export const ArticleScreen = ({article}) => {
  return (
    <ScreenWrapper>
      <ArticleHeader
        title={getArticleTitle(article)}
        tags={getArticleTags(article)}
        image={getArticleBannerImageUrl(article)}
        text={getArticlePreviewText(article)}
      />
      <ArticleBody article={article} articleType={getArticleType(article)} />
      <ArticleFooter relatedArticles={getArticleRelatedArticles(article)} />
    </ScreenWrapper>
  );
};
