import strftime from 'strftime';
import moment from 'moment';
import {sortBy} from 'lodash';

export function isToday(date) {
  const today = new Date();
  const givenDate = new Date(date);

  return givenDate.getDate() === today.getDate() && givenDate.getMonth() === today.getMonth()
    && givenDate.getFullYear() === today.getFullYear();
}

export function formatDate(date, format = '%b %d %Y') {
  if (!date) {
    return '';
  }

  return strftime(format, new Date(date));
}

export function formatDateTime({datetime, offset, format = '%b %d %Y %H:%m'}) {
  if (!datetime) {
    return '';
  }
  // Original offset in seconds but strftime takes in minutes
  const formatter = (typeof offset === 'number') ? strftime.timezone(offset / 60) : strftime;

  return formatter(format, moment(datetime).toDate());
}

export const sellDates = ({
  start_date,
  preorder_start_date,
  preorder_finish_date,
  preorder_option,
  preorder_available,
  timezone,
}) => {
  const dates = {
    preorder_start_date: new Date(
      (preorder_start_date && moment(toDate({datetime: preorder_start_date, offset: timezone?.preorder_start_date_offset}))) || moment(),
    ),
  };
  dates.preorder_finish_date = new Date(
    (preorder_finish_date && moment(toDate({datetime: preorder_finish_date, offset: timezone?.preorder_finish_date_offset})))
      || moment().clone().add(90, 'days'),
  );
  dates.min_preorder_start_date = dates.preorder_start_date;
  if (dates.preorder_start_date >= dates.preorder_finish_date) {
    dates.preorder_finish_date = new Date(moment(dates.preorder_start_date).clone().add(1, 'days'));
  }
  dates.start_date = new Date(
    (start_date && moment(toDate({datetime: start_date, offset: timezone?.start_date_offset})))
      || (preorder_finish_date && moment(toDate({datetime: preorder_finish_date, offset: timezone?.preorder_finish_date_offset})))
      || moment().clone().add(90, 'days'),
  );
  dates.min_start_date = new Date(
    (preorder_finish_date && moment(toDate({datetime: preorder_finish_date, offset: timezone?.preorder_finish_date_offset})) || moment()),
  );
  if (preorder_available && preorder_option != 'storage' && preorder_finish_date && dates.start_date < dates.preorder_finish_date) {
    dates.start_date = dates.preorder_finish_date;
  }
  if (dates.min_preorder_start_date > new Date()) {
    dates.min_preorder_start_date = new Date();
  }
  dates.min_preorder_finish_date = new Date(moment(dates.preorder_start_date).clone().add(1, 'days'));
  dates.max_preorder_finish_date = new Date(moment(dates.preorder_start_date).clone().add(120, 'days'));

  return dates;
};

export const timeOptions = () => {
  return [...Array(24).keys()].map((hour) => {
    return ['00', '30'].map((minute) => {
      const value = `${`0${hour}`.slice(-2)}:${minute}`;

      return ({label: value, value});
    });
  }).flat();
};

export const timezoneOptions = () => {
  const {timezones} = window.constants;

  return sortBy(timezones, (zone) => zone.utc_offset).map((zone) => (
    {label: `${zone.name} UTC${zone.formatted_offset}`, value: {name: zone.name, identifier: zone.identifier}}
  ));
};

export const toDate = ({datetime, offset}) => {
  const format = '%Y-%m-%d';
  let dateTimeStr;
  if (typeof datetime == 'string') {
    dateTimeStr = formatDateTime({datetime: moment(datetime).toDate(), format, offset});
  } else {
    dateTimeStr = formatDateTime({datetime, format, offset});
  }
  const regex = /(?<year>\d{4})[/-](?<month>\d{1,2})[/-](?<day>\d{1,2})/;
  const dateStr = dateTimeStr.match(regex);
  if (!dateStr) throw new Error(`Incorrect date format: ${datetime}`);

  const date = new Date();
  date.setFullYear(dateStr.groups.year, (parseInt(dateStr.groups.month) - 1), dateStr.groups.day);

  return date;
};

export const fundingProjectShippingApproximation = ({finishDate, isVinylProject}) => {
  if (!finishDate) return null;
  const asDate = new Date(finishDate).getDate();
  const estimateRange = isVinylProject ? 3 : 2;
  if (typeof asDate == 'number') {
    let approximation;
    if (asDate <= 9) {
      approximation = 'early';
    } else if (asDate >= 10 && asDate <= 19) {
      approximation = 'mid';
    } else {
      approximation = 'late';
    }
    const monthStr = moment(finishDate).add(estimateRange, 'months').format('MMMM');
    const approximatedMonth = approximation.concat(' ', monthStr);

    return {approximatedMonth};
  }
};
