import React, {useState, useEffect} from 'react';
import {Dropdown} from 'shared/components/dropdown';
import ApiHelper from 'utils/api_helper';

export const DownloadsPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [projects, setProjects] = useState([]);
  const [nextPage, setNextPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [sortBy, setSortBy] = useState('added:desc');
  const sortOptions = [
    {label: 'Sort by New-old', value: 'added:desc'},
    {label: 'Sort by Old-new', value: 'added:asc'},
    {label: 'Sort by Title', value: 'title'},
    {label: 'Sort by Artist name', value: 'artist'},
  ];

  const processPagination = (response) => {
    setTotalCount(response.headers.get('X-Total'));
    setNextPage(response.headers.get('X-Next-Page'));

    return response;
  };

  const fetchProjects = () => {
    const endpoint = `/api/v1/projects/downloads?sort=${sortBy}&page=${nextPage}&per_page=10`;

    ApiHelper
      .get(endpoint, processPagination)
      .then(({response}) => {
        if (response) {
          setProjects([...projects, ...response]);
          setIsLoading(false);
        }
      });
  };

  const renderProject = ({
    id,
    slug,
    title,
    artist_name,
    project_card_image_url,
    added_at,
    download_type,
  }) => {
    const showDate = (/order/).test(download_type);

    return (
      <div className="p-purchases-list p-purchases-list--download" key={id}>
        <div className="p-purchases-list__image">
          <a href={`/projects/${slug}`}>
            <img src={project_card_image_url} />
          </a>
        </div>
        <div className="p-purchases-list__info">
          <div className="p-purchases-list__title" wovn-ignore="">
            {title}
          </div>
          <div className="p-purchases-list__name" wovn-ignore="">
            {artist_name}
          </div>
          {showDate && (
            <div className="p-purchases-list__date">{`Order date: ${added_at}`}</div>
          )}
        </div>
        <div className="p-purchases-list__price">
          <a
            href={`/downloads/${id}`}
            className="c-button c-button--white-line-black c-button--large c-button--next"
          >
            DOWNLOAD PAGE
          </a>
        </div>
      </div>
    );
  };

  const renderProjects = () => {
    return (
      <ul className="p-purchases c-dashboard__section c-projects">
        {projects.map((project) => renderProject(project))}
        {nextPage && (
          <div className="pagination">
            <div className="c-button-grid--center-button">
              <button
                type="button"
                className="c-button c-button--large c-button--black"
                onClick={() => setIsLoading(true)}
                disabled={isLoading}
              >
                LOAD MORE
              </button>
            </div>
            <br />
          </div>
        )}
      </ul>
    );
  };

  const renderNoProjects = () => {
    return (
      <div className="p-projects__empty">
        <div className="p-projects__empty__title">
          <h2>No projects.</h2>
        </div>
      </div>
    );
  };

  useEffect(() => {
    setProjects([]);
    setNextPage(1);
    setIsLoading(true);
  }, [sortBy]);

  useEffect(() => {
    if (isLoading) fetchProjects();
  }, [isLoading]);

  return (
    <div className="p-account--purchase__grid">
      <div className="l-side-nav--order-details-control c-grid--sticky-top">
        <div className="p-side-nav-contents--order-details-control c-grid--sticky-top__item">
          <div className="c-dashboard__heading">
            <h2>Download</h2>
          </div>
          <div className="c-dashboard__explanation">
            You’re turning dreams into reality.&nbsp;
            Thank you for supporting independent artists and their music on Qrates.
          </div>
        </div>
      </div>
      <div className="c-grid__container p-account--purchase__grid-container">
        <div className="c-dashboard__section-control">
          <div className="c-grid--center">
            <div className="p-purchases__number">{`${totalCount} projects`}</div>
            <Dropdown
              options={sortOptions}
              onChange={setSortBy}
              initialValue={sortBy}
              customClass="c-select--sort c-dashboard__sort"
            />
          </div>
        </div>

        {projects.length > 0 && renderProjects()}
        {projects.length === 0 && renderNoProjects()}

        <div className="c-divider--blank" />
      </div>
    </div>
  );
};
