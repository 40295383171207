// @flow
import React, {useState} from 'react';
import {connectInfiniteHits, connectCurrentRefinements} from 'react-instantsearch-dom';
import styled, {css} from 'styled-components';
import StoryPreview, {STORY_SIZE} from '../StoryPreview/StoryPreview';
import {breakpointDesktopOnly} from '../../../../shared/styles/responsive';

const cssLoading = css`
  min-height: 100vh;
  background-color: #d0d0d0;
`;

const StyledContainer = styled.section`
  ${breakpointDesktopOnly} {
    display: flex;
    flex-wrap: wrap;
    border-top: 1px solid #000000;
    border-bottom: 1px solid #000000;
  }
  ${props => (props.isLoading ? cssLoading : '')};
`;

const Hits = ({hits, hasMore, refineNext, isMediumSizeFirst}) => {
  const [hasFilter, setHasFilter] = useState(false);
  const CurrentRefinements = (props) => {
    setHasFilter(props.items.length > 0);

    return false;
  };
  const CustomCurrentRefinements = connectCurrentRefinements(CurrentRefinements);

  return (
    <>
      <StyledContainer>
        <CustomCurrentRefinements />
        {hits.map((hit, index) => {
          const story = { ...hit, data: hit };
          const currentIndex = isMediumSizeFirst ? index + 1 : index;
          let size;

          switch (currentIndex % 3) {
            case 2:
              size = STORY_SIZE.small;
              break;
            case 1:
              size = STORY_SIZE.medium;
              break;
            default:
              size = STORY_SIZE.large;
          }

          return (
            <StoryPreview
              story={story}
              size={size}
              key={index}
            />
          );
        })}
      </StyledContainer>
      {hasMore && (
        <div className="p-lists__button c-button-grid--center-button">
          <button
            type="button"
            className="c-button c-button--large c-button--black"
            onClick={refineNext}
          >
            LOAD MORE STORIES
          </button>
        </div>
      )}
    </>
  );
};

export const HitsList = connectInfiniteHits(Hits);
