import React, {useState} from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';

import ApiHelper from 'utils/api_helper';
import {useLanguageChange} from 'utils/use_language_change';

import {ArticleScreen} from './screens/ArticleScreen/ArticleScreen';

export const Article = ({article}) => {
  const [record, setRecord] = useState(article);

  useLanguageChange(() => {
    ApiHelper.get(`/articles/${record.uid}.json`).then((res) => {
      setRecord(res.response);
    });
  });

  return (
    <Router>
      <Switch>
        <Route path="/articles/:slug">
          <ArticleScreen article={record} />
        </Route>
      </Switch>
    </Router>
  );
};
