import React, {useEffect} from 'react';
import {get} from 'lodash';

import ApiHelper from 'utils/api_helper';
import {handleError, clearValidationErrors} from 'utils/validation';
import {scrollToTop} from 'utils/scroll';
import {formatMoney} from 'utils/format_money';
import {getCountry} from 'utils/get_country';

import {CurrencySelectField} from 'shared/components/form/currency_select_field/currency_select_field';
import {CartItemScreen} from '../../../cart_item_screen/cart_item_screen';
import {OrderCardSummuryBlock} from '../../../order_card_summury_block/order_card_summury_block';

export const OrderCardsSection = ({
  cartItems,
  orders,
  setCartItems,
  setNotification,
  currency,
  onCurrencyChange,
  setIsLoading,
}) => {
  const sellCartItems = cartItems.filter((cartItem) => cartItem.is_sell);
  const fundingCartItems = cartItems.filter((cartItem) => cartItem.is_funding).map((cartItem) => (
    {...cartItem, order: orders.find((o) => get(o, 'order_items[0].project_id') === cartItem.project_id)}
  ));
  const sellOrder = orders.find((order) => order.order_items.find((orderItem) => sellCartItems.map((i) => i.project_id).includes(orderItem.project_id)));
  const shippingCountry = getCountry(get(sellOrder, 'order_items[0].delivery_country')).name;

  const getSellOrderCardItems = () => {
    let items = [
      ...((sellOrder?.order_items || []).filter((i) => i.discount).map((orderItem) => (
        {
          title: `Discount for “${orderItem.project_title}”`,
          value: `-${formatMoney({amount: orderItem.payment_discount_amount, currency: sellOrder.payment_currency})}`,
        }
      ))),
      {
        title: `Shipping to ${shippingCountry || '(please select country)'}`,
        value: formatMoney({amount: sellOrder?.total_payment_delivery_fee, currency: sellOrder?.payment_currency}),
      },
    ];
    if (sellOrder?.total_payment_tax) {
      items = [
        ...items,
        {
          title: `Tax (${get(sellOrder, 'order_items[0].tax_rate')}%)`,
          value: formatMoney({amount: sellOrder?.total_payment_tax, currency: sellOrder?.payment_currency}),
        },
      ];
    }

    return items;
  };

  const getFundingOrderCardItems = (cartItem) => {
    const shippingCountry = getCountry(get(cartItem, 'order.order_items[0].delivery_country')).name;
    let items = [
      ...((cartItem?.order?.order_items || []).filter((i) => i.discount).map((orderItem) => (
        {
          title: `Discount for “${orderItem.project_title}”`,
          value: `-${formatMoney({amount: orderItem.discount.amount, currency: cartItem.order.payment_currency})}`,
        }
      ))),
      {
        title: `Shipping to ${shippingCountry || '(please select country)'}`,
        value: formatMoney({amount: cartItem.order?.total_payment_delivery_fee, currency: cartItem.order?.payment_currency}),
      },
    ];

    if (cartItem.order?.total_payment_tax) {
      items = [
        ...items,
        {
          title: `Tax (${get(cartItem, 'order.order_items[0].tax_rate')}%)`,
          value: formatMoney({amount: cartItem.order?.total_payment_tax, currency: cartItem.order?.payment_currency}),
        },
      ];
    }

    return items;
  };

  const updateCartItems = (items) => {
    if (items.length === 0) return window.location.replace('/cart');

    clearValidationErrors(setNotification);
    const newErrors = {full_messages: []};
    items.forEach((cartItem) => {
      cartItem.errors.full_messages?.forEach((message) => {
        newErrors.full_messages.push(`${cartItem.project_title}: ${message}`);
      });
    });
    setCartItems(items);
    if (newErrors.full_messages.length) {
      handleError(newErrors, setNotification);
      scrollToTop();
    }
  };

  useEffect(() => {
    setIsLoading(true);
    ApiHelper.get('/api/v1/cart_items').then((res) => {
      const items = res.response.filter((cartItem) => cartItem.can_order && cartItem.state === 'active');
      if (items.length === 0) return window.location.replace('/cart');

      updateCartItems(items);
      setIsLoading(false);
    });
  }, []);

  return (
    <div className="c-order-info-section">
      {currency && (
        <CurrencySelectField
          className="c-order-info-section__currency-field"
          value={currency}
          onChange={onCurrencyChange}
        />
      )}
      {fundingCartItems.length > 0 && (
        <div className="pledge-section">
          <div className="pledge-section__title">Your pledge</div>
          <div className="pledge-section__description">
            <div className="pledge-section__description-item">
              <i className="c-pictogram c-pictogram--globe" />
              You’ll only be charged if the crowdfunding project reaches it’s goal.
            </div>
            <div className="pledge-section__description-item">
              <i className="c-pictogram c-pictogram--record-fill" />
              Records ship 2-3 months after that.
            </div>
            <div className="pledge-section__description-item">
              <i className="c-pictogram c-pictogram--login" />
              We’ll update you via email, and you can login any time to see what’s happening.
            </div>
          </div>
          <div className="pledge-section__orders">
            {fundingCartItems.map((cartItem, index) => {
              return (
                <div key={cartItem?.id || index}>
                  <CartItemScreen
                    theme="payment_details_screen"
                    key={cartItem.id}
                    cartItem={cartItem}
                    updateCartItems={updateCartItems}
                    setIsLoading={setIsLoading}
                    isReadonly
                  />
                  <OrderCardSummuryBlock
                    className="pledge-section__summury-item-block"
                    itemsList={getFundingOrderCardItems(cartItem)}
                    paymentCurrency={cartItem.order?.payment_currency}
                    paymentTotalAmount={cartItem.order?.total_payment_total_amount}
                    chargeDate={cartItem.charge_date}
                  />
                </div>
              );
            })}
          </div>
        </div>
      )}
      {sellCartItems?.length > 0 && (
        <div className="purchase-section">
          <div className="purchase-section__title">Your purchase</div>
          <div className="purchase-section__orders">
            {sellCartItems.map((cartItem) => (
              <CartItemScreen
                theme="payment_details_screen"
                cartItem={cartItem}
                updateCartItems={updateCartItems}
                setIsLoading={setIsLoading}
                key={cartItem.id}
                isReadonly
              />
            ))}
            <OrderCardSummuryBlock
              className="purchase-section__summury-item-block"
              itemsList={getSellOrderCardItems()}
              paymentCurrency={sellOrder?.payment_currency}
              paymentTotalAmount={sellOrder?.total_payment_total_amount}
              chargeDate={new Date()}
            />
          </div>
        </div>
      )}
    </div>
  );
};
