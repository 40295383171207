// @flow
import React from 'react';
import styled from 'styled-components';
import {RichText} from 'prismic-reactjs';
import {StyledArticleMaxWidthContainer} from 'prismic/shared/styles/layout';
import {cssSharedBgImage} from '../../../StoriesScreen/components/StoryPreview/StoryPreview';
import {breakpointDesktopOnly, breakpointTabletOnly} from 'prismic/shared/styles/responsive';
import {
  cssExtraHugeTextSize,
  cssLargePlusPlusText,
  cssNormalTextLarge,
  cssSmallText,
  cssTinyPlusText,
} from 'prismic/shared/styles/typography';
import {
  getArticleIntroText,
  getArticleIntroTitle,
  getArticleIntroImageUrl,
} from 'prismic/shared/cms/article';

const StyledContainer = styled.header`
  ${cssSharedBgImage};
  position: relative;
  background-image: url(${props => props.image || ''});
  border: 2px solid #000;
  margin: 0px 40px 60px;
  max-width: 1440px;
  &:after {
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 0;
    display: block;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
  }

  ${breakpointTabletOnly} {
    margin: 0px 15px 40px;
  }
`;

const StyledContent = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: #ffffff;
  height: 680px;
  padding-bottom: 20px;

  ${breakpointTabletOnly} {
    height: unset;
    min-height: 412.5px;
    padding: 0 20px;
  }
`;

const StyledTagsContainer = styled.div`
  display: flex;

  ${breakpointDesktopOnly} {
    margin-bottom: 10px;
  }

  ${breakpointTabletOnly} {
    margin-left: -10px;
    margin-bottom: 37px;
    margin-top: -60px;
  }
`;

const StyledTag = styled.div`
  ${cssSmallText};
  background-color: #ffffff;
  color: #000000;
  text-transform: uppercase;
  padding: 9px 10px;
  border: 2px solid #000000;

  &:not(:first-child) {
    margin-left: 20px;
  }

  ${breakpointTabletOnly} {
    ${cssTinyPlusText};
    padding: 2px 4px;
  }
`;

const StyledHeading = styled.h1`
  ${cssExtraHugeTextSize};
  text-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.4);
  max-width: 825px;

  ${breakpointTabletOnly} {
    ${cssLargePlusPlusText};
    max-width: 317px;
  }
`;

const StyledIntroText = styled.div`
display: flex;
justify-content: center;
align-items: center;
text-align: center;
margin-bottom: 100px;

h3 {
  ${cssNormalTextLarge};
  max-width: 900px;
}

${breakpointTabletOnly} {
  margin-bottom: 60px;
  padding: 0px 15px;
}
`

type Props = {
  tags: string[],
  title: any,
  image: string,
  text: string,
};

export const ArticleHeader = ({tags, image, title, text, slice}: Props) => {

  return (
    <div wovn-ignore="">
      <StyledArticleMaxWidthContainer>
        <StyledContainer image={getArticleIntroImageUrl(slice) || image}>
          <StyledContent>
            <StyledTagsContainer>
              {tags?.map((tag, index) => (
                <StyledTag key={`${tag}_${index}`}>{tag}</StyledTag>
              ))}
            </StyledTagsContainer>
            <StyledHeading>{RichText.asText(getArticleIntroTitle(slice) || title)}</StyledHeading>
          </StyledContent>
        </StyledContainer>
        <StyledIntroText><h3>{RichText.render(getArticleIntroText(slice) || text)}</h3></StyledIntroText>
      </StyledArticleMaxWidthContainer>
    </div>
  )
};
