import React, {
  useEffect,
  useState,
} from 'react';
import ApiHelper from 'utils/api_helper';
import {ProjectCard} from 'components/shared/project_cards/project';

export const DiscoverRecords = () => {
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [currentFilter, setCurrentFilter] = useState('latest');

  const fetchFilteredProjects = () => {
    ApiHelper.get(`/api/v1/projects/discover.json?filter=${currentFilter}`).then((res) => {
      setFilteredProjects(res.response.projects);
    });
  };

  useEffect(() => {
    fetchFilteredProjects();
  }, [currentFilter]);

  return (
    <section className="p-discover__section p-discover__section--records">
      <div className="p-section-header--discover">
        <div className="p-section-header__title">
          <h2>Records & Cassettes</h2>
          <nav>
            <ul>
              <li
                data-status={currentFilter == 'latest' ? 'current' : ''}
                onClick={() => setCurrentFilter('latest')}
              >
                <span>LATEST</span>
              </li>
              <li
                data-status={currentFilter == 'funding' ? 'current' : ''}
                onClick={() => setCurrentFilter('funding')}
              >
                <span>CROWDFUNDING</span>
              </li>
              <li
                data-status={currentFilter == 'preorder' ? 'current' : ''}
                onClick={() => setCurrentFilter('preorder')}
              >
                <span>PREORDER</span>
              </li>
              <li
                data-status={currentFilter == 'in_stock' ? 'current' : ''}
                onClick={() => setCurrentFilter('in_stock')}
              >
                <span>IN STOCK</span>
              </li>
            </ul>
          </nav>
        </div>
        <div className="p-section-header__button--pc">
          <a className="c-button c-button--black" href="/projects">
            BROWSE ALL PROJECTS
          </a>
        </div>
      </div>
      <div className="c-project-grid">
        {filteredProjects.map((project, index) => {
          return <ProjectCard project={project} key={index} />;
        })}
      </div>

      <div className="p-section-header__button--tablet-control c-button-grid--center">
        <a className="c-button--large c-button--black" href="/projects">
          BROWSE ALL RECORDS
        </a>
      </div>
    </section>
  );
};
