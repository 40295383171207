import React, {useState, useEffect} from 'react';
import Prismic from '@prismicio/client';
import {prismicClient} from 'prismic/shared/cms/prismic';
import {useLanguageChange} from 'utils/use_language_change';
import {Releases} from './components/releases_list';
import {CuratedFeaturedFallback} from '../components/curated_featured/curated_featured_fallback';

export const ReleaseOfTheWeekPage = () => {
  const [pageContent, setPageContent] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const query = Prismic.Predicates.at('document.type', 'release_of_the_week_page');

  const queryPrismic = async (options) => {
    setIsLoading(true);
    const locales = window.constants.prismic_locales;
    const response = await prismicClient.query(query, {
      lang: locales[options.locale || window.constants.locale || 'en'],
    });
    response && setPageContent(response.results[0].data);
  };

  const fetchPageContent = async (options = {}) => {
    try {
      return await queryPrismic(options);
    } catch (err) {
      console.error(err);
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchPageContent();
  }, []);

  useLanguageChange((event) => {
    const locale = event.detail.locale;
    fetchPageContent({locale});
  });

  if (isError) return <CuratedFeaturedFallback />;
  if (isLoading) return <div />;

  return (
    <div className="c-curated-releases">
      <div className="c-curated-releases__banner">
        <h3 wovn-ignore="">{pageContent.rotw_heading}</h3>
      </div>
      <Releases linkLabel={pageContent.link_label} />
    </div>
  );
};
