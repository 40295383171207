import React, {useState, useEffect} from 'react';
import Prismic from '@prismicio/client';
import {useLanguageChange} from 'utils/use_language_change';
import {prismicClient} from 'prismic/shared/cms/prismic';
import {RichText} from 'prismic-reactjs';
import {FilterBar} from '../../components/filter_bar/filter_bar';
import {CuratedFeaturedFallback} from '../../components/curated_featured/curated_featured_fallback';
import {toolkitFilters} from '../../constants/constants';

export const ArtistToolkitHeader = ({currentFilter, currentFilterHandler}) => {
  const [headerContent, setHeaderContent] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const query = Prismic.Predicates.at('document.type', 'artist_toolkit_page');

  const queryPrismic = async (options) => {
    setIsLoading(true);
    const locales = window.constants.prismic_locales;
    const response = await prismicClient.query(query, {
      lang: locales[options.locale || window.constants.locale || 'en'],
    });
    response && setHeaderContent(response.results[0].data);
  };

  const fetchArticles = async (options = {}) => {
    try {
      return await queryPrismic(options);
    } catch (err) {
      console.err(err);
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(fetchArticles, []);

  useLanguageChange((event) => {
    const locale = event.detail.locale;
    fetchArticles({locale});
  });

  if (isError) return <CuratedFeaturedFallback />;
  if (isLoading) return <div />;
  return (
    <div>
      <div className="c-artist-toolkit-header">
        <h1 wovn-ignore="">{RichText.asText(headerContent.artist_toolkit_heading)}</h1>
        <p wovn-ignore="">{headerContent.artist_toolkit_intro}</p>
      </div>
      <FilterBar
        theme="orange"
        filters={toolkitFilters}
        currentFilter={currentFilter}
        currentFilterHandler={currentFilterHandler}
      />
    </div>
  );
};
