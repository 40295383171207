import React from 'react';
import {RichText} from 'prismic-reactjs';

import {NewLabel} from 'shared/components/labels/new_label/new_label';
import {PublicPagesHeading} from 'shared/components/content/public_pages_heading/public_pages_heading';
import {ReactButton} from 'shared/components/buttons/react_button/react_button';

export const CommunitySection = ({
  id,
  title,
  label,
  description,
  link,
}) => {
  return (
    <div
      className="c-community-section"
      id={id}
    >
      <div className="c-community-section__container">
        <NewLabel className="c-community-section__label" title={label} />
        <PublicPagesHeading
          className="c-community-section__title"
          theme="middle-size"
          headingTagValue={3}
        >
          {title}
        </PublicPagesHeading>
        <p wovn-ignore="" className="c-community-section__description">{RichText.asText(description)}</p>
        <ReactButton
          wovn-ignore=""
          className="c-community-section__button"
          text={link.name}
          type="link"
          link={link.url}
          theme="primary-white-border"
        />
      </div>
    </div>
  );
};
