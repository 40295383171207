import React from 'react';
import classNames from 'classnames';

export const HalfFieldsContainer = ({
  className,
  children,
}) => {
  return (
    <div className={classNames('c-half-fields-container', {
      [className]: className,
    })}
    >
      {children}
    </div>

  );
};
