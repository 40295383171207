import React from 'react';
import classNames from 'classnames';

export const Preloader = ({
  className,
  theme,
  coverBlock,
  message,
  error,
}) => {
  return (
    <div className={classNames('c-preloader', {
      [className]: className,
      'c-preloader--cover-block': coverBlock,

      // Themes
      'c-preloader--size-70px': theme == 'size-70px',
    })}
    >
      {!error && <div className="c-preloader__item" />}
      {error && <div className="c-preloader__error-message">{error}</div>}
      {message && !error && <div className="c-preloader__message">{message}</div>}
    </div>
  );
};
