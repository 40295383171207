import React from 'react';
import classNames from 'classnames';
import {ComponentsGrid} from '../components_grid/components_grid';

export const GroupSection = ({
  className,
  children,
  type,
  title,
}) => {
  return (
    <div className={classNames('group-section', {
      [className]: className,
    })}
    >
      {title && <div className="group-section__title">{title}</div>}
      <ComponentsGrid type={type}>{children}</ComponentsGrid>
    </div>
  );
};
