import React, {useRef, useState} from 'react';
import classNames from 'classnames';

import {useClickOutside} from 'utils/use_click_outside';

export const SubMenu = ({
  menuItem,
  menuId,
  activeId,
  setActiveId,
  navMenuRef,
}) => {
  const [isOnSubmenuItemPage, setIsOnSubmenuItemPage] = useState(false);
  const navMenuElement = useRef();
  const currentPageUrl = window.location.pathname;
  useClickOutside(
    [navMenuElement, navMenuRef],
    activeId === menuId,
    () => setActiveId(null),
  );
  return (
    <div className="navigation-submenu" ref={navMenuElement}>
      <div
        className={classNames('navigation-submenu__button',
          {'is-active': menuId === activeId},
          {'is-current': isOnSubmenuItemPage},
        )}
        onClick={() => setActiveId(menuId === activeId ? null : menuId)}
      >
        {menuItem.name}
        <i className="c-pictogram c-pictogram--chevron-down" />
      </div>
      <ul className={classNames('navigation-submenu__list', {'is-active': menuId === activeId})}>
        {menuItem.subMenu.map((subMenuItem, index) => {
          const isOnPage = currentPageUrl === subMenuItem.path;
          isOnPage && !isOnSubmenuItemPage && setIsOnSubmenuItemPage(true);
          return (
            <li className="navigation-submenu__item" key={index}>
              <a
                className={classNames('navigation-submenu__link', {'is-current': isOnPage})}
                href={subMenuItem.path}
                target={subMenuItem.target}
              >
                {subMenuItem.name}
              </a>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
