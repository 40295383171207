import React, {useEffect} from 'react';

import {RichText} from 'prismic-reactjs';

export const ArtistsOnQrates = ({artistsData}) => {
  useEffect(() => {
    if (artistsData?.length) {
      const slider = () => new window.Swiper(
        '.p-on-artists__slider',
        {
          slideClass: 'p-on-artists__slide',
          slideActiveClass: 'p-on-artists__slide--active',
          wrapperClass: 'p-on-artists__wrapper',
          loop: true,
          speed: 600,
          effect: 'fade',
          simulateTouch: false,
          pagination: {
            type: 'bullets',
            clickable: true,
            el: '.p-on-artists__pagination',
            bulletClass: 'p-on-artists__pagination-item',
            bulletActiveClass: 'current',
            renderBullet: (index, className) => {
              return `<span class='${className}'>${(index + 1)}</span>`;
            },
          },
        },
      );
      slider();
    }
  }, [artistsData.length]);

  return (
    <div className="p-on-artists__slider">
      <div className="p-on-artists__wrapper">
        {artistsData.map((artist, index) => (
          <div className="p-on-artists__slide" key={index}>
            <div className="p-on-artists c-column--table" style={{backgroundColor: artist.background_color}}>
              <div className="c-column--table__left c-column__card p-on-artists__column-card">
                <div className="c-label--pages--white">ARTISTS ON QRATES</div>
                <div className="c-column__card-contents">
                  <p className="c-column__card-explanation p-on-artists__text">{RichText.asText(artist.description)}</p>
                  <div className="c-column__card-credit p-on-artists__artist">
                    —
                    {RichText.render(artist.signature)}
                  </div>
                </div>
                <div className="p-on-artists__pagination c-pagination" />
              </div>
              <a href={artist.cover_url.url} className="c-column--table__right p-on-artists__column-image">
                <img src={artist.cover_image.url} className="c-column__image-window--sp-control" title="artist_cover" />
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
