import React from 'react';

import QratesLogoImg from 'images/common/logos/qrates-logo-footer.svg';
import {LanguageSelector} from '../../form/language_selector/language_selector';
import {publicPagesRoutes, externalLinks} from '../../../../modules/routes';

export const PublicFooter = ({currentUser}) => {
  return (
    <footer className="c-public-footer" id="footer">
      <div className="c-public-footer__links">
        <ul className="c-public-footer__column">
          <li className="c-public-footer__link--header">FOR ARTISTS</li>
          <li className="c-public-footer__link">
            <a href={publicPagesRoutes.forArtistsPagePath}>For Artists</a>
          </li>
          <li className="c-public-footer__link">
            <a href={publicPagesRoutes.howItWorksPagePath}>How it Works</a>
          </li>
          <li className="c-public-footer__link">
            <a href={publicPagesRoutes.artistToolkitPagePath}>Artist Toolkit</a>
          </li>
          <li className="c-public-footer__link">
            <a href={externalLinks.referalProgramPagePath} target="_blank">Referral Program</a>
          </li>
        </ul>
        <ul className="c-public-footer__column">
          <li className="c-public-footer__link--header">FOR FANS</li>
          <li className="c-public-footer__link">
            <a href={publicPagesRoutes.forFansPagePath}>For Fans</a>
          </li>
          <li className="c-public-footer__link">
            <a href={publicPagesRoutes.projectsPagePath({product_format: 'vinyl'})}>Records</a>
          </li>
          <li className="c-public-footer__link">
            <a href={publicPagesRoutes.projectsPagePath({product_format: 'cassette'})}>Cassettes</a>
          </li>
          <li className="c-public-footer__link">
            <a href={publicPagesRoutes.storiesPagePath}>Stories</a>
          </li>
          <li className="c-public-footer__link">
            <a href={publicPagesRoutes.qratesCuratedPagePath}>Qrates Curated</a>
          </li>
        </ul>
        <ul className="c-public-footer__column">
          <li className="c-public-footer__link--header">OUR COMPANY</li>
          <li className="c-public-footer__link">
            <a className="f-adjust-ja" href={publicPagesRoutes.aboutQratesPagePath}>About Qrates</a>
          </li>
          <li className="c-public-footer__link">
            <a className="f-adjust-ja" href={publicPagesRoutes.careersPagePath}>Careers</a>
          </li>
          <li className="c-public-footer__link">
            <a className="f-adjust-ja" href={publicPagesRoutes.partnersPagePath}>Partners</a>
          </li>
        </ul>
        <ul className="c-public-footer__column">
          <li className="c-public-footer__link--header">HELP</li>
          <li className="c-public-footer__link">
            <a className="f-adjust-ja" href={externalLinks.supportPagesPath} target="_blank">Support Center</a>
          </li>
          <li className="c-public-footer__link">
            <a className="f-adjust-ja" href={publicPagesRoutes.contactPagePath}>Contact Us</a>
          </li>
          <li className="c-public-footer__link">
            <a className="f-adjust-ja" href={externalLinks.returnsPagePath} target="_blank">Returns</a>
          </li>
          <li className="c-public-footer__link">
            <a className="f-adjust-ja" href={externalLinks.shippingPagePath} target="_blank">Shipping</a>
          </li>
        </ul>
      </div>
      <div className="c-public-footer__sns u-hidden-desktop-only">
        <a href={externalLinks.qratesFacebookPath} target="_blank">
          <i className="c-pictogram c-pictogram--facebook" />
        </a>
        <a href={externalLinks.qratesInstagramPath} target="_blank">
          <i className="c-pictogram c-pictogram--instagram-fill" />
        </a>
        <a href={externalLinks.qratesTwitterPath} target="_blank">
          <i className="c-pictogram c-pictogram--twitter-fill" />
        </a>
      </div>
      <div className="c-public-footer__bottom">
        <div className="c-public-footer__logo">
          <a href={publicPagesRoutes.generalPagePath}>
            <img src={QratesLogoImg} alt="Qrates" />
          </a>
        </div>
        <div className="c-public-footer__info">
          <div className="c-public-footer__language">
            <LanguageSelector className="c-public-footer__language-selector" currentUser={currentUser} />
          </div>
          <div className="c-public-footer__sns u-hidden-tablet">
            <a href={externalLinks.qratesFacebookPath} target="_blank">
              <i className="c-pictogram c-pictogram--facebook" />
            </a>
            <a href={externalLinks.qratesInstagramPath} target="_blank">
              <i className="c-pictogram c-pictogram--instagram-fill" />
            </a>
            <a href={externalLinks.qratesTwitterPath} target="_blank">
              <i className="c-pictogram c-pictogram--twitter-fill" />
            </a>
          </div>
          <ul className="c-public-footer__legal-nav">
            <li><a className="f-adjust-ja" href={publicPagesRoutes.termsPagePath}>Terms of Service</a></li>
            <li><a className="f-adjust-ja" href={publicPagesRoutes.privacyPagePath}>Privacy Policy</a></li>
            <li><a className="f-adjust-ja" href={publicPagesRoutes.legalInformationPagePath}>Legal Information</a></li>
            <li className="u-hidden-tablet">
              <small className="c-public-footer__small">&copy; TDMS Inc.</small>
            </li>
          </ul>
          <small className="c-public-footer__small u-hidden-desktop-only">&copy; TDMS Inc.</small>
        </div>
      </div>
    </footer>
  );
};
