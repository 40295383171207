import React, {
  useEffect,
  useRef,
  forwardRef,
} from 'react';
import classNames from 'classnames';

import {useClickOutside} from 'utils/use_click_outside';
import {useWindowDimensions} from 'utils/window_dimensions';

import {ShareIcon} from 'shared/components/share/share_icon/share_icon';

export const ShareIconsBlock = forwardRef(({
  className,
  data,
  disableScrollOnParent,
  toggleMenu,
  isMenuOpen,
}, ref) => {
  const dropdownMenu = useRef();
  const container = useRef();
  const {width} = useWindowDimensions();
  const isMobile = width <= 768;

  useEffect(() => {
    if (disableScrollOnParent) {
      ref.current.style.overflowX = isMenuOpen ? 'hidden' : 'auto';
    }
  }, [isMenuOpen]);

  useClickOutside(
    [dropdownMenu],
    isMenuOpen && !isMobile,
    (e) => toggleMenu(e),
  );
  return (
    <div
      className={classNames('c-share-icons-block', {
        [className]: className,
      })}
      ref={container}
    >
      {!isMobile && (
        <>
          <div className="c-share-icons-block__title">SHARE</div>
          <div className="c-share-icons-block__icons">
            <ShareIcon type="facebook" data={data.facebook} />
            <ShareIcon type="twitter" data={data.twitter} />
            <ShareIcon type="widget" data={data.widget} />
            <ShareIcon type="qrcode" data={data.qrcode} />
          </div>
        </>
      )}
      {isMobile && (
        <>
          <div
            className={classNames('c-share-icons-block__name', {
              active: isMenuOpen,
            })}
            onClick={toggleMenu}
          >
            SHARE
          </div>
        </>
      )}
    </div>
  );
});
