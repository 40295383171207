import React, {useState} from 'react';
import classNames from 'classnames';
import {BrowserRouter, Switch, Route} from 'react-router-dom';

import {NavMenu} from './components/nav_menu/nav_menu';
import {Header} from './components/header/header';
import {MainScreen} from './components/screens/main_screen/main_screen';
import {DesignPanelRoutes} from './modules/routes/routes';
import {ColorsScreen} from './components/screens/colors_screen/colors_screen';
import {IconsScreen} from './components/screens/icons_screen/icons_screen';
import {TagsScreen} from './components/screens/tags_screen/tags_screen';
import {LabelsScreen} from './components/screens/labels_screen/labels_screen';
import {FormsScreen} from './components/screens/forms_screen/forms_screen';
import {ButtonsScreen} from './components/screens/buttons_screen/buttons_screen';
import {FontsScreen} from './components/screens/fonts_screen/fonts_screen';
import {ModalsScreen} from './components/screens/modals_screen/modals_screen';

export const DesignPanel = ({onClosePanel}) => {
  const [isNavActive, setIsNavActive] = useState(false);

  const navigationList = [
    {
      name: 'Style',
      items: [
        {name: 'Color System', path: 'colors'},
        {name: 'Fonts', path: 'fonts'},
        {name: 'Icons', path: 'icons'},
      ],
    },
    {
      name: 'Elements',
      items: [
        {name: 'Forms', path: 'forms'},
        {name: 'Buttons', path: 'buttons'},
        {name: 'Tags', path: 'tags'},
        {name: 'Labels', path: 'labels'},
        {name: 'Modals', path: 'modals'},
      ],
    },
  ];

  return (
    <div className="c-design-panel">
      <BrowserRouter>
        <Header className="c-design-panel__header" onClosePanel={onClosePanel} />
        <NavMenu
          className="c-design-panel__menu"
          navigationList={navigationList}
          isNavActive={isNavActive}
          setIsNavActive={setIsNavActive}
        />
        <div className={classNames('c-design-panel__content',
          {'is-active': isNavActive})}
        >
          <Switch>
            <Route exact path={DesignPanelRoutes.designPath()}>
              <MainScreen navigationList={navigationList} onButtonClick={() => setIsNavActive(true)} />
            </Route>
            <Route path={DesignPanelRoutes.designPath('colors')}><ColorsScreen /></Route>
            <Route path={DesignPanelRoutes.designPath('icons')}><IconsScreen /></Route>
            <Route path={DesignPanelRoutes.designPath('tags')}><TagsScreen /></Route>
            <Route path={DesignPanelRoutes.designPath('labels')}><LabelsScreen /></Route>
            <Route path={DesignPanelRoutes.designPath('forms')}><FormsScreen /></Route>
            <Route path={DesignPanelRoutes.designPath('buttons')}><ButtonsScreen /></Route>
            <Route path={DesignPanelRoutes.designPath('fonts')}><FontsScreen /></Route>
            <Route path={DesignPanelRoutes.designPath('modals')}><ModalsScreen /></Route>
          </Switch>
        </div>
      </BrowserRouter>
    </div>
  );
};
