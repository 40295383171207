import React from 'react';
import {
  render,
  screen,
  fireEvent,
  waitFor,
} from '@testing-library/react';

import {PrivacyPage} from '../privacy_page';

const data = {
  title: [{text: 'Privacy Title'}],
  description: [{text: 'Privacy Description', type: 'paragraph', spans: []}],
  sections: [
    {heading: [{text: 'Privacy1 Heading'}], body: [{text: 'Privacy1 Body', type: 'paragraph', spans: []}]},
    {heading: [{text: 'Privacy2 Heading'}], body: [{text: 'Privacy2 Body', type: 'paragraph', spans: []}]},
  ],
};
let mockData = {
  title: [{text: 'Privacy ES Title'}],
  description: [{text: 'Privacy ES Description', type: 'paragraph', spans: []}],
  sections: [
    {heading: [{text: 'Privacy1 ES Heading'}], body: [{text: 'Privacy1 ES Body', type: 'paragraph', spans: []}]},
    {heading: [{text: 'Privacy2 ES Heading'}], body: [{text: 'Privacy2 ES Body', type: 'paragraph', spans: []}]},
  ],
};

jest.mock('prismic/shared/cms/prismic', () => {
  return {
    prismicClient: {
      query: () => Promise.resolve({results: [{data: mockData}]}),
    },
  };
});

it('renders correct data', async () => {
  render(<PrivacyPage data={data} />);

  expect(screen.getByText('Privacy Title')).toBeInTheDocument();
  expect(screen.getByText('Privacy Description')).toBeInTheDocument();
  expect(screen.getByText('Privacy1 Heading')).toBeInTheDocument();
  expect(screen.getByText('Privacy1 Body')).toBeInTheDocument();
  expect(screen.getByText('Privacy2 Heading')).toBeInTheDocument();
  expect(screen.getByText('Privacy2 Body')).toBeInTheDocument();
});

describe('with languagechange and data for the selected language', () => {
  const event = new CustomEvent('languagechange', screen, {detail: {locale: 'es'}});

  it('changes data', async () => {
    render(<PrivacyPage data={data} />);

    expect(screen.getByText('Privacy Title')).toBeInTheDocument();
    expect(screen.getByText('Privacy Description')).toBeInTheDocument();
    expect(screen.getByText('Privacy1 Heading')).toBeInTheDocument();
    expect(screen.getByText('Privacy1 Body')).toBeInTheDocument();
    expect(screen.getByText('Privacy2 Heading')).toBeInTheDocument();
    expect(screen.getByText('Privacy2 Body')).toBeInTheDocument();

    fireEvent(window, event);

    await waitFor(() => {
      expect(screen.getByText('Privacy ES Title')).toBeInTheDocument();
    });

    expect(screen.getByText('Privacy ES Description')).toBeInTheDocument();
    expect(screen.getByText('Privacy1 ES Heading')).toBeInTheDocument();
    expect(screen.getByText('Privacy1 ES Body')).toBeInTheDocument();
    expect(screen.getByText('Privacy2 ES Heading')).toBeInTheDocument();
    expect(screen.getByText('Privacy2 ES Body')).toBeInTheDocument();
  });
});

describe('with languagechange and without data for the selected language', () => {
  const event = new CustomEvent('languagechange', screen, {detail: {locale: 'es'}});

  beforeEach(() => {
    mockData = null;
  });

  it('does not change the data', async () => {
    render(<PrivacyPage data={data} />);

    expect(screen.getByText('Privacy Title')).toBeInTheDocument();
    expect(screen.getByText('Privacy Description')).toBeInTheDocument();
    expect(screen.getByText('Privacy1 Heading')).toBeInTheDocument();
    expect(screen.getByText('Privacy1 Body')).toBeInTheDocument();
    expect(screen.getByText('Privacy2 Heading')).toBeInTheDocument();
    expect(screen.getByText('Privacy2 Body')).toBeInTheDocument();

    fireEvent(window, event);

    await waitFor(() => {
      expect(screen.getByText('Privacy Title')).toBeInTheDocument();
    });

    expect(screen.getByText('Privacy Description')).toBeInTheDocument();
    expect(screen.getByText('Privacy1 Heading')).toBeInTheDocument();
    expect(screen.getByText('Privacy1 Body')).toBeInTheDocument();
    expect(screen.getByText('Privacy2 Heading')).toBeInTheDocument();
    expect(screen.getByText('Privacy2 Body')).toBeInTheDocument();
  });
});
