import React, {
  useState,
  useEffect,
  useRef,
} from 'react';
import {ProjectMainPlayer} from '../players/project_main_player';
import {ShareIconsBlock} from '../share/share_icons_block/share_icons_block';
import {ShareIconsDropdown} from '../share/share_icons_dropdown/share_icons_dropdown';

export const ProjectNavigation = ({
  trackCount,
  commenting,
  tracks,
  className,
  data,
}) => {
  const [isSticky, setIsSticky] = useState(false);
  const [style, setStyle] = useState({});
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const projectNavRef = useRef(null);
  const scrollPositionRef = useRef(0);
  const initialOffsetRef = useRef(undefined);
  const containerRef = useRef();

  const toggleMenu = (e) => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleScroll = (e) => {
    const scrollYPosition = document.body.scrollTop;
    scrollPositionRef.current = scrollYPosition;
    const topPositionGreaterThanInitialOffset = scrollPositionRef.current >= initialOffsetRef.current;
    const currentOffset = projectNavRef.current.getBoundingClientRect();
    setIsSticky(currentOffset.y <= 0 && topPositionGreaterThanInitialOffset);
  };

  useEffect(() => {
    initialOffsetRef.current = projectNavRef.current.getBoundingClientRect().y;
    document.body.addEventListener('scroll', handleScroll);

    return () => {
      document.body.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (isSticky) {
      setStyle({
        position: 'fixed',
        top: 0,
        zIndex: 9999,
        width: '100%',
        overflowY: 'scroll',
      });
    } else {
      setStyle({});
    }
  }, [isSticky]);

  const NavigationMenu = () => {
    const activeSectionRef = useRef(null);

    const handleClick = (elementId) => {
      const selectedElement = document.getElementById(elementId);
      activeSectionRef.current = selectedElement;

      activeSectionRef.current?.scrollIntoView({behavior: 'smooth'});
    };

    return (
      <ul className="c-project-navigation__menu">
        <li>
          <a onClick={() => handleClick('project_detail_section')}>
            <span>ABOUT</span>
          </a>
        </li>
        {trackCount > 0 && (
          <li>
            <a onClick={() => handleClick('tracks_section')}>
              <span>TRACKS</span>
            </a>
          </li>
        )}
        <li>
          <a onClick={() => handleClick('images_section')}>
            <span>PRODUCT IMAGES</span>
          </a>
        </li>
        {commenting && (
          <li>
            <a onClick={() => handleClick('comment_section')}>
              <span>COMMENTS</span>
            </a>
          </li>
        )}
        <li>
          <a onClick={() => handleClick('profile_section')}>
            <span>PROFILE</span>
          </a>
        </li>
      </ul>
    );
  };

  return (
    <div id="navWrapper" ref={projectNavRef} style={style}>
      <ProjectMainPlayer tracks={tracks} />
      <div className="c-project-navigation__row">
        <div className="c-project-navigation__inner" ref={containerRef}>
          <NavigationMenu />
          <ShareIconsBlock
            ref={containerRef}
            className={className}
            data={data}
            disableScrollOnParent
            isMenuOpen={isMenuOpen}
            toggleMenu={toggleMenu}
          />
        </div>
        <ShareIconsDropdown
          isMenuOpen={isMenuOpen}
          data={data}
          disableScrollOnParent
          isSticky={isSticky}
        />
      </div>
    </div>
  );
};
