export const scrollAnimate = (element, to, direction = 'top', duration = 700) => {
  const scrollElement = direction === 'top' ? element.scrollTop : element.scrollLeft;
  const change = to - scrollElement;
  const startDate = +new Date();

  const easeInOutQuad = function (currentTime, startValue, changeInValue, duration) {
    currentTime /= duration / 2;
    if (currentTime < 1) return (changeInValue / 2) * currentTime * currentTime + startValue;
    currentTime--;
    return (-changeInValue / 2) * (currentTime * (currentTime - 2) - 1) + startValue;
  };

  const animateScroll = function () {
    const currentDate = +new Date();
    const currentTime = currentDate - startDate;
    direction === 'top' ? element.scrollTop : element.scrollLeft = parseInt(easeInOutQuad(currentTime, scrollElement, change, duration));
    if (currentTime < duration) {
      requestAnimationFrame(animateScroll);
    } else {
      direction === 'top' ? element.scrollTop : element.scrollLeft = to;
    }
  };

  animateScroll();
};
