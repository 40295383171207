import React from 'react';
import classNames from 'classnames';

export const ArtistCard = ({
  className,
  subtitle,
  title,
  link,
  avatarImage,
  theme,
  artists,
}) => {
  return (
    <div
      className={classNames('c-artist-card', {[className]: className})}
      wovn-ignore=""
    >
      <div className="c-artist-card__cover-image">
        <span><img src={avatarImage} alt="cover" /></span>
      </div>
      {title && <h3 className="c-artist-card__title">{title}</h3>}
      {subtitle && <p className="c-artist-card__subtitle">{subtitle}</p>}
      {link && <a className="c-artist-card__link" href={link}> </a>}
    </div>
  );
};
