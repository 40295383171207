import React, {useState} from 'react';

import ApiHelper from 'utils/api_helper';
import {useLanguageChange} from 'utils/use_language_change';

export const PageArtistSection = ({page, artist}) => {
  const [record, setRecord] = useState(artist);

  const pagesData = {
    crowdfunding: {
      label: 'CROWDFUNDING',
      title: 'Take pledges.',
      description: 'No upfront costs or unwanted inventory. Get your products made without any risks through crowdfunded pledges. ↓',
    },
    press_and_sell: {
      label: 'MAKE & SELL',
      title: 'Take pre-orders.',
      description: "Get your records pressed, sooner. Take pre-orders while they're in production. We’ll take care of the rest. ↓",
    },
  };

  useLanguageChange(() => {
    ApiHelper.get(`/why/${page}.json`).then((res) => {
      setRecord(res.response.page_artist);
    });
  });

  return (
    <div className="p-why__top p-top--grid">
      <div className="p-top__left">
        <h1 className="c-label--pages--white">{pagesData[page]?.label}</h1>
        <h2 className="p-top__heading"><span className="f-adjust-ja">{pagesData[page]?.title}</span></h2>
        <p className="p-top__explanation"><span className="f-adjust-ja">{pagesData[page]?.description}</span></p>
      </div>
      <div className="p-top__right">
        <div className="p-top__image--three">
          <img src={record.image_1} title="image_1" />
          <div className="p-top__image--four">
            <img src={record.image_2} title="image_2" />
          </div>
        </div>
        <div className="c-vertical-text">
          <a href={record.artist_url}>{record.artist_name}</a>
          IS ON QRATES
        </div>
      </div>
    </div>
  );
};
