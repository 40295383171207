import {Record} from 'immutable';

const _Record = Record({
  id: null,
  first_name: '',
  last_name: '',
  email: '',
  company: '',
  building: '',
  street: '',
  city: '',
  prefecture: '',
  country: '',
  postal_code: '',
  phone_number: '',
  quantity: 0,
  delivery_service: '',
  currency: '',
  tracking_url: null,
  tracking_number: '',
  address: '',
  delivery_fee: 0,
  handling_fee: 0,
  purpose: null,
  index: 0,
}, 'PressDeliveryRecord');

export class ProductDelivery extends _Record {
  static fromJSON(json) {
    if (json) {
      return new ProductDelivery(json);
    }

    return new ProductDelivery();
  }
}
