import React, {useState} from 'react';
import classNames from 'classnames';
import Modal from 'react-modal';
import queryString from 'query-string';
import {formatDate} from 'utils/date';
import {Repayment} from './modals/repayment';

export const PaymentDetails = ({order}) => {
  const appElement = document.getElementById('root');
  const queryParams = queryString.parse(location.search);
  const [orderState, setOrder] = useState(order);
  const [isRepaymentModalDisplayed, setIsRepaymentModalDisplayed] = useState(orderState.can_repayment && queryParams.repayment);

  const StatusDate = () => {
    const displayDate = ({date, error}) => {
      return (
        <div
          className={classNames('p-purchases__row-content p-purchases__row-content--inline', {'is-error': error})}
        >
          {`(${date})`}
        </div>
      );
    };

    if (['shipped', 'delivered'].includes(orderState.state) && orderState.shipped_at_str) {
      return displayDate({date: orderState.shipped_at_str});
    } else if (orderState.payment_state === 'captured' && orderState.captured_at_str) {
      return displayDate({date: orderState.captured_at_str});
    } else if (orderState.payment_state === 'failed' && orderState.failed_at_str) {
      return displayDate({date: orderState.failed_at_str, error: true});
    } else {
      return null;
    }
  };

  const InvoicePaymentState = () => {
    const paymentStateLabel = () => {
      switch (orderState.payment_state) {
        case 'captured':
          return 'Paid';
        case 'issued':
          return 'Issued';
        default:
          return 'Not paid';
      }
    };

    if (orderState.invoice_date) {
      return (
        <>
          <div className={classNames('p-purchases__row-content p-purchases__row-content--inline', {'is-error': orderState.payment_state == 'failed'})}>
            {paymentStateLabel()}
          </div>
          <div className={classNames('p-purchases__row-content p-purchases__row-content--inline', {'is-error': orderState.payment_state == 'failed'})}>
            {formatDate(orderState.invoice_date, '%b %-d %Y')}
          </div>
        </>
      );
    } else {
      return (
        <div className="p-purchases__row-content">
          Not issued
        </div>
      );
    }
  };

  return (
    <div className="c-dashboard__section">
      <div className="p-purchases__details__heading">
        Payment details
      </div>
      <div className="c-grid--start p-purchases__row">
        <div className="c-grid--n1-d3">
          <div className="p-purchases__row-target">
            Payment method
          </div>
        </div>
        <div className="c-grid--n1-d3">
          <div className="p-purchases__row-content p-purchases__row-content--inline">
            {orderState.display_payment_method}
          </div>
          {orderState.payment_account && (
            <div className="p-purchases__row-content p-purchases__row-content--inline">
              {`(${orderState.payment_account})`}
            </div>
          )}
        </div>
      </div>
      <div className="c-grid--start p-purchases__row">
        <div className="c-grid--n1-d3">
          <div className="p-purchases__row-target">
            Status
          </div>
        </div>
        <div className="c-grid--n1-d3">
          {orderState.payment_method == 'invoice' && <InvoicePaymentState />}
          {orderState.payment_method != 'invoice' && orderState.payment_state && (
            <div className={classNames('p-purchases__row-content p-purchases__row-content--inline', {'is-error': orderState.payment_state == 'failed'})}>
              {orderState.state_display_name}
            </div>
          )}
          <StatusDate />

          {orderState.can_repayment && (
            <div className="p-purchases__button p-purchases__button--last">
              <span
                className="c-button c-button--middle c-button--black"
                onClick={() => setIsRepaymentModalDisplayed(true)}
              >
                UPDATE PAYMENT
              </span>
            </div>
          )}
        </div>
      </div>
      <Modal
        isOpen={isRepaymentModalDisplayed}
        appElement={appElement}
        overlayClassName="p-modal"
        className="p-modal__container"
        onRequestClose={() => setIsRepaymentModalDisplayed(false)}
      >
        <Repayment onClose={() => setIsRepaymentModalDisplayed(false)} order={orderState} setOrder={setOrder} />
      </Modal>
    </div>
  );
};
