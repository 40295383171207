import React, {useState, useEffect} from 'react';
import classNames from 'classnames';
import {sumBy, get} from 'lodash';

import ApiHelper from 'utils/api_helper';
import {formatMoney} from 'utils/format_money';
import {getCountry} from 'utils/get_country';

import SellImagePath from 'images/common/sticker/peace.png';
import {CartItemScreen} from '../cart_item_screen/cart_item_screen';
import {OrderCardSummuryBlock} from '../order_card_summury_block/order_card_summury_block';
import {ResultOrderCard} from '../result_order_card/result_order_card';

export const OrderResultScreen = ({orders, facebookAppId}) => {
  const [cartItems, setCartItems] = useState([]);
  const activeCartItems = cartItems.filter((cartItem) => cartItem.can_order && cartItem.state === 'active');
  const paymentCurrency = get(activeCartItems, '[0].payment_currency');
  const checkoutDisabled = activeCartItems.some((i) => i.errors.full_messages.length > 0) || activeCartItems.filter((i) => i.can_order).length === 0;
  const orderableCartItems = activeCartItems.filter((item) => item.can_order);

  useEffect(() => {
    ApiHelper.get('/api/v1/cart_items').then((res) => {
      setCartItems(res.response);
    });
  }, []);

  return (
    <div className="c-order-result-screen">
      <div className="c-order-result-screen__top-section">
        <div className="c-order-result-screen__top-image"><img src={SellImagePath} alt="qrates sticker" /></div>
        <h3 className="c-order-result-screen__title">
          Thanks&nbsp;
          <span wovn-ignore="">
            {get(orders, '[0].billing_first_name')}
          </span>
        </h3>
        <p className="c-order-result-screen__subtitle">Your orders have been successfully submitted.</p>
      </div>
      <div className="c-order-result-screen__container">
        <div className="c-order-result-screen__orders-list">
          {orders.map((order, index) => {
            const orderType = order.order_type;
            const shippingCountry = getCountry(get(order, 'order_items[0].delivery_country')).name;
            return (
              <div className="c-order-result-screen__order-item" key={order.id || index}>
                <div className="c-order-result-screen__card-list">
                  {order.order_items.map((orderItem) => (
                    <ResultOrderCard order={orderItem} orderType={orderType} facebookAppId={facebookAppId} key={orderItem.id} />
                  ))}
                </div>
                <OrderCardSummuryBlock
                  orderHeader={{
                    orderID: order.id,
                    link: `/orders/${order.id}`,
                  }}
                  itemsList={[
                    {
                      title: `${order?.order_type === 'funding' ? 'Pledge' : 'Purchase'} subtotal`,
                      value: formatMoney({amount: order.total_payment_amount, currency: order.payment_currency}),
                    },
                    ...((order?.order_items || []).filter((i) => i.discount).map((orderItem) => (
                      {
                        title: `Discount for “${orderItem.project_title}”`,
                        value: `-${formatMoney({amount: orderItem.discount.amount, currency: order.payment_currency})}`,
                      }
                    ))),
                    {
                      title: `Shipping to ${shippingCountry || '(please select country)'}`,
                      value: formatMoney({amount: order.total_payment_delivery_fee, currency: order?.payment_currency}),
                    },
                  ]}
                  paymentCurrency={order?.payment_currency}
                  paymentTax={order?.total_payment_tax}
                  paymentTotalAmount={order?.total_payment_total_amount}
                  chargeDate={order.order_items[0]?.charge_date}
                />
              </div>
            );
          })}
        </div>
        {cartItems.length > 0 && (
          <>
            <div className="c-heading--center">
              <h3>Still in your cart</h3>
              <p>
                Putting an item in your cart does NOT reserve or hold the item for you.&nbsp;
                <br className="u-hidden-tablet" />
                The item will be secured once you complete your payment.
              </p>
            </div>
            {activeCartItems.length > 0 && (
              <>
                {activeCartItems.map((cartItem) => (
                  <CartItemScreen cartItem={cartItem} updateCartItems={setCartItems} key={cartItem.id} />
                ))}
                <div className="p-cart-thanks__details details-bar">
                  <div className="p-purchases__detail">Shipping calculated during checkout</div>
                  <div className="p-purchases__total">
                    <span className="p-purchases__total--label">{`SUBTOTAL (${paymentCurrency})`}</span>
                    <span>{formatMoney({amount: sumBy(orderableCartItems, 'payment_amount'), currency: paymentCurrency})}</span>
                  </div>
                </div>
                <div className="p-cart-thanks__button-result">
                  <a
                    href="/orders/new"
                    className={classNames(
                      'c-button c-button--large c-button--black p-cart__button-contents',
                      {disabled: checkoutDisabled},
                    )}
                  >
                    CHECKOUT RECORDS
                  </a>
                </div>
              </>
            )}
          </>
        )}
        {cartItems.length === 0 && <hr className="c-divider--blank" />}
      </div>
    </div>
  );
};
