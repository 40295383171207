import React from 'react';
import fallbackImage from 'images/common/sticker/press_only.png';

export const ErrorFallbackSection = () => {
  return (
    <div className="c-error-fallback-section">
      <div>
        <img src={fallbackImage} alt="Oops!" />
      </div>
      <h2>Sorry, an error has occurred.</h2>
    </div>
  );
};
