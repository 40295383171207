import React from 'react';
import classNames from 'classnames';

export const ArtistDetailsArrow = ({number, hidden}) => {
  return (
    <div className="c-for-artists-details-arrow">
      <div className="c-for-artists-details-arrow__circle">
        <span wovn-ignore="" className={classNames('c-for-artists-details-arrow__span-offset', {'c-for-artists-details-arrow__span--offset': number === 1})}>{number}</span>
      </div>
      <i className={classNames('c-for-artists-details-arrow__body', {'c-for-artists-details-arrow__body--hidden': hidden})} />
      <i className={classNames('c-for-artists-details-arrow__arrow-head', {'c-for-artists-details-arrow__arrow-head--hidden': hidden})} />
    </div>
  );
};
