/* eslint-disable no-nested-ternary */
// @flow
import React from 'react';
import styled, {css} from 'styled-components';
import {RichText} from 'prismic-reactjs';
import {
  cssHugeText,
  cssLargeText,
  cssMediumText,
  cssSmallText,
  cssTinyText,
} from '../../../../shared/styles/typography';
import {breakpointDesktopOnly, breakpointMobileOnly} from '../../../../shared/styles/responsive';
import type {StoryMdl} from '../../../../shared/cms/story';
import {
  getStoryCategory,
  getStoryPreviewHeading,
  getStoryPreviewImage,
  getStoryPreviewPreHeading,
  getStoryPreviewText,
  getStoryPublishedDate,
  getStorySlug,
} from '../../../../shared/cms/story';
import {formatDate} from '../../../../shared/utils/date';
import {cssFontFKRoman, FONT_WEIGHTS} from '../../../../shared/styles/fonts';

export const STORY_SIZE = {
  small: 'small',
  medium: 'medium',
  large: 'large',
};

export type StorySize = $Keys<typeof STORY_SIZE>;

const cssLargeSize = css`
  ${breakpointDesktopOnly} {
    width: 100%;
    border: none;
  }
`;

const cssMinHeight = css`
  min-height: 852px;
`;

const cssMediumSize = css`
  ${breakpointDesktopOnly} {
    ${cssMinHeight};
    flex: 2;
  }
`;

const cssSmallSize = css`
  ${breakpointDesktopOnly} {
    ${cssMinHeight};
    flex: 1;
  }
`;

const StyledWrapper = styled.article`
  position: relative;

  & > a {
    position: absolute;
    display: block;
    top: 0px;
    left: 0px;
    width: 100%
    height: 100%
  }

  ${breakpointDesktopOnly} {
    border: 1px solid #000000;
  }

  ${breakpointMobileOnly} {
    border-top: 2px solid #000000;
  }

  ${props =>
    props.size === STORY_SIZE.small
      ? cssSmallSize
      : props.size === STORY_SIZE.medium
        ? cssMediumSize
        : cssLargeSize};
`;

const StyledContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const cssStoryType = css`
  ${cssSmallText};
  text-transform: uppercase;
  border: 2px solid #000000;
  background-color: #ffffff;
  padding: 9px 10px;

  ${breakpointMobileOnly} {
    font-size: 12px;
  }
`;

const cssColumnImagery = css`
  position: relative;
  padding-top: 57.63%;
`;

const cssSmallImagery = css`
  ${cssColumnImagery}
`;

const cssMediumImagery = css`
  ${cssColumnImagery}
`;

const cssLargeImagery = css`
  ${cssColumnImagery}
  ${breakpointMobileOnly} {
    padding-top: 170%
  }
  ::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: inherit;
    filter: brightness(80%);
  }
`;

export const cssSharedBgImage = css`
  background-color: #e0e0e0;
  background-position: center;
  background-size: cover;
`;

const StyledImagery = styled.div`
  ${cssSharedBgImage};
  background-image: url(${props => props.image});
  ${props =>
    props.size === STORY_SIZE.small
      ? cssSmallImagery
      : props.size === STORY_SIZE.medium
        ? cssMediumImagery
        : cssLargeImagery};

  a {
    display: block;
    width: 100%;
    height: 100%;
  }
`;

const cssSharedContent = css`
  ${breakpointDesktopOnly} {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 31px 31px 37px 31px;
    justify-content: space-between;
  }

  ${breakpointMobileOnly} {
    padding: 18px 18px 26px 18px;
  }

  h3 {
    ${cssLargeText};
    color: #000000;

    ${breakpointMobileOnly} {
      font-size: 24px;
      letter-spacing: -0.5px;
      line-height: 28px;

      span:last-child {
        letter-spacing: 0;
      }
    }
  }

  p {
    ${cssMediumText};
    margin-top: 19px;
    color: #000000;

    ${breakpointMobileOnly} {
      margin-top: 18px;
      font-size: 18px;
      font-weight: 500;
      letter-spacing: -0.3px;
      line-height: 24px;
    }
  }
`;

const cssSmallContent = css`
  ${cssSharedContent};

  h3,
  p {
    max-width: 100%;
  }
`;

const cssMediumContent = css`
  ${cssSharedContent};

  h3,
  p {
    max-width: 100%;
  }
`;

const cssLargeDate = css`
  ${breakpointDesktopOnly} {
    //display: none;
    margin-top: 40px;
  }
`;

const StyledDate = styled.div`
  ${cssTinyText};
  font-size: 16px;
  padding-top: 53px;
  color: #000000;

  ${breakpointMobileOnly} {
    margin-top: 30px;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: -0.15px;
    line-height: 18px;
  }

  ${props => (props.size === STORY_SIZE.large ? cssLargeDate : '')};
`;

const cssLargeContent = props => css`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #ffffff;

  ${breakpointMobileOnly} {
    padding: 0 18px;
  }

  h3 {
    ${cssHugeText};
    max-width: 926px;

    ${breakpointMobileOnly} {
      font-size: 44px;
      letter-spacing: -1.38px;
      line-height: 44px;

      span:last-child {
        letter-spacing: -0.5px;
      }
    }
  }

  p {
    ${cssMediumText};
    max-width: 744px;
    margin: 33px auto 0;

    ${breakpointMobileOnly} {
      font-size: 18px;
      font-weight: 500;
      letter-spacing: -0.3px;
      line-height: 24px;
      margin-top: 24px;
      padding: 0 15px;
      margin-bottom: -4px;
    }
  }

  div {
    color: #ffffff;
  }
`;

const StyledType = styled.div`
  ${cssStoryType};
  align-self: center;
  color: #000000!important;

  ${breakpointMobileOnly} {
    top: 18px;
    left: 18px;
  }
`;

const StyledContent = styled.div`
  ${props =>
    props.size === STORY_SIZE.small
      ? cssSmallContent
      : props.size === STORY_SIZE.medium
        ? cssMediumContent
        : cssLargeContent(props)};
  a {
    font: inherit;
    text-decoration: underline;
  }
  h3 {
    span:last-of-type {
      ${cssFontFKRoman};
    }
  }
  p {
    font-weight: ${FONT_WEIGHTS.medium};
  }
`;

type Props = {
  size: StorySize,
  story: StoryMdl,
};

export const classNameStoryPreview = 'storyPreview';

const StoryPreview = ({size, story}: Props) => {
  const path = `/stories/${getStorySlug(story)}`;
  return (
    <StyledWrapper className={classNameStoryPreview} size={size}>
      <StyledContainer>
        <StyledImagery image={story.preview_image || getStoryPreviewImage(story)} size={size}>
          <a href={path} target="_blank" />
        </StyledImagery>
        <StyledContent size={size}>
          <StyledType>{getStoryCategory(story)}</StyledType>
          <div>
            <h3>
              <span>{story.preview_pre_heading || RichText.asText(getStoryPreviewPreHeading(story))}</span>
              <br />
              <span>{story.preview_heading || RichText.asText(getStoryPreviewHeading(story))}</span>
            </h3>
            <p>{story.preview_text || RichText.asText(getStoryPreviewText(story))}</p>
          </div>
          <StyledDate size={size}>{formatDate(getStoryPublishedDate(story))}</StyledDate>
        </StyledContent>
      </StyledContainer>
      <a href={path} target="_blank" />
    </StyledWrapper>
  );
};

export default StoryPreview;
