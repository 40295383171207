import React, {useState} from 'react';
import {InstantSearch, ScrollTo, Configure} from 'react-instantsearch-dom';
import algoliasearch from 'algoliasearch/lite';

import Menu from './custom_menu';
import Hits from './custom_hits';
import Pagination from '../shared/custom_pagination';
import Stats from './custom_stats';
import SortBy from '../shared/custom_sort_by';
import Results from './custom_results';

export const ProjectsList = ({
  algolia_id,
  algolia_search_api_key,
  current_user,
}) => {
  const searchClient = algoliasearch(
    algolia_id,
    algolia_search_api_key,
  );
  const [searchState, setSearchState] = useState({});
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const defaultTag = params.get('tag');
  const defaultCondition = params.get('condition');
  const defaultFormat = params.get('product_format');
  const defaultLocation = params.get('delivery_service');
  const projectIds = params.get('project_ids')?.split(',')?.filter((item) => item)
    ?.map((item) => `objectID:${item}`)
    ?.join(' OR ');

  const conditionsLabelMap = {
    wholesale: 'Available for stores',
    onsale: 'Available for order',
    funding: 'Crowdfunding',
    sell: 'Purchase',
    preorder: 'Pre order',
    in_stock: 'In stock',
    coming_soon: 'Coming soon',
    finished: 'Finished',
  };

  const conditionsSort = {
    wholesale: 8,
    onsale: 7,
    funding: 6,
    sell: 5,
    preorder: 4,
    in_stock: 3,
    coming_soon: 2,
    finished: 1,
  };

  const transformConditionItems = (items) => {
    const filteredItems = items.filter((item) => {
      return item.value != 'wholesale' || (defaultCondition == 'wholesale' || current_user?.distributor_id);
    });

    return filteredItems;
  };

  return (
    <InstantSearch
      searchClient={searchClient}
      searchState={searchState}
      indexName="Project"
      onSearchStateChange={(keyword) => setSearchState(keyword)}
    >
      <div className="p-projects here">
        {projectIds && (
          <Configure
            filters={projectIds}
            distinct
          />
        )}
        <ScrollTo scrollOn="query">
          <div className="p-projects__filters">
            <Menu
              searchState={searchState}
              defaultRefinement={defaultCondition}
              attribute="condition"
              clearOptionValue="condition"
              clearOptionLabel="All projects"
              labelMap={conditionsLabelMap}
              sortOrderWeight={conditionsSort}
              transformItems={transformConditionItems}
              isMainMenuActive
            />
            <Menu
              searchState={searchState}
              defaultRefinement={defaultTag}
              attribute="tags"
              clearOptionValue="tags"
              clearOptionLabel="All genres"
              limit={30}
            />
            <Menu
              searchState={searchState}
              defaultRefinement={defaultFormat}
              attribute="product_format"
              clearOptionValue="product_format"
              clearOptionLabel="All format"
              limit={20}
              labelMap={{
                vinyl: 'Vinyl',
                cassette: 'Cassette',
                vinyl_size_12: '12 inch',
                vinyl_size_10: '10 inch',
                vinyl_size_7: '7 inch',
                vinyl_size_12_double: '12 inch Double',
                vinyl_type_black: 'Black vinyl',
                vinyl_type_color: 'Color vinyl',
                vinyl_type_special: 'Special vinyl',
                vinyl_type_special_tricolor: 'Special vinyl tricolor',
                vinyl_type_picture: 'Picture vinyl',
              }}
              sortOrderWeight={{
                vinyl: 3,
                cassette: 3,
                vinyl_size_12: 2,
                vinyl_size_10: 2,
                vinyl_size_7: 2,
                vinyl_size_12_double: 2,
                vinyl_type_black: 1,
                vinyl_type_color: 1,
                vinyl_type_special: 1,
                vinyl_type_special_tricolor: 1,
                vinyl_type_picture: 1,
              }}
            />
            <Menu
              searchState={searchState}
              defaultRefinement={defaultLocation}
              attribute="delivery_service"
              clearOptionValue="delivery_service"
              clearOptionLabel="All shipping center"
            />
          </div>
          <div className="p-section-header--search">
            <Stats
              translations={{
                stats(nbHits, timeSpentMS) {
                  return (
                    <div className="p-section-header__hits">
                      {`${nbHits} ${nbHits != 1 ? 'projects' : 'project'}`}
                    </div>
                  );
                },
              }}
            />
            <SortBy
              defaultRefinement="Project"
              items={[
                {value: 'Project_start_date', label: 'Sort by Launch date'},
                {value: 'Project_finish_date', label: 'Sort by Remaining time'},
                {value: 'Project_ordered_quantity', label: 'Sort by Popularity'},
                {value: 'Project_artist_name', label: 'Sort by Artist name'},
                {value: 'Project_title', label: 'Sort by Title'},
              ]}
            />
          </div>
          <Results>
            <Hits currentUser={current_user} />
          </Results>
        </ScrollTo>
        <Pagination customClass="p-projects__pagination" textLabel="RECORDS" />
        <hr className="c-divider--blank" />
      </div>
    </InstantSearch>
  );
};
