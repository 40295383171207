import React, {useRef} from 'react';
import classNames from 'classnames';

import {useWindowDimensions} from 'utils/window_dimensions';

import {ShareIcon} from 'shared/components/share/share_icon/share_icon';

export const ShareIconsDropdown = ({
  data,
  isMenuOpen,
  isSticky,
}) => {
  const dropdownMenu = useRef();
  const {width} = useWindowDimensions();
  const isMobile = width <= 768;

  return (
    isMobile && (
      <>
        {isMenuOpen && (
          <div
            data-testid="drop-wrapper"
            className={
              classNames('c-share-icons-block__dropdown',
                {'c-share-icons-block__dropdown--sticky': isSticky},
              )
            }
            ref={dropdownMenu}
          >
            <div className="c-share-icons-block__dropdown-item">
              <ShareIcon type="facebook" data={data.facebook} isTitle />
            </div>
            <div className="c-share-icons-block__dropdown-item">
              <ShareIcon type="twitter" data={data.twitter} isTitle />
            </div>
            <div className="c-share-icons-block__dropdown-item">
              <ShareIcon type="qrcode" data={data.qrcode} isTitle />
            </div>
          </div>
        )}
      </>
    )
  );
};
