import React from 'react';
import classNames from 'classnames';
import {RichText} from 'prismic-reactjs';
import {NewLabel} from 'shared/components/labels/new_label/new_label';

export const CuratedFeaturedLarge = ({
  article,
  cardTheme,
  labelTheme,
  rootPath,
  linkLabel,
}) => {
  const path = `${rootPath + article.uid}`;
  return (
    <div className={classNames('c-curated-featured', {
      'c-curated-featured--light': cardTheme === 'light',
      'c-curated-featured--dark': cardTheme === 'dark',
    })}
    >
      {article && (
        <div className="c-curated-featured__container c-curated-featured__container--large">
          <div className="c-curated-featured__image-container c-curated-featured__image-container--middle-border">
            <img className="c-curated-featured__image" src={article.data.preview_image.url} alt="top-article-preview" />
          </div>
          <div className="c-curated-featured__intro c-curated-featured__intro--middle-border">
            <div className="c-curated-featured__info-container--large">
              <div className="c-curated-featured__label-container c-curated-featured__label-container--large">
                <NewLabel title={article.data.category || article.data.story_category} theme={labelTheme} />
              </div>
              <br />
              <h5 wovn-ignore="">{RichText.asText(article.data.preview_pre_heading)}</h5>
              <br />
              <h3 wovn-ignore="">{RichText.asText(article.data.title)}</h3>
              <br />
              <p wovn-ignore="">{RichText.asText(article.data.preview_text)}</p>
            </div>
            <div
              className={classNames({
                'c-curated-featured__link-container--black-font': labelTheme === 'white',
                'c-curated-featured__link-container--white-font': labelTheme === 'transparent',
              })}
            >
              <a wovn-ignore="" className="c-curated-featured__bottom-link" href={path}>
                {linkLabel}
                <i className="c-pictogram c-pictogram--arrow-forward" />
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
