import React, {useState, useEffect} from 'react';
import classNames from 'classnames';

import {assetPath} from 'project_start/components/utils/visualizer/assets_helper';

export const MainVisualPlayer = ({
  className,
  theme,
  main_visual_type,
  cover_image_url,
  background_image_url,
  background_color,
  iframe_src,
  handlePlayVideo,
}) => {
  const pageUrl = new URL(window.location);
  const startPlayVideoParam = 'start_play_video';
  const isStartPlayVideoParam = pageUrl.searchParams.get(startPlayVideoParam);
  const isVideoType = main_visual_type === 'main_visual_video';
  const isMainVisualTypeColor = main_visual_type === 'main_visual_color';
  const isMainVisualTypeImage = main_visual_type === 'main_visual_image';

  const manifest = window.constants.manifest;

  const backgroundStyle = () => {
    if (isVideoType) { return {}; }
    return {
      backgroundImage: `url(${isMainVisualTypeImage ? background_image_url : null})`,
      backgroundColor: isMainVisualTypeColor && background_color,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    };
  };

  const mainVisualImageStyle = () => {
    return {
      backgroundImage: `url(${assetPath(cover_image_url, manifest)})`,
      backgroundSize: (isMainVisualTypeColor || isMainVisualTypeImage) ? 'contain' : 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    };
  };

  let iframeSrc = iframe_src;
  if (iframeSrc) {
    iframeSrc = new URL(iframeSrc);
    iframeSrc.searchParams.set('autoplay', 1);
    iframeSrc.searchParams.set('modestbranding', 1);
    iframeSrc.searchParams.set('rel', 0);
  }

  const handlePlay = () => {
    handlePlayVideo ? handlePlayVideo() : setVideoPlaying(true);
  };

  const [videoPlaying, setVideoPlaying] = useState(false);

  useEffect(() => {
    if (isStartPlayVideoParam) {
      setVideoPlaying(true);
      pageUrl.searchParams.delete(startPlayVideoParam);
      window.history.pushState({}, '', pageUrl);
    }
  }, [isStartPlayVideoParam]);

  return (
    <div
      className={classNames('c-project-main-visual', {
        [className]: className,
        'c-project-main-visual--project-page': theme === 'project-page',
        'c-project-main-visual--project-widget': theme === 'project-widget',
      })}
      style={backgroundStyle()}
    >
      {!isVideoType && <div className="c-project-main-visual__background" style={mainVisualImageStyle()} />}
      {isVideoType && !videoPlaying && (
        <div className="c-project-main-visual__background" style={mainVisualImageStyle()}>
          <button type="button" className="c-project-main-visual__button c-button c-button--white-line c-button--middle" onClick={handlePlay}>
            WATCH VIDEO
          </button>
        </div>
      )}
      {isVideoType && videoPlaying && (
        <div className="c-project-main-visual__frame">
          <iframe
            id="main-visual-video"
            className="video_player"
            src={iframeSrc}
            width="689"
            height="387"
            frameBorder="0"
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
            allow="autoplay"
            allowFullScreen
          />
        </div>
      )}
    </div>
  );
};
