import React from 'react';
import classNames from 'classnames';

export const NewLabel = ({
  className, title, onClick, theme,
}) => {
  return (
    <span
      className={classNames('c-new-label', {
        [className]: className,
        'c-new-label--transparent': theme === 'transparent',
        'c-new-label--white': theme === 'white',
      })}
      onClick={onClick}
    >
      <span wovn-ignore="">
        {title}
      </span>
    </span>
  );
};
