import React, {useState} from 'react';
import {isEqual} from 'lodash';

import {ReactModal} from 'shared/components/modals/react_modal/react_modal';
import {Label} from 'shared/components/labels/label/label';
import {EditSubscriptionModal} from './components/edit_subscription_modal/edit_subscription_modal';

export const Subscriptions = ({
  user,
  asmGroupSuppressions,
  asmGroups,
}) => {
  const appElement = document.getElementById('mail-subscriptions');
  const [modal, setModal] = useState(false);
  const closeModal = () => setModal(false);
  const [groupIds, setGroupIds] = useState(asmGroupSuppressions);
  const isDescriptionShow = isEqual(groupIds.sort(), asmGroups.map((g) => g.id).sort());

  return (
    <div className="c-mail-subscriptions-section" id="mail-subscriptions">
      <h2 className="c-mail-subscriptions-section__title">Subscribe for newsletter</h2>
      {isDescriptionShow && (
        <p className="c-mail-subscriptions-section__description">
          You don’t have subscribes. Click edit to control your notification.
        </p>
      )}
      <div className="c-mail-subscriptions-section__status-panel status-panel">
        {asmGroups.map((group) => {
          return (
            <div className="status-panel__item" key={group.id}>
              <div className="status-panel__title">
                {group.title}
              </div>
              <div className="status-panel__status">
                {groupIds.includes(group.id) && (
                  <Label className="status-panel__label" title="NOT SUBSCRIBED" theme="unsuccess" />
                )}
                {!groupIds.includes(group.id) && (
                  <Label className="status-panel__label" title="SUBSCRIBED" theme="sucsess" />
                )}
              </div>
            </div>
          );
        })}
      </div>
      <div className="c-mail-subscriptions-section__buttons">
        <button className="c-mail-subscriptions-section__edit-button c-button c-button--black c-button--middle" type="button" onClick={() => setModal(true)}>
          {isDescriptionShow ? 'EDIT SUBSCRIBES' : 'EDIT'}
        </button>
      </div>
      <ReactModal
        isOpen={modal}
        appElement={appElement}
        onRequestClose={closeModal}
        onClose={closeModal}
      >
        <EditSubscriptionModal
          onClose={closeModal}
          user={user}
          groupIds={groupIds}
          setGroupIds={setGroupIds}
          asmGroups={asmGroups}
        />
      </ReactModal>
    </div>
  );
};
