import {createAction} from 'redux-actions';

export default {
  initialize: createAction('initialize'),
  initializeBased: createAction('initializeBased'),

  saveDraft: createAction('saveDraft'),
  saveSpec: createAction('saveSpec'),
  startProject: createAction('startProject'),
  saveProjectInRedis: createAction('saveProjectInRedis'),

  setProject: createAction('setProject'),
  setBasedProject: createAction('setBasedProject'),
  calculateCost: createAction('calculateCost'),
  setProjectCost: createAction('setProjectCost'),
  applyCoupon: createAction('applyCoupon'),

  setProjectTitle: createAction('setProjectTitle'),
  saveArtworkMockupFile: createAction('saveArtworkMockupFile'),
  setItemPageDesignBackgroundColor: createAction('setItemPageDesignBackgroundColor'),
  setPersistedItems: createAction('setPersistedItems'),

  addSpecVariation: createAction('addSpecVariation'),
  removeSpecVariation: createAction('removeSpecVariation'),
  setSpecVariationQuantity: createAction('setSpecVariationQuantity'),

  // Basics tab

  setFormatType: createAction('setFormatType'),
  setVinylSize: createAction('setVinylSize'),
  setHoleSize: createAction('setHoleSize'),
  setVinylRPM: createAction('setVinylRPM'),
  setQuantity: createAction('setQuantity'),
  setBaseQuantity: createAction('setBaseQuantity'),
  setVinylsNumber: createAction('setVinylsNumber'),
  setTapeLength: createAction('setTapeLength'),

  // Product tab

  setVinylColor: createAction('setVinylColor'),
  setVinylLabel: createAction('setVinylLabel'),
  setVinylType: createAction('setVinylType'),
  setVinylWeight: createAction('setVinylWeight'),
  setSpecialPattern: createAction('setSpecialPattern'),
  setCassetteType: createAction('setCassetteType'),
  setCassetteLabelType: createAction('setCassetteLabelType'),
  setCassetteColor: createAction('setCassetteColor'),

  // Packaging tab

  setPackagingType: createAction('setPackagingType'),
  setSleeveType: createAction('setSleeveType'),
  setInnerSleeveType: createAction('setInnerSleeve'),
  setFinish: createAction('setFinish'),
  setShippingMaterial: createAction('setShippingMaterial'),
  setCaseType: createAction('setCaseType'),
  setInnerCard: createAction('setInnerCard'),
  setOuterSleeve: createAction('setOuterSleeve'),

  // Extras tab

  setTestpress: createAction('setTestpress'),
  setDemo: createAction('setDemo'),
  toggleArtworkEnabled: createAction('toggleArtworkEnabled'),
  setDownloadCard: createAction('setDownloadCard'),
  setMastering: createAction('setMastering'),
  setMasteringQuantity: createAction('setMasteringQuantity'),
  setInsert: createAction('setInsert'),
  setSticker: createAction('setSticker'),
  setNumbering: createAction('setNumbering'),
  setNumberingFormat: createAction('setNumberingFormat'),
  setObi: createAction('setObi'),

  // Price calculator page

  setProjectType: createAction('setProjectType'),
  setCurrency: createAction('setCurrency'),
  setPledgeAmount: createAction('setPledgeAmount'),
  setDeliveryService: createAction('setDeliveryService'),
  setSalePrice: createAction('setSalePrice'),
  setTaxRate: createAction('setTaxRate'),
  setShippingCountry: createAction('setShippingCountry'),
  togglePreorderEnabled: createAction('togglePreorderEnabled'),
  setPreorderPrice: createAction('setPreorderPrice'),
  setPreorderQuantity: createAction('setPreorderQuantity'),
  toggleStoreNetworkEnabled: createAction('toggleStoreNetworkEnabled'),
  setWholesalePrice: createAction('setWholesalePrice'),
  setWholesaleQuantity: createAction('setWholesaleQuantity'),
  setCampaignApplied: createAction('setCampaignApplied'),

  // visualizer
  setFrontSleeveImage: createAction('setFrontSleeveImage'),
  setBackSleeveImage: createAction('setBackSleeveImage'),
  setSpineSleeveImage: createAction('setSpineSleeveImage'),
  setInnerSleeveImage: createAction('setInnerSleeveImage'),
  setFrontLabelImageA: createAction('setFrontLabelImageA'),
  setBackLabelImageA: createAction('setBackLabelImageA'),
  setFrontImageA: createAction('setFrontImageA'),
  setBackImageA: createAction('setBackImageA'),

  // user
  getCurrentUser: createAction('getCurrentUser'),
  createUser: createAction('createUser'),
  setUser: createAction('setUser'),
  signIn: createAction('signIn'),
  openSignupScreenWithParams: createAction('openSignupScreenWithParams'),

  // press prices
  getZeroPrices: createAction('getZeroPrices'),
  setZeroPrices: createAction('setZeroPrices'),

  // notification
  setNotification: createAction('setNotification'),

  // form submitting
  setFormSubmitting: createAction('setFormSubmitting'),
  // calculating
  setIsCalculating: createAction('setIsCalculating'),

  // current spec variation index
  setCurrentSpecVariationIndex: createAction('setCurrentSpecVariationIndex'),
};
