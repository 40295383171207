import React, {useState, useEffect} from 'react';
import {formatMoney} from 'utils/format_money';
import {formatDate} from 'utils/date';
import ApiHelper from 'utils/api_helper';

import {AddressSection} from 'orders/address_section';
import {PaymentDetails} from 'orders/payment_details';
import {ReactButton} from 'shared/components/buttons/react_button/react_button';
import {PurchaseOrderDetails} from './purchase_order_details/purchase_order_details';

export const PurchaseOrder = ({id}) => {
  const [order, setOrder] = useState();
  const failedPaymentState = order?.payment_state === 'failed';
  const currency = order?.payment_currency;
  const totalAmount = order?.total_payment_total_amount;
  const totalTax = order?.total_payment_tax;
  const totalShipping = order?.total_payment_delivery_fee;
  const orderDate = order?.order_items[0].created_at;
  const deliveryGroupMasters = order?.order_items.filter((orderItem) => orderItem.delivery_group_master);
  const itemsByDeliveryGroup = [];
  deliveryGroupMasters?.forEach((master) => {
    const deliveryGroup = order?.order_items?.filter((item) => item.delivery_group_index === master.delivery_group_index);
    itemsByDeliveryGroup.push(deliveryGroup);
  });

  const getOrder = async () => {
    await ApiHelper.get(`/api/v1/orders/${id}`).then((res) => {
      setOrder(res.response);
    });
  };

  useEffect(getOrder, [id]);

  return (
    <div className="p-account--purchase__grid">
      <div className="l-side-nav--order-details c-grid--sticky-top">
        <div className="p-side-nav-contents--order-sheet c-grid--sticky-top__item">
          <a className="p-side-nav-contents__back" href="/orders">
            <i className="c-pictogram c-pictogram--arrow-back" />
            ALL ORDERS
          </a>
          <div className="c-purchases__heading-id">
            <h2 wovn-ignore="">{`Order ID: ${id}`}</h2>
          </div>
          <div className="c-purchases__date">{formatDate(orderDate, '%b %-d %Y')}</div>
          <div className="c-purchases__preview-price">
            <div className="c-preview-price">
              <div className="c-preview-price__form--middle">
                <div className="c-preview-price__form--label">Total</div>
                <div className="c-preview-price__form--value">{formatMoney({amount: totalAmount, currency})}</div>
              </div>
              <hr className="c-divider" />
              <div className="c-preview-price__form--low">
                <div className="c-preview-price__form--label">Includes shipping</div>
                <div className="c-preview-price__form--value">{formatMoney({amount: totalShipping, currency})}</div>
              </div>
              <hr className="c-divider c-divider--thin" />
              <div className="c-preview-price__form--low">
                <div className="c-preview-price__form--label">Includes taxes</div>
                <div className="c-preview-price__form--value">{formatMoney({amount: totalTax, currency})}</div>
              </div>
            </div>
          </div>
          <div className="c-button-grid--right--no-padding">
            <ReactButton
              text="DOWNLOAD INVOICE"
              icon="receipt"
              theme="primary-white-border"
              type="link"
              link={`/orders/${id}/invoice`}
              target="_blank"
              width="full-flex"
            />
          </div>
          <div className="c-help-block">
            Problem with your order?
            <a className="c-link" href="/contact">Contact support</a>
          </div>
        </div>
      </div>
      <div className="c-grid__container p-account--purchase__grid-container">
        <div className="c-purchases__section--order">
          <div className="c-order-progress__heading-wrapper">
            <div className="c-order-progress__heading">Shipping</div>
            {failedPaymentState && (
              <div>
                <span className="c-label--jazz--payment-issue">PAYMENT ISSUE</span>
              </div>
            )}
          </div>
          <div className="c-order-progress__explanation">
            Shipping projects separately means they'll be dispatched as they're ready, and arrive as multiple deliveries.
          </div>
          {failedPaymentState && (
            <div className="c-order-progress__notification">
              Please update your payment details by clicking the edit button in the billing section below.
              Once you've done that, your payment method will be charged immediately. If we cannot successfully charge
              your payment method within 7 days from the end date of the crowdfunding campaign your order was placed to, your
              order will be automatically cancelled. Please note that we cannot accept any payment or order
              replacement requests after this 7 day period.
            </div>
          )}
          {itemsByDeliveryGroup?.map((delivery, index) => (
            <PurchaseOrderDetails
              key={index}
              delivery={delivery}
              index={index}
            />
          ))}
        </div>
        {order && (
          <>
            <AddressSection order={order} />
            <PaymentDetails order={order} />
          </>
        )}
      </div>
    </div>
  );
};
