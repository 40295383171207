import React from 'react';
import classNames from 'classnames';
import {toolkitFilters} from '../../../constants/constants';
import {CuratedSlider} from '../curated_slider/curated_slider';
import {LongCard} from '../../../components/curated_cards/long_card';
import {MediumCard} from '../../../components/curated_cards/medium_card';
import {ShortCard} from '../../../components/curated_cards/short_card';

export const Carousel = ({
  articles,
  title,
  buttonLabel,
  cardType,
  rootPath,
  buttonLink,
}) => {
  return (
    <div className={classNames('c-curated-carousel', {
      'c-curated-carousel c-curated-carousel--stories': cardType === 'stories',
      'c-curated-carousel c-curated-carousel--recommends': cardType === 'recommends',
      'c-curated-carousel c-curated-carousel--toolkit': cardType === 'toolkit',
    })}
    >
      {articles && (
        <CuratedSlider title={title} buttonLabel={buttonLabel} buttonLink={buttonLink}>
          {articles.map((article, index) => {
            switch (cardType) {
              case 'stories':
                return (
                  <LongCard
                    key={index}
                    index={index}
                    category={article.data.story_category}
                    uid={article.uid}
                    rootPath={rootPath}
                    image={article.data.preview_image.url}
                    heading={article.data.preview_heading}
                    text={article.data.preview_text}
                  />
                );
              case 'toolkit':
                return (
                  <MediumCard
                    key={index}
                    index={`${cardType + index}`}
                    type={cardType}
                    rootPath={rootPath}
                    category={toolkitFilters?.[article.data.category]}
                    uid={article.uid}
                    image={article.data.preview_image.url}
                    title={article.data.preview_title || article.data.preview_heading}
                    text={article.data.preview_text}
                  />
                );
              case 'recommends':
                return (
                  <ShortCard
                    key={index}
                    index={`${cardType + index}`}
                    type={cardType}
                    rootPath={rootPath}
                    uid={article.uid}
                    image={article.data.preview_image.url}
                    title={article.data.preview_heading}
                    text={article.data.preview_text}
                  />
                );
            }
          })}
        </CuratedSlider>
      )}
    </div>
  );
};
