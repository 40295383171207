import React from 'react';
import {RichText} from 'prismic-reactjs';
import {NewLabel} from 'shared/components/labels/new_label/new_label';

export const MediumCard = ({
  image,
  title,
  text,
  category,
  index,
  rootPath,
  uid,
}) => {
  const path = `${rootPath + uid}`;
  return (
    <a key={uid} href={path} target="_blank">
      <div key={`${title + index}`} index={index} className="c-curated-card-medium">
        <div className="c-curated-card-medium__top">
          <div className="c-curated-card-medium__label-container">
            <NewLabel title={category} theme="white" />
          </div>
          <img src={image} alt={`${title + index}`} wovn-ignore="" />
        </div>
        <div className="c-curated-card-medium__bottom">
          <h3 className="c-curated-card-medium__title" wovn-ignore="">
            {RichText.asText(title)}
          </h3>
          <p className="c-curated-card-medium__info" wovn-ignore="">
            {RichText.asText(text)}
          </p>
        </div>
      </div>
    </a>
  );
};
