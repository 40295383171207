import React from 'react';
import classNames from 'classnames';

import {Notification} from 'shared/components/notification';
import {accountRoutes} from 'modules/routes';

export const YourDetailsSection = ({
  className,
  currentUser,
  notification,
  cartItems,
}) => {
  const deliveryServices = cartItems.map((i) => i.delivery_service);

  const renderDescription = () => {
    if (deliveryServices.includes('delivery_service_japan')) {
      return (
        <>
          <p>For Japanese customers: please be sure to use Japanese character input for the name and address fields of your order.</p>
          <p>Please enter all address fields manually and do not use copy-paste.</p>
        </>
      );
    }

    return (
      <>
        <p>Your record will be shipped from either our USA or UK fulfillment centers.</p>
        <p>Please type your name and address information in English - using Roman letters and Arabic numerals.</p>
      </>
    );
  };

  return (
    <div className={classNames('c-your-details-section', {[className]: className})}>
      <h2 className="c-your-details-section__title">Your details</h2>
      {notification && (
        <Notification type={notification.type}>
          {notification.message}
        </Notification>
      )}
      <div className="c-your-details-section__description">
        {renderDescription()}
      </div>
      {!currentUser && (
        <div className="c-your-details-section__log-in">
          Already have an account? Please sign in to fill your information.
          <a href={accountRoutes.signIn}>LOG IN</a>
        </div>
      )}
    </div>
  );
};
