import React, {useState} from 'react';
import {FilterBar} from '../components/filter_bar/filter_bar';
import {StoriesList} from './stories_list';

export const StoriesPage = () => {
  const params = new URLSearchParams(window.location.search);
  const searchParamsQuery = params.get('category');
  const [currentFilter, setCurrentFilter] = useState(searchParamsQuery ?? 'All Stories');
  const filters = {
    'All Stories': 'All Stories',
    'Qrates Recommends': 'Qrates Recommends',
    Interview: 'Interview',
    'Scene Check': 'Scene Check',
    'Wax Heart': 'Wax Heart',
    'Qrates Biz': 'Qrates Biz',
  };

  const setCurrentFilterHandler = (value) => {
    setCurrentFilter(value);
  };

  return (
    <>
      <FilterBar theme="black" currentFilter={currentFilter} currentFilterHandler={setCurrentFilterHandler} filters={filters} />
      <StoriesList currentFilter={currentFilter} />
    </>
  );
};
