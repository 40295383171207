import React from 'react';
import classNames from 'classnames';

import ProfileIconPath from 'images/common/logos/profile_icon.png';

export const ListsCard = ({
  className,
  label,
  subtitle,
  title,
  link,
  quote,
  image,
  avatarImage,
  theme,
}) => {
  return (
    <div
      className={classNames('c-lists-card', {
        [className]: className,
        'c-lists-card--no-border': theme?.includes('no-border'),
      })}
    >
      {label && <div className="c-lists-card__lable c-label--working">{label}</div>}
      <div className="c-lists-card__cover-image" style={{backgroundImage: `url(${image})`}}> </div>
      {title && <h3 className="c-lists-card__title">{title}</h3>}
      {subtitle && <p className="c-lists-card__subtitle">{subtitle}</p>}
      {quote && (
        <div className="c-lists-card__quote">
          <div className="c-lists-card__avatar" style={{backgroundImage: `url(${avatarImage || ProfileIconPath})`}} />
          <p className="c-lists-card__quote-text">
            Curated by
            <span>{quote}</span>
          </p>
        </div>
      )}
      {link && <a className="c-lists-card__link" href={link} target="_blank"> </a>}
    </div>
  );
};
