import React, {useEffect, useState} from 'react';
import parse from 'html-react-parser';

import ApiHelper from 'utils/api_helper';
import {useLanguageChange} from 'utils/use_language_change';

export const CoverStory = ({stories}) => {
  const [records, setRecords] = useState(stories);

  useLanguageChange(() => {
    ApiHelper.get('/discover_cover_stories').then((res) => {
      setRecords(res.response);
    });
  });

  useEffect(() => {
    const slider = () => new window.Swiper(
      '.p-on-artists__slider',
      {
        slideClass: 'p-on-artists__slide',
        slideActiveClass: 'p-on-artists__slide--active',
        wrapperClass: 'p-on-artists__wrapper',
        loop: true,
        speed: 600,
        effect: 'fade',
        simulateTouch: false,
        pagination: {
          type: 'bullets',
          clickable: true,
          el: '.p-on-artists__pagination',
          bulletClass: 'p-on-artists__pagination-item',
          bulletActiveClass: 'current',
          renderBullet: (index, className) => {
            return `<span class='${className}'>${(index + 1)}</span>`;
          },
        },
      },
    );
    slider();
  }, [records.length]);

  return (
    <div className="p-on-artists--discover p-on-artists__slider">
      <div className="p-on-artists__wrapper">
        {records.map((story) => (
          <div className="p-on-artists__slide" key={story.uid}>
            <div className="c-column--table">
              <div className="c-column--table__left">
                <a href={`/stories/${story.uid}`} target="_blank">
                  <div className="c-column__image-window">
                    <img src={story.preview_image} className="p-lists__cover-image" />
                    <div className="c-label--pages--white u-hidden-desktop-only">{story.story_category?.toUpperCase()}</div>
                  </div>
                </a>
              </div>
              <div className="c-column--table__right c-column__card">
                <a href={`/stories/${story.uid}`} target="_blank">
                  <div className="c-label--pages--white u-hidden-tablet">{story.story_category?.toUpperCase()}</div>
                  <h3 className="c-column__title">
                    {story.preview_pre_heading}
                    <span className="p-on-artists__heading">{story.preview_heading}</span>
                  </h3>
                  <p className="c-column__explanation">{parse(story.preview_text)}</p>
                </a>
                <div className="c-pagination" />
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="p-on-artists__pagination c-pagination" />
    </div>
  );
};
