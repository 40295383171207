import React from 'react';
import classNames from 'classnames';
import {NavLink} from 'react-router-dom';
import {DesignPanelRoutes} from '../../modules/routes/routes';

export const NavMenu = ({
  className, navigationList, isNavActive, setIsNavActive,
}) => {
  return (
    <div className={classNames('menu', {
      [className]: className,
      'is-active': isNavActive,
    })}
    >
      <div className="menu__toogle-button" onClick={() => setIsNavActive(!isNavActive)}>
        <i className="c-pictogram c-pictogram--chevron-right" />
      </div>
      {isNavActive && <div className="menu__overlay" onClick={() => setIsNavActive(!isNavActive)} />}
      <div className="menu__list">
        <div className="menu__list-group">
          <div className="menu__list-group-title">Navigation</div>
          <div className="menu__sublist">
            <NavLink
              className="menu__sublist-item"
              activeClassName="is-active"
              to={DesignPanelRoutes.designPath()}
              exact
            >
              Design panel
            </NavLink>
          </div>
        </div>
        {navigationList && navigationList.map((category, index) => {
          return (
            <div className="menu__list-group" key={index}>
              <div className="menu__list-group-title">{category.name}</div>
              <div className="menu__sublist">
                {category.items.map((item, index) => {
                  return (
                    <NavLink
                      className="menu__sublist-item"
                      activeClassName="is-active"
                      to={DesignPanelRoutes.designPath(item.path)}
                      key={index}
                    >
                      {item.name}
                    </NavLink>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>

  );
};
