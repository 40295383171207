import React, {useState, useEffect} from 'react';
import classNames from 'classnames';

import {orderProjectButton} from 'utils/button_helpers';
import ApiHelper from 'utils/api_helper';

import {ReactModal} from 'shared/components/modals/react_modal/react_modal';
import {RestartRequestModal} from 'project/modals/restart_request_modal/restart_request_modal';
import {AddedToCartModal} from 'checkout/components/added_to_cart_modal/added_to_cart_modal';

export const OrderButton = ({
  projectJSON,
  setProject,
  low = false,
  target = '_self',
}) => {
  const projectId = projectJSON.id;
  const pageUrl = new URL(window.location);
  const addToOrderUrlParam = 'add_to_order';
  const voteForARestartUrlParam = 'vote_for_a_restart';
  const isAddToOrderUrlParam = pageUrl.searchParams.get(addToOrderUrlParam);
  const isVoteForARepressUrlParam = pageUrl.searchParams.get(voteForARestartUrlParam);

  const [modal, setModal] = useState(false);
  const [cartItem, setCartItem] = useState({});
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const orderButtonData = orderProjectButton({project: projectJSON});

  const restartPurpose = () => {
    if (projectJSON.is_failed) return 'retry';

    const productType = projectJSON.product_type?.original_value;
    return {vinyl: 'repress', cassette: 'reproduction'}[productType];
  };

  const handleOrder = () => {
    ApiHelper.post('/api/v1/cart_items', {project_id: projectJSON.id}).then((res) => {
      setCartItem(res.response);
      const event = new Event('add_cart_item');
      window.dispatchEvent(event);
    });
  };

  const closeModal = () => {
    setModal(false);
    window.location = window.location.pathname;
  };

  const voteRepress = () => {
    if (!projectJSON.is_signed_in) {
      window.location = `/signin?to=${window.location.origin}/projects/${projectJSON.id}?restart_request_modal=true`;
    } else if (target == '_blank') {
      window.open(`${window.location.origin}/projects/${projectJSON.id}?restart_request_modal=true`);
    } else {
      setModal(true);
    }
  };

  const removeRestartRequest = () => {
    ApiHelper.delete(`/api/v1/projects/${projectJSON.id}/restart_request`).then(() => {
      window.location = window.location.pathname;
    });
  };

  useEffect(() => {
    if (isAddToOrderUrlParam) {
      handleOrder();
      pageUrl.searchParams.delete(addToOrderUrlParam);
      window.history.pushState({}, '', pageUrl);
    }
    if (isVoteForARepressUrlParam) {
      voteRepress();
      pageUrl.searchParams.delete(voteForARestartUrlParam);
      window.history.pushState({}, '', pageUrl);
    }
  }, [isAddToOrderUrlParam, isVoteForARepressUrlParam]);

  useEffect(() => {
    if (params.get('restart_request_modal') && (projectJSON.is_finished || projectJSON.is_soldout) && !projectJSON.is_restart_requested) {
      setModal(true);
    }
  }, []);

  const renderButton = () => {
    if (orderButtonData.action === 'product_owner') {
      return (
        <a
          href={`/dashboard/projects/${projectId}`}
          className={classNames(
            'c-newbutton--black',
            {'c-newbutton--big-full': !low, 'c-newbutton--full': low},
          )}
        >
          {orderButtonData.label}
        </a>
      );
    } else if (orderButtonData.action === 'order') {
      return (
        <button
          type="button"
          className={classNames(
            'c-newbutton--black',
            {'c-newbutton--big-full': !low, 'c-newbutton--full': low},
          )}
          onClick={handleOrder}
        >
          {orderButtonData.label}
        </button>
      );
    } else if (orderButtonData.readOnly) {
      return (
        <button
          type="button"
          className={classNames(
            'c-newbutton--white-border readonly',
            {'c-newbutton--big-full': !low, 'c-newbutton--full': low},
          )}
        >
          {orderButtonData.label}
        </button>
      );
    } else if (orderButtonData.action === 'restart_request') {
      return (
        <button
          type="button"
          className={classNames(
            'c-newbutton--yellow-border',
            {'c-newbutton--big-full': !low, 'c-newbutton--full': low},
          )}
          onClick={voteRepress}
        >
          <span className="c-button__label">{orderButtonData.label}</span>
        </button>
      );
    } else if (orderButtonData.action === 'remove_restart_request') {
      return (
        <button
          type="button"
          className={classNames(
            'c-newbutton--white-border',
            {'c-newbutton--big-full': !low, 'c-newbutton--full': low},
          )}
          onClick={removeRestartRequest}
        >
          {orderButtonData.label}
        </button>
      );
    } else if (orderButtonData.disabled) {
      return (
        <button
          type="button"
          className={classNames(
            'c-newbutton--black',
            {'c-newbutton--big-full': !low, 'c-newbutton--full': low},
          )}
          disabled
        >
          {orderButtonData.label}
        </button>
      );
    } else {
      return (
        <button
          type="button"
          className={classNames(
            'c-newbutton--black',
            {'c-newbutton--big-full': !low, 'c-newbutton--full': low},
          )}
          disabled
        >
          {orderButtonData.label}
        </button>
      );
    }
  };

  return (
    <>
      {renderButton()}
      <ReactModal
        isOpen={modal}
        appElement={document.body}
        onClose={closeModal}
        onRequestClose={closeModal}
      >
        <RestartRequestModal
          onClose={closeModal}
          project={projectJSON}
          setProject={setProject}
          purpose={restartPurpose()}
        />
      </ReactModal>
      <ReactModal
        isOpen={!!cartItem?.id}
        appElement={document.body}
        onClose={() => setCartItem({})}
        onRequestClose={() => setCartItem({})}
      >
        <AddedToCartModal
          onClose={() => setCartItem({})}
          cartItem={cartItem}
        />
      </ReactModal>
    </>
  );
};
