import React from 'react';
import classNames from 'classnames';

export const ComponentsGrid = ({
  className,
  children,
  type,
}) => {
  return (
    <div className={classNames('components-grid', {
      [className]: className,
      'components-grid--colors': type === 'colors',
      'components-grid--icons': type === 'icons',
      'components-grid--forms': type === 'forms',
      'components-grid--fonts': type === 'fonts',
      'components-grid--modals': type === 'modals',
      'components-grid--inline': type === 'inline',
      'components-grid--inline components-grid--inline-dark': type === 'inline-dark',

    })}
    >
      <div className="components-grid__container">
        {children.map((item, index) => {
          return (
            <div key={index} className="components-grid__item">{item}</div>
          );
        })}
      </div>
    </div>
  );
};
