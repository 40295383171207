import React, {useState} from 'react';

import WatchingImagePath from 'images/common/sticker/watching.png';

import ApiHelper from 'utils/api_helper';
import {useLanguageChange} from 'utils/use_language_change';

export const RecordWeekly = ({record, project}) => {
  if (!record || !project) return false;

  const [weeklyRecord, setWeeklyRecord] = useState(record);
  const [weeklyProject, setWeeklyProject] = useState(project);

  useLanguageChange(() => {
    ApiHelper.get('/discover_record_of_the_week').then((res) => {
      setWeeklyRecord(res.response.record);
      setWeeklyProject(res.response.project);
    });
  });

  return (
    <section
      className="p-discover__section--weekly c-column--direction"
      style={{background: `${weeklyRecord.background_color}`}}
    >
      <div className="c-label--pages--white p-discover__weekly-label">PROJECT OF THE WEEK</div>
      <h4 className="c-column__title p-discover__weekly-info">
        {weeklyProject.title}
        <span>{weeklyProject.artist_name}</span>
        <span className="p-discover__weekly-genre">{weeklyProject.item?.tags?.join(', ')}</span>
      </h4>
      <div className="p-discover__weekly-image">
        <a href={`/projects/${weeklyProject.id}`}>
          <img src={weeklyProject.rendered_image_url} className="c-column__image" />
        </a>
      </div>
      <p className="p-discover__weekly-text u-hidden-tablet">{weeklyRecord.text}</p>
      <a href={`/projects/${weeklyProject.id}`} className="c-link p-discover__explore-link">
        EXPLORE THE PROJECT &#8594;
      </a>
      <img src={WatchingImagePath} className="c-sticker p-discover__weekly-seal" />
    </section>
  );
};
