import React, {useState, useEffect, useRef} from 'react';
import Prismic from '@prismicio/client';
import {prismicClient} from 'prismic/shared/cms/prismic';
import {useLanguageChange} from 'utils/use_language_change';
import {ReactButton} from 'shared/components/buttons/react_button/react_button';
import {CuratedFeaturedMedium} from '../../components/curated_featured/curated_featured_medium';
import {CuratedFeaturedFallback} from '../../components/curated_featured/curated_featured_fallback';

export const Releases = ({linkLabel}) => {
  const [releases, setReleases] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [nextPage, setNextPage] = useState(null);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const pageRef = useRef(1);
  const query = Prismic.Predicates.at('document.type', 'record_of_the_week');

  const queryPrismic = async (options) => {
    setIsLoading(true);
    const locales = window.constants.prismic_locales;
    const response = await prismicClient.query(query, {
      lang: locales[options.locale || window.constants.locale || 'en'],
      pageSize: 5,
      page: pageRef.current,
      orderings: '[document.last_publication_date desc]',
    });
    response && setNextPage(response.next_page);
    response && setButtonDisabled(!response.next_page);
    response && setReleases((prev) => [...prev, ...response.results]);
  };

  const fetchReleases = async (options = {}) => {
    try {
      return await queryPrismic(options);
    } catch (err) {
      console.error(err);
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchNextPage = (event) => {
    event.preventDefault();
    setButtonDisabled(true);
    if (nextPage) {
      pageRef.current += 1;
      fetchReleases({});
    }
  };

  useEffect(() => {
    fetchReleases();
  }, []);

  useLanguageChange((event) => {
    const locale = event.detail.locale;
    fetchReleases({locale});
  });

  if (isError) return <CuratedFeaturedFallback />;

  return (
    <div className="c-curated-releases__releases-wrapper">
      {releases?.map((release, index) => {
        const details = release?.data;
        return (
          <div data-testid={`test-id-${index}`} className="c-curated-releases__card-container" key={index}>
            <CuratedFeaturedMedium
              backgroundColor={details?.background_color}
              fontColor={details?.font_color || '#000000'}
              image={details?.project.rendered_image_url}
              article={details}
              linkLabel={linkLabel}
              text={details?.text}
              labelTheme="white"
            />
          </div>
        );
      })}
      {nextPage && (
        <div className="c-curated-releases__button-wrapper">
          <div className="c-curated-releases__button-container" wovn-ignore="">
            <ReactButton
              text={isLoading ? 'LOADING' : 'LOAD MORE'}
              width="full"
              disabled={buttonDisabled}
              onClick={(event) => fetchNextPage(event)}
            />
          </div>
        </div>
      )}
    </div>
  );
};
