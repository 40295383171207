import React, {useRef, useState} from 'react';
import {connectSortBy} from 'react-instantsearch-dom';

import {useClickOutside} from 'utils/use_click_outside';

const CustomSortBy = ({items, refine}) => {
  const [selectedValue, setSelectedValue] = useState('Sort by...');
  const [isOpened, setIsopened] = useState(false);

  const selectContainer = useRef();

  useClickOutside([selectContainer], isOpened, () => setIsopened(false));

  const selectValue = (item) => {
    setSelectedValue(item.label);
    refine(item.value);
  };

  return (
    <div
      className="c-select c-select--sort"
      status={isOpened ? 'active' : ''}
      onClick={() => setIsopened(!isOpened)}
      ref={selectContainer}
    >
      <div className="c-select__label">
        <span>{selectedValue}</span>
        <input type="hidden" />
        <div className="c-select__menu">
          {items.map((item) => (
            <div
              className="c-select__menu__option"
              key={item.value}
              style={{fontWeight: item.isRefined ? 'bold' : ''}}
              value={item.value}
              onClick={() => selectValue(item)}
            >
              {item.label}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default connectSortBy(CustomSortBy);
