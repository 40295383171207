// @flow
import React from 'react';
import styled from 'styled-components';
import {RichText} from 'prismic-reactjs';
import {cssSmallPlusText} from '../../styles/typography';
import type {StorySliceFeaturedImages} from '../../cms/story';
import {
  getFeaturedImageAltText,
  getFeaturedImageCaption,
  getFeaturedImageUrl,
  getSliceFeaturedImages,
  isSliceHalfSize,
} from '../../cms/story';
import {
  breakpointTabletOnly,
  breakpointMobileOnly,
} from '../../styles/responsive';

export const StyledContainer = styled.div`
  width: 100%;
  max-width: 1440px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 100px;
  padding: ${props => (props.halfSize ? '0px 40px' : '0px')};
  column-gap: 40px;

  ${breakpointTabletOnly} {
    flex-direction: column;
    align-items: center;
    row-gap: 40px;
    margin-bottom: 40px;
    padding: 0px 15px;
  }

  ${breakpointMobileOnly} {
    row-gap: unset;
  }
`;

export const StyledImageWrapper = styled.div`
  flex: 1;
  max-height: ${props => (props.halfSize ? '825px' : '765px')};
  max-width: ${props => (props.halfSize ? '660px' : '1360px')};
  margin: ${props => (props.halfSize ? '0px' : '0px 40px')};

  ${breakpointTabletOnly} {
    width: 100%;
    height: 100%;
    flex-wrap: wrap;
    flex: 0 1 100%;
    margin: ${props => (props.halfSize ? '0px 0px 40px' : '0px')};
  }

  ${breakpointMobileOnly} {
    width: 100%;
    height: 100%;
    flex-wrap: wrap;
    flex: 0 1 100%;

    &:last-child {
      margin: 0px;
      padding-bottom: 0px;
    }
  }
`;

export const StyledImage = styled.span`
  display: block;
  position: relative;
  overflow: hidden;
  height: ${props => (props.halfSize ? '0px' : '100%')};
  width: 100%;
  max-height: ${props => (props.halfSize ? '825px' : '')};
  max-width: ${props => (props.halfSize ?'660px' :  '')};
  padding-top: ${props => (props.halfSize ? '125%' : '')};

  ${breakpointTabletOnly} {
    max-width: unset;
    min-width: 330px;
    min-height: ${props => (props.halfSize ? '412.5px' : 'unset')};
  }

  img {
    position: ${props => (props.halfSize ? 'absolute' : '')};
    display: block;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    max-height: ${props => (props.halfSize ? '825px' : '765px')};
    border: 2px solid #000000;
    object-fit: cover;

    ${breakpointTabletOnly} {
      max-height: unset;
    }
  }
`;

export const StyledCaption = styled.p`
  ${cssSmallPlusText};
  text-transform: uppercase;
  margin-top: 15px;

  ${breakpointTabletOnly} {
    margin-top: 10px;
  }
`;

type Props = {
  slice: StorySliceFeaturedImages,
};

export const FeaturedImages = ({ slice }: Props) => {
  const images = getSliceFeaturedImages(slice);
  const isHalfSize = isSliceHalfSize(slice) || images.length >= 2;
  return (
    <StyledContainer halfSize={isHalfSize}>
      {images.map((image, index) => (
        <StyledImageWrapper halfSize={isHalfSize} key={index.toString()}>
          <StyledImage halfSize={isHalfSize}>
            <img src={getFeaturedImageUrl(image)} alt={getFeaturedImageAltText(image)} />
          </StyledImage>
          <StyledCaption>
            <span wovn-ignore="">
              {RichText.asText(getFeaturedImageCaption(image))}
            </span>
          </StyledCaption>
        </StyledImageWrapper>
      ))}
    </StyledContainer>
  );
};
