import React from 'react';
import styled, {css} from 'styled-components';
import {RichText} from 'prismic-reactjs';
import {
  cssSharedBgImage,
  cssStoryType,
} from '../../../StoriesScreen/components/StoryPreview/StoryPreview';
import {
  cssGiantishHeading,
  cssGiantText,
  cssLargeishText,
} from 'prismic/shared/styles/typography';
import type {StoryFullMdl, StorySliceIntro} from 'prismic/shared/cms/story';
import {
  getSliceIntroImage,
  getSliceIntroPreTitle,
  getSliceIntroText,
  getSliceIntroTitle,
  getStoryCategory,
} from 'prismic/shared/cms/story';
import {breakpointTabletOnly, cssMobileSidePadding} from 'prismic/shared/styles/responsive';

const cssIsNoMargin = css`
  margin-bottom: 0px;
  ${breakpointTabletOnly} {
    margin-bottom: 0px;
  }
`;

const StyledContainer = styled.div`
  width: 100%;
  margin-bottom: 100px;
  ${breakpointTabletOnly} {
    margin-bottom: 60px;
  }

  ${props => (props.noMargin ? cssIsNoMargin : '')};
`;

const StyledImageWrapper = styled.div`
  padding: 0px 40px;

  ${breakpointTabletOnly} {
    padding: 0px 15px;
  }
`

const StyledImageContainer = styled.span`
  display: block;
  position: relative;
  overflow: hidden;
  height: 0px;
  width: 100%;
  max-height: 765px;
  padding-top: 56.25%;
  border: 2px solid black;
  ${breakpointTabletOnly} {
    padding-top: 80%;
    min-height: 412.5px;
  }
`

const StyledFeaturedImage = styled.img`
  position: absolute;
  display: block;
  top: 0px;
  left: 0px;
  object-fit: cover;
  height: 100%;
  width: 100%;
`;

const StyledType = styled.div`
  ${cssStoryType};

  ${breakpointTabletOnly} {
    top: 10px;
    left: 10px;
  }
`;

const StyledIntro = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 60px;

  ${props => (!props.noMargin ? cssMobileSidePadding : '')};

  ${breakpointTabletOnly} {
    margin-top: 26px;
  }

  h2,
  h1 {
    max-width: 983px;
    margin-top: 10px;

    ${breakpointTabletOnly} {
      margin-top: 5px;
    }
  }

  h2 {
    ${cssGiantText};

    ${breakpointTabletOnly} {
      font-size: 30px;
      font-weight: bold;
      letter-spacing: -1px;
      line-height: 38px;
    }
  }

  h1 {
    ${cssGiantishHeading};
    font-weight: 500;

    ${breakpointTabletOnly} {
      font-size: 36px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 40px;
    }
  }

  p {
    ${cssLargeishText};
    max-width: 843px;
    &:first-of-type {
      margin-top: 30px;

      ${breakpointTabletOnly} {
        margin-top: 20px;
        padding: 0px 30px;
        font-size: 22px;
        font-weight: 500;
        letter-spacing: -0.37px;
        line-height: 26px;
      }
    }
  }
`;

type Props = {
  story: StoryFullMdl,
  slice: StorySliceIntro,
  type: string | undefined,
  noMargin: boolean | undefined,
};

export const StoryHeader = ({story, slice, type, noMargin}: Props) => {
  return (
    <StyledContainer noMargin={noMargin}>
      <StyledImageWrapper>
        <StyledImageContainer>
          <StyledFeaturedImage src={getSliceIntroImage(slice)} />
        </StyledImageContainer>
      </StyledImageWrapper>
      <StyledIntro noMargin={noMargin}>
        <StyledType wovn-ignore="">{getStoryCategory(story) || type}</StyledType>
        <h2 wovn-ignore="">{RichText.asText(getSliceIntroPreTitle(slice))}</h2>
        <h1 wovn-ignore="">{RichText.asText(getSliceIntroTitle(slice))}</h1>
        <p wovn-ignore="">{RichText.asText(getSliceIntroText(slice))}</p>
      </StyledIntro>
    </StyledContainer>
  );
};
