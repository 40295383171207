import React from 'react';
import {CuratedFeaturedLarge} from '../../components/curated_featured/curated_featured_large';
import {CuratedFeaturedFallback} from '../../components/curated_featured/curated_featured_fallback';
import {Carousel} from '../components/curated_carousel/curated_carousel';

export const StoriesSection = ({
  carouselHeading,
  carouselButtonLabel,
  featuredLinkLabel,
  stories,
  featuredStory,
  isError,
  isLoading,
}) => {
  const rootPath = '/stories/';

  if (isError) return <CuratedFeaturedFallback />;
  if (isLoading) return <div />;
  return (
    <>
      <CuratedFeaturedLarge
        rootPath={rootPath}
        linkLabel={featuredLinkLabel}
        article={featuredStory[0]}
        cardTheme="light"
        labelTheme="white"
      />
      <Carousel
        rootPath={rootPath}
        articles={stories}
        title={carouselHeading}
        buttonLabel={carouselButtonLabel}
        buttonLink="/stories"
        cardType="stories"
      />
    </>
  );
};
