import React from 'react';
import classNames from 'classnames';

export const DeleteAccountSection = ({
  className,
  isUserDeletable,
}) => {
  return (
    <div className={classNames('c-delete-account-section', {
      [className]: className,
    })}
    >
      <div className="c-delete-account-section__title">Delete account</div>
      <div className="c-delete-account-section__content">
        <div className="c-delete-account-section__description">
          Please note that your account cannot be reopened after it has been deleted,
          and all personal information and order information will be permanently deleted as well.&nbsp;
          <a href="https://support.qrates.com/l/en/article/oAWkRpsSer-can-i-delete-my-artist-account" className="c-link">Learn more</a>
        </div>
        <a
          className={classNames('c-delete-account-section__button c-button c-button--black', {
            'c-button--disabled': !isUserDeletable,
          })}
          href="#"
          data-izimodal-open=".p-account__edit-modal--delete"
        >
          DELETE ACCOUNT
        </a>
      </div>
    </div>

  );
};
