import {Record} from 'immutable';

import ProjectDashboardRoutes from 'dashboard/modules/routes';
import ProjectSpec from './project_spec';
import {ProductOrder} from './product_order';
import Item from './item';
import SellingCondition from './selling_condition';
import OrderItem from './order_item';
import ProjectSettingPageStates from './project_settings_page_states';

const _Record = Record({
  id: null,
  slug: null,
  base_project_id: null,
  user_id: null,
  project_type: null,
  stock_quantity: 0,
  total_balance: 0,
  total_gross_income: 0,
  outstanding_revenue: 0,

  spec: new ProjectSpec(),
  selling_condition: new SellingCondition(),
  selling_conditions: [],
  product_order: new ProductOrder(),
  press_messages: [],

  visualizerImage: null,
  front_sleeve_image_url: null,
  back_sleeve_image_url: null,
  spine_sleeve_image_url: null,
  inner_sleeve_image_url: null,
  a_label_image_url: null,
  b_label_image_url: null,
  a_vinyl_texture_url: null,
  b_vinyl_texture_url: null,
  rendered_image_url: null,
  sleeve_thumbnail_image_url: null,
  vinyl_thumbnail_image_url: null,
  cassette_thumbnail_image_url: null,
  case_thumbnail_image_url: null,
  state: null,
  currency: '',
  order_items_summary: {},
  stock_transfers: [],
  download_conditions: [],

  artist_name: '',

  is_visible: null,
  published: null,
  finished: null,
  ready_for_publish: null,
  in_production: null,
  paid: null,
  user_name: '',
  created_at: null,
  item: new Item(),
  settings_page_states: new ProjectSettingPageStates(),
  paypal_options: {},
  unread_messages_count: 0,
  restart_requests_count: 0,
  url: null,
  dashboard_path: null,
  edit_artist_path: null,
  setup_project_path: null,
  condition_message: null,
  artwork_confirmation_file_url: null,
  test_production_file_url: null,
  background_color: null,
  redis_repressing: null,
}, 'ProjectRecord');

export default class Project extends _Record {
  static fromJSON(json) {
    if (json.product_order) {
      const {spec, total_amount} = json.product_order;

      json.product_order = ProductOrder.fromJSON(json.product_order);
      json.cost = total_amount;
      json.spec = ProjectSpec.fromJSON(spec);
    } else {
      json.spec = new ProjectSpec();
      json.product_order = new ProductOrder();
    }

    if (json.order_items) {
      json.order_items = json.order_items.map((order_item) => OrderItem.fromJSON(order_item));
    }

    if (json.selling_conditions) {
      json.selling_conditions = json.selling_conditions.map((selling_condition) => {
        let condition = selling_condition;
        // To be sure that selling_conditions array includes the same changed (but not saved) selling_condition.
        // On the rails side selling_condition and selling_conditions are two different associations on project model.
        // So when we modify selling_condition (but not save), selling_conditions returns saved data different from modified.
        if (json.selling_condition && json.selling_condition.id && json.selling_condition.id == selling_condition.id) {
          condition = json.selling_condition;
        }

        return SellingCondition.fromJSON(condition);
      });
    }

    if (json.settings_page_states) {
      json.settings_page_states = ProjectSettingPageStates.fromJSON(json.settings_page_states);
    }

    json.item = Item.fromJSON(json.item);
    json.selling_condition = SellingCondition.fromJSON(json.selling_condition);

    return new Project(json);
  }

  isPublished() {
    const publishedStates = ['opened', 'fixed', 'released'];
    return this.item && publishedStates.includes(this.item.state);
  }

  readyForStartProduction() {
    const labels = [];
    labels.push(this.settings_page_states.vinyl);
    labels.push(this.settings_page_states.crowdfunding);
    labels.push(this.settings_page_states.sales);
    labels.push(this.settings_page_states.quantity);
    labels.push(this.settings_page_states.page_hub);
    labels.push(this.settings_page_states.distribution);
    labels.push(this.settings_page_states.tracklist);
    labels.push(this.settings_page_states.artwork_hub);
    labels.push(this.settings_page_states.audio_hub);
    labels.push(this.settings_page_states.copyright);

    return !labels.some((state) => state == 'todo');
  }

  fundingSellingCondition() {
    return this.selling_conditions.find((condition) => condition.selling_type == 'funding');
  }

  sellSellingCondition() {
    return this.selling_conditions.find((condition) => condition.selling_type == 'sell');
  }

  editArtistPath() {
    return `${this.edit_artist_path}?callback=${ProjectDashboardRoutes.pageSettingsPath(this.id)}`;
  }

  isVinylProject() {
    return this.getIn(['product_order', 'type']) === 'Product::VinylOrder';
  }

  isCassetteProject() {
    return this.getIn(['product_order', 'type']) === 'Product::CassetteOrder';
  }
}
