import React from 'react';
import {RichText} from 'prismic-reactjs';

import paidSticker from 'images/common/sticker/paid.svg';

import {SectionsContainer} from 'public/components/sections_container/sections_container';

export const ForArtistsIntroSection = ({
  forArtistsLabel,
  forArtistsTitle,
  forArtistsText,
  forArtistsImage1,
  forArtistsImage2,
}) => {
  return (
    <div className="c-for-artists-intro-section">
      <SectionsContainer>
        <div className="c-for-artists-intro-section__top-grid">
          <div className="c-for-artists-intro-section__top-left">
            <div wovn-ignore="" className="c-label--pages--black">{forArtistsLabel}</div>
            <h2 wovn-ignore="" className="c-for-artists-intro-section__heading u-hidden-tablet">
              {RichText.asText(forArtistsTitle)}
            </h2>
            <h2 wovn-ignore="" className="c-for-artists-intro-section__heading u-hidden-desktop-only">
              {RichText.asText(forArtistsTitle)}
            </h2>
            <p wovn-ignore="" className="c-for-artists-intro-section__explanation">
              {RichText.asText(forArtistsText)}
            </p>
          </div>
          <div className="c-for-artists-intro-section__top-right">
            <div className="c-for-artists-intro-section__image--one">
              <img src={forArtistsImage1?.url} alt="Why Qrates" />
            </div>
          </div>
        </div>
        <div className="c-for-artists-intro-section__bottom-grid">
          <div className="c-for-artists-intro-section__bottom-left">
            <div className="c-for-artists-intro-section__image--two">
              <img src={forArtistsImage2?.url} alt="Vinyl & Cassettes" />
            </div>
          </div>
          <div className="c-for-artists-intro-section__bottom-right">
            <img className="c-sticker c-for-artists-intro-section__paid-sticker" src={paidSticker} />
          </div>
        </div>
      </SectionsContainer>
    </div>
  );
};
