import React from 'react';
import classNames from 'classnames';

export const ToggleSwitch = ({
  className,
  checked,
  onChange,
  name,
  readOnly,
  locked,
  label,
  direction = 'right',
  toogleAlign,
}) => {
  const handleChange = (event) => {
    !readOnly && !locked && onChange(event);
  };

  return (
    <div className={classNames('c-toggle-switch', {
      [className]: className,

      'is-checked': checked,
      'is-locked': locked,
      'is-readOnly': readOnly,

      // Label align
      'c-toggle-switch--align-top': toogleAlign === 'top',
    })}
    >
      {label && direction === 'left' && (
      <div className="c-toggle-switch__title c-toggle-switch__title--left">
        {label}
        {locked && <i className="c-toggle-switch__text-icon c-pictogram c-pictogram--lock" /> }
      </div>
      )}
      <label className="c-toggle-switch__label">
        <input
          className="c-toggle-switch__input"
          type="checkbox"
          name={name}
          checked={checked}
          onChange={handleChange}
          readOnly={readOnly || locked}
        />
      </label>
      {label && direction === 'right' && (
      <div className="c-toggle-switch__title">
        {label}
        {locked && <i className="c-toggle-switch__text-icon c-pictogram c-pictogram--lock" /> }
      </div>
      )}
    </div>
  );
};
