import React from 'react';
import {
  render,
  screen,
  fireEvent,
  waitFor,
} from '@testing-library/react';

import {TermsPage} from '../terms_page';

const data = {
  title: [{text: 'Terms Title'}],
  sections: [
    {heading: [{text: 'Terms1 Heading'}], body: [{text: 'Terms1 Body', type: 'paragraph', spans: []}]},
    {heading: [{text: 'Terms2 Heading'}], body: [{text: 'Terms2 Body', type: 'paragraph', spans: []}]},
  ],
};
let mockData = {
  title: [{text: 'Terms ES Title'}],
  sections: [
    {heading: [{text: 'Terms1 ES Heading'}], body: [{text: 'Terms1 ES Body', type: 'paragraph', spans: []}]},
    {heading: [{text: 'Terms2 ES Heading'}], body: [{text: 'Terms2 ES Body', type: 'paragraph', spans: []}]},
  ],
};

jest.mock('prismic/shared/cms/prismic', () => {
  return {
    prismicClient: {
      query: () => Promise.resolve({results: [{data: mockData}]}),
    },
  };
});

it('renders correct data', async () => {
  render(<TermsPage data={data} />);

  expect(screen.getByText('Terms Title')).toBeInTheDocument();
  expect(screen.getByText('Terms1 Heading')).toBeInTheDocument();
  expect(screen.getByText('Terms1 Body')).toBeInTheDocument();
  expect(screen.getByText('Terms2 Heading')).toBeInTheDocument();
  expect(screen.getByText('Terms2 Body')).toBeInTheDocument();
});

describe('with languagechange and data for the selected language', () => {
  const event = new CustomEvent('languagechange', screen, {detail: {locale: 'es'}});

  it('changes data', async () => {
    render(<TermsPage data={data} />);

    expect(screen.getByText('Terms Title')).toBeInTheDocument();
    expect(screen.getByText('Terms1 Heading')).toBeInTheDocument();
    expect(screen.getByText('Terms1 Body')).toBeInTheDocument();
    expect(screen.getByText('Terms2 Heading')).toBeInTheDocument();
    expect(screen.getByText('Terms2 Body')).toBeInTheDocument();

    fireEvent(window, event);

    await waitFor(() => {
      expect(screen.getByText('Terms ES Title')).toBeInTheDocument();
    });

    expect(screen.getByText('Terms1 ES Heading')).toBeInTheDocument();
    expect(screen.getByText('Terms1 ES Body')).toBeInTheDocument();
    expect(screen.getByText('Terms2 ES Heading')).toBeInTheDocument();
    expect(screen.getByText('Terms2 ES Body')).toBeInTheDocument();
  });
});

describe('with languagechange and without data for the selected language', () => {
  const event = new CustomEvent('languagechange', screen, {detail: {locale: 'es'}});

  beforeEach(() => {
    mockData = null;
  });

  it('does not change the data', async () => {
    render(<TermsPage data={data} />);

    expect(screen.getByText('Terms Title')).toBeInTheDocument();
    expect(screen.getByText('Terms1 Heading')).toBeInTheDocument();
    expect(screen.getByText('Terms1 Body')).toBeInTheDocument();
    expect(screen.getByText('Terms2 Heading')).toBeInTheDocument();
    expect(screen.getByText('Terms2 Body')).toBeInTheDocument();

    fireEvent(window, event);

    await waitFor(() => {
      expect(screen.getByText('Terms Title')).toBeInTheDocument();
    });

    expect(screen.getByText('Terms1 Heading')).toBeInTheDocument();
    expect(screen.getByText('Terms1 Body')).toBeInTheDocument();
    expect(screen.getByText('Terms2 Heading')).toBeInTheDocument();
    expect(screen.getByText('Terms2 Body')).toBeInTheDocument();
  });
});
