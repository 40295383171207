export const isFeatureSupported = ({feature, user}) => {
  const generalSupportedFeatures = window.constants.supported_features;
  const userSupportedFeatures = user?.supported_features || [];
  const supportedFeatures = [...generalSupportedFeatures, ...userSupportedFeatures].map((feature) => feature.feature_name);

  return supportedFeatures.includes(feature);
};

export const attachUsername = ({msg, name, commaSeparated = false}) => {
  const isName = name && name !== ' ' && name.length > 0;
  return isName ? `${msg}${commaSeparated ? ',' : ''} ${name}` : msg;
};
