import React, {useState} from 'react';
import algoliasearch from 'algoliasearch/lite';
import {InstantSearch, connectHits, connectMenu} from 'react-instantsearch-dom';
import {sortBy} from 'lodash';
import {formatDate} from 'utils/date';
import {useLanguageChange} from 'utils/use_language_change';

export const DiscoverStories = ({algolia_id, algolia_search_api_key}) => {
  const searchClient = algoliasearch(algolia_id, algolia_search_api_key);
  const locale = window.constants.prismic_locales[window.constants.locale] || window.constants.prismic_locales.en;
  const [indexName, setIndexName] = useState(`Prismic::Story_${locale}`);

  useLanguageChange((event) => {
    setIndexName(`Prismic::Story_${window.constants.prismic_locales[event.detail.locale]}`);
  });

  const Hits = (props) => {
    let {hits} = props;
    hits = hits || [];
    const firstStory = hits[0];
    const secondStory = hits[1];
    const thirdStory = hits[2];

    return (
      <>
        <div className="c-column--article-one">
          {firstStory && (
            <div className="c-column--article-one__left c-column__section">
              <a href={`/stories/${firstStory.uid}`} target="_blank">
                <div className="c-column__image p-discover__stories-image-one">
                  {firstStory.preview_image && (
                    <img src={firstStory.preview_image} className="p-lists__cover-image" />
                  )}
                  <div className="c-label--pages--white">{firstStory.story_category?.toUpperCase()}</div>
                </div>
              </a>
              <div className="c-column__title">
                {firstStory.preview_pre_heading}
                <span className="p-on-artists__heading">{firstStory.preview_heading}</span>
              </div>
              <div className="c-column__explanation">{firstStory.preview_text}</div>
              <div className="c-column__data">{formatDate(firstStory.published_date)}</div>
            </div>
          )}
          {secondStory && (
            <div className="c-column--article-one__right c-column__section">
              <a href={`/stories/${secondStory.uid}`} target="_blank">
                <div className="c-column__image p-discover__stories-image-two">
                  {secondStory.preview_image && (
                    <img src={secondStory.preview_image} className="p-lists__cover-image" />
                  )}
                  <div className="c-label--pages--white">{secondStory.story_category?.toUpperCase()}</div>
                </div>
              </a>
              <div className="c-column__title">
                {secondStory.preview_pre_heading}
                <span className="p-on-artists__heading">{secondStory.preview_heading}</span>
              </div>
              <div className="c-column__explanation">{secondStory.preview_text}</div>
              <div className="c-column__data">{formatDate(secondStory.published_date)}</div>
            </div>
          )}
        </div>
        {thirdStory && (
          <a href={`/stories/${thirdStory.uid}`} target="_blank">
            <section className="p-discover--interview-two c-column--article-center" style={{backgroundImage: `url(${thirdStory.preview_image})`}}>
              <div className="p-discover--article-label c-label--pages c-label--white">
                {thirdStory.story_category?.toUpperCase()}
              </div>
              <div>
                <h3 className="c-column__title">
                  {thirdStory.preview_pre_heading}
                  <span className="p-on-artists__heading">{thirdStory.preview_heading}</span>
                </h3>
                <p className="c-column__explanation">{thirdStory.preview_text}</p>
                <p className="c-column__data">{formatDate(thirdStory.published_date)}</p>
              </div>
            </section>
          </a>
        )}

        <div className="p-section-header__button--tablet-control c-button-grid--center">
          <a href="/stories" className="c-button c-button--black">BROWSE ALL STORIES</a>
        </div>
      </>
    );
  };
  const CustomHits = connectHits(Hits);
  const MenuSelect = (props) => {
    const {items, refine, currentRefinement} = props;
    const categories = sortBy(items, [(i) => i.label]);

    return (
      <nav>
        <ul>
          <li data-status={!currentRefinement && 'current'}>
            <span
              onClick={(event) => {
                event.preventDefault();
                refine('');
              }}
            >
              LATEST
            </span>
          </li>
          {categories.map((category) => (
            <li
              data-status={currentRefinement === category.label && 'current'}
              key={category.label}
            >
              <span
                onClick={(event) => {
                  event.preventDefault();
                  refine(category.value);
                }}
              >
                {category.label?.toUpperCase()}
              </span>
            </li>
          ))}
        </ul>
      </nav>
    );
  };
  const CustomMenuSelect = connectMenu(MenuSelect);

  return (
    <InstantSearch indexName={indexName} searchClient={searchClient}>
      <section className="p-discover__section--stories">
        <div className="p-section-header--discover">
          <div className="p-section-header__title">
            <h2>Stories</h2>
            <CustomMenuSelect attribute="story_category" />
          </div>
          <div className="p-section-header__button--pc">
            <a href="/stories" className="c-button c-button--black">BROWSE ALL STORIES</a>
          </div>
        </div>
        <CustomHits />
      </section>
    </InstantSearch>
  );
};
