import {Record} from 'immutable';
import StoredFile from './stored_file';

const _Record = Record({
  id: null,
  name: null,
  country_and_city: null,
  profile: null,
  code: null,
  url_1: null,
  url_2: null,
  url_3: null,
  url_4: null,
  url_5: null,
  payment_account_paypal: null,
  payment_account_stripe: null,
  payment_method_items: [],
  cover_image: new StoredFile(),
  profile_image: new StoredFile(),

}, 'ArtistRecord');

export default class Artist extends _Record {
  static fromJSON(json) {
    if (json) {
      json.cover_image = StoredFile.fromJSON(json.cover_image);
      json.profile_image = StoredFile.fromJSON(json.profile_image);

      return new Artist(json);
    }

    return new Artist();
  }
}
