import React, {useState} from 'react';
import Select from 'react-select';
import Creatable from 'react-select/creatable';
import classNames from 'classnames';

export const CurrencySelectField = ({
  className,
  name,
  value,
  label,
  placeholder,
  disabled,
  onChange,
  creatable = false,
}) => {
  const [currency, setCurrency] = useState(value);
  const options = [
    {
      label: '$ USD',
      value: 'USD',
    },
    {
      label: '£ GBP',
      value: 'GBP',
    },
    {
      label: '€ EUR',
      value: 'EUR',
    },
    {
      label: '¥ JPY',
      value: 'JPY',
    },
  ];

  const onCurrencyChange = (option) => {
    onChange && !disabled && onChange(option);
    setCurrency(option.value);
  };

  let currentValue = options.find((option) => {
    return option.value === currency;
  });
  if (value) {
    currentValue ||= {value, label: value};
  }
  const SelectTag = creatable ? Creatable : Select;

  return (
    <div className={classNames('c-currency-select-field', {
      [className]: className,
      'is-disabled': disabled,
    })}
    >
      {label && <div className="c-currency-select-field__label">{label}</div>}
      <input type="hidden" name={name} />
      <SelectTag
        className={classNames('c-currency-select-field__input', {'is-locked': disabled})}
        classNamePrefix="c-currency-select-field"
        name={name}
        placeholder={placeholder}
        value={currentValue || null}
        options={options}
        onChange={onCurrencyChange}
        isDisabled={disabled}
        isSearchable={false}
        openMenuOnClick={!disabled}
      />
    </div>
  );
};
