import React from 'react';
import {
  render,
  screen,
  fireEvent,
  waitFor,
} from '@testing-library/react';

import {RecordWeekly} from '../record_weekly';

jest.mock('utils/api_helper', () => {
  return {
    get: () => Promise.resolve({
      response: {
        record: {
          text: 'Record Text 2',
        },
        project: {
          title: 'Project Title 2',
          artist_name: 'Artist Name 2',
          item: {
            tags: ['Rock', 'Punk', 'Blues'],
          },
        },
      },
    }),
  };
});

const record = {
  text: 'Record Text',
};

const project = {
  title: 'Project Title',
  artist_name: 'Artist Name',
  item: {
    tags: ['Metal', 'Pop', 'Blues'],
  },
};

it('renders correct data', async () => {
  render(<RecordWeekly record={record} project={project} />);

  expect(screen.getByText('PROJECT OF THE WEEK')).toBeInTheDocument();
  expect(screen.getByText('Project Title')).toBeInTheDocument();
  expect(screen.getByText('Artist Name')).toBeInTheDocument();
  expect(screen.getByText('Metal, Pop, Blues')).toBeInTheDocument();
  expect(screen.getByText('Record Text')).toBeInTheDocument();
  expect(screen.getByText(/EXPLORE THE PROJECT/)).toBeInTheDocument();
});

describe('with languagechange', () => {
  const event = new CustomEvent('languagechange', screen);

  it('replaces data', async () => {
    render(<RecordWeekly record={record} project={project} />);

    expect(screen.getByText('Project Title')).toBeInTheDocument();
    expect(screen.getByText('Artist Name')).toBeInTheDocument();
    expect(screen.getByText('Metal, Pop, Blues')).toBeInTheDocument();
    expect(screen.getByText('Record Text')).toBeInTheDocument();

    fireEvent(window, event);

    await waitFor(() => {
      expect(screen.getByText('Project Title 2')).toBeInTheDocument();
    });

    expect(screen.queryByText('Project Title')).not.toBeInTheDocument();
    expect(screen.queryByText('Artist Name')).not.toBeInTheDocument();
    expect(screen.queryByText('Metal, Pop, Blues')).not.toBeInTheDocument();
    expect(screen.queryByText('Record Text')).not.toBeInTheDocument();

    expect(screen.getByText('Artist Name 2')).toBeInTheDocument();
    expect(screen.getByText('Rock, Punk, Blues')).toBeInTheDocument();
    expect(screen.getByText('Record Text 2')).toBeInTheDocument();
  });
});

it('renders nothing when record is not present', async () => {
  render(<RecordWeekly record={null} project={project} />);

  expect(screen.queryByText('PROJECT OF THE WEEK')).not.toBeInTheDocument();
});

it('renders nothing when project is not present', async () => {
  render(<RecordWeekly record={record} project={null} />);

  expect(screen.queryByText('PROJECT OF THE WEEK')).not.toBeInTheDocument();
});
