import React from 'react';

export const CareerCard = ({
  title, location, description, groupsList, link,
}) => {
  return (
    <div className="с-career-card">
      <div className="с-career-card__location">{location || 'TOKYO / JAPAN'}</div>
      <h2 className="с-career-card__title"><span className="f-adjust-ja">{title}</span></h2>
      <p className="с-career-card__description"><span className="f-adjust-ja">{description}</span></p>
      <ul className="с-career-card__groups-list">
        {groupsList && groupsList.map((group, index) => {
          return (
            <li key={index}>
              <h3 className="с-career-card__group-title"><span className="f-adjust-ja">{group.name}</span></h3>
              <ul className="с-career-card__parameters-list">
                {group.list.map((listItem, index) => {
                  return (
                    <li className="с-career-card__parameters-list-item" key={index}><span className="f-adjust-ja">{listItem}</span></li>
                  );
                })}
              </ul>
            </li>
          );
        })}
      </ul>
      <div className="с-career-card__button">
        <a href={link.url} className="c-button c-button--white-line-black">{link.name}</a>
      </div>
    </div>
  );
};
