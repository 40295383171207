export const lockScroll = {
  enable: () => {
    const pagePosition = window.scrollY;

    document.body.classList.add('scroll-lock');
    document.body.dataset.position = pagePosition;
    document.body.style.top = `${-pagePosition}px`;
  },
  disable: () => {
    const pagePosition = parseInt(document.body.dataset.position, 10);

    document.body.style.top = 'auto';
    document.body.classList.remove('scroll-lock');
    window.scroll({top: pagePosition, left: 0});
    document.body.removeAttribute('data-position');
  },
};
