import React, {useEffect, useRef, useState} from 'react';
import classNames from 'classnames';

export const SectionNavigationBar = ({
  menuItems,
}) => {
  const [currentItem, setCurrentItem] = useState(menuItems[0]?.id);
  const [isHeaderFixed, setIsHeaderFixed] = useState(false);

  const navigationBarElement = useRef();
  const navigationBarContainerElement = useRef();

  const onClick = (e, id) => {
    e.preventDefault();
    const section = document.querySelector(`#${id}`).offsetTop;

    document.body.scrollTo({top: section - 200, behavior: 'smooth'});
  };

  const navScrollToCurrentTab = () => {
    const navigation = navigationBarContainerElement?.current;
    if (navigation) {
      const navigationContainer = navigation.querySelector('.c-section-navigation-bar__list');
      const currentTab = navigation.querySelector('.is-current');
      const containerPadding = (navigation.offsetWidth - currentTab.offsetWidth) / 2;

      if (navigation.offsetWidth < navigationContainer.offsetWidth) {
        navigationContainer.style.padding = `0px ${containerPadding}px`;

        navigation.scrollLeft = currentTab.offsetLeft - ((navigation.offsetWidth - currentTab.offsetWidth) / 2);
      }
    }
  };

  const fixedHeaderOnScroll = (e) => {
    const content = navigationBarElement?.current;
    const scrolled = document.body.scrollTop;
    const position = content.offsetTop;
    if (scrolled > position + 8) {
      setIsHeaderFixed(true);
    } else {
      setIsHeaderFixed(false);
    }

    menuItems.forEach((item) => {
      const section = document.querySelector(`#${item.id}`);
      if (
        section.offsetTop <= scrolled + 200 && section.offsetTop + section.offsetHeight > scrolled
      ) {
        setCurrentItem(item.id);
      }
    });
  };

  const onScroll = () => {
    fixedHeaderOnScroll();
    navScrollToCurrentTab();
  };

  useEffect(() => {
    window.addEventListener('resize', navScrollToCurrentTab);
    document.body.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('resize', navScrollToCurrentTab);
      document.body.removeEventListener('scroll', onScroll);
    };
  }, []);

  return (
    <div
      className={classNames(
        'c-section-navigation-bar',
        {'is-fixed': isHeaderFixed})}
      ref={navigationBarElement}
    >
      <div className="c-section-navigation-bar__container" ref={navigationBarContainerElement}>
        <ul className="c-section-navigation-bar__list">
          {menuItems.map((item, index) => {
            return (
              <li
                className={classNames(
                  'c-section-navigation-bar__list-item',
                  {'is-current': currentItem === item.id})}
                key={item.id || index}
              >
                <a wovn-ignore="" href={`#${item.id}`} onClick={(e) => onClick(e, item.id)} data-testid="navigation-link">
                  {item.name}
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};
