import React from 'react';
import classNames from 'classnames';
import {connectMenu, Index, Menu} from 'react-instantsearch-dom';

export const CategoryFilterTabs = ({className}) => {
  const ItemsList = (props) => {
    const {items, refine, currentRefinement} = props;

    return (
      <ul className="c-category-filter-tabs__list">
        <li
          className="c-category-filter-tabs__list-item"
          key="all"
          onClick={() => refine('')}
        >
          <span className={classNames(
            'c-category-filter-tabs__link',
            {'active': !currentRefinement})}
          >
            ALL
          </span>
        </li>
        {items.map((item) => {
          return (
            <li
              className="c-category-filter-tabs__list-item"
              key={item.value}
              onClick={() => refine(item.value)}
            >
              <span className={classNames(
                'c-category-filter-tabs__link',
                {'active': item.isRefined})}
              >
                {item.label.toUpperCase()}
              </span>
            </li>
          )
        })}
      </ul>
    );
  }
  const CustomMenu = connectMenu(ItemsList);

  return (
    <div className={classNames('c-category-filter-tabs', {[className]: className})}>
      <CustomMenu attribute="story_category" />
    </div>
  );
}
