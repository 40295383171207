import React, {useEffect, useRef, useState} from 'react';
import classNames from 'classnames';

import {useClickOutside} from 'utils/use_click_outside';
import {isFeatureSupported} from 'utils/user_helper';
import ApiHelper from 'utils/api_helper';

export const LanguageSelector = ({
  currentUser,
  className,
}) => {
  const options = [
    {label: 'English', value: 'en'},
    {label: '日本語', value: 'ja'},
    // {label: 'Spanish', value: 'es'}, // TODO: enable when Spanish translation work is done.
  ];

  const endpoint = '/api/v1/users/set_locale';
  const [currentValue, setCurrentValue] = useState(window.constants?.locale || currentUser?.locale || options[0].value);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const isMultilanguageSupported = isFeatureSupported({feature: 'multilanguage', user: currentUser});
  if (!isMultilanguageSupported) return null;

  const currentOption = options.find((option) => {
    return option.value === currentValue;
  });

  const projectMenu = useRef();

  const setLanguage = (value) => {
    setCurrentValue(value);
    setIsMenuOpen(false);
  };

  const onChange = (option) => {
    const event = new CustomEvent('languagechange', {detail: {locale: option.value}});
    ApiHelper.post(endpoint, {locale: option.value}).then(() => {
      setLanguage(option.value);
      window.dispatchEvent(event);
      window.Wovnio.changeLang(option.value);
    });
  };

  useClickOutside(
    [projectMenu],
    isMenuOpen,
    (e) => setIsMenuOpen(false),
  );

  useEffect(() => {
    if (isMultilanguageSupported) {
      const browserLanguage = navigator.language?.match(/en|ja/)?.[0]; // TODO: add "es" when Spanish translation work is done.
      const setDefaultLanguage = () => {
        onChange({value: window.constants?.locale || currentUser?.locale || browserLanguage || options[0].value});
      };
      window.addEventListener('load', setDefaultLanguage);

      return () => window.removeEventListener('load', setDefaultLanguage);
    }
  }, []);

  useEffect(() => {
    const htmlElement = document.querySelector('html');

    // Fonts on the page
    options.forEach((option) => htmlElement.classList.toggle(`${option.value}-language`, currentValue === option.value));
  }, [currentValue]);

  return (
    <div
      className={classNames('c-language-selector', {
        [className]: className,
        'is-menu-open': isMenuOpen,
      })}
      ref={projectMenu}
    >
      <div onClick={() => setIsMenuOpen(!isMenuOpen)} className="c-language-selector__input">{currentOption.label}</div>
      <div className="c-language-selector__menu wovn-languages">
        {options.map((option, index) => {
          return (
            <div
              onClick={() => onChange(option)}
              className={classNames('c-language-selector__option wovn-switch', {
                'is-selected': option.value === currentValue,
              })}
              data-value={option.value}
              key={index}
            >
              {option.label}
            </div>
          );
        })}
      </div>
    </div>
  );
};
