import React, {useState, useEffect} from 'react';

import Swiper from 'swiper';
import Prismic from '@prismicio/client';
import {RichText} from 'prismic-reactjs';

import {prismicClient} from 'prismic/shared/cms/prismic';

export const OnQratesBanner = () => {
  const [swiper, setSwiper] = useState();
  const [artists, setArtists] = useState([]);

  useEffect(() => {
    prismicClient.query(Prismic.Predicates.at('document.type', 'artists_set')).then((response) => {
      const artistList = response.results[0].data.artists;
      setArtists(artistList.length <= 5 ? [...artistList, ...artistList] : artistList);
    });
  }, []);

  useEffect(() => {
    if (artists.length > 0 && !swiper) {
      setSwiper(new Swiper(
        '.c-banner__slider',
        {
          slideClass: 'c-banner__slide',
          wrapperClass: 'c-banner__wrapper',
          spaceBetween: 0,
          waitForTransition: false,
          speed: artists.length * 5000,
          autoplay: {
            delay: 0,
          },
          loop: true,
          slidesPerView: 'auto',
          loopedSlides: 1,
          allowTouchMove: false,
          disableOnInteraction: true,
        },
      ));
    }
  }, [artists.length]);

  return (
    <div className="c-banner">
      <div className="c-banner__slider">
        <div className="c-banner__wrapper">
          <div className="c-banner__slide">
            <ul className="c-banner__list">
              {artists.map((artist, index) => (
                <li key={index} className="c-banner__slide">
                  <a href={artist.url.url} className="c-link">{RichText.asText(artist.name)}</a>
                  &nbsp;IS ON QRATES
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
