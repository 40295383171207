import React, {useState} from 'react';

import ApiHelper from 'utils/api_helper';

import {ReactButton} from 'shared/components/buttons/react_button/react_button';
import {ModalHeading} from 'shared/components/modals/parts/content/modal_heading/modal_heading';
import {ModalText} from 'shared/components/modals/parts/content/modal_text/modal_text';

import VinylImagePath from 'images/dashboard/11-status-shipped.png';
import CassetteImagePath from 'images/dashboard/cassette/14.svg';

export const RestartRequestModal = ({
  onClose,
  project,
  setProject,
  purpose,
}) => {
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const productType = project.product_type?.original_value;
  const images = {vinyl: VinylImagePath, cassette: CassetteImagePath};
  const imagePath = images[productType];

  const purposeData = {
    repress: {
      title: 'Vote for a repress',
      description: `
        Request the artist to repress this project.
        Add this project to watchlist to see updates about this project.
        We will let you know when artist did repress this project by email.
      `,
      button: 'SEND REPRESS REQUEST',
    },
    reproduction: {
      title: 'Vote for a reproduction',
      description: `
        Request the artist to restart this project.
        Add this project to watchlist to see updates about this project.
        We will let you know when artist did restart this project by email.
      `,
      button: 'SEND REDUPLICATION REQUEST',
    },
    retry: {
      title: 'Vote for a retry',
      description: `
        Request the artist to retry this project.
        Add this project to watchlist to see updates about this project.
        We will let you know when artist did retry this project by email.
      `,
      button: 'SEND RETRY REQUEST',
    },
  };

  const requestRepress = () => {
    setButtonDisabled(true);
    ApiHelper.post(`${window.location.origin}/api/v1/projects/${project.id}/restart_request`).then((res) => {
      setProject(res.response);
      onClose();
    });
  };

  return (
    <div className="c-restart-request-modal">
      <ModalHeading className="c-restart-request-modal__heading">{purposeData[purpose]?.title}</ModalHeading>
      <ModalText className="c-restart-request-modal__description">{purposeData[purpose]?.description}</ModalText>
      <div className="c-restart-request-modal__image"><img src={imagePath} title="product image" alt="product" /></div>
      <div className="c-restart-request-modal__buttons">
        <ReactButton
          className="c-restart-request-modal__cancel-button"
          type="button"
          onClick={() => onClose()}
          theme="primary-white-border"
        >
          CANCEL
        </ReactButton>
        <ReactButton
          className="c-restart-request-modal__repress-button"
          type="button"
          onClick={requestRepress}
          disabled={buttonDisabled}
          theme="primary-black"
        >
          {purposeData[purpose]?.button}
        </ReactButton>
      </div>
    </div>
  );
};
