import React, {useState} from 'react';
import {RichText} from 'prismic-reactjs';
import styled from 'styled-components';

import {SectionsContainer} from 'public/components/sections_container/sections_container';

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  position: relative;
  height: 700px;
  padding: 50px 30px 45px 30px;
  background-image: url(${(props) => props.hoverBackground || props.defaultBackground});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #000000;
  @media (max-width: 990px) {
    padding: 30px 15px 25px 15px;
    height: 520px;
    background-position: 30% top;
  }
`;

export const TopDiscoverNavSection = ({
  label,
  links,
  defaultBackground,
  bottomText,
  bottomHref,
}) => {
  const [hoverBackground, setHoverBackground] = useState();

  const handleMouseOver = (linkBackground) => {
    hoverBackground !== linkBackground && setHoverBackground(linkBackground);
  };

  const handleMouseLeave = () => {
    setHoverBackground(null);
  };

  return (
    <Wrapper
      defaultBackground={defaultBackground}
      hoverBackground={hoverBackground}
    >
      <SectionsContainer>
        <div className="c-top-discover-nav-section">
          <div wovn-ignore="" className="c-label--pages c-label--white">
            {label}
          </div>
          <nav>
            <ul>
              {links?.map((link, index) => (
                <li key={index}>
                  <a
                    wovn-ignore=""
                    href={link.link_href}
                    onMouseEnter={(e) => handleMouseOver(link.background?.url)}
                    onMouseLeave={() => handleMouseLeave()}
                  >
                    {link.link_title}
                  </a>
                </li>
              ))}
            </ul>
          </nav>
          <a wovn-ignore="" className="c-top-discover-nav-section__link" href={bottomHref}>
            {RichText.asText(bottomText)}
          </a>
        </div>
      </SectionsContainer>
    </Wrapper>
  );
};
