import React from 'react';
import {RichText} from 'prismic-reactjs';
import {ReactButton} from 'shared/components/buttons/react_button/react_button';
import {Steps} from './for_artists_steps_desktop';

export const ProcessDesktop = ({
  detailsTitle,
  detailsLabelArtist,
  detailsLabelQrates,
  detailsIntroTitleArtist,
  detailsIntroTitleQrates,
  detailsIntroTextArtist,
  detailsIntroTextQrates,
  detailsDesktop,
  detailsCTAText,
  detailsCTALabel,
}) => {
  return (
    <div className="u-hidden-tablet">
      <div className="c-for-artists-details-section__banner">
        <span wovn-ignore="">{RichText.asText(detailsTitle)}</span>
      </div>
      <div className="c-for-artists-details-section__intro">
        <div className="c-for-artists-details-section__you-intro">
          <div>
            <div wovn-ignore="" className="c-label--pages c-label--jazz">
              {detailsLabelArtist}
            </div>
            <h1 wovn-ignore="">
              {RichText.asText(detailsIntroTitleArtist)}
            </h1>
            <br />
            <p wovn-ignore="">
              {RichText.asText(detailsIntroTextArtist)}
            </p>
          </div>
        </div>
        <div className="c-for-artists-details-section__qrates-intro">
          <div>
            <div wovn-ignore="" className="c-label--pages c-label--jazz">
              {detailsLabelQrates}
            </div>
            <h1 wovn-ignore="">
              {RichText.asText(detailsIntroTitleQrates)}
            </h1>
            <br />
            <p wovn-ignore="">
              {RichText.asText(detailsIntroTextQrates)}
            </p>
          </div>
        </div>
      </div>
      {detailsDesktop && (
        <>
          <Steps
            details={detailsDesktop}
            ctaText={detailsCTAText}
            ctaLabel={detailsCTALabel}
          />
          <div className="c-for-artists-details-section__start-new">
            <div className="c-for-artists-details-section__start-new-contents-container">
              <h3>
                <span wovn-ignore="" className="c-for-artists-details-section__start-new--title">
                  {detailsCTAText}
                </span>
                <i className="c-pictogram c-pictogram--align-text-bottom c-pictogram--arrow-forward" />
              </h3>
              <ReactButton
                wovn-ignore=""
                text={detailsCTALabel}
                type="link"
                link="/projects/start"
                theme="primary-black-border"
                align="right"
                className="c-for-artists-details-section__button"
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};
