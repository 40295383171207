/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import classNames from 'classnames';

export const ReactButton = ({
  type = 'div',
  text,
  theme,
  align,
  onClick,
  locked,
  width,
  disabled,
  className,
  link,
  children,
  icon,
  ...props
}) => {
  const icons = {
    right_arrow: 'c-pictogram c-pictogram--arrow-forward',
    receipt: 'c-pictogram c-pictogram--mi c-pictogram--receipt',
  };

  const tags = {
    button: {tag: 'button', type},
    submit: {tag: 'button', type},
    reset: {tag: 'button', type},
    link: {tag: 'a', href: link},
    div: {tag: 'div'},
  };

  const CustomTag = tags[type]?.tag;

  const onButtonClick = (e) => {
    (!locked || !disabled) && onClick(e);
  };

  return (
    <CustomTag
      className={classNames('c-react-button', {
        [className]: className,

        // Themes
        'c-react-button--primary-black': theme === 'primary-black',
        'c-react-button--primary-black-large': theme === 'primary-black-large',

        'c-react-button--primary-white-border': theme === 'primary-white-border',
        'c-react-button--primary-white-border-large': theme === 'primary-white-border-large',

        'c-react-button--primary-yellow-border': theme === 'primary-yellow',
        'c-react-button--primary-black-border': theme === 'primary-black-border',

        'c-react-button--icon-right-arrow': icon === 'right_arrow',
        'c-react-button--icon-receipt': icon === 'receipt',

        // Align
        'c-react-button--align-center': align === 'center',
        'c-react-button--align-right': align === 'right',
        'c-react-button--align-left': align === 'left',

        // Width
        'c-react-button--full-width': width === 'full',
        'c-react-button--full-flex': width === 'full-flex',

        // State
        'is-locked': locked,
        'is-disabled': disabled,
      })}
      onClick={onButtonClick}
      disabled={disabled || locked}
      {...props}
      {...tags?.[type]}
    >
      {text && text}
      {children && children}
      {icon && <i className={`c-react-button__icon ${icons[icon]}`} />}
    </CustomTag>
  );
};
