import React from 'react';

import {Dropdown} from 'shared/components/dropdown';

export default function CurrencyDropdown(props) {
  const {
    name,
    currency,
    onChange,
    customClass,
  } = props;

  const currencyOptions = [
    {
      label: '$ USD',
      value: 'USD',
    },
    {
      label: '£ GBP',
      value: 'GBP',
    },
    {
      label: '€ EUR',
      value: 'EUR',
    },
    {
      label: '¥ JPY',
      value: 'JPY',
    },
  ];

  return (
    <Dropdown
      name={name}
      options={currencyOptions}
      onChange={onChange}
      initialValue={currency}
      customClass={customClass}
    />
  );
}
