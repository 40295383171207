import {Record} from 'immutable';
import StoredFile from './stored_file';

const _Record = Record({
  id: null,
  state: 'waiting',
  file_type: null,
  uploaded_file: new StoredFile(),
  approved_at: null,
  _destroy: false,
}, 'ArtworkFileRecord');

export default class ArtworkFile extends _Record {
  static fromJSON(json) {
    if (json.uploaded_file) {
      json.uploaded_file = StoredFile.fromJSON(json.uploaded_file);
    }

    return new ArtworkFile(json);
  }
}
