import React, {
  useEffect,
  useState,
} from 'react';
import queryString from 'query-string';
import ApiHelper from 'utils/api_helper';
import {ProjectCard} from 'components/shared/project_cards/project';
import {Dropdown} from 'src/shared/components/dropdown';
import {CatalogGrid} from 'src/shared/components/catalog/catalog_grid/catalog_grid';

export const WatchingsRecords = () => {
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [currentSorting, setCurrentSorting] = useState('desc');
  const [nextPage, setNextPage] = useState(null);
  const [loading, setLoading] = useState(false);
  const dropdownOptions = [{label: 'Sort by New-Old', value: 'desc'}, {label: 'Sort by Old-New', value: 'asc'}];

  const fetchFilteredProjects = (params = {}) => {
    ApiHelper.get(`/api/v1/projects/watchings.json?${queryString.stringify(params)}`).then((res) => {
      if (params.page) {
        setFilteredProjects([...filteredProjects, ...res.response.projects]);
      } else {
        setFilteredProjects(res.response.projects);
      }
      setNextPage(res.response.next_page);
      setLoading(false);
    });
  };

  const showMore = () => {
    setLoading(true);
    fetchFilteredProjects({page: nextPage, sort: currentSorting});
  };

  useEffect(() => {
    fetchFilteredProjects({sort: currentSorting});
  }, [currentSorting]);

  return (
    <div>
      <div className="p-section-header p-section-header--one-line">
        <div className="c-dashboard__heading">
          <h2>Records</h2>
        </div>
        <Dropdown
          options={dropdownOptions}
          onChange={setCurrentSorting}
          initialValue={currentSorting}
          customClass="c-select--sort"
        />
      </div>
      <CatalogGrid className="c-dashboard__lists-catalog" type="projects">
        {filteredProjects.map((project) => <ProjectCard project={project} isResponsive displayOrderButton />)}
      </CatalogGrid>
      <div className="c-button-grid--center-button">
        {!!nextPage && (
          <button
            className="c-button c-button--black c-button--middle"
            type="button"
            onClick={showMore}
            disabled={loading}
          >
            {loading ? 'LOADING...' : 'SHOW MORE'}
          </button>
        )}
      </div>
      {!!nextPage && <hr className="c-divider--blank" />}
    </div>
  );
};
