import React, {useState, useRef} from 'react';
import classNames from 'classnames';
import {Swiper, SwiperSlide} from 'swiper/react';
import SwiperCore, {Navigation} from 'swiper';
import {ReactButton} from 'shared/components/buttons/react_button/react_button';

export const CuratedSlider = ({
  children,
  title,
  buttonLabel,
  buttonLink,
}) => {
  const backArrowRef = useRef(null);
  const forwardArrowRef = useRef(null);
  const containerRef = useRef(null);
  const [arrowsVisible, setArrowsVisible] = useState(true);

  const checkArrowsVisibility = (swiperContainer) => {
    const slides = swiperContainer?.current.querySelectorAll('.swiper-slide');

    if (slides && slides.length > 0) {
      const slidesWidth = slides.length * slides[0].offsetWidth;
      const containerWidth = swiperContainer?.current.offsetWidth;

      setArrowsVisible(slidesWidth > containerWidth);
    }
  };

  SwiperCore.use([Navigation]);

  return (
    <div className="c-curated-slider">
      <div className="c-curated-slider__header">
        <div className="c-curated-slider__title">
          {title}
        </div>
        <div
          className={classNames('c-curated-slider__controls u-hidden-tablet', {
            'c-curated-slider__controls--is-hidden': !arrowsVisible,
          })}
        >
          <div className="c-curated-slider__arrow c-pictogram c-pictogram--arrow-back" ref={backArrowRef} />
          <div className="c-curated-slider__arrow c-pictogram c-pictogram--arrow-forward" ref={forwardArrowRef} />
          <ReactButton className="c-curated-slider__top-button" wovn-ignore="" text={buttonLabel} link={buttonLink} type="link" />
        </div>
      </div>
      <div>
        <Swiper
          ref={containerRef}
          speed={400}
          allowTouchMove
          slidesPerView="auto"
          onInit={(swiper) => {
            swiper.params.navigation.prevEl = backArrowRef.current;
            swiper.params.navigation.nextEl = forwardArrowRef.current;
            swiper.navigation.update();
          }}
          onAfterInit={() => {
            checkArrowsVisibility(containerRef);
          }}
          onResize={() => {
            checkArrowsVisibility(containerRef);
          }}
        >
          {children.map((item, index) => {
            return (
              <SwiperSlide
                key={index}
                className="swiper-slide--curated"
              >
                {item}
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
      <div className="c-curated-slider__bottom-button-container u-hidden-desktop-only">
        <ReactButton className="c-curated-slider__bottom-button" wovn-ignore="" text={buttonLabel} link={buttonLink} type="link" />
      </div>
    </div>
  );
};
