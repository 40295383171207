import React from 'react';
import {connectInfiniteHits} from 'react-instantsearch-dom';

import {CatalogGrid} from 'src/shared/components/catalog/catalog_grid/catalog_grid';
import {ProjectCard} from '../shared/project_cards/project';

const CustomHits = ({hits, currentUser}) => {
  const hitsList = hits.map((hit) => {
    return <ProjectCard isResponsive project={hit} key={hit.objectID} currentUser={currentUser} />;
  });

  return (
    <CatalogGrid className="p-projects__projects-catalog" type="projects">
      {hitsList}
    </CatalogGrid>
  );
};

export default connectInfiniteHits(CustomHits);
