import React from 'react';
import {GroupSection} from '../../group_section/group_section';
import {ScreenContainer} from '../../screen_container/screen_container';

export const FontsScreen = () => {
  return (
    <ScreenContainer title="Fonts">
      <GroupSection title="#01 H1" type="fonts">
        <div className="fonts-screen__item--01">H1 Qratesis 40px 400w Lorem ipsum</div>
        <div className="fonts-screen__item--02">H2 Qratesis 32px 400w Lorem ipsum</div>
        <div className="fonts-screen__item--03">H3 Qratesis 22px 400w Lorem ipsum</div>
        <div className="fonts-screen__item--04">H4 Qratesis 16px 400w Lorem ipsum</div>
      </GroupSection>
      <GroupSection title="#02 Font body" type="fonts">
        <div className="fonts-screen__item--05">Font-body-big Lorem ipsum</div>
        <div className="fonts-screen__item--06">Font-body-one Lorem ipsum</div>
        <div className="fonts-screen__item--07">Font-body-two Lorem ipsum</div>
      </GroupSection>
    </ScreenContainer>

  );
};
