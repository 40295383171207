import React, {useState, useEffect, useRef} from 'react';
import classNames from 'classnames';
import {connectMenu} from 'react-instantsearch-dom';
import ClearRefinements from './custom_clear_refinements';
import {scrollAnimate} from '../../src/utils/scroll_animate';
import {MainProjectsFilter} from './main_projects_filter/main_projects_filter';

const TabsSlider = ({
  attribute,
  items,
  refine,
  clearOptionLabel,
  clearOptionValue,
  defaultRefinement,
  labelMap,
  sortOrderWeight,
  searchState,
  isMainMenuActive,
}) => {
  const [isCustomActive, setIsCustomActive] = useState(!!defaultRefinement);
  const [isScrollActive, setIsScrollActive] = useState(false);
  const [isRightArrow, setIsRightArrow] = useState(false);
  const [isLeftArrow, setIsLeftArrow] = useState(false);
  const containerElement = useRef(null);
  const currentAppliedFilter = searchState?.menu && searchState.menu[attribute];
  const isAllTabActive = !currentAppliedFilter && !isCustomActive;

  let labelFunc;
  if (labelMap == undefined) {
    labelFunc = (label) => label;
  } else if (labelMap instanceof Function) {
    labelFunc = labelMap;
  } else if (labelMap instanceof Object) {
    labelFunc = (label) => labelMap[label] || label;
  } else {
    labelFunc = (label) => labelMap;
  }

  if (sortOrderWeight) {
    items = items.sort((a, b) => (sortOrderWeight[b.label] || 0) - (sortOrderWeight[a.label] || 0));
  }

  useEffect(() => {
    const handleScroll = () => {
      const containerWidth = containerElement.current.offsetWidth;
      const scrollWidth = containerElement.current.scrollWidth;
      const scrollLeft = containerElement.current.scrollLeft;

      setIsRightArrow(scrollLeft + containerWidth <= scrollWidth - 10);
      setIsLeftArrow(scrollLeft != 0);
      setIsScrollActive(containerWidth <= scrollWidth);
    };
    handleScroll();

    containerElement?.current.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleScroll, true);
    return () => {
      containerElement?.current.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleScroll, true);
    };
  }, [containerElement?.current, items, searchState.query]);

  const onArrowClick = (direction = 'left') => {
    const scrollWidth = containerElement.current.scrollWidth;
    const scrollLeft = containerElement.current.scrollLeft;
    const containerWidth = containerElement.current.offsetWidth;
    const scrollValue = direction === 'left' ? scrollLeft - containerWidth : scrollLeft + containerWidth;

    scrollAnimate(
      containerElement.current,
      scrollValue > scrollWidth ? scrollWidth : scrollValue, 'left',
      containerWidth <= 768 ? 1500 : 500);
  };

  const customTagDefined = defaultRefinement && !items.some((item) => item.label === defaultRefinement);
  const CustomTag = () => {
    return (
      <li
        className="c-tab-slider__list-item"
        onClick={(event) => {
          event.preventDefault();
          setIsCustomActive(true);
          refine(defaultRefinement);
        }}
      >
        <div className={classNames('c-tab-slider__link', {'c-tab-slider__link--active': isCustomActive})}>
          <span>{defaultRefinement}</span>
        </div>
      </li>
    );
  };

  const itemsList = items.map((item) => (
    <li
      className="c-tab-slider__list-item"
      key={item.label}
      data-value={item.label}
      onClick={(event) => {
        event.preventDefault();
        setIsCustomActive(false);
        refine(item.value);
      }}
    >
      <a href="#" className={classNames('c-tab-slider__link', {'c-tab-slider__link--active': item.isRefined})}>
        <span>{`${labelFunc(item.label)} (${item.count})`}</span>
      </a>
    </li>
  ));

  return (
    <>
      {isMainMenuActive && (
        <MainProjectsFilter
          attribute="condition"
          searchState={searchState}
          allActive={isAllTabActive}
          isCustomActive={isCustomActive}
          setIsCustomActive={setIsCustomActive}
        />
      )}
      <div className="c-tab-slider">
        <span
          className={classNames(
            'c-tab-slider__arrow c-tab-slider__arrow--left',
            {'c-tab-slider__arrow--active': isScrollActive && isLeftArrow})}
          onClick={() => { onArrowClick('left'); }}
        >
          <i className="c-pictogram c-pictogram--chevron-left" />
        </span>
        <div className="c-tab-slider__row" ref={containerElement}>
          <ul className="c-tab-slider__list">
            <ClearRefinements
              transformItems={(items) => items.filter(({attribute}) => attribute === clearOptionValue)}
              onClear={() => {
                setIsCustomActive(false);
              }}
              isActive={isAllTabActive}
              clearOptionLabel={clearOptionLabel}
            />
            {customTagDefined && <CustomTag />}
            {itemsList}
          </ul>
        </div>
        <span
          className={classNames(
            'c-tab-slider__arrow c-tab-slider__arrow--right',
            {'c-tab-slider__arrow--active': isScrollActive && isRightArrow})}
          onClick={() => { onArrowClick('right'); }}
        >
          <i className="c-pictogram c-pictogram--chevron-right" />
        </span>
      </div>
    </>
  );
};

export default connectMenu(TabsSlider);
