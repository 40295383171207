import React from 'react';
import classNames from 'classnames';

export const FieldsGroup = ({
  className,
  children,
  type,
  fieldsInRow = 1,
}) => {
  return (
    <div className={classNames('c-fields-group',
      `c-fields-group--fieldsInRow-${fieldsInRow}`, {
        [className]: className,
        'c-fields-group--radio': type === 'radio',
        'c-fields-group--radio-thin': type === 'radio-thin',
        'c-fields-group--radio-inline': type === 'radio-inline',
      })}
    >
      {children}
    </div>

  );
};
