/* eslint-disable jsx-a11y/no-autofocus */
import React from 'react';
import {connectSearchBox} from 'react-instantsearch-dom';
import classNames from 'classnames';

const CustomSearchBox = ({defaultRefinement, currentRefinement, refine}) => (
  <div className={classNames('c-search-modal__input', {'c-search-modal__input--blank': !currentRefinement})}>
    <i className="c-pictogram c-pictogram--search" />
    <input
      className="c-search__input"
      id="search"
      name="search"
      type="search"
      autoComplete="off"
      autoFocus="true"
      placeholder="Search by artist, keyword or genre"
      value={defaultRefinement || currentRefinement}
      onChange={(event) => {
        refine(event.currentTarget.value);
      }}
    />
    <div className="c-search-modal__input__clear" onClick={() => refine('')}>
      Clear
    </div>
  </div>
);
export default connectSearchBox(CustomSearchBox);
