import React from 'react';
import {
  render,
  screen,
  fireEvent,
  waitFor,
} from '@testing-library/react';

import {PageArtistSection} from '../page_artist_section';

const artist = {
  artist_name: 'Artist Name',
  artist_url: 'artist_url',
  image_1: 'image_1',
  image_2: 'image_2',
};

jest.mock('utils/api_helper', () => {
  return {
    get: () => Promise.resolve({
      response: {
        page_artist: {
          artist_name: 'Artist Name 1',
          artist_url: 'artist_url_1',
          image_1: 'image_3',
          image_2: 'image_4',
        },
      },
    }),
  };
});

describe('with crowdfunding page', () => {
  it('renders correct content', async () => {
    render(<PageArtistSection page="crowdfunding" artist={artist} />);

    expect(screen.getByText('Artist Name')).toHaveAttribute('href', 'artist_url');
    expect(screen.getByText('CROWDFUNDING')).toBeInTheDocument();
    expect(screen.getByText('Take pledges.')).toBeInTheDocument();
    expect(screen.getByText(/No upfront costs or unwanted inventory/)).toBeInTheDocument();
  });
});

describe('with press_and_sell page', () => {
  it('renders conrrect content', async () => {
    render(<PageArtistSection page="press_and_sell" artist={artist} />);

    expect(screen.getByText('Artist Name')).toHaveAttribute('href', 'artist_url');
    expect(screen.getByText('MAKE & SELL')).toBeInTheDocument();
    expect(screen.getByText('Take pre-orders.')).toBeInTheDocument();
    expect(screen.getByText(/Take pre-orders while they're in production/)).toBeInTheDocument();
  });
});

describe('with languagechange and data for the selected language', () => {
  const event = new CustomEvent('languagechange', {detail: {locale: 'es'}});

  it('replaces the content', async () => {
    render(<PageArtistSection page="press_and_sell" artist={artist} />);

    expect(screen.getByText('Artist Name')).toHaveAttribute('href', 'artist_url');
    expect(screen.getByTitle('image_1')).toHaveAttribute('src', 'image_1');
    expect(screen.getByTitle('image_2')).toHaveAttribute('src', 'image_2');

    fireEvent(window, event);

    await waitFor(() => {
      expect(screen.getByText('Artist Name 1')).toHaveAttribute('href', 'artist_url_1');
    });

    expect(screen.getByTitle('image_1')).toHaveAttribute('src', 'image_3');
    expect(screen.getByTitle('image_2')).toHaveAttribute('src', 'image_4');
  });
});
