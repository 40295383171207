import React, {useState, useEffect, useRef} from 'react';
import QRCode from 'qrcode.react';
import {FieldsGroup} from 'shared/components/form/grid_containers/fields_group/fields_group';
import {RadioField} from 'shared/components/form/radio_field/radio_field';

export const QRCodeDownloadModal = ({project_id}) => {
  const projectURL = `${window.location.origin}/projects/${project_id}`;
  const [format, setFormat] = useState('jpg');
  const buttonRef = useRef();

  const getSVGDataURL = (qrcode) => {
    const serializer = new XMLSerializer();
    const svgString = serializer.serializeToString(qrcode);

    return `data:image/svg+xml,${encodeURIComponent(svgString)}`;
  };

  useEffect(() => {
    const qrcode = document.querySelector('#project-qrcode');
    buttonRef.current.download = `qrcode.${format}`;
    switch (format) {
      case 'jpg':
        buttonRef.current.href = qrcode.toDataURL('image/jpeg', 1.0);
        break;
      case 'png':
        buttonRef.current.href = qrcode.toDataURL('image/png');
        break;
      case 'svg':
        buttonRef.current.href = getSVGDataURL(qrcode);
        break;
    }
  }, [format]);

  return (
    <div className="c-qrcode-download-modal">
      <div className="c-qrcode-download-modal__title">QR code</div>

      <div className="c-qrcode-download-modal__body">
        <div className="c-qrcode-download-modal__code">
          <QRCode
            value={projectURL}
            size={500}
            id="project-qrcode"
            renderAs={format == 'svg' ? 'svg' : 'canvas'}
            includeMargin
          />
          <div className="c-qrcode-download-modal__square" />
        </div>
        <div className="c-qrcode-download-modal__content">
          <p className="c-qrcode-download-modal__description">
            Share this project with friends!
            <br />
            Choose the format for download
          </p>
          <FieldsGroup className="c-qrcode-download-modal__radio-group" type="radio-thin">
            <RadioField
              label="JPG"
              checked={format == 'jpg'}
              onChange={() => setFormat('jpg')}
            />
            <RadioField
              label="PNG"
              checked={format == 'png'}
              onChange={() => setFormat('png')}
            />
            <RadioField
              label="SVG"
              checked={format == 'svg'}
              onChange={() => setFormat('svg')}
            />
          </FieldsGroup>
          <a
            className="c-qrcode-download-modal__button c-button c-button--black c-button--full"
            ref={buttonRef}
          >
            DOWNLOAD
          </a>
        </div>
      </div>
    </div>
  );
};
