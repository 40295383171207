import React from 'react';
import classNames from 'classnames';
import {useWindowDimensions} from 'utils/window_dimensions';
import {formatMoney} from 'utils/format_money';
import {formatDate} from 'utils/date';
import {ReactButton} from 'shared/components/buttons/react_button/react_button';
import {PurchaseOrderCard} from '../cards/purchase_order_card';

export const PurchaseOrderSummary = ({order}) => {
  const orderItems = order.order_items;
  const orderDate = orderItems[0].created_at;
  const paymentStateFailed = order.payment_state === 'failed';
  const currency = order.payment_currency;
  const amount = order.total_payment_total_amount;
  const {width} = useWindowDimensions();
  const isMobile = width <= 767;
  return (
    <div className="c-dashboard__section">
      <div className="c-grid--center">
        <div className="c-purchases__id" wovn-ignore="">
          Order ID:&nbsp;
          {order.id}
        </div>
        {paymentStateFailed && (
          <div>
            <span className="c-label--jazz--payment-issue">PAYMENT ISSUE</span>
          </div>
        )}
      </div>
      <div className="c-grid--center">
        <div className="c-purchases__data">
          Order date:&nbsp;
          {formatDate(orderDate, '%b %-d %Y')}
        </div>
        <div className="c-purchases__total">
          Total:&nbsp;
          {formatMoney({amount, currency})}
        </div>
      </div>
      {orderItems.map((orderItem, index) => (
        <PurchaseOrderCard orderItem={orderItem} index={index} key={index} />
      ))}
      <div className={classNames('c-button-grid--right', {'c-button-grid--right--no-padding': isMobile})}>
        <ReactButton
          text="VIEW ORDER PROGRESS"
          icon="right_arrow"
          theme="primary-white-border"
          align="right"
          type="link"
          link={`orders/${order.id}`}
        />
      </div>
    </div>
  );
};
