import queryString from 'query-string';

export const publicPagesRoutes = Object({
  generalPagePath: '/',
  whyPagePath: '/why',
  howItWorksPagePath: '/how-it-works',
  forFansPagePath: '/for-fans',
  forArtistsPagePath: '/for-artists',
  servicesPagePath: '/services/design',
  artistToolkitPagePath: '/articles',
  projectsPagePath: (params = {}) => { return `/projects?${queryString.stringify(params)}`; },
  storiesPagePath: '/stories',
  qratesCuratedPagePath: '/curated',
  aboutQratesPagePath: '/about',
  careersPagePath: '/careers',
  partnersPagePath: '/partners',
  contactPagePath: '/contact',
  termsPagePath: '/terms',
  privacyPagePath: '/privacy',
  legalInformationPagePath: '/legal',
});

export const checkoutFlowRoutes = {
  cartScreen: '/cart',
};

export const accountRoutes = {
  signIn: '/signin',
};

export const externalLinks = Object({
  referalProgramPagePath: 'https://share.qrates.com/',
  supportPagesPath: 'https://support.qrates.com/',
  returnsPagePath: 'https://support.qrates.com/article/ty2pmtraxf-cancellation-refund-and-replacement-policy',
  shippingPagePath: 'https://support.qrates.com/category/LvxH57l654-shopping-guide',
  qratesFacebookPath: 'https://www.facebook.com/qratesinfo',
  qratesInstagramPath: 'https://www.instagram.com/qrates/',
  qratesTwitterPath: 'https://twitter.com/qrates_com',
});
