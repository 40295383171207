import {useEffect} from 'react';

export const useClickOutside = (
  insideRefs,
  isVisible,
  onClose,
) => {
  useEffect(() => {
    const handleWindowClick = (event) => {
      const someRefContainTarget = insideRefs
        .filter((ref) => ref.current)
        .some((ref) => ref.current.contains(event.target));

      if (!isVisible || someRefContainTarget) {
        return;
      }

      if (onClose) {
        onClose(event);
      }
    };

    if (isVisible) {
      window.addEventListener('click', handleWindowClick);
    }

    return () => {
      if (isVisible) {
        window.removeEventListener('click', handleWindowClick);
      }
    };
  }, [insideRefs, isVisible, onClose]);
};
