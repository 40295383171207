import React, {useEffect, useState} from 'react';
import Prismic from '@prismicio/client';
import {prismicClient} from 'prismic/shared/cms/prismic';

import {useLanguageChange} from 'utils/use_language_change';

import {PublicTopSection} from 'public/components/public_top_section/public_top_section';
import {CardCategorySection} from 'public/components/card_category_section/card_category_section';
import {QratesStudioSliderBanner} from 'public/components/qrates_studio_slider_banner/qrates_studio_slider_banner';
import {SectionNavigationBar} from 'public/components/section_navigation_bar/section_navigation_bar';
import {ErrorFallbackSection} from '../../components/error_fallback_section/ErrorFallbackSection';
import {LoadingSection} from '../../components/loading_section/LoadingSection';

export const HowItWorksPage = () => {
  const [prismicPage, setPage] = useState(null);
  const cardSections = prismicPage?.data.body;
  const [navigationMenuItems, setNavigationMenuItems] = useState(null);
  const [sliderData, setSliderData] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const queries = [
    Prismic.Predicates.at('document.type', 'how_it_works'),
    Prismic.Predicates.at('document.type', 'qrates_studio_section'),
  ];

  const navigationLinkHandler = (text) => text.replace(/ /g, '').replace('&', '-').toLowerCase();

  const queryPrismic = async (options) => {
    setIsLoading(true);
    const locales = window.constants.prismic_locales;
    const promises = queries.map((spec) => prismicClient.query(spec, {lang: locales[options.locale || window.constants.locale || 'en']}));
    const response = await Promise.all(promises);
    if (response) {
      const navigationItems = [];

      response && response[0].results[0].data.body.forEach((item) => {
        navigationItems.push({
          name: item.primary.navigation_name[0].text,
          id: navigationLinkHandler(item.primary.navigation_name[0].text),
        });
      });

      setPage(response[0].results[0]);
      setNavigationMenuItems(navigationItems);
      setSliderData(response[1].results[0].data);
    }
  };

  const fetchContents = async (options = {}) => {
    try {
      return await queryPrismic(options);
    } catch (err) {
      setIsError(true);
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchContents();
  }, []);

  useLanguageChange((event) => {
    const locale = event.detail.locale;
    fetchContents({locale});
  });

  if (isLoading) return <LoadingSection />;
  if (isError) return <ErrorFallbackSection />;

  return (
    <div className="c-how-it-works-page">
      <PublicTopSection
        title={prismicPage.data.page_title[0].text}
        label={prismicPage.data.top_section_label[0].text}
        description={prismicPage.data.top_section_description}
        coverImage={prismicPage.data.top_section_image.url}
      />
      {navigationMenuItems?.length > 0 && (
        <SectionNavigationBar menuItems={navigationMenuItems} />
      )}
      {cardSections?.length > 0 && cardSections.map((section) => {
        return (
          <CardCategorySection
            key={section.id}
            id={navigationLinkHandler(section.primary.navigation_name[0].text)}
            title={section.primary.main_title[0].text}
            label={section.primary.navigation_name[0].text}
            description={section.primary.description}
            coverImage={section.primary.image.url}
            imageAlign={section.primary.image_align}
            backgroundColor={section.primary.background_color}
          />
        );
      })}
      <QratesStudioSliderBanner sliderData={sliderData} />
    </div>
  );
};
