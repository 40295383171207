import React from 'react';

export const shippingCondition = (record) => {
  if (record.shipping_condition_marker === 'onsale') {
    return (
      <>
        <span>Experiencing shipping delays </span>
        <a
          href="https://support.qrates.com/l/en/article/bahz86lcxr-shipping-delay-update"
          className="c-link"
          style={{fontSize: 'inherit'}}
          target="_blank"
        >
          more details
        </a>
      </>
    );
  } else {
    return record.shipping_condition;
  }
};
