import React, {useState} from 'react';
import Modal from 'react-modal';
import classNames from 'classnames';
import algoliasearch from 'algoliasearch/lite';
import {
  InstantSearch,
  CurrentRefinements,
} from 'react-instantsearch-dom';
import SearchBox from './custom_search_box';
import Hits from './custom_hits';
import Pagination from '../shared/custom_pagination';

export const SearchModal = ({
  className,
  searchIcon,
  algolia_id,
  algolia_search_api_key,
}) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [indexName, setIndexName] = useState('Project');
  const [searchState, setSearchState] = useState();

  const handleSearchStateChange = (searchState) => {
    setSearchQuery(searchState.query);
    setSearchState(searchState);
  };

  const handleKeywordSelected = (keyword) => {
    setSearchQuery(keyword);
    if (searchState) {
      const updatedState = searchState;
      updatedState.query = keyword;
      setSearchState(updatedState);
    }
  };

  const handleOpen = () => {
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handleSwitchIndex = (indexName) => {
    setIndexName(indexName);
  };

  const searchClient = algoliasearch(algolia_id, algolia_search_api_key);
  const appElement = document.getElementById('root');

  return (
    <>
      {searchIcon && (
        <a
          className={classNames(
            'c-search-modal-icon',
            {[className]: className},
          )}
          onClick={handleOpen}
          id="search-opener"
        >
          <i className="c-pictogram c-pictogram--search" />
        </a>
      )}
      <Modal
        isOpen={showModal}
        onRequestClose={handleClose}
        appElement={appElement}
        ariaHideApp={false}
        overlayClassName="c-search-modal"
        className="p-modal--full c-search-modal__contents"
        closeTimeoutMS={200}
      >
        <div
          className="c-close--modal"
          onClick={handleClose}
        >
          <a>&times;</a>
        </div>
        <div
          className={classNames(
            'c-search-modal__container',
            {'c-search-modal__container--search-only': !searchQuery || searchQuery.length < 1},
          )}
        >
          {indexName && (
            <InstantSearch
              searchClient={searchClient}
              indexName={indexName}
              onSearchStateChange={handleSearchStateChange}
            >
              <CurrentRefinements
                transformItems={(items) => items.filter((item) => item.currentRefinement === ' ')}
              />
              <SearchBox defaultRefinement={searchQuery} />
              <Hits
                searchQuery={searchQuery}
                onKeywordSelected={handleKeywordSelected}
                onSwitchTab={handleSwitchIndex}
                indexName={indexName}
              />
              {searchQuery && (
                <Pagination
                  textLabel={indexName == 'Project' ? 'RECORDS' : 'ARTISTS & LABELS'}
                />
              )}
              <hr className="c-divider--blank" />
            </InstantSearch>
          )}
        </div>
      </Modal>
    </>
  );
};
