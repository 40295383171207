import React, {useState, useEffect} from 'react';
import Prismic from '@prismicio/client';
import {useLanguageChange} from 'utils/use_language_change';
import {prismicClient} from 'prismic/shared/cms/prismic';
import {StoriesSection} from './stories_section/stories_section';
import {ReleaseOfTheWeekSection} from './release_of_the_week_section/release_of_the_week';
import {ToolkitSection} from './toolkit_section/toolkit_section';
import {QratesRecommendsSection} from './qrates_recommends_section/qrates_recommends_section';
import {CuratedFeaturedFallback} from '../components/curated_featured/curated_featured_fallback';
import {LoadingSection} from '../../components/loading_section/loading_section';

export const QratesCuratedPage = () => {
  const [sectionsBasics, setSectionsBasics] = useState([]);
  const [articles, setArticles] = useState([]);
  const [featuredArticle, setFeaturedArticle] = useState([]);
  const [stories, setStories] = useState([]);
  const [featuredStory, setFeaturedStory] = useState([]);
  const [qratesRecommendsData, setQratesRecommendsData] = useState([]);
  const [releaseOfTheWeekData, setReleaseOfTheWeekData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const pageBasicsQuery = Prismic.Predicates.at('document.type', 'curated_page');
  const releaseOfTheWeekQuery = Prismic.Predicates.at('document.type', 'record_of_the_week');

  const querySectionsByType = async (specifications) => {
    setIsLoading(true);
    const locales = window.constants.prismic_locales;
    const queries = [
      Prismic.Predicates.at('document.type', specifications?.type),
      Prismic.Predicates.at(specifications?.filterBy, specifications?.filter),
    ];
    const promises = queries.map((spec) => prismicClient.query(spec, {
      lang: locales[specifications?.options.locale || window.constants.locale || 'en'],
      pageSize: specifications?.pageSize,
      page: 1,
      orderings: '[document.last_publication_date desc]',
    }));
    const response = await Promise.all(promises);
    if (response) {
      specifications?.setContent(response[0].results);
      specifications?.setFeatured && specifications?.setFeatured(response[1].results);
    }
  };

  const fetchSectionsByType = async (specifications) => {
    try {
      return await querySectionsByType(specifications);
    } catch (err) {
      console.error(err);
      setIsError(true);
    }
  };

  const singleQuery = (options, query) => {
    const locales = window.constants.prismic_locales;
    return prismicClient.query(query, {
      lang: locales[options.locale || window.constants.locale || 'en'],
      pageSize: 1,
      page: 1,
      orderings: '[document.last_publication_date desc]',
    });
  };

  const queryPrismic = (options) => {
    setIsLoading(true);
    singleQuery(options, pageBasicsQuery).then((response) => {
      const pageData = response.results[0]?.data;
      pageData && setSectionsBasics(pageData);
      const featuredStoryUID = pageData?.featured_story.uid;
      const featuredArticleUID = pageData?.featured_article.uid;
      featuredStoryUID && fetchSectionsByType({
        options,
        filter: featuredStoryUID,
        type: 'story',
        filterBy: 'my.story.uid',
        pageSize: 10,
        setContent: setStories,
        setFeatured: setFeaturedStory,
      });
      featuredArticleUID && fetchSectionsByType({
        options,
        filter: featuredArticleUID,
        type: 'article',
        filterBy: 'my.article.uid',
        pageSize: 6,
        setContent: setArticles,
        setFeatured: setFeaturedArticle,
      });
      singleQuery(options, releaseOfTheWeekQuery).then(
        (response) => {
          setReleaseOfTheWeekData([response.results[0].data]);
        },
      );
      fetchSectionsByType({
        options,
        filter: ['Qrates Recommends'],
        type: 'story',
        filterBy: 'document.tags',
        pageSize: 5,
        setContent: setQratesRecommendsData,
      });
    });
  };

  const fetchAllSections = (options = {}) => {
    queryPrismic(options);
  };

  useEffect(fetchAllSections, []);

  useEffect(() => {
    if (articles.length && stories.length
      && featuredStory.length && qratesRecommendsData.length
      && releaseOfTheWeekData.length
    ) {
      setIsLoading(false);
    }
  }, [articles, stories, featuredStory, featuredStory, qratesRecommendsData, releaseOfTheWeekData]);

  useLanguageChange((event) => {
    const locale = event.detail.locale;
    fetchAllSections({locale});
  });

  if (isError) return <CuratedFeaturedFallback />;
  if (isLoading) return <LoadingSection />;
  return (
    <>
      {stories.length > 0 && featuredStory.length > 0 && (
        <StoriesSection
          carouselHeading={sectionsBasics.latest_stories_heading}
          carouselButtonLabel={sectionsBasics.latest_stories_button_label}
          featuredLinkLabel={sectionsBasics.featured_story_link_label}
          stories={stories}
          featuredStory={featuredStory}
          isLoading={isLoading}
          isError={isError}
        />
      )}
      {releaseOfTheWeekData.length > 0 && (
        <ReleaseOfTheWeekSection
          heading={sectionsBasics.release_of_the_week_heading}
          buttonLabel={sectionsBasics.release_of_the_week_button_label}
          buttonLink="/release-of-the-week"
          projectLinkLabel={sectionsBasics.project_details_link_label}
          release={releaseOfTheWeekData[0]}
          isLoading={isLoading}
          isError={isError}
        />
      )}
      {qratesRecommendsData.length > 0 && (
        <QratesRecommendsSection
          carouselHeading={sectionsBasics.qrates_recommends_heading}
          carouselButtonLabel={sectionsBasics.qrates_recommends_button_label}
          recommendations={qratesRecommendsData}
          isLoading={isLoading}
          isError={isError}
        />
      )}
      {articles.length > 0 && featuredArticle.length > 0 && (
        <ToolkitSection
          heading={sectionsBasics.artist_toolkit_heading}
          featuredLinkLabel={sectionsBasics.featured_article_link_label}
          carouselHeading={sectionsBasics.latest_articles_heading}
          carouselButtonLabel={sectionsBasics.latest_articles_button_label}
          articles={articles}
          featuredArticle={featuredArticle}
          isLoading={isLoading}
          isError={isError}
        />
      )}
    </>
  );
};
