// @flow
import React from 'react';
import styled, {css} from 'styled-components';
import {RichText} from 'prismic-reactjs';
import {
  getFeaturedListItemHeading,
  getFeaturedListItemArtistName,
  getFeaturedListItemImageAlt,
  getFeaturedListItemImageUrl,
  getFeaturedListItemOverline,
  getFeaturedListItemText,
  getSliceFeaturedListItems,
} from '../../cms/story';
import { prismicLinkResolver } from '../../cms/prismic';
import {
  cssLargePlusTextSize,
  cssMediumPlusText,
  cssMediumText,
  cssTextOutline,
  cssNormalText,
} from '../../styles/typography';
import {cssFontFKRoman, FONT_WEIGHTS} from '../../styles/fonts';
import {cssTextUnderline} from '../../styles/typography';
import {StyledGenericContent} from '../../styles/shared';
import {
  breakpointDesktopOnly,
  breakpointTabletOnly,
  cssMobileSidePadding,
} from '../../styles/responsive';

const cssIsNoMargin = css`
  padding-bottom: 0px;
  ${breakpointTabletOnly} {
    margin-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
  }
`;

const StyledContainer = styled.div`
  margin-bottom: 70px;
  ${cssMobileSidePadding};
  ${breakpointTabletOnly} {
    margin-top: 0px;
    margin-bottom: 20px;
  }
  ${props => (props.noMargin ? cssIsNoMargin : '')};

  a {
    ${cssTextUnderline};
    color: inherit;
  }
`;

const StyledListItem = styled.li`
  padding-top: 20px;
  list-style: none;

  ${breakpointTabletOnly} {
    padding-top: 10px;
  }

  ${breakpointDesktopOnly} {
    display: flex;
    column-gap: 40px;
  }
`;

const StyledListContentWrapper = styled.div`
  border-top: 2px solid #000000;
  margin: 0px 40px 80px;
  padding: 20px 0px;

  ${breakpointTabletOnly} {
    margin: 20px 0px;
  }
`

const StyledListItemLeft = styled.div`
  ${breakpointDesktopOnly} {
    width: 50%;
  }
`;

const StyledListItemRight = styled.div`
  ${breakpointDesktopOnly} {
    width: 50%;
  }
`;

const StyledListNumber = styled.div`
  ${cssTextOutline};
  font-size: 60px;
  font-weight: ${FONT_WEIGHTS.bold};
  letter-spacing: -0.3px;
  line-height: 60px;

  ${breakpointTabletOnly} {
    font-size: 50px;
    letter-spacing: -0.3px;
    line-height: 50px;
    margin-bottom: 20px;
  }
`

const StyledListItemOverline = styled.h4`
  ${cssMediumPlusText};
  margin-bottom: 13px;
`;

const StyledListItemHeading = styled.div`
  ${cssLargePlusTextSize};
  ${cssTextUnderline};
  letter-spacing: -1.5px;
  font-weight: ${FONT_WEIGHTS.black};
  ${cssFontFKRoman};
`;

const StyledListItemArtistName = styled.div`
  h3 {
    ${cssFontFKRoman};
    ${cssNormalText};
    line-height: 26px;
    letter-spacing: -1.5px
  }

  ${breakpointTabletOnly} {
    margin-bottom: 10px;
  }
`;

const StyledListItemImageWrapper = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  max-width: 300px;
  max-height: 300px;

  ${breakpointTabletOnly} {
    margin-top: 0px;
    margin-bottom: 20px;
    max-width: unset;
    max-height: unset;
  }

  a, img {
    position: absolute;
    display: block;
    top: 0px;
    left: 0px;
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
`;

const StyledListItemImageContainer = styled.span`
  display: block;
  position: relative;
  overflow: hidden;
  height: 0px;
  width: 100%;
  padding-top: 100%;

  ${breakpointTabletOnly} {
    padding-top: 100%;
  }
`;

const StyledListItemText = styled(StyledGenericContent)`
  ${cssMediumText};
  font-weight: ${FONT_WEIGHTS.medium};

  h3 {
    font-size: 22px;
    line-height: 28px;
    margin-bottom: 10px;
    font-weight: 700;
  }

  ${breakpointTabletOnly} {
    font-size: 18px;
    letter-spacing: -0.3px;
    line-height: 24px;
  }

  p:not(:first-of-type) {
    padding-top: 20px;
    ${breakpointTabletOnly} {
      padding-top: 18px;
    }
  }
`;


export const FeaturedList = ({slice, noMargin}) => (
  <StyledContainer noMargin={noMargin}>
    <ol>
      {getSliceFeaturedListItems(slice).map((item, index) => (
        <StyledListContentWrapper key={index.toString()}>
          <StyledListItemOverline>
            {RichText.asText(getFeaturedListItemOverline(item))}
          </StyledListItemOverline>
          <StyledListNumber>#{(index + 1).toString()}</StyledListNumber>
          <StyledListItem key={index.toString()}>
            <StyledListItemLeft>
              <StyledListItemHeading>
                {item.url?.url && (
                  <>
                    <a wovn-ignore="" href={item.url.url} target="_blank">
                      {RichText.render(getFeaturedListItemHeading(item), prismicLinkResolver)}
                    </a>
                  </>
                )}
                {!item.url?.url && (
                  <span wovn-ignore="">
                    {RichText.render(getFeaturedListItemHeading(item), prismicLinkResolver)}
                  </span>
                )}
              </StyledListItemHeading>
              <StyledListItemArtistName>
                <span wovn-ignore="">
                  {RichText.render(getFeaturedListItemArtistName(item), prismicLinkResolver)}
                </span>
              </StyledListItemArtistName>
              <StyledListItemImageWrapper>
                <StyledListItemImageContainer>
                  {item.url?.url && (
                    <a href={item.url.url} target="_blank">
                      <img
                        src={getFeaturedListItemImageUrl(item)}
                        alt={getFeaturedListItemImageAlt(item)}
                      />
                    </a>
                  )}
                  {!item.url?.url && (
                    <img
                      src={getFeaturedListItemImageUrl(item)}
                      alt={getFeaturedListItemImageAlt(item)}
                    />
                  )}
                </StyledListItemImageContainer>
              </StyledListItemImageWrapper>
            </StyledListItemLeft>
            <StyledListItemRight>
              <StyledListItemText>
                <span wovn-ignore="">
                  {RichText.render(getFeaturedListItemText(item), prismicLinkResolver)}
                </span>
              </StyledListItemText>
            </StyledListItemRight>
          </StyledListItem>
        </StyledListContentWrapper>
      ))}
    </ol>
  </StyledContainer>
);
