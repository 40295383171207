import queryString from 'query-string';

export default Object({
  fetchProjectPath: (projectId, params = {}) => { return `/api/v1/projects/${projectId}?${queryString.stringify(params)}`; },
  calculateCostPath: '/api/v1/projects/calculate_cost',
  applyCouponPath: '/api/v1/projects/apply_coupon',
  saveDraftPath: '/api/v1/projects/draft',
  saveSpecPath: '/api/v1/projects/spec',
  startProjectPath: '/api/v1/projects/start',
  saveProjectInRedisPath: '/api/v1/projects/save_in_redis',
  getCurrentUserPath: (params = {}) => { return `/api/v1/me?${queryString.stringify(params)}`; },
  getZeroPricesPath: '/api/v1/prices/zero',
});
