import {useState, useEffect} from 'react';

export const useWindowDimensions = () => {
  const breakpoint = 1300;
  const getWindowDimensions = () => {
    const {innerWidth: width, innerHeight: height} = window;
    return {
      width,
      height,
      isFullSize: width >= breakpoint,
    };
  };

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  useEffect(() => {
    const onResize = () => {
      setWindowDimensions(getWindowDimensions());
    };
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, []);
  return windowDimensions;
};
