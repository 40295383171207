import React, {useState, useEffect, useRef} from 'react';
import Prismic from '@prismicio/client';
import {useLanguageChange} from 'utils/use_language_change';
import {prismicClient} from 'prismic/shared/cms/prismic';
import {ReactButton} from 'shared/components/buttons/react_button/react_button';
import {CuratedFeaturedFallback} from '../components/curated_featured/curated_featured_fallback';
import {StoriesCard} from './stories_card';

export const StoriesList = ({currentFilter}) => {
  const [stories, setStories] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [nextPage, setNextPage] = useState(null);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const queryAllStories = Prismic.Predicates.at('document.type', 'story');
  const isQratesRecommendsFilter = currentFilter === 'Qrates Recommends';
  const dynamicParams = isQratesRecommendsFilter ? 'document.tags' : 'my.story.story_category';
  const dynamicSortBy = isQratesRecommendsFilter ? [currentFilter] : currentFilter;
  const queryFilteredByCategory = Prismic.Predicates.at(dynamicParams, dynamicSortBy);
  const dynamicQuery = currentFilter === 'All Stories' ? queryAllStories : [queryAllStories, queryFilteredByCategory];
  const pageRef = useRef(1);

  const queryPrismic = async (options, isPagination) => {
    setIsLoading(true);
    const locales = window.constants?.prismic_locales;
    const response = await prismicClient.query(dynamicQuery, {
      lang: locales[options.locale || window.constants.locale || 'en'],
      pageSize: 12,
      page: pageRef.current,
      orderings: '[document.last_publication_date desc]',
    });
    response && setNextPage(response.next_page);
    response && setStories(isPagination ? (prev) => [...prev, ...response.results] : response.results);
    response && setButtonDisabled(!response.next_page);
  };

  const fetchArticles = async (options = {}, isPagination) => {
    try {
      return await queryPrismic(options, isPagination);
    } catch (err) {
      console.err(err);
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchNextPage = () => {
    setButtonDisabled(true);
    if (nextPage) {
      pageRef.current += 1;
      fetchArticles({}, true);
    }
  };

  useEffect(() => {
    pageRef.current = 1;
    fetchArticles();
  }, [currentFilter]);

  useLanguageChange((event) => {
    const locale = event.detail.locale;
    fetchArticles({locale});
  });

  if (isError) return <CuratedFeaturedFallback />;

  return (
    <div className="c-stories-page__wrapper">
      <div className="c-stories-page__grid">
        {stories?.map((article, index) => (
          <StoriesCard
            key={index}
            uid={article.uid}
            category={article.data.story_category}
            title={article.data.preview_heading}
            image={article.data.preview_image.url}
            text={article.data.preview_text}
            index={index}
          />
        ))}
      </div>
      {nextPage && (
        <div className="c-stories-page__button-container" wovn-ignore="">
          <ReactButton
            className="c-stories-page__button"
            disabled={buttonDisabled}
            text={isLoading ? 'LOADING' : 'LOAD MORE'}
            onClick={fetchNextPage}
          />
        </div>
      )}
    </div>
  );
};
