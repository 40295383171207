import React, {useEffect, useState} from 'react';
import Prismic from '@prismicio/client';
import {prismicClient} from 'prismic/shared/cms/prismic';

import {useLanguageChange} from 'utils/use_language_change';

import {PublicTopSection} from 'public/components/public_top_section/public_top_section';
import {BannerWithTitle} from 'public/components/banner_with_title/banner_with_title';
import {CommunitySection} from 'public/components/community_section/community_section';
import {InstagramAlbumSection} from 'public/components/instagram_album_section/instagram_album_section';
import {CardCategorySection} from 'public/components/card_category_section/card_category_section';
import {ArtistsOnQrates} from '../../../../components/pages/artists_on_qrates/artists_on_qrates';
import {ErrorFallbackSection} from '../../components/error_fallback_section/ErrorFallbackSection';
import {LoadingSection} from '../../components/loading_section/LoadingSection';

export const ForFansPage = ({socialRecords}) => {
  const [prismicPage, setPage] = useState([]);
  const [communityData, setCommunityData] = useState([]);
  const [artistsData, setArtistsData] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const queries = [
    Prismic.Predicates.at('document.type', 'for_fans'),
    Prismic.Predicates.at('document.type', 'community_section'),
    [
      Prismic.Predicates.at('document.type', 'qrates_artist'),
      Prismic.Predicates.at('my.qrates_artist.page', 'why'),
    ],
  ];

  const queryPrismic = async (options) => {
    setIsLoading(true);
    const locales = window.constants.prismic_locales;
    const promises = queries.map((spec) => prismicClient.query(spec, {lang: locales[options.locale || window.constants.locale || 'en']}));
    const response = await Promise.all(promises);
    if (response) {
      setPage(response[0].results[0].data);
      setCommunityData(response[1].results[0].data);
      setArtistsData(response[2].results.map((result) => result.data));
    }
  };

  const fetchContents = async (options = {}) => {
    try {
      return await queryPrismic(options);
    } catch (err) {
      setIsError(true);
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchContents();
  }, []);

  useLanguageChange((event) => {
    const locale = event.detail.locale;
    fetchContents({locale});
  });

  if (isLoading) return <LoadingSection />;
  if (isError) return <ErrorFallbackSection />;

  return (
    <div className="c-for-fans-page">
      {prismicPage && (
        <PublicTopSection
          title={prismicPage.page_top_section_title[0].text}
          label={prismicPage.page_top_section_label[0].text}
          description={prismicPage.page_top_section_description}
          coverImage={prismicPage.page_top_section_image.url}
          imageRotateDirection="left"
        />
      )}
      {prismicPage.body?.length > 0 && prismicPage.body.map((section) => {
        switch (section.slice_type) {
          case 'card_category_section':
            return (
              <CardCategorySection
                key={section.id}
                id={section.primary.navigation_name[0].text.replace(/ /g, '').replace('&', '-')}
                title={section.primary.main_title[0].text}
                label={section.primary.navigation_name[0].text}
                description={section.primary.description}
                coverImage={section.primary.image.url}
                imageAlign={section.primary.image_align}
                backgroundColor={section.primary.background_color}
              />
            );
          case 'yellow_banner_with_title':
            return (
              <BannerWithTitle title={section.primary.title[0].text} id={section.id} key={section.id} />
            );
          default:
            return null;
        }
      })}
      {communityData && (
        <CommunitySection
          title={communityData.title[0].text}
          label={communityData.label}
          description={communityData.text}
          link={{
            name: communityData.button_label,
            url: communityData.href,
          }}
        />
      )}
      <ArtistsOnQrates artistsData={artistsData} />
      <InstagramAlbumSection items={socialRecords} />
    </div>
  );
};
