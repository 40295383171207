import React, {useState} from 'react';

import ApiHelper from 'utils/api_helper';
import {FeaturedProject} from 'components/project/featured_project';
import {CatalogGrid} from 'src/shared/components/catalog/catalog_grid/catalog_grid';
import {ProjectCard} from 'components/shared/project_cards/project';
import noProjectsImage from 'images/common/sticker/approve.png';
import {NoProjectsSection} from './no_projects_section/no_projects_section';

export const AccountProjectsPage = ({
  projects,
  next_page,
}) => {
  const focusedProject = projects[0];
  const [nextPage, setNextPage] = useState(next_page);
  const [projectList, setProjectList] = useState([...projects].slice(1));
  const [paginationButtonDisabled, setPaginationButtonDisabled] = useState(false);

  const pageContent = {
    noProjectSection: {
      title: 'It seems, that you don’t have any projects!',
      buttonTitle: 'MAKE A RECORD',
      image: noProjectsImage,
    },
  };

  const loadMoreProjects = () => {
    setPaginationButtonDisabled(true);
    ApiHelper.get(`/account/projects.json?page=${nextPage}`).then((res) => {
      setNextPage(res.response.next_page);
      setProjectList([...projectList, ...res.response.projects]);
      setPaginationButtonDisabled(false);
    }).catch((error) => {
      console.log(error);
      setPaginationButtonDisabled(false);
    });
  };

  return (
    <div className="c-account-projects-page">
      {!projects || projects.length < 1 && <NoProjectsSection content={pageContent.noProjectSection} /> }
      {focusedProject && <FeaturedProject className="c-account-projects-page__main-project" project={focusedProject} />}
      {projectList.length > 0 && (
        <>
          <div className="c-account-projects-page__title">Projects</div>
          <CatalogGrid className="c-account-projects-page__lists-catalog" type="projects">
            {projectList.map((project) => <ProjectCard key={project.id} project={project} section="dashboard" isResponsive />)}
          </CatalogGrid>
          {nextPage && (
            <div className="p-lists__button c-button-grid--center-button c-account-projects-page__load-more-button">
              <button
                type="button"
                className="c-button c-button--large c-button--black"
                onClick={loadMoreProjects}
                disabled={paginationButtonDisabled}
              >
                LOAD MORE PROJECTS
              </button>
            </div>
          )}
          <div className="c-account-projects-page__button"><a className="c-button c-button--big c-button--black c-button--next" href="">START A NEW PROJECT</a></div>
        </>
      )}
    </div>
  );
};
