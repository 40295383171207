import React, {useState} from 'react';

import ApiHelper from 'utils/api_helper';
import {useLanguageChange} from 'utils/use_language_change';
import {CareerCard} from '../../../shared/career_card/career_card';

export const CareersScreen = ({vacancies}) => {
  const [records, setRecords] = useState(vacancies);

  useLanguageChange(() => {
    ApiHelper.get('/careers.json').then((res) => {
      setRecords(res.response);
    });
  });

  return (
    <div className="p-about">
      <div className="c-column--second-heading">
        <p className="c-column__explanation"><span className="f-adjust-ja">Here’s the latest job opportunities at Qrates. For more information please feel free to get in touch.</span></p>
      </div>
      <div className="c-careers-card-grid">
        {records.map((vacancy) => {
          const category = vacancy.category;
          const messageTitle = vacancy.message_title;
          const url = `/contact?body=${encodeURIComponent(messageTitle)}&category=${encodeURIComponent(category)}`;

          return (
            <CareerCard
              key={vacancy.id}
              title={vacancy.title}
              location={vacancy.location}
              description={vacancy.short_description}
              groupsList={vacancy.requirements}
              link={{name: 'APPLY JOB →', url}}
            />
          );
        })}
      </div>
    </div>
  );
};
