export function scrollToTop(container = window.document.body) {
  try {
    container.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  } catch (error) {
    window.scrollTo(0, 0);
  }
}
