import React from 'react';

export const InstagramAlbumSection = ({items}) => {
  return (
    <div className="c-instagram-album-section">
      {items?.map((item, index) => (
        <a className="c-instagram-album-section__item" data-testid="link" key={index} href={item.url} target="_blank">
          <img className="c-instagram-album-section__image" data-testid="cover-image" src={item.preview_image} alt="cover" />
        </a>
      ))}
    </div>
  );
};
