import React, {useRef, useState} from 'react';
import classNames from 'classnames';

import {useClickOutside} from 'utils/use_click_outside';

import StickerImg from 'images/common/sticker/peace.png';
import {SocialIcon} from 'shared/components/icons/social_icon/social_icon';
import {SubMenu} from './components/submenu/submenu';

export const HeaderNavigation = ({
  navLinks,
  logInPath,
  isMobileMenuActive,
  startProjectPath,
  setIsMobileMenuActive,
}) => {
  const [activeSubmenu, setActiveSubmenu] = useState(null);
  const navMenuRef = useRef();
  const currentPageUrl = window.location.pathname;
  useClickOutside(
    [navMenuRef],
    isMobileMenuActive,
    () => setIsMobileMenuActive(false),
  );
  return (
    <nav className={classNames('c-checkout-header__navigation navigation', {'is-active': isMobileMenuActive})} ref={navMenuRef}>
      <ul className="navigation__list">
        {navLinks && navLinks.map((menuItem, index) => {
          return (
            <li className="navigation__list-item" key={index}>
              {menuItem?.path && !menuItem?.subMenu && (
                <a
                  className={classNames('navigation__link', {'is-current': currentPageUrl === menuItem?.path})}
                  href={menuItem.path}
                  target={menuItem.target}
                >
                  {menuItem.name}
                </a>
              )}
              {menuItem?.subMenu && (
                <SubMenu
                  menuItem={menuItem}
                  menuId={index}
                  activeId={activeSubmenu}
                  setActiveId={setActiveSubmenu}
                  navMenuRef={navMenuRef}
                />
              )}
            </li>
          );
        })}
      </ul>
      <div className="navigation__social-icons">
        <SocialIcon type="facebook" target="_blank" />
        <SocialIcon type="instagram" target="_blank" />
        <SocialIcon type="twitter" target="_blank" />
      </div>
      <div className="navigation__sticker">
        <img src={StickerImg} alt="peace" />
      </div>
      <div className="navigation__buttons">
        {logInPath && (
          <a className="navigation__login-button" href={logInPath}>Log in</a>
        )}
        {startProjectPath && (
          <a className="navigation__start-button" href={startProjectPath}>Make a record</a>
        )}
      </div>
    </nav>
  );
};
