import React from 'react';

import {CardElement, useStripe, useElements} from '@stripe/react-stripe-js';

export const StripeCheckoutForm = ({onChangeHandler}) => {
  const CARD_ELEMENT_OPTIONS = {
    iconStyle: 'solid',
    hidePostalCode: true,
    hideIcon: true,
    style: {
      base: {
        iconColor: 'rgb(240, 57, 122)',
        fontSize: '16px',
        '::placeholder': {
          fontSize: '16px',
        },
      },
    },
  };

  const stripe = useStripe();
  const elements = useElements();

  const handleChange = async (event) => {
    const card = elements.getElement(CardElement);
    if (!event.complete) {
      return onChangeHandler({empty_form: true});
    }

    const result = await stripe.createToken(card);
    onChangeHandler(result);
  };

  return (
    <div className="c-stripe-checkout-form">
      <div className="c-stripe-checkout-form__container">
        <CardElement className="c-stripe-checkout-form__field" options={CARD_ELEMENT_OPTIONS} onChange={handleChange} />
      </div>
    </div>
  );
};
