import {Record} from 'immutable';

const _Record = Record({
  item_id: 0,
  title_color: '',
  background_color: '#c4c7bd',
  background_image_id: 0,
  main_visual_type: 'main_visual_color',
  display_angle: 'display_with_sleeve',
  background_image_url: '',
  video_url: '',
  product_image_background_color: '',
  cover_image_file_id: null,
  cover_image_file_url: '',
  cover_image_url: '',
  images: [],
}, 'ItemPageDesignRecord');

export default class ItemPageDesign extends _Record {
  static fromJSON(json) {
    return new ItemPageDesign(json);
  }
}
