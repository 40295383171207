import {Record} from 'immutable';
import Project from './project';
import Artist from './artist';

const _Record = Record({
  id: null,
  first_name: null,
  last_name: null,
  email: null,
  country: null,
  city: null,
  building: null,
  street: null,
  postal_code: null,
  phone_number: null,
  mailer_token: null,
  remember_token: null,
  profile_image_url: null,
  focused_project: null,
  owned_projects_count: null,
  artist: new Artist(),
  supported_features: [],
}, 'UserRecord');

export default class User extends _Record {
  static fromJSON(json) {
    if (json) {
      if (json.focused_project) {
        json.focused_project = Project.fromJSON(json.focused_project);
      }
      json.artist = Artist.fromJSON(json.artist);

      return new User(json);
    } else {
      return new User();
    }
  }
}
