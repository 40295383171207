import {useEffect, useRef, useState} from 'react';

export const useAudioPlayer = (
  tracks,
) => {
  const [error, setError] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isCurrentTrackEnd, setIsCurrentTrackEnd] = useState(false);
  const [isActiveListener, setIsActiveListener] = useState(false);
  const [currentTrackIndex, setCurrentTrackIndex] = useState(0);

  const trackTitle = () => {
    const currentTrack = tracks[currentTrackIndex];

    if (tracks.length < 1) {
      return '';
    } else if (!error && currentTrack?.title) {
      return currentTrack?.title;
    } else if (error && currentTrack?.title) {
      return `Error on load track: ${currentTrack?.title}`;
    } else {
      return 'NO TITLE';
    }
  };

  const currentTrack = tracks && {...tracks[currentTrackIndex], title: trackTitle()} || null;
  const isArrowsActive = tracks?.length > 1;

  const audioElementRef = useRef(new Audio());

  const onSkipSong = (forwards) => {
    if (forwards) {
      tracks && setCurrentTrackIndex(
        (currentTrackIndex) => ((currentTrackIndex + 1 > tracks.length - 1) ? 0 : currentTrackIndex + 1),
      );
    } else {
      tracks && setCurrentTrackIndex(
        (currentTrackIndex) => ((currentTrackIndex - 1 < 0) ? (tracks.length - 1) : currentTrackIndex - 1),
      );
    }
  };

  const handleStartLoad = () => {
    setIsLoading(true);
    setError(false);
  };

  const handleError = (error) => {
    setError(error);
    setIsLoading(false);
    setIsPlaying(false);
  };

  const handleEnd = () => {
    setIsCurrentTrackEnd(true);
  };

  const onPlay = () => {
    const audioElement = audioElementRef?.current;
    const isAudioPlaying = audioElement.currentTime > 0 && !audioElement.paused && !audioElement.ended;

    if (!error) {
      !isAudioPlaying && !isPlaying ? audioElement.play() : audioElement.pause();
      tracks && setIsPlaying(!isPlaying);
    }
  };

  const playPrev = () => {
    isArrowsActive && onSkipSong(false);
  };

  const playNext = () => {
    isArrowsActive && onSkipSong(true);
  };

  useEffect(() => {
    const audioElement = audioElementRef?.current;

    if (audioElement && tracks && !isActiveListener) {
      setIsActiveListener(true);
      audioElement.addEventListener('ended', handleEnd);
      audioElement.addEventListener('loadstart', handleStartLoad);
      audioElement.addEventListener('loadeddata', () => setIsLoading(false));
      audioElement.addEventListener('error', handleError);
      return () => {
        audioElement.removeEventListener('ended', handleEnd);
        audioElement.removeEventListener('loadstart', handleStartLoad);
        audioElement.removeEventListener('loadeddata', () => setIsLoading(false));
        audioElement.removeEventListener('error', handleError);
      };
    }
  }, [audioElementRef?.current]);

  useEffect(() => {
    const audioElement = audioElementRef?.current;
    const currentTrack = tracks && tracks[currentTrackIndex] || null;

    audioElement.src = currentTrack?.audio_url;
    audioElement.load();
    isPlaying && audioElement.play();
  }, [tracks, currentTrackIndex]);

  useEffect(() => {
    if (isCurrentTrackEnd) {
      onSkipSong(true);
      setIsCurrentTrackEnd(false);
    }
  }, [isCurrentTrackEnd]);

  return {
    isLoading, isPlaying, isArrowsActive, currentTrack, currentTrackIndex, error, onPlay, playPrev, playNext,
  };
};
