import request from 'superagent';

function processResponse(response) {
  if (response.ok) {
    return Promise.resolve(response.json());
  } else {
    return Promise.reject(response.json());
  }
}

function processError(promise) {
  return promise.then((error) => ({error}));
}

export default class {
  static get(path, processPagination = (res) => res) {
    const csrfToken = document.getElementsByName('csrf-token').item(0).content;
    const headers = new Headers({'X-CSRF-Token': csrfToken, 'Content-Type': 'application/json'});
    const initConfig = {
      method: 'GET',
      headers,
    };
    const request = new Request(path);

    return fetch(request, initConfig)
      .then(processPagination)
      .then(processResponse)
      .then((response) => ({response}))
      .catch((error) => processError(error));
  }

  static post(path, body) {
    const csrfToken = document.getElementsByName('csrf-token').item(0).content;
    const headers = new Headers({'X-CSRF-Token': csrfToken, 'Content-Type': 'application/json'});
    const initConfig = {
      method: 'POST',
      headers,
      body: JSON.stringify(body),
    };
    const request = new Request(path);

    return fetch(request, initConfig)
      .then(processResponse)
      .then((response) => ({response}))
      .catch((error) => processError(error));
  }

  static patch(path, body) {
    const csrfToken = document.getElementsByName('csrf-token').item(0).content;
    const headers = new Headers({'X-CSRF-Token': csrfToken, 'Content-Type': 'application/json'});
    const initConfig = {
      method: 'PATCH',
      headers,
      body: JSON.stringify(body),
    };
    const request = new Request(path);

    return fetch(request, initConfig)
      .then(processResponse)
      .then((response) => ({response}))
      .catch((error) => processError(error));
  }

  static uploadFiles(path, body, progress, isCancelled = false) {
    const csrfToken = document.getElementsByName('csrf-token').item(0).content;
    const files = body.getAll('files[]');
    return new Promise((resolve, reject) => {
      const req = request.post(path).withCredentials();
      req.set('X-CSRF-Token', csrfToken);
      body.forEach((value, key) => {
        if (key != 'files[]') {
          req.field(key, value);
        }
      });
      files.forEach((file) => {
        req.attach('files[]', file, file.name);
      });
      req.on('progress', (e) => {
        if (isCancelled && isCancelled()) {
          req.abort();
        }
        if (progress) {
          progress(e.percent);
        }
      });
      req.end((err, res) => {
        if (err || !res.ok) {
          reject(err);
        } else {
          resolve(res.body);
        }
      });
    });
  }

  static upload(path, params) {
    const csrfToken = document.getElementsByName('csrf-token').item(0).content;
    const headers = new Headers({'X-CSRF-Token': csrfToken});
    const data = new FormData();

    Object.keys(params).forEach((key) => {
      data.append(key, params[key]);
    });

    const initConfig = {
      method: 'POST',
      headers,
      body: data,
    };
    const request = new Request(path);
    return fetch(request, initConfig)
      .then(processResponse)
      .then((response) => ({response}))
      .catch((error) => processError(error));
  }

  static delete(path, body) {
    console.log(path, body);
    const csrfToken = document.getElementsByName('csrf-token').item(0).content;
    const headers = new Headers({'X-CSRF-Token': csrfToken, 'Content-Type': 'application/json'});
    const initConfig = {
      method: 'DELETE',
      headers,
      body: JSON.stringify(body),
    };
    const request = new Request(path);

    return fetch(request, initConfig)
      .then(processResponse)
      .then((response) => ({response}))
      .catch((error) => processError(error));
  }
}
