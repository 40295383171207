import React from 'react';
import {RichText} from 'prismic-reactjs';
import {ReactButton} from 'shared/components/buttons/react_button/react_button';

export const ForArtistsResourcesSection = ({panels}) => {
  return (
    <div className="c-for-artists-resources">
      <div className="c-for-artists-resources__layout c-for-artists-resources__layout--toolkit">
        <img className="c-for-artists-resources__image" src={panels[0].image.url} alt="HanegiKoen" />
        <div className="c-for-artists-resources__info-container">
          <div wovn-ignore="" className="c-label--pages--no-margin">{panels[0].label}</div>
          <div className="c-for-artists-resources__title">
            <span wovn-ignore="" className="f-adjust-ja">
              {RichText.asText(panels[0].title)}
            </span>
          </div>
          <div className="c-for-artists-resources__explanation">
            <span wovn-ignore="" className="f-adjust-ja">
              {RichText.asText(panels[0].text)}
            </span>
          </div>
          <ReactButton
            wovn-ignore=""
            text={panels[0].button_title}
            type="link"
            link={panels[0].button_href}
            theme="primary-black"
            align="center"
            className="c-for-artists-resources__button"
          />
        </div>
      </div>
      <div className="c-for-artists-resources__layout c-for-artists-resources__layout--support">
        <img className="c-for-artists-resources__image" src={panels[1].image.url} alt="HanegiKoen" />
        <div className="c-for-artists-resources__info-container">
          <div wovn-ignore="" className="c-label--pages--no-margin">{panels[1].label}</div>
          <div className="c-for-artists-resources__title">
            <span wovn-ignore="" className="f-adjust-ja">
              {RichText.asText(panels[1].title)}
            </span>
          </div>
          <div className="c-for-artists-resources__explanation">
            <span wovn-ignore="" className="f-adjust-ja">
              {RichText.asText(panels[1].text)}
            </span>
          </div>
          <ReactButton
            wovn-ignore=""
            text={panels[1].button_title}
            type="link"
            link={panels[1].button_href}
            theme="primary-black"
            align="center"
            className="c-for-artists-resources__button"
          />
        </div>
      </div>
    </div>
  );
};
