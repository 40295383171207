import React from 'react';

import {formatDate} from 'utils/date';

export const ProjectDetailsModal = ({project}) => {
  const productType = project.product_type;
  const sellingType = project.selling_type;
  const downloadCondition = project.download_conditions.find((c) => c.selling_type === sellingType);

  const productData = {
    vinyl: {formatClassName: 'c-pictogram--record'},
    cassette: {formatClassName: 'c-pictogram--rectangle-cassette'},
  };

  const downloadConditionData = {
    funding: {
      order_captured: 'You can download a digital version of tracks after successful finish project in your personal account on Qrates.',
      shipped: 'You can download a digital version of tracks after shipping in your personal account on Qrates.',
      date: `You can download a digital version of tracks after ${formatDate(downloadCondition?.available_at, '%B %d %Y')} in your personal account on Qrates.`,
    },
    sell: {
      order_captured: `You can download a digital version of tracks after ${productType?.original_value} purchase in your personal account on Qrates.`,
      shipped: 'You can download a digital version of tracks after shipping in your personal account on Qrates.',
      date: `You can download a digital version of tracks after ${formatDate(downloadCondition?.available_at, '%B %d %Y')} in your personal account on Qrates.`,
    },
  };

  const displayShippingFees = () => {
    if (!project.delivery_fee_estimate) return false;

    let from;
    switch (project.delivery_service) {
      case 'delivery_service_uk':
        from = 'London/UK';
        break;
      case 'delivery_service_us':
        from = 'Michigan/US';
        break;
      case 'delivery_service_japan':
        from = 'Tokyo/Japan';
        break;
      case 'delivery_service_france':
        from = 'France';
        break;
      case 'delivery_service_self':
        from = "Artist's delivery base";
        break;
    }

    return (
      <>
        <span>{`Ship from ${from}`}</span>
        <br />
        <span>
          {`Shipping fee to ${project.delivery_country_name}: `}
          <span wovn-ignore="">{project.formatted_delivery_fee}</span>
          &nbsp;~
        </span>
      </>
    );
  };

  return (
    <div className="p-project-details-modal">
      <h1 className="p-project-details-modal__main-title">
        Project Details
        <span wovn-ignore="">{project.title}</span>
      </h1>
      <div className="p-project-details-modal__inner">
        <div className="p-project-details-modal__left">
          <div className="p-project-details-modal__container">
            <div className="p-project-details-modal__title">
              <i className={`c-pictogram ${productData[productType?.original_value]?.formatClassName}`} />
              Format
            </div>
            <div className="p-project-details-modal__text">
              {project.display_format}
              <br />
              {`Caution: ${productType?.value} mockups and specs may differ from the final product.
              Qrates is not responsible if the final product does not match expectations.`}
            </div>
          </div>
          <div className="p-project-details-modal__container">
            {project.is_funding && (
              <>
                <div className="p-project-details-modal__title">
                  <i className="c-pictogram c-pictogram--heart" />
                  Crowdfunding Details
                </div>
                {project.is_indefinite && (
                  <div className="p-project-details-modal__text">
                    This funding project does not have a specific period.
                    The project will finish when it meets the goal quantity and you will be charged at the same time.
                    The project period may be shortened / changed depending on the situation.
                  </div>
                )}
                {!project.is_indefinite && (
                  <div className="p-project-details-modal__text">
                    This funding project accepts orders during its project period and you will be charged once the project succeeds.
                    The project period may be shortened or extended depending on the situation.
                  </div>
                )}
              </>
            )}
            {project.is_preorder && (
              <>
                <div className="p-project-details-modal__title">
                  <i className="c-pictogram c-pictogram--preorder-alt" />
                  Pre-order Details
                </div>
                <div className="p-project-details-modal__text">
                  This is a Pre-Order project. You will be charged immediately after completing your order.
                  The pre-order will close once it reaches the available quantity or the project end date.
                </div>
              </>
            )}
            {project.is_sell_catalog && (
              <>
                <div className="p-project-details-modal__title">
                  <i className="c-pictogram c-pictogram--preorder-alt" />
                  Sell Catalog Details
                </div>
                <div className="p-project-details-modal__text">
                  This is a Sell Catalog project which you can purchase the artist's stock.
                  Your order will be shipped from the artist's delivery base after your order has been processed.
                </div>
              </>
            )}
          </div>
          <div className="p-project-details-modal__container">
            <div className="p-project-details-modal__title">
              <i className="c-pictogram c-pictogram--playlist" />
              Digital Download Bonus
            </div>
            {project.is_dl_audio_required && (
              <div className="p-project-details-modal__text">
                Digital download card is included.
              </div>
            )}
            {project.is_digital_download_bonus_exists && (
              <div className="p-project-details-modal__text">
                {downloadConditionData[sellingType]?.[downloadCondition?.condition_type || 'order_captured']}
              </div>
            )}
            {!project.is_digital_download_bonus_exists && (
              <div className="p-project-details-modal__text">
                This project has no digital download bonus.
              </div>
            )}
          </div>
        </div>
        <div className="p-project-details-modal__right">
          <div className="p-project-details-modal__container">
            <div className="p-project-details-modal__title">
              <i className="c-pictogram c-pictogram--duration" />
              Project Duration
            </div>
            <div className="p-project-details-modal__text">
              {project.is_sell && (
                <>
                  {project.is_preorder_available && (
                    <>
                      Pre-order:&nbsp;
                      <span wovn-ignore="">
                        {`${project.formatted_preorder_start_date} ~ ${project.formatted_preorder_finish_date}`}
                      </span>
                      <br />
                    </>
                  )}
                  {project.start_date && (
                    <>
                      Retail release date:&nbsp;
                      <span wovn-ignore="">{project.formatted_start_date}</span>
                    </>
                  )}
                </>
              )}
              {project.is_funding && (
                <span wovn-ignore="">
                  {`${project.formatted_start_date} ~ ${project.formatted_finish_date}`}
                </span>
              )}
            </div>
          </div>
          {!['prepare_for_shipping', 'shipped', 'delivered'].includes(project.product_order_state) && (
            <div className="p-project-details-modal__container">
              <div className="p-project-details-modal__title">
                <i className="c-pictogram c-pictogram--shipping-date" />
                Estimated Shipping Date
              </div>
              <div className="p-project-details-modal__text">
                {project.is_funding && (
                  <>
                    <span>
                      Records are shipped typically 2-3 months from the project’s end date after the project has successfully ended.
                    </span>
                    <br />
                    <span>
                      * Please note that the schedule is subject to change depending on any schedule changes at the
                      pressing plant and the artist’s ability to submit production assets.
                    </span>
                  </>
                )}
                {project.is_preorder && (
                  <>
                    <span>{project.approximate_estimated_shipping_date}</span>
                    <br />
                    <span>
                      * Please be aware that the schedule may change depending on the situation. We will notify you the updates via email.
                    </span>
                  </>
                )}
                {project.is_sell_catalog && (
                  <>
                    {project.delivery_service == 'delivery_service_self' && (
                      <span>
                        Your record will be shipped by the artist/label after your order has been processed.
                        Please contact the artist/label directly for more information.
                      </span>
                    )}
                    {project.delivery_service != 'delivery_service_self' && (
                      <span>
                        Your order will ship from our warehouse within 3-5 business days after the order has been processed.
                      </span>
                    )}
                  </>
                )}
              </div>
            </div>
          )}
          <div className="p-project-details-modal__container">
            <div className="p-project-details-modal__title">
              <i className="c-pictogram c-pictogram--shipping-fee" />
              Shipping Fees
            </div>
            <div className="p-project-details-modal__text" wovn-ignore="">
              {displayShippingFees()}
            </div>
          </div>
          <div className="p-project-details-modal__container">
            <div className="p-project-details-modal__title">
              <i className="c-pictogram c-pictogram--tax" />
              Tax
            </div>
            <div className="p-project-details-modal__text" wovn-ignore="">
              {`${project.tax_rate}%`}
            </div>
          </div>
        </div>
      </div>
      <div className="p-project-details-modal__contact">
        Have a question?&nbsp;
        <a href="/contact">Contact us</a>
      </div>
    </div>
  );
};
