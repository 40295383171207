import {Record} from 'immutable';

const _Record = Record({
  id: null,

  payment_currency: '',
  currency: '',
  price: 0,
  delivery_type: '',
  quantity: 0,
  delivery_first_name: '',
  delivery_last_name: '',
  delivery_company: '',
  delivery_country: '',
  delivery_street: '',
  delivery_building: '',
  delivery_city: '',
  delivery_prefecture: '',
  delivery_postal_code: '',
  delivery_phone_number: '',
  delivery_continent: '',
  delivery_service: '',
  payment_state: '',
  payment_discount_amount: 0,
}, 'OrderItemRecord');

export default class OrderItem extends _Record {
  static fromJSON(json) {
    if (json) {
      return new OrderItem(json);
    }

    return new OrderItem();
  }
}
