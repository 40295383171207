import React, {useState} from 'react';

import {ToggleSwitch} from 'shared/components/form/toggle_switch/toggle_switch';

import ApiHelper from 'utils/api_helper';

export const EditSubscriptionModal = ({
  onClose,
  user,
  groupIds,
  setGroupIds,
  asmGroups,
}) => {
  const defaultFormParams = () => {
    const params = {group_ids: {}, token: user.mailer_token};
    asmGroups.forEach((group) => {
      params.group_ids[group.id] = groupIds.includes(group.id);
    });

    return params;
  };

  const [formParams, setFormParams] = useState(defaultFormParams());
  const [isLocked, setIsLocked] = useState(false);

  const handleChange = (event) => {
    const group = asmGroups.find((group) => group.id == event.target.name);
    if (!group) return false;

    const newFormParams = {...formParams};
    newFormParams.group_ids[group.id] = !event.target.checked;
    setFormParams(newFormParams);
  };

  const handleSaveClick = () => {
    setIsLocked(true);
    ApiHelper.post('/api/v1/users/subscribe_notifications', formParams).then((res) => {
      if (res.response) {
        setGroupIds(res.response);
        setIsLocked(false);
        onClose();
      }
    });
  };

  return (
    <div className="c-edit-subscription-modal">
      <h1 className="c-edit-subscription-modal__title">Subscribe for newsletter</h1>
      <div className="c-edit-subscription-modal__fields">
        {asmGroups.map((group) => {
          return (
            <ToggleSwitch
              className="c-edit-subscription-modal__toogle"
              key={group.id}
              name={group.id}
              onChange={handleChange}
              checked={!formParams.group_ids[group.id]}
              readOnly={isLocked}
              toogleAlign="top"
              label={(
                <div>
                  <div className="c-edit-subscription-modal__toogle-title">{group.title}</div>
                  <div className="c-edit-subscription-modal__toogle-description">{group.description}</div>
                </div>
              )}
            />
          );
        })}
      </div>
      <div className="c-edit-subscription-modal__buttons">
        <button
          type="button"
          className="c-button c-button--white-line-black"
          onClick={onClose}
          disabled={isLocked}
        >
          CANCEL
        </button>
        <button
          type="button"
          className="c-edit-subscription-modal__save-button c-button c-button--black"
          onClick={handleSaveClick}
          disabled={isLocked}
        >
          SAVE
        </button>
      </div>
    </div>
  );
};
