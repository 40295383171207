import {handleActions} from 'redux-actions';
import Actions from 'project_start/modules/actions';

export const CurrentSpecVariationIndexReducer = handleActions({
  [Actions.setCurrentSpecVariationIndex]: (state, action) => {
    const {index} = action.payload;

    return index;
  },
}, 0);
