// @flow
import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { cssSmallPlusText } from '../../../../shared/styles/typography';
import { breakpointDesktopOnly, breakpointMobileOnly } from '../../../../shared/styles/responsive';

const StyledContainer = styled.nav`
  background: #000000;
  color: #ffffff;

  ${breakpointDesktopOnly} {
    padding: 34px 0;
  }
`;

const StyledList = styled.ul`
  display: flex;
  align-items: center;

  ${breakpointDesktopOnly} {
    justify-content: center;

    > li {
      margin: 0 5px;
    }
  }

  ${breakpointMobileOnly} {
    overflow-x: auto;
    padding-top: 20px;
    padding-bottom: 20px;
  }
`;

const cssActive = css`
  position: relative;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
    border-bottom: 3px solid #ffffff;
    margin-top: 3px;
  }
`;

const StyledLink = styled(({ isActive, ...props }) => <Link {...props} />)`
  ${cssSmallPlusText};
  text-transform: uppercase;
  color: inherit;

  ${breakpointDesktopOnly} {
    padding: 10px 12px;
  }

  ${breakpointMobileOnly} {
    display: block;
    padding: 5px 18px;
    white-space: nowrap;
  }

  span {
    display: inline-block;
    ${props => (props.isActive ? cssActive : '')};
  }
`;

export const STORIES_NAV_TYPES = {
  all: '',
  interviews: 'interviews',
  waxHeart: 'wax-heart',
  sceneCheck: 'scene-check',
  trackPremiers: 'track-premiers',
  qratesBiz: 'qrates-biz',
};

export const STORIES_FILTERED_TYPES = [
  STORIES_NAV_TYPES.interviews,
  STORIES_NAV_TYPES.waxHeart,
  STORIES_NAV_TYPES.sceneCheck,
  STORIES_NAV_TYPES.trackPremiers,
  STORIES_NAV_TYPES.qratesBiz,
];

export type StoriesNavType = $Keys<typeof STORIES_NAV_TYPES>;

type Props = {
  filter: StoriesNavType,
};

const StoriesNav = ({ filter }: Props) => {
  return (
    <StyledContainer>
        <StyledList>
          <li>
            <StyledLink isActive={filter === STORIES_NAV_TYPES.all || !filter} to="/stories">
              <span>All</span>
            </StyledLink>
          </li>
          <li>
            <StyledLink
              isActive={filter === STORIES_NAV_TYPES.interviews}
              to={`/stories/${STORIES_NAV_TYPES.interviews}`}
            >
              <span>Interviews</span>
            </StyledLink>
          </li>
          <li>
            <StyledLink
              isActive={filter === STORIES_NAV_TYPES.waxHeart}
              to={`/stories/${STORIES_NAV_TYPES.waxHeart}`}
            >
              <span>Wax Heart</span>
            </StyledLink>
          </li>
          <li>
            <StyledLink
              isActive={filter === STORIES_NAV_TYPES.sceneCheck}
              to={`/stories/${STORIES_NAV_TYPES.sceneCheck}`}
            >
              <span>Scene Check</span>
            </StyledLink>
          </li>
          <li>
            <StyledLink
              isActive={filter === STORIES_NAV_TYPES.trackPremiers}
              to={`/stories/${STORIES_NAV_TYPES.trackPremiers}`}
            >
              <span>Track Premiers</span>
            </StyledLink>
          </li>
          <li>
            <StyledLink
              isActive={filter === STORIES_NAV_TYPES.qratesBiz}
              to={`/stories/${STORIES_NAV_TYPES.qratesBiz}`}
            >
              <span>Qrates Biz</span>
            </StyledLink>
          </li>
        </StyledList>
    </StyledContainer>
  );
};

export default StoriesNav;
