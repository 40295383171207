import apiRoutes from './api_routes';

export default Object({
  api: apiRoutes,

  rootPath: (projectId) => (projectId ? `/projects/${projectId}/start` : '/projects/start'),
  basicsTab: (projectId) => (projectId ? `/projects/${projectId}/start/basics` : '/projects/start/basics'),
  productTab: (projectId) => (projectId ? `/projects/${projectId}/start/product` : '/projects/start/product'),
  packagingTab: (projectId) => (projectId ? `/projects/${projectId}/start/packaging` : '/projects/start/packaging'),
  extrasTab: (projectId) => (projectId ? `/projects/${projectId}/start/extras` : '/projects/start/extras'),
  calculateCostPath: (projectId) => (projectId ? `/projects/${projectId}/start/cost` : '/projects/start/cost'),
  crowdfundingCostPath: (projectId) => (projectId ? `/projects/${projectId}/start/cost/crowdfunding` : '/projects/start/cost/crowdfunding'),
  pressAndSellCostPath: (projectId) => (projectId ? `/projects/${projectId}/start/cost/crowdfunding` : '/projects/start/cost/press-and-sell'),
  pressOnlyCostPath: (projectId) => (projectId ? `/projects/${projectId}/start/cost/crowdfunding` : '/projects/start/cost/press-only'),
  actionPath: (projectId) => (projectId ? `/projects/${projectId}/start/:action` : '/projects/start/:action'),

  editSpecRootPath: (projectId) => `/dashboard/projects/${projectId}/settings/main/spec`,
  editSpecBasicsTab: (projectId) => `/dashboard/projects/${projectId}/settings/main/spec/basics`,
  editSpecProductTab: (projectId) => `/dashboard/projects/${projectId}/settings/main/spec/product`,
  editSpecPackagingTab: (projectId) => `/dashboard/projects/${projectId}/settings/main/spec/packaging`,
  editSpecExtrasTab: (projectId) => `/dashboard/projects/${projectId}/settings/main/spec/extras`,
  editSpecCalculateCostPath: (projectId) => `/dashboard/projects/${projectId}/settings/main/spec/cost`,
  editSpecActionPath: (projectId) => `/dashboard/projects/${projectId}/settings/main/spec/:action`,

  artistDashboardPath: '/dashboard',
  projectDashboardPath: (projectId) => `/dashboard/projects/${projectId}`,
  settingsVinylTabPath: (projectId) => `/dashboard/projects/${projectId}/settings/main`,
});
