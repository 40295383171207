import {Record} from 'immutable';
import ProjectDashboardRoutes from 'dashboard/modules/routes';
import {findKey, pickBy, sortBy} from 'lodash';

const _Record = Record({
  vinyl: 'todo',
  crowdfunding: 'todo',
  sales: 'todo',
  selling_condition: 'todo',
  quantity: 'todo',
  page_hub: 'todo',
  page_content: 'todo',
  artist_profile: 'todo',
  distribution: 'todo',
  tracklist: 'todo',
  audio_hub: 'todo',
  audio_sample: 'todo',
  audio_digital: 'todo',
  audio_product: 'todo',
  audio_test_production: 'todo',
  artwork_hub: 'todo',
  artwork_mockup: 'todo',
  artwork_printing: 'todo',
  copyright: 'todo',
}, 'ProjectSettingsPageStatesRecord');

export default class ProjectSettingsPageStates extends _Record {
  static fromJSON(json) {
    return new ProjectSettingsPageStates(json);
  }

  isLocked(page) {
    return ['locked', 'review', 'restricted'].includes(this[page]);
  }

  nextStep(project, order = null) {
    const unfinishedKeys = sortBy(this.unfinishedPages(project), (key) => order?.indexOf(this[key]));
    let pagesList = Object.keys(this.paths(project)).filter((key) => {
      return this.paths(project)[key] === window.location.pathname || unfinishedKeys.includes(key);
    });
    pagesList = sortBy(pagesList, (key) => order?.indexOf(this[key]));
    const nextIndex = pagesList.findIndex((key) => this.paths(project)[key] === window.location.pathname) + 1;
    const nextKey = pagesList[nextIndex] || unfinishedKeys.find((key) => this.paths(project)[key] != window.location.pathname);

    return {path: this.paths(project)[nextKey], public: nextKey === 'artist_profile'};
  }

  isRecommendedPage(project) {
    const pageKey = findKey(this.toJSON(), (value, key) => (
      ['recommended'].includes(value) && !key.includes('_hub') && this.paths(project)[key] == window.location.pathname
    ));

    return !!pageKey;
  }

  unfinishedPages(project, states = ['todo', 'recommended', 'reconfirm']) {
    const productType = project.getIn(['product_order', 'spec_json', 'product_type'])?.original_value;
    let pages = Object.keys(this.toJSON()).filter((key) => states.includes(this.toJSON()[key]));
    pages = pages.filter((page) => !page.includes('_hub'));
    const excludedPages = [];
    if (productType === 'cassette') excludedPages.push('audio_test_production');

    switch (project.project_type) {
      case 'funding':
        pages = pages.filter((page) => !['sales', 'quantity'].concat(excludedPages).includes(page));
        break;
      case 'preorder':
      case 'sell':
        pages = pages.filter((page) => !['crowdfunding', 'quantity'].concat(excludedPages).includes(page));
        break;
      case 'press_only':
        pages = pages.filter((page) => !['crowdfunding', 'sales', 'distribution', 'audio_sample'].concat(excludedPages).includes(page));
        break;
    }

    return pages;
  }

  paths(project) {
    const productType = project.getIn(['product_order', 'spec_json', 'product_type'])?.original_value;
    const productTypeRoutes = {
      vinyl: {
        audio_product: ProjectDashboardRoutes.audioSettingsVinylAudioPath(project.id),
        artwork_printing: ProjectDashboardRoutes.artworkSettingsVinylPath(project.id),
        audio_test_production: ProjectDashboardRoutes.audioSettingsTestProductionPath(project.id),
      },
      cassette: {
        audio_product: ProjectDashboardRoutes.audioSettingsCassetteAudioPath(project.id),
        artwork_printing: ProjectDashboardRoutes.artworkSettingsCassettesPath(project.id),
        audio_test_production: null,
      },
    };
    const routes = {
      vinyl: ProjectDashboardRoutes.productSettingsPath(project.id),
      crowdfunding: ProjectDashboardRoutes.crowdfundingSettingsPath(project.id),
      sales: ProjectDashboardRoutes.salesSettingsPath(project.id),
      selling_condition: ProjectDashboardRoutes.sellingConditionSettingsPath(project.id),
      quantity: ProjectDashboardRoutes.quantitySettingsPath(project.id),
      page_content: ProjectDashboardRoutes.pageContentSettingsPath(project.id),
      artist_profile: project.editArtistPath(),
      distribution: ProjectDashboardRoutes.distributionSettingsPath(project.id),
      tracklist: ProjectDashboardRoutes.tracklistSettingsPath(project.id),
      audio_sample: ProjectDashboardRoutes.audioSettingsSamplesPath(project.id),
      audio_product: productTypeRoutes[productType]?.audio_product,
      audio_test_production: productTypeRoutes[productType]?.audio_test_production,
      audio_digital: ProjectDashboardRoutes.audioSettingsDigitalAudioPath(project.id),
      artwork_mockup: ProjectDashboardRoutes.artworkSettingsMockupsPath(project.id),
      artwork_printing: productTypeRoutes[productType]?.artwork_printing,
      copyright: ProjectDashboardRoutes.copyrightSettingsPath(project.id),
    };

    return pickBy(routes);
  }
}
