import styled, { css } from 'styled-components';
import { breakpointDesktopOnly } from './responsive';

export const cssMaxWidth = css`
  max-width: 1440px;
`;

export const cssArticleMaxWidth = css`
  max-width: 1440px;
`;

export const cssCenteredMaxWidth = css`
  ${cssMaxWidth};
  margin-left: auto;
  margin-right: auto;
`;

export const StyledMaxWidthContainer = styled.div`
  ${breakpointDesktopOnly} {
    ${cssCenteredMaxWidth};
  }
`;

export const StyledArticleMaxWidthContainer = styled.div`
  ${breakpointDesktopOnly} {
    ${cssArticleMaxWidth};
    margin-left: auto;
    margin-right: auto;
  }
`;
