import {Record} from 'immutable';

const _Record = Record({
  display_angle: 0,
  cover_image_file_id: null,
  cover_image_file_url: '',
}, 'VinylItemRecord');

export default class VinylItem extends _Record {
  static fromJSON(json) {
    return new VinylItem(json);
  }
}
