import React from 'react';
import classNames from 'classnames';

export const PlayerProgressButton = ({progressPercent, isPlaying, onChange}) => {
  const progressValue = !isNaN(progressPercent) ? progressPercent : 0;
  const rotateDegree = 3.6 * progressValue;

  return (
    <div className="c-player-progress-button">
      <div className="c-player-progress-button__main-line" style={{transform: `rotate(${rotateDegree}deg)`}} />
      <div
        className={classNames('c-player-progress-button__left-side-cover', {'is-hide': progressValue >= 50})}
        data-testid="left-cover"
      />
      <div
        className={classNames('c-player-progress-button__half-full-cover', {'is-hide': progressValue <= 50})}
        data-testid="right-cover"
      />
      <div className="c-player-progress-button__center">
        <div
          className="c-player-progress-button__play-button play-button"
          onClick={() => onChange && onChange()}
          data-testid="button"
        >
          {isPlaying && (
            <svg className="play-button__stop-icon" data-testid="stop-icon" viewBox="0 0 100 100">
              <path d="M22 0h20v1e2H20z" />
              <path d="M65 0h20v1e2H60z" />
            </svg>
          )}
          {!isPlaying && (
            <svg className="play-button__play-icon" data-testid="play-icon" viewBox="0 0 100 100">
              <path d="M90.2 50.1 11 .5V1e2l79.4-49.7L90.2 50.1z" />
            </svg>
          )}
        </div>
      </div>
    </div>
  );
};
