import React from 'react';
import {connectStats} from 'react-instantsearch-dom';

const CustomStats = ({nbHits, headerText}) => {
  if (headerText) {
    return (
      <div className="c-heading">
        <h2>
          {headerText}
          <span className="c-heading__hits-count">
            {` (${nbHits})`}
          </span>
        </h2>
      </div>
    );
  } else {
    return (
      <>
        {` (${nbHits})`}
      </>
    );
  }
};

export default connectStats(CustomStats);
