import React from 'react';
import classNames from 'classnames';

export const ModalHeading = ({
  text,
  theme,
  className,
  children,
}) => {
  return (
    <h2 className={classNames('c-modal-heading', {
      [className]: className,

      // Themes
      'c-modal-heading--middle-size': theme === 'middle-size',
    })}
    >
      {text && text}
      {children && children}
    </h2>
  );
};
