import React, {useEffect, useState} from 'react';

import ApiHelper from 'utils/api_helper';
import {useLanguageChange} from 'utils/use_language_change';
import ProfileIconPath from 'images/common/logos/profile_icon.png';

import {ProjectCard} from 'components/shared/project_cards/project';
import {WatchButton} from 'shared/components/buttons/watch_button/watch_button';
import {UserAvatar} from 'shared/components/avatars/user_avatar/user_avatar';

export const ListScreen = ({list}) => {
  const projects = list.projects;
  const [record, setRecord] = useState(list);
  const [isWatching, setIsWatching] = useState(false);

  useEffect(() => {
    ApiHelper.get('/api/v1/prismic/lists/watchings').then((res) => {
      if (res.response) {
        const watching = res.response.find((watching) => watching.data.resource_uid == record.uid);
        watching && setIsWatching(true);
      }
    });
  }, []);

  useLanguageChange(() => {
    ApiHelper.get(`/lists/${record.uid}.json`).then((res) => {
      setRecord(res.response);
    });
  });

  const coverImage = record.cover_image;
  const title = record.title;
  const subtitle = record.subtitle;
  const curator = record.curator;

  const unWatchList = () => {
    ApiHelper.delete(`/api/v1/prismic/lists/${record.uid}/unwatch`).then((res) => {
      res.response && setIsWatching(false);
    });
  };

  const watchList = () => {
    ApiHelper.post(`/api/v1/prismic/lists/${record.uid}/watch`).then((res) => {
      if (res.error) {
        window.location = `/signin?to=${encodeURI(window.location.pathname)}`;
      } else {
        setIsWatching(true);
      }
    });
  };

  return (
    <div className="p-discover--lists">
      <div className="p-top--lists" style={{ backgroundImage: `url(${coverImage})` }}>
        <a href="/lists" className="p-top__back-link">
          <i className="c-pictogram c-pictogram--arrow-back" />
          BACK TO LISTS
        </a>
      </div>

      <div className="p-lists__heading-bar">
        <div className="p-lists__content">
          <div className="p-lists__heading-bar-title">
            {title}
            <span>
              {subtitle}
            </span>
          </div>
          <UserAvatar className="p-lists__avatar" title={`Curated by ${curator}`} logoImage={ProfileIconPath} />
        </div>
        <WatchButton
          className="p-lists__watch-button"
          isWatching={isWatching}
          onClick={isWatching ? unWatchList : watchList}
        />
      </div>

      <div className="p-section-header--search">
        <div className="p-section-header--search__quantity">
          {`${projects.length} Records`}
        </div>
      </div>

      <div className="c-project-grid">
        {projects && projects.map((project) => {
          return (
            <ProjectCard
              project={project}
              key={project.objectID}
            />
          );
        })}
      </div>

      <div className="c-divider--blank" />
    </div>
  );
};
