import React, {useState} from 'react';
import classNames from 'classnames';
import dateFormat from 'dateformat';

import {OrderButton} from 'src/public/projects/order_button/order_button';
import {ProjectPreview} from './preview';
import {ArtistProjectState} from './artist_state';
import {GenresList} from './components/genres_list/genres_list';
import {ProjectPrice} from './components/project_price/project_price';

export const PreorderProjectCard = ({
  project,
  section,
  href,
  className,
  isResponsive = false,
  displayOrderButton,
  currentUser,
}) => {
  const {
    objectID,
    title,
    artist_name,
    start_date,
    finish_date,
    restart_requests_count,
    genres,
    tags,
  } = project;

  const now = new Date();
  const started = start_date && new Date(start_date) <= now;
  const finished = finish_date && new Date(finish_date) <= now;
  const genresList = genres && genres.length > 0 ? genres : tags;
  const [projectJSON, setProject] = useState(project);

  const renderProjectTiming = () => {
    if (!started) {
      return `PRE-ORDER STARTS ${dateFormat(start_date, 'mmm d yyyy').toUpperCase()}`;
    } else if (started && finish_date && !finished) {
      const finishSoonHours = Math.floor((new Date(finish_date) - now) / (1000 * 60 * 60));

      if (finishSoonHours < 24) {
        return `${finishSoonHours} ${finishSoonHours > 1 ? 'HOURS' : 'HOUR'} LEFT`;
      } else {
        const daysLeft = Math.floor(finishSoonHours / 24);
        return `${daysLeft} ${daysLeft > 1 ? 'DAYS' : 'DAY'} LEFT`;
      }
    }
  };

  const renderSection = () => {
    switch (section) {
      case 'dashboard':
        return <ArtistProjectState project={project} />;
      default:
        return (
          <div className="c-project__meta">
            <div className="c-project__title" wovn-ignore="">
              {title}
            </div>
            <div className="c-project__artist-name" wovn-ignore="">
              {artist_name}
            </div>
            {genresList && genresList.length > 0
              && <GenresList className="c-project__genres-list" items={genresList} cropToContainerSize />}
            <hr className="c-project__divider" />
            <div className="c-project__state">
              <div className="c-project__state-right">
                <div className="c-project__state-details">
                  <ProjectPrice currentUser={currentUser} project={project} />
                </div>
              </div>
              <div className="c-project__state-left">
                <div className="c-project__state-days">
                  {renderProjectTiming()}
                </div>
                {started && (
                  <div className="c-project__state-details">
                    <span>
                      PRE-ORDER
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        );
    }
  };

  return (
    <div
      className={classNames('c-project c-project--preorder', {
        [className]: className,
        'c-project--fixed-width': !isResponsive,
      })}
      key={objectID}
    >
      <div className="c-project__container">
        <ProjectPreview params={{project, section}} />
        {renderSection()}
        {section == 'dashboard' && restart_requests_count > 0 && !displayOrderButton && (
          <span className="c-project__repress-requests">{`${restart_requests_count} ${restart_requests_count > 1 ? 'Requests' : 'Request'}`}</span>
        )}
        <a className="c-project__link" href={href} target="_blank"> </a>
      </div>
      {displayOrderButton && <OrderButton projectJSON={projectJSON} setProject={setProject} low />}
    </div>
  );
};
