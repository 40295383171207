import React from 'react';

import {formatMoney} from 'utils/format_money';
import {formatDate} from 'utils/date';
import classNames from 'classnames';

import {Tooltip} from 'shared/components/tooltip/tooltip';

export const OrderCardSummuryBlock = ({
  className,
  itemsList,
  paymentCurrency,
  paymentTax,
  paymentTotalAmount,
  orderHeader,
  chargeDate,
}) => {
  return (
    <div
      className={classNames('c-order-card-summury-block', {
        [className]: className,
      })}
    >
      {orderHeader && (
      <div className="c-order-card-summury-block__order-header order-header">
        <h3 className="order-header__title">
          Order ID: &nbsp;
          <span wovn-ignore="">{orderHeader.orderID}</span>
        </h3>
        <div className="order-header__link">
          <a href={orderHeader.link}>
            Track the order &nbsp;
            <span className="u-hidden-tablet">in your account &nbsp;</span>
            &#8594;
          </a>
        </div>
      </div>
      )}
      <div className="c-order-card-summury-block__details details">
        {itemsList?.length > 0 && itemsList.map((item, index) => {
          return (
            <div
              className={classNames('details__item', {
                'details__item--is-bold': item.isBold,
              })}
              key={index}
            >
              <span className="details__title">{item.title}</span>
              <span className="details__value" wovn-ignore="">{item.value}</span>
            </div>
          );
        })}
        {paymentTax > 0 && (
          <div className="details__item">
            <span className="details__title">
              Taxes
              <Tooltip
                text="Taxes"
                theme="thin-frames"
              />
            </span>
            <span className="details__value">{formatMoney({amount: paymentTax, currency: paymentCurrency})}</span>
          </div>
        )}
      </div>
      <div className="c-order-card-summury-block__summury summury">
        <div className="summury__total">Total</div>
        <div className="summury__right">
          <span className="summury__price" wovn-ignore="">{formatMoney({amount: paymentTotalAmount, currency: paymentCurrency})}</span>
          {chargeDate && (
            <span className="summury__description">
              Charged on&nbsp;
              <span wovn-ignore="">
                {formatDate(chargeDate)}
              </span>
            </span>
          )}
        </div>
      </div>
    </div>
  );
};
