import {DesignPanel} from 'design_panel/design_panel';
import {ProjectMainPlayer} from 'shared/components/players/project_main_player';
import {ProjectNavigation} from 'shared/components/project_navigation/project_navigation';
import {ProjectPlaylistPlayer} from 'shared/components/players/project_playlist_player';
import {Credentials} from 'account/components/credentials';
import {Subscriptions} from 'account/components/subscriptions';
import {BillingInfoSection} from 'account/components/billing_info_section/billing_info_section';
import {DeliveryInfoSection} from 'account/components/delivery_info_section/delivery_info_section';
import {UnsubscriptionScreen} from 'account/components/unsubscription_screen/unsubscription_screen';
import {PublicHeader} from 'src/shared/components/headers/public_header/public_header';
import {ProjectWidget} from 'src/shared/components/widgets/project_widget/project_widget';

import {RendererScreen} from 'public/renderer/renderer_screen';

import {ProjectDetailsButton} from 'public/projects/project_details_button/project_details_button';
import {SellingStatus} from 'public/projects/selling_status/selling_status';

import {ShareIcon} from 'src/shared/components/share/share_icon/share_icon';

import {AccountProjectsPage} from 'src/dashboard/components/project_dashboard/tabs/account_projects_page/account_projects_page';
import {DeleteAccountSection} from 'src/dashboard/components/project_dashboard/settings/sections/delete_account_section/delete_account_section';
import {DashboardFollowersBar} from 'src/dashboard/components/project_dashboard/partials/dashboard_followers_bar/dashboard_followers_bar';
import {LanguageSelector} from 'src/shared/components/form/language_selector/language_selector';

import {CartScreen} from 'src/checkout/components/cart_screen/cart_screen';
import {OrderResultScreen} from 'src/checkout/components/order_result_screen/order_result_screen';
import {ShareIconsBlock} from 'src/shared/components/share/share_icons_block/share_icons_block';
import {PaymentDetailsScreen} from 'src/checkout/components/payment_details_screen/payment_details_screen';
import {CheckoutHeader} from 'src/checkout/components/checkout_header/checkout_header';

import {Purchases} from 'src/dashboard/components/purchases/purchases';
import {PurchaseOrder} from 'src/dashboard/components/purchases/purchase_orders/purchase_order/purchase_order';

import {PublicFooter} from 'src/shared/components/footers/public_footer/public_footer';
import {TopPage} from 'public/pages/top_page/top_page';
import {ForArtistsPage} from 'public/pages/for_artists_page/for_artists_page';
import {HowItWorksPage} from 'public/pages/how_it_works_page/how_it_works_page';
import {ForFansPage} from 'public/pages/for_fans_page/for_fans_page';
import {StoriesPage} from 'public/pages/stories_page/stories_page';
import {QratesCuratedPage} from 'public/pages/qrates_curated_page/qrates_curated_page';
import {ArtistToolkitPage} from 'public/pages/artist_toolkit_page/artist_toolkit_page';
import {ReleaseOfTheWeekPage} from 'public/pages/release_of_the_week_page/release_of_the_week_page';

export const DesignPanel_IFMD = DesignPanel;
export const ShareIcon_IFMD = ShareIcon;
export const ShareIconsBlock_IFMD = ShareIconsBlock;
export const ProjectMainPlayer_IFMD = ProjectMainPlayer;
export const ProjectNavigation_IFMD = ProjectNavigation;
export const ProjectPlaylistPlayer_IFMD = ProjectPlaylistPlayer;
export const ProjectDetailsButton_IFMD = ProjectDetailsButton;
export const SellingStatus_IFMD = SellingStatus;
export const Credentials_IFMD = Credentials;
export const Subscriptions_IFMD = Subscriptions;
export const UnsubscriptionScreen_IFMD = UnsubscriptionScreen;
export const PublicHeader_IFMD = PublicHeader;
export const PublicFooter_IFMD = PublicFooter;
export const ProjectWidget_IFMD = ProjectWidget;
export const RendererScreen_IFMD = RendererScreen;
export const LanguageSelector_IFMD = LanguageSelector;
export const BillingInfoSection_IFMD = BillingInfoSection;
export const DeliveryInfoSection_IFMD = DeliveryInfoSection;

// Dashboard
export const AccountProjectsPage_IFMD = AccountProjectsPage;
export const DeleteAccountSection_IFMD = DeleteAccountSection;
export const DashboardFollowersBar_IFMD = DashboardFollowersBar;

// Checkout
export const CheckoutHeader_IFMD = CheckoutHeader;
export const CartScreen_IFMD = CartScreen;
export const OrderResultScreen_IFMD = OrderResultScreen;
export const PaymentDetailsScreen_IFMD = PaymentDetailsScreen;

// Orders
export const Purchases_IFMD = Purchases;
export const PurchaseOrder_IFMD = PurchaseOrder;

// Public pages
export const TopPage_IFMD = TopPage;
export const ForArtistsPage_IFMD = ForArtistsPage;
export const HowItWorksPage_IFMD = HowItWorksPage;
export const ForFansPage_IFMD = ForFansPage;
export const StoriesPage_IFMD = StoriesPage;
export const QratesCuratedPage_IFMD = QratesCuratedPage;
export const ArtistToolkitPage_IFMD = ArtistToolkitPage;
export const ReleaseOfTheWeekPage_IFMD = ReleaseOfTheWeekPage;
