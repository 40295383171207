import React from 'react';
import classNames from 'classnames';

import {Preloader} from 'shared/components/preloader/preloader';
import {useAudioPlayer} from 'utils/use_audio_player/use_audio_player';

export const TrackPlayer = ({tracks}) => {
  const {
    isLoading,
    isPlaying,
    isArrowsActive,
    currentTrack,
    playPrev,
    playNext,
    onPlay,
    error,
  } = useAudioPlayer(tracks);

  const PlayButton = () => (
    <svg viewBox="0 0 100 100">
      <path d="M22 0h20v1e2H20z" />
      <path d="M65 0h20v1e2H60z" />
    </svg>
  );
  const PauseButton = () => <svg viewBox="0 0 100 100"><path d="M90.2 50.1 11 .5V1e2l79.4-49.7L90.2 50.1z" /></svg>;
  const PrevButton = () => <svg viewBox="0 0 24 24"><path d="M24 24 4.8 12 24 0V24zM4.8.0v24H0V0H4.8z" /></svg>;
  const NextButton = () => <svg viewBox="0 0 24 24"><path className="st0" d="M0 0l19.2 12L0 24V0zM24 0v24h-4.8V0H24z" /></svg>;

  return (
    <div className="track-player">
      <div className="track-player__buttons">
        <div className={classNames('track-player__back-button', {disabled: !isArrowsActive})} onClick={playPrev}><PrevButton /></div>
        {!isLoading && (
          <div
            className={classNames('track-player__play-button', {disabled: error})}
            onClick={onPlay}
          >
            {isPlaying ? <PlayButton /> : <PauseButton />}
          </div>
        )}
        {isLoading && <Preloader className="track-player__preloader" />}
        <div
          className={classNames('track-player__forward-button', {disabled: !isArrowsActive})}
          onClick={playNext}
        >
          <NextButton />
        </div>
      </div>
      <h3 className="track-player__track-name">{currentTrack?.title}</h3>
    </div>
  );
};
