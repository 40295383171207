// @flow
import React from 'react';
import styled from 'styled-components';
import {RichText} from 'prismic-reactjs';
import {cssSmallPlusishText} from 'prismic/shared/styles/typography';
import type {StorySliceFooter} from 'prismic/shared/cms/story';
import {getSliceFooterText} from 'prismic/shared/cms/story';
import {cssGenericStyling} from 'prismic/shared/styles/shared';
import {
  breakpointTabletOnly,
  cssMobileSidePadding,
} from 'prismic/shared/styles/responsive';

const StyledContainer = styled.div`
  ${cssMobileSidePadding};
  width: 100%;
  padding-top: 30px;

  ${breakpointTabletOnly} {
    margin-bottom: 20px;
  }
`;

const StyledContent = styled.p`
  ${cssGenericStyling};
  ${cssSmallPlusishText};
  max-width: 935px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;

  ${breakpointTabletOnly} {
    margin: 0px 15px;
    width: 100%;
    max-width: unset;
  }
`;

type Props = {
  slice: StorySliceFooter,
};

const StoryFooter = ({ slice }: Props) => (
  <StyledContainer>
    <StyledContent wovn-ignore>{RichText.asText(getSliceFooterText(slice))}</StyledContent>
  </StyledContainer>
);

export default StoryFooter;
