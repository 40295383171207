import {handleActions} from 'redux-actions';

import {pick, sumBy, remove} from 'lodash';

import Project from 'models/project';
import Vinyl from 'models/vinyl';
import ProjectSpec from 'models/project_spec';
import ArtworkFile from 'models/artwork_file';
import StoredFile from 'models/stored_file';
import Actions from 'project_start/modules/actions';
import {updateVariation} from 'utils/spec_variations';
import {countriesDefaultData} from 'utils/country_helpers';

export default handleActions({
  [Actions.setProject]: (state, action) => {
    const {projectJSON} = action.payload;
    return Project.fromJSON(projectJSON);
  },
  [Actions.setBasedProject]: (state, action) => {
    const {projectJSON, repress} = action.payload;
    const project = Project.fromJSON(projectJSON);
    const repressParams = {
      id: null,
      base_project_id: project.id,
      product_order: {
        repress,
        main_delivery: {id: null},
      },
      selling_condition: {
        start_date: null,
        finish_date: null,
      },
      spec: {
        testpress: false,
        mastering: false,
        mastering_tracks: 0,
      },
    };

    return project.mergeDeep(repressParams);
  },
  [Actions.setProjectTitle]: (project, action) => {
    const {title} = action.payload;
    return project.setIn(['product_order', 'title'], title);
  },
  [Actions.setProjectType]: (project, action) => {
    const {projectType, defaultCountry} = action.payload;
    const mainDeliveryCountry = project.getIn(['product_order', 'deliveries', 0, 'country']);
    const countryData = countriesDefaultData[mainDeliveryCountry || defaultCountry];

    switch (projectType) {
      case 'funding':
      case 'sell':
        return project.mergeDeep({
          project_type: projectType,
          product_order: {
            delivery_service: countryData?.delivery_service || 'delivery_service_france',
          },
          selling_condition: {
            selling_type: projectType,
            delivery_service: countryData?.delivery_service || 'delivery_service_france',
            ship_from: 'US',
          },
        });
      case 'press_only':
        return project.mergeDeep({
          project_type: projectType,
          product_order: {
            delivery_service: 'delivery_service_self',
          },
          selling_condition: {
            selling_type: null,
          },
        }).setIn(['product_order', 'deliveries', 0, 'country'], mainDeliveryCountry || defaultCountry)
          .setIn(['product_order', 'deliveries', 0, 'purpose'], 'main');
      default:
        return project;
    }
  },
  [Actions.setProjectCost]: (project, action) => {
    if (project.project_type != 'press_only') {
      const {
        response: {
          currency,
          product_order: {
            quantity,
            cost,
            cost_with_changes,
            cost_versions,
            cost_versions_with_changes,
            coupon_id,
            coupon,
            campaign_applied,
            spec,
            spec_options,
            spec_json,
            disabled_spec_options,
            durations,
            available_mockups,
            billing_country,
            markers,
          },
          selling_condition: {
            base_quantity,
            estimated_profit,
            estimated_quantity,
            estimated_preorder_quantity,
            estimated_wholesale_quantity,
            total_sales_amount,
            total_handling_fee,
            min_price,
            min_wholesale_price,
            preorder_available,
            preorder_max_quantity,
          },
        },
      } = action.payload;

      return project
        .setIn(['product_order', 'cost'], cost)
        .setIn(['product_order', 'cost_with_changes'], cost_with_changes)
        .setIn(['product_order', 'cost_versions'], cost_versions)
        .setIn(['product_order', 'cost_versions_with_changes'], cost_versions_with_changes)
        .setIn(['product_order', 'coupon_id'], coupon_id)
        .setIn(['product_order', 'coupon'], coupon)
        .setIn(['product_order', 'campaign_applied'], campaign_applied)
        .setIn(['product_order', 'quantity'], quantity)
        .setIn(['product_order', 'spec_options'], spec_options)
        .setIn(['product_order', 'spec_json'], spec_json)
        .setIn(['product_order', 'disabled_spec_options'], disabled_spec_options)
        .setIn(['product_order', 'durations'], durations)
        .setIn(['product_order', 'available_mockups'], available_mockups)
        .setIn(['product_order', 'billing_country'], billing_country)
        .setIn(['product_order', 'markers'], markers)
        .setIn(['selling_condition', 'estimated_profit'], estimated_profit)
        .setIn(['selling_condition', 'estimated_quantity'], estimated_quantity)
        .setIn(['selling_condition', 'estimated_preorder_quantity'], estimated_preorder_quantity)
        .setIn(['selling_condition', 'estimated_wholesale_quantity'], estimated_wholesale_quantity)
        .setIn(['selling_condition', 'total_sales_amount'], total_sales_amount)
        .setIn(['selling_condition', 'total_handling_fee'], total_handling_fee)
        .setIn(['selling_condition', 'min_price'], min_price)
        .setIn(['selling_condition', 'min_wholesale_price'], min_wholesale_price)
        .setIn(['selling_condition', 'base_quantity'], base_quantity)
        .setIn(['selling_condition', 'currency'], currency)
        .setIn(['selling_condition', 'preorder_available'], preorder_available)
        .setIn(['selling_condition', 'preorder_max_quantity'], preorder_max_quantity)
        .set('currency', currency)
        .set('spec', ProjectSpec.fromJSON(spec))
        .setIn(['spec', 'quantity'], quantity);
    }

    const {
      response: {
        currency,
        product_order: {
          quantity,
          cost,
          cost_with_changes,
          cost_versions,
          cost_versions_with_changes,
          coupon_id,
          coupon,
          spec,
          spec_options,
          spec_json,
          disabled_spec_options,
          durations,
          available_mockups,
          billing_country,
          markers,
          deliveries,
        },
      },
    } = action.payload;

    return project
      .setIn(['product_order', 'cost'], cost)
      .setIn(['product_order', 'cost_with_changes'], cost_with_changes)
      .setIn(['product_order', 'cost_versions'], cost_versions)
      .setIn(['product_order', 'cost_versions_with_changes'], cost_versions_with_changes)
      .setIn(['product_order', 'coupon_id'], coupon_id)
      .setIn(['product_order', 'coupon'], coupon)
      .setIn(['product_order', 'quantity'], quantity)
      .setIn(['product_order', 'spec_options'], spec_options)
      .setIn(['product_order', 'spec_json'], spec_json)
      .setIn(['product_order', 'disabled_spec_options'], disabled_spec_options)
      .setIn(['product_order', 'durations'], durations)
      .setIn(['product_order', 'available_mockups'], available_mockups)
      .setIn(['product_order', 'markers'], markers)
      .setIn(['product_order', 'deliveries'], deliveries)
      .setIn(['product_order', 'billing_country'], billing_country)
      .set('currency', currency)
      .set('spec', ProjectSpec.fromJSON(spec))
      .setIn(['spec', 'quantity'], quantity);
  },
  [Actions.saveArtworkMockupFile]: (state, action) => {
    const {type, file_id, url} = action.payload;

    const artworkFiles = state.product_order.artwork_files.map((file) => {
      const {file_type, uploaded_file} = file;

      if (uploaded_file && uploaded_file.stored_file_type_id === type.id && file_type === 'mockup') {
        return file.set('_destroy', true);
      } else {
        return file;
      }
    });

    const storedFile = new StoredFile({
      created_at: new Date().toString(),
      id: file_id,
      stored_file_type_id: type.id,
      url,
    });
    const artworkFile = new ArtworkFile({
      file_type: 'mockup',
      uploaded_file: storedFile,
    });

    artworkFiles.push(artworkFile);

    return state.setIn(['product_order', 'artwork_files'], artworkFiles);
  },

  // Basics tab - common

  [Actions.setFormatType]: (project, action) => {
    const {formatType} = action.payload;
    const quantity = project.getIn(['spec', 'quantity']);

    if (formatType === 'Product::CassetteOrder') {
      project = project.setIn(['spec', 'cassettes', 0, 'shell_color'], 11);
      project = project.setIn(['spec', 'cassettes', 0, 'label_color'], 50);
      project = project.setIn(['spec', 'packaging'], 'packaging_none');
    } else {
      project = project.setIn(['spec', 'quantity'], quantity < 100 ? 100 : quantity);
      project = project.setIn(['spec', 'packaging'], 'sleeve_extra_packaging_overbag');
    }
    return project.setIn(['product_order', 'type'], formatType).setIn(['product_order', 'coupon_id'], null);
  },

  // Basics tab - cassette

  [Actions.setTapeLength]: (project, action) => {
    const {tapeLength} = action.payload;
    return project.setIn(['spec', 'tape_length'], tapeLength);
  },

  // Basics tab - vinyl

  [Actions.setVinylSize]: (project, action) => {
    const {vinylSize} = action.payload;
    const innersleeveType = project.getIn(['spec', 'innersleeve_type']);
    const isPolylined = ['innersleeve_type_polylined_white', 'innersleeve_type_polylined_black'].includes(innersleeveType);

    let newProject = project;
    if (vinylSize === 'vinyl_size_10') {
      if (isPolylined) {
        newProject = newProject.setIn(['spec', 'innersleeve_type'], 'innersleeve_type_paper_white');
      }
      const vinyls = project.getIn(['spec', 'vinyls']).map((vinyl) => {
        if (vinyl.get('vinyl_weight') == 'vinyl_weight_heavy') {
          return vinyl.set('vinyl_weight', 'vinyl_weight_normal');
        } else {
          return vinyl;
        }
      });
      newProject = newProject.setIn(['spec', 'vinyls'], vinyls);
    }
    if (vinylSize === 'vinyl_size_7') {
      if (isPolylined) {
        newProject = newProject.setIn(['spec', 'innersleeve_type'], 'innersleeve_type_paper_white');
      }
      const vinyls = project.getIn(['spec', 'vinyls']).map((vinyl) => {
        if (vinyl.get('vinyl_weight') == 'vinyl_weight_heavy') {
          return vinyl.set('vinyl_weight', 'vinyl_weight_normal');
        } else {
          return vinyl;
        }
      });
      newProject = newProject.setIn(['spec', 'vinyls'], vinyls);
    }

    return newProject.setIn(['spec', 'vinyl_size'], vinylSize);
  },
  [Actions.setHoleSize]: (project, action) => {
    const {holeSize} = action.payload;
    return project.setIn(['spec', 'vinyl_hole_size'], holeSize);
  },
  [Actions.setQuantity]: (project, action) => {
    const {quantity} = action.payload;
    return project.setIn(['spec', 'quantity'], quantity);
  },
  [Actions.setVinylRPM]: (project, action) => {
    const {rpm} = action.payload;
    return project.setIn(['spec', 'rpm'], rpm);
  },
  [Actions.setVinylsNumber]: (project, action) => {
    const {number} = action.payload;
    const vinyl = project.getIn(['spec', 'vinyls']).filter((vinyl) => { return vinyl.get('number') == 1; })[0];
    const sleeveType = project.getIn(['spec', 'sleeve_type']);
    let newSleeveType;

    switch (number) {
      case 1:
        newSleeveType = sleeveType == 'sleeve_type_print_5mm_with_hole'
          ? 'sleeve_type_print_3mm_with_hole'
          : 'sleeve_type_print_3mm_without_hole';

        return project.mergeIn(
          ['spec'],
          {
            num_of_vinyls: 1,
            vinyls: [vinyl],
            packaging_type: 'packaging_print',
            sleeve_type: newSleeveType,
          },
        );
      case 2:
        newSleeveType = ['sleeve_type_print_3mm_with_hole', 'sleeve_type_print_with_hole'].includes(sleeveType)
          ? 'sleeve_type_print_5mm_with_hole'
          : 'sleeve_type_print_5mm_without_hole';

        return project.mergeIn(
          ['spec'],
          {
            num_of_vinyls: 2,
            vinyls: [vinyl, new Vinyl({number: 2})],
            packaging_type: 'packaging_print',
            sleeve_type: newSleeveType,
          },
        );
      default:
        return project;
    }
  },

  // Product tab

  [Actions.setCassetteType]: (project, action) => {
    const {number, value, variationIndex} = action.payload;
    const getCassettes = (spec) => {
      return spec.cassettes.map((cassette) => {
        if (cassette.number === number) {
          if (value == 'cassette_type_solid') {
            cassette = cassette.set('shell_color', 11);
          } else if (value === 'cassette_type_transparent') {
            cassette = cassette.set('shell_color', 16);
          }
          return cassette.set('cassette_type', value);
        } else {
          return cassette;
        }
      });
    };

    return updateVariation({
      index: variationIndex,
      project,
      update: (spec) => spec.set('cassettes', getCassettes(spec)),
    });
  },
  [Actions.setVinylType]: (project, action) => {
    const {vinylNumber, value, variationIndex} = action.payload;

    const getVinyls = (spec) => {
      return spec.vinyls.map((vinyl) => {
        if (vinyl.number == vinylNumber) {
          let newVinyl = vinyl;
          if (value == 'vinyl_type_picture') {
            newVinyl = vinyl.set('vinyl_weight', 'vinyl_weight_normal');
          }

          return newVinyl.set('vinyl_type', value);
        } else {
          return vinyl;
        }
      });
    };

    return updateVariation({
      index: variationIndex,
      project,
      update: (spec) => spec.set('vinyls', getVinyls(spec)),
    });
  },
  [Actions.setSpecialPattern]: (project, action) => {
    const {vinylNumber, value, variationIndex} = action.payload;
    const getVinyls = (spec) => {
      return spec.vinyls.map((vinyl) => {
        if (vinyl.number == vinylNumber) {
          return vinyl.set('special_type', value);
        } else {
          return vinyl;
        }
      });
    };

    return updateVariation({
      index: variationIndex,
      project,
      update: (spec) => spec.set('vinyls', getVinyls(spec)),
    });
  },
  [Actions.setCassetteColor]: (project, action) => {
    const {
      number,
      field,
      value,
      variationIndex,
    } = action.payload;
    const getCassettes = (spec) => {
      return spec.cassettes.map((cassette) => {
        if (cassette.number === number) {
          return cassette.set(field, value);
        } else {
          return cassette;
        }
      });
    };

    return updateVariation({
      index: variationIndex,
      project,
      update: (spec) => spec.set('cassettes', getCassettes(spec)),
    });
  },
  [Actions.setVinylColor]: (project, action) => {
    const {
      vinylNumber,
      field,
      value,
      variationIndex,
    } = action.payload;
    const getVinyls = (spec) => {
      return spec.vinyls.map((vinyl) => {
        if (vinyl.number == vinylNumber) {
          return vinyl.set(field, value);
        } else {
          return vinyl;
        }
      });
    };

    return updateVariation({
      index: variationIndex,
      project,
      update: (spec) => spec.set('vinyls', getVinyls(spec)),
    });
  },

  [Actions.setVinylWeight]: (project, action) => {
    const {vinylNumber, value, variationIndex} = action.payload;
    const getVinyls = (spec) => {
      return spec.vinyls.map((vinyl) => {
        if (vinyl.number == vinylNumber) {
          return vinyl.set('vinyl_weight', value);
        } else {
          return vinyl;
        }
      });
    };
    return updateVariation({
      index: variationIndex,
      project,
      update: (spec) => spec.set('vinyls', getVinyls(spec)),
    });
  },
  [Actions.setVinylLabel]: (project, action) => {
    const {vinylNumber, value, variationIndex} = action.payload;
    const getVinyls = (spec) => {
      return spec.vinyls.map((vinyl) => {
        if (vinyl.number == vinylNumber) {
          return vinyl.set('label_type', value);
        } else {
          return vinyl;
        }
      });
    };

    return updateVariation({
      index: variationIndex,
      project,
      update: (spec) => spec.set('vinyls', getVinyls(spec)),
    });
  },
  [Actions.setCassetteLabelType]: (project, action) => {
    const {number, value, variationIndex} = action.payload;
    const getCassettes = (spec) => {
      return spec.cassettes.map((cassette) => {
        if (cassette.number === number) {
          if (value === 'label_type_screen_print') {
            cassette = cassette.set('label_color', 50);
          }
          return cassette.set('label_type', value);
        } else {
          return cassette;
        }
      });
    };

    return updateVariation({
      index: variationIndex,
      project,
      update: (spec) => spec.set('cassettes', getCassettes(spec)),
    });
  },

  // Packaging tab

  [Actions.setPackagingType]: (project, action) => {
    const {packagingType} = action.payload;
    let packaging = project.getIn(['spec', 'packaging']);
    let innersleeve_type = project.getIn(['spec', 'innersleeve']);
    let finish = project.getIn(['spec', 'finish']);
    let sleeve_type = project.getIn(['spec', 'sleeve_type']);
    let numbering = project.getIn(['spec', 'numbering']);
    let numberingFormat = project.getIn(['spec', 'numbering_format']);
    let numberingColor = project.getIn(['spec', 'numbering_color']);
    const numOfVinyls = project.getIn(['spec', 'num_of_vinyls']);

    switch (packagingType) {
      case 'packaging_discobag':
        if (!packaging || packaging === 'sleeve_extra_packaging_none') {
          packaging = 'sleeve_extra_packaging_overbag';
        }
        if (!innersleeve_type || innersleeve_type === 'innersleeve_type_none') {
          innersleeve_type = 'innersleeve_type_paper_white';
        }
        sleeve_type = 'sleeve_type_discobag_white';
        finish = 'sleeve_extra_finish_none';
        break;
      case 'packaging_print':
        if (!packaging || packaging === 'sleeve_extra_packaging_none') {
          packaging = 'sleeve_extra_packaging_overbag';
        }
        if (!finish || finish === 'sleeve_extra_finish_none') {
          finish = 'sleeve_extra_finish_lamination_matt';
        }
        if (!innersleeve_type || innersleeve_type === 'innersleeve_type_none') {
          innersleeve_type = 'innersleeve_type_paper_white';
        }
        if (numOfVinyls == 2) {
          sleeve_type = 'sleeve_type_print_5mm_without_hole';
        } else {
          sleeve_type = 'sleeve_type_print_without_hole';
        }
        break;
      case 'packaging_pvc':
        sleeve_type = 'sleeve_type_pvc';
        packaging = 'sleeve_extra_packaging_none';
        finish = 'sleeve_extra_finish_none';
        innersleeve_type = 'innersleeve_type_none';
        numbering = 'sleeve_extra_numbering_none';
        numberingFormat = null;
        numberingColor = null;
        break;
      case 'packaging_gatefold':
        if (!packaging || packaging === 'sleeve_extra_packaging_none') {
          packaging = 'sleeve_extra_packaging_overbag';
        }
        if (!finish || finish === 'sleeve_extra_finish_none') {
          finish = 'sleeve_extra_finish_lamination_matt';
        }
        if (!innersleeve_type || innersleeve_type === 'innersleeve_type_none') {
          innersleeve_type = 'innersleeve_type_paper_white';
        }
        sleeve_type = 'sleeve_type_gatefold';
        break;
      default:
        return project;
    }
    return project.mergeIn(['spec'], {
      packaging_type: packagingType,
      sleeve_type,
      packaging,
      finish,
      innersleeve_type,
      numbering,
      numbering_format: numberingFormat,
      numbering_color: numberingColor,
    });
  },
  [Actions.setCaseType]: (project, action) => {
    const {caseType} = action.payload;
    let newProject = project;
    const plasticClearCases = [
      'case_type_plastic_clear',
      'case_type_plastic_clear_black',
    ];

    switch (caseType) {
      case 'case_type_plastic_clear':
      case 'case_type_plastic_clear_black':
        // don't change the inner card setting
        // if switching between plastic clear and plastic clear black options
        if (!plasticClearCases.includes(project.getIn(['spec', 'case_type']))) {
          newProject = newProject.setIn(['spec', 'inner_card'], 'inner_card_3_panel_j_card');
        }
        break;
      case 'case_type_poly':
        newProject = newProject.setIn(['spec', 'inner_card'], 'inner_card_none');
        break;
      case 'case_type_paper':
        newProject = newProject.setIn(['spec', 'inner_card'], 'inner_card_none');
        newProject = newProject.setIn(['spec', 'outer_sleeve'], 'outer_sleeve_none');
        break;
    }
    return newProject.setIn(['spec', 'case_type'], caseType);
  },
  [Actions.setSleeveType]: (project, action) => {
    const {sleeveType} = action.payload;
    return project.setIn(['spec', 'sleeve_type'], sleeveType);
  },
  [Actions.setInnerSleeveType]: (project, action) => {
    const {innerSleeveType} = action.payload;
    return project.setIn(['spec', 'innersleeve_type'], innerSleeveType);
  },
  [Actions.setFinish]: (project, action) => {
    const {finish} = action.payload;
    return project.setIn(['spec', 'finish'], finish);
  },
  [Actions.setShippingMaterial]: (project, action) => {
    const {material} = action.payload;
    return project.setIn(['spec', 'packaging'], material);
  },
  [Actions.setInnerCard]: (project, action) => {
    const {innerCard} = action.payload;
    return project.setIn(['spec', 'inner_card'], innerCard);
  },
  [Actions.setOuterSleeve]: (project, action) => {
    const {outerSleeve} = action.payload;
    return project.setIn(['spec', 'outer_sleeve'], outerSleeve);
  },

  // Extras tab

  [Actions.toggleArtworkEnabled]: (project) => {
    const artworkEnabled = !project.getIn(['spec', 'artwork_support']);
    return project.setIn(['spec', 'artwork_support'], artworkEnabled);
  },
  [Actions.setDownloadCard]: (project, action) => {
    const {digital} = action.payload;
    return project.setIn(['spec', 'digital'], digital);
  },
  [Actions.setMastering]: (project, action) => {
    const {mastering, sub} = action.payload;
    return project.mergeDeep({spec: {mastering, mastering_sub: sub}});
  },
  [Actions.setMasteringQuantity]: (project, action) => {
    const {quantity} = action.payload;
    return project.setIn(['spec', 'mastering_tracks'], quantity);
  },
  [Actions.setTestpress]: (project, action) => {
    const {testpress} = action.payload;
    return project.setIn(['spec', 'testpress'], testpress);
  },
  [Actions.setDemo]: (project, action) => {
    const {demo} = action.payload;
    return project.setIn(['spec', 'demo'], demo);
  },
  [Actions.setInsert]: (project, action) => {
    const {insert} = action.payload;
    return project.setIn(['spec', 'insert'], insert);
  },
  [Actions.setSticker]: (project, action) => {
    const {sticker} = action.payload;
    return project.setIn(['spec', 'sticker'], sticker);
  },
  [Actions.setNumbering]: (project, action) => {
    const {numbering} = action.payload;
    return project.setIn(['spec', 'numbering'], numbering);
  },
  [Actions.setNumberingFormat]: (project, action) => {
    const {numbering_format, numbering_color} = action.payload;
    return project
      .setIn(['spec', 'numbering_format'], numbering_format)
      .setIn(['spec', 'numbering_color'], numbering_color);
  },
  [Actions.setObi]: (project, action) => {
    const {obi} = action.payload;
    return project.setIn(['spec', 'obi'], obi);
  },

  // Price calculator

  [Actions.setCurrency]: (project, action) => {
    const {currency} = action.payload;
    const defaultPrices = {
      USD: 3000,
      GBP: 3000,
      EUR: 3000,
      JPY: 3500,
    };
    return project
      .set('currency', currency)
      .setIn(['selling_condition', 'currency'], currency)
      .setIn(['selling_condition', 'price'], defaultPrices[currency]);
  },
  [Actions.setPledgeAmount]: (project, action) => {
    const {value} = action.payload;
    return project.setIn(['selling_condition', 'price'], value);
  },
  [Actions.setSalePrice]: (project, action) => {
    const {value} = action.payload;
    return project.setIn(['selling_condition', 'price'], value);
  },
  [Actions.setTaxRate]: (project, action) => {
    const {value} = action.payload;
    return project.setIn(['selling_condition', 'tax_rate'], value);
  },
  [Actions.setDeliveryService]: (project, action) => {
    const {deliveryService} = action.payload;

    if (project.get('project_type') == 'press_only') {
      return project.setIn(['product_order', 'delivery_service'], deliveryService);
    }

    return project.mergeDeep({
      product_order: {
        delivery_service: deliveryService,
      },
      selling_condition: {
        delivery_service: deliveryService,
        ship_from: deliveryService == 'delivery_service_us' ? 'US' : 'GB',
      },
    });
  },
  [Actions.togglePreorderEnabled]: (project, action) => {
    const preorderEnabled = !project.getIn(['selling_condition', 'preorder_available']);
    return project.setIn(['selling_condition', 'preorder_available'], preorderEnabled);
  },
  [Actions.setPreorderPrice]: (project, action) => {
    const {value} = action.payload;
    return project.setIn(['selling_condition', 'preorder_price'], value);
  },
  [Actions.setPreorderQuantity]: (project, action) => {
    const {value} = action.payload;
    return project.setIn(['selling_condition', 'preorder_max_quantity'], value);
  },
  [Actions.toggleStoreNetworkEnabled]: (project, action) => {
    const storeNetworkEnabled = !project.getIn(['selling_condition', 'wholesale_available']);
    return project.setIn(['selling_condition', 'wholesale_available'], storeNetworkEnabled);
  },
  [Actions.setWholesalePrice]: (project, action) => {
    const {value} = action.payload;
    return project.setIn(['selling_condition', 'wholesale_price'], value);
  },
  [Actions.setWholesaleQuantity]: (project, action) => {
    const {quantity} = action.payload;
    return project.setIn(['selling_condition', 'wholesale_max_quantity'], quantity);
  },
  [Actions.setShippingCountry]: (project, action) => {
    const {country} = action.payload;
    return project.setIn(['product_order', 'deliveries', 0, 'country'], country);
  },

  // visualizer
  [Actions.setFrontSleeveImage]: (project, action) => {
    const {visualizerImage} = action.payload;
    return project.set('front_sleeve_image_url', visualizerImage);
  },
  [Actions.setBackSleeveImage]: (project, action) => {
    const {visualizerImage} = action.payload;
    return project.set('back_sleeve_image_url', visualizerImage);
  },
  [Actions.setSpineSleeveImage]: (project, action) => {
    const {visualizerImage} = action.payload;
    return project.set('spine_sleeve_image_url', visualizerImage);
  },
  [Actions.setInnerSleeve]: (project, action) => {
    const {visualizerImage} = action.payload;
    return project.set('inner_sleeve_image_url', visualizerImage);
  },
  [Actions.setFrontLabelImageA]: (project, action) => {
    const {visualizerImage} = action.payload;
    return project.set('a_label_image_url', visualizerImage);
  },
  [Actions.setBackLabelImageA]: (project, action) => {
    const {visualizerImage} = action.payload;
    return project.set('b_label_image_url', visualizerImage);
  },
  [Actions.setFrontImageA]: (project, action) => {
    const {visualizerImage} = action.payload;
    return project.set('a_vinyl_texture_url', visualizerImage);
  },
  [Actions.setBackImageA]: (project, action) => {
    const {visualizerImage} = action.payload;
    return project.set('b_vinyl_texture_url', visualizerImage);
  },
  [Actions.setItemPageDesignBackgroundColor]: (project, action) => {
    const {color} = action.payload;

    return project.setIn(['item', 'item_page_design', 'background_color'], color);
  },
  [Actions.setPersistedItems]: (project, action) => {
    const {items} = action.payload;

    return project.setIn(['product_order', 'cost', 'persisted_items'], items);
  },
  [Actions.setCampaignApplied]: (project, action) => {
    const {campaign_applied} = action.payload;
    return project.setIn(['product_order', 'campaign_applied'], campaign_applied);
  },
  [Actions.addSpecVariation]: (project) => {
    const variations = project.getIn(['product_order', 'spec_variations']);
    let spec = project.get('spec');
    const newVariation = pick(spec, ['vinyls', 'cassettes', 'num_of_vinyls']);
    let newVariations = [...variations, ProjectSpec.fromJSON(newVariation)];
    newVariations = newVariations.map((variation, index) => variation.set('index', index + 1));
    const variationsQuantity = sumBy(variations, (v) => parseInt(v.getIn(['vinyls', 0, 'quantity'])));
    spec = spec.set('vinyls', spec.vinyls.map((v) => v.set('quantity', spec.quantity - variationsQuantity)));
    spec = spec.set('cassettes', spec.cassettes.map((c) => c.set('quantity', spec.quantity - variationsQuantity)));

    return project.setIn(['product_order', 'spec_variations'], newVariations).set('spec', spec);
  },
  [Actions.removeSpecVariation]: (project, action) => {
    const {variationIndex} = action.payload;

    const variations = project.getIn(['product_order', 'spec_variations']);
    remove(variations, (e) => e.index === variationIndex);
    let spec = project.get('spec');
    const variationsQuantity = sumBy(variations, (v) => parseInt(v.getIn(['vinyls', 0, 'quantity'])));
    spec = spec.set('vinyls', spec.vinyls.map((v) => v.set('quantity', spec.quantity - variationsQuantity)));
    spec = spec.set('cassettes', spec.cassettes.map((c) => c.set('quantity', spec.quantity - variationsQuantity)));

    return project.setIn(['product_order', 'spec_variations'], variations).set('spec', spec);
  },
  [Actions.setSpecVariationQuantity]: (project, action) => {
    const {quantity, index} = action.payload;
    let variations = project.getIn(['product_order', 'spec_variations']);
    let spec = project.get('spec');
    variations = variations.map((variation) => {
      if (variation.index === index) {
        return variation
          .set('vinyls', variation.vinyls.map((item) => item.set('quantity', quantity)))
          .set('cassettes', variation.cassettes.map((item) => item.set('quantity', quantity)));
      }

      return variation;
    });
    const variationsQuantity = sumBy(variations, (v) => parseInt(v.getIn(['vinyls', 0, 'quantity'])));
    const mainVariationQuantity = spec.quantity - variationsQuantity;
    spec = spec
      .set('vinyls', spec.vinyls.map((item) => item.set('quantity', mainVariationQuantity)))
      .set('cassettes', spec.cassettes.map((item) => item.set('quantity', mainVariationQuantity)));

    return project.set('spec', spec).setIn(['product_order', 'spec_variations'], variations);
  },
}, new Project());
