import React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import {QueryParamProvider} from 'use-query-params';
import RouterScrollTop from 'components/shared/router_scroll_top';

import Navigation from './nav';

import {StoriesScreen} from './screens/StoriesScreen/StoriesScreen';
import {StoryScreen} from './screens/StoryScreen/StoryScreen';
import {STORIES_FILTERED_TYPES} from './screens/StoriesScreen/components/StoriesNav/StoriesNav';

export const PrismicStories = ({
  algolia_id,
  algolia_search_api_key,
  raw_results,
  story,
}) => {
  return (
    <Router>
      <RouterScrollTop />
      <QueryParamProvider ReactRouterRoute={Route}>
        <Switch>
          {STORIES_FILTERED_TYPES.map(filter => (
            <Route path={`/stories/${filter}`} key={filter}>
              <Navigation />
              <StoriesScreen filter={filter} algolia_id={algolia_id} algolia_search_api_key={algolia_search_api_key} />
            </Route>
          ))}
          <Route path="/stories/:slug">
            <StoryScreen story={story} />
          </Route>
          <Route path="/stories">
            <Navigation />
            <StoriesScreen algolia_id={algolia_id} algolia_search_api_key={algolia_search_api_key} raw_results={raw_results} />
          </Route>
        </Switch>
      </QueryParamProvider>
    </Router>
  );
};
