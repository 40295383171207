import React, {useState, useEffect} from 'react';
import {
  get,
  set,
  map,
  flattenDeep,
  uniq,
} from 'lodash';
import classNames from 'classnames';

import {findLocations} from 'utils/find_locations';

import {InputField} from 'shared/components/form/input_field/input_field';
import {HalfFieldsContainer} from 'shared/components/form/grid_containers/half_fields_container/half_fields_container';
import {SelectField} from 'shared/components/form/select_field/select_field';
import {CheckboxField} from 'shared/components/form/checkbox_field/checkbox_field';
import {ReactButton} from 'shared/components/buttons/react_button/react_button';
import {ModalHeading} from 'shared/components/modals/parts/content/modal_heading/modal_heading';

export const ShippingInformationForm = ({
  isModal,
  formParams,
  setFormParams,
  calculateOrder,
  isReadOnly,
  onCloseModal,
  usStates,
  jpStates,
  className,
  currentUser,
  isUserInfoPresent,
}) => {
  const countries = window.constants.deliverableCountries.map((country) => ({label: country.name, value: country.code}));
  const usStatesOptions = usStates.map((state) => ({value: state, label: state}));
  const jpStatesOptions = jpStates.map((state) => ({value: state, label: state}));

  const [params, setParams] = useState({...formParams});
  const [postalCodeAppllied, setPostalCodeApplied] = useState(get(params, 'orders[0].order_items[0].delivery_postal_code'));
  const [applyPostalCodeDisabled, setApplyPostalCodeDisabled] = useState(false);
  const country = get(params, 'orders[0].order_items[0].delivery_country');

  const handleChange = (event, calculate = false) => {
    const newFormParams = isModal ? {...params} : {...formParams};
    newFormParams.orders.forEach((order) => {
      if (formParams.pre_populate_billing_info) {
        const fieldName = String(event.target.name).match(/delivery_([a-z_]+)/)?.[1];
        fieldName && set(order, `billing_${fieldName}`, event.target.value);
      }
      const orderItemFieldName = String(event.target.name).match(/(?:order_items\.)?([a-z_]+)/)?.[1];
      order.order_items.forEach((orderItem) => {
        orderItemFieldName && set(orderItem, orderItemFieldName, event.target.value);
      });
    });
    setParams(newFormParams);
    !isModal && setFormParams(newFormParams);
    calculate && calculateOrder({params: newFormParams});
  };

  const handleCountryChange = (value) => {
    handleChange({target: {name: 'delivery_country', value}}, !isModal);
    if (['US', 'JP', 'GB'].includes(value)) {
      handleChange({target: {name: 'delivery_prefecture', value: ''}});
    }
  };

  const handleCopyCheckbox = () => {
    const newFormParams = isModal ? {...params} : {...formParams};
    newFormParams.pre_populate_user_delivery_info = !params.pre_populate_user_delivery_info;
    setParams(newFormParams);
    !isModal && setFormParams(newFormParams);
  };

  const applyPostalCode = () => {
    setApplyPostalCodeDisabled(true);
    findLocations({
      params: {
        input: get(params, 'orders[0].order_items[0].delivery_postal_code'),
        country: get(params, 'orders[0].order_items[0].delivery_country'),
        deliveryServices: uniq(map(flattenDeep(map(params?.orders, 'order_items')), 'delivery_service')),
      },
      onError: () => setApplyPostalCodeDisabled(false),
      onSuccess: (res) => {
        handleChange({target: {name: 'delivery_prefecture', value: get(res, 'response[0].state_code') || ''}});
        handleChange({target: {name: 'delivery_city', value: get(res, 'response[0].city') || ''}});
        handleChange({target: {name: 'delivery_street', value: get(res, 'response[0].street_address') || ''}});
        handleChange({target: {name: 'delivery_building', value: get(res, 'response[0].street_number') || ''}});
        if (get(res, 'response[0].postal_code')) {
          handleChange({target: {name: 'delivery_postal_code', value: get(res, 'response[0].postal_code')}});
        }
        setApplyPostalCodeDisabled(false);
        setPostalCodeApplied(true);
      },
    });
  };

  const handleSave = () => {
    calculateOrder({params});
    setFormParams(params);
    onCloseModal();
  };

  useEffect(() => {
    setParams({...formParams});
  }, [formParams.orders?.length]);

  return (
    <div
      className={classNames('c-shipping-information-form', {
        'c-shipping-information-form--is-modal': isModal,
        [className]: className,
      })}
    >
      {isModal && (
        <ModalHeading className="c-shipping-information-form__modal-heading">Shipping information</ModalHeading>
      )}
      <HalfFieldsContainer className="c-shipping-information-form__user-info-group" isLastChieldFull>
        <InputField
          label="First name *"
          placeholder="Sam"
          name="order_items.delivery_first_name"
          value={get(params, 'orders[0].order_items[0].delivery_first_name')}
          onChange={handleChange}
          isReadOnly={isReadOnly}
        />
        <InputField
          label="Last name *"
          placeholder="Smith"
          name="order_items.delivery_last_name"
          value={get(params, 'orders[0].order_items[0].delivery_last_name')}
          onChange={handleChange}
          isReadOnly={isReadOnly}
        />
      </HalfFieldsContainer>
      <InputField
        className="c-shipping-information-form__company-field"
        label="Company"
        placeholder="Optional"
        name="order_items.delivery_company"
        value={get(params, 'orders[0].order_items[0].delivery_company')}
        onChange={handleChange}
        isReadOnly={isReadOnly}
      />
      <SelectField
        className="c-shipping-information-form__country-field"
        label="Country *"
        placeholder="Country..."
        onChange={(option) => handleCountryChange(option.value)}
        name="order_items.delivery_country"
        value={get(params, 'orders[0].order_items[0].delivery_country')}
        options={countries}
        readOnly={isReadOnly}
        isSearchable={!isReadOnly}
      />
      <div className={classNames('c-shipping-information-form__postal-code postal-code-block', {
        'postal-code-block--is-modal': isModal,
      })}
      >
        <InputField
          className="postal-code-block__postal-code-field"
          label="Postal code *"
          type="zip"
          placeholder="Postal code"
          name="order_items.delivery_postal_code"
          value={get(params, 'orders[0].order_items[0].delivery_postal_code')}
          onChange={handleChange}
          isReadOnly={isReadOnly}
        />
        {get(params, 'orders[0].order_items[0].delivery_country') && (
          <button
            className="postal-code-block__button edit-button"
            onClick={applyPostalCode}
            disabled={applyPostalCodeDisabled}
            type="button"
          >
            APPLY
          </button>
        )}
      </div>
      {!['JP', 'US', 'GB'].includes(country) && postalCodeAppllied && (
        <HalfFieldsContainer className="c-shipping-information-form__adress-group">
          <InputField
            label="Building"
            placeholder="Building (Optional)"
            name="order_items.delivery_building"
            value={get(params, 'orders[0].order_items[0].delivery_building')}
            onChange={handleChange}
            isReadOnly={isReadOnly}
          />
          <InputField
            label="Street adress *"
            placeholder="Street address"
            name="order_items.delivery_street"
            value={get(params, 'orders[0].order_items[0].delivery_street')}
            onChange={handleChange}
            isReadOnly={isReadOnly}
          />
          <InputField
            label="City *"
            type="city"
            placeholder="City"
            name="order_items.delivery_city"
            value={get(params, 'orders[0].order_items[0].delivery_city')}
            onChange={handleChange}
            isReadOnly={isReadOnly}
          />
          <InputField
            label="Prefecture / State"
            type="state"
            placeholder="Prefecture / State"
            name="order_items.delivery_prefecture"
            value={get(params, 'orders[0].order_items[0].delivery_prefecture')}
            onChange={handleChange}
            isReadOnly={isReadOnly}
          />
        </HalfFieldsContainer>
      )}
      {country === 'JP' && postalCodeAppllied && (
        <HalfFieldsContainer className="c-shipping-information-form__adress-group">
          <SelectField
            label="Prefecture *"
            placeholder="Prefecture"
            onChange={(option) => handleChange({target: {name: 'delivery_prefecture', value: option.value}})}
            name="order_items.delivery_prefecture"
            value={get(params, 'orders[0].order_items[0].delivery_prefecture')}
            options={jpStatesOptions}
            readOnly={isReadOnly}
            isSearchable={!isReadOnly}
          />
          <InputField
            label="City *"
            type="city"
            placeholder="City"
            name="order_items.delivery_city"
            value={get(params, 'orders[0].order_items[0].delivery_city')}
            onChange={handleChange}
            isReadOnly={isReadOnly}
          />
          <InputField
            label="Street adress *"
            placeholder="Street address"
            name="order_items.delivery_street"
            value={get(params, 'orders[0].order_items[0].delivery_street')}
            onChange={handleChange}
            isReadOnly={isReadOnly}
          />
          <InputField
            label="Building"
            placeholder="Building (Optional)"
            name="order_items.delivery_building"
            value={get(params, 'orders[0].order_items[0].delivery_building')}
            onChange={handleChange}
            isReadOnly={isReadOnly}
          />
        </HalfFieldsContainer>
      )}
      {country === 'GB' && postalCodeAppllied && (
        <HalfFieldsContainer className="c-shipping-information-form__adress-group">
          <InputField
            label="Building"
            placeholder="Building (Optional)"
            name="order_items.delivery_building"
            value={get(params, 'orders[0].order_items[0].delivery_building')}
            onChange={handleChange}
            isReadOnly={isReadOnly}
          />
          <InputField
            label="Street adress *"
            placeholder="Street address"
            name="order_items.delivery_street"
            value={get(params, 'orders[0].order_items[0].delivery_street')}
            onChange={handleChange}
            isReadOnly={isReadOnly}
          />
          <InputField
            label="Town *"
            type="city"
            placeholder="Town"
            name="order_items.delivery_city"
            value={get(params, 'orders[0].order_items[0].delivery_city')}
            onChange={handleChange}
            isReadOnly={isReadOnly}
          />
          <InputField
            label="County"
            placeholder="County"
            name="order_items.delivery_prefecture"
            value={get(params, 'orders[0].order_items[0].delivery_prefecture')}
            onChange={handleChange}
            isReadOnly={isReadOnly}
          />
        </HalfFieldsContainer>
      )}
      {country === 'US' && postalCodeAppllied && (
        <HalfFieldsContainer className="c-shipping-information-form__adress-group">
          <InputField
            label="Building"
            placeholder="Building (Optional)"
            name="order_items.delivery_building"
            value={get(params, 'orders[0].order_items[0].delivery_building')}
            onChange={handleChange}
            isReadOnly={isReadOnly}
          />
          <InputField
            label="Street adress *"
            placeholder="Street address"
            name="order_items.delivery_street"
            value={get(params, 'orders[0].order_items[0].delivery_street')}
            onChange={handleChange}
            isReadOnly={isReadOnly}
          />
          <InputField
            label="City *"
            type="city"
            placeholder="City"
            name="order_items.delivery_city"
            value={get(params, 'orders[0].order_items[0].delivery_city')}
            onChange={handleChange}
            isReadOnly={isReadOnly}
          />
          <SelectField
            label="State *"
            placeholder="State"
            onChange={(option) => handleChange({target: {name: 'delivery_prefecture', value: option.value}})}
            name="order_items.delivery_prefecture"
            value={get(params, 'orders[0].order_items[0].delivery_prefecture')}
            options={usStatesOptions}
            readOnly={isReadOnly}
            isSearchable={!isReadOnly}
          />
        </HalfFieldsContainer>
      )}
      <InputField
        className="c-shipping-information-form__phone-number"
        label="Phone number *"
        placeholder="+1 - 0123456789"
        name="order_items.delivery_phone_number"
        value={get(params, 'orders[0].order_items[0].delivery_phone_number')}
        onChange={handleChange}
        isReadOnly={isReadOnly}
      />
      {(isModal || (!isUserInfoPresent && currentUser)) && (
        <CheckboxField
          className="c-shipping-information-form__checkbox-field"
          name="save_user_info"
          theme="small-bold-text"
          onChange={handleCopyCheckbox}
          checked={params.pre_populate_user_delivery_info}
          disabled={isReadOnly}
          label="Update your default shipping information"
        />
      )}
      {isModal && (
        <>
          <div className="c-shipping-information-form__buttons">
            <ReactButton
              className="c-shipping-information-form__cancel-button"
              type="button"
              onClick={onCloseModal}
              theme="primary-white-border"
            >
              CANCEL
            </ReactButton>
            <ReactButton
              className="c-shipping-information-form__save-button"
              type="button"
              onClick={handleSave}
              theme="primary-black"
            >
              SAVE
            </ReactButton>
          </div>
        </>
      )}
    </div>
  );
};
