import React from 'react';
import classNames from 'classnames';

export const ReactLink = ({
  text,
  path,
  theme,
  className,
}) => {
  return (
    <a
      className={classNames('c-link', {
        [className]: className,

        // Themes
        'c-link--small': theme === 'small',
      })}
      href={path}
    >
      {text}
    </a>

  );
};
