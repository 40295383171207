import React, {useEffect, useState} from 'react';

import {lockScroll} from 'utils/lock_scroll';

import {getCurrentUser} from 'dashboard/modules/api';
import {AccountMenu} from 'shared/components/account_menu';
import {SearchModal} from 'search/search_modal';
import {CartIcon} from 'shared/components/icons/cart_icon/cart_icon';
import QratesLogoImg from 'images/common/logos/qrates-logo.svg';
import {Preloader} from '../../preloader/preloader';
import {HeaderNavigation} from './components/header_navigation/header_navigation';
import {publicPagesRoutes} from '../../../../modules/routes';

export const PublicHeader = ({
  search,
  cart,
  startProjectPath,
  logInPath,
}) => {
  const navLinks = [
    {
      id: 1,
      name: 'DISCOVER MUSIC',
      subMenu: [
        {
          name: 'Records',
          path: publicPagesRoutes.projectsPagePath({product_format: 'vinyl'}),
        },
        {
          name: 'Cassettes',
          path: publicPagesRoutes.projectsPagePath({product_format: 'cassette'}),
        },
        {
          name: 'Stories',
          path: publicPagesRoutes.storiesPagePath,
        },
        {
          name: 'Qrates Curated',
          path: publicPagesRoutes.qratesCuratedPagePath,
        },
      ],
    },
    {
      id: 2,
      name: 'For Artists',
      path: publicPagesRoutes.forArtistsPagePath,
    },
    {
      id: 3,
      name: 'For Fans',
      path: publicPagesRoutes.forFansPagePath,
    },
  ];
  const [currentUser, setCurrentUser] = useState(null);
  const [isUserLoading, setIsUserLoading] = useState(false);
  const [isMobileMenuActive, setIsMobileMenuActive] = useState(false);

  const getUser = async () => {
    setIsUserLoading(true);
    const response = await getCurrentUser();
    response?.response && setCurrentUser(response.response);
    setIsUserLoading(false);
  };

  const onMobileMenuOpen = () => {
    setIsMobileMenuActive(!isMobileMenuActive);
  };

  useEffect(() => {
    const onChangeMenuVisibility = () => {
      if (isMobileMenuActive && document.documentElement.clientWidth < 990) {
        lockScroll.enable();
      } else { lockScroll.disable(); }
    };

    onChangeMenuVisibility();
    window.addEventListener('resize', onChangeMenuVisibility);
    return () => {
      window.removeEventListener('resize', onChangeMenuVisibility);
    };
  }, [isMobileMenuActive]);

  useEffect(() => {
    getUser();
  }, []);
  return (
    <header className="c-public-header">
      <a className="c-public-header__logo" href="/">
        <img src={QratesLogoImg} alt="Qrates" />
      </a>
      <HeaderNavigation
        navLinks={navLinks}
        isMobileMenuActive={isMobileMenuActive}
        setIsMobileMenuActive={setIsMobileMenuActive}
        startProjectPath={startProjectPath}
        logInPath={!isUserLoading && !currentUser && logInPath}
      />
      <SearchModal
        className="c-public-header__search"
        searchIcon
        algolia_id={search.algolia_id}
        algolia_search_api_key={search.algolia_search_api_key}
      />
      <CartIcon className="c-public-header__cart" path={cart.path} itemsInCart={cart.itemsInCart} />
      <div className="c-public-header__right-bar">
        {!isUserLoading && !currentUser && logInPath && (
          <a className="c-public-header__login-button u-hidden-tablet" href={logInPath}>Log in</a>
        )}
        {startProjectPath && (
          <a className="c-public-header__start-button u-hidden-tablet c-button c-button--black c-button--disabled" href={startProjectPath} title="Currently we're suspending new project order from Qrates Studio">Make your own</a>
        )}
        {currentUser && <AccountMenu className="c-public-header__account-avatar" user={currentUser} />}
        {!currentUser && isUserLoading && <Preloader className="c-public-header__preloader" />}
        <div className="c-public-header__burger u-hidden-desktop-only" onClick={onMobileMenuOpen}>
          <i className="c-pictogram c-pictogram--hamburger" />
        </div>
      </div>
    </header>
  );
};
