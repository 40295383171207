import ApiHelper from 'utils/api_helper';
import Routes from 'project_start/modules/routes';

function artworkFilesAttributes(files, based = false) {
  return files
    .filter((file) => file.file_type === 'mockup')
    .map((file) => {
      const {
        id,
        file_type,
        uploaded_file,
        _destroy,
      } = file;

      return {
        id: based ? null : id,
        file_type,
        uploaded_file_id: uploaded_file?.stored_file_id || uploaded_file?.id,
        stored_file_type_id: uploaded_file?.stored_file_type_id,
        _destroy,
      };
    });
}

export function fetchProject(projectId, params = {}) {
  const path = Routes.api.fetchProjectPath(projectId, params);

  return ApiHelper.get(path);
}

export function saveDraft(project, options = {}) {
  const path = Routes.api.saveDraftPath;
  const {
    id,
    base_project_id,
    project_type,
    product_order: {
      title,
      artwork_files,
      delivery_service,
      deliveries,
      repress,
      coupon_id,
      campaign_applied,
      type,
    },
    spec,
    selling_condition: {
      currency,
      price,
    },
    item: {
      artist_name,
      code,
      item_page_design: {
        main_visual_type,
        background_color,
        video_url,
      },
    },
  } = project.toJSON();
  const {redis_key} = options;

  const {quantity, base_quantity} = spec;
  const deliveries_attributes = project_type == 'press_only' ? deliveries : [];

  const params = {
    redis_key,
    id,
    base_project_id,
    project_type,
    product_order_attributes: {
      base_quantity,
      quantity,
      currency,
      title,
      repress,
      coupon_id,
      delivery_service,
      artwork_files_attributes: artworkFilesAttributes(artwork_files, base_project_id),
      deliveries_attributes,
      campaign_applied,
      spec,
      type,
    },
    item_attributes: {
      title,
      artist_name,
      code,
      item_page_design_attributes: {
        main_visual_type,
        background_color,
        video_url,
      },
    },
    selling_condition_attributes: {
      price,
      currency,
      state: 'draft',
      base_quantity: quantity,
    },
  };

  return ApiHelper.post(path, params);
}

export function saveSpec(project, options = {}) {
  const path = Routes.api.saveSpecPath;
  const {
    id,
    project_type,
    product_order: {
      title,
      artwork_files,
      coupon_id,
      campaign_applied,
      type,
      deliveries,
      spec_variations,
      cost: {
        persisted_items,
      },
    },
    spec,
    item: {
      artist_name,
      code,
      item_page_design: {
        main_visual_type,
        background_color,
      },
    },
  } = project.toJSON();
  const {quantity, base_quantity} = spec;
  const currency = project_type == 'press_only' ? project.get('currency') : project.getIn(['selling_condition', 'currency']);
  const {admin} = options;

  const params = {
    id,
    admin,
    product_order_attributes: {
      base_quantity,
      quantity,
      currency,
      title,
      coupon_id,
      campaign_applied,
      artwork_files_attributes: artworkFilesAttributes(artwork_files),
      order_cost_attributes: {persisted_items},
      spec,
      spec_variations,
      type,
      deliveries_attributes: deliveries,
    },
    item_attributes: {
      title,
      artist_name,
      code,
      item_page_design_attributes: {
        main_visual_type,
        background_color,
      },
    },
  };

  return ApiHelper.post(path, params);
}

export function startProject(project, options = {}) {
  const path = Routes.api.startProjectPath;
  const {
    id,
    base_project_id,
    project_type,
    product_order: {
      title,
      artwork_files,
      delivery_service,
      deliveries,
      repress,
      coupon_id,
      campaign_applied,
      type,
    },
    spec,
    item: {
      artist_name,
      code,
      item_page_design: {
        main_visual_type,
        background_color,
        video_url,
      },
    },
    selling_condition,
  } = project.toJSON();
  const {redis_key} = options;

  const {quantity, base_quantity} = spec;
  const deliveries_attributes = project_type == 'press_only' ? deliveries : [];
  const sellingConditionAttributes = project_type == 'press_only' ? {}
    : {selling_condition_attributes: {...selling_condition.saveAttributes(), base_quantity: quantity}};
  if (project_type == 'sell') {
    sellingConditionAttributes.selling_condition_attributes.skip_period_calculation = true;
    sellingConditionAttributes.selling_condition_attributes.skip_preorder_period_calculation = true;
    sellingConditionAttributes.selling_condition_attributes.preorder_start_date = null;
    sellingConditionAttributes.selling_condition_attributes.preorder_finish_date = null;
  }

  const params = {
    redis_key,
    id,
    base_project_id,
    project_type,
    stock_quantity: 0,
    product_order_attributes: {
      base_quantity,
      quantity,
      currency: selling_condition.currency,
      title,
      delivery_service,
      repress,
      coupon_id,
      campaign_applied,
      artwork_files_attributes: artworkFilesAttributes(artwork_files, base_project_id),
      spec,
      type,
      deliveries_attributes,
    },
    item_attributes: {
      title,
      artist_name,
      code,
      item_page_design_attributes: {
        main_visual_type,
        background_color,
        video_url,
      },
    },
    ...sellingConditionAttributes,
  };

  return ApiHelper.post(path, params);
}

export function saveProjectInRedis(project) {
  const path = Routes.api.saveProjectInRedisPath;
  const {
    id,
    base_project_id,
    project_type,
    product_order: {
      title,
      artwork_files,
      delivery_service,
      deliveries,
      repress,
      coupon_id,
      campaign_applied,
      type,
    },
    spec,
    item: {
      artist_name,
      code,
      item_page_design: {
        main_visual_type,
        background_color,
        video_url,
      },
    },
    selling_condition,
  } = project.toJSON();

  const {quantity, base_quantity} = spec;
  let deliveries_attributes = [];
  deliveries_attributes = project_type == 'press_only' ? deliveries : [];
  const sellingConditionAttributes = project_type == 'press_only' ? {}
    : {selling_condition_attributes: {...selling_condition.saveAttributes(), base_quantity: quantity}};
  if (project_type == 'sell') {
    sellingConditionAttributes.selling_condition_attributes.skip_period_calculation = true;
    sellingConditionAttributes.selling_condition_attributes.skip_preorder_period_calculation = true;
    sellingConditionAttributes.selling_condition_attributes.preorder_start_date = null;
    sellingConditionAttributes.selling_condition_attributes.preorder_finish_date = null;
  }

  const params = {
    id,
    base_project_id,
    project_type,
    stock_quantity: 0,
    product_order_attributes: {
      base_quantity,
      quantity,
      currency: selling_condition.currency,
      title,
      delivery_service,
      repress,
      coupon_id,
      campaign_applied,
      artwork_files_attributes: artworkFilesAttributes(artwork_files, base_project_id),
      spec,
      type,
      deliveries_attributes,
    },
    item_attributes: {
      title,
      artist_name,
      code,
      item_page_design_attributes: {
        main_visual_type,
        background_color,
        video_url,
      },
    },
    ...sellingConditionAttributes,
  };

  return ApiHelper.post(path, params);
}

export function calculateCost(project, coupon_code = null, validate = false, recalculate_quantity = false) {
  const path = Routes.api.calculateCostPath;
  const {
    id,
    selling_condition: {
      price,
      initial_price,
    },
    spec,
    project_type,
    product_order: {
      coupon_id,
      campaign_applied,
      delivery_service,
      billing_country,
      deliveries,
      repress,
      spec_variations,
      type,
    },
    selling_condition,
  } = project.toJSON();
  const currency = project.get('currency') || project.getIn(['selling_condition', 'currency']);
  const product_order_id = project.getIn(['product_order', 'id']);
  const persisted_items = project.getIn(['product_order', 'cost', 'persisted_items']) || [];
  const revision = project.getIn(['product_order', 'cost', 'items_revision']) || project.getIn(['product_order', 'cost', 'latest_revision']);

  const {base_quantity, quantity} = spec;

  const deliveries_attributes = project_type == 'press_only' ? deliveries : [];
  let sellingConditionAttributes = {};
  if (project_type != 'press_only') {
    sellingConditionAttributes = {
      selling_condition_attributes: {
        ...selling_condition.saveAttributes(),
        base_quantity: quantity,
        price,
        initial_price,
      },
    };
  }

  const params = {
    project_id: id,
    validate,
    recalculate_quantity,
    product_order_attributes: {
      id: product_order_id,
      selling_type: project_type,
      currency,
      base_quantity,
      quantity,
      spec: {...spec.toJSON(), currency, revision},
      spec_variations,
      delivery_service,
      billing_country,
      repress,
      coupon_id,
      coupon_code,
      campaign_applied,
      deliveries_attributes,
      order_cost_attributes: {persisted_items},
      type,
    },
    ...sellingConditionAttributes,
  };

  return ApiHelper.post(path, params);
}

export function getCurrentUser({params}) {
  const path = Routes.api.getCurrentUserPath(params);

  return ApiHelper.get(path);
}

export const getZeroPrices = () => {
  const path = Routes.api.getZeroPricesPath;

  return ApiHelper.get(path);
};

export function uploadTexture({file, progress}) {
  return upload('/upload/texture', file, progress);
}

function upload(endPoint, file, progress) {
  const formData = new FormData();
  formData.append('file', file);

  return ApiHelper.uploadFiles(endPoint, formData, progress);
}
