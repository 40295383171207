import {handleActions} from 'redux-actions';
import User from 'models/user';
import Actions from 'project_start/modules/actions';

export default handleActions({
  [Actions.setUser]: (state, action) => {
    const {payload: {userJSON}} = action;

    if (userJSON) {
      return User.fromJSON(userJSON);
    }
    return null;
  },
}, null);
