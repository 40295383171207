import React from 'react';
import {RichText} from 'prismic-reactjs';
import {NewLabel} from 'shared/components/labels/new_label/new_label';

export const CuratedFeaturedMedium = ({
  backgroundColor,
  fontColor,
  image,
  article,
  text,
  labelTheme,
  linkLabel,
}) => {
  const genres = article && article.project.genres.join(' | ');
  return (
    <div className="c-curated-featured" style={{backgroundColor}}>
      <div className="c-curated-featured__container c-curated-featured__container--medium">
        <div className="c-curated-featured__image-container">
          <img className="c-curated-featured__image c-curated-featured__image--medium" src={image} />
        </div>
        <div className="c-curated-featured__intro c-curated-featured__intro--medium">
          <div className="c-curated-featured__label-container c-curated-featured__label-container--medium">
            <NewLabel title="Release of the week" labelTheme={labelTheme} />
          </div>
          <div className="c-curated-featured__info-container--medium">
            <h2 wovn-ignore="" className="c-curated-featured__title" style={{color: fontColor}}>{article.project.title}</h2>
            <h3 wovn-ignore="" className="c-curated-featured__subtitle" style={{color: fontColor}}>{article.project.artist_name}</h3>
            <h4 wovn-ignore="" className="c-curated-featured__details" style={{color: fontColor}}>{`[${genres}]`}</h4>
          </div>
          <p wovn-ignore="" className="c-curated-featured__text" style={{color: fontColor}}>
            {RichText.asText(text)}
          </p>
          <div className="c-curated-featured__link-container" style={{color: fontColor}}>
            <a wovn-ignore="" className="c-curated-featured__bottom-link" style={{color: fontColor}} href={`/projects/${article.project.id}`}>
              {linkLabel}
              <i className="c-pictogram c-pictogram--arrow-forward" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
