import React from 'react';
import classNames from 'classnames';

export const CatalogGrid = ({
  className,
  children,
  type,
}) => {
  return (
    <div className={classNames('c-catalog-grid', {
      [className]: className,
      'c-catalog-grid--lists': type === 'lists',
      'c-catalog-grid--artists': type === 'artists',
      'c-catalog-grid--stories': type === 'stories',
      'c-catalog-grid--projects': type === 'projects',
    })}
    >
      <div className="c-catalog-grid__container">
        {children.map((item, index) => {
          return (
            <div key={index} className="c-catalog-grid__item">{item}</div>
          );
        })}
      </div>
    </div>
  );
};
