import {Record} from 'immutable';

const _Record = Record({
  id: null,
  title: '',
  artist_name: '',
  duration: 0,
  audio_file_id: null,
  sample_audio_file_id: null,
  sample_audio_file_task_id: null,
  sample_audio_url: null,
  sample_audio_state: null,
  sample_audio_created_at: null,
  sound_cloud_track_id: null,
  sample_waveform_image_url: null,
  track_audio_file_meta: {},
  audio_file_task_id: null,
  audio_url: null,
  audio_state: null,
  audio_created_at: null,
}, 'TrackRecord');

export default class Track extends _Record {
  static fromJSON(json) {
    if (json) {
      if (json.duration) {
        json.minutes = Math.floor(json.duration / 60);
        json.seconds = Math.floor(json.duration % 60);
      }
    }
    return new Track(json);
  }

  changeSoundCloudData(source) {
    return this.merge({
      audio_file_id: 0,
      sample_audio_file_id: 0,
      sound_cloud_track_id: source.id,
      sample_waveform_image_url: source.waveform_url,
      title: source.title,
      artist_name: source.user.username,
      sample_audio_url: null,
      duration: Math.floor(source.duration / 1000),
    });
  }
}
