import React, {useState} from 'react';

import Prismic from '@prismicio/client';
import {RichText} from 'prismic-reactjs';

import {prismicClient} from 'prismic/shared/cms/prismic';
import {useLanguageChange} from 'utils/use_language_change';

export const PrivacyPage = ({data}) => {
  const [page, setPage] = useState(data);

  useLanguageChange((event) => {
    const locale = window.constants?.prismic_locales?.[event.detail.locale];
    prismicClient.query(Prismic.Predicates.at('document.type', 'privacy_page'), {lang: locale}).then((response) => {
      const pageData = response.results[0]?.data;
      pageData && setPage(pageData);
    });
  });

  return (
    <div className="p-terms">
      <article className="p-terms__container">
        <div className="p-terms__main">
          <div className="p-terms__contents"><h1>{RichText.asText(page.title)}</h1></div>
          <div className="p-terms__contents">{RichText.render(page.description)}</div>
          {page.sections?.map((section, index) => (
            <div className="p-terms__contents" key={index}>
              <h2>{RichText.asText(section.heading)}</h2>
              {RichText.render(section.body)}
            </div>
          ))}
        </div>
      </article>
      <div className="c-divider--blank" />
    </div>
  );
};
