import React from 'react';

import {connectInfiniteHits} from 'react-instantsearch-dom';

const Hits = ({hits}) => (
  <div className="c-artists__list">
    {hits.map((hit, index) => (
      <a className="c-artists__container" href={hit.url} target="_blank" key={index}>
        <div className="c-artists__image" style={{backgroundImage: `url(${hit.profile_image_url})`}} />
        <p className="c-artists__title">{hit.name}</p>
        <p className="c-artists__name">{hit.genre}</p>
      </a>
    ))}
  </div>
);

export default connectInfiniteHits(Hits);
