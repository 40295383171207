/* eslint-disable react/jsx-props-no-spreading */

import React, {useState} from 'react';
import classNames from 'classnames';
import Tippy from '@tippyjs/react/headless';

export const Tooltip = ({
  className,
  children,
  text,
  theme,
  mainTitle,
  wrappedComponent,
  placement,
  isVisible,
  trigger = 'click',
  maxWidth = '418px',
  maxHeight,
  closeButton,
  onHide,
  onShow,
  hideOnWheel = true,
}) => {
  const [arrow, setArrow] = useState(null);

  let verticalOffset;
  let horizontalOffset;

  if (theme === 'thin-frames') {
    verticalOffset = 15;
    horizontalOffset = 15;
  } else {
    verticalOffset = 20;
    horizontalOffset = 25;
  }

  const WrappedComponent = wrappedComponent;

  const customArrow = {
    name: 'arrow',
    options: {
      element: arrow,
    },
  };

  const customOffset = {
    name: 'offset',
    options: {
      offset: [verticalOffset, horizontalOffset],
    },
  };

  const customFlip = {
    name: 'flip',
    options: {
      fallbackPlacements: ['bottom', 'top', 'right', 'left'],
    },
  };

  const popperOptions = {
    modifiers: [customArrow, customOffset, customFlip],
  };

  const handleShow = (instance) => {
    const closeButton = instance.popper?.querySelector('.close-button');

    closeButton && closeButton.addEventListener('click', () => instance.hide(), true);
    hideOnWheel && window.addEventListener('wheel', (event) => instance.hide());

    onShow && onShow(instance);
  };

  const handleHide = (instance) => {
    const closeButton = instance.popper?.querySelector('.close-button');

    closeButton && closeButton.removeEventListener('click', () => instance.hide());
    hideOnWheel && window.removeEventListener('wheel', (event) => instance.hide());
    onHide && onHide(instance);
  };

  return (
    <div className={classNames('c-react-tooltip', {
      [className]: className,
      'c-react-tooltip--thin-frames': theme === 'thin-frames',
    })}
    >
      <Tippy
        render={(attrs) => (
          <div
            className={classNames('c-react-tooltip__container', {
              'c-react-tooltip__container--thin-frames': theme === 'thin-frames',
            })}
            {...attrs}
            style={{maxWidth, maxHeight}}
          >
            {closeButton && <span className="c-react-tooltip__close-button close-button">&times;</span>}
            {text && <p className="c-react-tooltip__text">{text}</p>}
            {children}
            <div className="c-react-tooltip__arrow" ref={setArrow} />
          </div>
        )}
        arrow
        appendTo={() => document.body}
        trigger={trigger}
        interactive
        duration={100}
        delay={[75, 0]}
        maxWidth={maxWidth}
        visible={isVisible}
        placement={placement}
        popperOptions={popperOptions}
        onShow={handleShow}
        onHide={handleHide}
      >
        <div className="c-react-tooltip__main-content">
          {mainTitle && !wrappedComponent && <span>{mainTitle}</span>}
          {!wrappedComponent && <i className="c-react-tooltip__icon c-pictogram c-pictogram--question" />}
          {wrappedComponent && <WrappedComponent />}
        </div>
      </Tippy>
    </div>
  );
};
