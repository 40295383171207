import React, {useEffect, useRef} from 'react';
import classNames from 'classnames';

export const GenresList = ({
  className,
  items,
  cropToContainerSize,
}) => {
  const genresContainer = useRef();

  const handleWidth = () => {
    const container = genresContainer?.current;

    if (cropToContainerSize && container) {
      const containerWidth = container.offsetWidth;
      const elements = container.childNodes;
      let elementsWidth = 0;

      containerWidth > 0 && elements.length > 0 && elements.forEach((element) => {
        elementsWidth += element.offsetWidth;

        if (containerWidth < elementsWidth) {
          element.classList.add('is-hide');
          return;
        }
        element.classList.remove('is-hide');
      });
    }
  };

  useEffect(() => {
    handleWidth();
  }, [cropToContainerSize && genresContainer?.current, items]);

  useEffect(() => {
    window.addEventListener('resize', handleWidth);
    return () => {
      window.removeEventListener('resize', handleWidth);
    };
  }, []);

  return (
    <div className={classNames({[className]: className})} wovn-ignore="">
      <div className="c-genres-list" ref={genresContainer}>
        {items.map((genre, index) => {
          return (
            <div className="c-genres-list__item" key={`${index}_${genre}`}><span>{genre}</span></div>
          );
        })}
      </div>
    </div>
  );
};
