import queryString from 'query-string';

export default Object({
  getCurrentUserPath: () => { return '/api/v1/me'; },
  fetchProjectPath: (projectId, params = {}) => { return `/api/v1/dashboard/projects/${projectId}?${queryString.stringify(params)}`; },
  fetchProjectsPath: (params) => { return `/api/v1/dashboard/projects?${queryString.stringify(params)}`; },
  publishProjectPath: (projectId) => { return `/api/v1/dashboard/projects/${projectId}/publish`; },
  startProductionPath: (projectId) => { return `/api/v1/dashboard/projects/${projectId}/start_production`; },
  fixProductionQuantityPath: (projectId) => { return `/api/v1/dashboard/projects/${projectId}/fix_production_quantity`; },
  submitTestpressApprovalPath: (projectId) => { return `/api/v1/dashboard/projects/${projectId}/testpress`; },
  submitArtworkConfirmationApprovalPath: (projectId) => { return `/api/v1/dashboard/projects/${projectId}/artwork`; },
  saveVinylSettingPath: (projectId) => { return `/api/v1/dashboard/projects/${projectId}/settings/main`; },
  saveCrowdfundingTabPath: (projectId) => { return `/api/v1/dashboard/projects/${projectId}/settings/crowdfunding`; },
  savePressSellTabPath: (projectId) => { return `/api/v1/dashboard/projects/${projectId}/settings/sales`; },
  saveQuantitySettingPath: (projectId) => { return `/api/v1/dashboard/projects/${projectId}/settings/quantity`; },
  startSellingPath: (projectId) => { return `/api/v1/dashboard/projects/${projectId}/settings/start_selling`; },
  savePressOnlyTabPath: (projectId) => { return `/api/v1/dashboard/projects/${projectId}/settings/quantity`; },
  saveDistributionTabPath: (projectId) => { return `/api/v1/dashboard/projects/${projectId}/settings/distribution`; },
  savePageContentSettingPath: (projectId) => { return `/api/v1/dashboard/projects/${projectId}/settings/page/content`; },
  saveArtistProfileSettingPath: (projectId) => { return `/api/v1/dashboard/projects/${projectId}/settings/page/artist`; },
  saveTracklistSettingPath: (projectId) => { return `/api/v1/dashboard/projects/${projectId}/settings/tracklist`; },
  saveSampleAudiosSettingPath: (projectId) => { return `/api/v1/dashboard/projects/${projectId}/settings/audios/samples`; },
  saveDigitalAudiosSettingPath: (projectId) => { return `/api/v1/dashboard/projects/${projectId}/settings/audios/digital`; },
  createAudioTranscodeTaskPath: '/api/v1/dashboard/transcode_audio_file_tasks',
  updateAudioTranscodeTaskPath: (taskId) => { return `/api/v1/dashboard/transcode_audio_file_tasks/${taskId}`; },
  saveArtworkMockupsTabPath: (projectId) => { return `/api/v1/dashboard/projects/${projectId}/settings/artworks/mockups`; },
  saveArtworkPrintingTabPath: (projectId) => { return `/api/v1/dashboard/projects/${projectId}/settings/artworks/printing`; },
  fetchProjectSalesWalletPath: (projectId) => { return `/api/v1/dashboard/projects/${projectId}/sales/wallet`; },
  requestRevenuePath: (projectId) => { return `/api/v1/dashboard/projects/${projectId}/sales/request_revenue`; },
  fetchProjectSalesSummaryPath: (projectId) => { return `/api/v1/dashboard/projects/${projectId}/sales`; },
  saveCopyrightSettingPath: (projectId) => { return `/api/v1/dashboard/projects/${projectId}/settings/copyright`; },
  createProjectPaymentPath: (projectId) => { return `/api/v1/dashboard/projects/${projectId}/payments`; },
  saveVinylAudioSetting: (projectId) => { return `/api/v1/dashboard/projects/${projectId}/settings/audios/vinyl`; },
  saveVinylAudioSettingPath: (projectId) => { return `/api/v1/dashboard/projects/${projectId}/settings/audios/vinyl`; },
  fetchProjectSalesOrdersPath: (projectId, params = {}) => { return `/api/v1/dashboard/projects/${projectId}/sales/orders?${queryString.stringify(params)}`; },
  exportProjectSalesOrdersPath: (projectId, params = {}) => { return `/api/v1/dashboard/projects/${projectId}/sales/download_orders?${queryString.stringify(params, {arrayFormat: 'bracket'})}`; },
  fetchProjectSalesStocksPath: (projectId, params = {}) => { return `/api/v1/dashboard/projects/${projectId}/sales/stocks?${queryString.stringify(params)}`; },
  exportProjectSalesStocksPath: (projectId, params = {}) => { return `/api/v1/dashboard/projects/${projectId}/sales/download_stocks?${queryString.stringify(params, {arrayFormat: 'bracket'})}`; },
  fetchProjectQratesMasteringPricePath: (projectId) => { return `/api/v1/dashboard/projects/${projectId}/settings/audios/mastering`; },
  saveProjectQratesMasteringPath: (projectId) => { return `/api/v1/dashboard/projects/${projectId}/settings/audios/mastering`; },
  saveProjectTestProductionPath: (projectId) => { return `/api/v1/dashboard/projects/${projectId}/settings/audios/test_production`; },
  calculateOrderPath: (projectId) => { return `/api/v1/dashboard/projects/${projectId}/sales/self/calculate`; },
  selfPurchaseOrdersPath: (projectId) => { return `/api/v1/dashboard/projects/${projectId}/sales/self`; },
  fetchProjectSelfPurchaseOrdersPath: (projectId, params = {}) => { return `/api/v1/dashboard/projects/${projectId}/sales/self?${queryString.stringify(params)}`; },
  calculateStockTransferPath: (projectId) => { return `/api/v1/dashboard/projects/${projectId}/sales/calculate_stock_transfer`; },
  sendVinylPath: (projectId) => { return `/api/v1/dashboard/projects/${projectId}/sales/send_vinyl`; },
  projectPressMessagesPath: (projectId, params = {}) => { return `/api/v1/dashboard/projects/${projectId}/messages?${queryString.stringify(params)}`; },
  uploadPressFilesPath: (projectId) => { return `/api/v1/dashboard/projects/${projectId}/files`; },
  sendMessageToCustomersPath: (projectId) => { return `/api/v1/dashboard/projects/${projectId}/sales/message`; },
  readProjectMessagesPath: (projectId) => { return `/api/v1/dashboard/projects/${projectId}/messages/read_messages`; },
  calculatePath: (projectId) => { return `/api/v1/dashboard/projects/${projectId}/calculate`; },
  getFinancialStatementPath: (projectId, params = {}) => `/api/v1/dashboard/projects/${projectId}/payments/financial_statement?${queryString.stringify(params)}`,
  getDefaultPricesPath: () => '/api/v1/prices/default',
});
