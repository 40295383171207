import React from 'react';
import classNames from 'classnames';

export const ModalLabel = ({
  text, className, children, theme,
}) => {
  return (
    <p className={classNames('c-modal-label', {
      [className]: className,

      'c-modal-label--middle': theme === 'middle',
    })}
    >
      {text && text}
      {children && children}
    </p>
  );
};
