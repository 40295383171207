import React from 'react';
import {GroupSection} from '../../group_section/group_section';
import {ScreenContainer} from '../../screen_container/screen_container';

export const ColorsScreen = ({navigationList}) => {
  const colorsList = [
    {
      name: 'Old colors',
      items: [
        {name: '$text_color', color: '#333333'},
        {name: '$grobal_gray', color: '#f5f5f5'},
        {name: '$accent_color', color: '#f56822'},
        {name: '$btn_twitter', color: '#4A90E2'},
        {name: '$btn_facebook', color: '#006FBA'},
        {name: '$btn_login', color: '#FEB723'},
        {name: '$btn_action', color: '#ff6613'},
        {name: '$btn_enable', color: '#7ed321'},
        {name: '$btn_learn', color: '#32DC32'},
        {name: '$progress', color: '#34bffe'},
        {name: '$funded', color: '#feb723'},
        {name: '$disabled', color: '#eeeeee'},
        {name: '$standard', color: '#60ea60'},
        {name: '$funding', color: '#2ebdfe'},
        {name: '$default_text', color: '#aab5bd'},
        {name: '$primary', color: '#1fc782'},
        {name: '$caution', color: '#f35e32'},
        {name: '$danger', color: '#ff0000'},
        {name: '$draft', color: '#99a2a5'},
        {name: '$infinite', color: '#127FB1'},
      ],
    },
    {
      name: 'Green',
      items: [
        {name: '$color-light-green', color: '#DCFDD5'},
        {name: '$color-normal-green', color: '#7EE569'},
        {name: '$color-dark-green', color: '#497775'},
      ],
    },
    {
      name: 'Orange',
      items: [
        {name: '$color-light-orange', color: '#FFD5BD'},
        {name: '$color-normal-orange', color: '#FF6711'},
        {name: '$color-dark-orange', color: '#774949'},
      ],
    },
    {
      name: 'Violet',
      items: [
        {name: '$color-light-violet', color: '#F8DFFF'},
        {name: '$color-normal-violet', color: '#E280FF'},
        {name: '$color-dark-violet', color: '#5B3C5D'},
      ],
    },
    {
      name: 'Yellow',
      items: [
        {name: '$color-light-yellow', color: '#FFFCD9'},
        {name: '$color-normal-yellow', color: '#FFF804'},
        {name: '$color-dark-yellow', color: '#81836C'},
      ],
    },
    {
      name: 'Blue',
      items: [
        {name: '$color-light-blue', color: '#CEDAF9'},
        {name: '$color-normal-blue', color: '#5883F4'},
        {name: '$color-dark-blue', color: '#294796'},
      ],
    },
    {
      name: 'Red',
      items: [
        {name: '$color-light-red', color: '#F8D8D8'},
        {name: '$color-normal-red', color: '#DA1B22'},
        {name: '$color-dark-red', color: '#851014'},
      ],
    },
    {
      name: 'Error',
      items: [
        {name: '$color-error', color: '#ff2d55'},
      ],
    },
    {
      name: 'Readonly',
      items: [
        {name: '$color-readonly', color: '#000000'},
      ],
    },
    {
      name: 'Placeholder',
      items: [
        {name: '$color-placeholder', color: '#888888'},
      ],
    },
    {
      name: 'Neutrals',
      items: [
        {name: '$color-neutrals-black', color: '#000000'},
        {name: '$color-neutrals-gray-dark', color: '#565451'},
        {name: '$color-neutrals-gray-mid', color: '#A0A297'},
        {name: '$color-neutrals-gray-light', color: '#E4E5DE'},
        {name: '$color-neutrals-gray-border', color: '#F2F3EF'},
        {name: '$color-neutrals-gray-canvas', color: '#EEEEEC'},
        {name: '$color-neutrals-white', color: '#ffffff'},
      ],
    },
    {
      name: 'Overlays',
      items: [
        {name: '$color-overlays-bk60', color: 'rgba(0,0,0,0.6)'},
        {name: '$color-overlays-bk50', color: 'rgba(0,0,0,0.5)'},
        {name: '$color-overlays-bk40', color: 'rgba(0,0,0,0.4)'},
        {name: '$color-overlays-bk20', color: 'rgba(0,0,0,0.2)'},
        {name: '$color-overlays-bk10', color: 'rgba(0,0,0,0.1)'},
        {name: '$color-overlays-hover', color: 'rgba(0,0,0,0.05)'},
        {name: '$color-overlays-wh70', color: 'rgba(255,255,255,0.7)'},
        {name: '$color-overlays-wh60', color: 'rgba(255,255,255,0.6)'},
        {name: '$color-overlays-wh50', color: 'rgba(255,255,255,0.5)'},
        {name: '$color-overlays-wh40', color: 'rgba(255,255,255,0.4)'},
        {name: '$color-overlays-wh20', color: 'rgba(255,255,255,0.2)'},
        {name: '$color-overlays-wh10', color: 'rgba(255,255,255,0.1)'},
      ],
    },
  ];

  return (
    <ScreenContainer title="Color system">
      {colorsList && colorsList.map((group, index) => {
        return (
          <GroupSection title={group.name} type="colors" key={index}>
            {group.items.map((item, index) => {
              return (
                <div
                  className="color-item"
                  style={{backgroundColor: item.color}}
                  onClick={() => { navigator.clipboard.writeText(item.name); }}
                  key={index}
                >
                  <div className="color-item__title">{item.name}</div>
                  <div className="color-item__color-value">{item.color}</div>
                </div>
              );
            })}
          </GroupSection>
        );
      })}
    </ScreenContainer>

  );
};
