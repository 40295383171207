import React, {useState} from 'react';
import Select from 'react-select';
import classNames from 'classnames';

import {connectSortBy} from 'react-instantsearch-dom';

const SortBy = ({
  items,
  refine,
  currentRefinement,
  className,
}) => {
  const [currentOption, setCurrentOption] = useState(items[0]);
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const onChange = (option) => {
    refine(option.value);
    setCurrentOption(items.find((item) => item.value === option.value));
    setMenuIsOpen(!menuIsOpen);
  };

  const customStyles = {
    option: (styles, state) => ({
      ...styles,
      cursor: 'pointer',
    }),
    control: (styles) => ({
      ...styles,
      cursor: 'pointer',
    }),
  };

  return (
    <div className={classNames('c-react-select-custom-sort', {[className]: className})}>
      <span className="c-react-select-custom-sort__label" onClick={() => setMenuIsOpen(!menuIsOpen)}>Sort by</span>
      <Select
        className="c-react-select-custom-sort__select c-react-select c-react-select--no-border"
        classNamePrefix="c-react-select"
        name="order_items.delivery_country"
        placeholder="Country..."
        value={currentOption}
        options={items}
        onChange={onChange}
        isSearchable={false}
        menuIsOpen={menuIsOpen}
        onMenuOpen={() => setMenuIsOpen(true)}
        onMenuClose={() => setMenuIsOpen(false)}
        styles={customStyles}
      />
    </div>
  );
};

export default connectSortBy(SortBy);
