import React from 'react';
import {RichText} from 'prismic-reactjs';
import {NewLabel} from 'shared/components/labels/new_label/new_label';

export const ArtistToolkitCard = ({
  image,
  title,
  category,
  text,
  index,
  uid,
}) => {
  return (
    <div
      key={`${title + index}`}
      index={index}
      className="c-artist-toolkit-card"
    >
      <a href={`/articles/${uid}`}>
        <div className="c-artist-toolkit-card__top">
          <div className="c-artist-toolkit-card__label-container">
            <NewLabel title={category} theme="transparent" />
          </div>
          <div className="c-artist-toolkit-card__image-container">
            <span wovn-ignore="">
              <img src={image} alt={`${title + index}`} />
            </span>
          </div>
        </div>
        <div className="c-artist-toolkit-card__bottom">
          <h3 wovn-ignore="" className="c-artist-toolkit-card__title">
            {RichText.asText(title)}
          </h3>
          <p wovn-ignore="" className="c-artist-toolkit-card__info">
            {RichText.asText(text)}
          </p>
        </div>
      </a>
    </div>
  );
};
