// @flow
import React from 'react';
import styled from 'styled-components';
import {StyledArticleMaxWidthContainer} from 'prismic/shared/styles/layout';
import {cssNormalText} from 'prismic/shared/styles/typography';
import {StyledLink, StyledArrowIcon} from '../../../StoryScreen/components/RelatedStories/RelatedStories';
import type {ArticlePreviewMdl} from 'prismic/shared/cms/article';
import {breakpointTabletOnly} from 'prismic/shared/styles/responsive';

const StyledContainer = styled.footer`
  margin: 0px 40px 100px;
  padding-top: 40px;
  border-top: 2px solid #000000;
  display: flex;
  justify-content: space-between;
  align-items: baseline;

  ${breakpointTabletOnly} {
    margin: 0px 15px 60px;
    padding-top: 30px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const StyledTitle = styled.h3`
  ${cssNormalText}
  width: 100%;

  ${breakpointTabletOnly} {
    margin-bottom: 20px;
  }
`;

const StyledBackWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 50%;

  ${breakpointTabletOnly} {
    width: 100%;
    > a {
      width: 100%;
    }
  }
`;

const StyledButtonText = styled.span`
`;

type Props = {
  relatedArticles: ArticlePreviewMdl[],
};

export const ArticleFooter = ({ relatedArticles }: Props) => (
  <StyledArticleMaxWidthContainer>
    <StyledContainer>
      <StyledTitle>
        For more advice and insights, check out our other articles from the toolkit.
      </StyledTitle>
      <StyledBackWrapper>
        <StyledLink isArrow mobileCondensed as="a" href="/articles">
          <StyledButtonText>VIEW MORE TOOLKITS</StyledButtonText>
          <StyledArrowIcon>→</StyledArrowIcon>
        </StyledLink>
      </StyledBackWrapper>
    </StyledContainer>
  </StyledArticleMaxWidthContainer>
);
