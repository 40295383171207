import React, {useEffect, useState} from 'react';
import classNames from 'classnames';
import {checkoutFlowRoutes} from 'modules/routes';

import ApiHelper from 'utils/api_helper';

export const CartIcon = ({
  className,
}) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    const updateCount = () => {
      ApiHelper.get('/api/v1/cart_items/quantity').then((res) => {
        setCount(res.response.quantity);
      });
    };
    updateCount();

    window.addEventListener('add_cart_item', updateCount);
    return () => window.removeEventListener('add_cart_item', updateCount);
  }, []);

  return (
    <a
      className={classNames('c-cart-icon', {
        [className]: className,
      })}
      href={checkoutFlowRoutes.cartScreen}
    >
      <i className="c-cart-icon__icon c-pictogram c-pictogram--cart" />
      {count > 0 && <div className="c-cart-icon__counter">{count <= 99 ? count : '99+'}</div>}
    </a>
  );
};
