import {createStore, compose, applyMiddleware} from 'redux';
import {createLogger} from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';
import rootReducer from 'project_start/modules/reducers';
import sagas from 'project_start/modules/sagas';

export default function configureStore() {
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const sagaMiddleware = createSagaMiddleware();
  const middleWares = [sagaMiddleware, thunk];

  if (process.env.NODE_ENV !== 'production') {
    middleWares.push(createLogger({collapsed: true}));
  }

  const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(...middleWares)),
  );

  sagaMiddleware.run(sagas);

  return store;
}
