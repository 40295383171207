// @flow
import React from 'react';
import styled from 'styled-components';
import { GlobalStyle } from '../../styles/global';
import { cssFontQrates } from '../../styles/fonts';

const StyledContainer = styled.div`
  ${cssFontQrates};
  color: #000000;
  background-color: #ffffff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  * {
    box-sizing: border-box;
  }

  a {
    cursor: pointer;
  }

  ul {
    list-style-type: none;
  }
`;

type Props = {
  children: any,
};

// const ScreenWrapper = ({ children }: Props) => (
//   <StyledContainer>
//     <GlobalStyle />
//     {children}
//   </StyledContainer>
// );
const ScreenWrapper = ({ children }: Props) => (
  <div>
    {children}
  </div>
);

export default ScreenWrapper;
