import React from 'react';
import classNames from 'classnames';

export const ScreenContainer = ({
  className,
  children,
  title,
}) => {
  return (
    <div className={classNames('screen', {
      [className]: className,
    })}
    >
      {title && <div className="screen__title">{title}</div>}
      <div className="screen__container">{children}</div>
    </div>
  );
};
