import React, {useEffect, useState} from 'react';
import algoliasearch from 'algoliasearch/lite';

import {formatDate} from 'utils/date';
import {useLanguageChange} from 'utils/use_language_change';
import {StoryCard} from '../shared/project_cards/story_card';
import {ListsCard} from 'src/shared/components/cards/lists_card/lists_card';
import {CatalogSlider} from 'shared/components/sliders/catalog_slider/catalog_slider';

export const FeaturedOn = ({
  algolia_id,
  algolia_search_api_key,
  project_ids,
  custom_class,
  locale,
}) => {
  const [stories, setStories] = useState([]);
  const [lists, setLists] = useState([]);
  const searchClient = algoliasearch(algolia_id, algolia_search_api_key);
  const [storyIndexName, setStoryIndexName] = useState(`Prismic::Story_${locale}`);
  const [listIndexName, setListIndexName] = useState(`Prismic::List_${locale}`);
  const storyIndex = searchClient.initIndex(storyIndexName);
  const listIndex = searchClient.initIndex(listIndexName);
  const filter = project_ids.map((i) => `related_project_ids:${i}`).join(' OR ');

  useLanguageChange((event) => {
    const lang = window.constants.prismic_locales[event.detail.locale];
    setStoryIndexName(`Prismic::Story_${lang}`);
    setListIndexName(`Prismic::List_${lang}`);
  });

  useEffect(() => {
    storyIndex.search('', {filters: filter}).then(({hits}) => setStories(hits.map((h) => ({type: 'story', ...h}))));
    listIndex.search('', {filters: filter}).then(({hits}) => setLists(hits.map((h) => ({type: 'list', ...h}))));
  }, [storyIndexName, listIndexName]);

  const records = [...lists, ...stories].sort((a, b) => new Date(b.first_publication_date) - new Date(a.first_publication_date));

  if (lists.length == 0 && stories.length == 0) return false;

  return (
    <CatalogSlider className={custom_class} slidesType="list-card" title="Featured On">
      {records.map((record, index) => {
        if (record.type === 'story') {
          return (
            <StoryCard
              key={record.objectID || index}
              label="Story"
              subtitle={record.preview_pre_heading}
              title={record.preview_heading}
              text={record.preview_text}
              date={formatDate(record.published_date, '%B %d %Y')}
              image={record.preview_image}
              link={record.path}
            />
          );
        }

        return (
          <ListsCard
            key={record.objectID || index}
            label="LIST"
            subtitle={record.subtitle}
            title={record.title}
            link={record.path}
            quote={record.curator}
            image={record.cover_image}
          />
        );
      })}
    </CatalogSlider>
  );
};
