import React from 'react';
import classNames from 'classnames';

export const SocialIcon = ({
  className,
  path,
  type,
  target,
}) => {
  const socialTypes = {
    facebook: {url: 'https://www.facebook.com/qratesinfo', iconClassName: 'c-pictogram c-pictogram--facebook'},
    instagram: {url: 'https://www.instagram.com/qrates/', iconClassName: 'c-pictogram c-pictogram--instagram-circle'},
    twitter: {url: 'https://twitter.com/qrates_com', iconClassName: 'c-pictogram c-pictogram--twitter-fill'},
  };

  return (
    <a
      className={classNames('c-social-icon', {[className]: className})}
      href={path || socialTypes[type].url}
      target={target}
    >
      <i className={`c-social-icon__icon ${socialTypes[type].iconClassName}`} />
    </a>
  );
};
