import dateFormat from 'dateformat';

export const formatDate = (date: string): string => {
  try {
    return dateFormat(Date.parse(date), 'mmmm dS, yyyy');
  } catch (error) {
    console.warn(`failed to parse data`, date);
    return '';
  }
};
