import React, {useState, useEffect} from 'react';
import classNames from 'classnames';
import ApiHelper from 'utils/api_helper';
import {formatMoney} from 'utils/format_money';
import {purchaseCardLabel} from 'utils/purchase_card_label';

export const PackageCard = ({orderItem}) => {
  const {
    project_id,
    quantity,
    project_title,
    artist_name,
    total_amount,
    currency,
    thumbnail_image_url,
    state,
  } = orderItem;
  const [projectDetails, setProjectDetails] = useState({});
  const [isDownloadable, setIsDownloadable] = useState(false);
  const productionState = projectDetails?.product_order?.state;
  const label = projectDetails && purchaseCardLabel({productionState, projectDetails, state});

  const dynamicUnitLabel = quantity > 1 ? 'units' : 'unit';
  const getDetails = () => {
    const searchParams = new URLSearchParams();
    searchParams.append(
      'except_attributes',
      [
        'project_card_image_url',
        'rendered_image_url',
        'sleeve_thumbnail_image_url',
        'vinyl_thumbnail_image_url',
        'vinyl2_thumbnail_image_url',
        'cassette_thumbnail_image_url',
        'case_thumbnail_image_url',
        'item',
      ],
    );
    ApiHelper.get(`/api/v1/projects/public/${project_id}?${searchParams}`).then((res) => {
      setProjectDetails(res.response);
    });
  };

  const checkDownloadableCondition = () => {
    ApiHelper.get(`/api/v1/projects/public/${project_id}/downloadable`).then((res) => {
      setIsDownloadable(res.response.downloadable);
    });
  };

  useEffect(() => {
    getDetails();
    checkDownloadableCondition();
  }, [orderItem]);

  return (
    <div className="c-package-items-list">
      <div className="c-package-items-list__container">
        <div className="c-package-items-list__image">
          <img src={thumbnail_image_url} />
        </div>
        <div className="c-package-items-list__info">
          <div className="c-package-items-list__title" wovn-ignore="">
            <span>
              {project_title}
            </span>
          </div>
          <div className="c-package-items-list__name" wovn-ignore="">{artist_name}</div>
          <div className="c-package-items-list__unit--bottom">
            {quantity}
            &nbsp;
            {dynamicUnitLabel}
            &nbsp;
            <span>{formatMoney({amount: total_amount, currency})}</span>
          </div>
        </div>
      </div>
      <div className="c-package-items-list__right">
        <div className="c-package-items-list__label">
          <span className="c-label--purchases">{label ?? <div className="c-dots-flashing" />}</span>
        </div>
        <div>
          <a
            className={classNames('c-link--download', {'c-link--disabled': !isDownloadable})}
            target="_blank"
            href={`/downloads/${project_id}`}
          >
            DOWNLOAD AUDIO FILES
          </a>
        </div>
      </div>
    </div>
  );
};
