import React, {useState, useEffect} from 'react';
import classNames from 'classnames';
import {useWindowDimensions} from 'utils/window_dimensions';

export const DashboardFollowersBar = ({
  className,
  colorTheme,
  followersCount,
  restartRequestText,
  followers,
}) => {
  const [folowersIconsLength, setFolowersIconsLength] = useState(7);
  const {isFullSize} = useWindowDimensions();
  const followersCountValue = followersCount || followers?.length;

  useEffect(() => {
    setFolowersIconsLength(isFullSize ? 7 : 5);
  }, [isFullSize]);

  return (
    <div
      className={classNames('c-dashboard-followers-bar', {
        [className]: className,

        // Themes
        [`c-dashboard-followers-bar--${colorTheme}`]: colorTheme,
      })}
    >
      <div className="c-dashboard-followers-bar__content">
        <p className="c-dashboard-followers-bar__text">{`${followersCountValue} followers`}</p>
        {restartRequestText && <p className="c-dashboard-followers-bar__text">{restartRequestText}</p>}
      </div>
      {followers?.length > 0 && (
        <figure className="c-dashboard-followers-bar__icons">
          {followers.slice(0, folowersIconsLength).map((watcher, index) => {
            return <div className="c-dashboard-followers-bar__icon" key={index}><img data-testid="follower_icon" src={watcher.profile_image_url} /></div>;
          })}
        </figure>
      )}
    </div>
  );
};
