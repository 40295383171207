import React, {
  useEffect,
  useState,
  useRef,
} from 'react';
import classNames from 'classnames';
import {isEqual} from 'lodash';

export const Dropdown = ({
  name,
  options,
  onChange,
  initialValue,
  placeholder,
  customClass,
  customIcon,
  readOnly,
  label,
}) => {
  const getOptionByValue = (value) => options.find((option) => isEqual(option.value, value)) || {};

  const [isOpened, setIsOpened] = useState(false);
  const [selectedOption, setSelectedOption] = useState(getOptionByValue(initialValue));

  const selectMenuRef = useRef(null);
  const selectRef = useRef(null);

  const handleSelectClick = (event) => {
    const selectElement = selectRef.current;

    if (readOnly || selectElement.dataset.active == '1') {
      selectElement.dataset.active = '0';

      return;
    }
    setIsOpened(!isOpened);

    selectElement.setAttribute('tabindex', 1);
    selectElement.focus();
  };

  const handleFocusOut = (event) => {
    setIsOpened(false);
    selectRef.current.dataset.active = '0';
  };

  const handleOptionClick = (value) => {
    const selectElement = selectRef.current;
    const option = getOptionByValue(value);
    if (option.disabled) {
      selectElement.dataset.active = '1';
    } else {
      selectElement.dataset.active = '0';
      setSelectedOption(option);
      onChange(value);
    }
  };

  useEffect(() => {
    const selectElement = selectRef.current;

    selectElement && selectElement.addEventListener('focusout', handleFocusOut);
  }, []);

  useEffect(() => {
    setSelectedOption(getOptionByValue(initialValue));
  }, [initialValue]);

  return (
    <>
      {label && <div className="c-select__main-label">{label}</div>}
      <div
        className={classNames('c-select', {active: isOpened, 'c-select--read-only': readOnly}, customClass)}
        onClick={handleSelectClick}
        ref={selectRef}
        status={isOpened ? 'active' : ''}
        value={selectedOption.value}
      >
        <div className="c-select__label" name={name}>
          <span>
            {selectedOption && customIcon && customIcon(selectedOption)}
            {selectedOption.label || placeholder || 'Please select...'}
          </span>
          <input
            type="hidden"
            name={name}
          />
          <div
            className="c-select__menu"
            ref={selectMenuRef}
          >
            {options.map((option, index) => {
              const {
                label, value, disabled, status,
              } = option;
              return (
                <div
                  className="c-select__menu__option"
                  value={value}
                  onClick={handleOptionClick.bind(this, value)}
                  status={isEqual(selectedOption.value, value) ? 'selected' : ''}
                  key={index}
                  disabled={disabled}
                >
                  {customIcon && customIcon(option)}
                  {label}
                  {status && <span className="c-select__menu__status-label">{status}</span>}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};
