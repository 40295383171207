import {
  put, takeLatest, call,
} from 'redux-saga/effects';
import Actions from 'project_start/modules/actions';
import * as ProjectApi from 'project_start/modules/api';
import ProjectRoutes from 'project_start/modules/routes';
import {renderValidationErrorMessage} from 'utils/validation';

export default function* () {
  yield takeLatest(Actions.initialize, initializeOperation);
  yield takeLatest(Actions.initializeBased, initializeBasedOperation);
  yield takeLatest(Actions.saveDraft, saveDraftOperation);
  yield takeLatest(Actions.saveSpec, saveSpecOperation);
  yield takeLatest(Actions.startProject, startProjectOperation);
  yield takeLatest(Actions.saveProjectInRedis, saveProjectInRedisOperation);
  yield takeLatest(Actions.calculateCost, calculateCostOperation);
  yield takeLatest(Actions.applyCoupon, applyCouponOperation);
  yield takeLatest(Actions.getCurrentUser, getCurrentUserOperation);
  yield takeLatest(Actions.openSignupScreenWithParams, openSignupScreenWithParamsOperation);
  yield takeLatest(Actions.getZeroPrices, getZeroPricesOperation);
}

function* initializeOperation(action) {
  const {
    projectId,
    params,
    onSuccess,
    onError,
  } = action.payload;

  const {response, error} = yield call(ProjectApi.fetchProject, projectId, params);

  if (response) {
    yield put(Actions.setProject({projectJSON: response}));
    onSuccess();
  } else {
    onError(error);
  }
}

function* initializeBasedOperation(action) {
  const {
    projectId,
    repress,
    onSuccess,
    onError,
  } = action.payload;

  const params = {repress};
  const {response, error} = yield call(ProjectApi.fetchProject, projectId, params);

  if (response) {
    yield put(Actions.setBasedProject({projectJSON: response, repress}));
    onSuccess();
  } else {
    onError(error);
  }
}

function* saveDraftOperation(action) {
  const {
    project, redis_key, onSuccess, onError,
  } = action.payload;

  const {response, error} = yield call(ProjectApi.saveDraft, project, {redis_key});

  if (response) {
    yield put(Actions.setProject({projectJSON: response}));
    if (onSuccess) { onSuccess(); }
    yield put(Actions.setNotification({type: 'done', message: 'Draft saved successfully.'}));
  } else {
    if (onError) { onError(error); }
    yield put(Actions.setNotification({type: 'error', message: renderValidationErrorMessage(error), error}));
  }
}

function* saveSpecOperation(action) {
  const {
    project,
    params,
    onSuccess,
    onError,
  } = action.payload;

  const {response, error} = yield call(ProjectApi.saveSpec, project, params);

  if (response) {
    yield put(Actions.setProject({projectJSON: response}));
    if (onSuccess) { onSuccess(); }
    yield put(Actions.setNotification({type: 'done', message: 'Project spec saved successfully.'}));
  } else {
    if (onError) { onError(error); }
    yield put(Actions.setNotification({type: 'error', message: renderValidationErrorMessage(error), error}));
  }
}

function* startProjectOperation(action) {
  const {
    project, redis_key, onSuccess, onError,
  } = action.payload;

  const {response, error} = yield call(ProjectApi.startProject, project, {redis_key});

  if (response) {
    if (onSuccess) { onSuccess(response.id); }
    window.location.href = ProjectRoutes.projectDashboardPath(response.id);
  } else {
    if (onError) { onError(error); }
    yield put(Actions.setNotification({type: 'error', message: renderValidationErrorMessage(error), error}));
  }
}

function* saveProjectInRedisOperation(action) {
  const {
    project, onSuccess, onError,
  } = action.payload;

  const {response, error} = yield call(ProjectApi.saveProjectInRedis, project);

  if (response) {
    if (onSuccess) { onSuccess(response); }
  } else {
    if (onError) { onError(error); }
    yield put(Actions.setNotification({type: 'error', message: renderValidationErrorMessage(error), error}));
  }
}

function* calculateCostOperation(action) {
  const {
    project,
    validate,
    recalculate_quantity,
    onSuccess,
    onError,
  } = action.payload;

  yield put(Actions.setIsCalculating(true));
  const {response, error} = yield call(ProjectApi.calculateCost, project, null, validate, recalculate_quantity);

  if (response) {
    console.log('calculate', response);
    yield put(Actions.setProjectCost({response}));

    if (onSuccess) { onSuccess(); }
  } else {
    console.error(error);
    if (onError) { onError(error); }
    if (validate) {
      yield put(Actions.setNotification({type: 'error', message: renderValidationErrorMessage(error), error}));
    }
  }
  yield put(Actions.setIsCalculating(false));
}

function* applyCouponOperation(action) {
  const {
    project, couponCode, onSuccess, onError,
  } = action.payload;

  const {response, error} = yield call(ProjectApi.calculateCost, project, couponCode);

  if (response) {
    yield put(Actions.setProjectCost({response}));

    if (response.product_order.coupon_id) {
      if (onSuccess) { onSuccess(); }
    } else if (onError) {
      onError({full_messages: ['Coupon code not applied.']});
    }
  } else if (onError) {
    onError(error);
  }
}

function* getCurrentUserOperation(action) {
  const {
    params,
    onSuccess,
    onError,
  } = action.payload;

  const {response, error} = yield call(ProjectApi.getCurrentUser, {params});
  if (response) {
    yield put(Actions.setUser({userJSON: response}));
    if (onSuccess) { onSuccess(); }
  } else {
    yield put(Actions.setUser({userJSON: null}));
    onSuccess && onSuccess();
    if (onError) { onError(error); }
  }
}

function openSignupScreenWithParamsOperation(action) {
  const {
    newWindow,
    callback,
    destination,
    redis_key,
  } = action.payload;
  const endpoint = `/${destination}?callback=${callback}&redis_key=${redis_key}`;
  if (!newWindow) return window.open(endpoint);

  newWindow.location.href = endpoint;
}

function* getZeroPricesOperation(action) {
  const {response} = yield call(ProjectApi.getZeroPrices);
  if (response) yield put(Actions.setZeroPrices(response));
}
