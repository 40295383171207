import React from 'react';
import Select from 'react-select';
import Creatable from 'react-select/creatable';
import classNames from 'classnames';

export const SelectField = ({
  className,
  name,
  label,
  value,
  options,
  onChange,
  placeholder,
  isSearchable = false,
  readOnly,
  locked,
  disabled,
  theme,
  creatable = false,
}) => {
  let currentValue = options.find((option) => {
    return option.value === value;
  });
  if (value) {
    currentValue ||= {value, label: value};
  }
  const SelectTag = creatable ? Creatable : Select;

  return (
    <div className={classNames('c-select-field', {
      [className]: className,
      'c-select-field--middle-width': theme?.includes('middle-width'),
      'is-readOnly': readOnly,
      'is-locked': locked,
      'is-disabled': disabled,
    })}
    >
      {label && <div className="c-select-field__label">{label}</div>}
      <input type="hidden" name={name} />
      <SelectTag
        className={classNames('c-select-field__input', {'is-locked': locked})}
        classNamePrefix="c-select-field"
        name={name}
        placeholder={placeholder}
        value={currentValue || null}
        options={options}
        onChange={onChange}
        isDisabled={disabled}
        isSearchable={isSearchable && !readOnly && !locked && !disabled}
        openMenuOnClick={!readOnly && !locked && !disabled}
      />
    </div>
  );
};
