import React from 'react';
import {
  render,
  screen,
  waitFor,
} from '@testing-library/react';

import {ArtistsOnQrates} from '../artists_on_qrates';

const mockResults = [
  {
    cover_url: {url: 'cover_url'},
    cover_image: {url: 'image_url'},
    description: [{text: 'description'}],
    signature: [{text: 'signature', spans: []}],
  },
];

jest.mock('prismic/shared/cms/prismic', () => {
  return {
    prismicClient: {
      query: () => {
        return Promise.resolve({
          results: mockResults,
        });
      },
    },
  };
});

beforeEach(() => {
  window.Swiper = () => {};
  window.constants = {prismic_locales: {}};
});

it('renders description', async () => {
  render(<ArtistsOnQrates artistsData={mockResults} />);

  await waitFor(() => {
    expect(screen.getByText('description')).toBeInTheDocument();
  });
});

it('renders cover', async () => {
  render(<ArtistsOnQrates artistsData={mockResults} />);

  await waitFor(() => {
    expect(screen.getByTitle('artist_cover')).toHaveAttribute('src', 'image_url');
  });
});
