import {Record} from 'immutable';
import Track from './track';

const _Record = Record({
  id: null,
  side: '',
  download_condition: 'vinyl_only',
  track: new Track(),
  track_number: 0,
}, 'ItemTrackRecord');

export default class ItemTrack extends _Record {
  static fromJSON(json) {
    if (json) {
      json.track = Track.fromJSON(json.track);
    }
    return new ItemTrack(json);
  }
}
