import {Record} from 'immutable';

const _Record = Record({
  number: 0,
  quantity: 0,
  cassette_type: 'cassette_type_solid',
  shell_color: 1,
  label_type: 'label_type_screen_print',
  label_color: 1,
}, 'CassetteRecord');

export default class Cassette extends _Record {}
