import {Record} from 'immutable';
import ItemPageDesign from './item_page_design';
import VinylItem from './vinyl_item';
import ItemTrack from './item_track';

const _Record = Record({
  title: '',
  artist_name: '',
  code: '',
  description_title: '',
  description: '',
  video_url: '',
  item_page_design: new ItemPageDesign(),
  vinyl: new VinylItem(),
  tags: [],
  state: null,
  watchings_count: 0,
  published_at: null,
  closed_on_publish: false,
  timezone: {},
  item_tracks: [new ItemTrack()],
}, 'ItemRecord');

export default class Item extends _Record {
  static fromJSON(json) {
    if (json) {
      json.item_page_design = ItemPageDesign.fromJSON(json.item_page_design);
      json.vinyl = VinylItem.fromJSON(json.vinyl);
      json.item_tracks = json.item_tracks.map((track, index) => ItemTrack.fromJSON(track));
    }
    return new Item(json);
  }
}
