export const purchaseCardLabel = ({
  productionState,
  projectDetails,
  state,
}) => {
  const awaitingItems = state === 'awaiting';
  const shippingSetup = state === 'processing';
  const isReserved = state === 'reserved';
  const shipped = state === 'shipped';
  const fundingType = projectDetails?.selling_condition?.selling_type === 'funding';
  const projectType = projectDetails?.selling_condition?.selling_type;
  const productionSetup = ['submitted', 'submitting', 'confirming', 'confirmed'].includes(productionState);
  const inProduction = projectDetails?.in_production;
  const successful = fundingType && projectDetails?.selling_condition?.is_succeeded;
  const unsuccessful = fundingType && projectDetails?.selling_condition?.is_failed;
  let label;
  switch (projectType) {
    case 'sell':
      if (!shipped && inProduction) {
        label = 'in production';
      }
      if (awaitingItems) {
        label = 'awaiting items';
      }
      if (shippingSetup) {
        label = 'shipping setup';
      }
      if (shipped) {
        label = 'shipped';
      }
      break;
    case 'funding':
      if (isReserved && !successful) {
        label = 'now funding';
      }
      if (successful && !inProduction) {
        label = 'succeeded';
      }
      if (unsuccessful) {
        label = 'uncompleted';
      }
      if (productionSetup) {
        label = 'production setup';
      }
      if (!shipped && inProduction) {
        label = 'in production';
      }
      if (shippingSetup) {
        label = 'shipping setup';
      }
      if (shipped) {
        label = 'shipped';
      }
      break;
    case 'preorder':
      if (!shipped && inProduction) {
        label = 'in production';
      }
      if (awaitingItems) {
        label = 'awaiting items';
      }
      if (shippingSetup) {
        label = 'shipping setup';
      }
      if (shipped) {
        label = 'shipped';
      }
      break;
  }
  return label;
};
