import React from 'react';
import {render, screen} from '@testing-library/react';

import {RestartRequestModal} from '../restart_request_modal';

describe('with repress purpose and vinyl project', () => {
  const project = {
    product_type: {original_value: 'vinyl'},
  };

  it('renders correct content', async () => {
    render(<RestartRequestModal purpose="repress" project={project} />);

    expect(screen.getByText('Vote for a repress')).toBeInTheDocument();
    expect(screen.getByText('SEND REPRESS REQUEST')).toBeInTheDocument();
    expect(screen.getByTitle('product image')).toHaveAttribute('src', '11-status-shipped.png');
    expect(screen.getByText(/Request the artist to repress this project/)).toBeInTheDocument();
  });
});

describe('with reproduction purpose and cassette project', () => {
  const project = {
    product_type: {original_value: 'cassette'},
  };

  it('renders correct content', async () => {
    render(<RestartRequestModal purpose="reproduction" project={project} />);

    expect(screen.getByText('Vote for a reproduction')).toBeInTheDocument();
    expect(screen.getByText('SEND REDUPLICATION REQUEST')).toBeInTheDocument();
    expect(screen.getByTitle('product image')).toHaveAttribute('src', '14.svg');
    expect(screen.getByText(/Request the artist to restart this project/)).toBeInTheDocument();
  });
});

describe('with retry purpose and vinyl project', () => {
  const project = {
    product_type: {original_value: 'vinyl'},
  };

  it('renders correct content', async () => {
    render(<RestartRequestModal purpose="retry" project={project} />);

    expect(screen.getByText('Vote for a retry')).toBeInTheDocument();
    expect(screen.getByText('SEND RETRY REQUEST')).toBeInTheDocument();
    expect(screen.getByTitle('product image')).toHaveAttribute('src', '11-status-shipped.png');
    expect(screen.getByText(/Request the artist to retry this project/)).toBeInTheDocument();
  });
});

describe('with retry purpose and cassette project', () => {
  const project = {
    product_type: {original_value: 'cassette'},
  };

  it('renders correct content', async () => {
    render(<RestartRequestModal purpose="retry" project={project} />);

    expect(screen.getByText('Vote for a retry')).toBeInTheDocument();
    expect(screen.getByText('SEND RETRY REQUEST')).toBeInTheDocument();
    expect(screen.getByTitle('product image')).toHaveAttribute('src', '14.svg');
    expect(screen.getByText(/Request the artist to retry this project/)).toBeInTheDocument();
  });
});
