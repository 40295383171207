import React, {useRef} from 'react';
import classNames from 'classnames';

export const InputField = ({
  label,
  name,
  theme,
  value,
  defaultValue,
  onChange,
  onBlur,
  onKeyUp,
  placeholder,
  isReadOnly,
  isLocked,
  className,
  labelIcon,
  type = 'text',
  error,
  symbol,
}) => {
  const input = useRef();

  const handleChange = (event) => {
    onChange && onChange(event);
  };

  const handleKeyUp = (event) => onKeyUp && onKeyUp(event);

  const symbols = {
    percent: '%',
  };

  const onWheel = (e) => {
    e.target.blur();
  };

  return (
    <div className={classNames({[className]: className})}>
      <label
        className={classNames('c-input-field', {
          'c-input-field--small-padding': theme?.includes('small_padding'),
          'c-input-field--symbol': symbol,

          'is-readOnly': isReadOnly,
          'is-locked': isLocked,
          'is-error': error,
        })}
      >
        {label && (
        <span className="c-input-field__label">
          {label}
          {labelIcon && (
            <a href={labelIcon.link} target="_blank">
              {labelIcon.icon === 'question' && <i className="c-pictogram c-pictogram--question" />}
            </a>
          )}
        </span>
        )}
        <input
          className="c-input-field__input"
          type={type}
          name={name}
          value={value}
          defaultValue={defaultValue}
          onChange={handleChange}
          onBlur={onBlur}
          onKeyUp={handleKeyUp}
          readOnly={isReadOnly || isLocked}
          placeholder={placeholder}
          onWheel={onWheel}
          ref={input}
        />
        {error && typeof error === 'string' && <p className="c-input-field__error">{error}</p>}
        {symbol && <span className="c-input-field__symbol">{symbols[symbol]}</span>}
      </label>
    </div>
  );
};
