import React from 'react';
import Modal from 'react-modal';
import classNames from 'classnames';

export const ReactModal = ({
  children,
  isOpen,
  appElement,
  onRequestClose,
  onClose,
  theme,
  adaptiveWidth,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      appElement={appElement}
      overlayClassName="c-react-modal-overlay"
      className="c-react-modal-centered-container"
      onRequestClose={onRequestClose || onClose}
    >
      <div className={classNames('c-react-modal', {
        'c-react-modal--thin-frames': theme === 'thin-frames',
        'c-react-modal--large-width': theme === 'large-width',
        'c-react-modal--fixed-dimensions': theme === 'fixed-dimensions',

        'c-react-modal--adaptive-width': adaptiveWidth,
      })}
      >
        {onClose && <span className="c-react-modal__close-button" onClick={onClose}>&times;</span>}
        <div className="c-react-modal__content">
          {children}
        </div>
      </div>
    </Modal>
  );
};
