import React, {useState} from 'react';

import ApiHelper from 'utils/api_helper';
import {useLanguageChange} from 'utils/use_language_change';
import ProfileIconPath from 'images/common/logos/profile_icon.png';

export const ListsScreen = ({lists, next_page, total_count}) => {
  const [records, setRecords] = useState(lists);
  const [nextPage, setNextPage] = useState(next_page);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const fetchLists = ({nextPage}) => {
    const queryParams = new URLSearchParams();
    nextPage && queryParams.set('page', nextPage);
    ApiHelper.get(`/lists.json?${queryParams}`).then((res) => {
      setNextPage(res.response.next_page);
      const results = nextPage ? [...records, ...res.response.results] : res.response.results;
      setRecords(results);
      setIsButtonDisabled(false);
    });
  };

  const handleMoreButtonClick = () => {
    setIsButtonDisabled(true);
    fetchLists({nextPage});
  };

  useLanguageChange(fetchLists);

  return (
    <>
      <div className="p-section-header--search">
        <div className="p-section-header--search__quantity">{`${total_count} lists`}</div>
      </div>
      <div className="p-lists">
        {records.map((list) => (
          <div className="p-lists__container" key={list.uid}>
            <a href={`/lists/${list.uid}`}>
              {list.cover_image && (
                <div className="p-lists__image" style={{backgroundImage: `url(${list.cover_image})`}} />
              )}
              {!list.cover_image && <div className="p-lists__image--one" />}
              <div className="p-lists__info">
                <p className="p-lists__title">{list.title}</p>
                <p className="p-lists__name">{list.subtitle}</p>
              </div>
              <div className="p-lists__selecter">
                <p className="p-lists__selecter-name">
                  <img src={ProfileIconPath} className="p-lists__selecter-avatar" />
                  {`Curated by ${list.curator}`}
                </p>
              </div>
            </a>
          </div>
        ))}
      </div>
      {nextPage && (
        <div className="pagination">
          <div className="p-lists__button c-button-grid--center-button">
            <button
              type="button"
              className="c-button c-button--large c-button--black"
              onClick={handleMoreButtonClick}
              disabled={isButtonDisabled}
            >
              LOAD MORE LISTS
            </button>
          </div>
        </div>
      )}
    </>
  );
};
