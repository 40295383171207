// @flow
import React from 'react';
import styled from 'styled-components';
import parse from 'html-react-parser';
import {RichText} from 'prismic-reactjs'
import {cssHeadingOne} from '../../styles/typography';
import {breakpointTabletOnly} from '../../styles/responsive';
import {getSliceHalfHeading} from '../../cms/story';

const StyledWrapper = styled.div`
  margin: 0px 0px 100px;
  height: 100%;

  h2 {
    ${cssHeadingOne};
    margin-bottom: 40px;
  }

  ${breakpointTabletOnly} {
    margin: 0px 0px 40px;

    h2 {
      margin: 0px 15px 40px;
    }
  }
`;

const StyledTable = styled.div`
  overflow-x: auto;

  ${breakpointTabletOnly} {
    margin: 0px 0px 40px 15px;
    padding-right: 15px;
  }
`;

const StyledContainer = styled.div`
  display: inline-block;
  width: 100%;

  table {
    border-collapse: collapse;
    width: 100%;
    font-size: 20px;
    font-style: normal;
    line-height: 28px;
    letter-spacing: -0.273px;
  }

  table,
  th,
  td {
    border: 1px solid black;
  }

  th {
    font-weight: bold;
  }

  tr {
    text-align: left;
  }

  th,
  td {
    padding: 10px;
    min-width: 100%;
  }

  ${breakpointTabletOnly} {
    min-width: 450px;

    table {
      overflow-x: auto;
      font-size: 18px;
      font-style: normal;
      line-height: 24px;
      letter-spacing: -0.3px;
    }

    th,
    td {
      min-width: 140px;
      min-width: 100%;
    }
  }
`;

type Props = {
  html: any,
};

export const HalfTable = ({ html, slice }: Props) => {
  return (
    <StyledWrapper>
      <span wovn-ignore="">{RichText.render(getSliceHalfHeading(slice))}</span>
      <StyledTable>
        <StyledContainer>
          <span wovn-ignore="">{parse(html, '[0].text', '' || '')}</span>
        </StyledContainer>
      </StyledTable>
    </StyledWrapper>
  );
};
