import React, {useState} from 'react';
import {ArtistToolkitHeader} from './components/artist_toolkit_header';
import {ArtistToolkitArticles} from './components/artist_toolkit_articles';

export const ArtistToolkitPage = () => {
  const params = new URLSearchParams(window.location.search);
  const searchParamsQuery = params.get('category');
  const [currentFilter, setCurrentFilter] = useState(searchParamsQuery ?? 'Most Recent');
  const setCurrentFilterHandler = (value) => setCurrentFilter(value);

  return (
    <div wovn-ignore="">
      <ArtistToolkitHeader
        currentFilter={currentFilter}
        currentFilterHandler={setCurrentFilterHandler}
      />
      <ArtistToolkitArticles currentFilter={currentFilter} />
    </div>
  );
};
