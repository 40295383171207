import React from 'react';

import {PublicPagesHeading} from 'shared/components/content/public_pages_heading/public_pages_heading';

export const BannerWithTitle = ({
  id,
  title,
}) => {
  return (
    <div className="c-banner-with-title" id={id}>
      <PublicPagesHeading
        className="c-banner-with-title__title"
        theme="middle-size"
        headingTagValue={3}
      >
        {title}
      </PublicPagesHeading>
    </div>
  );
};
