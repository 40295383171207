import React from 'react';
import { RichText } from 'prismic-reactjs';
import {Link} from 'react-router-dom';

import { PrismicRoutes } from 'components/prismic/modules/routes/routes';

export const ServicesContent = ({ item, nextButton, prevButton, scrollToContent }) => {
  return (
    <div className="services-content">
      <div className="services-content__title" wovn-ignore="">{item.data.title[0].text}</div>
      <div className="services-content__body" wovn-ignore="">
        {item.data.body.map((item, index) => {
          return (
            <div className="services-content__paragraph" key={index}>
              <div className="services-content__subtitle"><span className="f-adjust-ja">{item.primary.paragraph_title[0].text}</span></div>
              <div className="services-content__description">
                <div className="f-adjust-ja">
                  <RichText render={item.primary.paragraph_description} />
                </div>
              </div>
            </div>
          )
        })}
      </div>
      <div className="services-content__controls">
        <a className="c-button c-button--white-black-line" href={PrismicRoutes.whyPagePath()}>BACK TO WHY QRATES</a>
        <div className="services-content__arrows">
          {prevButton && (
            <Link
              className="c-button c-button--white-black-line c-button--white-black-line--arrow-left"
              to={prevButton}
              onClick={() => scrollToContent()}
            >
              <i className="c-pictogram c-pictogram--chevron-left" /> PREVIOUS
            </Link>
          )}
          {nextButton &&  (
            <Link
              className="c-button c-button--white-black-line c-button--white-black-line--arrow-right"
              to={nextButton}
              onClick={() => scrollToContent()}
            >
            <i className="c-pictogram c-pictogram--chevron-right" /> NEXT
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};
