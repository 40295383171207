export const countriesDefaultData = {
  US: {
    delivery_service: 'delivery_service_us',
    currency: 'USD',
  },
  GB: {
    delivery_service: 'delivery_service_uk',
    currency: 'GBP',
  },
  JP: {
    delivery_service: 'delivery_service_japan',
    currency: 'JPY',
  },
};
