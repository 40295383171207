import React from 'react';
import {Elements} from '@stripe/react-stripe-js';
import {get} from 'lodash';
import classNames from 'classnames';

import {stripePromise} from 'utils/stripe';

import {StripeCheckoutForm} from 'dashboard/components/project_dashboard/partials/stripe_checkout_form';
import {RadioField} from 'shared/components/form/radio_field/radio_field';

import IconMaster from 'images/common/icon/mastercard-icon.png';
import IconVisa from 'images/common/icon/visa-icon.png';
import IconAmex from 'images/common/icon/amex-icon.png';
import IconPaypal from 'images/common/icon/paypal-card-icon.png';

export const PaymentInfoSection = ({
  formParams,
  setFormParams,
  setSubmitDisabled,
}) => {
  const paymentMethod = get(formParams, 'orders[0].payment_method');
  const paymentCurrency = get(formParams, 'orders[0].payment_currency');
  const orderTypes = formParams.orders.map((o) => o.order_type);
  const paypalDisabled = orderTypes.filter((t) => t === 'funding').length > 1 || (orderTypes.includes('funding') && orderTypes.includes('sell_catalog'));

  const handlePaymentMethodChange = (value) => {
    const newFormParams = {...formParams};
    newFormParams.orders.forEach((order) => {
      order.payment_method = value;
    });
    setFormParams(newFormParams);
  };

  const handleStripeFormChange = (state) => {
    if (state.token?.id) {
      const newFormParams = {...formParams};
      newFormParams.orders.forEach((order) => {
        order.payment_token = state.token.id;
      });
      setFormParams(newFormParams);
      setSubmitDisabled(false);
    }
  };

  const renderOptions = () => {
    if (paymentMethod === 'invoice') {
      return (
        <label type="radio" htmlFor="stripe_field" className="c-payment-info-section__invoice-radio payment-radio-button">
          <RadioField
            className="payment-radio-button__field"
            label="Invoice"
            value="invoice"
            checked={paymentMethod === 'invoice'}
            onChange={() => handlePaymentMethodChange('invoice')}
            name="invoice_field"
          />
        </label>
      );
    } else {
      return (
        <>
          <label type="radio" htmlFor="stripe_field" className="c-payment-info-section__stripe-radio payment-radio-button">
            <RadioField
              className="payment-radio-button__field"
              label=" Pay with card"
              checked={paymentMethod === 'stripe'}
              onChange={() => handlePaymentMethodChange('stripe')}
              name="stripe_field"
            />
            <span className="payment-radio-button__card-container">
              <img src={IconMaster} alt="Master card" className="payment-radio-button__card-icon" />
              <img src={IconVisa} alt="Visa card" className="payment-radio-button__card-icon" />
              <img src={IconAmex} alt="Amex card" className="payment-radio-button__card-icon" />
            </span>
          </label>
          <label
            type="radio"
            className={classNames('c-payment-info-section__paypal-radio payment-radio-button', {'is--disabled': paypalDisabled})}
            disabled={paypalDisabled}
            htmlFor="paypal_field"
          >
            <RadioField
              className="payment-radio-button__field"
              label="Pay with PayPal"
              value="paypal"
              name="paypal_field"
              disabled={paypalDisabled}
              checked={paymentMethod === 'paypal'}
              onChange={() => handlePaymentMethodChange('paypal')}
            />
            <span className="payment-radio-button__card-container">
              <img src={IconPaypal} alt="Paypal" className="payment-radio-button__card-icon" />
            </span>
          </label>
        </>
      );
    }
  };

  return (
    <div className="c-payment-info-section">
      <h3 className="c-payment-info-section__title">Payment information</h3>
      {paypalDisabled && paymentMethod != 'invoice' && (
        <div className="c-payment-info-section__banner">
          Crowdfunding projects cannot currently be checked out together with purchase / preorder projects when paying via PayPal.
          We are sorry for the inconvenience. Please place your crowdfunding pledge and your purchase orders separately.
        </div>
      )}
      <div className="c-payment-info-section__options">
        {renderOptions()}
      </div>
      {paymentMethod === 'stripe' && paymentCurrency && (
        <div className="c-payment-info-section__inputs-card">
          <Elements stripe={stripePromise({currency: paymentCurrency})}>
            <StripeCheckoutForm onChangeHandler={handleStripeFormChange} />
          </Elements>
        </div>
      )}
    </div>
  );
};
