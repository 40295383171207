import React from 'react';
import {CuratedHeading} from '../components/curated_heading/curated_heading';
import {CuratedFeaturedLarge} from '../../components/curated_featured/curated_featured_large';
import {CuratedFeaturedFallback} from '../../components/curated_featured/curated_featured_fallback';
import {Carousel} from '../components/curated_carousel/curated_carousel';

export const ToolkitSection = ({
  heading,
  carouselHeading,
  carouselButtonLabel,
  featuredLinkLabel,
  articles,
  featuredArticle,
  isLoading,
  isError,
}) => {
  const rootPath = '/articles/';

  if (isError) return <CuratedFeaturedFallback />;
  if (isLoading) return <div />;
  return (
    <>
      <CuratedHeading heading={heading} />
      <CuratedFeaturedLarge
        rootPath={rootPath}
        article={featuredArticle[0]}
        cardTheme="dark"
        labelTheme="transparent"
        linkLabel={featuredLinkLabel}
      />
      <Carousel
        curated
        rootPath={rootPath}
        articles={articles}
        title={carouselHeading}
        buttonLabel={carouselButtonLabel}
        buttonLink="/articles"
        cardType="toolkit"
      />
    </>
  );
};
