// @flow
import React from 'react';
import styled from 'styled-components';
import {RichText} from 'prismic-reactjs';
import {cssSharedBgImage} from '../../../screens/StoriesScreen/components/StoryPreview/StoryPreview';
import {FONT_WEIGHTS} from '../../styles/fonts';
import {StyledLink} from '../../../screens/StoryScreen/components/RelatedStories/RelatedStories';
import type {StorySliceArtistProfile} from '../../cms/story';
import {
  getSliceArtistProfileDestination,
  getSliceArtistProfileImageUrl,
  getSliceArtistProfileText,
} from '../../cms/story';
import {prismicLinkResolver} from '../../cms/prismic';
import {cssSmallPlusText} from '../../styles/typography';
import {breakpointTabletOnly, cssMobileSidePadding} from '../../styles/responsive';

export const StyledContainer = styled.div`
  ${cssMobileSidePadding};
  padding-top: 100px;
  border-top: 2px solid #000000;
  margin: 0px 40px 100px;
  position: relative;


  ${breakpointTabletOnly} {
    margin: 0px 15px 40px;
  }
`;

const StyledImage = styled.div`
  ${cssSharedBgImage};
  background-image: url(${props => props.image || ''});
  width: 318px;
  height: 318px;
  border-radius: 50%;
  margin: 0 auto;

  ${breakpointTabletOnly} {
    width: 160px;
    height: 160px;
  }
`;

export const StyledInfo = styled.div`
  margin-top: 72px;
  text-align: center;

  ${breakpointTabletOnly} {
    margin-top: 40px;
  }

  span {
    font-size: 28px;
    letter-spacing: -0.3px;
    line-height: 34px;
    font-weight: ${FONT_WEIGHTS.bold};

    ${breakpointTabletOnly} {
      font-size: 22px;
      letter-spacing: -0.37px;
      line-height: 26px;
    }

    strong {
      font-weight: ${FONT_WEIGHTS.bold};
    }
  }
`;

export const StyledLinkWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 32px;

  span {
    ${cssSmallPlusText};
  }

  ${breakpointTabletOnly} {
    margin-top: 20px;
  }
`;

type Props = {
  slice: StorySliceArtistProfile,
};

export const ArtistProfile = ({ slice }: Props) => (
  <StyledContainer>
    <StyledImage image={getSliceArtistProfileImageUrl(slice)} />
    <StyledInfo>
      <span wovn-ignore="">{RichText.render(getSliceArtistProfileText(slice), prismicLinkResolver())}</span>
      <StyledLinkWrapper>
        <StyledLink
          as="a"
          mobileCondensed
          href={getSliceArtistProfileDestination(slice)}
          target="_blank"
          rel="noreferrer noopener"
        >
          <span wovn-ignore="">View Artist Profile</span>
        </StyledLink>
      </StyledLinkWrapper>
    </StyledInfo>
  </StyledContainer>
);
