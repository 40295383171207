export const updateVariation = ({index, project, update}) => {
  if (!index || index === 0) {
    return project.set('spec', update(project.get('spec')));
  } else {
    const variations = project.getIn(['product_order', 'spec_variations']).map((variation) => {
      return variation.index === index ? update(variation) : variation;
    });

    return project.setIn(['product_order', 'spec_variations'], variations);
  }
};
