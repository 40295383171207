import React, {useEffect, useState} from 'react';

import ApiHelper from 'utils/api_helper';

import {ModalHeading} from 'shared/components/modals/parts/content/modal_heading/modal_heading';
import {CartItemScreen} from '../cart_item_screen/cart_item_screen';

export const AddedToCartModal = ({cartItem, onClose}) => {
  const [item, setItem] = useState(null);

  useEffect(() => {
    const newItem = {...cartItem};
    if (!newItem.named_price) {
      newItem.named_price = newItem.available_price;
    }
    setItem(newItem);
  }, []);

  useEffect(() => {
    const handleQuantityChange = () => {
      ApiHelper.get('/api/v1/cart_items/quantity').then((res) => {
        if (res.response.quantity === 0) onClose();
      });
    };

    window.addEventListener('add_cart_item', handleQuantityChange);
    return () => window.removeEventListener('add_cart_item', handleQuantityChange);
  }, []);

  return (
    <div className="p-added-cart">
      <ModalHeading className="p-added-cart__heading">Added to cart</ModalHeading>
      {item && (
        <CartItemScreen
          theme="payment_details_screen"
          cartItem={item}
          updateCartItems={() => {}}
          key={item.id}
        />
      )}
      <div className="c-grid--center">
        <span className="c-link" onClick={onClose}>KEEP BROWSING</span>
        <a href="/cart/items" className="c-button c-button--black c-button--middle">
          <span className="c-button__label">VIEW CART</span>
        </a>
      </div>
    </div>
  );
};
