import React, {useState} from 'react';
import {RichText} from 'prismic-reactjs';
import classNames from 'classnames';

import {Swiper, SwiperSlide} from 'swiper/react';
import SwiperCore, {Autoplay, EffectFade} from 'swiper';

import sparkleSticker from 'images/common/sticker/sparkle_white.png';
import dollarSticker from 'images/common/sticker/dollar_white.png';

import cover1 from 'images/public/section_vinyl_studio/sample_all_india.png';
import cover2 from 'images/public/section_vinyl_studio/sample_4dilla.png';
import cover3 from 'images/public/section_vinyl_studio/sample_bombay.png';
import cover4 from 'images/public/section_vinyl_studio/sample_blarney.png';
import cover5 from 'images/public/section_vinyl_studio/sample_female.png';
import cover6 from 'images/public/section_vinyl_studio/sample_black_country_disco.png';

export const QratesStudioSliderBanner = ({sliderData}) => {
  const [stickerSwitcher, setStickerSwitcher] = useState(true);

  const slides = [cover1, cover2, cover3, cover4, cover5, cover6];

  SwiperCore.use([Autoplay, EffectFade]);

  const swiperSlides = slides.map((imageItem, index) => {
    return (
      <SwiperSlide key={index}>
        <img src={imageItem} data-testid="slider-image" alt={`slide_${index}`} />
      </SwiperSlide>
    );
  });

  const onLastSlide = () => {
    setStickerSwitcher((stickerSwitcher) => (!stickerSwitcher));
  };

  return (
    <div className="c-qrates-studio-slider-banner">
      <div className="c-qrates-studio-slider-banner__content">
        <div wovn-ignore="" className="c-qrates-studio-slider-banner__label c-label--pages--black">{sliderData.button_label}</div>
        <h3 wovn-ignore="" className="c-qrates-studio-slider-banner__title">
          {sliderData.title[0].text}
        </h3>
        <p className="c-qrates-studio-slider-banner__description">
          <span wovn-ignore="" className="f-adjust-ja">
            {RichText.asText(sliderData.text)}
          </span>
        </p>
        <div className="c-qrates-studio-slider-banner__slider">
          <Swiper
            loop
            observer
            observeParents
            speed={20}
            effect="fade"
            autoplay={{delay: 750}}
            spaceBetween={50}
            slidesPerView={1}
            allowTouchMove={false}
            onReachEnd={onLastSlide}
          >
            {swiperSlides}
          </Swiper>
          <div className={classNames('c-qrates-studio-slider-banner__sparkle-sticker', {active: stickerSwitcher})}>
            <img src={sparkleSticker} alt="sparkle-sticker" />
          </div>
          <div className={classNames('c-qrates-studio-slider-banner__dollar-sticker', {active: !stickerSwitcher})}>
            <img src={dollarSticker} alt="dollar-sticker" />
          </div>
        </div>
        <a wovn-ignore="" className="c-qrates-studio-slider-banner__button" href="/projects/start">{sliderData.button_label}</a>
      </div>
    </div>
  );
};
