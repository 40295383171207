import React, {useState} from 'react';
import {get, compact} from 'lodash';

import {ToggleSwitch} from 'shared/components/form/toggle_switch/toggle_switch';
import {ReactModal} from 'shared/components/modals/react_modal/react_modal';
// import {InputField} from 'shared/components/form/input_field/input_field';
import {BillingInformationForm} from '../billing_information_form/billing_information_form';

export const BillingInformationSection = ({
  currentUser,
  formParams,
  setFormParams,
  calculateOrder,
  isReadOnly,
  usStates,
  jpStates,
}) => {
  const [modal, setModal] = useState(null);
  const countries = window.constants.deliverableCountries.map((country) => ({label: country.name, value: country.code}));
  const country = countries.find((c) => c.value === get(formParams, 'orders[0].billing_country'));
  const address = compact([
    get(formParams, 'orders[0].billing_building'),
    get(formParams, 'orders[0].billing_street'),
    get(formParams, 'orders[0].billing_city'),
    get(formParams, 'orders[0].billing_prefecture'),
    get(formParams, 'orders[0].billing_postal_code'),
    country?.label,
  ]).join(', ');
  const name = compact([
    get(formParams, 'orders[0].billing_first_name'),
    get(formParams, 'orders[0].billing_last_name'),
  ]).join(' ');
  const isUserInfoPresent = currentUser && currentUser.first_name && currentUser.last_name && currentUser.country;

  const copyFromShippingInfo = (checked) => {
    const newFormParams = {...formParams};
    if (checked) {
      newFormParams.orders.forEach((order) => {
        order.billing_first_name = get(order, 'order_items[0].delivery_first_name');
        order.billing_last_name = get(order, 'order_items[0].delivery_last_name');
        order.billing_company = get(order, 'order_items[0].delivery_company');
        order.billing_building = get(order, 'order_items[0].delivery_building');
        order.billing_street = get(order, 'order_items[0].delivery_street');
        order.billing_city = get(order, 'order_items[0].delivery_city');
        order.billing_prefecture = get(order, 'order_items[0].delivery_prefecture');
        order.billing_postal_code = get(order, 'order_items[0].delivery_postal_code');
        order.billing_country = get(order, 'order_items[0].delivery_country');
        order.billing_phone_number = get(order, 'order_items[0].delivery_phone_number');
      });
    }
    newFormParams.pre_populate_billing_info = checked;
    setFormParams(newFormParams);
  };

  // TODO: Uncomment after enabling stripe tax feature
  // const handleTaxIdChange = (event) => {
  //   const newFormParams = {...formParams};
  //   newFormParams.orders.forEach((order) => {
  //     order.order_items.forEach((orderItem) => {
  //       set(orderItem, 'tax_id', event.target.value);
  //     });
  //   });
  //   setFormParams(newFormParams);
  //   calculateOrder({params: newFormParams});
  // };

  return (
    <div className="c-billing-information-section">
      <h3 className="c-billing-information-section__title">Billing information</h3>
      {!isUserInfoPresent && (
        <div className="c-billing-information-section__switch">
          <p>
            Same as shipping information
          </p>
          <ToggleSwitch
            className="c-billing-information-section__toggle"
            checked={formParams.pre_populate_billing_info}
            onChange={() => copyFromShippingInfo(!formParams.pre_populate_billing_info)}
            isLocked={isReadOnly}
          />
        </div>
      )}
      {isUserInfoPresent && (
        <div className="c-billing-information-section__ready-block-info ready-block-info">
          <div className="ready-block-info__content">
            <div className="ready-block-info__item" wovn-ignore="">{name}</div>
            <div className="ready-block-info__item" wovn-ignore="">{get(formParams, 'orders[0].billing_company')}</div>
            <div className="ready-block-info__item" wovn-ignore="">{address}</div>
            <div className="ready-block-info__item" wovn-ignore="">{get(formParams, 'orders[0].billing_phone_number')}</div>
          </div>
          <button
            className="ready-block-info__edit-modal-button edit-button"
            type="button"
            onClick={() => setModal('billing_info_form')}
          >
            EDIT
          </button>
        </div>
      )}
      {!isUserInfoPresent && !formParams.pre_populate_billing_info && (
        <BillingInformationForm
          className="c-billing-information-section__form"
          formParams={formParams}
          setFormParams={setFormParams}
          isReadOnly={isReadOnly}
          usStates={usStates}
          jpStates={jpStates}
          currentUser={currentUser}
          isUserInfoPresent={isUserInfoPresent}
        />
      )}
      {/* TODO: Uncomment after enabling stripe tax feature
        <InputField
          className="c-billing-information-form__phone-number"
          label="Tax ID / VAT Number"
          placeholder="1234567890"
          name="order_items.tax_id"
          value={get(formParams, 'orders[0].order_items[0].tax_id')}
          onChange={handleTaxIdChange}
          isReadOnly={isReadOnly}
        />
      */}
      <ReactModal
        isOpen={modal === 'billing_info_form'}
        appElement={document.body}
        onRequestClose={() => setModal(null)}
        onClose={() => setModal(null)}
      >
        <BillingInformationForm
          onCloseModal={() => setModal(null)}
          formParams={formParams}
          setFormParams={setFormParams}
          isReadOnly={isReadOnly}
          usStates={usStates}
          jpStates={jpStates}
          currentUser={currentUser}
          isUserInfoPresent={isUserInfoPresent}
          isModal
        />
      </ReactModal>
    </div>
  );
};
