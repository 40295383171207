import React from 'react';
import {
  sumBy,
  get,
  set,
  isEqual,
  omit,
  map,
} from 'lodash';
import {PayPalButton} from 'react-paypal-button-v2';

import {formatMoney} from 'utils/format_money';
import ApiHelper from 'utils/api_helper';
import {handleError, clearValidationErrors} from 'utils/validation';
import {scrollToTop} from 'utils/scroll';

export const PaymentDetailsFooter = ({
  orders,
  formParams,
  setFormParams,
  setNotification,
  submitDisabled,
  setSubmitDisabled,
  setReadOnly,
}) => {
  const paymentCurrency = get(orders, '[0].payment_currency');
  const totalPaymentTotalAmount = sumBy(orders, 'total_payment_total_amount');
  const orderTypes = formParams.orders.map((o) => o.order_type);

  const handleSubmit = ({params}) => {
    const newParams = {...params};
    setSubmitDisabled(true);
    setReadOnly(true);
    clearValidationErrors(setNotification);
    const fields = ['first_name', 'last_name', 'building', 'street', 'city', 'prefecture', 'postal_code', 'country', 'phone_number'];
    if (params.pre_populate_user_billing_info) {
      fields.forEach((field) => {
        set(newParams, `user.${field}`, get(newParams, `orders[0].billing_${field}`));
      });
    }
    if (params.pre_populate_user_delivery_info) {
      fields.forEach((field) => {
        set(newParams, `user.delivery_${field}`, get(newParams, `orders[0].order_items[0].delivery_${field}`));
      });
    }
    setFormParams(newParams);
    ApiHelper.post('/api/v1/checkout/orders', newParams).then((res) => {
      if (res.error) {
        handleError(res.error, setNotification);
        scrollToTop();
        setSubmitDisabled(false);
        setReadOnly(false);
      } else if (res.response) {
        window.location.replace(`/orders/result?order_ids=${res.response.map((o) => o.id).join(',')}`);
      }
    });
  };

  const validateCheckout = ({onSuccess, onError}) => {
    return ApiHelper.post('/api/v1/checkout/validate', formParams).then((res) => {
      if (res.error) {
        handleError(res.error, setNotification);
        scrollToTop();
        setSubmitDisabled(false);

        return onError && onError();
      } else if (res.response) {
        return onSuccess();
      }
    });
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    validateCheckout({onSuccess: () => event.target.submit()});
  };

  const onPaypalButtonClick = (data, actions) => {
    return validateCheckout({
      onSuccess: () => actions.resolve(),
      onError: () => actions.reject(),
    });
  };

  const approvePaypalPayment = (data, actions) => {
    const newFormParams = {...formParams};
    newFormParams.orders.forEach((order) => {
      order.payment_token = data.orderID;
    });
    setFormParams(newFormParams);
    handleSubmit({params: newFormParams});
  };

  const createPaypalOrder = (data, actions) => {
    const purchaseUnits = get(orders, '[0].paypal_options.purchase_units');
    const {
      billing_country,
      billing_street,
      billing_building,
      billing_prefecture,
      billing_city,
      billing_postal_code,
    } = get(formParams, 'orders[0]');

    const shippingInfo = {
      address: {
        country_code: billing_country,
        postal_code: billing_postal_code,
        address_line_1: billing_street,
        address_line_2: billing_building,
        admin_area_1: billing_prefecture,
        admin_area_2: billing_city,
      },
    };

    return actions.order.create({
      purchase_units: purchaseUnits.map((unit) => ({...unit, shipping: shippingInfo})),
    });
  };

  const renderButton = () => {
    if (['stripe', 'invoice'].includes(get(formParams, 'orders[0].payment_method'))) {
      return (
        <button
          type="submit"
          onClick={() => handleSubmit({params: formParams})}
          disabled={submitDisabled}
          className="c-payment-details-footer__payment-button"
        >
          SECURE PAYMENT
        </button>
      );
    } else if (get(formParams, 'orders[0].payment_method') === 'paypal' && isEqual(orderTypes, ['funding'])) {
      return (
        <form
          method="POST"
          action="/orders"
          style={{marginLeft: 'auto'}}
          onSubmit={handleFormSubmit}
        >
          {map(omit(get(formParams, 'orders[0]'), ['order_items']), (value, key) => {
            return (<input type="hidden" name={`order[${key}]`} value={value} />);
          })}
          {map(get(formParams, 'orders[0].order_items[0]'), (value, key) => {
            return (
              <input type="hidden" name={`order[order_items_attributes][0][${key}]`} value={value} />
            );
          })}
          <button type="submit" className="c-payment-details-footer__payment-button">SECURE PAYMENT</button>
        </form>
      );
    } else if (get(formParams, 'orders[0].payment_method') === 'paypal') {
      return (
        <div className="c-paypal-button c-payment-details-footer__paypal-button">
          <div className="c-paypal-button__button">
            <PayPalButton
              style={{
                shape: 'rect',
                layout: 'horizontal',
                tagline: 'false',
                color: 'black',
                size: 'responsive',
                height: 50,
              }}
              options={{clientId: window.constants.paypal_api_client_id, currency: paymentCurrency}}
              onButtonReady={() => setSubmitDisabled(false)}
              onClick={onPaypalButtonClick}
              createOrder={createPaypalOrder}
              onApprove={approvePaypalPayment}
            />
          </div>
          <button
            type="button"
            className="c-paypal-button__bg-button c-payment-details-footer__payment-button"
            disabled={submitDisabled}
          >
            SECURE PAYMENT
          </button>
        </div>
      );
    }
  };

  return (
    <div className="c-payment-details-footer">
      <div className="c-payment-details-footer__total">
        <h3>
          Total payment:&nbsp;
          <span wovn-ignore="">{formatMoney({amount: totalPaymentTotalAmount, currency: paymentCurrency})}</span>
        </h3>
      </div>
      {renderButton()}
    </div>
  );
};
