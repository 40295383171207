/* eslint testing-library/no-container: off */
/* eslint testing-library/no-node-access: off */

import React from 'react';
import {
  render,
  screen,
  waitFor,
  fireEvent,
} from '@testing-library/react';
import {DownloadsPage} from '../index';

const meta = document.createElement('meta');
meta.setAttribute('name', 'csrf-token');
document.head.appendChild(meta);

global.Request = jest.fn(() => {});

describe('when page is loaded with projects', () => {
  global.fetch = jest.fn(() => {
    return Promise.resolve({
      json: () => Promise.resolve([{title: 'New project', artist_name: 'Artist'}]),
      headers: new Headers({'X-Total': 5, 'X-Next-page': 2}),
      ok: true,
    });
  });

  it('renders correct data', async () => {
    render(<DownloadsPage />);

    expect(screen.getByText('Download')).toBeInTheDocument();

    await waitFor(() => {
      expect(screen.getByText('New project')).toBeInTheDocument();
    });

    expect(screen.getByText('5 projects')).toBeInTheDocument();
    expect(screen.getByText('LOAD MORE')).toBeInTheDocument();
  });
});

describe('when there are no projects', () => {
  it('renders correct data', async () => {
    global.fetch = jest.fn(() => {
      return Promise.resolve({
        json: () => Promise.resolve([]),
        headers: new Headers({'X-Total': 0}),
        ok: true,
      });
    });

    render(<DownloadsPage />);

    await waitFor(() => {
      expect(screen.getByText('No projects.')).toBeInTheDocument();
    });

    expect(screen.queryByText('LOAD MORE')).toBeNull();
    expect(screen.getByText('0 projects')).toBeInTheDocument();
  });
});

describe('pagination', () => {
  it('renders correct data', async () => {
    global.fetch = jest.fn(() => {
      return Promise.resolve({
        json: () => Promise.resolve([{title: 'New project 1', artist_name: 'Artist 1'}]),
        headers: new Headers({'X-Total': 2, 'X-Next-page': 2}),
        ok: true,
      });
    });

    render(<DownloadsPage />);

    await waitFor(() => {
      expect(screen.getAllByText('DOWNLOAD PAGE').length).toBe(1);
    });

    expect(screen.getByText('LOAD MORE')).toBeInTheDocument();

    global.fetch = jest.fn(() => {
      return Promise.resolve({
        json: () => Promise.resolve([{title: 'New project 2', artist_name: 'Artist 2'}]),
        headers: new Headers({'X-Total': 2}),
        ok: true,
      });
    });

    fireEvent.click(screen.getByText('LOAD MORE'));

    await waitFor(() => {
      expect(screen.getAllByText('DOWNLOAD PAGE').length).toBe(2);
    });

    expect(screen.queryByText('LOAD MORE')).toBeNull();
  });
});

describe('sorting', () => {
  it('renders correct data', async () => {
    global.fetch = jest.fn(() => {
      return Promise.resolve({
        json: () => Promise.resolve([
          {title: 'New project 1', artist_name: 'Artist 1'},
          {title: 'New project 2', artist_name: 'Artist 2'},
        ]),
        headers: new Headers({'X-Total': 2}),
        ok: true,
      });
    });

    const {container} = render(<DownloadsPage />);

    await waitFor(() => {
      expect(screen.getAllByText('DOWNLOAD PAGE').length).toBe(2);
    });

    expect(
      Array
        .prototype
        .slice
        .call(container.getElementsByClassName('p-purchases-list__title'))
        .map((elem) => elem.innerHTML),
    ).toEqual(['New project 1', 'New project 2']);

    global.fetch = jest.fn(() => {
      return Promise.resolve({
        json: () => Promise.resolve([
          {title: 'New project 2', artist_name: 'Artist 2'},
          {title: 'New project 1', artist_name: 'Artist 1'},
        ]),
        headers: new Headers({'X-Total': 2}),
        ok: true,
      });
    });

    fireEvent.click(screen.getByText('Sort by Title'));

    await waitFor(() => {
      expect(screen.getAllByText('DOWNLOAD PAGE').length).toBe(2);
    });

    expect(
      Array
        .prototype
        .slice
        .call(container.getElementsByClassName('p-purchases-list__title'))
        .map((elem) => elem.innerHTML),
    ).toEqual(['New project 2', 'New project 1']);
  });
});
