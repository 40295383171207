import React, {useState} from 'react';
import {InputField} from 'shared/components/form/input_field/input_field';
import {GroupSection} from '../../group_section/group_section';
import {ScreenContainer} from '../../screen_container/screen_container';

export const FormsScreen = () => {
  const [state, setState] = useState({
    inputField: '',
  });

  return (
    <ScreenContainer title="Forms">
      <GroupSection title="Input Field" type="forms">
        <InputField
          label="Input default"
          value={state.inputField}
          name="input1"
          onChange={(e) => setState({...state, inputField: e.target.value})}
        />
        <InputField
          label="Input read only"
          value="readonly"
          name="input3"
          isReadOnly
          onChange={(e) => setState({...state, inputField: e.target.value})}
        />
        <InputField
          label="Input locked"
          value={state.inputField}
          name="input4"
          isLocked
          onChange={(e) => setState({...state, inputField: e.target.value})}
        />
        <InputField
          label="Input on error"
          value={state.inputField}
          name="input5"
          error
          onChange={(e) => setState({...state, inputField: e.target.value})}
        />
        <InputField
          label="Input on error with message"
          value={state.inputField}
          name="input6"
          error="Error message"
          onChange={(e) => setState({...state, inputField: e.target.value})}
        />
      </GroupSection>
    </ScreenContainer>
  );
};
