import React, {useEffect, useRef, useState} from 'react';
import {BrowserRouter, Switch, Route} from 'react-router-dom';
import Prismic from '@prismicio/client';

import {useLanguageChange} from 'utils/use_language_change';

import {prismicClient} from 'components/prismic/shared/cms/prismic';
import {PrismicRoutes} from 'components/prismic/modules/routes/routes';

import {ServicesTabs} from './components/ServicesTabs/ServicesTabs';
import {ServicesContent} from './components/ServicesContent/ServicesContent';

export const Services = ({isTabs, isAccordion, locale}) => {
  const [servicesList, setServicesList] = useState(null);
  const [servicesItems, setServicesItems] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const mainComponent = useRef(null);

  const scrollToContent = () => {
    const elementPosition = mainComponent.current.offsetTop;
    window.scrollTo({top: elementPosition, behavior: 'smooth'});
  };

  const getArticles = async ({lang}) => {
    const response = await prismicClient.query(Prismic.Predicates.at('document.type', 'services'), {
      orderings: '[my.services.category,my.services.order_inside_category]',
      lang: lang || locale,
    });

    const categoriesList = [];
    const services = [];

    response && response.results.forEach((item) => {
      const category = item.data.category.split('.')[1];

      if (!categoriesList.includes(category)) {
        const servicesItems = response.results.filter((serviceItem) => serviceItem.data.category.split('.')[1] === category);

        services.push({
          name: category,
          items: servicesItems
        });

        categoriesList.push(category);
      }
    });

    setServicesList(services);
    setServicesItems(response.results);
    setIsLoading(false);
  };

  useEffect(() => {
    getArticles({});
  }, []);

  useLanguageChange((event) => {
    const lang = window.constants.prismic_locales[event.detail.locale];
    getArticles({lang});
  });

  if (isLoading) {
    return (
      <div className="c-services c-services--blank" />
    );
  }

  if (isAccordion) {
    return (
      <ServicesTabs className="c-services" isAccordion servicesList={servicesList} />
    );
  }

  if (isTabs) {
    return (
      <div className="c-services" ref={mainComponent}>
        {servicesList && (
          <BrowserRouter>
            <div className="c-services__nav" wovn-ignore="">
              <ServicesTabs isTabs servicesList={servicesList} scrollToContent={scrollToContent} />
            </div>
            <div className="c-services__content">
              <Switch>
                {servicesItems && servicesItems.map((item, index) => {
                  const nextArrowPath = servicesItems[index + 1] && PrismicRoutes.servicesPath(servicesItems[index + 1]?.uid);
                  const prevArrowPath = servicesItems[index - 1] && PrismicRoutes.servicesPath(servicesItems[index - 1]?.uid);
                  return (
                    <Route key={item.id} exact path={PrismicRoutes.servicesPath(item.uid)}>
                      <ServicesContent item={item} nextButton={nextArrowPath} prevButton={prevArrowPath} scrollToContent={scrollToContent} />
                    </Route>
                  );
                })}
              </Switch>
            </div>
          </BrowserRouter>
        )}
      </div>
    );
  }
};
