import React from 'react';

import {formatMoney} from 'utils/format_money';

export const ProjectPrice = ({project, currentUser}) => {
  const {
    currency,
    price,
    wholesale_price,
    selling_condition_markers,
  } = project;

  const priceToDisplay = () => {
    if (!currentUser?.distributor_id) {
      return price;
    } else if (selling_condition_markers?.includes('exclusive')) {
      if (currentUser?.distributor?.markers?.includes('exclusive')) {
        return (wholesale_price);
      } else {
        return price;
      }
    } else {
      return wholesale_price;
    }
  };

  return <>{formatMoney({amount: priceToDisplay(), currency})}</>;
};
