import React from 'react';
import {
  render,
  screen,
  fireEvent,
  waitFor,
} from '@testing-library/react';

import {Team} from '../team';

let mockResults = [
  {
    data: {
      members: [
        {name: [{text: 'Member 1'}], position: [{text: 'Developer'}], photo: {url: 'url_1'}},
        {name: [{text: 'Member 2'}], position: [{text: 'CTO'}], photo: {url: 'url_2'}},
      ],
    },
  },
];

jest.mock('prismic/shared/cms/prismic', () => {
  return {
    prismicClient: {
      query: () => {
        return Promise.resolve({
          results: mockResults,
        });
      },
    },
  };
});

beforeEach(() => {
  window.constants = {prismic_locales: {}};
});

it('renders members', async () => {
  render(<Team />);

  await waitFor(() => {
    expect(screen.getByText('Member 1')).toBeInTheDocument();
  });

  expect(screen.getByText('Member 2')).toBeInTheDocument();
  expect(screen.getByText('Developer')).toBeInTheDocument();
  expect(screen.getByText('CTO')).toBeInTheDocument();
});

describe('with languagechange', () => {
  const event = new CustomEvent('languagechange', {detail: {locale: 'es'}});

  it('replaces data', async () => {
    render(<Team />);

    await waitFor(() => {
      expect(screen.getByText('Member 1')).toBeInTheDocument();
    });

    expect(screen.getByText('Member 2')).toBeInTheDocument();
    expect(screen.getByText('Developer')).toBeInTheDocument();
    expect(screen.getByText('CTO')).toBeInTheDocument();

    mockResults = [
      {
        data: {
          members: [
            {name: [{text: 'Member 3'}], position: [{text: 'QA'}], photo: {url: 'url_3'}},
            {name: [{text: 'Member 4'}], position: [{text: 'Design'}], photo: {url: 'url_4'}},
          ],
        },
      },
    ];

    fireEvent(window, event);

    await waitFor(() => {
      expect(screen.getByText('Member 3')).toBeInTheDocument();
    });

    expect(screen.queryByText('Member 1')).not.toBeInTheDocument();
    expect(screen.queryByText('Member 2')).not.toBeInTheDocument();
    expect(screen.queryByText('Developer')).not.toBeInTheDocument();
    expect(screen.queryByText('CTO')).not.toBeInTheDocument();

    expect(screen.getByText('Member 3')).toBeInTheDocument();
    expect(screen.getByText('Member 4')).toBeInTheDocument();
    expect(screen.getByText('Design')).toBeInTheDocument();
    expect(screen.getByText('QA')).toBeInTheDocument();
  });
});
