import {handleActions} from 'redux-actions';
import Actions from 'project_start/modules/actions';

export const PressPricesReducer = handleActions({
  [Actions.setZeroPrices]: (state, action) => {
    const newState = state;
    newState.zero_prices = action.payload;

    return newState;
  },
}, {});
