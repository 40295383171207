// @flow
import React from 'react';
import styled from 'styled-components';
import {FacebookShareButton, TwitterShareButton} from 'react-share';
import {ReactComponent as IconFacebook} from 'prismic/shared/assets/svgs/facebook.svg';
import {ReactComponent as IconTwitter} from 'prismic/shared/assets/svgs/twitter.svg';
import {StyledMaxWidthContainer} from 'prismic/shared/styles/layout';
import {cssMobileSidePadding} from 'prismic/shared/styles/responsive';
import {breakpointTabletOnly} from 'prismic/shared/styles/responsive';

const StyledContainer = styled(StyledMaxWidthContainer)`
  ${cssMobileSidePadding};
`;

const StyledContent = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 17px;
  border-top: 2px solid #000000;
  margin: 0px 40px 60px;

  ${breakpointTabletOnly} {
    margin: 20px 0px;
    justify-content: flex-end;
  }
`;

const StyledInner = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;

  ${breakpointTabletOnly} {
    width: 100%
    margin-left: 0;
    justify-content: flex-end;
  }
`;

const StyledSocialList = styled.ul`
  display: flex;
  align-items: center;

  > li {
    &:not(:first-child) {
      margin-left: 9px;
    }
  }
`;

const StyledShare = styled.div`
  padding-right: 20px;
  font-size: 20px;
  font-weight: bold;
  line-height: 0.8;
  letter-spacing: 0.23px;
  text-transform: uppercase;
  align-self: center;
  ${breakpointTabletOnly} {
    font-size: 16px;
    padding-right: 10px;
    line-height: 1;
    letter-spacing: -0.44px;
  }
`;

const StyledShareButton = styled.div`
  display: inline-block;
  border: 0;
  background: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  cursor: pointer;
  padding: 0;
  ${breakpointTabletOnly} {
    width: 30px;
    height: 30px;
  }

  svg {
    width: 40px !important;
    height: 40px !important;
    ${breakpointTabletOnly} {
      width: 30px !important;
      height: 30px !important;
    }
  }
`;

type Props = {
  slug: string,
};

const ShareStory = ({ slug }: Props) => {
  const shareUrl = `https://qrates.com/stories/${slug}`;
  return (
    <StyledContainer>
      <StyledContent>
        <StyledInner>
          <StyledShare wovn-ignore="">Share</StyledShare>
          <StyledSocialList>
            <li>
              <StyledShareButton>
                <FacebookShareButton url={shareUrl}>
                  <IconFacebook />
                </FacebookShareButton>
              </StyledShareButton>
            </li>
            <li>
              <StyledShareButton>
                <TwitterShareButton url={shareUrl}>
                  <IconTwitter />
                </TwitterShareButton>
              </StyledShareButton>
            </li>
          </StyledSocialList>
        </StyledInner>
      </StyledContent>
    </StyledContainer>
  );
};

export default ShareStory;
