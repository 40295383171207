import React from 'react';
import styled from 'styled-components';
import {RichText} from 'prismic-reactjs';
import {StyledLinkWrapper} from '../ArtistProfile/ArtistProfile';
import {FONT_WEIGHTS} from '../../styles/fonts';
import {StyledLink} from '../../../screens/StoryScreen/components/RelatedStories/RelatedStories';
import {
  getSliceFeaturedProjectLabel,
  getSliceFeaturedProjectProject,
} from '../../cms/story';
import {breakpointTabletOnly, cssMobileSidePadding} from '../../styles/responsive';
import {
  getProjectArtistName,
  getProjectRenderedImage,
  getProjectTitle,
  getProjectUrl,
} from '../../cms/project';
import {cssStoryType} from '../../../screens/StoriesScreen/components/StoryPreview/StoryPreview';

const StyledContainer = styled.div`
  ${cssMobileSidePadding};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 60px 0px 0px;
  margin: 0px 40px 100px;
  border-top: 2px solid #000000;
  ${breakpointTabletOnly} {
    margin: 0px 15px 40px;
    padding: 40px 0px 0px;
  }
`;

const StyledImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
`;

const StyledImageContainer = styled.div`
  position: relative;
  width: 660px;
  object-fit: cover;
  z-index: 8;
  &::before {
    content: "";
    display: block;
    padding-bottom: 60%;
  }
`;
const StyledImageContents = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: unset;
  background-image: url(${props => props.image || ''});
  background-position: center center;
  background-size: 100% auto;
`;

const StyledLabel = styled.div`
  ${cssStoryType};
  margin: 0px 0px;
`;

const StyledInfo = styled.div`
  margin-top: 50px;
  text-align: center;
  ${breakpointTabletOnly} {
    margin-top: 5%;
  }

  h4 {
    font-size: 28px;
    letter-spacing: -0.3px;
    line-height: 34px;
    font-weight: ${FONT_WEIGHTS.bold};
    ${breakpointTabletOnly} {
      font-size: 22px;
      letter-spacing: -0.37px;
      line-height: 26px;
    }
    strong {
      font-weight: ${FONT_WEIGHTS.bold};
    }
  }
`;

export const FeaturedProject = ({slice}) => {
  const project = getSliceFeaturedProjectProject(slice);
  return (
    <StyledContainer>
      <StyledLabel>
        <span wovn-ignore="">
          {RichText.asText(getSliceFeaturedProjectLabel(slice)) || 'FEATURED PROJECT'}
        </span>
      </StyledLabel>
      <StyledImageWrapper>
        <StyledImageContainer>
          <StyledImageContents image={getProjectRenderedImage(project)} />
        </StyledImageContainer>
      </StyledImageWrapper>
      <StyledInfo>
        <h4 wovn-ignore="">
          {getProjectArtistName(project)} / {getProjectTitle(project)} is now available on
          Qrates.
        </h4>
        <StyledLinkWrapper>
          <StyledLink
            as="a"
            mobileCondensed
            href={getProjectUrl(project)}
            target="_blank"
            rel="noreferrer noopener"
          >
            View Project
          </StyledLink>
        </StyledLinkWrapper>
      </StyledInfo>
    </StyledContainer>
  );
};
