import React from 'react';
import {RichText} from 'prismic-reactjs';
import {ArtistDetailsArrow} from '../arrow/arrow';

export const StepsMobile = ({details}) => {
  return details?.map((step) => (
    <div className="c-for-artists-details-mobile__info-container" key={step?.number}>
      <ArtistDetailsArrow number={step?.number} />
      <div className="c-for-artists-details-mobile__info">
        <span wovn-ignore="">
          <RichText render={step?.process} />
        </span>
      </div>
    </div>
  ));
};
