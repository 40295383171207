import React from 'react';
import classNames from 'classnames';

export const CuratedHeading = ({heading, hideAt}) => (
  <h1
    className={classNames('qrates-curated-page__heading', {
      'qrates-curated-page__heading-hidden': hideAt === 'tablet',
    })}
    wovn-ignore=""
  >
    {heading}
  </h1>
);
