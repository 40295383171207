// @flow
import React, {useState} from 'react';
import styled from 'styled-components';
import {StyledArticleMaxWidthContainer} from 'prismic/shared/styles/layout';
import {cssMediumTextSize} from 'prismic/shared/styles/typography';
import {breakpointDesktopOnly, breakpointTabletOnly} from 'prismic/shared/styles/responsive';
import type {ArticleSliceMdl} from 'prismic/shared/cms/article';
import {ArticleHeader} from '../ArticleHeader/ArticleHeader';
import {AlbumEmbed} from 'prismic/shared/slices/AlbumEmbed/AlbumEmbed';
import {ArticleLayoutWrapper} from '../ArticleLayoutWrapper/ArticleLayoutWrapper';
import {getStorySlices, isSliceHalfSize} from 'components/prismic/shared/cms/story';
import {getArticleSliceTable, getArticleTags} from 'prismic/shared/cms/article';
import {ArtistProfile} from 'prismic/shared/slices/ArtistProfile/ArtistProfile';
import {Embed} from 'prismic/shared/slices/Embed/Embed';
import {Table} from 'prismic/shared/slices/Table/Table';
import {Divider} from 'prismic/shared/slices/Divider/Divider';
import {Footer} from 'prismic/shared/slices/Footer/Footer';
import {FeaturedQuote} from 'prismic/shared/slices/FeaturedQuote/FeaturedQuote';
import {FeaturedList} from 'prismic/shared/slices/FeaturedList/FeaturedList';
import {FeaturedImages} from 'prismic/shared/slices/FeaturedImages/FeaturedImages';
import {FeaturedProject} from 'prismic/shared/slices/FeaturedProject/FeaturedProject';
import {RenderRichText} from 'prismic/shared/slices/RichText/RichText';
import {HalfEmbed} from 'prismic/shared/slices/HalfEmbed/HalfEmbed';
import {HalfImage} from 'prismic/shared/slices/HalfImage/HalfImage';
import {HalfHeading} from 'prismic/shared/slices/HalfHeading/HalfHeading';
import {HalfTable} from 'prismic/shared/slices/HalfTable/HalfTable';
import {useLanguageChange} from 'utils/use_language_change';

const StyledContainer = styled.section``;

const StyledContent = styled(StyledArticleMaxWidthContainer)`
  ${breakpointDesktopOnly} {
    display: flex;
    width: 100%;
    column-gap: 40px;
  }
`;

const StyledMain = styled.div`
  ${cssMediumTextSize};
  h1,
  h2,
  h3,
  h4,
  p {
    a {
      color: inherit;
    }
  }

  ${breakpointDesktopOnly} {
    flex: 1 0 50%;
  }

  ${breakpointTabletOnly} {
    flex: 1 0 100%;
  }
`;

type Props = {
  article: ArticleSliceMdl[],
  articleType: string | undefined,
};

export const ArticleBody = ({ article, articleType }: Props) => {
  const [record, setRecord] = useState(article);
  const isArticle = articleType === 'article';
  const renderBodies = () => {
    const bodies = [];
    let group = [];

    const slices = getStorySlices(record);
    const isHalfSize = true;

    const tags = getArticleTags(article);

    const addGroupElements = (index: number) => {
      if (group.length > 0) {
        bodies.push(<ArticleLayoutWrapper index={index} key={`GROUP ${index.toString()}`}>{group}</ArticleLayoutWrapper>);
        group = [];
      }
    };

    const addElementToGroup = (element: any, index: number) => {
      group.push(element);
    };

    const addElementToBody = (element: any, index: number) => {
      addGroupElements(index - 1);
      bodies.push(element);
    };

    const addSliceElement = (slice: StoryBodySlice, element: any, index: number, isHalfSize?: boolean) => {
      if (isSliceHalfSize(slice) || isHalfSize) {
        addElementToGroup(element, index);
      } else {
        addElementToBody(element, index);
      }
    };

    slices.forEach((slice: StoryBodySlice, index) => {
      let element;
      switch (slice.slice_type) {
        case 'article_intro':
          element = <ArticleHeader slice={slice} tags={tags} key={index.toString()} />;
          addSliceElement(slice, element, index);
          break;
        case 'embedded_album':
          element = <AlbumEmbed slice={slice} key={index.toString()} />;
          addSliceElement(slice, element, index);
          break;
        case 'rich_text':
          element = <RenderRichText slice={slice} isArticle={isArticle} key={index.toString()} />;
          addSliceElement(slice, element, index, isHalfSize);
          break;
        case 'featured_images':
          element = <FeaturedImages slice={slice} key={index.toString()} />;
          addSliceElement(slice, element, index);
          break;
        case 'featured_quote':
          element = <FeaturedQuote slice={slice} key={index.toString()} />;
          addSliceElement(slice, element, index);
          break;
        case 'featured_list':
          element = <FeaturedList slice={slice} key={index.toString()} />;
          addSliceElement(slice, element, index);
          break;
        case 'half_image':
          element = <HalfImage slice={slice} key={index.toString()} />;
          addSliceElement(slice, element, index, isHalfSize);
          break;
        case 'half_embed':
          element = <HalfEmbed slice={slice} key={index.toString()} />;
          addSliceElement(slice, element, index, isHalfSize);
          break;
        case 'half_heading':
          element = <HalfHeading slice={slice} key={index.toString()} />;
          addSliceElement(slice, element, index, isHalfSize);
          break;
        case 'half_table':
          element = <HalfTable html={getArticleSliceTable(slice)} slice={slice} key={index.toString()} />;
          addSliceElement(slice, element, index, isHalfSize);
          break;
        case 'footer___credits':
          element = <Footer slice={slice} key={index.toString()} />;
          addSliceElement(slice, element, index);
          break;
        case 'artist_profile':
          element = <ArtistProfile slice={slice} key={index.toString()} />;
          addSliceElement(slice, element, index);
          break;
        case 'featured_project':
          element = <FeaturedProject slice={slice} key={index.toString()} />;
          addSliceElement(slice, element, index);
          break;
        case 'embed':
          element = <Embed slice={slice} key={index.toString()} />;
          addSliceElement(slice, element, index);
          break;
        case 'table':
          element = <Table html={getArticleSliceTable(slice)} key={index.toString()} />;
          addSliceElement(slice, element, index);
          break;
        case 'divider':
          element = <Divider key={index.toString()} />;
          addSliceElement(slice, element, index);
          break;
        default:
          console.warn(`no match for ${slice.slice_type}`);
          break;
      }
    });

    addGroupElements(slices.length);

    return bodies;
  };

  useLanguageChange((event) => {
    const locale = window.constants?.prismic_locales?.[event.detail.locale];
    const options = {
      lang: locale,
      fetchLinks: [
        'story.preview_heading',
        'story.preview_image',
        'story.preview_pre_heading',
        'story.preview_text',
        'story.published_date',
        'story.story_category'
      ],
    };
    const queries = [
      Prismic.Predicates.at('document.type', 'article'),
      Prismic.Predicates.at('my.article.uid', slug),
    ];
    prismicClient.query(queries, options).then((response) => {
      const result = response.results[0];
      result && setRecord(result);
    });
  });

  if (!record) {
    return <div>No story found.</div>;
  }

  return (
    <StyledContainer>
      <StyledContent>
        <StyledMain>
          <div wovn-ignore="">
            {renderBodies()}
          </div>
        </StyledMain>
      </StyledContent>
    </StyledContainer>
  )
};
