import React, {useState, useEffect} from 'react';

import Prismic from '@prismicio/client';
import {prismicClient} from 'prismic/shared/cms/prismic';

import {useLanguageChange} from 'utils/use_language_change';

import {QratesStudioSliderBanner} from 'public/components/qrates_studio_slider_banner/qrates_studio_slider_banner';
import {CommunitySection} from 'public/components/community_section/community_section';
import {ArtistsOnQrates} from '../../../../components/pages/artists_on_qrates/artists_on_qrates';
import {ForArtistsIntroSection} from './intro_section/for_artists_intro_section';
import {ForArtistsFormatsSection} from './formats_section/for_artists_formats_section';
import {ForArtistsDetailsSection} from './details_section/for_artists_details_section';
import {ForArtistsResourcesSection} from './artist_resources_section/artist_resources_section';
import {ErrorFallbackSection} from '../../components/error_fallback_section/ErrorFallbackSection';
import {LoadingSection} from '../../components/loading_section/LoadingSection';

export const ForArtistsPage = () => {
  const [contents, setContents] = useState([]);
  const [communityData, setCommunityData] = useState([]);
  const [artistsData, setArtistsData] = useState([]);
  const [sliderData, setSliderData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const queries = [
    Prismic.Predicates.at('document.type', 'for_artists'),
    Prismic.Predicates.at('document.type', 'community_section'),
    [
      Prismic.Predicates.at('document.type', 'qrates_artist'),
      Prismic.Predicates.at('my.qrates_artist.page', 'why'),
    ],
    Prismic.Predicates.at('document.type', 'qrates_studio_section'),
  ];

  const queryPrismic = async (options) => {
    setIsLoading(true);
    const locales = window.constants.prismic_locales;
    const promises = queries.map((spec) => prismicClient.query(spec, {lang: locales[options.locale || window.constants.locale || 'en']}));
    const response = await Promise.all(promises);
    if (response) {
      setContents(response[0].results[0].data);
      setCommunityData(response[1].results[0].data);
      setArtistsData(response[2].results.map((result) => result.data));
      setSliderData(response[3].results[0].data);
    }
  };

  const fetchContents = async (options = {}) => {
    try {
      return await queryPrismic(options);
    } catch (err) {
      setIsError(true);
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchContents();
  }, []);

  useLanguageChange((event) => {
    const locale = event.detail.locale;
    fetchContents({locale});
  });

  if (isLoading) return <LoadingSection />;
  if (isError) return <ErrorFallbackSection />;

  return (
    <>
      <ForArtistsIntroSection
        forArtistsLabel={contents.for_artists_label}
        forArtistsTitle={contents.for_artists_title}
        forArtistsText={contents.for_artists_text}
        forArtistsImage1={contents.for_artists_image_1}
        forArtistsImage2={contents.for_artists_image_2}
      />
      <ForArtistsFormatsSection
        formatsTitle={contents?.formats_title}
        formats={contents.formats}
      />
      <ForArtistsDetailsSection
        detailsTitle={contents.processes_title}
        detailsTitleMobile={contents.processes_title_mobile}
        detailsLabelArtist={contents.processes_artist_label}
        detailsLabelQrates={contents.processes_qrates_label}
        detailsIntroTitleArtist={contents.processes_artist_title}
        detailsIntroTitleQrates={contents.processes_qrates_title}
        detailsIntroTextArtist={contents.processes_artist_text}
        detailsIntroTextQrates={contents.processes_qrates_text}
        detailsDesktop={contents.processes_desktop}
        detailsMobileArtist={contents.processes_mobile_artists}
        detailsMobileQrates={contents.processes_mobile_qrates}
        detailsMobileArtistFinal={contents.processes_mobile_artists_final}
        detailsCTAText={contents.processes_cta_text}
        detailsCTALabel={contents.processes_cta_button_label}
      />
      <ForArtistsResourcesSection panels={contents.panels} />
      <CommunitySection
        label={communityData.label}
        title={communityData.title[0].text}
        description={communityData.text}
        link={{
          name: communityData.button_label,
          url: communityData.href,
        }}
      />
      <ArtistsOnQrates artistsData={artistsData} />
      <QratesStudioSliderBanner sliderData={sliderData} />
    </>
  );
};
