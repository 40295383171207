import {filterArtworkByType} from 'utils/files';
import {assetPath} from 'project_start/components/utils/visualizer/assets_helper';

import VinylLabelImage from 'images/press/spec/texture_label_color.png';
import VinylSleeveImage from 'images/press/spec/texture_sleeve.png';
import CassetteASideImage from 'images/press/spec/default_shell_label_paper_a.png';
import CassetteBSideImage from 'images/press/spec/default_shell_label_paper_b.png';
import CassetteCaseImage from 'images/press/spec/default_case_card.png';
import CassetteCaseSpineImage from 'images/press/spec/default_case_spine.png';

export const visualizerAssets = ({artworkFiles, productType}) => {
  const whitePixelImage = assetPath('qrates_visualizer/white_pixel.png', window.constants.manifest);
  const fileTypes = {
    vinyl: [
      {asset: 'sleeveFrontImageUrl', type: 'front_sleeve_image', default: VinylSleeveImage},
      {asset: 'sleeveBackImageUrl', type: 'back_sleeve_image', default: VinylSleeveImage},
      {asset: 'sleeveSpineImageUrl', type: 'spine_sleeve_image', default: whitePixelImage},
      {asset: 'sleeveInnerImageUrl', type: 'inner_sleeve_image', default: VinylSleeveImage},
      {asset: 'sleeveGatefoldInnerImageUrl', type: 'inner_sleeve_image', default: whitePixelImage},
      {asset: 'labelAImageUrl', type: 'a_label_image', default: VinylLabelImage},
      {asset: 'labelBImageUrl', type: 'b_label_image', default: VinylLabelImage},
      {asset: 'labelCImageUrl', type: 'c_label_image', default: VinylLabelImage},
      {asset: 'labelDImageUrl', type: 'd_label_image', default: VinylLabelImage},
      {asset: 'vinylATextureUrl', type: 'a_vinyl_texture', default: VinylLabelImage},
      {asset: 'vinylBTextureUrl', type: 'b_vinyl_texture', default: VinylLabelImage},
      {asset: 'vinylCTextureUrl', type: 'c_vinyl_texture', default: VinylLabelImage},
      {asset: 'vinylDTextureUrl', type: 'd_vinyl_texture', default: VinylLabelImage},
    ],
    cassette: [
      {asset: 'labelASideImageUrl', type: 'a_label_image', default: CassetteASideImage},
      {asset: 'labelBSideImageUrl', type: 'b_label_image', default: CassetteBSideImage},
      {asset: 'cardFrontImageUrl', type: 'front_inner_card_image', default: CassetteCaseImage},
      {asset: 'cardBackImageUrl', type: 'back_inner_card_image', default: whitePixelImage},
      {asset: 'cardSpineImageUrl', type: 'spine_inner_card_image', default: CassetteCaseSpineImage},
      {asset: 'cardInsideImageUrl', type: 'inside_inner_card_image', default: whitePixelImage},
      {asset: 'paperCaseFrontImageUrl', type: 'front_paper_case_image', default: CassetteCaseImage},
      {asset: 'paperCaseBackImageUrl', type: 'back_paper_case_image', default: whitePixelImage},
      {asset: 'paperCaseFrontSpineImageUrl', type: 'front_spine_paper_case_image', default: CassetteCaseSpineImage},
      {asset: 'paperCaseBackSpineImageUrl', type: 'back_spine_paper_case_image', default: whitePixelImage},
      {asset: 'outerSleeveFrontImageUrl', type: 'front_outer_sleeve_image', default: CassetteCaseImage},
      {asset: 'outerSleeveBackImageUrl', type: 'back_outer_sleeve_image', default: whitePixelImage},
      {asset: 'outerSleeveFrontSpineImageUrl', type: 'front_spine_outer_sleeve_image', default: CassetteCaseSpineImage},
      {asset: 'outerSleeveBackSpineImageUrl', type: 'back_spine_outer_sleeve_image', default: whitePixelImage},
    ],
  };

  const getFileByType = (type) => {
    return filterArtworkByType({type, artworkFiles})?.uploaded_file?.url;
  };

  const assets = () => {
    const results = {};

    fileTypes[productType]?.forEach((type) => {
      results[type.asset] = getFileByType(type.type) || type.default;
    });

    return results;
  };

  return assets();
};
