import React, {useState, useEffect, useCallback} from 'react';
import classNames from 'classnames';
import {debounce} from 'lodash';

import {formatMoney} from 'utils/format_money';
import ApiHelper from 'utils/api_helper';
import {shippingCondition} from 'utils/shipping_condition';
import {PriceCurrencyInput} from 'shared/components/price_currency_input/price_currency_input';

export const CartItemScreen = ({
  cartItem,
  updateCartItems,
  setIsLoading,
  theme,
  isReadonly,
}) => {
  const endpoint = `/api/v1/cart_items/${cartItem.id}`;
  const [projectDetails, setProjectDetails] = useState({});
  const remainingDaysFunding = projectDetails?.selling_condition?.left_days;
  const baseFundingQuantity = projectDetails?.selling_condition?.base_quantity;
  const orderedFundingQuantity = projectDetails?.selling_condition?.ordered_quantity;
  const [removeButtonDisabled, setRemoveButtonDisabled] = useState(false);
  const [formParams, setFormParams] = useState({
    named_price: cartItem.named_price,
    quantity: cartItem.quantity,
  });
  const getDetails = () => {
    const searchParams = new URLSearchParams();
    searchParams.append(
      'except_attributes',
      [
        'project_card_image_url',
        'rendered_image_url',
        'sleeve_thumbnail_image_url',
        'vinyl_thumbnail_image_url',
        'vinyl2_thumbnail_image_url',
        'cassette_thumbnail_image_url',
        'case_thumbnail_image_url',
        'item',
      ],
    );
    ApiHelper.get(`/api/v1/projects/public/${cartItem.project_id}?${searchParams}`).then((res) => {
      setProjectDetails(res.response);
    });
  };

  useEffect(getDetails, [cartItem]);

  const updateCartItem = ({formParams}) => {
    setIsLoading && setIsLoading(true);
    ApiHelper.patch(endpoint, formParams).then((res) => {
      updateCartItems(res.response);
      setIsLoading && setIsLoading(false);
      window.dispatchEvent(new Event('add_cart_item'));
    });
  };

  const removeCartItem = () => {
    setRemoveButtonDisabled(true);
    const confirm = window.confirm('Are you sure you want to remove this item from your cart?');
    if (confirm) {
      setIsLoading && setIsLoading(true);
      ApiHelper.delete(endpoint).then((res) => {
        updateCartItems(res.response.cart_items);
        setRemoveButtonDisabled(false);
      }).finally(() => {
        setIsLoading && setIsLoading(false);
        setRemoveButtonDisabled(false);
        window.dispatchEvent(new Event('add_cart_item'));
      });
    } else {
      setRemoveButtonDisabled(false);
    }
  };

  const debouncedUpdateCartItem = useCallback(debounce(updateCartItem, 500), []);

  const variateQuantity = ({destination}) => {
    const variations = {
      increment: formParams.quantity + 1,
      decrement: formParams.quantity - 1,
    };
    const newQuantity = variations[destination];
    if (newQuantity > 0) {
      const newFormParams = {...formParams, quantity: newQuantity};
      setFormParams(newFormParams);
      debouncedUpdateCartItem({formParams: newFormParams});
    } else {
      removeCartItem();
    }
  };

  const updateAttribute = (event) => {
    const value = Number(event.target.value || 0);
    const newFormParams = {...formParams, [event.target.name]: value};
    setFormParams(newFormParams);
    debouncedUpdateCartItem({formParams: newFormParams});
  };

  const renderPriceInput = () => {
    if (cartItem.is_self_purchase || cartItem.is_wholesale) {
      return (
        <div className="p-purchases-list__price-box p-purchases-list__readonly-number">
          {formatMoney({amount: cartItem.available_price, currency: cartItem.currency})}
        </div>
      );
    } else {
      return (
        // TODO: Refactor styles
        <div className="p-purchases-list__input-price-box">
          <PriceCurrencyInput
            price={formParams.named_price}
            currency={cartItem.currency}
            onChangePrice={(price) => updateAttribute({target: {value: price, name: 'named_price'}})}
            className={classNames('c-input-addon--front--border', {'c-input--error': cartItem.errors.error?.named_price})}
            inputClassName="c-input-addon__input--number"
            step={cartItem.currency === 'JPY' ? 1 : 0.01}
            size={7}
          />
        </div>
      );
    }
  };

  const renderQuantityInput = () => {
    return (
      <div className={classNames('p-purchases-list__number-changer', {'c-input--error': cartItem.errors.error?.quantity})}>
        <i
          className="c-pictogram c-pictogram--minus"
          onClick={() => variateQuantity({destination: 'decrement'})}
        />
        <input
          type="number"
          name="quantity"
          value={formParams.quantity}
          maxLength={3}
          min={0}
          step={1}
          onChange={updateAttribute}
          className="c-input u-text_center"
        />
        <i
          className="c-pictogram c-pictogram--plus"
          onClick={() => variateQuantity({destination: 'increment'})}
        />
      </div>
    );
  };

  const renderPrice = () => {
    if (cartItem.is_self_purchase || cartItem.is_wholesale) {
      return cartItem.available_price;
    } else {
      return formParams.named_price;
    }
  };

  const renderReadonlyInfo = () => {
    return (
      <div className="c-cart-item__price-info">
        <div className="c-cart-item__price-info-quantity">{formParams.quantity}</div>
        <div className="c-cart-item__price-info-price">
          {formatMoney({amount: renderPrice(), currency: cartItem.currency})}
        </div>
      </div>
    );
  };

  const renderStatus = () => {
    let statusText = '';
    const daysLeftText = `${remainingDaysFunding ?? '...'} DAYS LEFT`;
    const unitsFunded = `${orderedFundingQuantity ?? '-'}/${baseFundingQuantity ?? '-'}`;

    if (cartItem.is_sell_catalog) {
      statusText = 'IN STOCK';
    } else if (cartItem.is_preorder) {
      statusText = 'PRE-ORDER';
    } else if (cartItem.is_funding) {
      statusText = 'CROWDFUNDING';
    }

    if (!cartItem.is_onsale) {
      statusText = cartItem.is_funding ? 'FINISHED' : 'SOLD OUT';
    }

    if (cartItem.is_funding && cartItem.is_onsale) {
      return (
        <>
          <span className="c-cart-item__unit">{unitsFunded}</span>
          <span className="c-cart-item__days-left" data-testid="days-left">{daysLeftText}</span>
        </>
      );
    } else {
      return (
        <>
          <span className="p-purchases-list__status c-cart-item__status">{statusText}</span>
          <span className="c-cart-item__horizontal-divider" />
          <span>{shippingCondition(cartItem)}</span>
        </>
      );
    }
  };

  const renderMoveAction = () => {
    const [actionDisabled, setActionDisabled] = useState(false);

    const stateData = {
      active: {
        title: 'CHECKOUT LATER',
        endpoint: `${endpoint}/pend`,
      },
      pending: {
        title: 'MOVE TO CART',
        endpoint: `${endpoint}/activate`,
      },
    };

    const handleAction = () => {
      setActionDisabled(true);
      ApiHelper.patch(stateData[cartItem.state]?.endpoint).then((res) => {
        updateCartItems(res.response);
      }).finally(() => {
        setActionDisabled(false);
      });
    };

    return (
      <span
        className={classNames('c-cart-item__action', {disabled: actionDisabled})}
        onClick={handleAction}
      >
        {stateData[cartItem.state]?.title}
      </span>
    );
  };

  return (
    <div className={classNames('c-cart-item', {'c-cart-item--payment-details-screen': theme === 'payment_details_screen'})}>
      <div
        className={classNames(
          'p-purchases-list--cart c-cart-item__content',
          {'p-purchases-list--soldout': !cartItem.can_order},
        )}
        key={cartItem.id}
      >
        <div className="p-purchases-list__image">
          <a href={`/projects/${cartItem.project_id}`} target="_blank">
            <img src={cartItem.thumbnail_image_url} />
          </a>
        </div>
        <div className="p-purchases-list__info">
          <div className="p-purchases-list__title" wovn-ignore="">
            {cartItem.project_title}
          </div>
          <div className="p-purchases-list__name" wovn-ignore="">
            {cartItem.artist_name}
          </div>
          <div className="p-purchases-list__date">
            <span>{renderStatus()}</span>
          </div>
        </div>
        <div
          className={classNames('p-purchases-list__items u-hidden-tablet', {
            'u-hidden-desktop-only': theme === 'payment_details_screen',
          })}
        >
          <div className="c-grid--end">
            {cartItem.can_order && (
              <>
                {!isReadonly && renderQuantityInput()}
                {!isReadonly && renderPriceInput()}
                {isReadonly && renderReadonlyInfo()}
              </>
            )}
          </div>
          <div className="c-grid--end c-cart-item__actions">
            {renderMoveAction()}
            <span
              className={classNames('c-cart-item__action', {disabled: removeButtonDisabled})}
              onClick={removeCartItem}
            >
              REMOVE
            </span>
          </div>
        </div>
        <div
          className={classNames('p-purchases-list__number-changer--box', {
            'u-hidden-desktop-only': theme !== 'payment_details_screen',
          })}
        >
          {!isReadonly && renderQuantityInput()}
          {!isReadonly && renderPriceInput()}
          {isReadonly && renderReadonlyInfo()}
        </div>
      </div>
      <div
        className={classNames(
          'c-grid--end c-cart-item__actions u-hidden-desktop-only c-cart-item__bottom-actions',
          {'u-hidden-tablet': cartItem.state !== 'pending'},
        )}
      >
        {renderMoveAction()}
        <span
          className={classNames('c-cart-item__action', {disabled: removeButtonDisabled})}
          onClick={removeCartItem}
        >
          REMOVE
        </span>
      </div>
    </div>
  );
};
