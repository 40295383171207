import React, {useState} from 'react';
import ApiHelper from 'utils/api_helper';
import {Notification} from 'shared/components/notification';
import {handleError, clearValidationErrors} from 'utils/validation';

export const PasswordModal = ({onClose, user}) => {
  const path = '/account/update_password';
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [notification, setNotification] = useState(null);
  const [section, setSection] = useState('form');

  const onSubmit = () => {
    setButtonDisabled(true);
    clearValidationErrors(setNotification);
    const formParams = {
      user: {
        current_password: currentPassword, password: newPassword, password_confirmation: confirmPassword,
      },
    };
    ApiHelper.patch(path, formParams).then((response) => {
      setCurrentPassword('');
      setNewPassword('');
      setConfirmPassword('');
      if (response.error) {
        handleError(response.error, setNotification, 'tableField');
        setButtonDisabled(false);

        return;
      }

      setNotification(null);
      setSection('form_sent');
    });
  };

  const clickContinue = (e) => {
    e.preventDefault();
    onClose();
  };

  const changedSection = (
    <>
      <div className="c-dashboard__heading">Password changed</div>
      <span className="c-close--modal" onClick={onClose}>
        <a>&times;</a>
      </span>
      <p className="c-login__explanation">Your password has been successfully changed.</p>
      <a className="c-button c-button--black c-button--full" onClick={clickContinue}>CONTINUE</a>
    </>
  );

  const formSection = (
    <>
      <div className="c-dashboard__heading">Change password</div>
      <span className="c-close--modal" onClick={onClose}>
        <a>&times;</a>
      </span>
      <p className="c-login__explanation">{`Your email address: ${user.email}`}</p>
      {notification && (
        <div className="c-login__error">
          <Notification type="error">
            {notification.message}
          </Notification>
        </div>
      )}
      <div className="p-form-group--table">
        <div className="p-form-group--table__row">
          <div className="p-form-group--table__label">
            <label htmlFor="current_password">Current password</label>
          </div>
          {user.encrypted_password && (
            <input
              type="password"
              name="current_password"
              placeholder="Current Password"
              className="c-input"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
            />
          )}
          {!user.encrypted_password && <p>Not set</p>}
        </div>
        <div className="p-form-group--table__row">
          <div className="p-form-group--table__label">
            <label htmlFor="new_password">New password</label>
          </div>
          <input
            type="password"
            name="password"
            placeholder="New Password"
            className="c-input"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
        </div>
        <div className="p-form-group--table__row">
          <div className="p-form-group--table__label">
            <label htmlFor="confirm_password">Confirm password</label>
          </div>
          <input
            type="password"
            name="password_confirmation"
            placeholder="Confirm Password"
            className="c-input"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </div>
      </div>

      <button
        type="button"
        className="c-button c-button--black c-button--full"
        onClick={onSubmit}
        disabled={buttonDisabled}
      >
        CHANGE PASSWORD
      </button>
    </>
  );
  return (
    <div className="p-modal__content">
      <div className="p-modal__inner">
        <div className="p-modal__section">
          {section === 'form' && formSection}
          {section === 'form_sent' && changedSection}
        </div>
      </div>
    </div>
  );
};
