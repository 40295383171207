import {Record} from 'immutable';
import Vinyl from './vinyl';
import Cassette from './cassette';

const _Record = Record({
  index: 0,
  num_of_vinyls: 1,
  base_quantity: 100,
  quantity: 100,

  genre: null,

  // Vinyl

  artwork: null,
  rpm: 33,
  vinyl_size: 'vinyl_size_12',
  vinyls: [new Vinyl({number: 1, quantity: 100})],
  vinyl_hole_size: 'vinyl_hole_size_small',
  packaging_type: 'packaging_print',
  sleeve_type: 'sleeve_type_print_3mm_without_hole',
  innersleeve_type: 'innersleeve_type_paper_white',
  finish: 'sleeve_extra_finish_lamination_matt',
  packaging: 'sleeve_extra_packaging_overbag',

  label_type: null,
  testpress: false,
  mastering: 'mastering_none',
  mastering_sub: null,
  mastering_tracks: 0,
  digital: 'digital_none',
  insert: null,
  sticker: null,
  numbering: null,
  numbering_format: null,
  numbering_color: null,
  obi: null,

  loud_cut: null,
  silence: null,

  // Cassette

  tape_length: null,
  cassettes: [new Cassette({number: 1, quantity: 100})],
  case_type: 'case_type_plastic_clear',
  inner_card: 'inner_card_3_panel_j_card',
  outer_sleeve: 'outer_sleeve_none',
  demo: 'demo_5',
}, 'PressSpecRecord');

export default class ProjectSpec extends _Record {
  static fromJSON(json) {
    if (json) {
      json.vinyls = json.vinyls?.map((vinyl, index) => new Vinyl({...vinyl, number: index + 1}));
      json.cassettes = json.cassettes?.map((cassette, index) => new Cassette({...cassette, number: index + 1}));

      switch (json.sleeve_type) {
        case 'sleeve_type_discobag_white':
        case 'sleeve_type_discobag_black':
          json.packaging_type = 'packaging_discobag';
          break;
        case 'sleeve_type_pvc':
          json.packaging_type = 'packaging_pvc';
          break;
        case 'sleeve_type_gatefold':
          json.packaging_type = 'packaging_gatefold';
          break;
        default:
          json.packaging_type = 'packaging_print';
      }

      return new ProjectSpec(json);
    } else {
      return new ProjectSpec();
    }
  }
}
