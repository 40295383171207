import { css } from 'styled-components';

export const FONT_WEIGHTS = {
  light: 300,
  regular: 400,
  medium: 500,
  pre_bold: 700,
  bold: 800,
  black: 900,
};
export const cssFonts = css`
  @font-face {
    font-family: 'Qrates Basis';
    src: url('/assets/fonts/QratesBasis-Black.eot');
    src: url('/assets/fonts/QratesBasis-Black.eot?#iefix') format('embedded-opentype'),
      url('/assets/fonts/QratesBasis-Black.woff') format('woff'),
      url('/assets/fonts/QratesBasis-Black.ttf') format('truetype');
    font-weight: ${FONT_WEIGHTS.black};
    font-style: normal;
  }
  @font-face {
    font-family: 'Qrates Basis';
    src: url('/assets/fonts/QratesBasis-Bold.eot');
    src: url('/assets/fonts/QratesBasis-Bold.eot?#iefix') format('embedded-opentype'),
      url('/assets/fonts/QratesBasis-Bold.woff') format('woff'),
      url('/assets/fonts/QratesBasis-Bold.ttf') format('truetype');
    font-weight: ${FONT_WEIGHTS.bold};
    font-style: normal;
  }
  @font-face {
    font-family: 'Qrates Basis';
    src: url('/assets/fonts/QratesBasis-Light.eot');
    src: url('/assets/fonts/QratesBasis-Light.eot?#iefix') format('embedded-opentype'),
      url('/assets/fonts/QratesBasis-Light.woff') format('woff'),
      url('/assets/fonts/QratesBasis-Light.ttf') format('truetype');
    font-weight: ${FONT_WEIGHTS.light};
    font-style: normal;
  }
  @font-face {
    font-family: 'Qrates Basis';
    src: url('/assets/fonts/QratesBasis-Medium.eot');
    src: url('/assets/fonts/QratesBasis-Medium.eot?#iefix') format('embedded-opentype'),
      url('/assets/fonts/QratesBasis-Medium.woff') format('woff'),
      url('/assets/fonts/QratesBasis-Medium.ttf') format('truetype');
    font-weight: ${FONT_WEIGHTS.medium};
    font-style: normal;
  }
  @font-face {
    font-family: 'Qrates Basis';
    src: url('/assets/fonts/QratesBasis-Regular.eot');
    src: url('/assets/fonts/QratesBasis-Regular.eot?#iefix') format('embedded-opentype'),
      url('/assets/fonts/QratesBasis-Regular.woff') format('woff'),
      url('/assets/fonts/QratesBasis-Regular.ttf') format('truetype');
    font-weight: ${FONT_WEIGHTS.regular};
    font-style: normal;
  }
  @font-face {
    font-family: 'FK Roman';
    src: url('/assets/fonts/FK_Roman_Full_Family/otf/FKRomanDisplay-Black.otf');
    src: url('/assets/fonts/FK_Roman_Full_Family/woff/FKRomanDisplay-Black.woff') format('woff'),
      url('/assets/fonts/FK_Roman_Full_Family/ttf/FKRomanDisplay-Black.ttf') format('truetype');
    font-weight: ${FONT_WEIGHTS.black};
    font-style: normal;
  }
  @font-face {
    font-family: 'FK Roman';
    src: url('/assets/fonts/FK_Roman_Full_Family/otf/FKRomanDisplay-Bold.otf');
    src: url('/assets/fonts/FK_Roman_Full_Family/woff/FKRomanDisplay-Bold.woff') format('woff'),
      url('/assets/fonts/FK_Roman_Full_Family/ttf/FKRomanDisplay-Bold.ttf') format('truetype');
    font-weight: ${FONT_WEIGHTS.bold};
    font-style: normal;
  }
`;

export const cssFontQrates = css`
  font-family: 'Qrates Basis', Helvetica, Arial, sans-serif;
`;

export const cssFontFKRoman = css`
  font-family: 'FK Roman', Helvetica, Arial, sans-serif;
`;
