// @flow
import React from 'react';
import styled from 'styled-components';
import { RichText } from 'prismic-reactjs';
import { Link } from 'react-router-dom';
import { cssSharedBgImage } from '../../../StoriesScreen/components/StoryPreview/StoryPreview';
import { cssMediumPlusText } from '../../../../shared/styles/typography';
import { FONT_WEIGHTS } from '../../../../shared/styles/fonts';
import type { ArticlePreviewMdl } from '../../../../shared/cms/article';
import {
  getArticlePreviewImageUrl,
  getArticlePreviewText,
  getArticlePreviewTitle,
  getArticleSlug,
  getArticleTags,
} from '../../../../shared/cms/article';
import { breakpointMobileOnly } from '../../../../shared/styles/responsive';

const StyledContainer = styled.article`
  a {
    color: inherit;
    text-decoration: none;
  }
`;

const StyledImage = styled.div`
  ${cssSharedBgImage};
  background-image: url(${props => props.image || ''});
  height: 285px;
  position: relative;

  ${breakpointMobileOnly} {
    height: 254px;
  }
`;

const StyledTags = styled.div`
  display: flex;
  position: absolute;
  top: 10px;
  left: 10px;
`;

const StyledTag = styled.div`
  font-size: 12px;
  letter-spacing: -0.15px;
  line-height: 15px;
  font-weight: ${FONT_WEIGHTS.bold};
  text-transform: uppercase;
  background-color: #ffffff;
  border: 2px solid #000000;
  padding: 3px 7px;

  &:not(:first-child) {
    margin-left: 5px;
  }
`;

const StyledTitle = styled.h4`
  ${cssMediumPlusText};
  margin-top: 20px;
  margin-bottom: 12px;
`;

const StyledDescription = styled.p`
  font-size: 14px;
  letter-spacing: -0.2px;
  line-height: 18px;
  font-weight: ${FONT_WEIGHTS.medium};
`;

type Props = {
  article: ArticlePreviewMdl,
};

const ArticlePreview = ({ article }: Props) => {
  const to = `/article/${getArticleSlug(article)}`;
  return (
    <StyledContainer>
      <Link to={to}>
        <StyledImage image={getArticlePreviewImageUrl(article)}>
          <StyledTags>
            {getArticleTags(article).map((tag, index) => (
              <StyledTag key={`${tag}${index.toString()}`}>{tag}</StyledTag>
            ))}
          </StyledTags>
        </StyledImage>
      </Link>
      <Link to={to}>
        <StyledTitle>{RichText.asText(getArticlePreviewTitle(article))}</StyledTitle>
      </Link>
      <StyledDescription>{RichText.asText(getArticlePreviewText(article))}</StyledDescription>
    </StyledContainer>
  );
};

export default ArticlePreview;
