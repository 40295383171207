import accounting from 'accounting';

export function formatMoney(props) {
  const {
    amount, currency,
  } = props;

  let rate = 1;
  accounting.settings.currency.format = '%s%v';
  switch (currency) {
    case 'EUR':
      accounting.settings.currency.symbol = '€';
      accounting.settings.currency.precision = 2;
      rate = 100;
      break;
    case 'USD':
      accounting.settings.currency.symbol = '$';
      accounting.settings.currency.precision = 2;
      rate = 100;
      break;
    case 'GBP':
      accounting.settings.currency.symbol = '£';
      accounting.settings.currency.precision = 2;
      rate = 100;
      break;
    case 'JPY':
      accounting.settings.currency.symbol = '¥';
      accounting.settings.currency.precision = 0;
      rate = 1;
      break;
  }

  return accounting.formatMoney(amount / rate);
}

export function amountToValue(props) {
  const {amount, currency} = props;

  if (amount == null || amount == undefined) {
    return '';
  }
  accounting.settings.currency.format = '%v';
  if (currency == 'JPY') {
    accounting.settings.currency.precision = 0;
    return accounting.formatMoney(amount, {thousand: ''});
  } else {
    accounting.settings.currency.precision = 2;
    return accounting.formatMoney(amount / 100, {thousand: ''});
  }
}

export function valueToAmount(props) {
  const {amount, currency} = props;

  let price;
  if (currency == 'JPY') {
    price = parseInt(amount);
  } else {
    price = Math.round(parseFloat(amount) * 100);
  }
  if (isNaN(price)) {
    return null;
  } else {
    return price;
  }
}
