import React from 'react';
import {RichText} from 'prismic-reactjs';
import {ReactButton} from 'shared/components/buttons/react_button/react_button';
import {StepsMobile} from './for_artists_steps_mobile';

export const ProcessMobile = ({
  detailsTitleMobile,
  detailsLabelArtist,
  detailsLabelQrates,
  detailsIntroTitleArtist,
  detailsIntroTitleQrates,
  detailsIntroTextArtist,
  detailsIntroTextQrates,
  detailsMobileArtist,
  detailsMobileQrates,
  detailsMobileArtistFinal,
  detailsCTAText,
  detailsCTALabel,
}) => {
  return (
    <div className="u-hidden-desktop-only">
      <div className="c-for-artists-details-section__banner">
        <span wovn-ignore=""><RichText render={detailsTitleMobile} /></span>
      </div>
      <div className="c-for-artists-details-mobile">
        <div className="c-for-artists-details-mobile__intro">
          <div wovn-ignore="" className="c-label--pages c-label--jazz">
            {detailsLabelArtist}
          </div>
          <h1 wovn-ignore="">
            {RichText.asText(detailsIntroTitleArtist)}
          </h1>
          <br />
          <p wovn-ignore="">
            {RichText.asText(detailsIntroTextArtist)}
          </p>
        </div>
        {detailsMobileArtist && (
          <StepsMobile details={detailsMobileArtist} />
        )}
        <div className="c-for-artists-details-mobile--qrates">
          <div className="c-for-artists-details-mobile__intro">
            <div className="c-label--pages c-label--jazz">
              {detailsLabelQrates}
            </div>
            <h1 wovn-ignore="">
              {RichText.asText(detailsIntroTitleQrates)}
            </h1>
            <br />
            <p wovn-ignore="">
              {RichText.asText(detailsIntroTextQrates)}
            </p>
          </div>
          {detailsMobileQrates && (
            <StepsMobile details={detailsMobileQrates} />
          )}
        </div>
        <div className="c-for-artists-details-mobile__paid">
          <div wovn-ignore="" className="c-label--pages c-label--jazz">
            {detailsLabelArtist}
          </div>
          {detailsMobileArtistFinal && (
            <RichText render={detailsMobileArtistFinal} />
          )}
        </div>
      </div>
      <div className="c-for-artists-details-mobile__start-new">
        <h3>
          <span wovn-ignore="" className="c-for-artists-details-mobile__start-new--title">
            {detailsCTAText}
          </span>
          <br />
          <i className="c-pictogram c-pictogram--align-text-bottom c-pictogram--arrow-down" />
        </h3>
        <ReactButton
          wovn-ignore=""
          text={detailsCTALabel}
          type="link"
          link="/projects/start"
          theme="primary-black-border"
          align="center"
          width="full"
        />
      </div>
    </div>
  );
};
