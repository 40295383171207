import React, {useState, useEffect} from 'react';

import {ProjectTimeline} from '../shared/project_timeline/project_timeline';

export const OrderTimelineHandler = ({orderItem}) => {
  const orderState = orderItem?.state;
  const paymentState = orderItem.order?.payment_state;

  const isFixed = orderState === 'fixed';
  const isShipped = orderState === 'shipped';
  const isAvaiting = orderState === 'awaiting';
  const isReserved = orderState === 'reserved';
  const isDelivered = orderState === 'delivered';
  const isProcessing = orderState === 'processing';

  const isPaymentFailed = paymentState === 'failed';

  const items = ['Project started', 'Project goal', 'Records are pressed', 'We ship to you!'];

  const [isTimelineShow, setIsTimelineShow] = useState(false);
  const [isError, setIsError] = useState(false);
  const [message, setMessage] = useState(null);
  const [currentItem, setCurrentItem] = useState(0);

  useEffect(() => {
    if (isReserved) {
      setCurrentItem(1);
    } else if (isFixed) {
      setCurrentItem(2);
    } else if (isAvaiting || isProcessing) {
      setCurrentItem(3);
    } else if (isShipped || isDelivered) {
      setCurrentItem(4);
    }

    if (isPaymentFailed) {
      setIsError(true);
      setMessage('Payment failed');
    }

    setIsTimelineShow(isReserved || isFixed || isAvaiting || isProcessing || isShipped || isDelivered);
  }, [isPaymentFailed, isReserved, isFixed, isAvaiting, isProcessing, isShipped, isDelivered]);

  return (
    <>
      {isTimelineShow && <ProjectTimeline items={items} currentItem={currentItem} message={message} isError={isError} />}
    </>
  );
};
