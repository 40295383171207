import React from 'react';

import {RichText} from 'prismic-reactjs';

import StickerImagePath from 'images/common/sticker/ok.png';

import {SectionsContainer} from 'public/components/sections_container/sections_container';
import {ReactButton} from 'shared/components/buttons/react_button/react_button';

export const TopArtistSection = ({
  record,
  coverText,
  forFansButtonLabel,
  forArtistsButtonLabel,
}) => {
  return (
    <section className="c-top-artist-section">
      <SectionsContainer>
        <div className="c-top-artist-section--grid c-top-artist-section--home">
          <div className="c-top-artist-section__left">
            <h1 wovn-ignore="" className="c-top-artist-section__heading">
              QRATES
              <span wovn-ignore="">MUSIC IN</span>
              <span wovn-ignore="">YOUR HANDS</span>
            </h1>
            <p wovn-ignore="" className="c-top-artist-section__explanation u-hidden-tablet">
              <span wovn-ignore="" className="f-adjust-ja">{RichText.asText(coverText)}</span>
            </p>
            <div className="c-button-grid__top-page u-hidden-tablet">
              <ReactButton
                wovn-ignore=""
                text={forFansButtonLabel}
                type="link"
                link="/for-fans"
                theme="primary-white-border"
                align="align-left"
                className="c-top-artist-section__button"
              />
              <ReactButton
                wovn-ignore=""
                text={forArtistsButtonLabel}
                type="link"
                link="/for-artists"
                theme="primary-black"
                align="align-left"
                className="c-top-artist-section__button"
              />
            </div>
          </div>
          <div className="c-top-artist-section__right">
            <a href={record.cover_artist_url}>
              <div className="c-top-artist-section__image">
                <img src={record.cover} title="artist_cover" />
              </div>
              <img src={StickerImagePath} className="c-sticker c-sticker--ok" />
            </a>
            <div wovn-ignore="" className="c-vertical-text">
              <a wovn-ignore="" href={record.cover_artist_url}>{record.cover_artist_name}</a>
              IS ON QRATES
            </div>
          </div>
          <p className="c-top-artist-section__explanation u-hidden-desktop-only">
            <span wovn-ignore="" className="f-adjust-ja">{RichText.asText(coverText)}</span>
          </p>
          <div className="c-button-grid__top-page--intro-only u-hidden-desktop-only">
            <ReactButton
              wovn-ignore=""
              text={forFansButtonLabel}
              type="link"
              link="/for-fans"
              theme="primary-white-border"
              align="center"
              width="full"
            />
            <ReactButton
              wovn-ignore=""
              text={forArtistsButtonLabel}
              type="link"
              link="/for-artists"
              theme="primary-black"
              align="center"
              width="full"
            />
          </div>
        </div>
      </SectionsContainer>
    </section>
  );
};
