import React from 'react';
import {CatalogGrid} from 'src/shared/components/catalog/catalog_grid/catalog_grid';
import {ArtistCard} from 'src/shared/components/cards/artist_card/artist_card';

export const ArtistCatalog = ({
  artists,
}) => {
  return (
    <CatalogGrid className="c-dashboard__artists-catalog" type="artists">
      {artists.map((artist, index) => {
        return (
          <ArtistCard
            key={index}
            title={artist.name}
            subtitle={artist.genre}
            link={`/artists/${artist.to_param}`}
            avatarImage={artist.profile_image?.url}
          />
        );
      })}
    </CatalogGrid>
  );
};
