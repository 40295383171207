import {Record} from 'immutable';
import ArtworkFile from './artwork_file';
import VinylAudioFile from './vinyl_audio_file';
import ProjectSpec from './project_spec';

import CopyrightForm from './copyright_form';
import {ProductDelivery} from './product_delivery';

const _Record = Record({
  id: null,

  title: '',
  artist_name: '',
  catalog_number: '',
  state: '',
  billing_address: '',
  billing_name: '',
  billing_email: '',
  billing_first_name: '',
  billing_last_name: '',
  billing_company: '',
  billing_building: '',
  billing_street: '',
  billing_city: '',
  billing_prefecture: '',
  billing_country: '',
  billing_postal_code: '',
  billing_phone_number: '',
  delivery_service: 'delivery_service_france',
  mastering_required: null,
  artwork_files: [],
  vinyl_audio_files: [new VinylAudioFile()],
  cassette_audio_files: [new VinylAudioFile()],
  copyright_form: new CopyrightForm(),
  coupon_id: null,
  coupon: {},
  cost: {},
  cost_with_changes: {},
  cost_versions: [],
  cost_versions_with_changes: [],
  paid_date: null,
  paid_amount: 0,
  order_placed_date: null,
  test_production_shipped_date: null,
  test_production_confirmed_date: null,
  visual_confirmation_registered_date: null,
  test_production_rejected_date: null,
  visual_confirmation_confirmed_date: null,
  visual_confirmation_rejected_date: null,
  shipped_from_factory_date: null,
  shipped_date: null,
  estimated_shipping_date: null,
  quantity_updated_date: null,
  start_submitting_date: null,
  quantity: 0,
  tolerance_included_quantity: 0,
  base_quantity: 0,
  repress: false,
  spec_variations: [],
  spec_json: {},
  spec_options: {},
  disabled_spec_options: {},
  available_mockups: {},
  available_artworks: {},
  main_delivery: new ProductDelivery(),
  test_production_delivery: new ProductDelivery(),
  deliveries: [new ProductDelivery({purpose: 'main'})],
  test_production_required: false,
  artwork_required: false,
  tracklist_review: null,
  audio_review: null,
  artwork_file_review: null,
  artwork_confirmation_review: null,
  test_production_confirmation_review: null,
  test_production_address_review: null,
  copyright_review: null,
  markers: [],
  timezone: {},
  durations: {},
  max_durations: {},
  campaign_applied: false,
  continuous_audio: false,
  tax_id: null,

  type: 'Product::VinylOrder',
}, 'productOrderRecord');

export class ProductOrder extends _Record {
  static fromJSON(json) {
    if (json) {
      json.artwork_files = json.artwork_files.map((file) => ArtworkFile.fromJSON(file));
      json.copyright_form = CopyrightForm.fromJSON(json.copyright_form);
      json.main_delivery = ProductDelivery.fromJSON(json.main_delivery);
      json.test_production_delivery = ProductDelivery.fromJSON(json.test_production_delivery);

      json.vinyl_audio_files = json.vinyl_audio_files.map((vinyl_audio) => VinylAudioFile.fromJSON(vinyl_audio));
      json.cassette_audio_files = json.cassette_audio_files.map((cassette_audio) => VinylAudioFile.fromJSON(cassette_audio));
      json.spec_variations = json.spec_variations?.map((variation, index) => ProjectSpec.fromJSON({...variation, index: index + 1}));
      return new ProductOrder(json);
    } else {
      return new ProductOrder();
    }
  }

  findProductAudioByTrack(track) {
    const productType = this.spec_json?.product_type?.original_value;
    const audioFiles = productType === 'cassette' ? this.cassette_audio_files : this.vinyl_audio_files;
    const sortedAudio = audioFiles.sort((a, b) => { return (a.id || 0) - (b.id || 0); });
    const productAudio = sortedAudio.find((audio) => {
      if (this.continuous_audio) {
        return audio.present_type === track.side;
      } else {
        return audio.track?.id === track.track.id;
      }
    });
    return productAudio || {};
  }
}
