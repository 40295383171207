import React, {useState, useEffect} from 'react';
import classNames from 'classnames';
import {Elements} from '@stripe/react-stripe-js';
import {PayPalButton} from 'react-paypal-button-v2';
import {formatMoney} from 'utils/format_money';
import {StripeCheckoutForm} from 'dashboard/components/project_dashboard/partials/stripe_checkout_form';
import ApiHelper from 'utils/api_helper';
import {handleError} from 'utils/validation';
import {stripePromise} from 'utils/stripe';

export const Repayment = ({onClose, order, setOrder}) => {
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [formParams, setFormParams] = useState({});
  const [notification, setNotification] = useState(null);
  const [paymentTrigger, setPaymentTrigger] = useState(null);
  const currency = order.payment_currency;
  const amount = order.total_payment_total_amount;
  const updatePaymentTrigger = () => {
    setPaymentTrigger(Date.now());
  };
  const selectPaymentMethod = (event) => {
    setButtonDisabled(true);
    setPaymentMethod(event.target.value);
  };
  const createPaypalOrder = (data, actions) => {
    return actions.order.create(order.paypal_options);
  };
  const handleStripeFormSubmit = (result) => {
    if (result.empty_form) {
      setButtonDisabled(true);
    } else if ((result.token || {}).id) {
      setFormParams({
        order: {
          payment_notes: JSON.stringify(result.token),
          payment_method: 'stripe',
          payment_token: result.token.id,
        },
      });
      setButtonDisabled(false);
    } else {
      console.log(result);
    }
  };

  const handleRepayment = () => {
    setNotification(null);
    setButtonDisabled(true);
    ApiHelper.patch(`/api/v1/orders/${order.id}/repayment`, formParams).then((res) => {
      if (res.error) {
        handleError(res.error, setNotification);
        setButtonDisabled(false);
      } else {
        setOrder(res.response);
        onClose();
      }
    });
  };

  useEffect(() => {
    paymentTrigger && handleRepayment();
  }, [paymentTrigger]);

  return (
    <div className="p-modal__content">
      <div className="p-modal__inner">
        <div className="p-modal__section">
          <div className="c-dashboard__heading">
            Update your payment information
          </div>
          <span className="c-close--modal" onClick={onClose}>
            <a>&times;</a>
          </span>
          {notification && (
            <div className="c-notification c-notification--error c-notification--middle">
              {notification.message}
            </div>
          )}
          <div className="c-grid">
            <div className="c-grid--space c-info--control">
              <div className="c-info__value">Payment amount</div>
              <div className="c-info__value">{formatMoney({amount, currency})}</div>
            </div>
          </div>
          <div className="c-grid">
            <div className="c-grid--space">
              <div className="c-info__title p-project-dashboard__choose-title">
                Payment method
              </div>
              <div className="p-form-group--radio">
                <label type="radio" className="c-input--radio p-form-group__input">
                  <input
                    type="radio"
                    className="c-input--radio"
                    value="stripe"
                    checked={paymentMethod == 'stripe'}
                    onChange={selectPaymentMethod}
                  />
                  <span>Pay with Card</span>
                </label>
                <label type="radio" className="c-input--radio p-form-group__input">
                  <input
                    type="radio"
                    className="c-input--radio"
                    value="paypal"
                    checked={paymentMethod == 'paypal'}
                    onChange={selectPaymentMethod}
                  />
                  <span>Pay with PayPal</span>
                </label>
              </div>
            </div>
          </div>
          {paymentMethod == 'stripe' && (
            <div className="c-grid--space">
              <div className="c-info__title">
                Credit card
              </div>
              <Elements stripe={stripePromise({currency})}>
                <StripeCheckoutForm onChangeHandler={handleStripeFormSubmit} />
              </Elements>
            </div>
          )}
        </div>
        <div className={classNames('p-modal__buttons--full',
          {'p-modal__buttons--paypal': paymentMethod == 'paypal'})}
        >
          <div className="p-modal__buttons-right">
            {paymentMethod != 'paypal' && (
              <button
                type="button"
                className="c-button c-button--black c-button--full"
                disabled={buttonDisabled}
                onClick={handleRepayment}
              >
                PROCEED TO PAYMENT
              </button>
            )}
            {paymentMethod == 'paypal' && (
              <PayPalButton
                style={{
                  shape: 'pill',
                  layout: 'horizontal',
                  tagline: 'false',
                }}
                options={{clientId: window.constants.paypal_api_client_id, currency}}
                createOrder={createPaypalOrder}
                onApprove={(data, actions) => {
                  setFormParams({
                    order: {
                      payment_method: 'paypal',
                      payment_token: data.orderID,
                      payment_notes: JSON.stringify(data),
                    },
                  });
                  updatePaymentTrigger();
                }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
