// @flow
import React from 'react';
import styled, {css} from 'styled-components';
import {StyledArticleMaxWidthContainer} from 'prismic/shared/styles/layout';
import {StoriesCard} from '../../../../../../src/public/pages/stories_page/stories_card';
import {cssLargePlusText, cssSmallPlusText} from '../../../../shared/styles/typography';
import type {StoryMdl} from '../../../../shared/cms/story';
import {
  breakpointDesktopOnly,
  breakpointTabletOnly,
  breakpointMobileOnly,
  cssMobileSidePadding,
  StyledDesktopOnly,
} from '../../../../shared/styles/responsive';
import { classNameStoryPreview } from '../../../StoriesScreen/components/StoryPreview/StoryPreview';

const StyledContainer = styled.div`
  margin: 0px 40px 60px;

  ${breakpointTabletOnly} {
    margin: 60px 15px 30px;

    .${classNameStoryPreview} {
      margin-top: 30px;
    }
  }
`;

const StyledCardsContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  column-gap: 30px;
  width: 100%;

  ${breakpointTabletOnly} {
    flex-direction: column;
    row-gap: 30px;
  }
`;

const StyledHeader = styled(StyledArticleMaxWidthContainer)`
  ${cssMobileSidePadding};
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  ${breakpointTabletOnly} {
    margin-bottom: 30px;
  }
`;

const StyledHeading = styled.h4`
  ${cssLargePlusText};

  ${breakpointTabletOnly} {
    font-size: 36px;
    letter-spacing: -1px;
    line-height: 40px;
  }
`;

const cssMobileCondensed = css`
  ${breakpointMobileOnly} {
    font-size: 12px;
    letter-spacing: -0.15px;
    line-height: 15px;
    height: 40px;
  }
`;

const cssIsArrow = css`
  padding: 0px 25px;
  justify-content: space-between;
  ${breakpointTabletOnly} {
    padding: 0px 20px;
  }
`;

export const StyledLink = styled.a`
  ${cssSmallPlusText};
  display: flex;
  height: 50px;
  justify-content: center;
  align-items: center;
  background-color: #000000;
  color: #ffffff;
  border-radius: 25px;
  text-transform: uppercase;
  padding: 5px 30px;
  text-decoration: none;
  min-width: 260px;
  ${props => (props.mobileCondensed ? cssMobileCondensed : '')};
  ${props => (props.isArrow ? cssIsArrow : '')};
`;

export const StyledArrowIcon = styled.span`
  margin-left: 20px;

  ${breakpointTabletOnly} {
    margin-left: 0px;
  }
`;

const StyledBrowseWrapper = styled.div`
  ${breakpointDesktopOnly} {
    display: none;
  }
  margin-top: 30px;
`;

type Props = {
  stories: StoryMdl[],
};

const RelatedStories = ({ stories }: Props) => (
  <StyledArticleMaxWidthContainer>
    <StyledContainer>
      <StyledHeader>
        <StyledHeading>Related Stories</StyledHeading>
        <StyledDesktopOnly>
          <StyledLink href="/stories" isArrow>
            Browse all stories
            <StyledArrowIcon>→</StyledArrowIcon>
          </StyledLink>
        </StyledDesktopOnly>
      </StyledHeader>
      <StyledCardsContainer>
        {stories?.map((story, index) => (
          <StoriesCard
            category={story.data.story_category}
            image={story.data.preview_image?.url}
            title={story.data.preview_heading}
            text={story.data.preview_text}
            index={index}
            uid={story.data.uid}
          />
        ))}
      </StyledCardsContainer>
      <StyledBrowseWrapper>
        <StyledLink href="/stories" mobileCondensed isArrow>
          Browse all stories
          <StyledArrowIcon>→</StyledArrowIcon>
        </StyledLink>
      </StyledBrowseWrapper>
    </StyledContainer>
  </StyledArticleMaxWidthContainer>
);

export default RelatedStories;
