import React, {useRef, useState} from 'react';
import {useClickOutside} from 'utils/use_click_outside';
import ApiHelper from 'utils/api_helper';

export const ProjectPreview = ({params}) => {
  const {
    project: {
      objectID,
      slug,
      on_sale,
      project_card_image_url,
      label_state,
      project_type,
      state,
      repress,
    },
    section,
  } = params;

  const isDraft = state == 'draft';

  const draftProjectPath = `/projects/${slug}/start`;
  const dashboardProjectPath = `/dashboard/projects/${slug}`;
  const projectPagePath = `projects/${slug}`;
  const deleteProjectPath = `/api/v1/projects/${objectID}`;

  const deleteProject = (e) => {
    e.preventDefault();
    if (window.confirm('Are you sure you want to delete the project?')) {
      ApiHelper.delete(deleteProjectPath).then((res) => window.location.reload());
    }
  };

  const projectPath = () => {
    switch (section) {
      case 'dashboard':
        if (isDraft) {
          return draftProjectPath;
        } else {
          return dashboardProjectPath;
        }
      default:
        return projectPagePath;
    }
  };

  const handleLinkTo = (href) => {
    location.href = href;
  };

  const projectMenu = useRef();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toogleMenu = (e) => {
    e.preventDefault();
    setIsMenuOpen(!isMenuOpen);
  };

  useClickOutside(
    [projectMenu],
    isMenuOpen,
    (e) => toogleMenu(e),
  );

  const renderProjectMenu = () => {
    return (
      <div className="c-project__popover">
        <div className="c-project__menu-icon" onClick={toogleMenu} />
        {isMenuOpen && (
          <ul className="c-project__popover__menu" ref={projectMenu}>
            <li className="c-project__popover__link" onClick={() => handleLinkTo(projectPath())}>
              <span>GO TO PROJECT &#8594;</span>
            </li>
            {!isDraft && (
              <li className="c-project__popover__link" onClick={() => handleLinkTo(`${dashboardProjectPath}/settings`)}>
                <span>SETTINGS</span>
              </li>
            )}
            {project_type != 'press_only' && (
            <>
              <li className="c-project__popover__link" onClick={() => handleLinkTo(`${dashboardProjectPath}/payments`)}>
                <span>PAYMENTS</span>
              </li>
              <li className="c-project__popover__link" onClick={() => handleLinkTo(`${dashboardProjectPath}/promote`)}>
                <span>SHARE</span>
              </li>
            </>
            )}
            {isDraft && (
              <li className="c-project__popover__link" onClick={deleteProject}>
                <a>DELETE</a>
              </li>
            )}
          </ul>
        )}
      </div>
    );
  };

  const backgroundStyle = () => {
    const backgroundStyleImage = (imageUrl, size = 'cover') => ({
      backgroundImage: `url(${imageUrl})`,
      backgroundSize: size,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    });

    return backgroundStyleImage(project_card_image_url || '/assets/common/default/fig_vinyl_rendered_image_default.png');
  };

  return (
    <div className="c-project__inner" style={backgroundStyle()}>
      <div className="c-project__inner-container">
        {section == 'dashboard' && (
          <>
            <div className="c-label--container">
              <div className={on_sale ? 'c-label--selling' : 'c-label--working'}>{label_state}</div>
              {repress && <div className="c-label--working">REPRESS</div>}
            </div>
            {renderProjectMenu()}
          </>
        )}
      </div>
    </div>
  );
};
