import React, {useState} from 'react';
import classNames from 'classnames';

export const FilterBar = ({
  filters,
  currentFilter,
  currentFilterHandler,
  theme,
}) => {
  const filtersData = Object.entries(filters);
  const [selected, setSelected] = useState('');

  const handleClick = (selectedFilter) => {
    currentFilterHandler(selectedFilter);
    setSelected(selectedFilter);
  };

  return (
    <div
      className={classNames('c-filter-bar', {
        'c-filter-bar--orange': theme === 'orange',
        'c-filter-bar--black': theme === 'black',
      })}
    >
      {filtersData.map((filter, index) => (
        <a
          id={filter[0]}
          wovn-ignore=""
          className={classNames(
            'c-filter-bar__option',
            {'c-filter-bar__option--active': selected === filter[0] || currentFilter === filter[0]},
          )}
          key={index}
          onClick={() => handleClick(filter[0])}
        >
          {filter[1]}
        </a>
      ))}
    </div>
  );
};
