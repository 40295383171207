import React from 'react';
import {
  render,
  screen,
  fireEvent,
  waitFor,
} from '@testing-library/react';

import {ArticlesScreen} from '../articles_screen';

const articles = [
  {
    uid: 1,
    preview_title: 'Title 1',
    preview_text: 'Text 1',
    tags: ['tag_1', 'tag_2'],
  },
];

jest.mock('utils/api_helper', () => {
  return {
    get: () => Promise.resolve({
      response: {
        results: [
          {
            uid: 2,
            preview_title: 'Title 2',
            preview_text: 'Text 2',
            tags: ['tag_3', 'tag_4'],
          },
        ],
      },
    }),
  };
});

it('renders correct data', async () => {
  render(<ArticlesScreen articles={articles} next_page={2} />);

  expect(screen.getByText('Title 1')).toBeInTheDocument();
  expect(screen.getByText('Text 1')).toBeInTheDocument();
  expect(screen.getByText('TAG_1')).toBeInTheDocument();
  expect(screen.getByText('TAG_2')).toBeInTheDocument();
  expect(screen.getByText('LOAD MORE')).toBeInTheDocument();
});

describe('with pagination button click and without next_page in the response', () => {
  it('appends data', async () => {
    render(<ArticlesScreen articles={articles} next_page={2} />);

    expect(screen.getByText('Title 1')).toBeInTheDocument();
    expect(screen.getByText('Text 1')).toBeInTheDocument();
    expect(screen.getByText('TAG_1')).toBeInTheDocument();
    expect(screen.getByText('TAG_2')).toBeInTheDocument();
    expect(screen.getByText('LOAD MORE')).toBeInTheDocument();

    fireEvent.click(screen.getByText('LOAD MORE'));

    await waitFor(() => {
      expect(screen.getByText('Title 2')).toBeInTheDocument();
    });

    expect(screen.getByText('Title 1')).toBeInTheDocument();
    expect(screen.getByText('Text 1')).toBeInTheDocument();
    expect(screen.getByText('TAG_1')).toBeInTheDocument();
    expect(screen.getByText('TAG_2')).toBeInTheDocument();
    expect(screen.getByText('Text 2')).toBeInTheDocument();
    expect(screen.getByText('TAG_3')).toBeInTheDocument();
    expect(screen.getByText('TAG_4')).toBeInTheDocument();
    expect(screen.queryByText('LOAD MORE')).not.toBeInTheDocument();
  });
});

describe('with languagechange', () => {
  const event = new CustomEvent('languagechange', {detail: {locale: 'es'}});

  it('replaces data', async () => {
    render(<ArticlesScreen articles={articles} next_page={2} />);

    expect(screen.getByText('Title 1')).toBeInTheDocument();
    expect(screen.getByText('Text 1')).toBeInTheDocument();
    expect(screen.getByText('TAG_1')).toBeInTheDocument();
    expect(screen.getByText('TAG_2')).toBeInTheDocument();
    expect(screen.getByText('LOAD MORE')).toBeInTheDocument();

    fireEvent(window, event);

    await waitFor(() => {
      expect(screen.getByText('Title 2')).toBeInTheDocument();
    });

    expect(screen.queryByText('Title 1')).not.toBeInTheDocument();
    expect(screen.queryByText('Text 1')).not.toBeInTheDocument();
    expect(screen.queryByText('TAG_1')).not.toBeInTheDocument();
    expect(screen.queryByText('TAG_2')).not.toBeInTheDocument();
    expect(screen.queryByText('LOAD MORE')).not.toBeInTheDocument();
    expect(screen.getByText('Text 2')).toBeInTheDocument();
    expect(screen.getByText('TAG_3')).toBeInTheDocument();
    expect(screen.getByText('TAG_4')).toBeInTheDocument();
  });
});
