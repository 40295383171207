import React from 'react';
import classNames from 'classnames';
import {RichText} from 'prismic-reactjs';
import {ReactButton} from 'shared/components/buttons/react_button/react_button';
import {ArtistDetailsArrow} from '../arrow/arrow';

export const Steps = ({details}) => {
  return details?.map((step) => (
    <div
      key={step?.number}
      className={classNames('c-for-artists-details-section',
        {'c-for-artists-details-section__last': step.number === details.length})}
    >
      <div className="c-for-artists-details-section__you">
        <span wovn-ignore=""><RichText render={step?.process_artist} /></span>
      </div>
      <ArtistDetailsArrow number={step?.number} hidden={step?.number === details.length} />
      <div className="c-for-artists-details-section__qrates">
        <span wovn-ignore=""><RichText render={step?.process_qrates} /></span>
        {step.process_qrates_button_href && (
          <div className="c-for-artists-details-section__qrates--button">
            <ReactButton
              wovn-ignore=""
              text={step.process_qrates_button_title}
              type="link"
              link={step.process_qrates_button_href}
              theme="primary-black"
              align="center"
              className="c-for-artists-details-section__button"
            />
          </div>
        )}
      </div>
    </div>
  ));
};
