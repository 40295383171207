import React, {useEffect, useState} from 'react';
import queryString from 'query-string';
import ApiHelper from 'utils/api_helper';
import {formatDate} from 'utils/date';
import {useLanguageChange} from 'utils/use_language_change';
import ProfileIconPath from 'images/common/logos/profile_icon.png';
import ProjectThumbnailImagePath from 'images/common/default/fig_vinyl_vinyl_thumbnail_image_default.png';

export const DiscoverLists = () => {
  const [projects, setProjects] = useState([]);
  const [lists, setLists] = useState([]);
  const [currentList, setCurrentList] = useState(lists[0] || {});
  const [listUid, setListUid] = useState(currentList.uid);
  const numbers = ['one', 'two', 'three', 'four'];

  const fetchFilteredLists = () => {
    const params = {list_uid: listUid};
    ApiHelper.get(`/api/v1/discover/lists.json?${queryString.stringify(params)}`).then((res) => {
      setProjects(res.response.projects);
      setLists(res.response.lists);
      setCurrentList(res.response.list);
      setListUid(res.response.list?.uid);
    });
  };

  useLanguageChange(fetchFilteredLists);

  useEffect(() => {
    fetchFilteredLists();
  }, [listUid]);

  return (
    <section className="p-discover__section--lists">
      <div className="p-section-header--discover">
        <div className="p-section-header__title">
          <h2>Lists</h2>
          <nav>
            <ul>
              {lists.map((list) => {
                return (
                  <li data-status={currentList.uid == list.uid && 'current'}>
                    <span className="c-clickable-link" onClick={() => setListUid(list.uid)}>{list.title.toUpperCase()}</span>
                  </li>
                );
              })}
            </ul>
          </nav>
        </div>

        <div className="p-section-header__button--pc">
          <a href="/lists" className="c-button c-button--black">BROWSE ALL LISTS</a>
        </div>
      </div>

      {listUid && (
        <div className="p-lists--grid-container">
          <div className="p-lists--grid-container__title">
            {currentList.cover_image && <img src={currentList.cover_image} className="p-lists__cover-image" />}
          </div>
          {projects.map((project, index) => {
            return (
              <>
                <div className="p-lists--grid-container__one" />
                <a
                  href={`/projects/${project.objectID}`}
                  className={`p-lists--grid-container__${numbers[index]}`}
                  target="_blank"
                  key={index}
                >
                  <div className="p-lists__image">
                    <img src={project.thumbnail_image_url || ProjectThumbnailImagePath} />
                  </div>
                  <div className="p-lists__info">
                    <div className="p-lists__title" wovn-ignore="">
                      {project.title}
                    </div>
                    <div className="p-lists__name" wovn-ignore="">
                      {project.artist_name}
                    </div>
                    <div className="p-lists__details">
                      <div className="p-lists__genre" wovn-ignore="">
                        {project.item?.genre}
                      </div>
                      <div className="p-lists__right-contents">
                        {project.is_failed && <div className="p-lists__quantity">Uncompleted </div>}
                        {project.project_type == 'funding' && (
                          <div className="p-lists__quantity">{`${project.ordered_quantity}/${project.base_quantity} `}</div>
                        )}
                        {project.on_sale && project.available_quantity > 0 && project.available_quantity <= 5 && (
                          <div className="p-lists__quantity">{`${project.available_quantity} copies left `}</div>
                        )}
                        {project.coming_soon && project.is_preorder && (
                          <div className="p-lists__condition">{`Start date : ${formatDate(project.start_date, '%b %-d %Y')}`}</div>
                        )}
                        {project.is_finished && <div className="p-lists__condition">Finished</div>}
                        {project.countdown && !project.is_finished && (
                          <div className="p-lists__condition p-lists__condition--left">
                            {project.left_hours}
                            <span className="p-lists__condition--left__unit">&nbsp;hours left</span>
                          </div>
                        )}
                        {project.coming_soon && project.project_type == 'funding' && (
                          <div className="p-lists__condition">{`Start date : ${formatDate(project.start_date, '%b %-d %Y')}`}</div>
                        )}
                        {!!project.left_days && (
                          <div className="p-lists__condition p-lists__condition--left">
                            {project.left_days}
                            <span className="p-lists__condition--left__unit">&nbsp;days left</span>
                          </div>
                        )}
                        {project.is_sell && (
                          <div className="p-lists__condition">{`Release date: ${formatDate(project.start_date, '%b %-d %Y')}`}</div>
                        )}
                      </div>
                    </div>
                  </div>
                </a>
              </>
            );
          })}

          <div className="p-lists--grid-container__selecter">
            <div className="p-lists__selecter-name">
              <img src={ProfileIconPath} className="p-lists__selecter-avatar" />
              Curated by
              <a href="/">{currentList.curator}</a>
            </div>
            <a
              href={`/lists/${currentList.uid}`}
              className="p-lists__selecter-link"
              target="_blank"
            >
              SEE MORE FROM THIS LIST &#8594;
            </a>
          </div>
        </div>
      )}

      <div className="c-button-grid--center p-section-header__button--tablet-control">
        <a href="/lists" className="c-button--large c-button--black">BROWSE ALL LISTS</a>
      </div>
    </section>
  );
};
