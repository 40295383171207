export const assetPath = (path, manifest) => {
  if (path === undefined || path.startsWith('http://') || path.startsWith('https://')) {
    return path;
  }
  if (manifest && manifest[path]) {
    return `/assets/${manifest[path]}`;
  } else {
    return `/assets/${path}`;
  }
};

export const imagePath = (manifest) => (url) => {
  return assetPath(url, manifest);
};
