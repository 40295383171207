import React from 'react';

import {formatMoney} from 'utils/format_money';

export const SellingStatus = ({
  project,
  start_date,
  preorder_start_date,
}) => {
  const renderSellingType = () => {
    if (project.is_funding) {
      return (
        <div className="p-project-status__status--funding">Crowdfunding</div>
      );
    } else if (project.is_sell_catalog && !project.is_soldout) {
      return (
        <div className="p-project-status__status--sell">In Stock</div>
      );
    } else if (project.is_sell_catalog && project.is_soldout) {
      return (
        <div className="p-project-status__status--sell">Sold Out</div>
      );
    } else if (project.is_preorder) {
      return (
        <div className="p-project-status__status--preorder">Pre-Order</div>
      );
    }
  };

  const renderDate = () => {
    if (project.is_preorder && project.is_coming_soon) {
      return (
        <div className="p-project-status__input-group">
          <span className="p-project-status__input-group-label">Start date</span>
          <div className="p-project-status__input-group-date" wovn-ignore="">
            {preorder_start_date}
          </div>
        </div>
      );
    } else if (project.is_funding && project.is_coming_soon) {
      return (
        <div className="p-project-status__input-group">
          <span className="p-project-status__input-group-label">Launch date</span>
          <div className="p-project-status__input-group-date" wovn-ignore="">
            {start_date}
          </div>
        </div>
      );
    } else if (project.is_sell_catalog && project.is_coming_soon) {
      return (
        <div className="p-project-status__input-group">
          <span className="p-project-status__input-group-label">Release date</span>
          <div className="p-project-status__input-group-date" wovn-ignore="">
            {start_date}
          </div>
        </div>
      );
    } else if (project.is_finished) {
      return (
        <div className="p-project-status__input-group">
          <span className="p-project-status__input-group-label">Remaining time</span>
          <div className="p-project-status__input-group-date">Finished</div>
        </div>
      );
    } else if (project.is_countdown) {
      return (
        <div className="p-project-status__input-group">
          <span className="p-project-status__input-group-label">Remaining time</span>
          <div className="p-project-status__input-group-date">
            <span wovn-ignore="">
              {project.left_hours}
            </span>
            &nbsp;hours left
          </div>
        </div>
      );
    } else if (project.left_days) {
      return (
        <div className="p-project-status__input-group">
          <span className="p-project-status__input-group-label">Remaining time</span>
          <div className="p-project-status__input-group-date">
            <span wovn-ignore="">
              {project.left_days}
            </span>
            &nbsp;days left
          </div>
        </div>
      );
    } else if (!project.left_days && project.is_preorder) {
      return (
        <div className="p-project-status__input-group">
          <span className="p-project-status__input-group-label">Remaining time</span>
          <div className="p-project-status__input-group-date">TBD</div>
        </div>
      );
    } else if (project.start_date) {
      return (
        <div className="p-project-status__input-group">
          <span className="p-project-status__input-group-label">Release date</span>
          <div className="p-project-status__input-group-date" wovn-ignore="">
            {start_date}
          </div>
        </div>
      );
    }
  };

  const renderSellingState = () => {
    if (project.is_funding) {
      return (
        <div className="p-project-status__input-group">
          <span className="p-project-status__input-group-label">Achievement</span>
          <div className="p-project-status__input-group-date">
            {project.is_failed && 'Uncompleted'}
            {!project.is_failed && `${project.ordered_quantity}/${project.base_quantity}`}
          </div>
        </div>
      );
    } else if (project.is_onsale && project.available_quantity <= 5) {
      return (
        <div className="p-project-status__input-group">
          <span className="p-project-status__input-group-label">Stocks</span>
          <div className="p-project-status__input-group-date">{`${project.available_quantity} copies left`}</div>
        </div>
      );
    }
  };

  const renderPrice = () => {
    if (project.can_self_purchase && !project.preview) {
      return formatMoney({amount: project.self_price, currency: project.currency});
    } else if (project.is_wholesale_available && project.is_distributor_present) {
      return formatMoney({amount: project.buyer_price, currency: project.currency});
    } else if (project.is_preorder) {
      return formatMoney({amount: project.preorder_price, currency: project.currency});
    } else {
      return formatMoney({amount: project.price, currency: project.currency});
    }
  };

  return (
    <>
      <div className="p-project-status__status">{renderSellingType()}</div>
      {renderDate()}
      {renderSellingState()}

      <div className="p-project-status__input-group">
        <span className="p-project-status__input-group-label">Price</span>
        <div className="p-project-status__input-group-price">{renderPrice()}</div>
      </div>

      {project.is_funding && (
        <div className="p-project-status__graph">
          {project.is_succeeded && (
            <div className="p-project-status__graph-value p-project-status__graph-progress--finished" />
          )}
          {!project.is_succeeded && (
            <div className={`p-project-status__graph-value p-project-status__graph-progress--${project.funding_rate}`} />
          )}
        </div>
      )}
    </>
  );
};
