export const orderProjectButton = ({project}) => {
  const isComingSoon = project.is_coming_soon || project.coming_soon;
  const isWholesaleAvailable = project.is_wholesale_available;
  const isDistributorPresent = project.is_distributor_present;
  const isExclusive = project.is_exclusive;
  const isDistributorExclusive = project.is_distributor_exclusive;
  const productType = project.product_type?.original_value;
  const button = {};
  const isProductionStarted = project.is_production_started || !['draft', 'preparation', 'paid'].includes(project.product_order_state);
  const isFinished = (project.is_funding && project.is_finished && isProductionStarted)
    || (!project.is_funding && project.is_finished) || project.is_soldout;
  const isProjectOwner = project.artist?.id === project.current_user?.artist_id;

  const productTypeData = {
    vinyl: {
      restart_request: 'VOTE FOR A REPRESS',
      restart_requested: 'REPRESS REQUESTED',
    },
    cassette: {
      restart_request: 'VOTE FOR A REDUPLICATION',
      restart_requested: 'REDUPLICATION REQUESTED',
    },
  };

  if (project.is_funding) {
    button.label = 'BACK NOW';
    button.action = 'order';
  } else {
    button.label = 'ADD TO CART';
    button.action = 'order';
  }

  if (isProjectOwner) {
    button.label = 'GO TO PROJECT DASHBOARD';
    button.action = 'product_owner';
  } else if (!project.preview && project.can_self_purchase) {
    button.label = 'SELF PURCHASE';
    button.action = 'order';
  } else if (!project.preview && isComingSoon) {
    button.label = 'COMING SOON';
    button.action = null;
    button.disabled = true;
  } else if (!project.preview && isWholesaleAvailable && isExclusive && isDistributorExclusive) {
    button.label = `${button.label} (WHOLESALE)`;
    button.action = 'order';
  } else if (!project.preview && isWholesaleAvailable && isDistributorPresent && !isExclusive) {
    button.label = `${button.label} (WHOLESALE)`;
    button.action = 'order';
  } else if (project.is_funding && project.is_finished && !isProductionStarted) {
    button.action = null;
    button.disabled = true;
  } else if (project.is_failed && project.is_restart_requested) {
    button.label = 'RETRY REQUESTED';
    button.action = 'remove_restart_request';
  } else if (project.is_failed && !project.is_restart_requested) {
    button.label = 'VOTE FOR A RETRY';
    button.action = 'restart_request';
  } else if (isFinished && project.is_restart_requested) {
    button.label = productTypeData[productType]?.restart_requested;
    button.action = 'remove_restart_request';
  } else if (isFinished && !project.is_restart_requested) {
    button.label = productTypeData[productType]?.restart_request;
    button.action = 'restart_request';
  }

  return button;
};
