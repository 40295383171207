import React, {useState, useEffect} from 'react';
import Prismic from '@prismicio/client';
import {RichText} from 'prismic-reactjs';

import {useLanguageChange} from 'utils/use_language_change';
import {prismicClient} from 'prismic/shared/cms/prismic';

export const Team = () => {
  const [members, setMembers] = useState([]);
  const queries = [Prismic.Predicates.at('document.type', 'team')];

  const fetchMembers = (locale) => {
    const lang = window.constants.prismic_locales[locale || 'en'];
    prismicClient.query(queries, {lang}).then((response) => setMembers(response.results[0].data.members));
  };

  useEffect(() => fetchMembers(window.constants.locale), []);
  useLanguageChange((event) => fetchMembers(event.detail.locale));

  return (
    <div className="c-grid--columns" wovn-ignore="">
      {members.map((member, index) => (
        <div className="p-about__team__member" key={index}>
          <div className="p-about__member-logo">
            <img src={member.photo.url} />
          </div>
          <p className="p-about__team__member__name">{RichText.asText(member.name)}</p>
          <p className="p-about__team__member__position">{RichText.asText(member.position)}</p>
        </div>
      ))}
    </div>
  );
};
