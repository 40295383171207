import {combineReducers} from 'redux';
import ProjectReducer from './project';
import UserReducer from './user';
import NotificationReducer from './notification';
import FormSubmittingReducer from './form_submitting';
import {IsCalculatingReducer} from './is_calculating';
import {PressPricesReducer} from './press_prices';
import {CurrentSpecVariationIndexReducer} from './current_spec_variation_index';

export default combineReducers({
  project: ProjectReducer,
  user: UserReducer,
  notification: NotificationReducer,
  form_submitting: FormSubmittingReducer,
  is_calculating: IsCalculatingReducer,
  press_prices: PressPricesReducer,
  current_spec_variation_index: CurrentSpecVariationIndexReducer,
});
