import React, {useState} from 'react';
import {ReactButton} from 'shared/components/buttons/react_button/react_button';
import {ReactModal} from 'shared/components/modals/react_modal/react_modal';
import {ModalHeading} from 'shared/components/modals/parts/content/modal_heading/modal_heading';
import {ModalText} from 'shared/components/modals/parts/content/modal_text/modal_text';
import {ModalLabel} from 'shared/components/modals/parts/content/modal_label/modal_label';
import {GroupSection} from '../../group_section/group_section';
import {ScreenContainer} from '../../screen_container/screen_container';

export const ModalsScreen = () => {
  const appElement = document.body;
  const [isDefaultModalOpen, setIsDefaultModalOpen] = useState(false);
  const [isThinFramesModalOpen, setIsThinFramesModalOpen] = useState(false);
  return (
    <ScreenContainer className="modals-screen" title="Modals">
      <GroupSection title="Modals type" type="modals">
        <ReactButton text="Default modal" onClick={() => setIsDefaultModalOpen(!isDefaultModalOpen)} />
        <ReactButton text="Thin frames" onClick={() => setIsThinFramesModalOpen(!isThinFramesModalOpen)} />
        <ReactModal
          isOpen={isDefaultModalOpen}
          appElement={appElement}
          onClose={() => setIsDefaultModalOpen(!isDefaultModalOpen)}
        >
          <div className="с-test-modal с-test-modal--empty-content">
            <ModalHeading text="Name of the modal" />
          </div>
        </ReactModal>
        <ReactModal
          isOpen={isThinFramesModalOpen}
          theme="thin-frames"
          appElement={appElement}
          onClose={() => setIsThinFramesModalOpen(!isThinFramesModalOpen)}
        >
          <div className="с-test-modal с-test-modal--empty-content">
            <ModalHeading text="Name of the modal" />
          </div>
        </ReactModal>
      </GroupSection>
      <GroupSection title="Content" type="modals">
        <div className="test-modal">
          <ModalHeading text="Name of the modal" />
          <ModalLabel className="test-classname">Modal Label. Lorem ipsum dolor</ModalLabel>
          <ModalText className="test-classname">
            ModalText. Lorem ipsum dolor, sit amet consectetur adipisicing elit. Molestiae sed quam est voluptate ipsam
            ullam quo! Voluptatem iste, id perferendis aliquam mollitia veritatissed voluptas officia soluta ipsam
            perspiciatis laborum nihil vitae, ratione autem similique ducimus pariatur magni! Esse ratione inventore
            laudantium quo fugit quia aperiam dignissimos, voluptatibus adipisci rem amet culpa. Eveniet, dolorem non
            odio hic ea iusto minima quibusdam voluptatum eligendi architecto quam excepturi libero unde laudantium quas
          </ModalText>
          <ModalText className="test-classname" type="small">
            ModalText. Lorem ipsum dolor, sit amet consectetur adipisicing elit. Molestiae sed quam est voluptate ipsam
            ullam quo! Voluptatem iste, id perferendis aliquam mollitia veritatissed voluptas officia soluta ipsam
            perspiciatis laborum nihil vitae, ratione autem similique ducimus pariatur magni! Esse ratione inventore
            laudantium quo fugit quia aperiam dignissimos, voluptatibus adipisci rem amet culpa. Eveniet, dolorem non
            odio hic ea iusto minima quibusdam voluptatum eligendi architecto quam excepturi libero unde laudantium quas
          </ModalText>
        </div>
        <div className="test-modal">
          <ModalHeading text="Name of the modal" />
        </div>
      </GroupSection>
    </ScreenContainer>

  );
};
