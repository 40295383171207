import React from 'react';
import {scrollToTop} from 'utils/scroll';

export function handleError(error, setNotification = null, fieldTheme = 'default') {
  Object.keys(error.error || {}).forEach((item) => {
    document.getElementsByName(item).forEach((element) => {
      fieldTheme === 'default' && element.classList.add('c-input--error');
      fieldTheme === 'tableField' && element.parentNode.classList.add('c-row--error');
    });
  });

  setNotification && setNotification({
    type: 'error',
    message: renderValidationErrorMessage(error),
  });
}

export function renderValidationErrorMessage(error) {
  let errors = error.full_messages || [];
  if (error.error && typeof error.error == 'string') {
    errors = errors.concat([error.error]);
  }
  return (
    <ul>
      {
        errors.map((error, index) => <li key={index}>{error}</li>)
      }
    </ul>
  );
}

export function clearValidationErrors(setNotification) {
  document.querySelectorAll('.c-input--error').forEach((element) => {
    element.classList.remove('c-input--error');
  });
  document.querySelectorAll('.c-row--error').forEach((element) => {
    element.classList.remove('c-row--error');
  });
  setNotification && setNotification(null);
}

export function validationOptions(options = {}) {
  const {setNotification, onSuccess, onError} = options;

  return {
    onSuccess: () => {
      scrollToTop();
      clearValidationErrors(setNotification);
      onSuccess && onSuccess();
    },
    onError: (error) => {
      clearValidationErrors(setNotification);
      handleError(error, setNotification);
      scrollToTop();
      onError && onError(error);
    },
  };
}
