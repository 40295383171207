// @flow
import React from 'react';
import styled, {css} from 'styled-components';
import {RichText, Elements} from 'prismic-reactjs';
import {
  cssHeadingOne,
  cssHeadingTwo,
  cssHeadingThree,
  cssNormalText,
  cssNormalTextSmall,
  cssNormalTextLarge,
  cssNormalTextLargest,
  cssAnchorText,
} from '../../styles/typography';
import {getSliceRichText} from '../../cms/story';
import {prismicLinkResolver} from '../../cms/prismic';
import type {StorySliceRichText} from '../../cms/story';
import {
  breakpointDesktopOnly,
  breakpointTabletOnly,
  cssMobileSidePadding,
} from '../../styles/responsive';

// -- HTML Serializer
// This function will be used to change the way the HTML is loaded
const htmlSerializer = (type, element, content, children, key) => {
  const props = {};
  switch (type) {
    // Add a class to paragraph elements
    case Elements.embed:
      if (element.oembed && element.oembed.provider_name === 'Instagram') {
        console.log('element', element);
        const url = get(element, 'oembed.embed_url', '') || '';
        console.log('url', url);
        if (url) {
          return (
            <StyledInstaWrapper key={key}>
              <InstagramEmbed hideCaption url={element.oembed.embed_url} />
            </StyledInstaWrapper>
          );
        }
      }
      return null;
    // Return null to stick with the default behavior
    default:
      return null;
  }
};

const cssAlignRight = css`
  margin-left: auto;
`;

const cssLayoutFull = css`
  width: 100%;
  flex: 0 1 100%;
`;

const cssLayoutHalf = css`
  width: 50%;
  flex: 0 1 calc(50% - 20px);
`;

const StyledContainer = styled.div`
  ${cssMobileSidePadding};

  ${breakpointTabletOnly} {
  }

  ${breakpointDesktopOnly} {
    ${props => (props.alignRight ? cssAlignRight : '')};
    ${props => (props.layoutSize ? cssLayoutHalf : cssLayoutFull)};
  }

  h1 {
    ${cssHeadingOne};
    margin-bottom: 10px;
    ${breakpointTabletOnly} {
      margin-top: 25px;
    }
  }

  h2 {
    ${cssHeadingTwo};
    margin-bottom: 10px;
    ${breakpointTabletOnly} {
      margin-top: 29px;
    }
  }

  h3 {
    ${cssHeadingTwo};
    margin-bottom: 10px;
    ${breakpointTabletOnly} {
      margin-top: 10px;
    }
  }

  h4 {
    ${cssHeadingThree};
    margin-bottom: 10px;
    ${breakpointTabletOnly} {
      margin-top: 10px;
    }
  }

  h6 {
    ${cssNormalTextSmall};
    margin-top: 10px;
  }

  p {
    ${cssNormalText}
    margin-bottom: 40px;

    &:last-of-type {
      margin-bottom: 0px;
    }

    ${breakpointTabletOnly} {
      margin-bottom: 20px;

      &:last-of-type {
        margin-bottom: 0px;
      }
    }
    em {
      ${cssNormalText}
    }
    span.small-paragraph {
      ${cssNormalTextSmall};
    }
    span.normal-paragraph {
      ${cssNormalText};
    }
    span.large-paragraph {
      ${cssNormalTextLarge};
    }
    span.largest-paragraph {
      ${cssNormalTextLargest};
      margin-bottom: 25px;
      em {
        &:not(:first-of-type) {
          margin-top: 87px;
      }
      ${breakpointTabletOnly} {
        &:not(:first-of-type) {
          margin-top: 55px;
        }
      }
    }
  }

  a {
    ${cssAnchorText};
  }

  ul { 
    list-style-type: disc; 
    list-style-position: inside; 
  }
  ol { 
    list-style-type: decimal; 
    list-style-position: inside; 
  }
  ul ul, ol ul { 
    list-style-type: circle; 
    list-style-position: inside; 
    margin-left: 15px; 
  }
  ol ol, ul ol { 
    list-style-type: lower-latin; 
    list-style-position: inside; 
    margin-left: 15px; 
  }
`;

type Props = {
  slice: StorySliceRichText,
  isArticle: boolean | undefined,
};

export const RenderRichText = ({slice}: Props) => {
  return (
    <StyledContainer layoutSize alignRight className="c-rich-text">
      <div wovn-ignore="">{RichText.render(getSliceRichText(slice), prismicLinkResolver, htmlSerializer)}</div>
    </StyledContainer>
  );
};
