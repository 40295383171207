import React from 'react';
import {connectMenu} from 'react-instantsearch-core';
import {ProjectsNav} from '../projects_nav/projects_nav';

export const MainProjectsFilter = connectMenu(({
  refine,
  attribute,
  searchState,
  isCustomActive,
  setIsCustomActive,
}) => {
  const currentAppliedFilter = searchState?.menu && searchState.menu[attribute];

  const isAllTabActive = !currentAppliedFilter && !isCustomActive;
  const isFundingActive = currentAppliedFilter === 'funding';
  const isPurchaseActive = currentAppliedFilter === 'sell';

  const links = [
    {label: 'All projects', value: 'all', isActive: isAllTabActive},
    {label: 'Crowdfunding', value: 'funding', isActive: isFundingActive},
    {label: 'Purchase', value: 'sell', isActive: isPurchaseActive},
  ];

  const handleChange = (event, item) => {
    event.preventDefault();
    refine(item.value === 'all' ? null : item.value);
    setIsCustomActive(false);
  };

  return (
    <ProjectsNav items={links} onChange={handleChange} />
  );
});
