// @flow
import React from 'react';
import styled from 'styled-components';
import {breakpointDesktopOnly, breakpointTabletOnly} from 'prismic/shared/styles/responsive';

const StyledContainer = styled.div`
  ${breakpointDesktopOnly} {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 0px 40px;
    column-gap: 40px;
    & > * {
      flex: 0 1 calc(50% - 20px);
      width: 50%;
      margin-top: 0px;
      margin-bottom: 100px;
    }
  }
  ${breakpointTabletOnly} {
    flex-wrap: wrap;
    & > * {
      flex: 0 1 100%;
      margin-bottom: 40px;
    }
  }
`;

type Props = {
  children: any,
  index?: Number,
};

export const ArticleLayoutWrapper = ({children, index}: Props) => <StyledContainer index={index}>{children}</StyledContainer>;
