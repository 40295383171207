import React, {useState} from 'react';

import ApiHelper from 'utils/api_helper';

import {ReactLink} from 'shared/components/links/react_link/react_link';
import {CheckboxField} from 'shared/components/form/checkbox_field/checkbox_field';
import {FieldsGroup} from 'shared/components/form/grid_containers/fields_group/fields_group';

import BigEyesStickerPath from 'images/common/sticker/big-eyes.svg';
import PressOnStickerPath from 'images/common/sticker/press-on.svg';

export const UnsubscriptionScreen = ({user, groupId}) => {
  const emailSettingsUrl = `${location.origin}/account/settings#mail-subscriptions`;
  const [formParams, setFormParams] = useState({
    group_ids: {[groupId]: true},
    reasons: {},
    token: user.mailer_token,
  });
  const [showSucessMessage, setShowSuccessMessage] = useState(false);
  const [isLocked, setIsLocked] = useState(false);

  const reasons = {
    first: 'I am getting too many notifications.',
    second: 'The notifications are no longer relevant for me.',
    third: 'I didn\'t subscribe for these types of notifications.',
  };

  const handleChange = (event) => {
    const checkboxes = document.querySelectorAll("[name='first']:checked, [name='second']:checked, [name='third']:checked");
    const formReasons = [];
    const newFormParams = {...formParams};
    checkboxes.forEach((item) => {
      formReasons.push(reasons[item.name]);
    });
    newFormParams.reasons[groupId] = formReasons.join(' ');
    setFormParams(newFormParams);
  };

  const handleButtonClick = () => {
    setIsLocked(true);
    ApiHelper.post('/api/v1/users/subscribe_notifications', formParams).then((res) => {
      if (res.response) {
        setIsLocked(false);
        setShowSuccessMessage(true);
      }
    });
  };

  return (
    <div className="unsubscription-screen">
      <div className="unsubscription-screen__content">
        <div className="unsubscription-screen__header">
          <h1 className="unsubscription-screen__title">Unsubscription</h1>
          <img className="unsubscription-screen__header-sticker" src={BigEyesStickerPath} alt="header-sticker" />
        </div>
        <h2 className="unsubscription-screen__sub-title">
          What a pity, that you want to unsubscribe :(&nbsp;
          <span>We will be missing you!</span>
        </h2>
        {showSucessMessage && (
          <p className="unsubscription-screen__description">
            Thank you!
          </p>
        )}
        {!showSucessMessage && (
          <>
            <p className="unsubscription-screen__description">Please, choose the reason of unsubscribing, it will help us becomming better!</p>
            <FieldsGroup className="unsubscription-screen__fields" type="radio">
              <CheckboxField
                name="first"
                label={reasons.first}
                onChange={handleChange}
                disabled={isLocked}
              />
              <CheckboxField
                name="second"
                label={reasons.second}
                onChange={handleChange}
                disabled={isLocked}
              />
              <CheckboxField
                name="third"
                label={reasons.third}
                onChange={handleChange}
                disabled={isLocked}
              />
            </FieldsGroup>
            <img className="unsubscription-screen__bottom-sticker" src={PressOnStickerPath} alt="bottom-sticker" />
            <div className="unsubscription-screen__unsubscribe-descr">
              Click on 'Unsubscribe' to stop receiving emails from this sender on this email address :&nbsp;
              <span>{user.email}</span>
            </div>
            <div className="unsubscription-screen__button">
              <button
                type="button"
                className="c-button c-button--black c-button--large c-button--full"
                onClick={handleButtonClick}
                disabled={isLocked}
              >
                UNSUBSCRIBE
              </button>
            </div>
          </>
        )}
        <ReactLink
          className="unsubscription-screen__settings-link"
          text="MANAGE EMAIL SETTING FROM ACCOUNT DASHBOARD"
          path={emailSettingsUrl}
          theme="small"
        />
      </div>
    </div>
  );
};
