import React from 'react';
import {ReactButton} from 'shared/components/buttons/react_button/react_button';
import {CuratedFeaturedMedium} from '../../components/curated_featured/curated_featured_medium';
import {CuratedFeaturedFallback} from '../../components/curated_featured/curated_featured_fallback';
import {CuratedHeading} from '../components/curated_heading/curated_heading';

export const ReleaseOfTheWeekSection = ({
  heading,
  buttonLabel,
  buttonLink,
  projectLinkLabel,
  release,
  isError,
  isLoading,
}) => {
  if (isError) return <CuratedFeaturedFallback />;
  if (isLoading) return <div />;

  return (
    <div className="c-curated-recommends__wrapper" wovn-ignore="">
      <div className="c-curated-recommends__heading-container u-hidden-tablet">
        <CuratedHeading heading={heading} hideAt="tablet" />
        <ReactButton className="c-curated-recommends__top-button" text={buttonLabel} link={buttonLink} type="link" />
      </div>
      {release && (
        <CuratedFeaturedMedium
          image={release.project.rendered_image_url}
          backgroundColor={release.background_color}
          fontColor={release.font_color || '#000'}
          article={release}
          text={release.text}
          labelTheme="white"
          linkLabel={projectLinkLabel}
          hideAt="tablet"
        />
      )}
      <div className="c-curated-recommends__bottom-button-container u-hidden-desktop-only">
        <ReactButton className="c-curated-recommends__bottom-button" text={buttonLabel} link={buttonLink} type="link" />
      </div>
    </div>
  );
};
