import React, {useEffect, useState, useRef} from 'react';
import axios from 'axios';
import {isEqual} from 'lodash';
import camelcaseKeys from 'camelcase-keys';
import {QratesVisualizer} from '@tdmsinc/qrates-visualizer';
import ProjectSpec from 'models/project_spec';
import {visualizerAssets} from 'project_start/components/utils/visualizer/assets';
import {imagesMapping} from './utils/images_mapping';

export const RendererScreen = ({projectId, qratesFilesUrl, qratesFilesToken}) => {
  const constants = window.constants;
  const manifest = constants.manifest;
  const [itemPageDesignId, setItemPageDesignId] = useState(null);
  const [projectFileTypes, setProjectFileTypes] = useState([]);
  const [itemPageDesignFileTypes, setItemPageDesignFileTypes] = useState([]);
  const [spec, setSpec] = useState(null);
  const [artworkFiles, setArtworkFiles] = useState(null);
  const [productType, setProducctType] = useState(null);
  const [uploadedImages, setUploadedImages] = useState([]);
  const imagesMap = imagesMapping({
    spec,
    productType,
    projectId,
    itemPageDesignId,
    projectFileTypes,
    itemPageDesignFileTypes,
  }).map((image, index) => ({...image, index}));
  const [currentImage, setCurrentImage] = useState(imagesMap[0]);
  const visualizerContainerRef = useRef();
  const previewRef = useRef();
  const logRef = useRef();
  const isAllImagesRendered = uploadedImages.length > 0 && isEqual(uploadedImages.map((i) => i.title).sort(), imagesMap.map((i) => i.title).sort());
  const options = {
    renderCallback: (gl, scene, camera, active) => renderImage(gl, scene, camera, active),
    preserveDrawingBuffer: true,
    viewIndex: currentImage?.view,
    step: 1,
    animating: false,
    cameraDefaults: {
      position: [0, 0, 0.8],
      fov: 50,
      zoom: currentImage?.zoom || 0,
    },
  };
  const assets = visualizerAssets({artworkFiles, productType});

  const renderImage = (gl, scene, camera, active) => {
    if (active === 1) return false;

    gl.setSize(...currentImage.dimensions);
    if (camera.name === 'orthographic') {
      camera.left = camera.bottom = -475;
      camera.right = camera.top = 475;
    }
    camera.aspect = currentImage.dimensions[0] / currentImage.dimensions[1];
    camera.fov = 20;
    camera.zoom = currentImage.cameraZoom?.[spec.vinyl_size] || camera.zoom;
    camera.position.x = currentImage.position?.[spec.vinyl_size]?.x || camera.position.x;
    camera.updateProjectionMatrix();
    gl.toneMappingExposure = 0.6;
    gl.render(scene, camera);
    const image = new Image();
    image.style.height = '50px';
    image.style.border = '1px solid blue';
    logProcess(`${currentImage.title} rendered.`);
    image.src = gl.domElement.toDataURL();
    uploadRemoteFile({imageUrl: image.src, imageParams: currentImage});
    previewRef.current.append(image);
    const nextImage = imagesMap.find((image) => image.index === (currentImage.index + 1));
    setCurrentImage(nextImage);
  };

  const uploadRemoteFile = ({imageUrl, imageParams, attempt = 1}) => {
    const blob = base64ToBlob(imageUrl);
    const formData = new FormData();
    formData.append('stored_file[file]', blob, 'image.png');
    formData.append('stored_file[item_id]', imageParams.item_id);
    formData.append('stored_file[item_type]', imageParams.item_type);
    formData.append('stored_file[file_type_id]', imageParams.file_type_id);
    formData.append('stored_file[state]', 'pending');
    axios.post(
      `${qratesFilesUrl}/stored_files`,
      formData,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: qratesFilesToken,
        },
      },
    ).then((response) => {
      setUploadedImages((state) => [...state, {title: imageParams.file_type_value, image: response.data}]);
    }).catch((error) => {
      if (attempt <= 3) uploadRemoteFile({imageUrl, imageParams, attempt: attempt + 1});

      throw error;
    });
  };

  const logProcess = (line, options = {}) => {
    const element = document.createElement('p');
    element.id = options.id;
    element.innerText = line;
    logRef.current.append(element);
  };

  useEffect(() => {
    isAllImagesRendered && uploadFilesProcess();
  }, [uploadedImages.length]);

  useEffect(() => {
    !currentImage && setCurrentImage(imagesMap[0]);
  }, [imagesMap.length]);

  useEffect(() => {
    if (!spec && !productType && !artworkFiles) {
      axios.get(
        `/renderer/${projectId}.json`,
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'Access-Control-Allow-Headers': 'Content-Type',
            'Access-Control-Allow-Origin': window.location.origin,
            'Access-Control-Allow-Methods': 'OPTIONS,POST,GET',
          },
        },
      ).then((response) => {
        response.data.spec && setSpec(ProjectSpec.fromJSON(response.data.spec).toJS());
        setItemPageDesignId(response.data.item_page_design_id);
        setProjectFileTypes(response.data.project_file_types);
        setItemPageDesignFileTypes(response.data.item_page_design_file_types);
        setArtworkFiles(response.data.artwork_files);
        setProducctType(response.data.product_type);
      });
    }
  }, []);

  const base64ToBlob = (base64) => {
    const base64Data = base64.split(',')[1];
    const data = window.atob(base64Data);
    const buff = new ArrayBuffer(data.length);
    const arr = new Uint8Array(buff);
    let i;
    let dataLen;
    for (i = 0, dataLen = data.length; i < dataLen; i++) {
      arr[i] = data.charCodeAt(i);
    }

    return new Blob([arr], {type: 'image/png'});
  };

  const uploadFilesProcess = async () => {
    logProcess('files uploading...', {id: 'files_uploading'}); // id required by lambda function
    uploadFiles();
  };

  const uploadFiles = async () => {
    axios.patch(
      `/renderer/${projectId}`,
      {images: uploadedImages.map((i) => i.image)},
      {
        headers: {
          'X-CSRF-Token': document.getElementsByName('csrf-token').item(0).content,
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      },
    ).then(() => {
      logProcess('uploading finished.', {id: 'uploading_finished'}); // id required by lambda function
    }).catch((err) => {
      logProcess('!!! something went wrong !!!');
      logProcess(err);
    });
  };

  if (!spec || !artworkFiles || !manifest || !productType) return false;

  return (
    <>
      <div ref={previewRef} />
      {currentImage && (
        <div
          className="vvContainer"
          style={{height: '769px', width: '882px'}}
          ref={visualizerContainerRef}
        >
          <QratesVisualizer
            options={options}
            assets={assets}
            spec={camelcaseKeys(spec, {deep: true})}
            format={productType}
          />
        </div>
      )}
      <div ref={logRef} />
    </>
  );
};
