import React from 'react';
import {connectStateResults} from 'react-instantsearch-dom';

export default connectStateResults(
  ({searchState, searchResults, children}) => (searchResults && searchResults.nbHits !== 0 ? (
    children
  ) : (
    <h1 style={{textAlign: 'center'}}>
      No results have been found for
      {' '}
      {searchState.query}
      .
    </h1>
  )),
);
