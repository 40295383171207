import React from 'react';
import classNames from 'classnames';

import {formatMoney} from 'utils/format_money';

import {ProjectPreview} from './preview';
import {ArtistProjectState} from './artist_state';
import {GenresList} from './components/genres_list/genres_list';

export const PressOnlyProjectCard = ({
  project,
  section,
  href,
  className,
  isResponsive = false,
}) => {
  const {
    objectID,
    title,
    artist_name,
    price,
    currency,
    genres,
    tags,
  } = project;

  const genresList = genres && genres.length > 0 ? genres : tags;

  const renderProjectState = () => {
    if (section == 'dashboard') {
      return <ArtistProjectState project={project} />;
    } else {
      return <div className="c-project__state-details">PRESS ORDER</div>;
    }
  };

  const renderSection = () => {
    switch (section) {
      case 'dashboard':
        return <ArtistProjectState project={project} />;
      default:
        return (
          <div className="c-project__meta">
            <div className="c-project__title" wovn-ignore="">
              {title}
            </div>
            <div className="c-project__artist-name" wovn-ignore="">
              {artist_name}
            </div>
            {genresList && genresList.length > 0
              && <GenresList className="c-project__genres-list" items={genresList} cropToContainerSize />}
            <hr className="c-project__divider" />
            <div className="c-project__state">
              <div className="c-project__state-right">
                <div className="c-project__state-details">
                  {formatMoney({amount: price, currency})}
                </div>
              </div>
              <div className="c-project__state-left">
                {renderProjectState()}
              </div>
            </div>
          </div>
        );
    }
  };

  return (
    <div
      className={classNames('c-project c-project--press-only', {
        [className]: className,
        'c-project--fixed-width': !isResponsive,
      })}
      key={objectID}
    >
      <div className="c-project__container">
        <ProjectPreview params={{project, section}} />
        {renderSection()}
        <a className="c-project__link" href={href} target="_blank"> </a>
      </div>
    </div>
  );
};
