import React, {useState, useEffect} from 'react';
import ApiHelper from 'utils/api_helper';
import classNames from 'classnames';
import {fundingProjectShippingApproximation} from 'utils/date';
import {shippingCondition} from 'utils/shipping_condition';

export const ResultOrderCard = ({
  className,
  order,
  orderType,
  facebookAppId,
}) => {
  const [projectDetails, setProjectDetails] = useState({});
  const fundingType = orderType === 'funding';
  const remainingDaysFunding = projectDetails?.selling_condition?.left_days;
  const baseFundingQuantity = projectDetails?.selling_condition?.base_quantity;
  const orderedFundingQuantity = projectDetails?.selling_condition?.ordered_quantity;
  const isVinylProject = projectDetails?.product_order?.type == 'Product::VinylOrder';
  const finishDate = projectDetails?.selling_condition?.finish_date;
  const hasSucceeded = projectDetails?.selling_condition?.is_succeeded;
  let approximateShipTiming;
  if (finishDate) {
    const {approximatedMonth} = fundingProjectShippingApproximation({finishDate, isVinylProject});
    approximateShipTiming = hasSucceeded ? `Ships ${approximatedMonth}` : `Ships ${approximatedMonth} if successful`;
  }
  const getDetails = () => {
    const searchParams = new URLSearchParams();
    searchParams.append(
      'except_attributes',
      [
        'project_card_image_url',
        'rendered_image_url',
        'sleeve_thumbnail_image_url',
        'vinyl_thumbnail_image_url',
        'vinyl2_thumbnail_image_url',
        'cassette_thumbnail_image_url',
        'case_thumbnail_image_url',
        'item',
      ],
    );
    ApiHelper.get(`/api/v1/projects/public/${order.project_id}?${searchParams}`).then((res) => {
      setProjectDetails(res.response);
    });
  };

  useEffect(getDetails, [order]);

  const shareResult = ({provider, projectId, message}) => {
    let url;
    const shareUrl = window.encodeURIComponent(`${window.location.origin}/projects/${projectId}`);
    switch (provider) {
      case 'twitter':
        url = `https://twitter.com/share?url=${shareUrl}&hashtags=vinylize,qrates&text=${window.encodeURIComponent(message)}`;

        break;
      case 'facebook':
        url = `https://www.facebook.com/dialog/share?app_id=${facebookAppId}&href=${shareUrl}&redirect_uri=${shareUrl}`;

        break;
    }

    window.open(url);
  };

  return (
    <div
      className={classNames('c-result-order-card', {
        [className]: className,
      })}
    >
      <a className="c-result-order-card__image" href={`/projects/${order.project_id}`}>
        <img src={order.thumbnail_image_url} />
      </a>
      <div className="c-result-order-card__info">
        <div className="c-result-order-card__title" wovn-ignore="">{order.project_title}</div>
        <div className="c-result-order-card__name" wovn-ignore="">{order.artist_name}</div>
        {fundingType && (
          <div className="c-result-order-card__date">
            <span className="c-result-order-card__unit u-hidden-phone">{`${orderedFundingQuantity ?? '-'}/${baseFundingQuantity ?? '-'}`}</span>
            <span className="c-result-order-card__days-left u-hidden-phone" data-testid="days-left">{`${remainingDaysFunding ?? '...'} DAYS LEFT`}</span>
            <span className="c-result-order-card__estimated-shipping">{approximateShipTiming}</span>
          </div>
        )}
        {!fundingType && (
          <div className="c-result-order-card__date">{shippingCondition(order)}</div>
        )}
        <div className="c-result-order-card__share-block">
          <p>Share</p>
          <i
            onClick={() => shareResult({provider: 'facebook', projectId: order.project_id})}
            className="c-pictogram c-pictogram--facebook"
          />
          <i
            onClick={() => shareResult({provider: 'twitter', projectId: order.project_id, message: order.share_message})}
            className="c-pictogram c-pictogram--twitter-fill"
          />
        </div>
      </div>
    </div>
  );
};
