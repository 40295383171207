import React, {useState, useEffect} from 'react';
import classNames from 'classnames';
import {isNaN} from 'lodash';

import {amountToValue, valueToAmount} from 'utils/format_money';

import CurrencyDropdown from '../currency_dropdown';

export const PriceCurrencyInput = ({
  price,
  onChangePrice,
  currency,
  onChangeCurrency,
  readOnly,
  inputName,
  inputClassName,
  className,
  inputKey,
  currencyAlign,
  min = 0,
  step = 0.01,
  size,
}) => {
  const currencyMark = {
    USD: '$',
    GBP: '£',
    JPY: '¥',
    EUR: '€',
  };

  const [value, setValue] = useState(amountToValue({amount: price, currency}));

  currencyAlign = currencyAlign == 'right' ? 'right' : 'left';

  const preparePrice = (price) => {
    const newPrice = price < min ? min : price;

    return newPrice;
  };

  const handleOnChange = (e) => {
    let newPrice = e.target.value
      .replace(',', '.')
      .replace(/[^0-9.,-]+/g, '')
      .replace(/(-?\d+[.,]?\d{0,2})[\d.]*/, '$1');
    newPrice = preparePrice(newPrice);

    setValue(newPrice);
  };

  const variatePrice = ({action}) => {
    const actions = {increment: +step, decrement: -step};
    const parsedValue = parseFloat(value);
    let newPrice = ((isNaN(parsedValue) ? 0 : parsedValue) + parseFloat(actions[action])).toFixed(2);
    newPrice = preparePrice(newPrice);
    setValue(newPrice);
  };

  const handleKeyDown = (e) => {
    const actions = {ArrowUp: 'increment', ArrowDown: 'decrement', Enter: 'enter'};
    const action = actions[e.key];
    if (!action) return false;

    e.preventDefault();
    if (action === 'enter') {
      return onChangePrice(valueToAmount({amount: value, currency}));
    }
    variatePrice({action});
  };

  const input = (
    <span className={classNames('c-input-addon--front c-input-addon--currency-input',
      {[className]: className},
    )}
    >
      <span className={`c-input-addon__unit c-input-addon__unit--${currencyAlign}`}>
        {currencyMark[currency]}
      </span>
      <input
        className={classNames('c-input-addon__input', {[inputClassName]: inputClassName})}
        type="text"
        data-testid={inputName}
        name={inputName}
        key={inputKey}
        value={value}
        onChange={handleOnChange}
        onKeyDown={handleKeyDown}
        onBlur={() => onChangePrice(valueToAmount({amount: value, currency}))}
        readOnly={readOnly}
        size={size}
      />
    </span>
  );

  useEffect(() => {
    setValue(amountToValue({amount: price, currency}));
  }, [price]);

  if (!onChangeCurrency) {
    return input;
  }

  return (
    <div
      className="p-average-selecter__input-area"
      name={inputName}
    >
      <CurrencyDropdown
        name="currency"
        onChange={onChangeCurrency}
        currency={currency}
        readOnly={readOnly}
        customClass="c-select--average"
      />
      {input}
    </div>
  );
};
