import React, {useRef, useState} from 'react';
import Color from 'color';
import classNames from 'classnames';

import {assetPath} from 'project_start/components/utils/visualizer/assets_helper';
import {useClickOutside} from 'utils/use_click_outside';
import ApiHelper from 'utils/api_helper';

export const FeaturedProject = ({project, className}) => {
  const {
    objectID,
    slug,
    project_type,
    label_state,
    main_visual_type,
    background_color,
    background_image_url,
    rendered_image_url,
    cover_image_url,
    title,
    state,
    on_sale,
    condition_message,
    restart_requests_count,
    repress,
  } = project;

  const isDraft = state == 'draft';
  const dashboardProjectPath = `/dashboard/projects/${slug}`;
  const draftProjectPath = `/projects/${slug}/start`;
  const setupProjectPath = isDraft ? draftProjectPath : dashboardProjectPath;
  const deleteProjectPath = `/api/v1/projects/${objectID}`;

  const backgroundStyle = () => {
    const backgroundStyleColor = {
      backgroundColor: background_color || '#E4E5DE',
    };

    switch (main_visual_type) {
      case 'main_visual_image':
        if (background_image_url) {
          return {
            backgroundImage: `url(${background_image_url})`,
            opacity: 0.6,
          };
        }

        return backgroundStyleColor;
      case 'main_visual_user_image':
      case 'main_visual_video':
        return {
          backgroundImage: `url(${cover_image_url})`,
          opacity: 0.6,
        };
      default:
        return backgroundStyleColor;
    }
  };

  let textColor = 'black';
  if (main_visual_type == 'main_visual_image'
      && !background_image_url
      && !Color(background_color || 'white').isLight()) {
    textColor = 'white';
  }

  const projectMenu = useRef();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toogleMenu = (e) => {
    setIsMenuOpen(!isMenuOpen);
  };

  const deleteProject = (e) => {
    e.preventDefault();
    if (window.confirm('Are you sure you want to delete the project?')) {
      ApiHelper.delete(deleteProjectPath).then((res) => window.location.reload());
    }
  };

  useClickOutside(
    [projectMenu],
    isMenuOpen,
    (e) => toogleMenu(e),
  );

  const renderProjectMenu = () => {
    return (
      <div className="c-your-projects__popover">
        <div className="c-your-projects__menu-icon" onClick={toogleMenu} />
        {isMenuOpen && (
          <ul className="c-your-projects__popover__menu" ref={projectMenu}>
            <li className="c-your-projects__popover__link">
              <a href={dashboardProjectPath}>GO TO PROJECT &#8594;</a>
            </li>
            {!isDraft && (
              <li className="c-your-projects__popover__link">
                <a href={`${dashboardProjectPath}/settings`}>SETTINGS</a>
              </li>
            )}
            {project_type != 'press_only' && (
            <>
              <li className="c-your-projects__popover__link">
                <a href={`${dashboardProjectPath}/payments`}>PAYMENTS</a>
              </li>
              <li className="c-your-projects__popover__link">
                <a href={`${dashboardProjectPath}/promote`}>SHARE</a>
              </li>
            </>
            )}
            {isDraft && (
              <li className="c-your-projects__popover__link">
                <a onClick={deleteProject}>DELETE</a>
              </li>
            )}
          </ul>
        )}
      </div>
    );
  };

  const manifest = window.constants.manifest;

  return (
    <section
      className={classNames('c-your-projects', {[className]: className})}
    >
      <div className="c-your-projects__bg" style={backgroundStyle()} />
      {renderProjectMenu()}
      <a href={setupProjectPath} className="c-your-projects__image">
        {rendered_image_url && <img src={assetPath(rendered_image_url, manifest)} />}
      </a>
      <div className="c-your-projects__container">
        <div className="c-label--container">
          <div className={on_sale ? 'c-label--selling' : 'c-label--working'}>{label_state}</div>
          {repress && <div className="c-label--working">REPRESS</div>}
        </div>
        <div className={`c-your-projects__title c-your-projects__title--${textColor}`} wovn-ignore="">{title}</div>
        {condition_message && (
          <div className={`c-your-projects__description c-your-projects__description--${textColor}`}>
            {condition_message}
            {restart_requests_count > 0 && (
              <span className="c-project__repress-requests">{`${restart_requests_count} ${restart_requests_count > 1 ? 'Requests' : 'Request'}`}</span>
            )}
          </div>
        )}
        <a href={setupProjectPath} className="c-button c-button--big c-button--black c-button--next">
          GO TO PROJECT
        </a>
      </div>
    </section>
  );
};
