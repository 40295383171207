import React from 'react';
import {render, screen, waitFor} from '@testing-library/react';

import {OnQratesBanner} from '../on_qrates_banner';

jest.mock('prismic/shared/cms/prismic', () => {
  return {
    prismicClient: {
      query: () => {
        return Promise.resolve({
          results: [
            {
              data: {
                artists: [
                  {name: [{text: 'artist_name_1'}], url: {url: 'artist_url_1'}},
                  {name: [{text: 'artist_name_2'}], url: {url: 'artist_url_2'}},
                  {name: [{text: 'artist_name_3'}], url: {url: 'artist_url_3'}},
                  {name: [{text: 'artist_name_4'}], url: {url: 'artist_url_4'}},
                  {name: [{text: 'artist_name_5'}], url: {url: 'artist_url_5'}},
                  {name: [{text: 'artist_name_6'}], url: {url: 'artist_url_6'}},
                ],
              },
            },
          ],
        });
      },
    },
  };
});

it('renders artist 1', async () => {
  render(<OnQratesBanner />);

  await waitFor(() => {
    expect(screen.getByText('artist_name_1')).toHaveAttribute('href', 'artist_url_1');
  });
});

it('renders artist 2', async () => {
  render(<OnQratesBanner />);

  await waitFor(() => {
    expect(screen.getByText('artist_name_2')).toHaveAttribute('href', 'artist_url_2');
  });
});

it('renders artist 3', async () => {
  render(<OnQratesBanner />);

  await waitFor(() => {
    expect(screen.getByText('artist_name_3')).toHaveAttribute('href', 'artist_url_3');
  });
});

it('renders artist 4', async () => {
  render(<OnQratesBanner />);

  await waitFor(() => {
    expect(screen.getByText('artist_name_4')).toHaveAttribute('href', 'artist_url_4');
  });
});

it('renders artist 5', async () => {
  render(<OnQratesBanner />);

  await waitFor(() => {
    expect(screen.getByText('artist_name_5')).toHaveAttribute('href', 'artist_url_5');
  });
});

it('renders artist 6', async () => {
  render(<OnQratesBanner />);

  await waitFor(() => {
    expect(screen.getByText('artist_name_6')).toHaveAttribute('href', 'artist_url_6');
  });
});
