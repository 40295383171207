import React, {useState} from 'react';

import {OrderButton} from 'src/public/projects/order_button/order_button';
import {WatchButton} from './watch_button';

export const StatusButtons = ({project}) => {
  const [projectJSON, setProject] = useState(project);

  return (
    <>
      {!projectJSON.is_item_draft && (
        <OrderButton projectJSON={projectJSON} setProject={setProject} />
      )}
      <WatchButton watching={projectJSON.watching} />
    </>
  );
};
