import React from 'react';
import {RichText} from 'prismic-reactjs';
import classNames from 'classnames';

import {NewLabel} from 'shared/components/labels/new_label/new_label';
import {PublicPagesHeading} from 'shared/components/content/public_pages_heading/public_pages_heading';

export const CardCategorySection = ({
  id,
  title,
  label,
  description,
  coverImage,
  imageAlign = 'right',
  backgroundColor = '#FFFFFF',
}) => {
  return (
    <div
      className={classNames('c-card-category-section', {'c-card-category-section--image-left': imageAlign === 'left'})}
      style={{backgroundColor}}
      id={id}
    >
      <div className="c-card-category-section__container">
        <div className="c-card-category-section__content">
          <NewLabel className="c-card-category-section__label" title={label} />
          <div className="c-card-category-section__text">
            <PublicPagesHeading
              className="c-card-category-section__title"
              theme="middle-size"
              headingTagValue={3}
            >
              {title}
            </PublicPagesHeading>
            <p wovn-ignore="" className="c-card-category-section__description">{RichText.asText(description)}</p>
          </div>
        </div>
        <div className="c-card-category-section__image-side">
          <div className="c-card-category-section__cover-image">
            <img src={coverImage} alt="cover" />
          </div>
        </div>
      </div>
    </div>
  );
};
