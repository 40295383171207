import React from 'react';
import {ProcessDesktop} from './desktop/process_desktop';
import {ProcessMobile} from './mobile/process_mobile';

export const ForArtistsDetailsSection = ({
  detailsTitle,
  detailsTitleMobile,
  detailsLabelArtist,
  detailsLabelQrates,
  detailsIntroTitleArtist,
  detailsIntroTitleQrates,
  detailsIntroTextArtist,
  detailsIntroTextQrates,
  detailsDesktop,
  detailsMobileArtist,
  detailsMobileQrates,
  detailsMobileArtistFinal,
  detailsCTAText,
  detailsCTALabel,
}) => {
  return (
    <>
      <ProcessDesktop
        detailsTitle={detailsTitle}
        detailsLabelArtist={detailsLabelArtist}
        detailsLabelQrates={detailsLabelQrates}
        detailsIntroTitleArtist={detailsIntroTitleArtist}
        detailsIntroTitleQrates={detailsIntroTitleQrates}
        detailsIntroTextArtist={detailsIntroTextArtist}
        detailsIntroTextQrates={detailsIntroTextQrates}
        detailsDesktop={detailsDesktop}
        detailsCTAText={detailsCTAText}
        detailsCTALabel={detailsCTALabel}
      />
      <ProcessMobile
        detailsTitleMobile={detailsTitleMobile}
        detailsLabelArtist={detailsLabelArtist}
        detailsLabelQrates={detailsLabelQrates}
        detailsIntroTitleArtist={detailsIntroTitleArtist}
        detailsIntroTitleQrates={detailsIntroTitleQrates}
        detailsIntroTextArtist={detailsIntroTextArtist}
        detailsIntroTextQrates={detailsIntroTextQrates}
        detailsMobileArtist={detailsMobileArtist}
        detailsMobileQrates={detailsMobileQrates}
        detailsMobileArtistFinal={detailsMobileArtistFinal}
        detailsCTAText={detailsCTAText}
        detailsCTALabel={detailsCTALabel}
      />
    </>
  );
};
