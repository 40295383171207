import React from 'react';

export const NoProjectsSection = ({content}) => {
  return (
    <div className="c-no-projects-section">
      <h2 className="c-no-projects-section__title">{content.title}</h2>
      <div className="c-no-projects-section__image"><img src={content.image} alt="" /></div>
      <div className="c-no-projects-section__button"><a className="c-button c-button--black c-button--big-padding" href="">{content.buttonTitle}</a></div>
    </div>
  );
};
