import React, {useState} from 'react';
import {connectInfiniteHits, Index} from 'react-instantsearch-dom';
import {map} from 'lodash';

import {formatDate} from 'utils/date';
import {useLanguageChange} from 'utils/use_language_change';
import {CatalogGrid} from 'src/shared/components/catalog/catalog_grid/catalog_grid';
import {ListsCard} from 'src/shared/components/cards/lists_card/lists_card';
import Stats from './custom_stats';
import SortBy from './custom_sort_by';
import {ProjectCard} from '../shared/project_cards/project';
import {StoryCard} from '../shared/project_cards/story_card';

const renderHit = (hit, indexName) => {
  const artistHit = () => {
    return (
      <a className="c-artists__container" href={hit.path} target="_blank">
        <div className="c-artists__image" style={{backgroundImage: `url(${hit.profile_image_url})`}} />
        <p className="c-artists__title" wovn-ignore="">{hit.name}</p>
        <p className="c-artists__name" wovn-ignore="">{hit.genre}</p>
      </a>
    );
  };

  const listHit = () => (
    <ListsCard
      label="LIST"
      subtitle={hit.subtitle}
      title={hit.title}
      link={hit.path}
      quote={hit.curator}
      image={hit.cover_image}
    />
  );

  const storyCard = () => (
    <StoryCard
      label={hit.story_category}
      subtitle={hit.preview_pre_heading}
      title={hit.preview_heading}
      text={hit.preview_text}
      date={formatDate(hit.published_date, '%B %d %Y')}
      image={hit.preview_image}
      link={hit.path}
    />
  );

  switch (true) {
    case /Project/.test(indexName):
      return <ProjectCard project={hit} isResponsive />;
    case /Artist/.test(indexName):
      return artistHit();
    case /Prismic::List/.test(indexName):
      return listHit();
    case /Prismic::Story/.test(indexName):
      return storyCard();
    default:
      return null;
  }
};

const CustomHits = ({
  hits, onKeywordSelected, searchQuery, onSwitchTab, indexName,
}) => {
  const [locale, setLocale] = useState(window.constants.prismic_locales[window.constants.locale || 'en']);
  const defaultKeywords = {
    vulfpeck: 'vulfpeck',
    chill: 'chill',
    hiphop: 'hiphop',
    electronic: 'electronic',
    indie: 'indie',
    funk: 'funk',
    'r&b': 'r&b',
    rock: 'rock',
  };

  const titles = {
    Project: 'Records',
    Artist: 'Artists & Labels',
    [`Prismic::Story_${locale}`]: 'Stories',
    [`Prismic::List_${locale}`]: 'Lists',
  };

  const defaultKeywordList = Object.keys(defaultKeywords).map((key) => {
    return (
      <div
        key={key}
        className="c-tag--project"
        onMouseDown={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onKeywordSelected(defaultKeywords[key]);
        }}
      >
        {defaultKeywords[key]}
      </div>
    );
  });

  useLanguageChange((event) => {
    setLocale(window.constants.prismic_locales[event.detail.locale || 'en']);
  });

  if (!searchQuery) {
    return (
      <div className="c-search-modal__popular">
        <div className="c-search-modal__popular__label">
          <p>Popular searches</p>
        </div>
        <div className="c-search-modal__popular__tags" wovn-ignore="">
          {defaultKeywordList}
        </div>
      </div>
    );
  } else {
    return (
      <>
        <nav className="c-search-modal__menu">
          <ul>
            {map(titles, (value, key) => (
              <li>
                <Index indexName={key}>
                  <a
                    status={indexName == key ? 'active' : ''}
                    onClick={() => onSwitchTab(key)}
                  >
                    {value.toUpperCase()}
                    <Stats />
                  </a>
                </Index>
              </li>
            ))}
          </ul>
        </nav>
        <br />
        <Stats headerText={titles[indexName]} />
        <div className="c-search-modal__content">
          {indexName == 'Project' && (
            <>
              <Index indexName="Project">
                <SortBy
                  className="c-search-modal__sort-by c-search-modal__sort-by--projects"
                  defaultRefinement="Project_ordered_quantity"
                  items={[
                    {value: 'Project_ordered_quantity', label: 'Popularity'},
                    {value: 'Project_start_date', label: 'Launch date'},
                    {value: 'Project_finish_date', label: 'Remaining time'},
                    {value: 'Project_artist_name', label: 'Artist name'},
                    {value: 'Project_title', label: 'Title'},
                  ]}
                />
              </Index>
              <CatalogGrid className="c-search-modal__projects-catalog" type="projects">
                {hits.map((hit) => renderHit(hit, indexName))}
              </CatalogGrid>
            </>
          )}
          {indexName == 'Artist' && (
            <>
              <Index indexName="Artist">
                <SortBy
                  className="c-search-modal__sort-by"
                  defaultRefinement="Artist"
                  items={[
                    {value: 'Artist', label: 'Fans count'},
                    {value: 'Artist_projects_count', label: 'No. of Projects'},
                    {value: 'Artist_name', label: 'A-Z'},
                  ]}
                />
              </Index>
              <div className="c-artists__list">
                {hits.map((hit) => renderHit(hit, indexName))}
              </div>
            </>
          )}
          {indexName == `Prismic::Story_${locale}` && (
            <>
              <Index indexName={`Prismic::Story_${locale}`}>
                <SortBy
                  className="c-search-modal__sort-by"
                  defaultRefinement={`Prismic::Story_${locale}_published_date_desc`}
                  items={[
                    {value: `Prismic::Story_${locale}_published_date_desc`, label: 'Published date'},
                    {value: `Prismic::Story_${locale}_title_asc`, label: 'Title'},
                  ]}
                />
              </Index>
              <CatalogGrid className="c-search-modal__catalog" type="stories">
                {hits.map((card) => renderHit(card, indexName))}
              </CatalogGrid>
            </>
          )}
          {indexName == `Prismic::List_${locale}` && (
            <>
              <Index indexName={`Prismic::List_${locale}`}>
                <SortBy
                  className="c-search-modal__sort-by"
                  defaultRefinement={`Prismic::List_${locale}_first_publication_date_desc`}
                  items={[
                    {value: `Prismic::List_${locale}_first_publication_date_desc`, label: 'Published date'},
                    {value: `Prismic::List_${locale}_title_asc`, label: 'Title'},
                  ]}
                />
              </Index>
              <CatalogGrid className="c-search-modal__catalog" type="lists">
                {hits.map((card) => renderHit(card, indexName))}
              </CatalogGrid>
            </>
          )}
        </div>
      </>
    );
  }
};

export default connectInfiniteHits(CustomHits);
