import ApiHelper from 'utils/api_helper';

import {isEqual} from 'lodash';

export const findLocations = ({params, onSuccess, onError}) => {
  const {input, country, deliveryServices} = params;
  const uri = new URL(window.origin);
  const language = (isEqual(deliveryServices, ['delivery_service_japan']) && country === 'JP') ? 'ja' : 'en';
  uri.pathname = '/api/v1/google/find_locations';
  uri.searchParams.append('input', input);
  uri.searchParams.append('country', country);
  uri.searchParams.append('language', language);

  ApiHelper.get(uri).then((res) => onSuccess(res)).catch((error) => onError(error));
};
