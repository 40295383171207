import React, {createRef, useRef, useState} from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import SwiperCore, {Navigation} from 'swiper';
import classNames from 'classnames';

export const CatalogSlider = ({
  children, title, className, slidesType,
}) => {
  const backArrow = createRef(null);
  const forwardArrow = createRef(null);
  const containerElement = useRef(null);
  const [isArrowsVisible, setIsArrowsVisible] = useState(true);

  const checkArrowsVisibility = (swiperContainer) => {
    const slides = swiperContainer?.current.querySelectorAll('.swiper-slide');

    if (slides && slides.length > 0) {
      const slidesWidth = slides.length * slides[0].offsetWidth;
      const containerWidth = swiperContainer?.current.offsetWidth;

      setIsArrowsVisible(slidesWidth > containerWidth);
    }
  };

  SwiperCore.use([Navigation]);

  return (
    <div className={classNames('c-catalog-slider', {
      [className]: className,
      'c-catalog-slider--lists-type': slidesType === 'list-card',
      'c-catalog-slider--projects-type': slidesType === 'project-card',
    })}
    >
      <div className="c-catalog-slider__header">
        <h2 className="c-catalog-slider__title">{title}</h2>
        <div className="c-catalog-slider__controls">
          <div className={classNames('c-catalog-slider__arrows', {'c-catalog-slider__arrows--is-hide': !isArrowsVisible})}>
            <div className="c-catalog-slider__arrow c-pictogram c-pictogram--arrow-back" ref={backArrow} />
            <div className="c-catalog-slider__arrow c-pictogram c-pictogram--arrow-forward" ref={forwardArrow} />
          </div>
        </div>
      </div>
      <div wovn-ignore="">
        <Swiper
          ref={containerElement}
          speed={400}
          allowTouchMove
          slidesPerView="auto"
          onInit={(swiper) => {
            swiper.params.navigation.prevEl = backArrow.current;
            swiper.params.navigation.nextEl = forwardArrow.current;
            swiper.navigation.update();
          }}
          onAfterInit={() => {
            checkArrowsVisibility(containerElement);
          }}
          onResize={() => {
            checkArrowsVisibility(containerElement);
          }}
        >
          {children.map((item, index) => {
            return (
              <SwiperSlide
                key={index}
              >
                {item}
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </div>
  );
};
