import React, {useState} from 'react';

import {FieldsGroup} from 'shared/components/form/grid_containers/fields_group/fields_group';
import {RadioField} from 'shared/components/form/radio_field/radio_field';
import {Preloader} from 'shared/components/preloader/preloader';

export const WidgetModal = ({projectId}) => {
  const [widgetMode, setWidgetMode] = useState('full');
  const [isWidgetLoading, setIsWidgetLoading] = useState(true);
  const [widgetError, setWidgetError] = useState(null);

  const widgetStyle = () => {
    const height = widgetMode === 'full' ? '419px' : '202px';
    return {
      border: 'medium none',
      width: '100%',
      maxWidth: '315px',
      height,
    };
  };

  const widgetText = () => {
    const height = widgetMode === 'full' ? '419px' : '202px';
    const baseURL = location.origin;

    return `<iframe src="${baseURL}/widgets/${projectId}/?mode=${widgetMode}" style="border: none; width: 300px; height: ${height}" scrolling="no" frameborder="0">`;
  };

  const widgetPreview = () => {
    if (widgetMode) {
      return (
        <iframe
          onLoad={() => setIsWidgetLoading(false)}
          onError={() => setWidgetError('Something went wrong')}
          src={`/widgets/${projectId}?mode=${widgetMode}`}
          style={widgetStyle()}
          scrolling="no"
        />
      );
    }
  };

  return (
    <div className="c-widget-modal">
      <div className="c-widget-modal__content">
        <h2 className="c-widget-modal__title">Widget size</h2>
        <FieldsGroup className="c-widget-modal__controls" type="radio">
          <RadioField
            label="Full size widget"
            checked={widgetMode === 'full'}
            onChange={(e) => {
              setWidgetMode('full');
              setIsWidgetLoading(true);
            }}
          />
          <RadioField
            label="Small widget"
            checked={widgetMode === 'small'}
            onChange={(e) => {
              setWidgetMode('small');
              setIsWidgetLoading(true);
            }}
          />
        </FieldsGroup>
        <h2 className="c-widget-modal__title">Embed code</h2>
        <div className="c-widget-modal__description">Copy the code below and paste it into your website or blog.</div>
        <div className="c-widget-modal__share-text">{widgetText()}</div>
      </div>
      <div className="c-widget-modal__preview">
        <h2 className="c-widget-modal__title">Widget preview</h2>
        <div className="c-widget-modal__widget">
          {widgetPreview()}
          {isWidgetLoading && (
            <Preloader error={widgetError} coverBlock />
          )}
        </div>
      </div>
    </div>
  );
};
