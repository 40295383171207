import React, {useEffect, useState} from 'react';
import Prismic from '@prismicio/client';
import {RichText} from 'prismic-reactjs';
import ApiHelper from 'utils/api_helper';
import {useLanguageChange} from 'utils/use_language_change';
import {Dropdown} from 'src/shared/components/dropdown';
import {CatalogGrid} from 'src/shared/components/catalog/catalog_grid/catalog_grid';
import {ListsCard} from 'src/shared/components/cards/lists_card/lists_card';

export const WatchingsLists = () => {
  const [listIds, setListIds] = useState([]);
  const [lists, setLists] = useState([]);
  const [ordering, setOrdering] = useState('desc');
  const [locale, setLocale] = useState(window.constants.prismic_locales[window.constants.locale]);
  const prismicClient = Prismic.client('https://qrates-prd.cdn.prismic.io/api/v2');
  const queries = [Prismic.Predicates.at('document.type', 'list'), Prismic.Predicates.in('my.list.uid', listIds)];
  const dropdownOptions = [{label: 'Sort by Recent', value: 'desc'}, {label: 'Sort by Old', value: 'asc'}];

  useEffect(() => {
    ApiHelper.get('/api/v1/prismic/lists/watchings').then((res) => {
      if (res.response) {
        setListIds(res.response.map((w) => w.data.resource_uid));
      }
    });
  }, []);

  useLanguageChange((event) => {
    setLocale(window.constants.prismic_locales[event.detail.locale]);
  });

  useEffect(() => {
    if (listIds.length > 0) {
      const options = {lang: locale};
      if (ordering == 'desc') {
        options.orderings = `[document.last_publication_date ${ordering}]`;
      } else {
        options.orderings = '[document.last_publication_date]';
      }
      prismicClient.query(queries, options).then((response) => {
        setLists(response.results);
      });
    }
  }, [listIds, ordering, locale]);

  return (
    <div>
      <div className="p-section-header p-section-header--one-line">
        <div className="c-dashboard__heading">
          <h2>Lists</h2>
        </div>
        <Dropdown
          options={dropdownOptions}
          onChange={setOrdering}
          initialValue={ordering}
          customClass="c-select--sort c-dashboard__sort"
        />
      </div>
      <CatalogGrid className="c-dashboard__lists-catalog" type="lists">
        {lists.map((list, index) => {
          return (
            <ListsCard
              key={index}
              theme={['no-border']}
              title={RichText.asText(list.data.title)}
              subtitle={RichText.asText(list.data.subtitle)}
              link={`/lists/${list.id}`}
              image={list.data.cover_image.url}
            />
          );
        })}
      </CatalogGrid>
    </div>
  );
};
