import React from 'react';

import {GroupSection} from '../../group_section/group_section';
import {ScreenContainer} from '../../screen_container/screen_container';

export const LabelsScreen = ({navigationList}) => {
  const labelsList = [
    {
      name: 'Main',
      items: [
        {name: 'c-label'},
      ],
    },
    {
      name: 'Use on dashboard',
      items: [
        {name: 'c-label--container'},
        {name: 'c-label--todo'},
        {name: 'c-label--done'},
        {name: 'c-label--locked'},
        {name: 'c-label--review'},
        {name: 'c-label--option'},
        {name: 'c-label--recommend'},
        {name: 'c-label--jazz'},
        {name: 'c-label--jazz c-label--jazz--issue'},
      ],
    },
    {
      name: 'Use on project',
      items: [
        {name: 'c-label--working'},
        {name: 'c-label--selling'},
        {name: 'c-label--icon c-label--icon--done'},
        {name: 'c-label--icon c-label--icon--review'},
        {name: 'c-label--icon c-label--icon--todo'},
        {name: 'c-label--icon c-label--icon--locked'},
        {name: 'c-label--icon c-label--icon--alert'},
        {name: 'c-label--icon c-label--icon--partial'},
        {name: 'c-label--icon c-label--icon--reviewing'},
        {name: 'c-label--small'},
        {name: 'c-label--middle c-label--middle--done'},
        {name: 'c-label--middle c-label--middle--review'},
        {name: 'c-label--middle c-label--middle--todo'},
        {name: 'c-label--middle c-label--middle--locked'},
        {name: 'c-label--middle c-label--middle--alert'},
        {name: 'c-label--middle c-label--middle--recommended'},
        {name: 'c-label--middle c-label--middle--partial'},
        {name: 'c-label--pages'},
        {name: 'c-label--pages c-label--white'},
        {name: 'c-label--pages c-label--black'},
        {name: 'c-label--new'},
        {name: 'c-label--help'},
        {name: 'c-label--white'},
        {name: 'c-label--funding'},
        {name: 'c-label--small-rectangle'},
      ],
    },
  ];

  return (
    <ScreenContainer title="Labels">
      {labelsList && labelsList.map((group, index) => {
        return (
          <GroupSection title={group.name} type="inline-dark" key={index}>
            {group.items.map((item, index) => {
              const itemToCopy = `.c-label .${item.name}`;
              return (
                <div
                  className={`c-label ${item.name}`}
                  key={index}
                  onClick={() => { navigator.clipboard.writeText(itemToCopy); }}
                >
                  PROJECT
                </div>
              );
            })}
          </GroupSection>
        );
      })}
    </ScreenContainer>
  );
};
