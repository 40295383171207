import {Record} from 'immutable';
import moment from 'moment';

const _Record = Record({
  id: null,
  state: 'draft',

  selling_type: null,
  currency: 'USD',
  price: 3000,
  estimated_profit: 0,
  estimated_quantity: 0,
  estimated_preorder_quantity: 0,
  estimated_wholesale_quantity: 0,
  total_sales_amount: 0,
  total_handling_fee: 0,
  tax_rate: 0,

  start_date: moment().utc().startOf('day'),
  finish_date: moment().utc().startOf('day'),
  timezone: {},
  delivery_service: 'delivery_service_france',
  ship_from: 'US',
  shipping_country: '',

  self_price: 0,
  preorder_available: false,
  preorder_price: null,
  preorder_max_quantity: null,
  preorder_start_date: moment().utc().startOf('day'),
  preorder_finish_date: moment()
    .utc()
    .clone()
    .startOf('day')
    .add(1, 'days'),

  wholesale_available: false,
  wholesale_price: null,
  wholesale_max_quantity: null,

  min_price: null,
  min_wholesale_price: null,

  ordered_quantity: 0,
  base_quantity: 0,

  countdown: null,
  coming_soon: null,
  is_preorder: null,

  left_days: 0,
  left_hours: 0,

  is_finished: false,
  is_achieved: false,
  is_succeeded: false,
  is_failed: false,
  can_order: false,
  can_self_purchase: false,
}, 'SellingConditionRecord');

export default class SellingCondition extends _Record {
  static fromJSON(json) {
    return new SellingCondition(json);
  }

  saveAttributes() {
    const {
      selling_type,
      currency,
      price,
      tax_rate,
      start_date,
      delivery_service,
      wholesale_available,
      wholesale_price,
      wholesale_max_quantity,
      preorder_available,
      preorder_price,
      preorder_ordered_quantity,
      preorder_start_date,
      preorder_finish_date,
      preorder_max_quantity,
      ship_from,
    } = this;

    switch (selling_type) {
      case 'funding':
        return ({
          selling_type,
          currency,
          price,
          tax_rate,
          start_date,
          wholesale_available,
          wholesale_price,
          wholesale_max_quantity,
          delivery_service,
          ship_from,
        });
      case 'sell':
        return ({
          selling_type,
          currency,
          price,
          tax_rate,
          preorder_available,
          preorder_price,
          preorder_ordered_quantity,
          preorder_max_quantity,
          preorder_start_date,
          preorder_finish_date,
          wholesale_available,
          wholesale_price,
          wholesale_max_quantity,
          delivery_service,
          ship_from,
        });
      case 'press_only':
        return null;
      default:
        return ({
          currency,
          price,
          delivery_service,
        });
    }
  }
}
