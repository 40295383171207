import React, {useState} from 'react';
import Select from 'react-select';
import {set} from 'lodash';
import classNames from 'classnames';
import ApiHelper from 'utils/api_helper';
import {Notification} from 'shared/components/notification';
import {handleError} from 'utils/validation';

export default function BillingDetails(props) {
  const {order, setOrder, onClose} = props;
  const orderItem = order.order_items[0] || {};
  const countries = window.constants.deliverableCountries.map((country) => ({label: country.name, value: country.code}));
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [notification, setNotification] = useState(null);
  const [sameAsBillingAddress, setSameAsBillingAddress] = useState(false);
  const [formParams, setFormParams] = useState({
    order: {
      order_items_attributes: [{
        id: orderItem.id,
        delivery_first_name: orderItem.delivery_first_name,
        delivery_last_name: orderItem.delivery_last_name,
        delivery_company: orderItem.delivery_company,
        delivery_building: orderItem.delivery_building,
        delivery_street: orderItem.delivery_street,
        delivery_city: orderItem.delivery_city,
        delivery_prefecture: orderItem.delivery_prefecture,
        delivery_postal_code: orderItem.delivery_postal_code,
        delivery_country: orderItem.delivery_country,
        delivery_phone_number: orderItem.delivery_phone_number,
      }],
    },
  });
  const currentCountry = countries.find((option) => option.value === formParams.order.order_items_attributes[0].delivery_country);

  const toggleSameAsBillingAddress = () => {
    setSameAsBillingAddress(!sameAsBillingAddress);

    if (!sameAsBillingAddress) {
      const fields = [
        'first_name',
        'last_name',
        'email',
        'company',
        'building',
        'street',
        'city',
        'prefecture',
        'country',
        'postal_code',
        'phone_number',
      ];
      fields.forEach((field) => {
        setValue({target: {name: `order_items.delivery_${field}`, value: order[`billing_${field}`]}});
      });
    }
  };

  const onCountryChange = (option) => {
    setValue({target: {name: 'order_items.delivery_country', value: option.value}});
  };

  const setValue = (event) => {
    const field = event.target.name.split('.')[1];
    setFormParams(set({...formParams}, `order.order_items_attributes[0].${field}`, event.target.value));
  };

  const onSubmit = () => {
    setButtonDisabled(true);
    ApiHelper.patch(`/orders/${order.id}`, formParams).then((response) => {
      if (response.error) {
        handleError(response.error, setNotification);
        setButtonDisabled(false);

        return;
      }

      setOrder(response.response);
      setButtonDisabled(false);
      setNotification(null);
      onClose();
    });
  };

  return (
    <>
      <div className="c-dashboard__heading">Shipping information</div>
      <span className="c-close--modal" onClick={onClose}>
        <a>&times;</a>
      </span>
      {notification && (
        <div className="c-login__error">
          <Notification type="error">
            {notification.message}
          </Notification>
        </div>
      )}

      <div className="c-dashboard__same-address">
        <div className="c-dashboard__same-address-text">Same as billing address</div>
        <div className="c-dashboard__same-address-checkbox c-toggle-checkbox">
          <span className="c-toggle-checkbox__detail" />
          <label
            className={classNames('c-toggle-checkbox__label', {checked: sameAsBillingAddress})}
          >
            <input
              className="c-toggle-checkbox__input"
              type="text"
              onClick={(e) => toggleSameAsBillingAddress()}
            />
          </label>
        </div>
      </div>

      <div className="p-form-group--half">
        <div className="p-form-group__value">
          <div className="p-form-group--label">First name*</div>
          <input
            className="c-input"
            type="text"
            name="order_items.delivery_first_name"
            readOnly={sameAsBillingAddress}
            onChange={setValue}
            value={formParams.order.order_items_attributes[0].delivery_first_name || ''}
          />
        </div>
        <div className="p-form-group__value">
          <div className="p-form-group--label">
            Last name*
          </div>
          <input
            className="c-input"
            type="text"
            name="order_items.delivery_last_name"
            readOnly={sameAsBillingAddress}
            onChange={setValue}
            value={formParams.order.order_items_attributes[0].delivery_last_name || ''}
          />
        </div>
      </div>
      <div className="p-form-group--label">Company</div>
      <div className="p-form-group">
        <div className="p-form-group__value">
          <input
            className="c-input"
            type="text"
            name="order_items.delivery_company"
            placeholder="Optional"
            readOnly={sameAsBillingAddress}
            onChange={setValue}
            value={formParams.order.order_items_attributes[0].delivery_company || ''}
          />
        </div>
      </div>
      <div className="p-form-group--address">
        <div className="p-form-group--label">Address*</div>
        <div className="p-form-group">
          <div className="p-form-group__value">
            <input
              className="c-input"
              type="text"
              name="order_items.delivery_building"
              placeholder="Building (Optional)"
              readOnly={sameAsBillingAddress}
              onChange={setValue}
              value={formParams.order.order_items_attributes[0].delivery_building || ''}
            />
          </div>
        </div>
        <div className="p-form-group">
          <div className="p-form-group__value">
            <input
              className="c-input"
              type="text"
              name="order_items.delivery_street"
              placeholder="Street address"
              readOnly={sameAsBillingAddress}
              onChange={setValue}
              value={formParams.order.order_items_attributes[0].delivery_street || ''}
            />
          </div>
        </div>
        <div className="p-form-group--half">
          <div className="p-form-group__value">
            <input
              className="c-input"
              type="city"
              name="order_items.delivery_city"
              placeholder="City"
              readOnly={sameAsBillingAddress}
              onChange={setValue}
              value={formParams.order.order_items_attributes[0].delivery_city || ''}
            />
          </div>
          <div className="p-form-group__value">
            <input
              className="c-input"
              type="state"
              name="order_items.delivery_prefecture"
              placeholder="Prefecture / State (Optional)"
              readOnly={sameAsBillingAddress}
              onChange={setValue}
              value={formParams.order.order_items_attributes[0].delivery_prefecture || ''}
            />
          </div>
        </div>
        <div className="p-form-group--half">
          <div className="p-form-group__value">
            <input
              className="c-input"
              type="zip"
              name="order_items.delivery_postal_code"
              placeholder="Postal code"
              onChange={setValue}
              value={formParams.order.order_items_attributes[0].delivery_postal_code || ''}
              readOnly={sameAsBillingAddress}
            />
          </div>
          <div className="p-form-group__value c-react-select__wrap">
            <input type="hidden" name="order_items.delivery_country" />
            <Select
              className="c-react-select"
              classNamePrefix="c-react-select"
              name="order_items.delivery_country"
              placeholder="Country..."
              value={currentCountry}
              options={countries}
              onChange={onCountryChange}
              isSearchable={!sameAsBillingAddress}
              openMenuOnClick={!sameAsBillingAddress}
              isDisabled
            />
          </div>
        </div>
      </div>
      <div className="p-form-group--label">Phone number*</div>
      <div className="p-form-group">
        <div className="p-form-group__value">
          <input
            className="c-input"
            type="text"
            name="order_items.delivery_phone_number"
            readOnly={sameAsBillingAddress}
            value={formParams.order.order_items_attributes[0].delivery_phone_number || ''}
            onChange={setValue}
          />
        </div>
      </div>
      <div className="p-form-group--label">Shipping method</div>
      <div className="p-form-group p-form-group--control">
        <div className="p-form-group__value">
          <div className="c-input--static">
            {orderItem.delivery_service === 'delivery_service_uk' && 'Economy shipping'}
            {(orderItem.delivery_service === 'delivery_service_us_priority'
              || orderItem.delivery_service === 'delivery_service_japan'
              || orderItem.delivery_service === 'delivery_service_uk_priority') && 'Priority shipping'}
            {orderItem.delivery_service === 'delivery_service_self' && 'Shipping by Artist'}
          </div>
        </div>
      </div>

      <button
        type="button"
        className="c-button c-button--black c-button--full"
        onClick={onSubmit}
        disabled={buttonDisabled}
      >
        SAVE CHANGES
      </button>
    </>
  );
}
