import React, {useState} from 'react';
import classNames from 'classnames';
import {get} from 'lodash';

import {formatMoney} from 'utils/format_money';
import ApiHelper from 'utils/api_helper';
import {getCountry} from 'utils/get_country';

import {CouponModal} from 'shared/components/modals/coupon_modal/coupon_modal';
import {ReactModal} from 'shared/components/modals/react_modal/react_modal';
import {OrderCardSummuryBlock} from '../../../order_card_summury_block/order_card_summury_block';

export const PaymentSummurySection = ({
  className,
  isMobileView,
  formParams,
  setFormParams,
  calculateOrder,
  cartItems,
  orders,
}) => {
  const [modal, setModal] = useState(null);
  const sellCartItems = cartItems.filter((cartItem) => cartItem.is_sell);
  const fundingCartItems = cartItems.filter((cartItem) => cartItem.is_funding).map((cartItem) => (
    {...cartItem, order: orders?.find((o) => get(o, 'order_items[0].project_id') === cartItem.project_id)}
  ));
  const sellOrder = orders?.find((order) => order.order_items.find((orderItem) => sellCartItems.map((i) => i.project_id).includes(orderItem.project_id)));

  const sellCartItemsList = sellCartItems.map((item) => {
    return {title: item.project_title, value: formatMoney({amount: item.payment_amount, currency: item.currency}), isBold: true};
  });

  const shippingCountry = getCountry(get(sellOrder, 'order_items[0].delivery_country')).name;

  const handleCouponSubmit = ({couponCode, onSuccess, onError}) => {
    ApiHelper.post('/api/v1/cart_items/apply_discount', {code: couponCode}).then((res) => {
      if (res.error) {
        onError(res.error);
      } else if (res.response) {
        const newFormParams = {...formParams};
        newFormParams.orders.forEach((order) => {
          order.order_items.forEach((orderItem) => {
            const cartItem = res.response.find((item) => item.project_id === orderItem.project_id);
            if (cartItem) {
              orderItem.discount_id = cartItem.discount_id;
            }
          });
        });
        setFormParams(newFormParams);
        calculateOrder({params: newFormParams});
        onSuccess();
      }
    });
  };

  return (
    <div className={classNames('c-payment-summury-section', {[className]: className})}>
      {!isMobileView && (sellCartItems?.length > 0 || fundingCartItems?.length > 0) && (
        <div className="payment-summury-desctop">
          <div className="payment-summury-desctop__coupon-item">
            <span onClick={() => setModal('coupon')}>
              <i className="c-pictogram c-pictogram--coupon" />
              Add a coupon code
            </span>
          </div>
        </div>
      )}
      {isMobileView && (
        <div className="payment-summury-mobile">
          {fundingCartItems?.length > 0 && (
            <div className="payment-summury-mobile__section">
              <h3 className="payment-summury-mobile__title">Your pledge</h3>
              <div className="payment-summury-mobile__orders-list">
                {fundingCartItems.map((cartItem, index) => {
                  const shippingCountry = getCountry(get(cartItem, 'order.order_items[0].delivery_country')).name;

                  return (
                    <div key={cartItem?.id || index}>
                      <OrderCardSummuryBlock
                        itemsList={[
                          {
                            title: cartItem.project_title,
                            value: formatMoney({amount: cartItem.payment_amount, currency: cartItem.currency}),
                            isBold: true,
                          },
                          ...((cartItem?.order?.order_items || []).filter((i) => i.discount).map((orderItem) => (
                            {
                              title: `Discount for “${orderItem.project_title}”`,
                              value: `-${formatMoney({amount: orderItem.discount.amount, currency: cartItem.order.payment_currency})}`,
                            }
                          ))),
                          {
                            title: `Shipping to ${shippingCountry || '(please select country)'}`,
                            value: formatMoney({amount: cartItem.order?.total_payment_delivery_fee, currency: cartItem.order?.payment_currency}),
                          },
                        ]}
                        paymentCurrency={cartItem.order?.payment_currency}
                        paymentTax={cartItem.order?.total_payment_tax}
                        paymentTotalAmount={cartItem.order?.total_payment_total_amount}
                        chargeDate={cartItem.charge_date}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          {sellCartItems?.length > 0 && (
            <div className="payment-summury-mobile__section">
              <h3 className="payment-summury-mobile__title">Your purchase</h3>
              <OrderCardSummuryBlock
                className="payment-summury-mobile__order-item"
                itemsList={[
                  ...sellCartItemsList,
                  ...((sellOrder?.order_items || []).filter((i) => i.discount).map((orderItem) => (
                    {
                      title: `Discount for “${orderItem.project_title}”`,
                      value: `-${formatMoney({amount: orderItem.discount.amount, currency: sellOrder.payment_currency})}`,
                    }
                  ))),
                  {
                    title: `Shipping to ${shippingCountry || '(please select country)'}`,
                    value: formatMoney({amount: sellOrder?.total_payment_delivery_fee, currency: sellOrder?.payment_currency}),
                  },
                ]}
                paymentCurrency={sellOrder?.payment_currency}
                paymentTax={sellOrder?.total_payment_tax}
                paymentTotalAmount={sellOrder?.total_payment_total_amount}
                chargeDate={new Date()}
              />
            </div>
          )}
          {(sellCartItems?.length > 0 || fundingCartItems?.length > 0) && (
            <div className="payment-summury-mobile__coupon-item">
              <span onClick={() => setModal('coupon')}>
                <i className="c-pictogram c-pictogram--coupon" />
                Add a coupon code
              </span>
            </div>
          )}
        </div>
      )}
      <ReactModal
        isOpen={modal === 'coupon'}
        appElement={document.body}
        onRequestClose={() => setModal(null)}
        onClose={() => setModal(null)}
      >
        <CouponModal
          onClose={() => setModal(null)}
          onSubmit={handleCouponSubmit}
          type="order"
        />
      </ReactModal>
    </div>
  );
};
