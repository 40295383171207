import styled, { css } from 'styled-components';

export const cssGenericStyling = css`
  a {
    color: inherit;
  }
`;

export const StyledGenericContent = styled.div`
  ${cssGenericStyling};
`;
