import React from 'react';
import classNames from 'classnames';

export const WatchButton = ({className, isWatching, onClick}) => {
  return (
    <>
      {isWatching && (
        <button
          className={classNames('c-button c-button--below-large c-button--white-line-black c-button--icon-left', {[className]: className})}
          type="button"
          onClick={onClick}
        >
          <i className="c-pictogram c-pictogram--star" />
          UNWATCH THIS LIST
        </button>
      )}
      {!isWatching && (
        <button
          className={classNames('c-button c-button--below-large c-button--black c-button--icon-left', {[className]: className})}
          type="button"
          onClick={onClick}
        >
          <i className="c-pictogram c-pictogram--star" />
          WATCH THIS LIST
        </button>
      )}
    </>
  );
};
