import React from 'react';
import classNames from 'classnames';

export const ModalText = ({
  text, className, type, children,
}) => {
  return (
    <p className={classNames('c-modal-text', {
      [className]: className,
      'c-modal-text--small': type === 'small',
    })}
    >
      {text && text}
      {children && children}
    </p>
  );
};
