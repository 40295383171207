// @flow
import { get } from 'lodash';
import type { ImageMdl } from './story';

type ArticleDataMdl = {
  preview_image: ImageMdl,
  preview_text: any,
  preview_title: any,
  tags: {
    tag: {
      text: string,
    },
  }[],
};

export type ArticlePreviewMdl = {
  slugs: string[],
  uid: string,
  id: string,
  data: ArticleDataMdl,
};

export const getArticleSlug = (article: ArticlePreviewMdl): string => {
  return get(article, 'uid', '') || '';
};

export const getArticleTags = (article: ArticlePreviewMdl): string[] => {
  return get(article, 'data.tags', []).map(tag => {
    return get(tag, 'tag[0].text', '') || '';
  });
};

export const getArticlePreviewTitle = (article: ArticlePreviewMdl): any => {
  return get(article, 'data.preview_title', '') || '';
};

export const getArticlePreviewText = (article: ArticlePreviewMdl): any => {
  return get(article, 'data.preview_text' || 'primary.intro_text', '') || '';
};

export const getArticlePreviewImageUrl = (article: ArticlePreviewMdl): string => {
  return get(article, 'data.preview_image.url', '') || '';
};

export const getArticleIntroText = (slice: ArticlePreviewMdl): any => {
  return get(slice, 'primary.intro_text', '') || '';
};

export const getArticleIntroTitle = (slice: ArticlePreviewMdl): any => {
  return get(slice, 'primary.title1', '') || '';
};

export const getArticleIntroImageUrl = (slice: ArticlePreviewMdl): string => {
  return get(slice, 'primary.featured_image.url', '') || '';
};

export type ArticleSliceRichTextMdl = {
  primary: {
    text: any,
  },
};

export const getArticleSliceRichText = (slice: ArticleSliceRichTextMdl): any => {
  return get(slice, 'primary.text', '') || '';
};

export type ArticleSliceFeaturedImagesMdl = {
  items: {
    image: ImageMdl,
    caption: any,
  }[],
};

export const getArticleSliceFeaturedImages = (
  slice: ArticleSliceFeaturedImagesMdl
): { image: ImageMdl, caption: any }[] => {
  return get(slice, 'items', []) || [];
};

export type ArticleSliceTableMdl = {
  primary: {
    html: any,
  },
};

export const getArticleSliceTable = (slice: ArticleSliceTableMdl): any => {
  return get(slice, 'primary.html', '') || '';
};

export type ArticleSliceMdl = {
  slice_type: 'rich_text' | 'featured_images' | 'table',
};

export type ArticleFullMdl = ArticlePreviewMdl & {
  data: ArticleDataMdl & {
    banner_image: ImageMdl,
    body: ArticleSliceMdl[],
    title: any,
    content: any,
    related_articles: {
      article: ArticlePreviewMdl,
    }[],
  },
};

export const getArticleBannerImageUrl = (article: ArticleFullMdl): string => {
  return get(article, 'data.banner_image.url', '') || '';
};

export const getArticleRelatedArticles = (article: ArticleFullMdl): ArticlePreviewMdl[] => {
  return get(article, 'data.related_articles', []).map(relatedArticle => {
    return get(relatedArticle, 'article', null);
  });
};

export const getArticleTitle = (article: ArticleFullMdl): any => {
  return get(article, 'data.title', '') || '';
};

export const getArticleType = (article: ArticleFullMdl): any => {
  console.log(get(article, 'type', '') || '');
  return get(article, 'type', '') || '';
};

export const getArticleBodyContent = (article: ArticleFullMdl): ArticleSliceMdl[] => {
  return get(article, 'data.body', []) || [];
};
