import React from 'react';
import classNames from 'classnames';

export const RadioField = ({
  className,
  name,
  label,
  value,
  checked,
  onChange,
  readOnly,
  locked,
  disabled,
  theme,
  ref,
}) => {
  return (
    <div
      className={classNames('c-radio-field', {
        [className]: className,

        'c-radio-field--selected-bold': theme === 'selected-bold',
        'c-radio-field--large-bold': theme === 'selected-bold',
        'c-radio-field--large-selected-bold': theme === 'large-selected-bold',

        'is-checked': checked,
        'is-readOnly': readOnly,
        'is-locked': locked,
        'is-disabled': disabled,
      })}
    >
      <label
        className="c-radio-field__label"
        readOnly={readOnly || locked || disabled}
      >
        <input
          className="c-radio-field__input"
          type="radio"
          value={value}
          name={name}
          checked={checked}
          onChange={onChange}
          ref={ref}
        />
        <div className="c-radio-field__box" />
        {label && (
          <p className="c-radio-field__title">
            {label}
            <i className="c-radio-field__lock-icon c-pictogram c-pictogram--lock" />
          </p>
        )}
      </label>
    </div>
  );
};
