import React from 'react';
import {
  render,
  screen,
  fireEvent,
  waitFor,
} from '@testing-library/react';

import {CoverStory} from '../cover_story';

const stories = [
  {
    uid: 1,
    preview_image: 'image_1',
    story_category: 'Category 1',
    preview_heading: 'Heading 1',
    preview_pre_heading: 'Pre Heading 1',
    preview_text: 'Text 1',
  },
];

jest.mock('utils/api_helper', () => {
  return {
    get: () => Promise.resolve({
      response: [
        {
          uid: 2,
          preview_image: 'image_2',
          story_category: 'Category 2',
          preview_heading: 'Heading 2',
          preview_pre_heading: 'Pre Heading 2',
          preview_text: 'Text 2',
        },
        {
          uid: 3,
          preview_image: 'image_3',
          story_category: 'Category 3',
          preview_heading: 'Heading 3',
          preview_pre_heading: 'Pre Heading 3',
          preview_text: 'Text 3',
        },
      ],
    }),
  };
});

beforeEach(() => {
  window.Swiper = () => {};
});

it('renders correct data', async () => {
  render(<CoverStory stories={stories} />);

  expect(screen.getAllByText('CATEGORY 1')).toHaveLength(2);
  expect(screen.getByText('Heading 1')).toBeInTheDocument();
  expect(screen.getByText('Pre Heading 1')).toBeInTheDocument();
  expect(screen.getByText('Text 1')).toBeInTheDocument();
});

describe('with languagechange', () => {
  const event = new CustomEvent('languagechange', screen);

  it('replaces data', async () => {
    render(<CoverStory stories={stories} />);

    expect(screen.getAllByText('CATEGORY 1')).toHaveLength(2);
    expect(screen.getByText('Heading 1')).toBeInTheDocument();
    expect(screen.getByText('Pre Heading 1')).toBeInTheDocument();
    expect(screen.getByText('Text 1')).toBeInTheDocument();

    fireEvent(window, event);

    await waitFor(() => {
      expect(screen.getByText('Heading 2')).toBeInTheDocument();
    });

    expect(screen.queryByText('CATEGORY 1')).not.toBeInTheDocument();
    expect(screen.queryByText('Heading 1')).not.toBeInTheDocument();
    expect(screen.queryByText('Pre Heading 1')).not.toBeInTheDocument();
    expect(screen.queryByText('Text 1')).not.toBeInTheDocument();

    expect(screen.getAllByText('CATEGORY 2')).toHaveLength(2);
    expect(screen.getByText('Heading 2')).toBeInTheDocument();
    expect(screen.getByText('Pre Heading 2')).toBeInTheDocument();
    expect(screen.getByText('Text 2')).toBeInTheDocument();

    expect(screen.getAllByText('CATEGORY 3')).toHaveLength(2);
    expect(screen.getByText('Heading 3')).toBeInTheDocument();
    expect(screen.getByText('Pre Heading 3')).toBeInTheDocument();
    expect(screen.getByText('Text 3')).toBeInTheDocument();
  });
});
