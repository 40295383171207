import React from 'react';
import classNames from 'classnames';

export const ProjectsNav = ({
  className,
  items,
  onChange,
}) => {
  const itemsList = items?.map((item, id) => {
    return (
      <li className="c-projects-nav__list-item" onClick={(e) => onChange(e, item)} key={id}>
        <a
          className={classNames('c-projects-nav__link', {
            'is-active': item.isActive,
          })}
          href="#"
        >
          {item.label}
        </a>
      </li>
    );
  });

  return (
    <div
      className={classNames('c-projects-nav', {
        [className]: className,
      })}
    >
      <div className="c-projects-nav__container">
        <div className="c-projects-nav__main">
          <ul className="c-projects-nav__list">
            {itemsList}
          </ul>
        </div>

      </div>
    </div>
  );
};
