import React, {useEffect, useState} from 'react';
import {MainVisualPlayer} from 'components/project/main_visual_player';
import {SoundCloudHelper} from 'utils/soundcloud_helper';
import {TrackPlayer} from './track_player/track_player';
import {WidgetButton} from './widget_button/widget_button';
import {WidgetInfo} from './widget_info/widget_info';

export const ProjectWidget = ({project}) => {
  const {
    root_url,
    page_design,
    slug,
    id,
    a_side_tracks,
    b_side_tracks,
    c_side_tracks,
    d_side_tracks,
    bonus_side_tracks,
  } = project;
  const urlParams = new URLSearchParams(window.location.search);
  const widgetMode = urlParams.get('mode');
  const soundCloudHelper = new SoundCloudHelper(window.constants.soundcloud_token);

  const rootPath = root_url;
  const projectPlayVideoPath = `${rootPath}projects/${slug || id}?start_play_video="true"`;

  const visualPreview = {
    main_visual_type: page_design?.main_visual_type,
    cover_image_url: page_design?.cover_image_url,
    background_image_url: page_design.background_image?.url,
    background_color: page_design?.background_color,
    iframe_src: page_design?.video_url,
  };

  const [playerAudioTracks, setPlayerAudioTracks] = useState([]);

  useEffect(() => {
    const audioTracks = [
      ...a_side_tracks,
      ...b_side_tracks,
      ...c_side_tracks,
      ...d_side_tracks,
      ...bonus_side_tracks,
    ];

    const prepareTracks = audioTracks.map(async (track) => {
      const {
        id, title, enable_sample_play, audio_url, sound_cloud_track_id,
      } = track;

      if (sound_cloud_track_id && enable_sample_play) {
        try {
          const response = await soundCloudHelper.getStreamUrlsByTrackId(sound_cloud_track_id);
          return {
            id, title, enable_sample_play, audio_url: response, active: true,
          };
        } catch (error) {
          return {
            id, title, enable_sample_play, active: false,
          };
        }
      } else if (audio_url && enable_sample_play) {
        return {
          id, title, audio_url, enable_sample_play, active: true,
        };
      }
    });

    Promise.all(prepareTracks).then((tracks) => {
      setPlayerAudioTracks(tracks.filter((track) => track?.enable_sample_play && track?.active));
    });
  }, [a_side_tracks, b_side_tracks, c_side_tracks, d_side_tracks, bonus_side_tracks]);

  return (
    <div className="c-project-widget">
      {widgetMode === 'full' && (
        <div className="c-project-widget__visual">
          <MainVisualPlayer
            className="c-project-widget__main-visual"
            theme="project-widget"
            main_visual_type={visualPreview.main_visual_type}
            cover_image_url={visualPreview.cover_image_url}
            background_image_url={visualPreview.background_image_url}
            background_color={visualPreview.background_color}
            iframe_src={visualPreview.iframe_src}
            handlePlayVideo={() => { window.open(projectPlayVideoPath, '_blank').focus(); }}
          />
          {playerAudioTracks && <TrackPlayer tracks={playerAudioTracks} />}
        </div>
      )}
      <div className="c-project-widget__info">
        <WidgetInfo project={project} />
        <WidgetButton className="c-project-widget__button" project={project} />
      </div>
    </div>
  );
};
