import React, {useState, useEffect} from 'react';
import classNames from 'classnames';

import ApiHelper from 'utils/api_helper';

export const WatchButton = ({watching}) => {
  const [isWatched, setIsWatched] = useState(!watching['new_record?']);

  const addToWatchlist = () => {
    ApiHelper.post(`/watchings/${watching.item_id}`).then((res) => {
      if (!res.response.result && res.response.require_login) {
        window.location = `/signin?to=${encodeURI(window.location.pathname)}`;
      } else {
        setIsWatched(res.response.result);
      }
    });
  };

  const removeFromWatchlist = () => {
    ApiHelper.delete(`/watchings/${watching.item_id}`).then((res) => {
      if (res.response.hasOwnProperty('result')) {
        setIsWatched(!res.response.result);
      }
    });
  };

  useEffect(() => {
    setIsWatched(!watching['new_record?']);
  }, [watching]);

  return (
    <div
      className="p-project-status__button--to-watch"
      onClick={isWatched ? removeFromWatchlist : addToWatchlist}
    >
      <div className="c-newbutton--watch-project">
        <span className={classNames('c-newbutton__label', {'c-newbutton__label--is-watched': isWatched})}>
          {isWatched ? 'IN WATCHLIST' : 'ADD TO WATCHLIST'}
        </span>
      </div>
    </div>
  );
};
