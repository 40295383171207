export const SoundCloudHelper = (accessToken) => {
  const getTrackInfoById = (id) => {
    return new Promise((resolve, reject) => {
      const endpoint = `https://api.soundcloud.com/tracks/${id}`;

      fetch(endpoint, {
        method: 'get',
        credentials: 'include',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }).then((response) => {
        return response.json();
      }).then((trackMeta) => {
        resolve(trackMeta);
      }).catch((e) => {
        reject(e.message);
      });
    });
  };

  const searchTrackByUrl = (url) => {
    return new Promise((resolve, reject) => {
      if (url.lastIndexOf('https://soundcloud.com/', 0) !== 0) {
        reject('This URL not soundcloud URL');
      }

      const endpoint = 'https://api.soundcloud.com/resolve?';
      const params = new URLSearchParams({url});

      fetch(endpoint + params, {
        method: 'get',
        credentials: 'include',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }).then((response) => {
        return response.json();
      }).then((trackMeta) => {
        resolve(trackMeta);
      }).catch((e) => {
        reject(e.message);
      });
    });
  };

  const getStreamUrlsByTrackId = (id) => {
    return new Promise((resolve, reject) => {
      getTrackInfoById(id).then((trackMeta) => {
        if (trackMeta.streamable && trackMeta.access != 'blocked') {
          const endpoint = `https://api.soundcloud.com/tracks/${id}/streams`;

          fetch(endpoint, {
            method: 'get',
            credentials: 'include',
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }).then((response) => {
            return response.json();
          }).then((response) => {
            if (response.errors) {
              reject(response.errors);
            } else {
              resolve(response.http_mp3_128_url);
            }
          }).catch((e) => {
            reject(e.message);
          });
        } else {
          reject('Track is not streamable.');
        }
      });
    });
  };

  return {
    getTrackInfoById,
    searchTrackByUrl,
    getStreamUrlsByTrackId,
  };
};
