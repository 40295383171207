import React from 'react';
import {
  render,
  screen,
  fireEvent,
  waitFor,
} from '@testing-library/react';

import {ListsScreen} from '../lists_screen';

const lists = [
  {
    uid: 1,
    title: 'Title 1',
    subtitle: 'Subtitle 1',
    curator: 'Qrates',
    cover_image: 'image_1',
  },
];

jest.mock('utils/api_helper', () => {
  return {
    get: () => Promise.resolve({
      response: {
        results: [
          {
            uid: 2,
            title: 'Title 2',
            subtitle: 'Subtitle 2',
            curator: 'Qrates',
            cover_image: 'image_2',
          },
        ],
      },
    }),
  };
});

it('renders correct data', async () => {
  render(<ListsScreen lists={lists} total_count={23} next_page={2} />);

  expect(screen.getByText('Title 1')).toBeInTheDocument();
  expect(screen.getByText('Subtitle 1')).toBeInTheDocument();
  expect(screen.getByText('LOAD MORE LISTS')).toBeInTheDocument();
  expect(screen.getByText('Curated by Qrates')).toBeInTheDocument();
});

describe('with pagination button click and without next_page in the response', () => {
  it('appends data', async () => {
    render(<ListsScreen lists={lists} total_count={23} next_page={2} />);

    expect(screen.getByText('Title 1')).toBeInTheDocument();
    expect(screen.getByText('Subtitle 1')).toBeInTheDocument();
    expect(screen.queryByText('Title 2')).not.toBeInTheDocument();
    expect(screen.queryByText('Subtitle 2')).not.toBeInTheDocument();

    fireEvent.click(screen.getByText('LOAD MORE LISTS'));

    await waitFor(() => {
      expect(screen.getByText('Title 2')).toBeInTheDocument();
    });

    expect(screen.getByText('Title 1')).toBeInTheDocument();
    expect(screen.getByText('Subtitle 1')).toBeInTheDocument();
    expect(screen.getByText('Subtitle 2')).toBeInTheDocument();
    expect(screen.queryByText('LOAD MORE LISTS')).not.toBeInTheDocument();
  });
});

describe('with languagechange', () => {
  const event = new CustomEvent('languagechange', screen);

  it('replaces data', async () => {
    render(<ListsScreen lists={lists} total_count={23} next_page={2} />);

    expect(screen.getByText('Title 1')).toBeInTheDocument();
    expect(screen.getByText('Subtitle 1')).toBeInTheDocument();
    expect(screen.queryByText('Title 2')).not.toBeInTheDocument();
    expect(screen.queryByText('Subtitle 2')).not.toBeInTheDocument();
    expect(screen.getByText('LOAD MORE LISTS')).toBeInTheDocument();

    fireEvent(window, event);

    await waitFor(() => {
      expect(screen.getByText('Title 2')).toBeInTheDocument();
    });

    expect(screen.getByText('Subtitle 2')).toBeInTheDocument();
    expect(screen.queryByText('Title 1')).not.toBeInTheDocument();
    expect(screen.queryByText('Subtitle 1')).not.toBeInTheDocument();
    expect(screen.queryByText('LOAD MORE LISTS')).not.toBeInTheDocument();
  });
});
