import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {handleError, clearValidationErrors} from 'utils/validation';
import ProjectActions from 'project_start/modules/actions';

import {ModalHeading} from 'shared/components/modals/parts/content/modal_heading/modal_heading';
import {ModalText} from 'shared/components/modals/parts/content/modal_text/modal_text';
import {ReactButton} from 'shared/components/buttons/react_button/react_button';
import {InputField} from 'shared/components/form/input_field/input_field';

export const CouponModal = ({onClose, onSubmit, type = 'project'}) => {
  const dispatch = useDispatch();

  const [couponCode, changeCouponCode] = useState('');
  const [notification, setNotification] = useState(false);
  const [isLocked, setIsLocked] = useState(false);

  const project = useSelector((state) => state.project);

  const onError = (error) => {
    setIsLocked(false);
    handleError(error, setNotification);
  };

  const handleSubmit = () => {
    setIsLocked(true);
    clearValidationErrors(setNotification);
    if (onSubmit) {
      onSubmit({
        couponCode,
        onSuccess: onClose,
        onError,
      });
    } else {
      dispatch(ProjectActions.applyCoupon({
        project,
        couponCode,
        onSuccess: onClose,
        onError,
      }));
    }
  };

  return (
    <div className="c-coupon-modal">
      <ModalHeading className="c-coupon-modal__heading">Add a coupon code</ModalHeading>
      {type === 'project' && (
        <ModalText className="c-coupon-modal__description">
          You can apply only one code for one project.
        </ModalText>
      )}
      {notification && (
        <div className="c-notification c-notification--error c-notification--middle">{notification.message}</div>
      )}
      <InputField
        className="c-coupon-modal__input"
        label="Coupon code *"
        placeholder="QRATES1234"
        onChange={(e) => changeCouponCode(e.target.value)}
        isReadOnly={isLocked}
      />
      <div className="c-coupon-modal__buttons">
        {type === 'project' && (
          <ReactButton
            className="c-coupon-modal__cancel-button"
            type="button"
            onClick={onClose}
            theme="primary-white-border"
            disabled={isLocked}
          >
            CANCEL
          </ReactButton>
        )}
        <ReactButton
          className="c-coupon-modal__apply-button"
          type="button"
          onClick={handleSubmit}
          theme="primary-black"
          disabled={isLocked}
        >
          APPLY
        </ReactButton>
      </div>
    </div>
  );
};
