import {Record} from 'immutable';
import StoredFile from './stored_file';
import Track from './track';

const _Record = Record({
  id: null,
  state: '',
  present_type: '',
  _destroy: false,
  _approve: false,
  _reject: false,
  uploaded_file: new StoredFile(),
  processed_file: new StoredFile(),
  track: new Track(),
}, 'VinylAudioFileRecord');

export default class VinylAudioFile extends _Record {
  static fromJSON(json) {
    if (json) {
      json.uploaded_file = StoredFile.fromJSON(json.uploaded_file);
      json.track = Track.fromJSON(json.track);
    }
    return new VinylAudioFile(json);
  }
}
