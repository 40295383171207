import React, {useState} from 'react';
import {connectSearchBox} from 'react-instantsearch-dom';

const CustomSearchBox = ({currentRefinement, refine}) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <div className="c-search-filter__input">
        <input
          className="c-input"
          type="text"
          value={currentRefinement}
          onChange={(e) => refine(e.currentTarget.value)}
        />
      </div>
      <label className="c-search-filter__button" htmlFor="add_button">
        <div className="c-button-add">
          <div className="c-button-add__icon">
            {(isOpen) ? 'x' : '+'}
          </div>
          <div className="c-button-add__label">
            {(isOpen) ? 'Close Filter' : 'Add Filter'}
          </div>
        </div>
      </label>
      <input
        className="c-search-filter__signal"
        type="checkbox"
        id="add_button"
        onChange={() => {
          setIsOpen(!isOpen);
        }}
      />
    </>
  );
};

export default connectSearchBox(CustomSearchBox);
