import React from 'react';

import {ReactButton} from 'shared/components/buttons/react_button/react_button';
import {GroupSection} from '../../group_section/group_section';
import {ScreenContainer} from '../../screen_container/screen_container';

export const ButtonsScreen = () => {
  const onClick = () => alert('onClick');

  return (
    <ScreenContainer title="Buttons">
      <GroupSection title="#01 Primary black default" type="inline">
        <ReactButton text="Go To Pricing" onClick={onClick} theme="primary-black" />
        <ReactButton text="Go To Pricing" onClick={onClick} theme="primary-black" locked />
        <ReactButton text="Go To Pricing" onClick={onClick} theme="primary-black" disabled />
      </GroupSection>
      <GroupSection title="#03 Primary black with icon" type="inline">
        <ReactButton icon="right_arrow" text="Go To Pricing" onClick={onClick} theme="primary-black" />
        <ReactButton icon="right_arrow" text="Go To Pricing" onClick={onClick} theme="primary-black" locked />
        <ReactButton icon="right_arrow" text="Go To Pricing" onClick={onClick} theme="primary-black" disabled />
      </GroupSection>
      <GroupSection title="#02 Primary black large" type="inline">
        <ReactButton text="Go To Pricing" onClick={onClick} theme="primary-black-large" />
        <ReactButton text="Go To Pricing" onClick={onClick} theme="primary-black-large" locked />
        <ReactButton text="Go To Pricing" onClick={onClick} theme="primary-black-large" disabled />
      </GroupSection>
      <GroupSection title="#03 Primary white with border" type="inline">
        <ReactButton text="Go To Pricing" onClick={onClick} theme="primary-white-border" />
        <ReactButton text="Go To Pricing" onClick={onClick} theme="primary-white-border" locked />
        <ReactButton text="Go To Pricing" onClick={onClick} theme="primary-white-border" disabled />
      </GroupSection>
      <GroupSection title="#03 Primary white with icon" type="inline">
        <ReactButton icon="right_arrow" text="Go To Pricing" onClick={onClick} theme="primary-white-border" />
        <ReactButton icon="right_arrow" text="Go To Pricing" onClick={onClick} theme="primary-white-border" locked />
        <ReactButton icon="right_arrow" text="Go To Pricing" onClick={onClick} theme="primary-white-border" disabled />
      </GroupSection>
      <GroupSection title="#03 Primary large white with border" type="inline">
        <ReactButton text="Go To Pricing" onClick={onClick} theme="primary-white-border-large" />
        <ReactButton text="Go To Pricing" onClick={onClick} theme="primary-white-border-large" locked />
        <ReactButton text="Go To Pricing" onClick={onClick} theme="primary-white-border-large" disabled />
      </GroupSection>
    </ScreenContainer>
  );
};
