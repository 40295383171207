import {get} from 'lodash';

export const getProjectTitle = (project) => {
  return get(project, 'title', '') || '';
};

export const getProjectArtistName = (project) => {
  return get(project, 'artist_name', '') || '';
};

export const getProjectPackagingThumbnail = (project) => {
  const productType = project.product_type?.original_value;
  const images = {cassette: 'case_thumbnail_image_url', vinyl: 'sleeve_thumbnail_image_url'};
  return get(project, images[productType], '') || '';
};

export const getProjectProductThumbnail = (project) => {
  const productType = project.product_type?.original_value;
  const images = {cassette: 'cassette_thumbnail_image_url', vinyl: 'vinyl_thumbnail_image_url'};
  return get(project, images[productType], '') || '';
};

export const getProjectRenderedImage = (project) => {
  return get(project, 'rendered_image_url', '') || '';
};

export const getProjectUrl = (project) => {
  return get(project, 'url', '') || '';
};

export const getProjectSlug = (project) => {
  return get(project, 'slug', '') || '';
};
