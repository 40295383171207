import React from 'react';

import {CrowdfundingProjectCard} from './funding';
import {SellProjectCard} from './sell';
import {PreorderProjectCard} from './preorder';
import {PressOnlyProjectCard} from './press_only';

export const ProjectCard = ({
  project,
  section,
  isResponsive,
  className,
  currentUser,
  displayOrderButton = false,
}) => {
  const {state, slug} = project;
  const projectPath = `/projects/${slug}`;
  const draftProjectPath = `/projects/${slug}/start`;
  const dashboardProjectPath = `/dashboard/projects/${slug}`;

  const href = () => {
    if (section != 'dashboard') {
      return projectPath;
    }
    if (state == 'draft') {
      return draftProjectPath;
    }

    return dashboardProjectPath;
  };

  switch (project.project_type) {
    case 'funding':
      return (
        <CrowdfundingProjectCard
          className={className}
          project={project}
          section={section}
          href={href()}
          isResponsive={isResponsive}
          displayOrderButton={displayOrderButton}
          currentUser={currentUser}
        />
      );
    case 'sell':
      return (
        <SellProjectCard
          className={className}
          project={project}
          section={section}
          href={href()}
          isResponsive={isResponsive}
          displayOrderButton={displayOrderButton}
          currentUser={currentUser}
        />
      );
    case 'preorder':
      return (
        <PreorderProjectCard
          className={className}
          project={project}
          section={section}
          href={href()}
          isResponsive={isResponsive}
          displayOrderButton={displayOrderButton}
          currentUser={currentUser}
        />
      );
    case 'press_only':
      return (
        <PressOnlyProjectCard
          className={className}
          project={project}
          section={section}
          href={href()}
          isResponsive={isResponsive}
          displayOrderButton={displayOrderButton}
        />
      );
    default:
      return (
        <PressOnlyProjectCard
          className={className}
          project={project}
          section={section}
          href={href()}
          isResponsive={isResponsive}
          displayOrderButton={displayOrderButton}
        />
      );
  }
};
