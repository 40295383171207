import React, {useState} from 'react';
import {get, set} from 'lodash';

import ApiHelper from 'utils/api_helper';
import {handleError, clearValidationErrors} from 'utils/validation';

import {InputField} from 'shared/components/form/input_field/input_field';
import {SelectField} from 'shared/components/form/select_field/select_field';
import {Notification} from 'shared/components/notification';
import {ReactButton} from 'shared/components/buttons/react_button/react_button';
import {ModalHeading} from 'shared/components/modals/parts/content/modal_heading/modal_heading';
import {HalfFieldsContainer} from 'shared/components/form/grid_containers/half_fields_container/half_fields_container';

export const BillingInfoForm = ({onClose, user, setUser}) => {
  const {deliverableCountries} = window.constants;
  const countriesOptionsList = deliverableCountries.map((country) => ({label: country.name, value: country.code}));
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [notification, setNotification] = useState();
  const [formParams, setFormParams] = useState({
    validation_context: 'billing_info',
    user: {
      first_name: user.first_name || '',
      last_name: user.last_name || '',
      building: user.building || '',
      street: user.street || '',
      city: user.city || '',
      prefecture: user.prefecture || '',
      postal_code: user.postal_code || '',
      country: user.country || '',
      phone_number: user.phone_number || '',
    },
  });

  const handleChange = (event) => {
    setFormParams(set({...formParams}, event.target.name, event.target.value));
  };

  const handleSubmit = () => {
    setSubmitDisabled(true);
    clearValidationErrors(setNotification);
    ApiHelper.patch('/api/v1/users/update', formParams).then((res) => {
      if (res.error) {
        handleError(res.error, setNotification);
      } else {
        setUser(res.response);
        onClose();
      }
      setSubmitDisabled(false);
    });
  };

  return (
    <div className="c-billing-info-form">
      <ModalHeading className="c-billing-info-form__modal-heading">Update your billing information</ModalHeading>
      {notification && (
        <Notification type={notification.type}>
          {notification.message}
        </Notification>
      )}
      <HalfFieldsContainer className="c-billing-info-form__user-info-group">
        <InputField
          placeholder="First name"
          label="First name *"
          onChange={handleChange}
          name="user.first_name"
          type="text"
          value={get(formParams, 'user.first_name')}
        />
        <InputField
          placeholder="Last name"
          label="Last name *"
          onChange={handleChange}
          name="user.last_name"
          type="text"
          value={get(formParams, 'user.last_name')}
        />
      </HalfFieldsContainer>
      <HalfFieldsContainer className="c-billing-info-form__adress-group">
        <InputField
          placeholder="Building"
          label="Building"
          onChange={handleChange}
          name="user.building"
          type="text"
          value={get(formParams, 'user.building')}
        />
        <InputField
          placeholder="Street address"
          label="Street address *"
          onChange={handleChange}
          name="user.street"
          type="text"
          value={get(formParams, 'user.street')}
        />
        <InputField
          placeholder="City"
          label="City *"
          onChange={handleChange}
          name="user.city"
          type="text"
          value={get(formParams, 'user.city')}
        />
        <InputField
          placeholder="Prefecture / State"
          label="Prefecture / State *"
          onChange={handleChange}
          name="user.prefecture"
          type="text"
          value={get(formParams, 'user.prefecture')}
        />
        <InputField
          placeholder="Postal code"
          label="Postal code *"
          onChange={handleChange}
          name="user.postal_code"
          type="text"
          value={get(formParams, 'user.postal_code')}
        />
        <SelectField
          label="Country *"
          placeholder="Select country"
          value={get(formParams, 'user.country')}
          onChange={(option) => handleChange({target: {value: option.value, name: 'user.country'}})}
          options={countriesOptionsList}
          isSearchable
        />
      </HalfFieldsContainer>
      <InputField
        className="c-billing-info-form__phone-number"
        placeholder="xx-xxxx-xxxx"
        label="Phone number *"
        onChange={handleChange}
        name="user.phone_number"
        type="text"
        value={get(formParams, 'user.phone_number')}
      />
      <div className="c-billing-info-form__buttons">
        <div className="c-help-block">* Required information</div>
        <ReactButton
          className="c-billing-info-form__save-button"
          type="button"
          onClick={handleSubmit}
          disabled={submitDisabled}
          theme="primary-black"
        >
          SAVE CHANGES
        </ReactButton>
      </div>
    </div>
  );
};
