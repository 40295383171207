// @flow
import React from 'react';
import styled, {css} from 'styled-components';
import {RichText} from 'prismic-reactjs';
import {cssHeadingOne} from '../../styles/typography';
import type {StorySliceRichText} from '../../cms/story';
import {getSliceHalfHeading} from '../../cms/story';
import {
  breakpointDesktopOnly,
  breakpointTabletOnly,
} from '../../styles/responsive';

const cssAlignLeft = css`
  margin-left: auto;
`;

const cssLayoutFull = css`
  width: 100%;
  flex: 0 1 100%;
  
`;

const cssLayoutHalf = css`
  width: 50%;
  flex: 0 1 calc(50% - 20px);
`;

const StyledContainer = styled.div`
  ${breakpointDesktopOnly} {
    ${cssAlignLeft};
    ${cssLayoutHalf};
  }

  ${breakpointTabletOnly} {
    ${cssLayoutFull};
  }

  h2 {
    ${cssHeadingOne};
    margin-bottom: 40px;
    ${breakpointTabletOnly} {
      padding: 0px 15px;
    }
  }
`;

type Props = {
  slice: StorySliceRichText,
};

export const HalfHeading = ({slice}: Props) => {
  return (
    <StyledContainer wovn-ignore="" layoutSize alignRight className="c-rich-text">
      {RichText.render(getSliceHalfHeading(slice))}
    </StyledContainer>
  );
};
