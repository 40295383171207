// @flow
import React from 'react';
import styled from 'styled-components';
import {RichText} from 'prismic-reactjs';
import {cssSmallPlusText, cssHeadingOne} from '../../styles/typography';
import {
  getHalfImageCaption,
  getSliceHalfImage,
  getHalfImageUrl,
  getHalfImageAltText,
  getSliceHalfHeading,
} from '../../cms/story';
import {
  breakpointTabletOnly,
  cssMobileSidePadding,
} from '../../styles/responsive';

export const StyledContainer = styled.div`
  ${props => (!props.noMobilePadding ? cssMobileSidePadding : '')};
  width: 100%;
  max-width: 1440px;
  display: flex;
  flex-direction: column;
  column-gap: 40px;

  ${breakpointTabletOnly} {
    align-items: center;
    margin: 40px 0px;
  }

  h2 {
    ${cssHeadingOne};
    margin-bottom: 40px;
  }
`;

export const StyledImageWrapper = styled.div`
  flex: 1;
  max-height: 825px;
  max-width: 660px;

  ${breakpointTabletOnly} {
    width: 100%;
    height: 100%;
    flex-wrap: wrap;
    flex: 0 1 100%;
  }
`;

export const StyledImage = styled.span`
  display: block;
  position: relative;
  overflow: hidden;
  height: 0px;
  width: 100%;
  max-height: 825px;
  max-width: 660px;
  padding-top: 125%;

  ${breakpointTabletOnly} {
    max-width: unset;
    min-width: 330px;
    min-height: 412.5px;
  }

  img {
    position: absolute;
    display: block;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    max-height: 825px;
    border: 2px solid #000000;
    object-fit: cover;

    ${breakpointTabletOnly} {
      max-height: unset;
    }
  }
`;

export const StyledCaption = styled.p`
  ${cssSmallPlusText};
  text-transform: uppercase;
  margin-top: 15px;

  ${breakpointTabletOnly} {
    margin-top: 10px;
  }
`;

type Props = {
  slice: StorySliceFeaturedImages,
};

export const HalfImage = ({ slice }: Props) => {
  const image = getSliceHalfImage(slice);
  return (
    <StyledContainer>
      <span wovn-ignore="">{RichText.render(getSliceHalfHeading(slice))}</span>
      <StyledImageWrapper isSingleImage>
        <StyledImage isSingleImage>
          <img src={getHalfImageUrl(image)} alt={getHalfImageAltText(image)} />
        </StyledImage>
        <StyledCaption wovn-ignore="">{RichText.asText(getHalfImageCaption(image))}</StyledCaption>
      </StyledImageWrapper>
    </StyledContainer>
  );
};
