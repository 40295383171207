import React from 'react';

import algoliasearch from 'algoliasearch/lite';
import {
  InstantSearch,
  Stats,
  Configure,
} from 'react-instantsearch-dom';

import Hits from './hits';
import Pagination from '../shared/custom_pagination';
import SortBy from '../shared/custom_sort_by';

export default function ArtistsList({algolia_id, algolia_search_api_key}) {
  const searchClient = algoliasearch(
    algolia_id,
    algolia_search_api_key,
  );

  return (
    <>
      <InstantSearch
        indexName="Artist"
        searchClient={searchClient}
      >
        <Configure
          filters="is_active = 1"
          distinct
        />
        <div className="p-section-header--search">
          <Stats
            translations={{
              stats(nbHits, timeSpentMS) {
                return (
                  <div className="p-section-header__hits">
                    <span wovn-ignore="">
                      {nbHits}
                    </span>
                    &nbsp;Artists & Labels
                  </div>
                );
              },
            }}
          />
          <SortBy
            defaultRefinement="Artist"
            items={[
              {value: 'Artist', label: 'Sort by Fans count'},
              {value: 'Artist_projects_count', label: 'Sort by No. of Projects'},
              {value: 'Artist_name', label: 'Sort by A-Z'},
            ]}
          />
        </div>
        <div className="p-artists__list-container" wovn-ignore="">
          <Hits />
        </div>
        <Pagination textLabel="ARTISTS & LABELS" />
        <hr className="c-divider--blank" />
      </InstantSearch>
    </>
  );
}
