import React, {useEffect, useState} from 'react';
import styled, {css} from 'styled-components';
import {connectSortBy, connectStats} from 'react-instantsearch-dom';
import {ReactComponent as ChevronDown} from '../../../../shared/assets/svgs/chevron-down.svg';
import {cssMediumText} from '../../../../shared/styles/typography';
import {breakpointMobileOnly} from '../../../../shared/styles/responsive';
import {useLanguageChange} from 'utils/use_language_change';

const StyledContent = styled.div`
  padding: 30px 27px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${breakpointMobileOnly} {
    padding: 20px 18px;
  }
`;

const cssLoading = css`
  visibility: hidden;
`;

const StyledCount = styled.div`
  ${cssMediumText};
  font-weight: 500;
  ${props => (props.isLoading ? cssLoading : '')};
  ${breakpointMobileOnly} {
    font-size: 18px;
  }
`;

const StyledSort = styled.div`
  ${cssMediumText};
  ${breakpointMobileOnly} {
    font-size: 18px;
  }
  font-weight: 500;

  select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    font: inherit;
    color: inherit;
    font-weight: bold;
    border: 0;
    padding: 0px 23px 0px 5px;
    vertical-align: bottom;
    margin-left: -2px;
  }
`;

const StyledSelectWrapper = styled.span`
  position: relative;

  svg {
    position: absolute;
    top: 2px;
    right: -2px;
    pointer-events: none;
    ${breakpointMobileOnly} {
      top: 1px;
    }
  }
`;

export const SORT_OLDEST = 'oldest';

export const StoriesSort = () => {
  const [locale, setLocale] = useState(window.constants.prismic_locales[window.constants.locale || 'en']);
  const items = [
    {value: `Prismic::Story_${locale}_published_date_desc`, label: 'Recent'},
    {value: `Prismic::Story_${locale}_title_asc`, label: 'Title'}
  ];
  const SortBy = (props) => {
    const {items, currentRefinement, refine} = props;

    return (
      <select
        onChange={(event) => {
          event.preventDefault();
          refine(event.target.value);
        }}
      >
        {items.map((item) => (
          <option key={item.label} value={item.value} selected={currentRefinement === item.value}>
            {item.label}
          </option>
        ))}
      </select>
    );
  };
  const CustomSortBy = connectSortBy(SortBy);
  const Stats = (props) => <span>{props.nbHits}</span>;
  const CustomStats = connectStats(Stats);

  useLanguageChange((event) => {
    setLocale(window.constants.prismic_locales[event.detail.locale || 'en']);
  });

  return (
    <StyledContent>
      <StyledCount>
        <CustomStats />
        {' stories'}
      </StyledCount>
      <StyledSort>
        <label>
          {'Sort by '}
          <StyledSelectWrapper>
            <CustomSortBy items={items} defaultRefinement={`Prismic::Story_${locale}`} />
            <ChevronDown />
          </StyledSelectWrapper>
        </label>
      </StyledSort>
    </StyledContent>
  );
};
