import React from 'react';
import classNames from 'classnames';

export const CheckboxField = ({
  className,
  id,
  name,
  label,
  checked,
  onChange,
  readOnly,
  locked,
  theme,
  disabled,
  children,
  value,
}) => {
  return (
    <div
      className={classNames('c-checkbox-field', {
        [className]: className,

        'is-checked': checked,
        'is-readOnly': readOnly,
        'is-locked': locked,
        'is-disabled': disabled,

        'c-checkbox-field--grey-background': theme === 'grey-background',
        'c-checkbox-field--small-bold-text': theme === 'small-bold-text',
        'c-checkbox-field--medium-size-text': theme === 'medium-size-text',
      })}
    >
      <label
        className="c-checkbox-field__label"
        readOnly={readOnly || locked || disabled}
      >
        <input
          className="c-checkbox-field__input"
          type="checkbox"
          id={id}
          name={name}
          checked={checked}
          onChange={onChange}
          data-testid="checkbox-element"
          disabled={locked || disabled || readOnly}
          value={value}
        />
        <div className="c-checkbox-field__box" />
        {label || children && (
          <span className="c-checkbox-field__title">
            {label}
            {children}
            <i className="c-checkbox-field__lock-icon c-pictogram c-pictogram--lock" />
          </span>
        )}
      </label>
    </div>
  );
};
