import React, { useState } from "react";
import { NavLink, Link } from "react-router-dom";
import classNames from 'classnames';

import { PrismicRoutes } from "components/prismic/modules/routes/routes";

export const ServicesTabs = ({
  servicesList,
  scrollToContent,
  isTabs,
  isAccordion,
}) => {
  const [activeTab, setActiveTab] = useState(null);

  return (
    <div className="services-tabs">
      {servicesList &&
        servicesList.map((item, index) => {
          return (
            <div className="services-tabs__category" key={index}>
              {isAccordion && (
                <div className="services-tabs__category-link">
                  {item.name}
                </div>
              )}
              {isTabs && (
                <Link
                  className="services-tabs__category-link"
                  to={PrismicRoutes.servicesPath(item.items[0].uid)}
                  onClick={scrollToContent}
                >
                  {item.name}
                </Link>
              )}
              <ul className="services-tabs__category-list">
                {item.items.map((item) => {
                  return (
                    <li className={classNames('services-tabs__item', {'is-active': activeTab === item.id})} key={item.id}>
                      {isAccordion && (
                        <>
                          <button
                            className={classNames('services-tabs__tab-link', {'is-active': activeTab === item.id})}
                            onClick={() => setActiveTab(item.id === activeTab ? null : item.id)}
                            type="button"
                          >
                            {item.data.title[0].text}
                          </button>
                          <div className="services-tabs__tab-content">
                            <div className="services-tabs__description">{item.data.subtitle[0].text}</div>
                            <a href={PrismicRoutes.servicesPath(item.uid)} className="services-tabs__tab-content-link c-link">LEARN MORE &#8594;</a>
                          </div>
                        </>
                      )}
                      {isTabs && (
                        <NavLink
                          className="services-tabs__link"
                          activeClassName="is-active"
                          to={PrismicRoutes.servicesPath(item.uid)}
                          onClick={scrollToContent}
                        >
                          {item.data.title[0].text}
                        </NavLink>
                      )}
                    </li>
                  );
                })}
              </ul>
            </div>
          );
        })}
    </div>
  );
};
