import React from 'react';

import {supportDocuments} from 'modules/globalLinks';

export const CheckoutSupportLinksSection = () => {
  return (
    <div className="c-checkout-support-links-section">
      <a
        className="c-checkout-support-links-section__info-link"
        href={supportDocuments.cancellationRefundReplacementPolicy}
        target="_blank"
      >
        Information on cancellation, refunds and replacements
      </a>
    </div>
  );
};
