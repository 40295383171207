// @flow
import React from 'react';
import styled, { css } from 'styled-components';
import StoryPreview, { STORY_SIZE } from '../StoryPreview/StoryPreview';
import { breakpointDesktopOnly } from '../../../../shared/styles/responsive';
import type { StoryMdl } from '../../../../shared/cms/story';

const cssLoading = css`
  min-height: 100vh;
  background-color: #d0d0d0;
`;

const StyledContainer = styled.section`
  ${breakpointDesktopOnly} {
    display: flex;
    flex-wrap: wrap;
    border-top: 1px solid #000000;
    border-bottom: 1px solid #000000;
  }
  ${props => (props.isLoading ? cssLoading : '')};

  .c-stories-list__container {
    ${breakpointDesktopOnly} {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
    }
  }
`;

type Props = {
  loading: boolean,
  stories: StoryMdl[],
  hasFilter?: boolean,
  isMediumSizeFirst?: boolean,
};

const StoriesList = ({ loading, stories, hasFilter = false, isMediumSizeFirst}: Props) => (
  <StyledContainer className="c-stories-list" isLoading={loading}>
    <div className="c-stories-list__container" wovn-ignore="">
      {!loading &&
        stories.map((story: StoryMdl, index) => {
          const currentIndex = isMediumSizeFirst ? index + 1 : index;
          let size;

          switch (currentIndex % 3) {
            case 2:
              size = STORY_SIZE.small;
              break;
            case 1:
              size = STORY_SIZE.medium;
              break;
            default:
              size = STORY_SIZE.large;
          }
          return (
          <StoryPreview
            story={story}
            size={size}
            key={index}
          />
        )})}
    </div>
  </StyledContainer>
);

export default StoriesList;
