import React from 'react';
import {connectInfiniteHits} from 'react-instantsearch-dom';
import classNames from 'classnames';

const CustomPagination = ({
  hasMore, refineNext, textLabel, customClass,
}) => (
  <>
    {hasMore && (
      <div className={classNames('c-button-grid--center-button', customClass)} onClick={(e) => refineNext()}>
        <span className="c-button c-button--large c-button--black">
          LOAD MORE
          {textLabel && ' '}
          {textLabel}
        </span>
      </div>
    )}
  </>
);

export default connectInfiniteHits(CustomPagination);
