import React, {useState, useEffect} from 'react';

import ApiHelper from 'utils/api_helper';

import {BillingDetails} from './billing_details';
import {ShippingDetails} from './shipping_details';

export const AddressSection = ({order}) => {
  const orderItem = order.order_items[0] || {};
  const [initialOrder, setOrder] = useState(order);
  const [isEditable, setIsEditable] = useState(false);

  useEffect(() => {
    ApiHelper.get(`/api/v1/orders/${order.id}/editable`).then((res) => {
      setIsEditable(res.response.editable);
    });
  }, []);

  return (
    <div>
      <div className="c-dashboard__section" wovn-ignore="">
        {orderItem.order_type != 'digital' && <BillingDetails order={initialOrder} setOrder={setOrder} isEditable={isEditable} />}
      </div>
      <div className="c-dashboard__section" style={{borderBottom: '2px solid #000000'}} wovn-ignore="">
        {orderItem.order_type != 'digital' && <ShippingDetails order={initialOrder} setOrder={setOrder} isEditable={isEditable} />}
      </div>
    </div>
  );
};
