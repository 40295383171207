export const imagesMapping = ({
  spec,
  productType,
  projectId,
  itemPageDesignId,
  projectFileTypes,
  itemPageDesignFileTypes,
}) => {
  let images = [];

  const getFileType = ({value, item_type}) => {
    const fileTypes = {Project: projectFileTypes, ItemPageDesign: itemPageDesignFileTypes}[item_type];

    return fileTypes?.find((type) => type.value === value);
  };

  if (productType === 'vinyl') {
    const isGatefold = /gatefold/.test(spec.sleeve_type);
    images = [
      {
        dimensions: [600, 600],
        view: 21,
        title: 'thumbnail_image',
        item_id: projectId,
        item_type: 'Project',
        file_type_id: getFileType({value: 'thumbnail_image', item_type: 'Project'}).id,
        file_type_value: getFileType({value: 'thumbnail_image', item_type: 'Project'}).value,
        cameraZoom: {
          vinyl_size_12: 2500,
          vinyl_size_10: 3000,
          vinyl_size_7: 4200,
        },
      },
      {
        dimensions: [600, 600],
        view: 22,
        title: 'sleeve_thumbnail_image',
        item_id: projectId,
        item_type: 'Project',
        file_type_id: getFileType({value: 'sleeve_thumbnail_image', item_type: 'Project'}).id,
        file_type_value: getFileType({value: 'sleeve_thumbnail_image', item_type: 'Project'}).value,
        cameraZoom: {
          vinyl_size_12: 2500,
          vinyl_size_10: 3000,
          vinyl_size_7: 4200,
        },
      },
      {
        dimensions: [600, 600],
        view: 23,
        title: 'vinyl_thumbnail_image',
        item_id: projectId,
        item_type: 'Project',
        file_type_id: getFileType({value: 'vinyl_thumbnail_image', item_type: 'Project'}).id,
        file_type_value: getFileType({value: 'vinyl_thumbnail_image', item_type: 'Project'}).value,
        cameraZoom: {
          vinyl_size_12: 2500,
          vinyl_size_10: 3000,
          vinyl_size_7: 4200,
        },
      },
      {
        dimensions: [1022, 634],
        view: 11,
        title: 'rendered_image_1',
        item_id: itemPageDesignId,
        item_type: 'ItemPageDesign',
        file_type_id: getFileType({value: 'rendered_image_1', item_type: 'ItemPageDesign'}).id,
        file_type_value: getFileType({value: 'rendered_image_1', item_type: 'ItemPageDesign'}).value,
        position: {
          vinyl_size_12: {x: -0.007},
          vinyl_size_10: {x: -0.008},
          vinyl_size_7: {x: -0.004},
        },
        zoom: 0.203,
      },
      {
        dimensions: [1022, 634],
        view: 12,
        title: 'rendered_image_2',
        item_id: itemPageDesignId,
        item_type: 'ItemPageDesign',
        file_type_id: getFileType({value: 'rendered_image_2', item_type: 'ItemPageDesign'}).id,
        file_type_value: getFileType({value: 'rendered_image_2', item_type: 'ItemPageDesign'}).value,
      },
      {
        dimensions: [1022, 634],
        view: 13,
        title: 'rendered_image_3',
        item_id: itemPageDesignId,
        item_type: 'ItemPageDesign',
        file_type_id: getFileType({value: 'rendered_image_3', item_type: 'ItemPageDesign'}).id,
        file_type_value: getFileType({value: 'rendered_image_3', item_type: 'ItemPageDesign'}).value,
      },
      {
        dimensions: [1022, 634],
        view: 15,
        title: 'rendered_image_4',
        item_id: itemPageDesignId,
        item_type: 'ItemPageDesign',
        file_type_id: getFileType({value: 'rendered_image_4', item_type: 'ItemPageDesign'}).id,
        file_type_value: getFileType({value: 'rendered_image_4', item_type: 'ItemPageDesign'}).value,
      },
      {
        dimensions: [1022, 634],
        view: 16,
        title: 'rendered_image_5',
        item_id: itemPageDesignId,
        item_type: 'ItemPageDesign',
        file_type_id: getFileType({value: 'rendered_image_5', item_type: 'ItemPageDesign'}).id,
        file_type_value: getFileType({value: 'rendered_image_5', item_type: 'ItemPageDesign'}).value,
      },
      {
        dimensions: [1022, 634],
        view: 11,
        title: 'rendered_image',
        item_id: projectId,
        item_type: 'Project',
        file_type_id: getFileType({value: 'rendered_image', item_type: 'Project'}).id,
        file_type_value: getFileType({value: 'rendered_image', item_type: 'Project'}).value,
        position: {
          vinyl_size_12: {x: -0.007},
          vinyl_size_10: {x: -0.008},
          vinyl_size_7: {x: -0.004},
        },
        zoom: 0.203,
      },
    ];
    if (isGatefold) {
      images.push({
        dimensions: [1022, 634],
        view: 14,
        title: 'rendered_image_6',
        item_id: itemPageDesignId,
        item_type: 'ItemPageDesign',
        file_type_id: getFileType({value: 'rendered_image_6', item_type: 'ItemPageDesign'}).id,
        file_type_value: getFileType({value: 'rendered_image_6', item_type: 'ItemPageDesign'}).value,
      });
    }
    if (isGatefold || spec.num_of_vinyls === 2) {
      images.push({
        dimensions: [600, 600],
        view: 24,
        title: 'vinyl2_thumbnail_image',
        item_id: projectId,
        item_type: 'Project',
        file_type_id: getFileType({value: 'vinyl2_thumbnail_image', item_type: 'Project'}).id,
        file_type_value: getFileType({value: 'vinyl2_thumbnail_image', item_type: 'Project'}).value,
        cameraZoom: {
          vinyl_size_12: 2500,
          vinyl_size_10: 3000,
          vinyl_size_7: 4200,
        },
      });
    }
    if (spec.num_of_vinyls === 2) {
      images.push({
        dimensions: [1022, 634],
        view: 17,
        title: 'rendered_image_7',
        item_id: itemPageDesignId,
        item_type: 'ItemPageDesign',
        file_type_id: getFileType({value: 'rendered_image_7', item_type: 'ItemPageDesign'}).id,
        file_type_value: getFileType({value: 'rendered_image_7', item_type: 'ItemPageDesign'}).value,
      });
      images.push({
        dimensions: [1022, 634],
        view: 18,
        title: 'rendered_image_8',
        item_id: itemPageDesignId,
        item_type: 'ItemPageDesign',
        file_type_id: getFileType({value: 'rendered_image_8', item_type: 'ItemPageDesign'}).id,
        file_type_value: getFileType({value: 'rendered_image_8', item_type: 'ItemPageDesign'}).value,
      });
    }
  } else if (productType === 'cassette') {
    images = [
      {
        dimensions: [600, 600],
        title: 'thumbnail_image',
        item_id: projectId,
        item_type: 'Project',
        file_type_id: getFileType({value: 'thumbnail_image', item_type: 'Project'}).id,
        file_type_value: getFileType({value: 'thumbnail_image', item_type: 'Project'}).value,
        view: 4,
        zoom: 0.171,
      },
      {
        dimensions: [600, 600],
        title: 'cassette_thumbnail_image',
        item_id: projectId,
        item_type: 'Project',
        file_type_id: getFileType({value: 'cassette_thumbnail_image', item_type: 'Project'}).id,
        file_type_value: getFileType({value: 'cassette_thumbnail_image', item_type: 'Project'}).value,
        view: 2,
        zoom: 0,
      },
      {
        dimensions: [600, 600],
        title: 'case_thumbnail_image',
        item_id: projectId,
        item_type: 'Project',
        file_type_id: getFileType({value: 'case_thumbnail_image', item_type: 'Project'}).id,
        file_type_value: getFileType({value: 'case_thumbnail_image', item_type: 'Project'}).value,
        view: 4,
        zoom: 0.171,
      },
      {
        dimensions: [1022, 634],
        title: 'rendered_image_1',
        item_id: itemPageDesignId,
        item_type: 'ItemPageDesign',
        file_type_id: getFileType({value: 'rendered_image_1', item_type: 'ItemPageDesign'}).id,
        file_type_value: getFileType({value: 'rendered_image_1', item_type: 'ItemPageDesign'}).value,
        view: 1,
        zoom: 0.171,
      },
      {
        dimensions: [1022, 634],
        title: 'rendered_image_4',
        item_id: itemPageDesignId,
        item_type: 'ItemPageDesign',
        file_type_id: getFileType({value: 'rendered_image_4', item_type: 'ItemPageDesign'}).id,
        file_type_value: getFileType({value: 'rendered_image_4', item_type: 'ItemPageDesign'}).value,
        view: 2,
        zoom: 0.171,
      },
      {
        dimensions: [1022, 634],
        title: 'rendered_image_3',
        item_id: itemPageDesignId,
        item_type: 'ItemPageDesign',
        file_type_id: getFileType({value: 'rendered_image_3', item_type: 'ItemPageDesign'}).id,
        file_type_value: getFileType({value: 'rendered_image_3', item_type: 'ItemPageDesign'}).value,
        view: 3,
        zoom: 0.171,
      },
      {
        dimensions: [1022, 634],
        title: 'rendered_image_2',
        item_id: itemPageDesignId,
        item_type: 'ItemPageDesign',
        file_type_id: getFileType({value: 'rendered_image_2', item_type: 'ItemPageDesign'}).id,
        file_type_value: getFileType({value: 'rendered_image_2', item_type: 'ItemPageDesign'}).value,
        view: 4,
        zoom: 0.171,
      },
      {
        dimensions: [1022, 634],
        title: 'rendered_image',
        item_id: projectId,
        item_type: 'Project',
        file_type_id: getFileType({value: 'rendered_image', item_type: 'Project'}).id,
        file_type_value: getFileType({value: 'rendered_image', item_type: 'Project'}).value,
        view: 1,
        zoom: 0.171,
      },
    ];
  }

  return images;
};
