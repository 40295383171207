import React from 'react';

import {GroupSection} from '../../group_section/group_section';
import {ScreenContainer} from '../../screen_container/screen_container';

export const IconsScreen = ({navigationList}) => {
  const colorsList = [
    {
      name: 'Pictograms',
      items: [
        {name: 'c-pictogram--download'},
        {name: 'c-pictogram--upload'},
        {name: 'c-pictogram--alert-a'},
        {name: 'c-pictogram--alert-b'},
        {name: 'c-pictogram--dot'},
        {name: 'c-pictogram--review'},
        {name: 'c-pictogram--arrow-back'},
        {name: 'c-pictogram--arrow-down'},
        {name: 'c-pictogram--arrow-forward'},
        {name: 'c-pictogram--arrow-forward-small'},
        {name: 'c-pictogram--arrow-up'},
        {name: 'c-pictogram--cart'},
        {name: 'c-pictogram--checkbox-checked'},
        {name: 'c-pictogram--checkbox-empty'},
        {name: 'c-pictogram--chevron-down'},
        {name: 'c-pictogram--chevron-left'},
        {name: 'c-pictogram--chevron-right'},
        {name: 'c-pictogram--chevron-up'},
        {name: 'c-pictogram--close'},
        {name: 'c-pictogram--contact'},
        {name: 'c-pictogram--crowdfunding-alt'},
        {name: 'c-pictogram--crowdfunding-fill'},
        {name: 'c-pictogram--crowdfunding-outline'},
        {name: 'c-pictogram--digital'},
        {name: 'c-pictogram--duration'},
        {name: 'c-pictogram--edit'},
        {name: 'c-pictogram--ellipses'},
        {name: 'c-pictogram--embed-fill'},
        {name: 'c-pictogram--facebook'},
        {name: 'c-pictogram--filters'},
        {name: 'c-pictogram--format'},
        {name: 'c-pictogram--globe'},
        {name: 'c-pictogram--hamburger'},
        {name: 'c-pictogram--i-edit'},
        {name: 'c-pictogram--image'},
        {name: 'c-pictogram--stock'},
        {name: 'c-pictogram--instagram'},
        {name: 'c-pictogram--instagram-fill'},
        {name: 'c-pictogram--lock'},
        {name: 'c-pictogram--login'},
        {name: 'c-pictogram--minus'},
        {name: 'c-pictogram--music-playlist'},
        {name: 'c-pictogram--music'},
        {name: 'c-pictogram--pause'},
        {name: 'c-pictogram--pen'},
        {name: 'c-pictogram--play'},
        {name: 'c-pictogram--playlist'},
        {name: 'c-pictogram--plus'},
        {name: 'c-pictogram--polaroid'},
        {name: 'c-pictogram--preorder-alt'},
        {name: 'c-pictogram--preorder'},
        {name: 'c-pictogram--profile'},
        {name: 'c-pictogram--purchase-vinyl'},
        {name: 'c-pictogram--purchase'},
        {name: 'c-pictogram--question'},
        {name: 'c-pictogram--radio-empty'},
        {name: 'c-pictogram--radio-selected'},
        {name: 'c-pictogram--receipt'},
        {name: 'c-pictogram--record-fill'},
        {name: 'c-pictogram--record-outline'},
        {name: 'c-pictogram--search'},
        {name: 'c-pictogram--settings-outline'},
        {name: 'c-pictogram--share'},
        {name: 'c-pictogram--shipping-date'},
        {name: 'c-pictogram--shipping-fee'},
        {name: 'c-pictogram--shop'},
        {name: 'c-pictogram--sign'},
        {name: 'c-pictogram--skip-back'},
        {name: 'c-pictogram--skip-forward'},
        {name: 'c-pictogram--soundcloud'},
        {name: 'c-pictogram--soundcloud-fill'},
        {name: 'c-pictogram--star'},
        {name: 'c-pictogram--storage'},
        {name: 'c-pictogram--story'},
        {name: 'c-pictogram--summary-alt'},
        {name: 'c-pictogram--summary'},
        {name: 'c-pictogram--tax'},
        {name: 'c-pictogram--tick'},
        {name: 'c-pictogram--twitter'},
        {name: 'c-pictogram--twitter-fill'},
        {name: 'c-pictogram--wallet'},
        {name: 'c-pictogram--approve'},
        {name: 'c-pictogram--audio-download'},
        {name: 'c-pictogram--audio-upload'},
        {name: 'c-pictogram--crowdfunding-alt1'},
        {name: 'c-pictogram--crowdfunding-alt-black'},
        {name: 'c-pictogram--delivery'},
        {name: 'c-pictogram--file-wav'},
        {name: 'c-pictogram--folder-upload'},
        {name: 'c-pictogram--goal'},
        {name: 'c-pictogram--launch'},
        {name: 'c-pictogram--music-black'},
        {name: 'c-pictogram--pay'},
        {name: 'c-pictogram--play-square'},
        {name: 'c-pictogram--production'},
        {name: 'c-pictogram--record'},
        {name: 'c-pictogram--settings'},
        {name: 'c-pictogram--mixer'},
        {name: 'c-pictogram--soundwave'},
        {name: 'c-pictogram--system-configuration'},
        {name: 'c-pictogram--test-pressings'},
        {name: 'c-pictogram--verify'},
        {name: 'c-pictogram--pricing'},
        {name: 'c-pictogram--bandcamp'},
        {name: 'c-pictogram--youtube'},
        {name: 'c-pictogram--spotify'},
        {name: 'c-pictogram--tumblr'},
        {name: 'c-pictogram--stock'},
        {name: 'c-pictogram--details'},
        {name: 'c-pictogram--orders'},
        {name: 'c-pictogram--purshase'},
        {name: 'c-pictogram--vinyl'},
        {name: 'c-pictogram--wallet'},
        {name: 'c-pictogram--dashboard'},
        {name: 'c-pictogram--star-black'},
        {name: 'c-pictogram--qrcode'},
        {name: 'c-pictogram--cassette'},
        {name: 'c-pictogram--rectangle-cassette'},
        {name: 'c-pictogram--coupon'},
        {name: 'c-pictogram--heart'},
        {name: 'c-pictogram--small-black-cassette'},
        {name: 'c-pictogram--message'},
        {name: 'c-pictogram--large-circle'},
        {name: 'c-pictogram--instagram-circle'},
      ],
    },
  ];

  return (
    <ScreenContainer title="Icons">
      {colorsList && colorsList.map((group, index) => {
        return (
          <GroupSection title={group.name} type="icons" key={index}>
            {group.items.map((item, index) => {
              const itemToCopy = `.c-pictogram .${item.name}`;
              return (
                <div className="icon-item" onClick={() => { navigator.clipboard.writeText(itemToCopy); }} key={index}>
                  <i className={`c-pictogram ${item.name}`} />
                </div>
              );
            })}
          </GroupSection>
        );
      })}
    </ScreenContainer>
  );
};
