import React, {useRef, useState, useEffect} from 'react';
import Color from 'color';
import defaultProfileImagePath from 'images/dummy/dummy_profile_image.png';
import classNames from 'classnames';
import {assetPath} from 'project_start/components/utils/visualizer/assets_helper';
import {useClickOutside} from 'utils/use_click_outside';

export const AccountMenu = ({
  user,
  className,
  textColor,
  isDashboard,
}) => {
  const [dropdownOpened, setDropdownOpened] = useState(false);
  const accountMenu = useRef();
  const openButtonRef = useRef();

  const setMaxHeight = () => {
    const navPanelCoordinates = document.querySelector('[data-nav-panel]')?.getBoundingClientRect();

    if (navPanelCoordinates && accountMenu?.current) {
      accountMenu.current.style.maxHeight = `${window.innerHeight - (navPanelCoordinates.top + navPanelCoordinates.height)}px`;
    }
  };

  useEffect(() => {
    setMaxHeight();
    window.addEventListener('resize', setMaxHeight);
    return () => {
      window.removeEventListener('resize', setMaxHeight);
    };
  }, [accountMenu]);

  useClickOutside([accountMenu, openButtonRef], dropdownOpened, () => setDropdownOpened(false));

  if (!user) {
    return false;
  }

  const pageDesign = user.focused_project?.item?.item_page_design;

  const backgroundStyle = () => {
    const backgroundStyleColor = {
      backgroundColor: pageDesign?.background_color || '#E4E5DE',
    };

    switch (pageDesign?.main_visual_type) {
      case 'main_visual_image':
        if (pageDesign?.background_image_url) {
          return {
            backgroundImage: `url(${pageDesign?.background_image_url})`,
            opacity: 0.6,
          };
        }

        return backgroundStyleColor;
      case 'main_visual_user_image':
      case 'main_visual_video':
        return {
          backgroundImage: `url(${pageDesign?.cover_image_url})`,
          opacity: 0.6,
        };
      default:
        return backgroundStyleColor;
    }
  };

  let featuredProjectTextColor = 'black';
  if (pageDesign?.main_visual_type == 'main_visual_image'
      && !pageDesign?.background_image_url
      && !Color(pageDesign?.background_color || 'white').isLight()) {
    featuredProjectTextColor = 'white';
  }

  const renderFocusedProject = () => {
    const {
      condition_message,
      setup_project_path,
      rendered_image_url,
      product_order: {
        title,
      },
    } = user.focused_project;

    return (
      <div
        className="p-avatar-popup-project"
        ref={accountMenu}
      >
        <div className="p-avatar-popup-project__bg" style={backgroundStyle()} />
        <div className="p-avatar-popup-project__image">
          <img src={assetPath(rendered_image_url)} height="81" />
        </div>
        <div className="p-avatar-popup-project__info" wovn-ignore="">
          <div className={`p-avatar-popup-project__title p-avatar-popup-project__title--${featuredProjectTextColor}`}>{title || '<NO TITLE>'}</div>
          <div className={`p-avatar-popup-project__projects p-avatar-popup-project__projects--${featuredProjectTextColor}`}>{condition_message}</div>
        </div>
        <a className="c-button c-button--white c-button--full c-button--iron-right p-avatar-popup-project__button" href={setup_project_path}>
          GO TO PROJECT
          <i className="c-pictogram c-pictogram--arrow-forward-small" />
        </a>
      </div>
    );
  };

  return (
    <>
      <a
        className={classNames({[className]: className})}
        data-dropdown-opener="menu"
        onClick={() => {
          setDropdownOpened(!dropdownOpened);
        }}
        ref={openButtonRef}
      >
        <span
          className="p-avatar-popup__avatar"
          style={{backgroundImage: `url(${user && user.profile_image_url || defaultProfileImagePath})`, borderColor: textColor}}
        />
      </a>
      <div
        className={classNames('p-avatar-popup__popover', {
          'p-avatar-popup__popover--dashboard': isDashboard,
          'p-avatar-popup__popover--opened': dropdownOpened,
        })}
        ref={accountMenu}
      >
        {user.focused_project && renderFocusedProject()}
        <div className="p-avatar-popup-menu" wovn-ignore="">
          {user.owned_projects_count > 0 && (
            <a href="/account/projects" className="p-avatar-popup-menu__link">
              Projects
              <span className="c-badge--yellow p-avatar-popup-menu__badge">
                {user.owned_projects_count <= 99 ? user.owned_projects_count : '99+'}
              </span>
            </a>
          )}
          <a href="/orders" className="p-avatar-popup-menu__link">
            Purchases
          </a>
          <a href="/downloads" className="p-avatar-popup-menu__link">
            Download
          </a>
          <a href="/watchings" className="p-avatar-popup-menu__link">
            Watchlist
          </a>
          <a href="/account/settings" className="p-avatar-popup-menu__link">
            Settings
          </a>
          <a href="/signout" className="p-avatar-popup-menu__link">
            Log out
          </a>
          <a className="p-avatar-popup__popover__close u-hidden-desktop-only" onClick={() => setDropdownOpened(false)}>CLOSE</a>
        </div>
      </div>
    </>
  );
};
