import ApiHelper from 'utils/api_helper';
import Routes from 'dashboard/modules/routes';

export function getCurrentUser() {
  const path = Routes.api.getCurrentUserPath();

  return ApiHelper.get(path);
}

export function fetchArtistProjects({params}) {
  const path = Routes.api.fetchProjectsPath(params);

  return ApiHelper.get(path);
}

export function fetchProject({projectId, params = {}}) {
  const path = Routes.api.fetchProjectPath(projectId, params);

  return ApiHelper.get(path);
}

export function publishProject({projectId}) {
  const path = Routes.api.publishProjectPath(projectId);

  return ApiHelper.patch(path);
}

export function startProduction({projectId, formParams}) {
  const path = Routes.api.startProductionPath(projectId);

  return ApiHelper.patch(path, formParams);
}

export function fixProductionQuantity({projectId, formParams}) {
  const path = Routes.api.fixProductionQuantityPath(projectId);

  return ApiHelper.patch(path, formParams);
}

export function submitTestpressApproval({projectId, formParams}) {
  const path = Routes.api.submitTestpressApprovalPath(projectId);

  return ApiHelper.patch(path, formParams);
}

export function submitArtworkConfirmationApproval({projectId, formParams}) {
  const path = Routes.api.submitArtworkConfirmationApprovalPath(projectId);

  return ApiHelper.patch(path, formParams);
}

export function saveCrowdfundingTabForm({projectId, formParams}) {
  const path = Routes.api.saveCrowdfundingTabPath(projectId);

  return ApiHelper.patch(path, formParams);
}

export function savePressSellTabForm({projectId, formParams}) {
  const path = Routes.api.savePressSellTabPath(projectId);

  return ApiHelper.patch(path, formParams);
}

export function saveQuantitySetting({projectId, formParams}) {
  const path = Routes.api.saveQuantitySettingPath(projectId);

  return ApiHelper.patch(path, formParams);
}

export function startSelling({projectId, formParams}) {
  const path = Routes.api.startSellingPath(projectId);

  return ApiHelper.post(path, formParams);
}

export function savePressOnlyTabForm({projectId, formParams}) {
  const path = Routes.api.savePressOnlyTabPath(projectId);

  return ApiHelper.patch(path, formParams);
}

export function saveVinylSetting({projectId, formParams}) {
  const path = Routes.api.saveVinylSettingPath(projectId);

  return ApiHelper.patch(path, formParams);
}

export function saveDistributionTabForm({projectId, formParams}) {
  const path = Routes.api.saveDistributionTabPath(projectId);

  return ApiHelper.patch(path, formParams);
}

export function savePageContentSetting({projectId, formParams}) {
  const path = Routes.api.savePageContentSettingPath(projectId);

  return ApiHelper.patch(path, formParams);
}

export function saveArtistProfileSetting({projectId, formParams}) {
  const path = Routes.api.saveArtistProfileSettingPath(projectId);

  return ApiHelper.patch(path, formParams);
}

export function saveTracklistSetting({projectId, formParams}) {
  const path = Routes.api.saveTracklistSettingPath(projectId);

  return ApiHelper.patch(path, formParams);
}

export function saveSampleAudiosSetting({projectId, formParams}) {
  const path = Routes.api.saveSampleAudiosSettingPath(projectId);

  return ApiHelper.patch(path, formParams);
}

export function saveDigitalAudiosSetting({projectId, formParams}) {
  const path = Routes.api.saveDigitalAudiosSettingPath(projectId);

  return ApiHelper.patch(path, formParams);
}

export function createTranscodeAudioFileTask({originalFileName, transcodeType}) {
  const path = Routes.api.createAudioTranscodeTaskPath;

  const params = {
    transcode_audio_file_task: {
      original_file_name: originalFileName,
      transcode_type: transcodeType,
    },
  };

  return ApiHelper.post(path, params);
}

export function updateTranscodeAudioFileTask({transcodeTaskId}) {
  const path = Routes.api.updateAudioTranscodeTaskPath(transcodeTaskId);

  const params = {
    transcode_audio_file_task: {
      state: 'ready',
    },
  };

  return ApiHelper.patch(path, params);
}

export function fetchTranscodeAudioTask({transcodeTaskId}) {
  const path = Routes.api.updateAudioTranscodeTaskPath(transcodeTaskId);

  return ApiHelper.get(path);
}

export function fetchProjectSalesWallet({projectId, formParams}) {
  const path = Routes.api.fetchProjectSalesWalletPath(projectId);

  return ApiHelper.get(path, formParams);
}

export function requestRevenue({projectId, formParams}) {
  const path = Routes.api.requestRevenuePath(projectId);

  return ApiHelper.post(path, formParams);
}

export function fetchProjectSalesSummary({projectId}) {
  const path = Routes.api.fetchProjectSalesSummaryPath(projectId);

  return ApiHelper.get(path);
}

export function uploadCoverImage({file, progress}) {
  return upload('/upload/cover', file, progress);
}

export function uploadTexture({file, progress, isCancelled}) {
  return upload('/upload/texture', file, progress, isCancelled);
}

export function uploadImage({file, progress}) {
  return upload('/upload/image', file, progress);
}

export function uploadThumbnail({file, progress}) {
  return upload('/upload/thumbnail', file, progress);
}

export function uploadAudio({file, progress, isCancelled}) {
  return upload('/upload/audio', file, progress, isCancelled);
}

export function uploadPressingAudio({file, progress}) {
  return upload('/upload/pressing_audio', file, progress);
}

export function uploadPressingArtwork({file, progress, isCancelled}) {
  return upload('/upload/pressing_artwork', file, progress, isCancelled);
}

function upload(endPoint, file, progress, isCancelled) {
  const formData = new FormData();
  formData.append('file', file);

  return ApiHelper.uploadFiles(endPoint, formData, progress, isCancelled);
}

export function uploadFiles(projectId, files, progress, params = {}) {
  const formData = new FormData();
  Object.entries(params).forEach((item) => formData.append(item[0], item[1]));
  files.forEach((file) => {
    formData.append('files[]', file);
  });
  const path = Routes.api.uploadPressFilesPath(projectId);
  return ApiHelper.uploadFiles(path, formData, progress);
}

export function saveArtworkMockupsTabForm({projectId, formParams}) {
  const path = Routes.api.saveArtworkMockupsTabPath(projectId);

  return ApiHelper.patch(path, formParams);
}

export function saveArtworkPrintingTabForm({projectId, formParams}) {
  const path = Routes.api.saveArtworkPrintingTabPath(projectId);

  return ApiHelper.patch(path, formParams);
}

export function saveCopyrightSetting({projectId, formParams}) {
  const path = Routes.api.saveCopyrightSettingPath(projectId);

  return ApiHelper.patch(path, formParams);
}

export function createProjectPayment({projectId, formParams}) {
  const path = Routes.api.createProjectPaymentPath(projectId);

  return ApiHelper.post(path, formParams);
}

export function saveVinylAudioSetting({projectId, formParams}) {
  const path = Routes.api.saveVinylAudioSettingPath(projectId);

  return ApiHelper.patch(path, formParams);
}

export function fetchProjectSalesOrders({projectId, params = {}}) {
  const path = Routes.api.fetchProjectSalesOrdersPath(projectId, params);

  return ApiHelper.get(path);
}

export function fetchProjectSalesStocks({projectId, params = {}}) {
  const path = Routes.api.fetchProjectSalesStocksPath(projectId, params);

  return ApiHelper.get(path);
}

export function fetchProjectQratesMasteringPrice({projectId}) {
  const path = Routes.api.fetchProjectQratesMasteringPricePath(projectId);

  return ApiHelper.get(path);
}

export function saveProjectQratesMastering({projectId, formParams}) {
  const path = Routes.api.saveProjectQratesMasteringPath(projectId);

  return ApiHelper.post(path, formParams);
}

export function saveProjectTestProduction({projectId, formParams}) {
  const path = Routes.api.saveProjectTestProductionPath(projectId);

  return ApiHelper.patch(path, formParams);
}

export function calculateOrder({projectId, formParams}) {
  const path = Routes.api.calculateOrderPath(projectId);

  return ApiHelper.post(path, formParams);
}

export function calculateProductOrder({projectId, formParams}) {
  const path = Routes.api.calculatePath(projectId);

  return ApiHelper.post(path, formParams);
}

export function createSelfPurchaseOrder({projectId, formParams}) {
  const path = Routes.api.selfPurchaseOrdersPath(projectId);

  return ApiHelper.post(path, formParams);
}

export function fetchProjectSelfPurchaseOrders({projectId, params = {}}) {
  const path = Routes.api.fetchProjectSelfPurchaseOrdersPath(projectId, params);

  return ApiHelper.get(path);
}

export function fetchProjectPressMessages({projectId, params = {}}) {
  const path = Routes.api.projectPressMessagesPath(projectId, params);

  return ApiHelper.get(path);
}

export function calculateStockTransfer({projectId, formParams}) {
  const path = Routes.api.calculateStockTransferPath(projectId);

  return ApiHelper.post(path, formParams);
}

export function sendVinyl({projectId, formParams}) {
  const path = Routes.api.sendVinylPath(projectId);

  return ApiHelper.post(path, formParams);
}

export function createProjectPressMessage({projectId, formParams}) {
  const path = Routes.api.projectPressMessagesPath(projectId);

  return ApiHelper.post(path, formParams);
}

export function sendMessageToCustomers({projectId, formParams}) {
  const path = Routes.api.sendMessageToCustomersPath(projectId);

  return ApiHelper.post(path, formParams);
}

export function readProjectMessages({projectId}) {
  const path = Routes.api.readProjectMessagesPath(projectId);

  return ApiHelper.post(path);
}

export const getFinancialStatement = ({projectId, params = {}}) => {
  const path = Routes.api.getFinancialStatementPath(projectId, params);

  return ApiHelper.get(path);
};

export const getDefaultPrices = ({params = {}}) => {
  const path = Routes.api.getDefaultPricesPath();

  return ApiHelper.post(path, params);
};
