import React, {useRef, useEffect} from 'react';
import classNames from 'classnames';

export const ProjectTimeline = ({
  items,
  isError,
  message,
  currentItem,
}) => {
  const isTimelineComplete = currentItem >= items.length;

  const containerElement = useRef(null);
  const messageElement = useRef(null);

  const setAdaptiveHeight = () => {
    const messageHeight = messageElement.current.offsetHeight;
    containerElement.current.style.paddingTop = `${messageHeight}px`;
  };

  useEffect(() => {
    if (message && containerElement?.current && messageElement?.current) {
      setAdaptiveHeight();
      window.addEventListener('resize', () => setAdaptiveHeight());
      return () => {
        window.removeEventListener('resize', () => setAdaptiveHeight());
      };
    }
  }, [message, containerElement, messageElement]);

  return (
    <div className="c-project-timeline" ref={containerElement}>
      <div className="c-project-timeline__container">
        {items.map((item, index) => {
          const isCurrent = (index + 1) === currentItem;
          return (
            <div
              key={index}
              className={classNames('c-project-timeline__item', {
                'is-current': isCurrent,
                'c-project-timeline__item--first': index === 0,
                'c-project-timeline__item--last': (items.length - 1) === index,
                'c-project-timeline__item--progress': !isTimelineComplete && (index + 1) <= currentItem,
                'c-project-timeline__item--complete': isTimelineComplete === true,
                'is-error': isError && (index + 1) <= currentItem,
              })}
            >
              <div className="c-project-timeline__dot" />
              <p className="c-project-timeline__label">{item}</p>
              {isCurrent && message && <p className="c-project-timeline__message" ref={messageElement}>{message}</p>}
            </div>
          );
        })}
      </div>
    </div>
  );
};
