import React from 'react';
import classNames from 'classnames';
import {formatMoney} from 'utils/format_money';
import {shippingCondition} from 'utils/shipping_condition';
import {PackageCard} from '../cards/package_card';

export const PurchaseOrderDetails = ({
  delivery,
  index,
}) => {
  const totalShipping = delivery[0]?.delivery_fee;
  const deliveryService = delivery[0]?.delivery_service;
  const currency = delivery[0]?.currency;
  const shippedState = delivery[0]?.state === 'shipped' || 'delivered';
  const shippedWithTracking = shippedState && delivery[0]?.tracking_url;

  const deliveryType = {
    delivery_service_pickup: 'Pickup order',
    delivery_service_self: 'Ships directly from factory',
    delivery_service_bulk: 'Ships directly from factory',
    delivery_service_france: 'Ships from France',
    delivery_service_japan: 'Ships from Japan warehouse',
    delivery_service_uk: 'Ships from UK warehouse',
    delivery_service_uk_priority: 'Ships from UK warehouse',
    delivery_service_us: 'Ships from US warehouse',
    delivery_service_us_priority: 'Ships from US warehouse',
  };

  return (
    <div className="c-order-progress__package">
      <div className="c-label--jazz">
        {`PACKAGE ${index + 1}`}
      </div>
      <div className="c-order-progress__date">
        <span className="c-order-progress__description">Estimated shipping date:&nbsp;</span>
        <span className="c-order-progress__info">{shippingCondition(delivery[0])}</span>
      </div>
      <div className="c-order-progress__shipping">
        <div className="c-order-progress__fee">
          <span className="c-order-progress__description">
            Shipping fee:
            &nbsp;
          </span>
          <span className="c-order-progress__info">{formatMoney({amount: totalShipping, currency})}</span>
        </div>
        <div className="c-order-progress__shipping-details">
          <span className="c-order-progress__info">
            {deliveryType[deliveryService]}
            &nbsp;
          </span>
          <a className={classNames('c-link--purchases', {'c-link--disabled': !shippedWithTracking})} href="#">TRACK ORDER</a>
        </div>
      </div>
      {delivery?.map((deliveryItem, index) => (
        <PackageCard orderItem={deliveryItem} key={index} />
      ))}
    </div>
  );
};
