import React from 'react';
import {RichText} from 'prismic-reactjs';
import {NewLabel} from 'shared/components/labels/new_label/new_label';

export const LongCard = ({
  image,
  index,
  heading,
  text,
  uid,
  category,
  rootPath,
}) => {
  const path = `${rootPath + uid}`;
  return (
    <a key={uid} href={path} target="_blank">
      <div className="c-curated-card-long">
        <div className="c-curated-card-long__image-container">
          <div className="c-curated-card-long__label-container">
            <NewLabel title={category} theme="transparent" />
          </div>
          <img src={image} alt={`stories ${index}`} />
        </div>
        <div className="c-curated-card-long__content">
          <h4 wovn-ignore="" className="c-curated-card-long__title">
            {RichText.asText(heading)}
          </h4>
          <p wovn-ignore="" className="c-curated-card-long__info">
            {RichText.asText(text)}
          </p>
        </div>
      </div>
    </a>
  );
};
