import {Record} from 'immutable';
import StoredFile from './stored_file';

const _Record = Record({
  id: null,
  uploaded_file: new StoredFile(),
  _destroy: false,
}, 'CopyrightFile');

export default class CopyrightFile extends _Record {
  static fromJSON(json) {
    if (json.uploaded_file) {
      json.uploaded_file = StoredFile.fromJSON(json.uploaded_file);
    }

    return new CopyrightFile(json);
  }
}
