import React from 'react';
import {RichText} from 'prismic-reactjs';

import StickerImagePath from 'images/common/sticker/fast.png';
import {SectionsContainer} from 'public/components/sections_container/sections_container';
import {ReactButton} from 'shared/components/buttons/react_button/react_button';

export const TopForArtistsSection = ({
  label,
  title,
  description,
  image,
  forArtistsButtonLabel,
  qratesStudioButtonLabel,
}) => {
  return (
    <section className="c-top-for-artists-section">
      <SectionsContainer>
        <div className="c-top-for-artists-section--grid">
          <div className="c-top-for-artists-section__left">
            <img src={StickerImagePath} className="c-sticker c-sticker--fast" />
            <div className="c-top-for-artists-section__image">
              <img src={image} title="artist_cover" />
            </div>
          </div>
          <div className="c-top-for-artists-section__right">
            <div wovn-ignore="" className="c-label--pages c-label--jazz">
              {label}
            </div>
            <h2 wovn-ignore="" className="c-top-for-artists-section__title">
              {RichText.asText(title)}
            </h2>
            <p className="c-top-for-artists-section__explanation">
              <span wovn-ignore="" className="f-adjust-ja">
                {RichText.asText(description)}
              </span>
            </p>
            <div className="c-button-grid__top-page u-hidden-tablet">
              <ReactButton
                wovn-ignore=""
                text={forArtistsButtonLabel}
                type="link"
                link="/for-artists"
                theme="primary-black"
                align="left"
                className="c-top-for-artists-section__button"
              />
              {/*<ReactButton
                wovn-ignore=""
                text={qratesStudioButtonLabel}
                type="link"
                link="/projects/start"
                theme="primary-yellow"
                align="left"
                className="c-top-for-artists-section__button"
              />*/}
            </div>
            <div className="c-button-grid__top-page u-hidden-desktop-only">
              <ReactButton
                wovn-ignore=""
                text={forArtistsButtonLabel}
                type="link"
                link="/for-artists"
                theme="primary-black"
                align="left"
                width="full"
              />
              {/*<ReactButton
                wovn-ignore=""
                text={qratesStudioButtonLabel}
                type="link"
                link="/projects/start"
                theme="primary-yellow"
                align="left"
                width="full"
              />*/}
            </div>
          </div>
        </div>
      </SectionsContainer>
    </section>
  );
};
