import {Record} from 'immutable';

const _Record = Record({
  number: 0,
  vinyl_type: 'vinyl_type_black',
  vinyl_weight: 'vinyl_weight_normal',
  vinyl_color_1: 2, // Yellow
  vinyl_color_2: 5, // Blue
  vinyl_color_3: 25, // Magenta
  label_type: 'label_type_color',
  special_type: 'special_type_splatter',
  display_angle: 0,
  cover_image_file_id: null,
  cover_image_file_url: '',
  quantity: 0,

}, 'VinylRecord');

export default class Vinyl extends _Record {}
