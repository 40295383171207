export const deliveryServices = {
  delivery_service_uk: {
    standartCode: 'delivery_service_uk',
    priorityCode: 'delivery_service_uk_priority',
    standartTitle: 'Economy shipping',
    priorityTitle: 'Priority shipping',
  },
  delivery_service_us: {
    standartCode: 'delivery_service_us_priority',
    standartTitle: 'Priority shipping',
  },
  delivery_service_japan: {
    standartCode: 'delivery_service_japan',
    standartTitle: 'Priority shipping',
  },
  delivery_service_france: {
    standartCode: 'delivery_service_france',
    standartTitle: 'Standard shipping',
  },
  delivery_service_self: {
    standartCode: 'delivery_service_self',
    standartTitle: 'Shipping by Artist',
  },
};

export const isPriorityShippingOnly = (deliveryServiceKey) => {
  const deliveryService = deliveryServices[deliveryServiceKey] || {};

  return deliveryService.hasOwnProperty('priorityCode')
    && deliveryService.hasOwnProperty('priorityTitle')
    && Object.keys(deliveryService).length === 2;
};

export const isPriorityShippingAvailable = (deliveryServiceKey) => {
  const deliveryService = deliveryServices[deliveryServiceKey] || {};

  return deliveryService.hasOwnProperty('priorityCode') && deliveryService.hasOwnProperty('priorityTitle');
};
