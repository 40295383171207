export const getFileType = (type) => {
  const {stored_file_types} = window.constants;

  return stored_file_types.find((fileType) => fileType.value === type);
};

export const filterArtworkByType = ({type, artworkFiles}) => {
  const storedFileType = getFileType(type);

  if (storedFileType) {
    return artworkFiles.find((file) => {
      return file.uploaded_file?.stored_file_type_id === storedFileType.id;
    });
  }
};

export const mockupTypes = ({project}) => {
  const caseType = project.getIn(['spec', 'case_type']);
  const isTypeEnabled = ({type}) => {
    const mockups = project.getIn(['product_order', 'available_mockups'])?.[type] || [];

    return mockups.length > 0;
  };

  return {
    vinyl: [
      {type: 'sleeve', label: 'SLEEVE', disabled: !isTypeEnabled({type: 'sleeve'})},
      {type: 'label', label: 'LABEL', unavailable: !isTypeEnabled({type: 'label'})},
      {type: 'texture', label: 'PICTURE VINYL', unavailable: !isTypeEnabled({type: 'texture'})},
    ],
    cassette: [
      {type: 'card', label: caseType === 'case_type_paper' ? 'O-CARD' : 'J-CARD', disabled: !isTypeEnabled({type: 'card'})},
      {type: 'label', label: 'LABEL', disabled: !isTypeEnabled({type: 'label'})},
      {type: 'outer_sleeve', label: 'OUTER SLEEVE', disabled: !isTypeEnabled({type: 'outer_sleeve'})},
    ],
  };
};
