import apiRoutes from './api_routes';

export default Object({
  api: apiRoutes,

  artistDashboardPath: '/dashboard',

  projectDashboardPath: (projectId) => `/dashboard/projects/${projectId}`,
  projectSettingsPath: (projectId) => `/dashboard/projects/${projectId}/settings`,
  projectSalesPath: (projectId) => `/dashboard/projects/${projectId}/payments`,
  projectPaymentsPath: (projectId) => `/dashboard/projects/${projectId}/payments/payments`,
  projectPromotePath: (projectId) => `/dashboard/projects/${projectId}/promote`,
  projectMessagesPath: (projectId) => `/dashboard/projects/${projectId}/messages`,

  settingsPath: (projectId) => `/dashboard/projects/${projectId}/settings`,
  productSettingsPath: (projectId) => `/dashboard/projects/${projectId}/settings/main`,
  crowdfundingSettingsPath: (projectId) => `/dashboard/projects/${projectId}/settings/crowdfunding`,
  salesSettingsPath: (projectId) => `/dashboard/projects/${projectId}/settings/payments`,
  quantitySettingsPath: (projectId) => `/dashboard/projects/${projectId}/settings/quantity`,
  pageSettingsPath: (projectId) => `/dashboard/projects/${projectId}/settings/page`,
  pageContentSettingsPath: (projectId) => `/dashboard/projects/${projectId}/settings/page/content`,
  distributionSettingsPath: (projectId) => `/dashboard/projects/${projectId}/settings/distribution`,
  sellingConditionSettingsPath: (projectId) => `/dashboard/projects/${projectId}/settings/selling_condition`,
  tracklistSettingsPath: (projectId) => `/dashboard/projects/${projectId}/settings/tracklist`,
  audioSettingsPath: (projectId) => `/dashboard/projects/${projectId}/settings/audio`,
  audioSettingsSamplesPath: (projectId) => `/dashboard/projects/${projectId}/settings/audio/samples`,
  audioSettingsVinylAudioPath: (projectId) => `/dashboard/projects/${projectId}/settings/audio/vinyl`,
  audioSettingsCassetteAudioPath: (projectId) => `/dashboard/projects/${projectId}/settings/audio/cassette`,
  audioSettingsDigitalAudioPath: (projectId) => `/dashboard/projects/${projectId}/settings/audio/digital`,
  audioSettingsTestProductionPath: (projectId) => `/dashboard/projects/${projectId}/settings/audio/test_production`,
  artworkSettingsPath: (projectId) => `/dashboard/projects/${projectId}/settings/artwork`,
  artworkSettingsMockupsPath: (projectId) => `/dashboard/projects/${projectId}/settings/artwork/mockups`,
  artworkSettingsVinylPath: (projectId) => `/dashboard/projects/${projectId}/settings/artwork/vinyl`,
  artworkSettingsCassettesPath: (projectId) => `/dashboard/projects/${projectId}/settings/artwork/cassettes`,
  copyrightSettingsPath: (projectId) => `/dashboard/projects/${projectId}/settings/copyright`,

  editSpecPath: (projectId) => `/dashboard/projects/${projectId}/settings/main/spec`,

  salesPath: (projectId) => `/dashboard/projects/${projectId}/payments`,
  salesSummaryPath: (projectId) => `/dashboard/projects/${projectId}/payments/summary`,
  salesOrdersPath: (projectId) => `/dashboard/projects/${projectId}/payments/orders`,
  salesStocksPath: (projectId) => `/dashboard/projects/${projectId}/payments/stocks`,
  salesWalletPath: (projectId) => `/dashboard/projects/${projectId}/payments/wallet`,
  salesSelfPurchasePath: (projectId) => `/dashboard/projects/${projectId}/payments/self`,
  costDetailsPath: (projectId) => `/dashboard/projects/${projectId}/payments/cost_details`,
  financialStatementPath: (projectId) => `/dashboard/projects/${projectId}/payments/financial_statement`,
  discountsPath: (projectId) => `/dashboard/projects/${projectId}/payments/discounts`,

  newMessagePath: (projectId) => `/dashboard/projects/${projectId}/messages/compose`,
  sentMessagesPath: (projectId) => `/dashboard/projects/${projectId}/messages/sent`,
  sentMessagePath: (projectId, messageId) => `/dashboard/projects/${projectId}/messages/sent/${messageId}`,
  draftMessagesPath: (projectId) => `/dashboard/projects/${projectId}/messages/drafts`,
  draftMessagePath: (projectId, messageId) => `/dashboard/projects/${projectId}/messages/drafts/${messageId}`,

  previewPagePath: (projectId) => `/projects/${projectId}/preview`,
  articlesPath: (slug) => `/articles/${slug}`,

  connectPaypalPath: (projectId) => `/dashboard/artists/connect_paypal_return?project_id=${projectId}`,
  connectStripePath: (projectId) => `/dashboard/artists/connect_stripe?project_id=${projectId}`,
});
