import React, {useState} from 'react';

import {ReactModal} from 'shared/components/modals/react_modal/react_modal';
import {BillingInfoForm} from './components/billing_info_form/billing_info_form';

export const BillingInfoSection = ({user}) => {
  const [modal, setModal] = useState(null);
  const [userJSON, setUserJSON] = useState(user);
  const country = window.constants.deliverableCountries?.find((c) => c.code == userJSON.country)?.name;

  return (
    <>
      <h2 className="c-dashboard__title p-account--settings__section-title">Billing information</h2>
      <div className="p-form-group--table">
        <div className="p-form-group--table__row">
          <div className="p-form-group--table__label">Full Name *</div>
          <div className="c-input--static" wovn-ignore="">{[userJSON.first_name, userJSON.last_name].join(' ')}</div>
        </div>
        <div className="p-form-group--table__row">
          <div className="p-form-group--table__label">Building *</div>
          <div className="c-input--static" wovn-ignore="">{userJSON.building}</div>
        </div>
        <div className="p-form-group--table__row">
          <div className="p-form-group--table__label">Street *</div>
          <div className="c-input--static" wovn-ignore="">{userJSON.street}</div>
        </div>
        <div className="p-form-group--table__row">
          <div className="p-form-group--table__label">City *</div>
          <div className="c-input--static" wovn-ignore="">{userJSON.city}</div>
        </div>
        <div className="p-form-group--table__row">
          <div className="p-form-group--table__label">Prefecture / State *</div>
          <div className="c-input--static" wovn-ignore="">{userJSON.prefecture}</div>
        </div>
        <div className="p-form-group--table__row">
          <div className="p-form-group--table__label">Country *</div>
          <div className="c-input--static" wovn-ignore="">{country}</div>
        </div>
        <div className="p-form-group--table__row">
          <div className="p-form-group--table__label">Postal code *</div>
          <div className="c-input--static" wovn-ignore="">{userJSON.postal_code}</div>
        </div>
        <div className="p-form-group--table__row">
          <div className="p-form-group--table__label">Phone number *</div>
          <div className="c-input--static" wovn-ignore="">{userJSON.phone_number}</div>
        </div>
      </div>
      <div className="c-button-grid--right c-button-grid--right--no-padding">
        <button
          type="button"
          onClick={() => setModal('billing_info_form')}
          className="c-button c-button--black c-button--large"
        >
          EDIT INFORMATION
        </button>
      </div>
      <ReactModal
        isOpen={modal === 'billing_info_form'}
        appElement={document.body}
        onRequestClose={() => setModal(null)}
        onClose={() => setModal(null)}
      >
        <BillingInfoForm
          onClose={() => setModal(null)}
          user={userJSON}
          setUser={setUserJSON}
        />
      </ReactModal>
    </>
  );
};
