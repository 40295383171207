import React from 'react';
import {Carousel} from '../components/curated_carousel/curated_carousel';
import {CuratedFeaturedFallback} from '../../components/curated_featured/curated_featured_fallback';

export const QratesRecommendsSection = ({
  carouselHeading,
  carouselButtonLabel,
  recommendations,
  isError,
  isLoading,
}) => {
  const rootPath = '/stories/';

  if (isError) return <CuratedFeaturedFallback />;
  if (isLoading) return <div />;
  return (
    <Carousel
      curated
      rootPath={rootPath}
      articles={recommendations}
      title={carouselHeading}
      buttonLabel={carouselButtonLabel}
      buttonLink="/stories?category=Qrates+Recommends"
      cardType="recommends"
    />
  );
};
