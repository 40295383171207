import React, {useState} from 'react';
import ApiHelper from 'utils/api_helper';
import {Notification} from 'shared/components/notification';
import {handleError, clearValidationErrors} from 'utils/validation';

export const EmailModal = ({onClose}) => {
  const path = '/account/update_email';
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [notification, setNotification] = useState(null);
  const [section, setSection] = useState('form');

  const onSubmit = () => {
    setButtonDisabled(true);
    clearValidationErrors(setNotification);
    const formParams = {user: {unconfirmed_email: email, password}};
    ApiHelper.patch(path, formParams).then((response) => {
      if (response.error) {
        handleError(response.error, setNotification, 'tableField');
        setButtonDisabled(false);

        return;
      }

      setEmail(null);
      setPassword(null);
      setButtonDisabled(false);
      setNotification(null);
      setSection('form_sent');
    });
  };

  const tryAgain = (e) => {
    e.preventDefault();
    setSection('form');
  };

  const confirmationSentSection = (
    <>
      <div className="c-dashboard__heading">We've sent a verification link to change your email address.</div>
      <span className="c-close--modal" onClick={onClose}>
        <a>&times;</a>
      </span>
      <p className="c-login__explanation">
        Didn't receive anything? It might be in your spam folder, or you may have made a typo.
        Try again below, or get in touch with our customer support team and we'll be happy to help.
      </p>

      <a className="c-button c-button--black c-button--full" onClick={tryAgain}>TRY AGAIN</a>
      <a href="/contact" className="c-link c-login__contact-link">CONTACT SUPPORT</a>
    </>
  );

  const formSection = (
    <>
      <div className="c-dashboard__heading">Change email address</div>
      <span className="c-close--modal" onClick={onClose}>
        <a>&times;</a>
      </span>
      <p className="c-login__explanation">Type in your new e-mail address below.</p>
      {notification && (
        <div className="c-login__error">
          <Notification type="error">
            {notification.message}
          </Notification>
        </div>
      )}
      <div className="p-form-group--table">
        <div className="p-form-group--table__row">
          <div className="p-form-group--table__label">
            <label htmlFor="unconfirmed_email">New Email</label>
          </div>
          <input
            type="text"
            name="unconfirmed_email"
            placeholder="New Email"
            className="c-input"
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="p-form-group--table__row">
          <div className="p-form-group--table__label">
            <label htmlFor="password">Password</label>
          </div>
          <input
            type="password"
            name="password"
            placeholder="Password"
            className="c-input"
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
      </div>

      <button
        type="button"
        className="c-button c-button--black c-button--full"
        onClick={onSubmit}
        disabled={buttonDisabled}
      >
        SEND
      </button>
    </>
  );

  return (
    <div className="p-modal__content">
      <div className="p-modal__inner">
        <div className="p-modal__section">
          {section === 'form' && formSection}
          {section === 'form_sent' && confirmationSentSection}
        </div>
      </div>
    </div>
  );
};
