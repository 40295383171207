import React, {useState} from 'react';
import algoliasearch from 'algoliasearch/lite';
import {InstantSearch, Configure} from 'react-instantsearch-dom';
import {useQueryParam} from 'use-query-params';
import {NumberParam, StringParam} from 'serialize-query-params';
import StoriesNav from './components/StoriesNav/StoriesNav';
import {StoriesSort, SORT_OLDEST} from './components/StoriesSort/StoriesSort';
import {HitsList} from './components/HitsList/HitsList';
import {useLanguageChange} from 'utils/use_language_change';
import ScreenWrapper from '../../shared/components/ScreenWrapper/ScreenWrapper';
import {CategoryFilterTabs} from '../../category_filters_tabs';

export const StoriesScreen = ({algolia_id = '', algolia_search_api_key = '', raw_results = {}}) => {
  const searchClient = algoliasearch(algolia_id, algolia_search_api_key);
  const [locale, setLocale] = useState(window.constants.prismic_locales[window.constants.locale || 'en']);

  useLanguageChange((event) => {
    setLocale(window.constants.prismic_locales[event.detail.locale || 'en']);
  });

  return (
    <InstantSearch
      indexName="Prismic::Story"
      searchClient={searchClient}
      resultsState={{rawResults: [raw_results], state: {query: ''}, metadata: []}}
    >
      <Configure hitsPerPage={10} />
      <CategoryFilterTabs className="c-nav-discover__filters" />
      <ScreenWrapper>
        <div>
          <StoriesSort />
          <HitsList />
        </div>
      </ScreenWrapper>
    </InstantSearch>
  );
};
