import React, {useState, useEffect} from 'react';
import classNames from 'classnames';

import {SoundCloudHelper} from 'utils/soundcloud_helper';

import {PlayerProgressButton} from './player_progress_button/player_progress_button';

export const ProjectPlaylistPlayer = ({tracks = {}}) => {
  const player = document.getElementById('audioPlayer');
  const soundCloudHelper = new SoundCloudHelper(window.constants.soundcloud_token);

  const [currentTrack, setCurrentTrack] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (player) {
      player.addEventListener('play', setIsPlaying.bind(this, true));
      player.addEventListener('pause', setIsPlaying.bind(this, false));
      player.addEventListener('playerTrackIdChange', () => {
        player.getAttribute('data-trackId') && setCurrentTrack(parseInt(player.getAttribute('data-trackId')));
        setProgress(0);
      });
      player.addEventListener('timeupdate', () => {
        const currentTime = player.currentTime;
        const duration = player.duration;

        setProgress((currentTime / duration) * 100);
      });
    }
  }, []);

  const setTrackId = (trackId) => {
    player.setAttribute('data-trackId', trackId);
    const event = new Event('playerTrackIdChange');
    player.dispatchEvent(event);
  };

  const handlePlayTrack = ({
    id,
    audio_url,
    sound_cloud_track_id,
  }) => {
    if (isPlaying) {
      player.pause();
      setIsPlaying(false);
    }

    if (!isPlaying && currentTrack && currentTrack === id) {
      player.play();
      setIsPlaying(true);
    }

    const handlePlayback = (url) => {
      player.setAttribute('src', url);
      player.play();
      setIsPlaying(true);
      setCurrentTrack(id);
      setTrackId(id);
    };

    if (!currentTrack || currentTrack !== id) {
      if (audio_url) {
        handlePlayback(audio_url);
      } else if (sound_cloud_track_id) {
        soundCloudHelper
          .getStreamUrlsByTrackId(sound_cloud_track_id)
          .then((url) => handlePlayback(url));
      }
    }
  };

  const isNowPlaying = (trackID) => {
    return currentTrack && currentTrack === trackID && isPlaying;
  };

  const renderTrackList = (trackItems) => {
    const [vinylTracks, setVinylTracks] = useState(trackItems.map((track) => ({...track, available: false})));

    const updateVinylTrack = ({track, params}) => {
      setVinylTracks((state) => {
        return state.map((vinylTrack) => {
          let newTrack = vinylTrack;
          if (vinylTrack.id === track.id) {
            newTrack = {...newTrack, ...params};
          }

          return newTrack;
        });
      });
    };

    useEffect(() => {
      vinylTracks.forEach((track) => {
        if (track.enable_sample_play && track.sound_cloud_track_id) {
          soundCloudHelper.getStreamUrlsByTrackId(track.sound_cloud_track_id).then((url) => {
            updateVinylTrack({track, params: {audio_url: url, available: true}});
          }).catch((error) => {
            updateVinylTrack({track, params: {available: false}});
          });
        } else if (track.enable_sample_play) {
          updateVinylTrack({track, params: {available: true}});
        }
      });
    }, []);

    return (
      <>
        {vinylTracks.map((vinylTrack, index) => (
          <div
            className={classNames(
              'c-tracks-player__tracks',
              {'c-tracks-player__tracks--disabled': !vinylTrack.available},
            )}
            key={index}
            data-track-id={vinylTrack.id}
            data-audio-url={vinylTrack.audio_url}
            data-disabled={!vinylTrack.available}
          >
            <div>
              {vinylTrack.available && (
                <PlayerProgressButton
                  isPlaying={currentTrack == vinylTrack.id ? isPlaying : isNowPlaying(vinylTrack.id)}
                  progressPercent={currentTrack == vinylTrack.id && progress}
                  onChange={() => handlePlayTrack(vinylTrack)}
                />
              )}
            </div>
            <div className="c-tracks-player__contents">
              <div className="c-tracks-player__title">
                <span>{vinylTrack.title}</span>
              </div>
              <div className="c-tracks-player__artist">
                <span>{vinylTrack.artist_name}</span>
              </div>
            </div>
            <div className="c-tracks-player__length">
              <span>{vinylTrack.duration_str}</span>
            </div>
          </div>
        ))}
      </>
    );
  };

  const renderLabel = (side) => {
    const sides = {
      a_side: 'A',
      b_side: 'B',
      c_side: 'C',
      d_side: 'D',
      bonus: 'Bonus',
    };

    return (
      <h3>
        {sides[side]}
        <span>&nbsp;Side</span>
      </h3>
    );
  };

  const renderTracks = (side) => {
    const trackItems = tracks && tracks[side];
    if (trackItems && trackItems.length > 0) {
      return (
        <div className="c-tracks-player__side-container">
          <div className="c-tracks-player__side-container__heading">
            {renderLabel(side)}
          </div>
          {renderTrackList(trackItems)}
        </div>
      );
    }
  };

  return (
    <div id="tracks_section">
      <div className="c-tracks-player p-project__tracks">
        <div className="c-tracks-player__heading">
          <h2>Tracklist</h2>
        </div>
        <div className="c-tracks-player__inner" wovn-ignore="">
          {renderTracks('a_side')}
          {renderTracks('b_side')}
          {renderTracks('c_side')}
          {renderTracks('d_side')}
          {renderTracks('bonus')}
        </div>
      </div>
    </div>
  );
};
