import React, {useState} from 'react';
import Select from 'react-select';
import {set} from 'lodash';
import ApiHelper from 'utils/api_helper';
import {Notification} from 'shared/components/notification';
import {handleError} from 'utils/validation';

export const BillingDetailsModal = ({order, setOrder, onClose}) => {
  const countries = window.constants.deliverableCountries.map((country) => ({label: country.name, value: country.code}));
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [notification, setNotification] = useState(null);
  const [formParams, setFormParams] = useState({
    order: {
      billing_first_name: order.billing_first_name,
      billing_last_name: order.billing_last_name,
      billing_company: order.billing_company,
      billing_building: order.billing_building,
      billing_street: order.billing_street,
      billing_city: order.billing_city,
      billing_prefecture: order.billing_prefecture,
      billing_postal_code: order.billing_postal_code,
      billing_country: order.billing_country,
      billing_phone_number: order.billing_phone_number,
    },
  });
  const currentCountry = countries.find((option) => option.value === formParams.order.billing_country);

  const onCountryChange = (option) => {
    setValue({target: {name: 'billing_country', value: option.value}});
  };

  const setValue = (event) => {
    setFormParams(set({...formParams}, `order.${event.target.name}`, event.target.value));
  };

  const onSubmit = () => {
    setButtonDisabled(true);
    ApiHelper.patch(`/orders/${order.id}`, formParams).then((response) => {
      if (response.error) {
        handleError(response.error, setNotification);
        setButtonDisabled(false);

        return;
      }

      setOrder(response.response);
      setButtonDisabled(false);
      setNotification(null);
      onClose();
    });
  };

  return (
    <>
      <div className="c-dashboard__heading">Billing address</div>
      <span className="c-close--modal" onClick={onClose}>
        <a>&times;</a>
      </span>
      {notification && (
        <div className="c-login__error">
          <Notification type="error">
            {notification.message}
          </Notification>
        </div>
      )}

      <div className="p-form-group--half">
        <div className="p-form-group__value">
          <div className="p-form-group--label">First name*</div>
          <input
            className="c-input"
            type="text"
            name="billing_first_name"
            value={formParams.order.billing_first_name || ''}
            onChange={setValue}
          />
        </div>
        <div className="p-form-group__value">
          <div className="p-form-group--label">
            Last name*
          </div>
          <input
            className="c-input"
            type="text"
            name="billing_last_name"
            value={formParams.order.billing_last_name || ''}
            onChange={setValue}
          />
        </div>
      </div>
      <div className="p-form-group--label">Company</div>
      <div className="p-form-group">
        <div className="p-form-group__value">
          <input
            className="c-input"
            type="text"
            name="billing_company"
            placeholder="Optional"
            value={formParams.order.billing_company || ''}
            onChange={setValue}
          />
        </div>
      </div>
      <div className="p-form-group--address">
        <div className="p-form-group--label">Your address*</div>
        <div className="p-form-group">
          <div className="p-form-group__value">
            <input
              className="c-input"
              type="text"
              name="billing_building"
              placeholder="Building (Optional)"
              value={formParams.order.billing_building || ''}
              onChange={setValue}
            />
          </div>
        </div>
        <div className="p-form-group">
          <div className="p-form-group__value">
            <input
              className="c-input"
              type="text"
              name="billing_street"
              placeholder="Street address"
              value={formParams.order.billing_street || ''}
              onChange={setValue}
            />
          </div>
        </div>
        <div className="p-form-group--half">
          <div className="p-form-group__value">
            <input
              className="c-input"
              type="city"
              name="billing_city"
              placeholder="City"
              value={formParams.order.billing_city || ''}
              onChange={setValue}
            />
          </div>
          <div className="p-form-group__value">
            <input
              className="c-input"
              type="state"
              name="billing_prefecture"
              placeholder="Prefecture / State (Optional)"
              value={formParams.order.billing_prefecture || ''}
              onChange={setValue}
            />
          </div>
        </div>
        <div className="p-form-group--half">
          <div className="p-form-group__value">
            <input
              className="c-input"
              type="zip"
              name="billing_postal_code"
              placeholder="Postal code"
              value={formParams.order.billing_postal_code || ''}
              onChange={setValue}
            />
          </div>
          <div className="p-form-group__value c-react-select__wrap">
            <input type="hidden" name="order.billing_country" />
            <Select
              className="c-react-select"
              classNamePrefix="c-react-select"
              name="billing_country"
              placeholder="Country..."
              value={currentCountry}
              options={countries}
              onChange={onCountryChange}
            />
          </div>
        </div>
      </div>
      <div className="p-form-group--label">Phone number*</div>
      <div className="p-form-group">
        <div className="p-form-group__value">
          <input
            className="c-input"
            type="text"
            name="billing_phone_number"
            value={formParams.order.billing_phone_number || ''}
            onChange={setValue}
          />
        </div>
      </div>

      <button
        type="button"
        className="c-button c-button--black c-button--full"
        onClick={onSubmit}
        disabled={buttonDisabled}
      >
        SAVE CHANGES
      </button>
    </>
  );
};
