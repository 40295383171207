async function convertToPrismicFormat(response, page, resultsPerPage) {
  return {
    results_size: response.length,
    results: response.slice((page - 1) * resultsPerPage, page * resultsPerPage).map(object => {
      return {
        id: object.objectID,
        title: object.title,
        description: `${object.title} by ${object.artist_name}`,
        image_url: object.sleeve_thumbnail_image_url,
        last_update: object.updated_at_timestamp,
        blob: object,
      };
    }),
  };
}

exports.handler = async (event, context) => {
  // For the default version
  // eslint-disable-next-line global-require
  const algoliasearch = require('algoliasearch');
  const client = algoliasearch('8ZU5MN9NLF', '9c2b59c80abe8d843e6ac53ea0311526');
  const index = client.initIndex('Project');
  // const d = new Date();
  let hits = [];
  await index.browseObjects({
    // filters: `updated_at > ${Math.floor(d.setDate(d.getDate() - 1) / 1000)}`,
    batch: batch => {
      hits = hits.concat(batch);
    },
  });
  return {
    statusCode: 200,
    body: JSON.stringify(await convertToPrismicFormat(hits, event.queryStringParameters.page, 50)),
  };
};
