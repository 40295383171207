// @flow
import React from 'react';
import styled from 'styled-components';
import {RichText} from 'prismic-reactjs';
import {cssQuoteHeading, cssSmallPlusText} from '../../styles/typography';
import {cssFontFKRoman} from '../../styles/fonts';
import type {StorySliceFeaturedQuote} from '../../cms/story';
import {
  getSliceFeaturedQuoteCaption,
  getSliceFeaturedQuoteText,
} from '../../cms/story';
import {breakpointTabletOnly} from '../../styles/responsive';

const StyledContainer = styled.div`
  padding: 0px 40px;
  margin-bottom: 100px;
  width: 100%;

  ${breakpointTabletOnly} {
    padding: 0px 15px;
    margin-bottom: 40px;
  }

  h3 {
    ${cssQuoteHeading};
    ${cssFontFKRoman};

    &::before {
      content: '“';
    }
    &::after {
      content: '"';
    }
  }

  p {
    ${cssSmallPlusText};
    text-transform: uppercase;
    margin-top: 15px;

    ${breakpointTabletOnly} {
      margin-top: 10px;
    }
  }
`;

type Props = {
  slice: StorySliceFeaturedQuote,
  noMargin: boolean | undefined,
};

export const FeaturedQuote = ({ slice }: Props) => (
  <StyledContainer>
    <h3 wovn-ignore="">{RichText.asText(getSliceFeaturedQuoteText(slice))}</h3>
    <p wovn-ignore="">{RichText.asText(getSliceFeaturedQuoteCaption(slice))}</p>
  </StyledContainer>
);
