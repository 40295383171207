import React from 'react';
import fallbackImage from 'images/common/sticker/press_only.png';

export const CuratedFeaturedFallback = () => (
  <div className="c-curated-featured">
    <div className="c-curated-featured__container c-curated-featured__container--large">
      <div className="c-curated-featured__error-contents">
        <img className="c-curated-featured__error-image" src={fallbackImage} alt="Error" />
        <h2 className="c-curated-featured__error-message">Sorry, an error has occurred.</h2>
      </div>
    </div>
  </div>
);
