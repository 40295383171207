import React, {useState, useEffect} from 'react';
import {Provider} from 'react-redux';
import {
  get,
  map,
  uniq,
  flattenDeep,
  isEqual,
} from 'lodash';
import classNames from 'classnames';

import ApiHelper from 'utils/api_helper';
import configureStore from 'project_start/modules/store';

import {Preloader} from 'src/shared/components/preloader/preloader';
import {PaymentSummurySection} from './components/payment_summury_section/payment_summury_section';
import {PaymentDetailsFooter} from './components/payment_details_footer/payment_details_footer';
import {YourDetailsSection} from './components/your_details_section/your_details_section';
import {CreateAccountSection} from './components/create_account_section/create_account_section';
import {OrderCardsSection} from './components/order_cards_section/order_cards_section';
import {ShippingInformationSection} from './components/shipping_information_section/shipping_information_section';
import {BillingInformationSection} from './components/billing_information_section/billing_information_section';
import {CheckoutSupportLinksSection} from './components/checkout_support_links_section/checkout_support_links_section';
import {PaymentInfoSection} from './components/payment_info_section/payment_info_section';

const store = configureStore();

export const PaymentDetailsScreen = ({currentUser}) => {
  const [usStates, setUsStates] = useState([]);
  const [jpStates, setJpStates] = useState([]);
  const [isReadOnly, setReadOnly] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [notification, setNotification] = useState();
  const [orders, setOrders] = useState([]);
  const [cartItems, setCartItems] = useState([]);
  const sellCartItems = cartItems.filter((cartItem) => cartItem.is_sell);
  const fundingCartItems = cartItems.filter((cartItem) => cartItem.is_funding);
  const paymentCurrency = orders[0]?.payment_currency;
  const [isLoading, setIsLoading] = useState(false);
  const defaultFormParams = () => {
    const orders = [];
    if (fundingCartItems.length > 0) {
      orders.push(...fundingCartItems.map((i) => [i]));
    }
    if (sellCartItems.length > 0) {
      orders.push(sellCartItems);
    }

    return (
      {
        orders: orders.map((items) => ({
          delivery_strategy: 'delivery_strategy_fastest',
          delivery_service_type: 'delivery_service_type_standard',
          payment_currency: items[0]?.payment_currency,
          payment_method: get(currentUser, 'distributor.payment_method') === 'invoice' ? 'invoice' : 'stripe',
          api_version: items[0].is_funding ? 'nvp' : null,
          order_type: items[0].is_funding ? 'funding' : 'sell_catalog',
          billing_first_name: currentUser?.first_name || '',
          billing_last_name: currentUser?.last_name || '',
          billing_country: currentUser?.country || '',
          billing_postal_code: currentUser?.postal_code || '',
          billing_building: currentUser?.building || '',
          billing_street: currentUser?.street || '',
          billing_city: currentUser?.city || '',
          billing_prefecture: currentUser?.prefecture || '',
          billing_phone_number: currentUser?.phone_number || '',
          user_id: currentUser?.id,
          terms: false,
          invoice_id: items[0].id,
          order_items: items.map((item) => ({
            project_id: item.project_id,
            selling_condition_id: item.selling_condition_id,
            discount_id: item.discount_id,
            quantity: item.quantity,
            currency: item.currency,
            payment_currency: item.payment_currency,
            named_price: item.named_price,
            delivery_service: item.delivery_service,
            delivery_first_name: currentUser?.delivery_first_name || '',
            delivery_last_name: currentUser?.delivery_last_name || '',
            delivery_country: currentUser?.delivery_country || '',
            delivery_postal_code: currentUser?.delivery_postal_code || '',
            delivery_building: currentUser?.delivery_building || '',
            delivery_street: currentUser?.delivery_street || '',
            delivery_city: currentUser?.delivery_city || '',
            delivery_prefecture: currentUser?.delivery_prefecture || '',
            delivery_phone_number: currentUser?.delivery_phone_number || '',
          })),
        })),
      }
    );
  };
  const [formParams, setFormParams] = useState(defaultFormParams());
  const deliveryServices = uniq(map(flattenDeep(map(formParams?.orders, 'order_items')), 'delivery_service'));

  const calculateOrder = ({params}) => {
    setIsLoading(true);
    ApiHelper.post('/api/v1/checkout/calculate', params).then((res) => {
      setOrders(res.response);
      setIsLoading(false);
    });
  };

  const onCurrencyChange = (option) => {
    const newFormParams = {...formParams};
    newFormParams.orders.forEach((order) => {
      order.payment_currency = option.value;
    });
    setFormParams(newFormParams);
    calculateOrder({params: newFormParams});
  };

  useEffect(() => {
    let newFormParams = {...formParams};
    if (!get(newFormParams, 'orders[0].order_items')?.length) {
      newFormParams = defaultFormParams();
    }
    newFormParams.orders.forEach((order) => {
      order.order_items = order.order_items.filter((orderItem) => {
        return cartItems.find((i) => i.project_id === orderItem.project_id);
      });
    });

    if (get(newFormParams, 'orders[0].order_items')?.length) {
      newFormParams.orders.forEach((order) => {
        order.order_items.forEach((orderItem) => {
          const cartItem = cartItems.find((i) => i.project_id === orderItem.project_id);
          if (cartItem) {
            orderItem.discount_id = cartItem.discount_id;
            orderItem.quantity = cartItem.quantity;
            orderItem.currency = cartItem.currency;
            orderItem.payment_currency = cartItem.payment_currency;
            orderItem.named_price = cartItem.named_price;
          }
        });
      });
      setFormParams(newFormParams);
      calculateOrder({params: newFormParams});
    }
  }, [cartItems]);

  useEffect(() => {
    const lang = isEqual(deliveryServices, ['delivery_service_japan']) ? 'ja' : 'en';
    ApiHelper.get(`/api/v1/helpers/countries_states?lang=${lang}`).then((res) => {
      setUsStates(res.response.us);
      setJpStates(res.response.jp);
    });
  }, [deliveryServices?.toString()]);

  useEffect(() => {
    if (get(formParams, 'orders[0].payment_method') === 'invoice') {
      setSubmitDisabled(false);
    }
  }, [get(formParams, 'orders[0].payment_method')]);

  return (
    <Provider store={store}>
      <div className="c-payment-detais-screen">
        <div className="c-payment-detais-screen__container">
          <div className="c-payment-detais-screen__left-side">
            <YourDetailsSection
              notification={notification}
              currentUser={currentUser}
              cartItems={cartItems}
            />
            <ShippingInformationSection
              currentUser={currentUser}
              formParams={formParams}
              setFormParams={setFormParams}
              calculateOrder={calculateOrder}
              isReadOnly={isReadOnly}
              usStates={usStates}
              jpStates={jpStates}
              isLoading={isLoading}
            />
            <BillingInformationSection
              currentUser={currentUser}
              formParams={formParams}
              setFormParams={setFormParams}
              calculateOrder={calculateOrder}
              isReadOnly={isReadOnly}
              usStates={usStates}
              jpStates={jpStates}
            />
            <PaymentInfoSection
              formParams={formParams}
              setFormParams={setFormParams}
              setSubmitDisabled={setSubmitDisabled}
            />
            {!currentUser && (
              <CreateAccountSection
                formParams={formParams}
                setFormParams={setFormParams}
                isReadOnly={isReadOnly}
              />
            )}
            <CheckoutSupportLinksSection />
            <PaymentSummurySection
              className="c-payment-detais-screen__mobile-payment-summury-section"
              formParams={formParams}
              setFormParams={setFormParams}
              calculateOrder={calculateOrder}
              cartItems={cartItems}
              orders={orders}
              isMobileView
            />
          </div>
          <div className="c-payment-detais-screen__right-side">
            <div className={classNames('c-payment-detais-screen__right-side-container', {
              'is-loading': isLoading,
            })}
            >
              {isLoading && <Preloader className="c-payment-detais-screen__right-side-preloader" coverBlock />}
              <OrderCardsSection
                currency={paymentCurrency}
                onCurrencyChange={onCurrencyChange}
                cartItems={cartItems}
                orders={orders}
                setCartItems={setCartItems}
                setNotification={setNotification}
                setIsLoading={setIsLoading}
              />
              <PaymentSummurySection
                className="c-payment-detais-screen__payment-summury-section"
                currency={paymentCurrency}
                cartItems={cartItems}
                formParams={formParams}
                setFormParams={setFormParams}
                calculateOrder={calculateOrder}
              />
            </div>
          </div>
        </div>
        <PaymentDetailsFooter
          orders={orders}
          formParams={formParams}
          setFormParams={setFormParams}
          setNotification={setNotification}
          submitDisabled={submitDisabled || isLoading}
          setSubmitDisabled={setSubmitDisabled}
          setReadOnly={setReadOnly}
        />
      </div>
    </Provider>
  );
};
