import React from 'react';
import {GroupSection} from '../../group_section/group_section';
import {ScreenContainer} from '../../screen_container/screen_container';

export const TagsScreen = ({navigationList}) => {
  const tagsList = [
    {
      name: 'Light',
      items: [
        {name: 'c-tag'},
        {name: 'c-tag--project'},
        {name: 'c-tag--genre'},
        {name: 'c-tag--your-project'},
        {name: 'c-tag--purchase'},
      ],
    },
    {
      name: 'Dark',
      items: [
        {name: 'c-tag c-tag--black'},
        {name: 'c-tag--project c-tag--black'},
        {name: 'c-tag--genre c-tag--black'},
        {name: 'c-tag--your-project c-tag--black'},
        {name: 'c-tag--purchase c-tag--black'},
      ],
    },
  ];
  return (
    <ScreenContainer title="Tags">
      {tagsList && tagsList.map((group, index) => {
        return (
          <GroupSection title={group.name} type="inline" key={index}>
            {group.items.map((item, index) => {
              const itemToCopy = `.c-tag .${item.name}`;
              return (
                <div
                  className={item.name}
                  key={index}
                  onClick={() => { navigator.clipboard.writeText(itemToCopy); }}
                >
                  <span>1</span>
                  Tag
                </div>
              );
            })}
          </GroupSection>
        );
      })}
    </ScreenContainer>
  );
};
