import React, {useState, useEffect} from 'react';
import ApiHelper from 'utils/api_helper';
import {formatMoney} from 'utils/format_money';
import {purchaseCardLabel} from 'utils/purchase_card_label';
import {shippingCondition} from 'utils/shipping_condition';

export const PurchaseOrderCard = ({orderItem}) => {
  const {
    project_id,
    quantity,
    project_title,
    artist_name,
    total_amount,
    currency,
    thumbnail_image_url,
    state,
  } = orderItem;
  const [projectDetails, setProjectDetails] = useState({});
  const productionState = projectDetails?.product_order?.state;
  const label = projectDetails && purchaseCardLabel({productionState, projectDetails, state});
  const projectLink = projectDetails?.url;
  const fundingType = projectDetails?.selling_condition?.selling_type === 'funding';
  const fundingDaysLeft = fundingType && projectDetails?.selling_condition?.left_days;
  const baseFundingQuantity = fundingType && projectDetails?.selling_condition?.base_quantity;
  const orderedFundingQuantity = fundingType && projectDetails?.selling_condition?.ordered_quantity;

  const dynamicUnitLabel = quantity > 1 ? 'units' : 'unit';
  const getDetails = () => {
    const searchParams = new URLSearchParams();
    searchParams.append(
      'except_attributes',
      [
        'project_card_image_url',
        'rendered_image_url',
        'sleeve_thumbnail_image_url',
        'vinyl_thumbnail_image_url',
        'vinyl2_thumbnail_image_url',
        'cassette_thumbnail_image_url',
        'case_thumbnail_image_url',
        'item',
      ],
    );
    ApiHelper.get(`/api/v1/projects/public/${project_id}?${searchParams}`).then((res) => {
      setProjectDetails(res.response);
    });
  };

  useEffect(getDetails, [orderItem]);

  return (
    <a className="c-purchases-items-list" target="_blank" href={projectLink}>
      <div className="c-purchases-items-list__image">
        <img src={thumbnail_image_url} />
      </div>
      <div className="c-purchases-items-list__info">
        <div className="c-purchases-items-list__title" wovn-ignore="">
          <span>
            {project_title}
          </span>
        </div>
        <div className="c-purchases-items-list__name" wovn-ignore="">{artist_name}</div>
        <div className="c-purchases-items-list__date">{shippingCondition(orderItem)}</div>
      </div>
      <div className="c-purchases-items-list__price">
        <div className="c-purchases-items-list__unit">
          {quantity}
          &nbsp;
          {dynamicUnitLabel}
          <span>{formatMoney({amount: total_amount, currency})}</span>
        </div>
        {fundingType && (
          <>
            <span className="c-purchases-items-list__unit">{`${orderedFundingQuantity}/${baseFundingQuantity}`}</span>
            <span className="c-purchases-items-list__days-left">{`${fundingDaysLeft} DAYS LEFT`}</span>
          </>
        )}
        <span className="c-label--purchases">{label ?? <div className="c-dots-flashing" />}</span>
      </div>
    </a>
  );
};
