import React from 'react';
import {RichText} from 'prismic-reactjs';

export const ForArtistsFormatsSection = ({formatsTitle, formats}) => {
  return (
    <div className="c-for-artists-formats-section">
      <div wovn-ignore="" className="c-for-artists-formats-section__section-title">
        {RichText.asText(formatsTitle)}
      </div>
      <div className="c-for-artists-formats-section__grid">
        <div className="c-for-artists-formats-section__format c-for-artists-formats-section__format--vinyl">
          <div className="c-for-artists-formats-section__content-container">
            <h3 className="c-for-artists-formats-section__format-title">
              <span wovn-ignore="" className="f-adjust-ja">
                {RichText.asText(formats[0]?.format_title)}
              </span>
            </h3>
            <p className="c-for-artists-formats-section__format-explanation">
              <span wovn-ignore="" className="f-adjust-ja">
                {RichText.asText(formats[0]?.format_text)}
              </span>
            </p>
            <div className="c-for-artists-formats-section__image-container">
              <span>
                <img className="c-for-artists-formats-section__image" src={formats[0].format_image.url} alt="Vinyl" />
              </span>
            </div>
          </div>
        </div>
        <div className="c-for-artists-formats-section__format c-for-artists-formats-section__format--cassette">
          <div className="c-for-artists-formats-section__content-container">
            <h3 className="c-for-artists-formats-section__format-title">
              <span wovn-ignore="" className="f-adjust-ja">
                {RichText.asText(formats[1]?.format_title)}
              </span>
            </h3>
            <p className="c-for-artists-formats-section__format-explanation">
              <span wovn-ignore="" className="f-adjust-ja">
                {RichText.asText(formats[1]?.format_text)}
              </span>
            </p>
            <div className="c-for-artists-formats-section__image-container">
              <span>
                <img className="c-for-artists-formats-section__image" src={formats[1].format_image.url} alt="Cassette" />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
