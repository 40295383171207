import React, {useState, useEffect} from 'react';
import classNames from 'classnames';
import {
  get,
  compact,
  uniq,
  flattenDeep,
} from 'lodash';

import ApiHelper from 'utils/api_helper';
import {formatMoney} from 'utils/format_money';

import {Tooltip} from 'shared/components/tooltip/tooltip';
import {ToggleSwitch} from 'shared/components/form/toggle_switch/toggle_switch';
import {RadioField} from 'shared/components/form/radio_field/radio_field';
import {CheckboxField} from 'shared/components/form/checkbox_field/checkbox_field';
import {ReactModal} from 'shared/components/modals/react_modal/react_modal';
import {deliveryServices, isPriorityShippingAvailable} from 'utils/delivery_services';
import {ShippingInformationForm} from '../shipping_information_form/shipping_information_form';

export const ShippingInformationSection = ({
  currentUser,
  formParams,
  setFormParams,
  calculateOrder,
  isReadOnly,
  usStates,
  jpStates,
}) => {
  const [modal, setModal] = useState(null);
  const [priorityDeliveryFeeDelta, setPriorityDeliveryFeeDelta] = useState(0);
  const countries = window.constants.deliverableCountries.map((country) => ({label: country.name, value: country.code}));
  const country = countries.find((c) => c.value === get(formParams, 'orders[0].order_items[0].delivery_country'));
  const address = compact([
    get(formParams, 'orders[0].order_items[0].delivery_building'),
    get(formParams, 'orders[0].order_items[0].delivery_street'),
    get(formParams, 'orders[0].order_items[0].delivery_city'),
    get(formParams, 'orders[0].order_items[0].delivery_prefecture'),
    get(formParams, 'orders[0].order_items[0].delivery_postal_code'),
    country?.label,
  ]).join(', ');
  const name = compact([
    get(formParams, 'orders[0].order_items[0].delivery_first_name'),
    get(formParams, 'orders[0].order_items[0].delivery_last_name'),
  ]).join(' ');
  const [deliveryStrategyToggle, setDeliveryStrategyToggle] = useState(get(formParams, 'orders[0].delivery_strategy') === 'delivery_strategy_cheapest');
  const isUserInfoPresent = currentUser && currentUser.delivery_first_name && currentUser.delivery_last_name && currentUser.delivery_country;
  const priorityFeeDeltaFormatted = formatMoney({
    amount: priorityDeliveryFeeDelta,
    currency: get(formParams, 'orders[0].payment_currency'),
  });
  const isCheapestDeliveryAllowed = formParams?.orders?.every((order) => order.order_items.length > 1)
    && uniq(flattenDeep(formParams?.orders.map((order) => order.order_items.map((orderItem) => orderItem.delivery_service)))).length === 1;

  const handleDeliveryStrategyChange = (event) => {
    const checked = event.target.checked;

    setDeliveryStrategyToggle(checked);
    const newFormParams = {...formParams};
    newFormParams.orders.forEach((order) => {
      order.delivery_strategy = checked ? 'delivery_strategy_cheapest' : 'delivery_strategy_fastest';
    });
    setFormParams(newFormParams);
    calculateOrder({params: newFormParams});
  };

  const handleDeliveryServiceChange = (value) => {
    const deliveryServiceKeys = {
      delivery_service_type_standard: 'standartCode',
      delivery_service_type_priority: 'priorityCode',
    };
    const newFormParams = {...formParams};
    newFormParams.orders.forEach((order) => {
      order.delivery_service_type = value;
      order.order_items.forEach((orderItem) => {
        const newDeliveryService = deliveryServices[String(orderItem.delivery_service).match(/delivery_service_[a-z]+/)?.[0]]?.[deliveryServiceKeys[value]];
        if (newDeliveryService) {
          orderItem.delivery_service = newDeliveryService;
        }
      });
    });
    setFormParams(newFormParams);
    calculateOrder({params: newFormParams});
  };

  const deliveryService = String(get(formParams, 'orders[0].order_items[0].delivery_service')).match(/delivery_service_[a-z]+/)?.[0];
  const priorityShippingAvailable = isPriorityShippingAvailable(deliveryService);
  const standardShippingLabel = deliveryServices[deliveryService]?.standartTitle;
  const shipTogetherTooltip = {
    text: 'When this option is enabled, we\'ll try to ship multiple items in a single package to reduce shipping costs.',
    supportLinkHref: 'https://support.qrates.com/l/en/article/097ns55v73-what-are-combined-shipping-and-separate-shipping',
    supportLinkLabel: 'Learn more',
  };

  useEffect(() => {
    if (priorityShippingAvailable) {
      ApiHelper.post('/api/v1/checkout/priority_delta', formParams).then((res) => {
        setPriorityDeliveryFeeDelta(res.response.value);
      });
    }
  }, [formParams]);

  return (
    <div className={classNames('c-shipping-information-section', {
      'c-shipping-information-section--is-show-only': currentUser,
    })}
    >
      <h3 className="c-shipping-information-section__title">Shipping information</h3>
      {isUserInfoPresent && (
        <div className="c-shipping-information-section__ready-block-info ready-block-info">
          <div className="ready-block-info__content">
            <div className="ready-block-info__item">{name}</div>
            <div className="ready-block-info__item">{get(formParams, 'orders[0].order_items[0].delivery_company')}</div>
            <div className="ready-block-info__item">{address}</div>
            <div className="ready-block-info__item">{get(formParams, 'orders[0].order_items[0].delivery_phone_number')}</div>
          </div>
          <button
            className="ready-block-info__edit-modal-button edit-button"
            type="button"
            onClick={() => setModal('delivery_info_form')}
          >
            EDIT
          </button>
        </div>
      )}
      {!isUserInfoPresent && (
        <ShippingInformationForm
          className="c-shipping-information-section__form"
          formParams={formParams}
          setFormParams={setFormParams}
          calculateOrder={calculateOrder}
          isReadOnly={isReadOnly}
          usStates={usStates}
          jpStates={jpStates}
          currentUser={currentUser}
          isUserInfoPresent={isUserInfoPresent}
        />
      )}
      <ReactModal
        isOpen={modal === 'delivery_info_form'}
        appElement={document.body}
        onRequestClose={() => setModal(null)}
        onClose={() => setModal(null)}
      >
        <ShippingInformationForm
          isModal
          onCloseModal={() => setModal(null)}
          formParams={formParams}
          setFormParams={setFormParams}
          calculateOrder={calculateOrder}
          isReadOnly={isReadOnly}
          usStates={usStates}
          jpStates={jpStates}
          currentUser={currentUser}
          isUserInfoPresent={isUserInfoPresent}
        />
      </ReactModal>
      <div className={classNames('shipping-method-section', {'shipping-method-section--with-border': currentUser})}>
        <h3 className="shipping-method-section__title">Shipping method</h3>
        {isCheapestDeliveryAllowed && (
          <div className="shipping-method-section__switch">
            <div className="shipping-method-section__switch-title">
              Ship together
              <Tooltip theme="thin-frames">
                <div className="c-tooltip-content">
                  <p className="c-tooltip-content__text">
                    {shipTogetherTooltip.text}
                  </p>
                  <a
                    className="c-tooltip-content__link c-link"
                    href={shipTogetherTooltip.supportLinkHref}
                    target="_blank"
                  >
                    {shipTogetherTooltip.supportLinkLabel}
                  </a>
                </div>
              </Tooltip>
            </div>
            <ToggleSwitch
              className="shipping-method-section__toggle"
              checked={deliveryStrategyToggle}
              onChange={handleDeliveryStrategyChange}
              isLocked={isReadOnly}
            />
          </div>
        )}
        {deliveryService && (
          <>
            <div className="shipping-method-section__radio-block radio-block u-hidden-tablet">
              <RadioField
                className="radio-block__item"
                label={standardShippingLabel}
                theme="selected-bold"
                checked={get(formParams, 'orders[0].delivery_service_type') === 'delivery_service_type_standard'}
                onChange={() => handleDeliveryServiceChange('delivery_service_type_standard')}
              />
              {priorityShippingAvailable && (
                <div className="radio-block__priority-item">
                  <RadioField
                    label="Priority shipping (includes tracking)"
                    theme="selected-bold"
                    checked={get(formParams, 'orders[0].delivery_service_type') === 'delivery_service_type_priority'}
                    onChange={() => handleDeliveryServiceChange('delivery_service_type_priority')}
                  />
                  {priorityDeliveryFeeDelta > 0 && (
                    <p className="radio-block__priority-descr">
                      {`+ ${priorityFeeDeltaFormatted} per international order`}
                    </p>
                  )}
                </div>
              )}
            </div>
            <div className="shipping-method-section__checkboxes-block checkboxes-block u-hidden-desktop-only">
              <div className="checkboxes-block__checkbox">
                <CheckboxField
                  className="checkboxes-block__checkbox-field"
                  checked={get(formParams, 'orders[0].delivery_service_type') === 'delivery_service_type_standard'}
                  onChange={() => handleDeliveryServiceChange('delivery_service_type_standard')}
                  disabled={isReadOnly}
                />
                <p>{standardShippingLabel}</p>
              </div>
              {priorityShippingAvailable && (
                <div className="checkboxes-block__checkbox-priority-field">
                  <CheckboxField
                    className="checkboxes-block__checkbox-field"
                    checked={get(formParams, 'orders[0].delivery_service_type') === 'delivery_service_type_priority'}
                    onChange={() => handleDeliveryServiceChange('delivery_service_type_priority')}
                    disabled={isReadOnly}
                  />
                  <div className="checkboxes-block__content">
                    <p>Priority shipping (includes tracking)</p>
                    {priorityDeliveryFeeDelta > 0 && (
                      <p className="checkboxes-block__priority-description">
                        {`+ ${priorityFeeDeltaFormatted} per international order`}
                      </p>
                    )}
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};
