// @flow
import React from 'react';
import styled from 'styled-components';
import {RichText} from 'prismic-reactjs';
import {cssSmallPlusishText} from '../../styles/typography';
import type {StorySliceFooter} from '../../cms/story';
import {getSliceFooterText} from '../../cms/story';
import {cssGenericStyling} from '../../styles/shared';
import {
  breakpointTabletOnly,
  cssMobileSidePadding,
} from '../../styles/responsive';

const StyledContainer = styled.div`
  ${cssMobileSidePadding};
  width: 100%;
  padding: 20px 0px;
  ${breakpointTabletOnly} {
    padding: 40px 15px 0px;
  }
`;

const StyledContent = styled.div`
  ${cssGenericStyling};
  ${cssSmallPlusishText};
  max-width: 935px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;

  ${breakpointTabletOnly} {
    max-width: unset;
  }
`;

type Props = {
  slice: StorySliceFooter,
};

export const Footer = ({ slice }: Props) => (
  <StyledContainer>
    <StyledContent>
      <span wovn-ignore="">
        {RichText.render(getSliceFooterText(slice))}
      </span>
    </StyledContent>
  </StyledContainer>
);
