import React, {useState} from 'react';
import Modal from 'react-modal';
import {EmailModal} from 'account/components/credentials/email';
import {PasswordModal} from 'account/components/credentials/password';

export const Credentials = ({user}) => {
  const appElement = document.getElementById('account-credentials');
  const [modal, setModal] = useState(null);

  const clickLink = (e) => {
    e.preventDefault();
    setModal(e.target.getAttribute('value'));
  };

  const closeModal = () => {
    setModal(null);
  };

  return (
    <div id="account-credentials">
      <h2 className="c-dashboard__title p-account--settings__section-title">Email and password</h2>
      <div className="p-form-group--table">
        <div className="p-form-group--table__row">
          <div className="p-form-group--table__label">Email</div>
          <div className="c-input--static">{user.email}</div>
        </div>

        <div className="p-form-group--table__row">
          <div className="p-form-group--table__label">Password</div>
          <div className="c-input--static">{user.encrypted_password ? '********' : 'Not set'}</div>
        </div>
      </div>

      <div className="c-button-grid--right c-button-grid--bottom-control">
        <a href="email_account_path" onClick={clickLink} value="email" className="c-button c-button--black">CHANGE EMAIL</a>
        <a href="password_account_path" onClick={clickLink} value="password" className="c-button c-button--black">CHANGE PASSWORD</a>
      </div>

      <Modal
        appElement={appElement}
        isOpen={!!modal}
        className="p-modal__container"
        overlayClassName="p-modal"
        onRequestClose={closeModal}
      >
        {modal === 'email' && <EmailModal onClose={closeModal} />}
        {modal === 'password' && <PasswordModal onClose={closeModal} user={user} />}
      </Modal>
    </div>
  );
};
