import React from 'react';
import classNames from 'classnames';

export const PublicPagesHeading = ({
  text,
  theme,
  className,
  children,
  headingTagValue = 2,
}) => {
  const CustomTag = `h${headingTagValue}`;

  return (
    <CustomTag className={classNames('c-public-pages-heading', {
      [className]: className,

      // Themes
      'c-public-pages-heading--middle-size': theme === 'middle-size',
    })}
    >
      <span wovn-ignore="">{text && text}</span>
      {children && children}
    </CustomTag>
  );
};
