import React, {useEffect, useState} from 'react';
import classNames from 'classnames';

import {lockScroll} from 'utils/lock_scroll';
import {checkoutFlowRoutes, publicPagesRoutes} from 'modules/routes';

import {getCurrentUser} from 'dashboard/modules/api';
import {AccountMenu} from 'shared/components/account_menu';
import {CartIcon} from 'shared/components/icons/cart_icon/cart_icon';
import QratesLogoImg from 'images/common/logos/qrates-logo.svg';
import {Preloader} from 'src/shared/components/preloader/preloader';
import {SearchModal} from 'search/search_modal';
import {HeaderNavigation} from './components/header_navigation/header_navigation';

export const CheckoutHeader = ({
  navLinks,
  startProjectPath,
  logInPath,
  currentStep,
}) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [isUserLoading, setIsUserLoading] = useState(false);
  const [isMobileMenuActive, setIsMobileMenuActive] = useState(false);
  const algoliaSearch = window.constants?.algolia_search;

  const getUser = async () => {
    setIsUserLoading(true);
    const response = await getCurrentUser();
    response?.response && setCurrentUser(response.response);
    setIsUserLoading(false);
  };

  const onMobileMenuOpen = () => {
    setIsMobileMenuActive(!isMobileMenuActive);
  };

  useEffect(() => {
    const onChangeMenuVisibility = () => {
      if (isMobileMenuActive && document.documentElement.clientWidth < 990) {
        lockScroll.enable();
      } else { lockScroll.disable(); }
    };

    onChangeMenuVisibility();
    window.addEventListener('resize', onChangeMenuVisibility);
    return () => {
      window.removeEventListener('resize', onChangeMenuVisibility);
    };
  }, [isMobileMenuActive]);

  useEffect(() => {
    getUser();
  }, []);
  return (
    <header className="c-checkout-header">
      <a className="c-checkout-header__logo" href={publicPagesRoutes.generalPagePath}>
        <img src={QratesLogoImg} alt="Qrates" />
      </a>
      <div className="c-checkout-header__steps-nav steps-nav">
        {(currentStep === 'cart' || currentStep === 'payment_details') && (
          <a
            className={classNames('steps-nav__link', {
              'is-current': currentStep === 'cart',
            })}
            href={checkoutFlowRoutes.cartScreen}
          >
            1. Your cart
          </a>
        )}
        {currentStep === 'result' && (
          <div className="steps-nav__link is-disabled">
            1. Your cart
          </div>
        )}
        <div className={classNames('steps-nav__link', {
          'is-current': currentStep === 'payment_details',
          'is-disabled': currentStep === 'result' || currentStep === 'cart',
        })}
        >
          2. PAYMENT DETAILS
        </div>
        <div className={classNames('steps-nav__link', {
          'is-current': currentStep === 'result',
          'is-disabled': currentStep === 'payment_details' || currentStep === 'cart',
        })}
        >
          3. CONFIRMATION
        </div>
      </div>
      <HeaderNavigation
        navLinks={navLinks}
        isMobileMenuActive={isMobileMenuActive}
        setIsMobileMenuActive={setIsMobileMenuActive}
        startProjectPath={startProjectPath}
        logInPath={!isUserLoading && !currentUser && logInPath}
      />
      <SearchModal
        className="c-checkout-header__search-modal"
        searchIcon
        algolia_id={algoliaSearch.algolia_id}
        algolia_search_api_key={algoliaSearch.algolia_search_api_key}
      />
      <CartIcon className="c-checkout-header__cart" />
      <div className="c-checkout-header__right-bar">
        {!isUserLoading && !currentUser && logInPath && (
          <a className="c-checkout-header__login-button u-hidden-tablet" href={logInPath}>Log in</a>
        )}
        {currentUser && <AccountMenu className="c-checkout-header__account-avatar" user={currentUser} />}
        {!currentUser && isUserLoading && <Preloader className="c-checkout-header__preloader" />}
        <div className="c-checkout-header__burger u-hidden-desktop-only" onClick={onMobileMenuOpen}>
          <i className="c-pictogram c-pictogram--hamburger" />
        </div>
      </div>
    </header>
  );
};
