import React from 'react';
import classNames from 'classnames';

export const UserAvatar = ({
  className, title, logoImage, link, onClick,
}) => {
  return (
    <div className={classNames('c-user-avatar', {[className]: className})} onClick={onClick}>
      <div className="c-user-avatar__logo">
        <img src={logoImage} alt={title} />
      </div>
      {title && <p className="c-user-avatar__title">{title}</p>}
      {link && <a className="c-user-avatar__link" href={link}> </a>}
    </div>
  );
};
