import React from 'react';
import {
  render,
  screen,
  fireEvent,
  waitFor,
} from '@testing-library/react';

import {CareersScreen} from '../CareersScreen';

const vacancies = [
  {
    id: 1,
    title: 'Title 1',
    short_description: 'Short Description 1',
  },
];

jest.mock('utils/api_helper', () => {
  return {
    get: () => Promise.resolve({
      response: [
        {
          id: 1,
          title: 'Title 2',
          short_description: 'Short Description 2',
        },
      ],
    }),
  };
});

it('renders correct data', async () => {
  render(<CareersScreen vacancies={vacancies} />);

  expect(screen.getByText('Title 1')).toBeInTheDocument();
  expect(screen.getByText('Short Description 1')).toBeInTheDocument();
});

describe('with languagechange', () => {
  const event = new CustomEvent('languagechange', {detail: {locale: 'es'}});

  it('replaces data', async () => {
    render(<CareersScreen vacancies={vacancies} />);

    expect(screen.getByText('Title 1')).toBeInTheDocument();
    expect(screen.getByText('Short Description 1')).toBeInTheDocument();

    fireEvent(window, event);

    await waitFor(() => {
      expect(screen.getByText('Title 2')).toBeInTheDocument();
    });

    expect(screen.queryByText('Title 1')).not.toBeInTheDocument();
    expect(screen.queryByText('Short Description 1')).not.toBeInTheDocument();

    expect(screen.getByText('Title 2')).toBeInTheDocument();
    expect(screen.getByText('Short Description 2')).toBeInTheDocument();
  });
});
