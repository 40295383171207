import React, {useState, useEffect} from 'react';

import Prismic from '@prismicio/client';
import {prismicClient} from 'prismic/shared/cms/prismic';

import {useLanguageChange} from 'utils/use_language_change';

import {QratesStudioSliderBanner} from 'public/components/qrates_studio_slider_banner/qrates_studio_slider_banner';
import {InstagramAlbumSection} from 'public/components/instagram_album_section/instagram_album_section';
import {TopArtistSection} from './top_artist_section/top_artist_section';
import {TopDiscoverNavSection} from './top_discover_nav_section/top_discover_nav_section';
import {TopForArtistsSection} from './top_for_artists_section/top_for_artists_section';
import {ErrorFallbackSection} from '../../components/error_fallback_section/ErrorFallbackSection';
import {LoadingSection} from '../../components/loading_section/LoadingSection';

export const TopPage = ({artist, socialRecords}) => {
  const [contents, setContents] = useState([]);
  const [sliderData, setSliderData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const queries = [
    Prismic.Predicates.at('document.type', 'top_page'),
    Prismic.Predicates.at('document.type', 'qrates_studio_section'),
  ];

  const queryPrismic = async (options) => {
    setIsLoading(true);
    const locales = window.constants.prismic_locales;
    const promises = queries.map((spec) => prismicClient.query(spec, {lang: locales[options.locale || window.constants.locale || 'en']}));
    const response = await Promise.all(promises);
    if (response) {
      setContents(response[0].results[0].data);
      setSliderData(response[1].results[0].data);
    }
  };

  const fetchContents = async (options = {}) => {
    try {
      return await queryPrismic(options);
    } catch (err) {
      console.error(err);
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchContents();
  }, []);

  useLanguageChange((event) => {
    const locale = event.detail.locale;
    fetchContents({locale});
  });

  if (isLoading) return <LoadingSection />;
  if (isError) return <ErrorFallbackSection />;

  return (
    <>
      <TopArtistSection
        record={artist}
        coverText={contents.cover_text}
        forFansButtonLabel={contents.label_for_for_fans_button}
        forArtistsButtonLabel={contents.label_for_for_artists_button}
      />
      <TopDiscoverNavSection
        label={contents.for_fans_label}
        links={contents.for_fans_links}
        bottomText={contents.for_fans_bottom_text}
        bottomHref="/curated"
        defaultBackground={contents.for_fans_default_background?.url}
      />
      <TopForArtistsSection
        label={contents.for_artists_label}
        title={contents.for_artists_title}
        description={contents.for_artists_text}
        image={artist.cover}
        forArtistsButtonLabel={contents.label_for_for_artists_button1}
        qratesStudioButtonLabel={contents.label_for_qrates_studio_button}
      />
      <TopDiscoverNavSection
        label={contents.artist_toolkit_label}
        links={contents.artist_toolkit_links}
        bottomText={contents.artist_toolkit_bottom_text}
        bottomHref="/articles"
        defaultBackground={contents.artist_toolkit_default_background?.url}
      />
      <QratesStudioSliderBanner sliderData={sliderData} />
      <InstagramAlbumSection items={socialRecords} />
    </>
  );
};
