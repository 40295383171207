import React from 'react';
import {RichText} from 'prismic-reactjs';

export const ShortCard = ({
  type,
  image,
  title,
  text,
  index,
  rootPath,
  uid,
}) => {
  const path = `${rootPath + uid}`;
  return (
    <a key={uid} href={path} target="_blank">
      <div key={`${title + index}`} index={index} className="c-curated-card-short">
        <div className="c-curated-card-short__top">
          <img src={image} alt={`${title + index}`} wovn-ignore="" />
        </div>
        <div className="c-curated-card-short__bottom">
          <h4 className="c-curated-card-short__title" wovn-ignore="">
            {RichText.asText(title)}
          </h4>
          <p className="c-curated-card-short__info" wovn-ignore="">
            {RichText.asText(text)}
          </p>
        </div>
      </div>
    </a>
  );
};
