// @flow
import { get } from 'lodash';
import type { StorySize } from '../../screens/StoriesScreen/components/StoryPreview/StoryPreview';
import { STORY_SIZE } from '../../screens/StoriesScreen/components/StoryPreview/StoryPreview';
import type { StoriesNavType } from '../../screens/StoriesScreen/components/StoriesNav/StoriesNav';
import { STORIES_NAV_TYPES } from '../../screens/StoriesScreen/components/StoriesNav/StoriesNav';
import type { ProjectMdl } from './project';

export type ImageMdl = {
  url: string,
  alt: string,
};

export const getImageUrl = (image: ImageMdl): string => {
  return get(image, 'url', '') || '';
};

export const getImageAltText = (image: ImageMdl): any => {
  return get(image, 'alt', '') || '';
};

export type StoryDataMdl = {
  preview_heading: any,
  preview_image: ImageMdl,
  preview_pre_heading: any,
  preview_text: any,
  preview_type: 'Full-width' | 'Medium' | 'Small',
  published_date: string,
  story_category: 'Interview',
  title: any,
};

export type StoryMdl = {
  slugs: string[],
  uid: string,
  id: string,
  data: StoryDataMdl,
};

export type RelatedArticleMdl = {
  story: StoryMdl,
  preview_type1: 'Full-width' | 'Medium' | 'Small',
};

export type StorySliceIntro = {
  slice_type: 'article_intro',
  primary: {
    intro_text: any,
    featured_image: ImageMdl,
    ['pre-title']: any,
    title1: any,
  },
};

export type StorySliceEmbeddedAlbum = {
  slice_type: 'embedded_album',
  primary: {
    label: any,
    project: ProjectMdl,
  },
};

export const getSliceEmbeddedAlbumProject = (slice: StorySliceEmbeddedAlbum): ProjectMdl => {
  return get(slice, 'primary.project', null);
};

export const getSliceEmbeddedAlbumLabel = (slice: StorySliceEmbeddedAlbum): any => {
  return get(slice, 'primary.label', '') || '';
};

export type StorySliceFooter = {
  slice_type: 'footer___credits',
  primary: {
    text: any,
  },
};

export const getSliceFooterText = (slice: StorySliceFooter): any => {
  return get(slice, 'primary.text', '') || '';
};

export type StorySliceFeaturedQuote = {
  slice_type: 'featured_quote',
  primary: {
    main_text: any,
    caption: any,
  },
};

export const getSliceFeaturedQuoteText = (slice: StorySliceFeaturedQuote): any => {
  return get(slice, 'primary.main_text', '') || '';
};

export const getSliceFeaturedQuoteCaption = (slice: StorySliceFeaturedQuote): any => {
  return get(slice, 'primary.caption', '') || '';
};

export type StorySliceRichText = {
  slice_type: 'rich_text',
  primary: {
    text: any,
    layout_alignment: 'Right' | 'Default',
  },
};

export type StorySliceArtistProfile = {
  slice_type: 'artist_profile',
  primary: {
    text: any,
    image: ImageMdl,
    link: {
      url: string,
    },
  },
};

export const getSliceArtistProfileDestination = (slice: StorySliceArtistProfile): string => {
  return get(slice, 'primary.link.url', '') || '';
};

export const getSliceArtistProfileText = (slice: StorySliceArtistProfile): any => {
  return get(slice, 'primary.text', '') || '';
};

export const getSliceArtistProfileImageUrl = (slice: StorySliceArtistProfile): string => {
  return get(slice, 'primary.image.url', '') || '';
};

export const getSliceArtistProfileImageAlt = (slice: StorySliceArtistProfile): string => {
  return get(slice, 'primary.image.alt', '') || '';
};

export type StorySliceFeaturedProject = {
  slice_type: 'featured_project',
  primary: {
    project: ProjectMdl,
    label: any,
  },
};

export const getSliceFeaturedProjectLabel = (slice: StorySliceFeaturedProject): any => {
  return get(slice, 'primary.label', '') || '';
};

export const getSliceFeaturedProjectProject = (slice: StorySliceFeaturedProject): ProjectMdl => {
  return get(slice, 'primary.project', null);
};

export type FeaturedImageMdl = {
  image: ImageMdl,
  caption: any,
};

export const getFeaturedImageCaption = (data: FeaturedImageMdl): any => {
  return get(data, 'caption', '') || '';
};

export const getFeaturedImageUrl = (data: FeaturedImageMdl): string => {
  return get(data, 'image.url', '') || '';
};

export const getFeaturedImageAltText = (data: FeaturedImageMdl): string => {
  return get(data, 'image.alt', '') || '';
};

export const getHalfImageUrl = (data: FeaturedImageMdl): string => {
  return get(data, 'image.url', '') || '';
};

export const getHalfImageAltText = (data: FeaturedImageMdl): string => {
  return get(data, 'image.alt', '') || '';
};

export const getHalfImageCaption = (data: FeaturedImageMdl): any => {
  return get(data, 'caption', '') || '';
};

export type FeaturedListItemMdl = {
  heading: any,
  image: ImageMdl,
  overline: any,
  text: any,
};

export const getFeaturedListItemHeading = (item: FeaturedListItemMdl): any => {
  return get(item, 'heading', '') || '';
};

export const getFeaturedListItemArtistName = (item: FeaturedListItemMdl): any => {
  return get(item, 'artist_name', '') || '';
};

export const getFeaturedListItemOverline = (item: FeaturedListItemMdl): any => {
  return get(item, 'overline', '') || '';
};

export const getFeaturedListItemText = (item: FeaturedListItemMdl): any => {
  return get(item, 'text', '') || '';
};

export const getFeaturedListItemImageUrl = (item: FeaturedListItemMdl): any => {
  return get(item, 'image.url', '') || '';
};

export const getFeaturedListItemImageAlt = (item: FeaturedListItemMdl): any => {
  return get(item, 'image.alt', '') || '';
};

export type StorySliceFeaturedList = {
  slice_type: 'featured_list',
  items: FeaturedListItemMdl[],
};

export const getSliceFeaturedListItems = (slice: StorySliceFeaturedList): FeaturedListItemMdl[] => {
  return get(slice, 'items', []) || [];
};

export type StorySliceFeaturedImages = {
  slice_type: 'featured_images',
  items: FeaturedImageMdl[],
};

export type SliceHalfImage = {
  slice_type: 'half_image',
};

export type SliceHalfHeading = {
  slice_type: 'half_heading',
};

export const getSliceFeaturedImages = (slice: StorySliceFeaturedImages): FeaturedImageMdl[] => {
  return get(slice, 'items', []) || [];
};

export const getSliceHalfImage = (slice: SliceHalfImage): any => {
  return get(slice, 'primary', []) || [];
};

export const getSliceHalfHeading = (slice: any): any => {
  return get(slice, 'primary.heading', '' || '');
};

export const getSliceRichText = (slice: StorySliceRichText): any => {
  return get(slice, 'primary.text', '') || '';
};

export const getSliceIntroImage = (slice: StorySliceIntro): string => {
  return get(slice, 'primary.featured_image.url', '') || '';
};

export const getSliceIntroPreTitle = (slice: StorySliceIntro): any => {
  return get(slice, 'primary.pre-title', '') || '';
};

export const getSliceIntroTitle = (slice: StorySliceIntro): any => {
  return get(slice, 'primary.title1', '') || '';
};

export const getSliceIntroText = (slice: StorySliceIntro): any => {
  return get(slice, 'primary.intro_text', '') || '';
};

export type StoryBodySlice = {
  slice_type: string,
  primary?: {
    layout_size?: 'Half' | 'Full',
  },
};

export const getSliceLayoutAlignment = (slice: StoryBodySlice): string => {
  return get(slice, 'primary.layout_alignment', '') || '';
};

export const getSliceLayoutSize = (slice: StoryBodySlice): string => {
  return get(slice, 'primary.layout_size', '') || '';
};

export const isSliceHalfSize = (slice: StoryBodySlice): boolean => {
  return getSliceLayoutSize(slice) === 'Half';
};

export const getSliceAlign = (slice) => {
  return get(slice, 'primary.align', '') || '';
};

export type StoryFullMdl = StoryMdl & {
  data: StoryDataMdl & {
    body: StoryBodySlice[],
    related_articles: RelatedArticleMdl[],
  },
};

export const getStorySlices = (story: StoryFullMdl): StoryBodySlice[] => {
  return get(story, 'data.body', []) || [];
};

export const getStoryRelatedArticles = (story: StoryFullMdl): RelatedArticleMdl[] => {
  return get(story, 'data.related_articles', []) || [];
};

export const getStorySlug = (story: StoryMdl): string => {
  return get(story, 'uid', '') || '';
};

export const getStoryCategory = (story: StoryMdl): string => {
  return get(story, 'data.story_category', '') || '';
};

export const getStoryPublishedDate = (story: StoryMdl): any => {
  return get(story, 'data.published_date', '') || '';
};

export const getStoryPreviewText = (story: StoryMdl): any => {
  return get(story, 'data.preview_text', '') || '';
};

export const getStoryPreviewImage = (story: StoryMdl): any => {
  return get(story, 'data.preview_image.url', '') || '';
};

export const getStoryPreviewPreHeading = (story: StoryMdl): any => {
  return get(story, 'data.preview_pre_heading', '') || '';
};

export const getStoryPreviewHeading = (story: StoryMdl): any => {
  return get(story, 'data.preview_heading', '') || '';
};

export const getStoryPreviewSize = (story: StoryMdl): StorySize => {
  const previewType = get(story, 'data.preview_type', '');
  switch (previewType) {
    case 'Small':
      return STORY_SIZE.small;
    case 'Medium':
      return STORY_SIZE.medium;
    default:
      return STORY_SIZE.large;
  }
};

const mappedStoryCategories = {
  [STORIES_NAV_TYPES.interviews]: 'Interview',
  [STORIES_NAV_TYPES.waxHeart]: 'Wax Heart',
  [STORIES_NAV_TYPES.sceneCheck]: 'Scene Check',
  [STORIES_NAV_TYPES.trackPremiers]: 'Track Premiere',
  [STORIES_NAV_TYPES.qratesBiz]: 'Qrates Biz',
};

export const getFilterStoryCategory = (filter: StoriesNavType): string => {
  const match = mappedStoryCategories[filter];
  return match || '';
};
