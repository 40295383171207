// @flow
import React, {useEffect} from 'react';
import styled, {css} from 'styled-components';
import classNames from 'classnames';
import parse from 'html-react-parser';
import {cssMediumPlusText, cssMediumText, cssAnchorText} from '../../styles/typography';
import {FONT_WEIGHTS} from '../../styles/fonts';
import {
  breakpointDesktopOnly,
  breakpointTabletOnly,
  cssMobileSidePadding,
} from '../../styles/responsive';
import {getSliceAlign} from '../../cms/story';

const cssAlignRight = css`
  margin-left: auto;
  margin-right: 0px;
`;

const cssFullSize = css`
  width: 100%!important;
  max-width: 100%!important;
  padding-inline: 40px;
  .c-story-embed {
    max-width: 100%!important;
  }
`;

const StyledContainer = styled.div`
  padding: 0px 40px;
  margin-bottom: 100px;
  & > * {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0px;
    }
  }
  ${breakpointTabletOnly} {
    padding: 0px 0px;
    margin-bottom: 40px;
  }

  ${cssMobileSidePadding};

  ${breakpointDesktopOnly} {
    width: 50%;
    ${props => (props.alignRight ? cssAlignRight : '')};
  }

  ${cssFullSize};

  .c-story-embed {
    width: 100%;
    max-width: 500px;
    ${breakpointTabletOnly} {
      max-width: 100%;
    }
    & > * {
      width: 100%;
    }
    &--video {
      padding-top: 56.25%;
      position: relative;
      max-width: 100%;

      iframe {
        position: absolute;
        top: 0px;
        left: 0px;
        height: 100%;
        width: 100%;
      }
    }
  }

  blockquote {
    max-width: unset!important;
  }

  h3 {
    ${cssMediumPlusText};
    &:not(:first-child) {
      margin-top: 40px;

      ${breakpointTabletOnly} {
        margin-top: 30px;
      }
    }
  }

  p {
    ${cssMediumText};
    font-weight: ${FONT_WEIGHTS.medium};
    margin-top: 10px;

    ${breakpointTabletOnly} {
      font-size: 18px;
      font-weight: 500;
      letter-spacing: -0.3px;
      line-height: 24px;
    }
  }

  a {
    ${cssAnchorText};
  }

  ul {
    list-style-type: disc;
    list-style-position: inside;
  }
  ol {
    list-style-type: decimal;
    list-style-position: inside;
  }
  ul ul, ol ul {
    list-style-type: circle;
    list-style-position: inside;
    margin-left: 15px;
  }
  ol ol, ul ol {
    list-style-type: lower-latin;
    list-style-position: inside;
    margin-left: 15px;
  }
`;

export const Embed = ({slice}: Props) => {
  useEffect(() => {
    if (slice.items?.some((item) => item.embed?.provider_name === 'Instagram')) {
      window.instgrm.Embeds.process();
    }
  }, []);

  return (
    <StyledContainer
      className="story-embed-slice"
      align={getSliceAlign(slice)}
      size={slice.primary?.layout_size}
    >
      {slice.items?.map((item, index) => {
        const isVideo = item.embed?.type === 'video';

        return (
          <div
            key={index}
            className={classNames('c-story-embed', {'c-story-embed--video': isVideo})}
          >
            {parse(item.embed?.html)}
          </div>
        )
      })}
    </StyledContainer>
  );
};
