import React, {useState} from 'react';

import StickerImagePath from 'images/common/sticker/ok.png';

import {useLanguageChange} from 'utils/use_language_change';
import ApiHelper from 'utils/api_helper';

export const TopArtistSection = ({artist}) => {
  const [record, setRecord] = useState(artist);

  useLanguageChange(() => {
    ApiHelper.get('/top/artist').then((res) => {
      setRecord(res.response);
    });
  });

  return (
    <section className="p-home__top p-top">
      <div className="p-top--grid p-top--home">
        <div className="p-top__left">
          <h1 className="p-top__heading">
            QRATES
            <span>MUSIC IN</span>
            <span>YOUR HANDS</span>
          </h1>
          <p className="p-top__explanation u-hidden-tablet">
            <span className="f-adjust-ja">We make it easy for artists to press vinyl, so fans can keep pieces of the music they </span>
            <span className="p-top__explanation-word f-adjust-ja"> love. ↓</span>
          </p>
        </div>
        <div className="p-top__right">
          <a href={record.cover_artist_url}>
            <div className="p-top__image">
              <img src={record.cover} title="artist_cover" />
            </div>
            <img src={StickerImagePath} className="c-sticker" />
          </a>
          <div className="c-vertical-text">
            <a href={record.cover_artist_url} wovn-ignore="">{record.cover_artist_name}</a>
            IS ON QRATES
          </div>
        </div>
        <p className="p-top__explanation u-hidden-desktop-only">
          We make it easy for artists to make vinyl and cassettes, so fans can keep pieces of the music they love ↓
        </p>
      </div>
      <div className="c-button-grid">
        <a href="/discover" className="c-button c-button--black">DISCOVER MUSIC →</a>
        <a href="javascript:void();" className="c-button c-button--white-line-black c-button--disabled">MAKE YOUR OWN</a>
      </div>
    </section>
  );
};
