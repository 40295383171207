// @flow
import React from 'react';
import styled from 'styled-components';
import {get} from 'lodash';
import parse from 'html-react-parser';
import {breakpointTabletOnly} from '../../styles/responsive';

const StyledTable = styled.div`
  overflow-x: auto;
  margin: 0px 40px 100px;
  height: 100%;

  ${breakpointTabletOnly} {
    margin: 0px 0px 40px 15px;
    padding-right: 15px;
  }
`;

const StyledContainer = styled.div`
  display: inline-block;
  width: 100%;

  table {
    border-collapse: collapse;
    width: 100%;
    font-size: 20px;
    font-style: normal;
    line-height: 28px;
    letter-spacing: -0.273px;
  }

  table,
  th,
  td {
    border: 1px solid black;
  }

  th {
    font-weight: bold;
  }

  tr {
    text-align: left;
  }

  th,
  td {
    padding: 10px;
  }

  ${breakpointTabletOnly} {
    min-width: 990px;

    table {
      overflow-x: auto;
      font-size: 18px;
      font-style: normal;
      line-height: 24px;
      letter-spacing: -0.3px;
    }

  th,
  td {
    padding: 10px;
    min-width: 140px;
    text-wrap: nowrap;
  }
  }
`;

type Props = {
  html: any,
};

export const Table = ({html}: Props) => (
  <StyledTable>
    <StyledContainer>
      <span wovn-ignore="">{parse(html, '[0].text', '' || '')}</span>
    </StyledContainer>
  </StyledTable>
);
