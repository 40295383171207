import React from 'react';

export const ArtistProjectState = ({project}) => {
  const {
    condition_message,
    title,
  } = project;

  return (
    <div className="c-project__meta">
      <div className="c-project__title">
        {title}
      </div>
      {condition_message && (
        <div className="c-project__row">
          <div className="c-project__state-details">
            {condition_message}
          </div>
        </div>
      )}
    </div>
  );
};
