import {css} from 'styled-components';
import {cssFontFKRoman, FONT_WEIGHTS} from './fonts';
import {breakpointTabletOnly, breakpointMobileOnly} from './responsive';

export const cssHeadingOne = css`
  font-size: 35px;
  letter-spacing: -0.3px;
  line-height: 40px;
  font-weight: ${FONT_WEIGHTS.pre_bold};

  ${breakpointTabletOnly} {
    font-size: 26px;
    line-height: 30px;
  }
`;

export const cssHeadingTwo = css`
  font-size: 28px;
  letter-spacing: -0.38px;
  line-height: 32px;
  font-weight: ${FONT_WEIGHTS.pre_bold};

  ${breakpointTabletOnly} {
    font-size: 24px;
    line-height: 28px;
  }
`;

export const cssHeadingThree = css`
  font-size: 22px;
  letter-spacing: -0.3px;
  line-height: 28px;
  font-weight: ${FONT_WEIGHTS.pre_bold};

  ${breakpointTabletOnly} {
    font-size: 20px;
    line-height: 26px;
  }
`;

export const cssNormalText = css`
  font-size: 20px;
  letter-spacing: -0.27px;
  line-height: 28px;
  font-weight: ${FONT_WEIGHTS.medium};
  ${breakpointTabletOnly} {
    font-size: 18px;
    letter-spacing: -0.3px;
    line-height: 24px;
  }
`;

export const cssNormalTextSmall = css`
  font-size: 14px;
  letter-spacing: -0.19px;
  line-height: 18px;
  ${breakpointTabletOnly} {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.23px;
  }
`;

export const cssNormalTextLarge = css`
  font-size: 28px;
  letter-spacing: -0.3px;
  line-height: 34px;
  font-weight: ${FONT_WEIGHTS.medium};
  ${breakpointTabletOnly} {
    font-size: 22px;
    letter-spacing: -0.37px;
    line-height: 26px;
  }
`;

export const cssNormalTextLargest = css`
  ${cssFontFKRoman};
  font-size: 40px;
  line-height: 49px;
  letter-spacing: -0.5px;
  em {
    display: block;
    font-style: normal;
    ${breakpointTabletOnly} {
      font-size: 32px;
      line-height: 42px;
      letter-spacing: -1.5px;
    }
  }
`;

export const cssTinyTextSize = css`
  font-size: 11px;
  letter-spacing: 0;
  line-height: 16px;
`;

export const cssTinyText = css`
  ${cssTinyTextSize};
  font-weight: 500;
`;

export const cssTinyPlusText = css`
  font-size: 12px;
  letter-spacing: -0.15px;
  line-height: 18px;
  font-weight: ${FONT_WEIGHTS.bold};
`;

export const cssSmallTextSize = css`
  font-size: 14px;
  letter-spacing: 0.18px;
  line-height: 16px;
`;

export const cssSmallText = css`
  ${cssSmallTextSize};
  font-weight: ${FONT_WEIGHTS.bold};
`;

export const cssSmallPlusTextSize = css`
  font-size: 16px;
  letter-spacing: 0.18px;
  line-height: 16px;
  ${breakpointMobileOnly} {
    font-size: 12px;
    letter-spacing: -0.15px;
    line-height: normal;
  }
`;

export const cssSmallPlusText = css`
  ${cssSmallPlusTextSize};
  font-weight: ${FONT_WEIGHTS.pre_bold};
`;

export const cssSmallPlusishText = css`
  font-size: 16px;
  letter-spacing: -0.2px;
  line-height: 22px;
  font-weight: ${FONT_WEIGHTS.medium};

  ${breakpointTabletOnly} {
    font-size: 14px;
    line-height: 18px;
  }
`;

export const cssSmallPlusishPlusText = css`
  font-size: 18px;
  letter-spacing: -0.27px;
  line-height: 28px;
  font-weight: ${FONT_WEIGHTS.medium};
`;

export const cssMediumTextSize = css`
  font-size: 20px;
  letter-spacing: -0.27px;
  line-height: 28px;
`;

export const cssMediumText = css`
  ${cssMediumTextSize};
`;

export const cssMediumPlusTextSize = css`
  font-size: 22px;
  letter-spacing: -0.3px;
  line-height: 31px;
`;

export const cssMediumPlusText = css`
  ${cssMediumPlusTextSize};
  font-weight: ${FONT_WEIGHTS.bold};
`;

export const cssLargeTextSize = css`
  font-size: 28px;
  letter-spacing: -0.09px;
  line-height: 42px;
`;

export const cssLargeText = css`
  ${cssLargeTextSize};
  font-weight: ${FONT_WEIGHTS.bold};
`;

export const cssLargeishText = css`
  font-size: 28px;
  letter-spacing: -0.3px;
  line-height: 34px;
  font-weight: ${FONT_WEIGHTS.medium};

  ${breakpointTabletOnly} {
    font-size: 22px;
    line-height: 26px;
  }
`;

export const cssLargerText = css`
  font-size: 32px;
  letter-spacing: -0.35px;
  line-height: 42px;
  font-weight: ${FONT_WEIGHTS.bold};
`;

export const cssLargeLessThanLargePlusTextSize = css`
  font-size: 36px;
  letter-spacing: -1px;
  line-height: 47px;
  font-weight: ${FONT_WEIGHTS.bold};
`;

export const cssLargePlusTextSize = css`
  font-size: 40px;
  letter-spacing: -1px;
  line-height: 42px;

  ${breakpointTabletOnly} {
    font-size: 26px;
    line-height: 40px;
  }
`;

export const cssLargePlusText = css`
  ${cssLargePlusTextSize};
  font-weight: ${FONT_WEIGHTS.pre_bold};
`;

export const cssLargePlusPlusText = css`
  font-size: 44px;
  letter-spacing: -1.38px;
  line-height: 52px;
  font-weight: ${FONT_WEIGHTS.bold};
`;

export const cssHugeTextSize = css`
  font-size: 54px;
  letter-spacing: -1.75px;
  line-height: 64px;
`;

export const cssExtraHugeTextSize = css`
  font-size: 64px;
  line-height: 68px;
  font-weight: ${FONT_WEIGHTS.pre_bold};
`;

export const cssHugeText = css`
  ${cssHugeTextSize};
  font-weight: ${FONT_WEIGHTS.bold};
`;

export const cssGiantTextSize = css`
  font-size: 48px;
  letter-spacing: -2px;
  line-height: 60px;
`;

export const cssGiantText = css`
  ${cssGiantTextSize};
  font-weight: ${FONT_WEIGHTS.pre_bold};
`;

export const cssGiantishHeading = css`
  ${cssFontFKRoman};
  font-size: 64px;
  font-weight: ${FONT_WEIGHTS.regular};
  letter-spacing: -0.74px;
  line-height: 68px;
`;

export const cssQuoteHeading = css`
  font-family: 'Lucida Grande';
  font-size: 48px;
  font-weight: ${FONT_WEIGHTS.medium};
  letter-spacing: -1.5px;
  line-height: 60px;

  ${breakpointTabletOnly} {
    font-size: 30px;
    letter-spacing: -1px;
    line-height: 38px;
  }
`;

export const cssTextOutline = css`
  color: black;
  -webkit-text-fill-color: white; /* Will override color (regardless of order) */
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: black;
`;

export const cssAnchorText = css`
  font-weight: bold;
  text-decoration: underline;
`;

export const cssTextUnderline = css`
  text-decoration-line: underline;
  text-decoration-thickness: 1px;
  text-underline-offset: 2px;
`
