import React from 'react';
import classNames from 'classnames';
import {RichText} from 'prismic-reactjs';

import {NewLabel} from 'shared/components/labels/new_label/new_label';
import {PublicPagesHeading} from 'shared/components/content/public_pages_heading/public_pages_heading';

export const PublicTopSection = ({
  title,
  label,
  description,
  coverImage,
  imageRotateDirection,
}) => {
  return (
    <div className="c-public-top-section">
      <div className="c-public-top-section__container">
        <div className="c-public-top-section__content">
          <NewLabel className="c-public-top-section__label" title={label} />
          <PublicPagesHeading
            className="c-public-top-section__title"
            headingTagValue={1}
          >
            {title}
          </PublicPagesHeading>
          <p wovn-ignore="" className="c-public-top-section__description">{RichText.asText(description)}</p>
        </div>
        <div className={classNames(
          'c-public-top-section__right right-image-block',
          {'right-image-block--rotate-left': imageRotateDirection === 'left'})}
        >
          <a className="right-image-block__link" href="">
            <div className="right-image-block__image">
              <img src={coverImage} alt="cover" />
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};
