import React from 'react';

export const Notification = ({
  children,
  type,
  onDismiss,
  customClassName,
}) => {
  return (
    <div className={`c-notification c-notification--${type} ${customClassName}`}>
      {children}
      {onDismiss && (
        <a className="c-notification__dismiss" onClick={onDismiss}>&times;</a>
      )}
    </div>
  );
};
