import React, {useState} from 'react';
import classNames from 'classnames';

import {ReactModal} from 'shared/components/modals/react_modal/react_modal';
import {WidgetModal} from 'shared/components/modals/widget_modal/widget_modal';
import {QRCodeDownloadModal} from 'shared/components/modals/qrcode_download_modal/qrcode_download_modal';

export const ShareIcon = ({
  className,
  type,
  data,
  isTitle,
}) => {
  const isModal = ['widget', 'qrcode'].includes(type);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const typeData = {
    widget: {
      title: 'WIDGET',
      iconClassName: 'c-pictogram c-pictogram--embed-fill',
    },
    qrcode: {
      title: 'GET QR CODE',
      iconClassName: 'c-pictogram c-pictogram--qrcode',
    },
    facebook: {
      title: 'FACEBOOK',
      iconClassName: 'c-pictogram c-pictogram--facebook',
    },
    twitter: {
      title: 'TWITTER',
      iconClassName: 'c-pictogram c-pictogram--twitter-fill',
    },
  };
  const appElement = document.getElementById('root');
  const closeModal = () => setIsModalOpen(false);

  let linkUrl = '';

  if (type === 'facebook') {
    const share_url = data.url;
    const app_id = data.app_id;
    const redirect_uri = data.redirect_uri;
    linkUrl = `https://www.facebook.com/dialog/share?app_id=${app_id}&href=${encodeURIComponent(share_url)}&redirect_uri=${encodeURIComponent(redirect_uri)}`;
  }

  if (type === 'twitter') {
    const message = data.message;
    const share_url = data.url;
    linkUrl = `https://twitter.com/share?url=${encodeURIComponent(share_url)}&hashtags=qrates&text=${encodeURIComponent(message)}`;
  }

  return (
    <div className={classNames('c-share-icon', {
      [className]: className,
    })}
    >
      {['widget', 'qrcode'].includes(type) && (
        <>
          <i className={typeData[type]?.iconClassName} onClick={() => isModal && setIsModalOpen(true)} />
          {isTitle && <span onClick={() => isModal && setIsModalOpen(true)}>{typeData[type]?.title}</span>}
        </>
      )}
      {['facebook', 'twitter'].includes(type) && (
        <a href={linkUrl} target="_blank">
          <i className={typeData[type]?.iconClassName} />
          {isTitle && <span>{typeData[type]?.title}</span>}
        </a>
      )}
      {isModal && (
        <ReactModal
          isOpen={isModalOpen}
          appElement={appElement}
          onRequestClose={closeModal}
          onClose={closeModal}
          theme="thin-frames"
        >
          {type === 'widget' && <WidgetModal projectId={data.projectId} />}
          {type === 'qrcode' && <QRCodeDownloadModal project_id={data.projectId} />}
        </ReactModal>
      )}
    </div>
  );
};
