import React, {useState} from 'react';

import {ProjectDetailsModal} from 'shared/components/modals/project_details_modal/project_details_modal';
import {ReactModal} from 'shared/components/modals/react_modal/react_modal';

export const ProjectDetailsButton = ({project}) => {
  const [modal, setModal] = useState(false);
  const appElement = document.getElementById('project-details');
  const closeModal = () => setModal(false);

  return (
    <div id="project-details">
      <div
        className="p-project-status__link"
        onClick={() => setModal(true)}
      >
        MORE INFO
      </div>
      <ReactModal
        isOpen={modal}
        theme="thin-frames"
        appElement={appElement}
        onRequestClose={closeModal}
        onClose={closeModal}
        adaptiveWidth
      >
        <ProjectDetailsModal project={project} />
      </ReactModal>
    </div>
  );
};
