import React, {useRef} from 'react';
import {get, set} from 'lodash';

import {CheckboxField} from 'shared/components/form/checkbox_field/checkbox_field';
import {InputField} from 'shared/components/form/input_field/input_field';

export const CreateAccountSection = ({formParams, setFormParams, isReadOnly}) => {
  const termsRef = useRef(false);
  const handleChange = (event, isTerms) => {
    const newFormaParams = {...formParams};
    newFormaParams.orders.forEach((order) => {
      if (isTerms) {
        termsRef.current = !termsRef.current;
        set(order, event.target.name, termsRef.current);
      } else {
        set(order, event.target.name, event.target.value);
      }
    });
    setFormParams(newFormaParams);
  };

  const prePopulateUserInfo = () => {
    const newFormaParams = {...formParams};
    set(newFormaParams, 'pre_populate_user_billing_info', !formParams.pre_populate_user_billing_info);
    set(newFormaParams, 'pre_populate_user_delivery_info', !formParams.pre_populate_user_delivery_info);
    setFormParams(newFormaParams);
  };

  return (
    <div className="c-create-account-section">
      <h3 className="c-create-account-section__title">Create an account</h3>
      <div className="c-create-account-section__social social-block">
        <a className="social-block__item" href="">
          <i className="c-pictogram c-pictogram--soundcloud" />
          Use Soundcloud
        </a>
        <a className="social-block__item" href="">
          <i className="c-pictogram c-pictogram--facebook" />
          Use Facebook
        </a>
        <a className="social-block__item" href="">
          <i className="c-pictogram c-pictogram--twitter-fill" />
          Use Twitter
        </a>
      </div>
      <div className="c-create-account-section__description">
        Your Qrates account will allow you to place orders, see your order status, receive updates, and more!
      </div>
      <div className="c-create-account-section__form">
        <InputField
          className="c-create-account-section__email-field"
          label="E-mail *"
          name="email"
          placeholder="e-mail@email.com"
          value={get(formParams, 'orders[0].email')}
          onChange={handleChange}
          isReadOnly={isReadOnly}
        />
        <InputField
          className="c-create-account-section__password-field"
          label="Password *"
          name="password"
          placeholder="*****"
          value={get(formParams, 'orders[0].password')}
          onChange={handleChange}
          isReadOnly={isReadOnly}
          type="password"
        />
        <InputField
          className="c-create-account-section__confirm-password-field"
          label="Password (Confirmation) *"
          name="password_confirmation"
          placeholder="*****"
          value={get(formParams, 'orders[0].password_confirmation')}
          onChange={handleChange}
          isReadOnly={isReadOnly}
          type="password"
        />
      </div>
      <div className="c-create-account-section__password-description">
        Passwords should contain a mix of capital and lowercase
        letters and numerals for security, and must be at least 6 characters in length. *Required information
      </div>
      <div className="c-create-account-section__confirm-block">
        <CheckboxField
          className="c-create-account-section__confirm-checkbox-field"
          name="terms"
          theme="medium-size-text"
          onChange={(event, isTerms) => handleChange(event, isTerms = true)}
          disabled={isReadOnly}
          checked={get(formParams, 'orders[0].terms')}
        >
          <p>
            I have read and agree to the Qrates
            &nbsp;
            <a className="c-link" href="/terms">terms and conditions</a>
            &nbsp;
            and
            &nbsp;
            <a className="c-link" href="/privacy">privacy policy</a>
            .
          </p>
        </CheckboxField>

      </div>
      <div className="c-create-account-section__checkbox">
        <CheckboxField
          className="c-create-account-section__checkbox-field"
          name="save_user_info"
          onChange={prePopulateUserInfo}
          disabled={isReadOnly}
          checked={formParams.pre_populate_user_billing_info}
        />
        <p>Save all of your information to order easily next time.</p>
      </div>
    </div>
  );
};
